import * as React from "react";
import {ChangeEvent} from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import {i18n} from "../util/I18n";
import FtRegion from "../model/FtRegion";
import lodash from "lodash";
import Button from "@material-ui/core/Button";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtSnackbar from "../ui/FtSnackbar";
import FtToast from "../ui/FtToast";
import FtUtil from "../util/FtUtil";
import FssDeliveryProvider from "../model/FssDeliveryProvider";
import FssCityService from "../service/FssCityService";
import FssDeliveryProviderService from "../service/FssDeliveryProviderService";

export class FssPickUpProviderDialogState {
    open: boolean = false;
    pickUpProvider: FssDeliveryProvider = new FssDeliveryProvider();
    countryList: Array<FtRegion> = [];
    provinceList: Array<FtRegion> = [];
    cityList: Array<FtRegion> = [];
    onSuccessFunction?: () => void;
}


export default class FssPickUpProviderDialog extends React.Component<any, FssPickUpProviderDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            pickUpProvider: new FssDeliveryProvider(),
            countryList: [],
            provinceList: [],
            cityList: [],
        };
        this.onChange = this.onChange.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.show = this.show.bind(this);
        this.onSavePickUpDeliveryProvider = this.onSavePickUpDeliveryProvider.bind(this);
        this.onDeletePickUpDeliveryProvider = this.onDeletePickUpDeliveryProvider.bind(this);
    }

    componentDidMount(): void {
        // FssCityService.getCanadaRegion((result: FtRegion) => {
        //     this.state.countryList.push(result);
        //     this.forceUpdate();
        // });
        // FssCityService.getChinaRegion((result: FtRegion) => {
        //     this.state.countryList.push(result);
        //     this.forceUpdate();
        // });
        // FssCityService.getAmericaRegion((result: FtRegion) => {
        //     this.state.countryList.push(result);
        //     this.forceUpdate();
        // });
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.pickUpProvider, event.target.id, event.target.value);
        this.forceUpdate();
    }

    show(pickUpProvider: FssDeliveryProvider, onSuccessFunction?: () => void) {
        this.setState({open: true, pickUpProvider: pickUpProvider, onSuccessFunction: onSuccessFunction});
    }

    onCancelClick(): void {
        this.setState({open: false});
    }

    render() {
        const provinceList: Array<FtRegion> = this.getRegionList(this.state.pickUpProvider.pickUpCountry, this.state.countryList);
        const cityList: Array<FtRegion> = this.getRegionList(this.state.pickUpProvider.pickUpProvince, provinceList);
        return <Dialog open={this.state.open} PaperProps={{style: {height: "80vh"}}}>
            <DialogTitle style={{textAlign: "center"}}>
                {this.state.pickUpProvider.id !== null && this.state.pickUpProvider.id.trim().length > 0 && i18n("自取点详情")}
                {this.state.pickUpProvider.id === null || this.state.pickUpProvider.id.trim().length === 0 && i18n("新增自取点")}
            </DialogTitle>
            <DialogContent dividers style={{paddingTop: 0, paddingBottom: 0}}>
                <FtRow cellWidthS={"1"} cellWidthM={"1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                       cellVerticalSpace={16}>
                    <JtTextField id="deliveryProviderName" label={i18n("自取点名称")}
                                 value={this.state.pickUpProvider.deliveryProviderName}
                                 onChange={this.onChange} style={{width: "100%"}}
                                 inputProps={{style: {textAlign: "right"}}}/>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel> {i18n("国家")} </InputLabel>
                        <Select id="pickUpCountry" labelId="demo-simple-select-label"
                                value={this.state.pickUpProvider.pickUpCountry}
                                onChange={(event, child) => {
                                    this.state.pickUpProvider.pickUpCountry = event.target.value as string;
                                    let provinceList: Array<FtRegion> = new Array<FtRegion>();
                                    const country = lodash.find(this.state.countryList, {'code': event.target.value as string});
                                    if (country && country.subItems && country.subItems.length > 0) {
                                        country.subItems.map((region: FtRegion) => {
                                            provinceList.push(region);
                                        })
                                    }
                                    this.setState({
                                        provinceList: provinceList
                                    });
                                    this.forceUpdate();
                                }}>
                            {this.state.countryList.map(country => {
                                return <MenuItem value={country.code}>{i18n(country.name)}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel id="demo-simple-select-label"> {i18n("省份")} </InputLabel>
                        <Select id="pickUpCountry" labelId="demo-simple-select-label"
                                value={this.state.pickUpProvider.pickUpProvince}
                                onChange={(event, child) => {
                                    this.state.pickUpProvider.pickUpProvince = event.target.value as string;
                                    let cityList: Array<FtRegion> = new Array<FtRegion>();
                                    const province = lodash.find(this.state.provinceList, {'code': event.target.value as string});
                                    if (province && province.subItems && province.subItems.length > 0) {
                                        province.subItems.map((region: FtRegion) => {
                                            cityList.push(region);
                                        })
                                    }
                                    this.setState({cityList: cityList});
                                    this.forceUpdate();

                                }}>
                            {provinceList.map(province => {
                                return <MenuItem value={province.code}>{i18n(province.name)}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel id="demo-simple-select-label"> {i18n("收件人市")} </InputLabel>
                        <Select id="pickUpCountry" labelId="demo-simple-select-label"
                                value={this.state.pickUpProvider.pickUpCity}
                                onChange={(event, child) => {
                                    this.state.pickUpProvider.pickUpCity = event.target.value as string;
                                    this.forceUpdate();
                                }}>
                            {cityList.map(city => {
                                return <MenuItem value={city.code}>{i18n(city.name)}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                    <JtTextField id="contactName" label={i18n("联系人")}
                                 value={this.state.pickUpProvider.contactName}
                                 onChange={this.onChange} style={{width: '100%'}}/>

                    <JtTextField id="contactPhone" label={i18n("联系电话")}
                                 value={this.state.pickUpProvider.contactPhone} onChange={this.onChange}
                                 style={{width: '100%'}}/>
                    <JtTextField id="contactEmail" label={i18n("联系Email")}
                                 value={this.state.pickUpProvider.contactEmail} onChange={this.onChange}
                                 style={{width: '100%'}}/>
                    <JtTextField id="pickUpAddress" label={i18n("详细地址")} required={true}
                                 value={this.state.pickUpProvider.pickUpAddress} onChange={this.onChange}
                                 style={{width: '100%'}}/>
                    <JtTextField id="shortDescription" label={i18n("描述")}
                                 value={this.state.pickUpProvider.shortDescription} onChange={this.onChange}
                                 style={{width: '100%'}}/>
                    <FormControl style={{width: '100%'}}>
                        <InputLabel> {i18n("到店付款")} </InputLabel>
                        <Select id="payAtStore"
                                value={this.state.pickUpProvider.payAtStore}
                                onChange={(event, child) => {
                                    this.state.pickUpProvider.payAtStore = event.target.value as boolean;
                                    this.forceUpdate();
                                }}>
                            <MenuItem value={"false"}>{i18n("否")}</MenuItem>
                            <MenuItem value={"true"}>{i18n("是")}</MenuItem>
                        </Select>
                    </FormControl>
                </FtRow>
            </DialogContent>
            <DialogActions style={{border: "none"}}>
                <FtRow justifyContent={"space-between"} cellHorizontalAlign={"center"} style={{width: "100%"}}>
                    <Button variant="outlined" onClick={this.onCancelClick}>取消</Button>
                    {this.state.pickUpProvider.id !== null && this.state.pickUpProvider.id.trim().length > 0 &&
                    <Button variant="outlined" onClick={this.onDeletePickUpDeliveryProvider}>删除</Button>}
                    <Button variant="outlined" color="primary" onClick={this.onSavePickUpDeliveryProvider}>确定</Button>
                </FtRow>
            </DialogActions>
        </Dialog>
    }

    onDeletePickUpDeliveryProvider() {
        FtMessageDialog.dialog.openDialog(i18n("确定要删除吗?"), () => {
            FssDeliveryProviderService.deletePublisherDeliveryProvider(this.state.pickUpProvider.id, (data: string) => {
                FtSnackbar.showText(data);
                this.onCancelClick();
                if (this.state.onSuccessFunction) {
                    this.state.onSuccessFunction();
                }
            });
        });
    }

    onSavePickUpDeliveryProvider() {
        if (this.state.pickUpProvider.deliveryProviderName == "") {
            FtSnackbar.showText(i18n("输入自取点名称！"));
            return;
        }
        if (this.state.pickUpProvider.pickUpCountry == "") {
            FtSnackbar.showText(i18n("请选择国家！"));
            return;
        }
        if (this.state.pickUpProvider.pickUpProvince == "") {
            FtSnackbar.showText(i18n("请选择省！"));
            return;
        }
        if (this.state.pickUpProvider.pickUpCity == "") {
            FtSnackbar.showText(i18n("请选择市！"));
            return;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        FssDeliveryProviderService.savePickUpDeliveryProvider(this.state.pickUpProvider,
            (data: FssDeliveryProvider) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
                this.onCancelClick();
                if (this.state.onSuccessFunction) {
                    this.state.onSuccessFunction();
                }
            });
    }

    getRegionList(item: string, list: Array<FtRegion>) {
        let result: Array<FtRegion> = [];
        if (item !== null && item.trim().length > 0) {
            const country = lodash.find(list, {'code': item});
            if (country && country.subItems && country.subItems.length > 0) {
                country.subItems.map((region: FtRegion) => {
                    result.push(region);
                })
            }
        }
        return result;
    }
}
