import * as React from "react";
import {CSSProperties, HTMLAttributes} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {grey} from "@material-ui/core/colors";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtIconLabel from "../ui/FtIconLabel";
import {i18n} from "../util/I18n";

import {
    CodeOutlined,
    FormatListBulletedRounded,
    HeadsetMic,
    ImageOutlined,
    ListOutlined,
    OndemandVideoOutlined,
    SearchOutlined,
    ShoppingCart,
    ViewAgendaOutlined,
    ViewCarouselOutlined,
    ViewComfy
} from "@material-ui/icons";
import FtPanel from "../ui/FtPanel";
import FssComponent, {EComponentType} from "../model/FssComponent";

export interface FssComponentPanelProp extends HTMLAttributes<HTMLDivElement> {
    onInsertComponent: (component: FssComponent, reference?: boolean) => void;
}

export class FssComponentPanelState extends FtComponentState {

}

export default class FssComponentPanel extends FtComponent<FssComponentPanelProp, FssComponentPanelState> {
    constructor(props: any) {
        super(props);
        this.onClickSystemComponent=this.onClickSystemComponent.bind(this);
    }

    render() {
        let labelStyle: CSSProperties = {width: 100, height: 80};
        let iconStyle: CSSProperties = {width: 36, height: 36};
        return <div>
            <FtPanel panelHeader={i18n("内置组件")} elevation={0}
                     style={{
                         backgroundColor: grey[800],
                         color: grey[50],
                         width: 200,
                         boxSizing: "border-box"
                     }}>
                <FtGrid>
                    <FtRow cellWidthM={"1,1"} cellHorizontalAlign={"center"}>
                        <FtIconLabel style={labelStyle} id={EComponentType.IMAGE_LIST}
                                     onClickMe={this.onClickSystemComponent} label={i18n("图片")}
                                     iconNode={<ImageOutlined style={iconStyle}/>} />
                        <FtIconLabel style={labelStyle} id={EComponentType.PRODUCT_LIST}
                                     onClickMe={this.onClickSystemComponent} label={i18n("商品列表")}
                                     iconNode={<ViewAgendaOutlined style={iconStyle}/>} />
                        {/*<FtIconLabel style={labelStyle} id={EComponentType.CATEGORY_GRID}*/}
                        {/*             onClickMe={this.onClickSystemComponent} label={i18n("分类列表")}*/}
                        {/*             iconNode={<ViewComfy style={iconStyle}/>} />*/}
                        <FtIconLabel style={labelStyle} id={EComponentType.STORE_CATEGORY_GRID}
                                     onClickMe={this.onClickSystemComponent} label={i18n("店铺分类列表")}
                                     iconNode={<ViewComfy style={iconStyle}/>} />
                        <FtIconLabel style={labelStyle} id={EComponentType.HTML}
                                     onClickMe={this.onClickSystemComponent} label={"HTML"}
                                     iconNode={<CodeOutlined style={iconStyle}/>} />
                        <FtIconLabel style={labelStyle} id={EComponentType.LOGO_BANNER}
                                     onClickMe={this.onClickSystemComponent} label={i18n("LOGO广告")}
                                     iconNode={<ViewCarouselOutlined style={iconStyle}/>} />
                        <FtIconLabel style={labelStyle} id={EComponentType.VIDEO}
                                     onClickMe={this.onClickSystemComponent} label={i18n("视频")}
                                     iconNode={<OndemandVideoOutlined style={iconStyle}/>} />
                        <FtIconLabel style={labelStyle} id={EComponentType.SEARCH_BOX}
                                     onClickMe={this.onClickSystemComponent} label={i18n("搜索框")}
                                     iconNode={<SearchOutlined style={iconStyle}/>} />
                        <FtIconLabel style={labelStyle} id={EComponentType.NAVIGATION}
                                     onClickMe={this.onClickSystemComponent} label={i18n("导航")}
                                     iconNode={<ListOutlined style={iconStyle}/>} />
                        {/*<FtIconLabel style={labelStyle} id={EComponentType.SHOPPING_CART_ICON}*/}
                        {/*             onClickMe={this.onClickSystemComponent} label={i18n("购物车图标")}*/}
                        {/*             iconNode={<ShoppingCart style={iconStyle}/>} />*/}
                        <FtIconLabel style={labelStyle} id={EComponentType.CONTACTS_ICON}
                                     onClickMe={this.onClickSystemComponent} label={i18n("客服图标")}
                                     iconNode={<HeadsetMic style={iconStyle}/>} />
                        {/*<FtIconLabel style={labelStyle} id={EComponentType.CATEGORY_NAVIGATION}*/}
                        {/*             onClickMe={this.onClickSystemComponent} label={i18n("分类导航")}*/}
                        {/*             iconNode={<FormatListBulletedRounded style={iconStyle}/>} />*/}
                        <FtIconLabel style={labelStyle} id={EComponentType.STORE_CATEGORY_NAVIGATION}
                                     onClickMe={this.onClickSystemComponent} label={i18n("店铺分类导航")}
                                     iconNode={<FormatListBulletedRounded style={iconStyle}/>} />
                        <FtIconLabel style={labelStyle} id={EComponentType.JIE_LONG_LIST}
                                     onClickMe={this.onClickSystemComponent} label={i18n("接龙列表")}
                                     iconNode={<ViewAgendaOutlined style={iconStyle}/>} />
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FtPanel panelHeader={i18n("我的组件")} elevation={0} style={{
                backgroundColor: grey[800],
                color: grey[50],
                width: 200,
                boxSizing: "border-box"
            }}>

            </FtPanel>
        </div>;
    }

    private onClickSystemComponent(id?: string) {
        if (id) {
            const component: FssComponent = new FssComponent();
            component.componentName = "Copy of " + id;
            component.componentType = id as EComponentType;
            component.data={};
            component.content="";
            component.createDatetimeText=""+new Date().getTime();
            if (this.props.onInsertComponent) {
                this.props.onInsertComponent(component, false);
            }
        }
    }
}
