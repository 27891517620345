import {FssAbstractCompData} from "./FssAbstractCompData";


export class FssImageLink {
    imageUrl?: string;
    link?: string;
    name?: string;
}

export enum EImageListLayout {
    CAROUSEL = "CAROUSEL",
    ROW = "ROW",
    COLUMN = "COLUMN"
}

export default class FssImageListCompData extends FssAbstractCompData {
    imageLinkArray?: Array<FssImageLink> = [];
    layout?: EImageListLayout;
    margin?: string | number;
    borderRadius?: string | number;
    mobileLineNum?: number;
    pcLineNum?: number;
    likeMobile?: boolean;
    pcImageLinkArray?: Array<FssImageLink> = [];
    title?: string;
    showMore?: boolean = false;
    moreLink?: string;
    cellHorizontalSpace?: number;
    cellVerticalSpace?: number;
}
