import * as React from "react";
import {TextField, Box} from "@material-ui/core";
import {ColorResult, SketchPicker} from "react-color";

export interface FtColorInputProp {
    label?: string;
    color?: string;
    backgroundColor?: string;
    onChangeColor?: (color?: string) => void;
}

export class FtColorInputState {
    showPicker: boolean = false;
}

export default class FtColorInput extends React.Component<FtColorInputProp, FtColorInputState> {
    constructor(props: any) {
        super(props);
        this.state = {showPicker: false};
    }

    componentDidMount(): void {

    }


    render() {
        return <div>
            <TextField label={this.props.label} InputProps={{
                style: {color: this.props.color, backgroundColor: this.props.backgroundColor}, onClick: () => {
                    this.setState({showPicker: true});
                }
            }} value={this.props.backgroundColor} onChange={(event)=>{
                if (this.props.onChangeColor)
                    this.props.onChangeColor(event.target.value);
            }}>
            </TextField>
            {this.state.showPicker && <div style={{position: "relative"}}>
                <div style={{position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',}}
                     onClick={() => {
                         this.setState({showPicker: false})
                     }}/>
                <Box style={{position: "absolute", top: 0, left: 0}} zIndex={2}>
                    <SketchPicker color={this.props.backgroundColor} disableAlpha={true}
                                  onChangeComplete={(color: ColorResult) => {
                                      if (this.props.onChangeColor)
                                          this.props.onChangeColor(color.hex);
                                  }}/>
                </Box>
            </div>}
        </div>;
    }
}
