import * as React from "react";
import {ReactNode} from "react";
import {AddBox, ZoomInOutlined} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {Card, CardContent, CardMedia, FormControlLabel, Switch, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FtPanel from "../../ui/FtPanel";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import lodash from "lodash";
import FtToolbar from "../../ui/FtToolbar";
import FtSnackbar from "../../ui/FtSnackbar";
import JtTextField from "../../ui/JtTextField";
import FtMediaViewerDialog from "../../ui/FtMediaViewerDialog";
import {i18n} from "../../util/I18n";
import FssImageListCompData, {EImageListLayout, FssImageLink} from "../../page/component/data/FssImageListCompData";
import FssUserMediaSelectDialog from "../../userMedia/FssUserMediaSelectDialog";
import FssUserMedia from "../../model/FssUserMedia";


interface FssImageListSelectedProp {
    key: string;
    imageListCompData: FssImageListCompData;
    onChangeComponent?: (imageListCompData: FssImageListCompData) => void;
    showType: "mobile" | "pc";
}

export class FssImageListSelectedState {
    dragElement: FssImageLink = new FssImageLink();
    lock: boolean = true;
    openMessageBoxDialog: boolean = false;
    deleteIndex: number = -1;
}

export default class FssImageListSelected extends React.Component<FssImageListSelectedProp, FssImageListSelectedState> {
    jtUserMediaSelectDialog!: FssUserMediaSelectDialog;

    constructor(props: any) {
        super(props);
        this.state = {
            dragElement: new FssImageLink(),
            lock: true,
            openMessageBoxDialog: false,
            deleteIndex: -1
        };
        this.onChangeComponent = this.onChangeComponent.bind(this);
        this.getMessageBoxDialog = this.getMessageBoxDialog.bind(this);
        this.onChangeSelectOptions = this.onChangeSelectOptions.bind(this);
        this.onClickImage = this.onClickImage.bind(this);
        this.onClickZoomOut = this.onClickZoomOut.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    }

    componentWillUnmount() {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    }

    preventDefault = (e: Event) => {
        e.preventDefault();
    }

    onDragStart = (image: FssImageLink) => {
        this.setState({dragElement: image});
    }

    onDragEnter = (image: FssImageLink) => {
        let imageUrl = image.imageUrl;
        if (imageUrl !== this.state.dragElement.imageUrl) {
            let imageSelectOptions = this.getImageLinkArray();

            const oldDragIndex = lodash.findIndex(imageSelectOptions, (item: { imageUrl?: string; }) => item.imageUrl === this.state.dragElement.imageUrl);
            const oldEnterIndex = lodash.findIndex(imageSelectOptions, (item: { imageUrl?: string; }) => item.imageUrl === image.imageUrl);
            if (oldDragIndex > oldEnterIndex) {
                const newDataArray = imageSelectOptions.filter(item => item.imageUrl !== this.state.dragElement.imageUrl);
                const insertIndex = lodash.findIndex(newDataArray, (item: { imageUrl?: string; }) => item.imageUrl === image.imageUrl);
                newDataArray.splice(insertIndex, 0, this.state.dragElement);
                this.onChangeSelectOptions(newDataArray);
            } else {
                const newDataArray = imageSelectOptions.filter(item => item.imageUrl !== this.state.dragElement.imageUrl);
                const insertIndex = lodash.findIndex(newDataArray, (item: { imageUrl?: string; }) => item.imageUrl === image.imageUrl) + 1;
                newDataArray.splice(insertIndex, 0, this.state.dragElement);
                this.onChangeSelectOptions(newDataArray);
            }
        }
    }
    onDragLeave = (image: FssImageLink) => {
        if (image.imageUrl !== this.state.dragElement.imageUrl) {
            let imageSelectOptions = this.getImageLinkArray();
            if (this.state.lock && image.imageUrl === (imageSelectOptions[imageSelectOptions.length - 1]).imageUrl) {
                const newDataArray = imageSelectOptions.filter(item => item.imageUrl !== this.state.dragElement.imageUrl);
                newDataArray.push(this.state.dragElement);
                this.setState({lock: false,}, () => {
                    this.onChangeSelectOptions(newDataArray);
                });
            } else {
                this.setState({lock: true,});
            }
        }
    }

    onChangeComponent() {
        if (this.props.onChangeComponent)
            this.props.onChangeComponent(this.props.imageListCompData);
    }

    getMessageBoxDialog(): ReactNode {
        let imageOptions = this.getImageLinkArray();
        return <Dialog open={this.state.openMessageBoxDialog} onClose={(e) => {
            this.setState({openMessageBoxDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("确认")}</DialogTitle>
            <DialogContent>
                {i18n("确定要删除吗?")}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                }}>{i18n("取消")}</Button>
                <Button variant="outlined" color="primary" onClick={(e) => {
                    imageOptions.splice(this.state.deleteIndex, 1);
                    this.setState({openMessageBoxDialog: false});
                    this.onChangeSelectOptions(imageOptions);
                }}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }

    onChangeSelectOptions(imageSelectOptions: Array<FssImageLink>) {
        if (this.props.showType == "mobile") {
            this.props.imageListCompData.imageLinkArray = imageSelectOptions;
        } else if (this.props.showType == "pc") {
            this.props.imageListCompData.pcImageLinkArray = imageSelectOptions;
        }
        // 如果与移动端一致
        if (this.props.imageListCompData.likeMobile) {
            this.props.imageListCompData.pcImageLinkArray = lodash.clone(this.props.imageListCompData.imageLinkArray);
        }
        this.onChangeComponent();
    }

    getImageLinkArray() {
        let imageListCompData = this.props.imageListCompData;
        if (imageListCompData.imageLinkArray) {
            return imageListCompData.imageLinkArray;
        }
        return [];
        // if (this.props.showType == "mobile" && imageListCompData.imageLinkArray) {
        //     imageSelectOptions = imageListCompData.imageLinkArray;
        // }
        // else if (this.props.showType == "pc" && imageListCompData.pcImageLinkArray) {
        //     imageSelectOptions = imageListCompData.pcImageLinkArray;
        // }
        // return imageSelectOptions;
    }

    render() {
        let imageListCompData = this.props.imageListCompData;
        let imageSelectOptions: Array<FssImageLink> = this.getImageLinkArray();
        return <div style={{minHeight: 280}} key={this.props.key}>
            <FtToolbar style={{minHeight: 38}}
                       leftNode={<Button>{i18n("图片")}</Button>}
                       rightNode={<Button color="default" disabled={this.props.showType == "pc"} onClick={() => {
                           this.onClickImage();
                       }}><AddBox/></Button>}>
            </FtToolbar>
            <FtPanel style={{width: "100%", paddingBottom: 8}}>
                {imageSelectOptions.map((image: FssImageLink, index: number) => {
                    return <Card key={image.imageUrl}
                                 draggable={!imageListCompData.likeMobile || this.props.showType == "mobile"}
                                 onDragStart={() => {
                                     this.onDragStart(image)
                                 }}
                                 onDragEnd={(e) => {
                                     e.preventDefault();
                                 }}
                                 onDragEnter={() => {
                                     this.onDragEnter(image)
                                 }}
                                 onDragLeave={() => {
                                     this.onDragLeave(image)
                                 }}
                                 style={{
                                     textAlign: "left",
                                     margin: 8,
                                     backgroundColor: "#EEEEEE",
                                     position: "relative"
                                 }}>
                        {this.props.showType != "pc" &&
                        <IconButton
                            style={{position: "absolute", left: 10, top: 10, backgroundColor: "#EEEEEE", color: "red"}}
                            onClick={(event) => {
                                if (image.imageUrl)
                                    this.onClickZoomOut(event, image.imageUrl);
                            }}>
                            <ZoomInOutlined/>
                        </IconButton>}
                        {(!imageListCompData.likeMobile || this.props.showType == "mobile") && <IconButton
                            style={{position: "absolute", right: 10, top: 10, backgroundColor: "#EEEEEE", color: "red"}}
                            onClick={() => {
                                this.setState({openMessageBoxDialog: true, deleteIndex: index})
                            }}>
                            <DeleteIcon/>
                        </IconButton>}
                        <CardMedia style={{height: 120}} image={image.imageUrl}/>
                        <CardContent>
                            <JtTextField id="href" label={i18n("目标链接URL")} value={image.link ? image.link : ""}
                                         disabled={imageListCompData.likeMobile && this.props.showType == "pc"}
                                         onChange={(event) => {
                                             image.link = event.target.value;
                                             this.onChangeComponent();
                                         }} style={{width: "95%"}}>
                            </JtTextField>
                            {this.props.imageListCompData.layout == EImageListLayout.ROW &&
                            <JtTextField id="name" label={i18n("名称")} value={image.name ? image.name : ""}
                                         disabled={imageListCompData.likeMobile && this.props.showType == "pc"}
                                         onChange={(event) => {
                                             image.name = event.target.value;
                                             this.onChangeComponent();
                                         }} style={{width: "95%"}}/>}
                        </CardContent>
                    </Card>;
                })}
            </FtPanel>
            {this.getMessageBoxDialog()}
            <FssUserMediaSelectDialog ref={(element: any) => {
                this.jtUserMediaSelectDialog = element
            }}/>
        </div>;
    }

    onClickImage(imageUrl?: string, id?: string) {
        this.jtUserMediaSelectDialog.show("single", (userMediaList: Array<FssUserMedia> | null) => {
            if (userMediaList != null && userMediaList.length > 0) {
                let mediaUrl = userMediaList[0].mediaUrl;
                let imageSelectOptions = this.getImageLinkArray();
                const checkExist = lodash.findIndex(imageSelectOptions, (item: { imageUrl?: string; }) => item.imageUrl === mediaUrl);
                if (checkExist > -1)
                    FtSnackbar.showText(i18n("此图片已经添加过了"));
                else {
                    let image = new FssImageLink();
                    image.imageUrl = mediaUrl;
                    imageSelectOptions.push(image);
                    this.onChangeSelectOptions(imageSelectOptions);
                }
            }
        });
    }

    onClickZoomOut(event: any, imageUrl: string) {
        event.stopPropagation();
        if (imageUrl && imageUrl.length > 0) {
            FtMediaViewerDialog.FtMediaViewerDialog.show(imageUrl);
        }
    }
}
