import FssHttpService from "./FssHttpService";
import FssStoreCategory from "../model/FssStoreCategory";

export default class FssStoreCategoryService {

    static getStoreCategoryList(keyword: string | undefined, pageSize: number | undefined, successFunction: (result: Array<FssStoreCategory>) => void) {
        FssHttpService.get("/api/storeCategory/getStoreCategoryList", {
            keyword: keyword,
            pageSize: pageSize
        }, successFunction);
    }

    static getCategoryListByIds(categoryIds: Array<string>, successFunction: (categoryList: Array<FssStoreCategory>) => void) {
        FssHttpService.postFormData("/api/storeCategory/getCategoryListByIds", {categoryIds: categoryIds}, successFunction);
    }

    static getRootCategory(successFunction: (data: FssStoreCategory) => void): void {
        FssHttpService.get("/api/storeCategory/getRootCategory", {}, successFunction);
    }
}
