import * as React from "react";
import {CSSProperties, ReactNode} from "react";
import {SvgIcon} from "@material-ui/core";
import FtSnackbar from "./FtSnackbar";
import FtUtil from "../util/FtUtil";
import FtToast from "./FtToast";
import {i18n} from "../util/I18n";

export interface FtFileUploadButtonProp {
    onFinish: (data: Array<any>, fileName: string) => void;
    uploadServiceFunction: (file: any, successFunction: (data: Array<any>) => void,
                            progressFunction: (percent: number) => void, failFunction: (error: any) => void) => void;
    fileType?: string;
    buttonStyle?: CSSProperties;
    buttonLabel?: string;
    buttonSvg?: ReactNode;
    multiple?: boolean;
    formStyle?: CSSProperties;
    inputId?: string;
}

export class FtFileUploadButtonState {

}

export default class FtFileUploadButtonToService extends React.Component<FtFileUploadButtonProp, FtFileUploadButtonState> {
    inputUploadFile!: HTMLInputElement;

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onFileChange = this.onFileChange.bind(this);
    }

    onFileChange(event: any) {
        if (event.target.files != null) {
            const fileList: FileList = event.target.files;
            const files: Array<File> = [];
            for (let index = 0; index < fileList.length; index++) {
                let file = fileList.item(index);
                let fileSize = 5 * 1024 * 1024;
                if (file != null) {
                    if (file.size >= fileSize) {
                        FtSnackbar.FtSnackbar.show("Maximum file size is 5M");
                        return;
                    }
                    files.push(file);
                }
            }
            if (this.inputUploadFile.form) {
                this.inputUploadFile.form.reset();
            }
            if (files.length > 0) {
                FtToast.showToastLoading(i18n("正在上传"), 60000);
                let file = files[0];
                this.props.uploadServiceFunction(file, (data: Array<any>) => {
                    FtToast.closeToast();
                    console.log("################")
                    // this.props.onFinish(this.state.uploadResultArray);
                    this.props.onFinish(data, file.name);
                    if (this.inputUploadFile.form) {
                        this.inputUploadFile.form.reset();
                    }
                }, (percent: number) => {

                }, (error: any) => {
                    FtToast.closeToast();
                    if (error.responseText) {
                        FtSnackbar.FtSnackbar.show(error.responseText);
                    }
                    if (this.inputUploadFile.form) {
                        this.inputUploadFile.form.reset();
                    }
                });
            }
        }
    }

    render() {
        let buttonStyle: CSSProperties = {display: "flex", alignItems: "center"};
        if (this.props.buttonStyle) {
            buttonStyle = FtUtil.mergeObject(buttonStyle, this.props.buttonStyle);
        }
        let formStyle: CSSProperties = {};
        if (this.props.formStyle) {
            formStyle = FtUtil.mergeObject(formStyle, this.props.formStyle);
        }
        let inputId = this.props.inputId ? this.props.inputId : "fileUploadInput";
        return <div>
            <form style={formStyle}>
                <input type="file" style={{width: 0, height: 0, opacity: 0}} multiple={this.props.multiple}
                       name="uploadfile"
                       id={inputId} accept={this.props.fileType}
                       ref={(element: any) => {
                           this.inputUploadFile = element;
                       }}
                       onChange={this.onFileChange}/>
            </form>
            <label htmlFor={inputId} style={buttonStyle}>
                {this.getButtonSvg()}
                {this.getButtonLabel()}
            </label>
        </div>;
    }

    getButtonSvg() {
        if (this.props.buttonSvg != null) {
            return this.props.buttonSvg;
        }
        return <SvgIcon>
            <path
                d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,12L16,16H13.5V19H10.5V16H8L12,12Z"/>
        </SvgIcon>
    }

    getButtonLabel() {
        if (this.props.buttonLabel != null && this.props.buttonLabel.trim().length > 0) {
            return this.props.buttonLabel.trim();
        }
        return null;
    }
}
