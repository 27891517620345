import * as React from "react";
import WatchableData from "../data/WatchableData";
import FtUtil from "../util/FtUtil";
import {FssWebSession} from "../model/FssWebSession";
import FssAppWebsite from "../model/FssAppWebsite";

export class FssClientDataState {
    webSession: FssWebSession = new FssWebSession();
    appWebsite: FssAppWebsite = new FssAppWebsite();
    landingPageUrl: string = "";
    websiteStoreId: string = "";
    storeDefaultSellerId: string = "";
}


export default class FssClientData extends WatchableData {
    private static data: FssClientDataState = new FssClientDataState();

    static getData(): FssClientDataState {
        return FssClientData.data;
    }

    static updateAppWebsite(appWebsite: FssAppWebsite) {
        FssClientData.getData().appWebsite = FtUtil.mergeObject(new FssAppWebsite(), appWebsite);
        this.fireDataChange("appWebsite", FssClientData.getData().appWebsite, appWebsite);
        this.saveToLocalStorage();
    }

    static updateLanguage(lang: string) {
        FssClientData.getData().webSession.language = lang;
        FssClientData.saveToLocalStorage();
    }

    static updateWebSession(data: FssWebSession): void {
        FssClientData.getData().webSession = FtUtil.mergeObject(new FssWebSession(), data);
        this.fireDataChange("webSession", FssClientData.getData().webSession);
        //保存登录数据
        FssClientData.saveToLocalStorage();
    }

    static clearWebSession(): void {
        FssClientData.getData().webSession = new FssWebSession();
        window.localStorage.removeItem("FssClientData.data.webSession");
        window.localStorage.removeItem("FssClientData.data.websiteStoreId");
        window.localStorage.removeItem("FssClientData.data.storeDefaultSellerId");
    }

    static isLogin(): boolean {
        const webSession = FssClientData.getData().webSession;
        return webSession != null && webSession.loginSessionId != null && webSession.loginSessionId.length > 0;
    }

    static saveToLocalStorage() {
        window.localStorage.setItem("FssClientData.data.webSession", JSON.stringify(FssClientData.getData().webSession));
        window.localStorage.setItem("FssClientData.data.websiteStoreId", FssClientData.getData().websiteStoreId);
        window.localStorage.setItem("FssClientData.data.storeDefaultSellerId", FssClientData.getData().storeDefaultSellerId);
    }

    static saveWechatOpenidToLocalStorage(wechatOpenId: string) {
        window.localStorage.setItem("wechatOpenId", wechatOpenId);
    }

    static loadFromLocalStorage() {
        const str: string | null = window.localStorage.getItem("FssClientData.data.webSession");
        if (str && str.length > 0)
            FssClientData.getData().webSession = JSON.parse(str);
        const websiteStoreId: string | null = window.localStorage.getItem("FssClientData.data.websiteStoreId");
        if (websiteStoreId && websiteStoreId.length > 0)
            FssClientData.getData().websiteStoreId = websiteStoreId;
        const storeDefaultSellerId: string | null = window.localStorage.getItem("FssClientData.data.storeDefaultSellerId");
        if (storeDefaultSellerId && storeDefaultSellerId.length > 0)
            FssClientData.getData().storeDefaultSellerId = storeDefaultSellerId;

    }

    static formatDatetime(source: Date | number | string | undefined): string {
        if (source != null) {
            const appWebsite = FssClientData.getData().appWebsite;
            let options: any = {dateStyle: "short", timeStyle: "short"};
            if (appWebsite.timeZone != null && appWebsite.timeZone.length > 0)
                options.timeZone = appWebsite.timeZone;
            return new Date(source).toLocaleString(appWebsite.language != null ? appWebsite.language : undefined, options);
        } else
            return "";
    }

    static formatYYYYmmddHHmmss(source: Date | number | string | undefined): string {
        if (source != null) {
            let options: any = {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'};
            return new Date(source).toLocaleString("en-US", options);
        } else {
            return "";
        }
    }
}
