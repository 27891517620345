import {SvgIcon} from "@material-ui/core";
import React from "react";


export default class FtFlagIconCA extends React.Component<any, any>{
    render(){
        return <SvgIcon x="0px" y="0px"
                        viewBox="0 0 512 512" style={{}} >
            <rect y="85.331" style={{fill:"#F0F0F0"}} width="512" height="341.326"/>
            <g>
                <rect y="85.331" style={{fill:"#D80027"}} width="170.663" height="341.337"/>
                <rect x="341.337" y="85.331" style={{fill:"#D80027"}} width="170.663" height="341.337"/>
                <polygon style={{fill:"#D80027"}} points="288,279.704 320,263.704 304,255.705 304,239.705 272,255.705 288,223.705 272,223.705
		256,199.704 240,223.705 224,223.705 240,255.705 208,239.705 208,255.705 192,263.704 224,279.704 216.001,295.704
		248.001,295.704 248.001,319.705 264.001,319.705 264.001,295.704 296.001,295.704 	"/>
            </g>
        </SvgIcon>;
    }
}
