import * as React from "react";
import Tabs, {TabsTypeMap} from "@material-ui/core/Tabs";
import {DefaultComponentProps} from "@material-ui/core/OverridableComponent";
import FtTabPanel from "./FtTabPanel";
import {Tab} from "@material-ui/core";
import {ReactNode} from "react";

export interface JtTabsProp extends DefaultComponentProps<TabsTypeMap> {
}

export default class FtTabs extends React.Component<JtTabsProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const tabLabelArray: Array<ReactNode> = [];
        let currentTabPanel: FtTabPanel | undefined = undefined;
        //取出TabPanel
        React.Children.map(this.props.children, (child: ReactNode, index: number) => {
            if (child != null) {
                const jtTabPanel: FtTabPanel = child as FtTabPanel;
                tabLabelArray.push(<Tab style={jtTabPanel.props.style} value={jtTabPanel.props.value} label={jtTabPanel.props.label} />);
                if (this.props.value && this.props.value == jtTabPanel.props.value)
                    currentTabPanel = jtTabPanel;
            }
        });
        return <div>
            <Tabs {...this.props}>
                {tabLabelArray}
            </Tabs>
            {currentTabPanel}
        </div>;
    }
}
