import * as React from "react";
import {ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {TextField} from "@material-ui/core";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import {CSVLink} from "react-csv";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import IconButton from "@material-ui/core/IconButton";
import {i18n} from "../util/I18n";
import {FssPaymentTransactionDaily} from "../model/FssPaymentTransactionDaily";
import FssPaymentTransactionService from "../service/FssPaymentTransactionService";
import FtRow from "../ui/FtRow";

export interface FssPaymentTransactionDailyListProp {
}

class JtPageContentListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    csvData: Array<FssPaymentTransactionDaily> = new Array<FssPaymentTransactionDaily>();
}

class JtTableAdapter extends FtDataTableAdapter<FssPaymentTransactionDaily> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssPaymentTransactionService.getFssPaymentTransactionDailyList(this.keyword,
            this.getFilterColumnValue("startDate"), this.getFilterColumnValue("endDate"),
            this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssPaymentTransactionDaily>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssPaymentTransactionDailyListPage extends FtComponent<FssPaymentTransactionDailyListProp, JtPageContentListPageState> {
    csvLink!:any;
    constructor(props: any) {

        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("dataDateText", i18n("日期"));
        columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("payOrderMoney", i18n("订单付款金额"),"right");
        columnProps.push(column1);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("refundOrderMoney", i18n("订单退款金额"),"right");
        columnProps.push(column3);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("paymentAccountName", i18n("支付帐号"),"center");
        columnProps.push(column5);

        let tableAdapter: JtTableAdapter;
        const cacheState: JtPageContentListPageState = FtState.pop("FssPaymentTransactionDailyListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter, columnProps: columnProps,
            stateKey: "FssPaymentTransactionDailyListPage", csvData: new Array<FssPaymentTransactionDaily>()
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.downloadCsvFile = this.downloadCsvFile.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("按日汇总")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         filterPanel={this.getFilterPanel()}
                         toolbarNoSelect={this.downloadCsvFile()}>
            </FtDataTable>
        </div>;
    }

    getFilterPanel(): ReactNode {

        return <FtRow>
            <TextField id="startDate"
                       label={i18n("开始时间")}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("startDate")}
                       InputLabelProps={{
                           shrink: true,
                       }}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("startDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            <TextField id="endDate"
                       label={i18n("结束时间")}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("endDate")}
                       InputLabelProps={{
                           shrink: true,
                       }}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("endDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
        </FtRow>;
    }

    downloadCsvFile(): ReactNode {
        let headers = [
            {label: i18n("日期"), key: "dataDateText"},
            {label: i18n("订单付款金额"), key: "payOrderMoney"},
            {label: i18n("订单退款金额"), key: "refundOrderMoney"},
            {label: i18n("支付帐号ID"), key: "paymentAccountId"}
        ];
        return <div>
            <IconButton onClick={() => {
                FtToast.showToastLoading("loading");
                FssPaymentTransactionService.getFssPaymentTransactionDailyList(this.state.tableAdapter.keyword,
                    this.state.tableAdapter.getFilterColumnValue("startDate"), this.state.tableAdapter.getFilterColumnValue("endDate"),
                    0, 999999,
                    (result: FtPagedList<FssPaymentTransactionDaily>) => {
                        FtToast.closeToast();
                        this.setState({csvData: result.content},()=>{
                            if(this.csvLink&&this.csvLink.link)
                                this.csvLink.link.click();
                        });
                    });
            }}><CloudDownloadOutlined/></IconButton>
            <CSVLink data={this.state.csvData} headers={headers} filename={i18n("日汇总.csv")}
                     ref={(element:any)=>{this.csvLink=element}}>
            </CSVLink>
        </div>
    }
}
