import * as React from "react";
import {ReactNode} from "react";
import {Typography} from "@material-ui/core";
import {Link} from "react-router-dom";
import FssInviteEmployeeComp from "./FssInviteEmployeeComp";
import {RouteComponentProps} from "react-router-dom";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import {i18n} from "../util/I18n";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FssSellerEmployeeService from "../service/FssSellerEmployeeService";
import FssSellerEmployee from "../model/FssSellerEmployee";
import FssUserService from "../service/FssUserService";
import FtApiResponse from "../model/FtApiResponse";

enum FasEmailResultPageType {
    RESET_PASSWORD = "RESET_PASSWORD",
    EMAIL_VERIFICATION = "EMAIL_VERIFICATION",
    INVITE_USER = "INVITE_USER",
}

interface FasEmailResultPageProp extends RouteComponentProps {
}

export class FasEmailResultPageState {
    verificationCode: string = "";
    type: string = "";
    resultNode: ReactNode = null;
}

export default class FssEmailResultPage extends React.Component<FasEmailResultPageProp, FasEmailResultPageState> {

    constructor(props: any) {
        super(props);

        this.state = {verificationCode: "", type: "", resultNode: null}

        this.getBodyComp=this.getBodyComp.bind(this);
        this.onClickOk=this.onClickOk.bind(this);
    }

    componentDidMount(): void {
        let url = window.location.href;
        const verificationCode = FtUtil.getParameterByName("verificationCode", url);
        if (verificationCode != null && verificationCode.length > 0){
            this.setState({verificationCode: verificationCode})
        }
        const type = FtUtil.getParameterByName("type", url);
        if (type != null && type.length > 0){
            this.setState({type: type})
        }
    }

    render() {
        return <div style={{height: "100%", width: "100%", display: "flex",
            alignItems: "center", justifyContent: "center", position: "fixed"}}>
            {this.getBodyComp()}
        </div>
    }


    onClickOk () {
        FtToast.showToastLoading();
        FssSellerEmployeeService.inviteUserByEmail(this.state.verificationCode, (fssSellerEmployee: FssSellerEmployee) => {
            FtToast.closeToast();
            let resultNode = <FtGrid style={{width: "100%"}} marginVertical={16} marginHorizontal={16}>
                <FtRow cellWidthS={"1"} cellVerticalSpace={32} cellHorizontalAlign={"center"}>
                    <Typography variant={"h5"}>{i18n("恭喜您成功加入网店")}</Typography>
                    <Typography variant={"h6"}>{i18n("确认成功，请及时联系")}[{fssSellerEmployee.seller?.fullName}]{i18n("管理员为您设置网店权限，否则无法登录。")}</Typography>
                </FtRow>
            </FtGrid>;
            this.setState({resultNode: resultNode});
        });
    }

    getBodyComp () {
        if (this.state.resultNode != null) {
            return this.state.resultNode;
        }else if (this.state.type == FasEmailResultPageType.EMAIL_VERIFICATION) {
            this.onEmailVerification();
        } else if (this.state.type == FasEmailResultPageType.INVITE_USER) {
            return <FssInviteEmployeeComp history={this.props.history} location={this.props.location} match={this.props.match}
                                          verificationCode={this.state.verificationCode}
                                          onClickOk={this.onClickOk}/>;
        }
    }
    onEmailVerification () {
        FtToast.showToastLoading("正在验证邮箱", 10000);
        FssUserService.checkEmailVerification(this.state.verificationCode, (apiResponse: string) => {
            FtToast.closeToast();
            let resultNode = <FtGrid style={{width: "100%"}} marginVertical={16} marginHorizontal={16}>
                <FtRow cellWidthS={"1"} cellVerticalSpace={32} cellHorizontalAlign={"center"}>
                    <Typography variant={"h5"}>{i18n("邮箱验证成功！")}</Typography>
                </FtRow>
            </FtGrid>;
            this.setState({resultNode: resultNode});
        }, (apiResponse: FtApiResponse) => {
            FtToast.closeToast();
            let resultNode = <FtGrid style={{width: "100%"}} marginVertical={16} marginHorizontal={16}>
                <FtRow cellWidthS={"1"} cellVerticalSpace={32} cellHorizontalAlign={"center"}>
                    <Typography variant={"h5"}>{i18n(apiResponse.message)}</Typography>
                </FtRow>
            </FtGrid>;
            this.setState({resultNode: resultNode});
        });
    }

}
