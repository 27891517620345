import * as React from "react";
import {RouteComponentProps} from "react-router-dom";
import FtToolbar from "../ui/FtToolbar";
import {ChevronLeft} from "@material-ui/icons";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import {i18n} from "../util/I18n";
import FssProduct from "../model/FssProduct";
import FssProductService from "../service/FssProductService";
import {Alert} from "@material-ui/lab";
import FtFileUploadButtonToService from "../ui/FtFileUploadButtonToService";
import FtToast from "../ui/FtToast";
import FtFileUploadButtonToOss from "../ui/FtFileUploadButtonToOss";

interface FtImportProductPageProp extends RouteComponentProps {
}

export class FtImportProductPageState {
    fileName?: string;
    message?: string = "";
    isCreateMissCategory: boolean = false;
    reImportExistingImage: boolean = false;
    productList?: Array<FssProduct>;
    appointFileName?: string;
    imageDomainName?: string;
    importImageComment: string = "";
}


export default class FtImportProductPage extends React.Component<FtImportProductPageProp, FtImportProductPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            isCreateMissCategory: false,
            reImportExistingImage: false,
            importImageComment: "请先导入图片，图片名称与CSV中保持一致，比如abc.jpg"
        };
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.onClickImportNow = this.onClickImportNow.bind(this);
        this.onFinishImage = this.onFinishImage.bind(this);
        this.reset = this.reset.bind(this);
        this.setProductImageUrl = this.setProductImageUrl.bind(this);
    }

    componentDidMount(): void {

    }

    render() {
        let dataPanelHeader = "csv文件请按照如下格式";
        if (this.state.productList && this.state.productList.length > 0)
            dataPanelHeader = "共 " + this.state.productList.length + " 个商品";
        return <div style={{display: "flex", flexDirection: "column"}}>
            <FtToolbar
                leftNode={<Button color="default" onClick={this.onCancelClick}><ChevronLeft/>{i18n("返回")}</Button>}/>
            <FtPanel panelHeader={i18n("使用csv文件批量导入商品")} marginVertical={0} marginHorizontal={0}>
                <FtGrid>
                    <FtRow>
                        {(!this.state.fileName || this.state.fileName.length == 0)
                        && <FtFileUploadButtonToService onFinish={this.onFinishUpload}
                                                        uploadServiceFunction={FssProductService.importProduct}
                                                        multiple={false}
                                                        formStyle={{height: 0}}
                                                        buttonLabel={i18n("请选择CSV文件")}
                                                        buttonStyle={{
                                                            border: "1px solid #333333",
                                                            padding: 4,
                                                            marginBottom: 4
                                                        }}/>}
                        {this.state.fileName && <Typography>文件：{this.state.fileName}</Typography>}
                    </FtRow>
                    <FtRow>
                        文件格式请参考：
                        <a href={process.env.PUBLIC_URL + "/csv/sample_add_product.csv?time=" + new Date().getTime()} target={"_blank"}>sample_add_product.csv</a>
                    </FtRow>
                    {this.state.message && this.state.message.length > 0 &&
                    <FtRow cellWidthS={"1"}> <Alert severity="info">{this.state.message}</Alert></FtRow>}
                    {this.state.fileName &&
                    <FtRow cellHorizontalSpace={16}>
                        <Button onClick={this.reset} variant={"outlined"}>{i18n("重置")}</Button>
                        <Button onClick={this.onClickImportNow} variant={"contained"}
                                color={"primary"}>{i18n("开始导入")}</Button>
                        <FtFileUploadButtonToOss onFinish={this.onFinishImage}
                                                 inputId={"uploadLoadImage"}
                                                 buttonSvg={<Button style={{pointerEvents: "none"}}
                                                                    variant={"contained"}
                                                                    color={"secondary"}>{i18n("上传图片")}</Button>}
                                                 buttonStyle={{width: "max-content", cursor: "pointer"}}
                                                 multiple={true}
                                                 fileType={"image/*"}
                                                 appointFileName={this.state.appointFileName}
                                                 formStyle={{height: 0}}/>
                    </FtRow>}
                    {this.state.fileName &&
                    <Typography color={"secondary"}>
                        {this.state.importImageComment}
                    </Typography>}
                </FtGrid>
            </FtPanel>
            <FtPanel panelHeader={dataPanelHeader} marginVertical={0} marginHorizontal={0} style={{marginTop: 16}}>
                <FtGrid>
                    <TableContainer component={Paper} style={{maxHeight: window.screen.availHeight - 460}}>
                        <Table size="small" stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>商品名称</StyledTableCell>
                                    <StyledTableCell>标题</StyledTableCell>
                                    <StyledTableCell>销售价</StyledTableCell>
                                    <StyledTableCell>原价</StyledTableCell>
                                    <StyledTableCell>采购价</StyledTableCell>
                                    <StyledTableCell>状态</StyledTableCell>
                                    <StyledTableCell>品牌</StyledTableCell>
                                    <StyledTableCell>简述</StyledTableCell>
                                    <StyledTableCell>标签</StyledTableCell>
                                    <StyledTableCell>Barcode</StyledTableCell>
                                    <StyledTableCell>规格</StyledTableCell>
                                    <StyledTableCell>规格值</StyledTableCell>
                                    <StyledTableCell>销售单位</StyledTableCell>
                                    <StyledTableCell>重量(克)</StyledTableCell>
                                    <StyledTableCell>体积(立方米)</StyledTableCell>
                                    <StyledTableCell>库存数量</StyledTableCell>
                                    <StyledTableCell>销售数量</StyledTableCell>
                                    <StyledTableCell>分类名称</StyledTableCell>
                                    <StyledTableCell>拣货分组</StyledTableCell>
                                    <StyledTableCell>库存位置</StyledTableCell>
                                    <StyledTableCell>图片1</StyledTableCell>
                                    <StyledTableCell>图片2</StyledTableCell>
                                    <StyledTableCell>图片3</StyledTableCell>
                                    <StyledTableCell>图片4</StyledTableCell>
                                    <StyledTableCell>图片5</StyledTableCell>
                                    <StyledTableCell>第三方来源ID</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.productList?.map((fssProduct: FssProduct, index: number) => {
                                    return <TableRow>
                                        <StyledTableCell>{fssProduct.name}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.title}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.salesPriceUsd}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.regularPriceUsd}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.purchasePriceUsd}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.productStatusText}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.brand}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.shortDescription}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.tags}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.barcode}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.specificationName}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.specificationValue}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.sellUnit}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.weight}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.size}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.stockAmount}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.salesAmount}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.category?.categoryName}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.pickingGroup}</StyledTableCell>
                                        <StyledTableCell>{fssProduct.storageLocation}</StyledTableCell>
                                        <StyledTableCell><img src={fssProduct.coverImageUrl}
                                                              style={{width: 100}}/></StyledTableCell>
                                        <StyledTableCell><img src={fssProduct.productImage1}
                                                              style={{width: 100}}/></StyledTableCell>
                                        <StyledTableCell><img src={fssProduct.productImage2}
                                                              style={{width: 100}}/></StyledTableCell>
                                        <StyledTableCell><img src={fssProduct.productImage3}
                                                              style={{width: 100}}/></StyledTableCell>
                                        <StyledTableCell><img src={fssProduct.productImage4}
                                                              style={{width: 100}}/></StyledTableCell>
                                        <StyledTableCell>{fssProduct.importSourceId}</StyledTableCell>
                                    </TableRow>;
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </FtGrid>
            </FtPanel>
        </div>;
    }

    private reset() {
        this.setState({
            fileName: undefined,
            message: undefined,
            productList: undefined,
            isCreateMissCategory: false,
            reImportExistingImage: false,
        });
    }

    onFinishImage(fileUrl: string, fileName: string) {
        let imageDomainName = fileUrl.substring(0, fileUrl.lastIndexOf("/") + 1);
        this.setState({imageDomainName: imageDomainName});
    }

    private onFinishUpload(data: Array<FssProduct>, fileName: string) {
        this.setState({productList: data, fileName: fileName, appointFileName: new Date().getTime() + "_"});
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    private onClickImportNow() {
        if (this.state.productList && this.state.productList.length > 0) {
            FtToast.showToastLoading(i18n("开始导入"), 120000);
            let productList = this.setProductImageUrl(this.state.productList);
            FssProductService.saveProductList(productList, (data: string) => {
                FtToast.closeToast();
                this.setState({
                    message: data,
                    fileName: undefined,
                    imageDomainName: undefined,
                    appointFileName: undefined
                });
            });
        }
    }

    setProductImageUrl(productList: Array<FssProduct>) {
        productList.forEach((product: FssProduct) => {
            let domain = "";
            if (this.state.imageDomainName != null && this.state.imageDomainName.length > 0) {
                if (this.state.appointFileName != null && this.state.appointFileName.length > 0) {
                    domain = this.state.imageDomainName + this.state.appointFileName;
                } else {
                    domain = this.state.imageDomainName;
                }
            }
            if (product.coverImageUrl != null && product.coverImageUrl.length > 0
                && !product.coverImageUrl.startsWith("https://") && !product.coverImageUrl.startsWith("http://")) {
                product.coverImageUrl = domain + product.coverImageUrl;
            }
            if (product.productImage1 != null && product.productImage1.length > 0
                && !product.productImage1.startsWith("https://") && !product.productImage1.startsWith("http://")) {
                product.productImage1 = domain + product.productImage1;
            }
            if (product.productImage2 != null && product.productImage2.length > 0
                && !product.productImage2.startsWith("https://") && !product.productImage2.startsWith("http://")) {
                product.productImage2 = domain + product.productImage2;
            }
            if (product.productImage3 != null && product.productImage3.length > 0
                && !product.productImage3.startsWith("https://") && !product.productImage3.startsWith("http://")) {
                product.productImage3 = domain + product.productImage3;
            }
            if (product.productImage4 != null && product.productImage4.length > 0
                && !product.productImage4.startsWith("https://") && !product.productImage4.startsWith("http://")) {
                product.productImage4 = domain + product.productImage4;
            }
        })
        return productList;
    }
}

const StyledTableCell = withStyles(theme => ({
    root: {
        maxWidth: 200,
        wordWrap: "break-word"
    },
}))(TableCell);
