import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtSnackbar from "../../ui/FtSnackbar";
import {Add} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {Divider, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography} from "@material-ui/core";
import FtRow from "../../ui/FtRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FtPanel from "../../ui/FtPanel";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import lodash from "lodash";
import {i18n} from "../../util/I18n";
import {grey} from "@material-ui/core/colors";
import FtDivFlex from "../../ui/FtDivFlex";
import FssProductListCompData, {EProductSortType} from "../../page/component/data/FssProductListCompData";
import FssProductComboBox from "../../product/FssProductComboBox";
import FssProduct from "../../model/FssProduct";
import FssProductService from "../../service/FssProductService";

interface FssProductListSelectedProp {
    key: string;
    productListCompData: FssProductListCompData;
    onChangeComponent?: (productListCompData: FssProductListCompData) => void;
    onChangeDraggableFlag?: (draggableFlag: boolean)=>void;
}

export class FssProductListSelectedState {
    productSelectOptions: Array<FssProduct> = new Array<FssProduct>();
    product?: FssProduct;
    dragElement: FssProduct = new FssProduct();
    lock: boolean = true;
    openMessageBoxDialog: boolean = false;
    deleteProduct: FssProduct = new FssProduct();
}

export default class FssProductListSelected extends React.Component<FssProductListSelectedProp, FssProductListSelectedState> {

    constructor(props: any) {
        super(props);
        this.state = {
            productSelectOptions: new Array<FssProduct>(),
            product: new FssProduct(),
            dragElement: new FssProduct(),
            lock: true,
            openMessageBoxDialog: false,
            deleteProduct: new FssProduct()
        };
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onChangeComponent = this.onChangeComponent.bind(this);
        this.getMessageBoxDialog = this.getMessageBoxDialog.bind(this);
        this.onChangeSelectOptions = this.onChangeSelectOptions.bind(this);
        this.onChangeComponent=this.onChangeComponent.bind(this);
        this.getMessageBoxDialog=this.getMessageBoxDialog.bind(this);
        this.onChangeSelectOptions=this.onChangeSelectOptions.bind(this);
        this.onChangeDraggableFlag=this.onChangeDraggableFlag.bind(this);
    }

    componentDidMount(): void {
        if (this.props.productListCompData.productIdArray && this.props.productListCompData.productIdArray.length > 0) {
            FssProductService.getProductListByIdArray(this.props.productListCompData.productIdArray, (productList: Array<FssProduct>) => {
                this.setState({productSelectOptions: productList});
            });
        }
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    }

    componentWillUnmount() {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    }

    preventDefault = (e: Event) => {
        e.preventDefault();
    }

    onDragStart = (product: FssProduct) => {
        this.setState({dragElement: product});
        this.onChangeDraggableFlag(false);
    }

    onChangeDraggableFlag(flag: boolean){
        if(this.props.onChangeDraggableFlag){
            this.props.onChangeDraggableFlag(flag);
        }
    }

    onDragEnter = (product: FssProduct) => {
        let productId = product.id;
        if (productId !== this.state.dragElement.id) {
            const oldDragIndex = lodash.findIndex(this.state.productSelectOptions, (item: { id: string; }) => item.id === this.state.dragElement.id);
            const oldEnterIndex = lodash.findIndex(this.state.productSelectOptions, (item: { id: string; }) => item.id === product.id);
            if (oldDragIndex > oldEnterIndex) {
                const newDataArray = this.state.productSelectOptions.filter(item => item.id !== this.state.dragElement.id);
                const insertIndex = lodash.findIndex(newDataArray, (item: { id: string; }) => item.id === product.id);
                newDataArray.splice(insertIndex, 0, this.state.dragElement);
                this.onChangeSelectOptions(newDataArray);
            } else {
                const newDataArray = this.state.productSelectOptions.filter(item => item.id !== this.state.dragElement.id);
                const insertIndex = lodash.findIndex(newDataArray, (item: { id: string; }) => item.id === product.id) + 1;
                newDataArray.splice(insertIndex, 0, this.state.dragElement);
                this.onChangeSelectOptions(newDataArray);
            }
        }
    }

    onDragLeave = (product: FssProduct) => {
        if (product.id !== this.state.dragElement.id) {
            if (this.state.lock && product.id === (this.state.productSelectOptions[this.state.productSelectOptions.length - 1]).id) {
                const newDataArray = this.state.productSelectOptions.filter(item => item.id !== this.state.dragElement.id);
                newDataArray.push(this.state.dragElement);
                this.setState({lock: false,}, () => {
                    this.onChangeSelectOptions(newDataArray);
                });
            } else {
                this.setState({lock: true,});
            }
        }
    }

    onChangeProduct(item: any) {
        this.setState({product: item});
    }

    onChangeComponent(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        this.props.productListCompData.sortType = event.target.value;
        if (this.props.onChangeComponent)
            this.props.onChangeComponent(this.props.productListCompData);
        // 按热度麦序 => salesAmount
        if (event.target.value == EProductSortType.HEAT) {
            let productSelectOptions = lodash.sortBy(this.state.productSelectOptions, (product: FssProduct) => {
                return -product.salesAmount;
            });
            this.onChangeSelectOptions(productSelectOptions);
        }
    }

    getMessageBoxDialog(): ReactNode {
        let deleteProduct = this.state.deleteProduct;
        let productOptions = this.state.productSelectOptions;

        return <Dialog open={this.state.openMessageBoxDialog} onClose={(e) => {
            this.setState({openMessageBoxDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("确认")}</DialogTitle>
            <DialogContent>
                {i18n("确定要删除『{0}』吗?",deleteProduct.title)}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                }}>{i18n("取消")}</Button>
                <Button variant="outlined" color="primary" onClick={(e) => {
                    lodash.remove(productOptions, (item: { id: string; }) => item.id === deleteProduct.id);
                    this.setState({openMessageBoxDialog: false});
                    this.onChangeSelectOptions(productOptions);
                }}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }

    onChangeSelectOptions(productSelectOptions: Array<FssProduct>) {
        this.setState({productSelectOptions: productSelectOptions});
        let productIdArray = lodash.map(productSelectOptions, 'id');
        this.props.productListCompData.productIdArray = productIdArray;
        if (this.props.onChangeComponent)
            this.props.onChangeComponent(this.props.productListCompData);
    }

    render() {
        let mobileLineNum = this.props.productListCompData.mobileLineNum;
        let cellWidthS = "1";
        if(mobileLineNum==2)
            cellWidthS = "1,1";
        else if(mobileLineNum==3)
            cellWidthS = "1,1,1";
        return <div style={{minHeight: 150}} key={this.props.key}>
            <FtRow cellWidthM={"4,2,5"} style={{alignItems: "flex-end"}}>
                <FssProductComboBox label={i18n("商品")} onChangeProduct={this.onChangeProduct}
                                   selectedProduct={this.state.product} />
                <Button onClick={() => {
                    let productSelectOptions = this.state.productSelectOptions;
                    if (this.state.product != null && this.state.product.title != '') {
                        const checkExist = lodash.findIndex(productSelectOptions, (item: { id: string; }) => item.id === this.state.product?.id);
                        if (checkExist > -1)
                            FtSnackbar.showText(i18n("此商品已经添加过了"));
                        else{
                            productSelectOptions.push(this.state.product);
                            this.onChangeSelectOptions(productSelectOptions);
                        }
                    }
                }} color={"primary"} variant="outlined"><Add/>{i18n("添加")}</Button>
                <FormControl component="fieldset">
                    <RadioGroup row aria-label="position"
                                value={this.props.productListCompData.sortType}
                                onChange={this.onChangeComponent}>
                        <FormControlLabel value={EProductSortType.SPECIFIED} control={<Radio/>} label={i18n("指定顺序")}/>
                        <FormControlLabel value={EProductSortType.RANDOM} control={<Radio/>} label={i18n("随机顺序")}/>
                        <FormControlLabel value={EProductSortType.HEAT} control={<Radio/>} label={i18n("按热度")}/>
                    </RadioGroup>
                </FormControl>
            </FtRow>
            <FtPanel style={{width: "80%", paddingLeft: 5}}>
                {this.state.productSelectOptions.length > 0 &&
                <FormLabel component="legend" style={{width: "100%"}}>{i18n("已选商品")}</FormLabel>}
                <FtRow cellWidthM={cellWidthS} style={{alignItems: "end"}}>
                    {this.state.productSelectOptions.map((product: FssProduct, index: number) => {
                        return <div key={product.id}
                                    draggable={this.props.productListCompData.sortType == EProductSortType.SPECIFIED}
                                    onDragStart={() => {this.onDragStart(product)}}
                                    onDragEnd={(e) => {this.onChangeDraggableFlag(true);e.preventDefault();}}
                                    onDragEnter={() => {this.onDragEnter(product)}}
                                    onDragLeave={() => {this.onDragLeave(product)}}>
                            {cellWidthS.length > 1 && this.getManyColumnItem(product)}
                            {cellWidthS.length == 1 && this.getOneColumnItem(product)}
                        </div>
                    })}
                </FtRow>
            </FtPanel>
            {this.getMessageBoxDialog()}
        </div>;
    }

    getManyColumnItem(product: FssProduct): ReactNode {
        return <div style={{
            display: "flex", flexDirection: "column", textAlign: "left", backgroundColor: "white", borderRadius: 8,
            border: "1px solid #e9e9e9"
        }}>
            <img src={product.coverImageUrl}
                 style={{borderTopLeftRadius: 8, borderTopRightRadius: 8, width: "100%", display: "block"}} />
            <div style={{paddingLeft: 4, paddingRight: 4, paddingTop: 4, height: 26}}>
                <Typography variant="h6" style={{
                    fontSize: "80%", overflow: "hidden", textOverflow: "ellipsis", display: "-webkit-box",
                    "WebkitLineClamp": 2, "WebkitBoxOrient": "vertical", lineHeight: 1.1}}>
                    {product.title}
                </Typography>
            </div>
            <FtDivFlex justifyContent={"space-between"} style={{paddingLeft: 4, paddingRight: 4,flexWrap:"nowrap"}}>
                <Typography variant="h6" color={"primary"} style={{overflow: "hidden",whiteSpace: "nowrap"}}>
                    <small style={{fontSize: "60%", fontWeight: 100}}>$</small>
                    {product.salesPriceUsd != null ? product.salesPriceUsd.toFixed(2):""}
                    {(product.sellUnit && product.sellUnit.length > 0) ? <small  style={{fontSize: "60%"}}> / {product.sellUnit}</small> : <small></small>}
                    {product.regularPriceUsd != null && product.regularPriceUsd > 0 && product.regularPriceUsd - product.salesPriceUsd > 0 &&
                    <small style={{
                        fontSize: "60%",
                        fontWeight: 100,
                        color: grey[500],
                        textDecoration: "line-through"
                    }}>{product.regularPriceUsd.toFixed(2) + ""}</small>}
                </Typography>
                <div style={{textAlign: "right"}}>
                    <IconButton onClick={() => {
                        this.setState({openMessageBoxDialog: true, deleteProduct: product});
                    }}><DeleteIcon/>
                    </IconButton>
                </div>
            </FtDivFlex>
        </div>;
    }

    getOneColumnItem(product: FssProduct): ReactNode {
        return <FtDivFlex justifyContent={"space-between"} alignItems={"flex-start"} flexWrap={"nowrap"}
                          style={{paddingLeft: 8, paddingRight: 0, paddingTop: 4, width: "100%", boxSizing: "border-box"}}>
            <img alt="complex" src={product.coverImageUrl} style={{width: 96, borderRadius: 6,}}/>
            <FtDivFlex style={{flexGrow: 5, alignItems: "flex-start", paddingLeft: 8, width: "100%"}} direction={"column"}>
                <Typography style={{
                    height: 32, paddingRight: 8, width: "100%", boxSizing: "border-box",
                    fontSize: 14, lineHeight: "16px", overflow: "hidden", "WebkitLineClamp": 2, "WebkitBoxOrient": "vertical"
                }}>{product.title}</Typography>
                <Typography style={{height: 14, color: grey[500], paddingRight: 8, width: "100%", boxSizing: "border-box",
                    fontSize: 10, lineHeight: "14px", overflow: "hidden", "WebkitLineClamp": 1, "WebkitBoxOrient": "vertical"
                }}>{product.shortDescriptionCompact}</Typography>
                <FtRow justifyContent={"space-between"} style={{width: "100%",flexWrap:"nowrap"}}>
                    <Typography variant={"h6"} style={{fontSize: 20, fontWeight: 500, flexGrow: 2,lineHeight:1}} color={"primary"}>
                        <small style={{fontSize: "60%"}}> $ </small>
                        {product.salesPriceUsd != null ? product.salesPriceUsd.toFixed(2) : ""}
                        {(product.sellUnit && product.sellUnit.length > 0) ?
                            <small style={{fontSize: "60%"}}> / {product.sellUnit}</small> : <small></small>}
                        {product.regularPriceUsd != null && product.regularPriceUsd > 0 && product.regularPriceUsd - product.salesPriceUsd > 0 &&
                        <small style={{fontSize: "60%", fontWeight: 500, color: grey[500], textDecoration: "line-through", marginLeft: 4}}>
                            {product.regularPriceUsd.toFixed(2) + ""}</small>}
                    </Typography>
                    <div style={{textAlign: "right"}}>
                        <IconButton onClick={() => {
                            this.setState({openMessageBoxDialog: true, deleteProduct: product});
                        }}><DeleteIcon/>
                        </IconButton>
                    </div>
                </FtRow>
                <Divider style={{width: "100%", marginTop: 2}}/>
            </FtDivFlex>
        </FtDivFlex>;
    };
}
