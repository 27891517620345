import * as React from "react";
import {Button, Card, Checkbox, Chip, FormControlLabel, FormLabel} from "@material-ui/core";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtPanel from "../ui/FtPanel";
import FtRow from "../ui/FtRow";
import FtGrid from "../ui/FtGrid";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import FtState from "../ui/FtState";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {i18n} from "../util/I18n";
import FormControl from "@material-ui/core/FormControl";
import FtTextFieldDate from "../ui/FtTextFieldDate";
import FtRegion from "../model/FtRegion";
import {RouteComponentProps} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import _ from "lodash";
import DialogActions from "@material-ui/core/DialogActions";
import FssRegionService from "../service/FssRegionService";
import FssOrder, {EOrderStatus, EPaymentStatus, EPaymentType, EShippingType} from "../model/FssOrder";
import FssRegion from "../model/FssRegion";
import FssDeliveryProviderService from "../service/FssDeliveryProviderService";
import FssDeliveryProvider, {EDeliveryType} from "../model/FssDeliveryProvider";
import FssOrderService from "../service/FssOrderService";
import FssProduct from "../model/FssProduct";
import FtPagedList from "../model/FtPagedList";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import ChipInput from "material-ui-chip-input";
import FssOrderListPage, {EOrderPageType} from "./FssOrderListPage";
import FssMarketingEventComboBox from "../marketing/FssMarketingEventComboBox";
import FssMarketingEvent from "../model/FssMarketingEvent";
import FtMessageDialog from "../ui/FtMessageDialog";

interface FssOrderFindPageProp extends RouteComponentProps {

}

class FssOrderFindPageState extends FtComponentState {
    showOrderListPage: boolean = false;
    filterColumns: Map<string, any> = new Map<string, any>();
    orderCode: string = "";
    keyword: string = "";
    orderStatusArr: Array<EOrderStatus> = [];
    paymentStatusArr: Array<EPaymentStatus> = [];
    paymentTypeArr: Array<EPaymentType> = [];
    shipToMobile?: string = "";
    shipFromPhone?: string = "";
    orderUserId?: string = "";
    marketingEvent?: FssMarketingEvent;
    shipToAddress?: string = "";
    comment?: string = "";
    referral?: string = "";
    startDate?: string = "";
    endDate?: string = "";
    scheduleStartDate?: string = "";
    scheduleEndDate?: string = "";
    includeScheduleTimeIsNull: boolean = false;
    countryList: Array<FtRegion> = new Array<FtRegion>();
    checkedCountry: Array<string> = [];
    checkedProvince: Array<string> = [];
    checkedCity: Array<string> = [];
    shippingType: Array<EShippingType> = [];
    deliveryProviderIds: Array<string> = [];
    waitShippingFilter: Map<string, string> = new Map<string, string>();
    waitPayFilter: Map<string, string> = new Map<string, string>();
    waitApproveFilter: Map<string, string> = new Map<string, string>();
    shippedFilter: Map<string, string> = new Map<string, string>();
    product?: FssProduct;
    pickUp: Array<string> = [];
    openCategoryDialog: boolean = false;
    pickUpList: FssDeliveryProvider[] = [];
    deliveryToHomeValues: Array<string> = [];
    deliveryToHomeLabels: Array<string> = [];
    fssReginList: Array<FssRegion> = [];
    fssReginIdList: Array<string> = [];
}

export default class FssOrderFindPage extends FtComponent<FssOrderFindPageProp, FssOrderFindPageState> {
    constructor(props: FssOrderFindPageProp) {
        super(props);
        let stateKey: string = "FssOrderFindPage";
        const cacheState: FssOrderFindPageState = FtState.get(stateKey);
        if (cacheState != null) {
            this.state = cacheState;
        } else {
            const waitShippingFilter: Map<string, any> = new Map<string, any>();
            waitShippingFilter.set("orderStatus", [EOrderStatus.APPROVED, EOrderStatus.PICKING, EOrderStatus.PACKAGING]);
            const waitPayFilter: Map<string, any> = new Map<string, any>();
            waitPayFilter.set("paymentStatus", [EPaymentStatus.WAIT, EPaymentStatus.PARTIAL_PAID]);
            waitPayFilter.set("orderStatus", [EOrderStatus.CREATED, EOrderStatus.APPROVED, EOrderStatus.PICKING,
                EOrderStatus.PACKAGING, EOrderStatus.SHIPPED, EOrderStatus.DELIVERED]);
            const waitApproveFilter: Map<string, any> = new Map<string, any>();
            waitApproveFilter.set("orderStatus", [EOrderStatus.CREATED]);
            const shippedFilter: Map<string, any> = new Map<string, any>();
            shippedFilter.set("orderStatus", [EOrderStatus.SHIPPED]);

            this.state = {
                stateKey: stateKey,
                showOrderListPage: false,
                orderCode: "",
                keyword: "",
                orderStatusArr: [],
                paymentStatusArr: [],
                paymentTypeArr: [],
                countryList: [],
                checkedCountry: [],
                checkedProvince: [],
                checkedCity: [],
                shippingType: [],
                waitShippingFilter: waitShippingFilter,
                waitPayFilter: waitPayFilter,
                waitApproveFilter: waitApproveFilter,
                shippedFilter: shippedFilter,
                product: new FssProduct(),
                pickUp: [],
                openCategoryDialog: false,
                pickUpList: [],
                deliveryToHomeValues: [],
                deliveryToHomeLabels: [],
                deliveryProviderIds: [],
                fssReginList: [],
                fssReginIdList: [],
                filterColumns: new Map<string, any>(),
                includeScheduleTimeIsNull: false,
            };
        }
        this.findByOrderCode = this.findByOrderCode.bind(this);
        this.findByKeyword = this.findByKeyword.bind(this);
        this.findByGroups = this.findByGroups.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.onChangeProvince = this.onChangeProvince.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.resetGroups = this.resetGroups.bind(this);
        this.getMainFilterPanel = this.getMainFilterPanel.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.openPickUpDialog = this.openPickUpDialog.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.loadDeliveryProvider = this.loadDeliveryProvider.bind(this);
        this.onChangeMarketingEvent = this.onChangeMarketingEvent.bind(this);
    }

    componentDidMount(): void {
        this.setState({countryList: []}, () => {
            this.loadDeliveryProvider();
            this.loadFssRigion();
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (window.location.href.indexOf("/orderDetail/") === -1) {
            FtState.pop("FssOrderFindPage");
        }
    }

    loadFssRigion() {
        let filterColumns = new Map<string, any>();
        filterColumns.set("status", "NORMAL");
        FssRegionService.getPageRegionList("", filterColumns, null, null, 0, 999,
            (result: FtPagedList<FssRegion>) => {
                FtToast.closeToast();
                this.setState({fssReginList: result.content});
                this.forceUpdate();
            });
    }

    loadDeliveryProvider() {
        FssDeliveryProviderService.getPageDeliveryProviderList("", new Map<string, any>(), null, null, 0, 999,
            (result: FtPagedList<FssDeliveryProvider>) => {
                const data = result.content;
                const pickUpList: FssDeliveryProvider[] = [];
                const deliveryToHomeLabels: Array<string> = [];
                const deliveryToHomeValues: Array<string> = [];
                if (data != null) {
                    data.forEach((item: FssDeliveryProvider) => {
                        if (item.deliveryType == EDeliveryType.SELF_PICK_UP) {
                            pickUpList.push(item);
                        }
                        if (item.deliveryType == EDeliveryType.DELIVERY_TO_DOOR) {
                            deliveryToHomeLabels.push(item.deliveryProviderName)
                            deliveryToHomeValues.push(item.id);
                        }
                    });
                    this.setState({
                        pickUpList: pickUpList,
                        deliveryToHomeLabels: deliveryToHomeLabels,
                        deliveryToHomeValues: deliveryToHomeValues,
                    });
                }
            });
    }

    onChangeCountry(checkedValue: Array<string>) {
        this.setState({checkedCountry: checkedValue});
    }

    onChangeProvince(checkedValue: Array<string>) {
        this.setState({checkedProvince: checkedValue});
    }

    onChangeCity(checkedValue: Array<string>) {
        this.setState({checkedCity: checkedValue});
    }

    findByOrderCode() {
        let filterColumns = new Map<string, any>();
        filterColumns.set("orderCode", this.state.orderCode);
        this.loadData(filterColumns);
    }

    findByKeyword() {
        let filterColumns = new Map<string, any>();
        filterColumns.set("searchKeyword", this.state.keyword);
        this.loadData(filterColumns);
    }

    findByGroups() {
        let filterColumns = new Map<string, any>();
        let productId = undefined;
        if (this.state.product && this.state.product.id && this.state.product.id.length > 0) {
            productId = this.state.product.id;
        }
        let marketingEventId = undefined;
        if (this.state.marketingEvent && this.state.marketingEvent.id && this.state.marketingEvent.id.length > 0) {
            marketingEventId = this.state.marketingEvent.id;
        }
        // 判断时间顺序
        if (this.state.scheduleStartDate && this.state.scheduleEndDate && this.state.scheduleEndDate < this.state.scheduleStartDate) {
            FtMessageDialog.dialog.openDialog("【预约配送结束时间】不能小于【预约配送开始时间】");
            return;
        }
        if (this.state.startDate && this.state.endDate && this.state.endDate < this.state.startDate) {
            FtMessageDialog.dialog.openDialog("【下单结束时间】不能小于【下单开始时间】");
            return;
        }
        filterColumns.set("orderStatus", this.state.orderStatusArr);
        filterColumns.set("paymentStatus", this.state.paymentStatusArr);
        filterColumns.set("paymentType", this.state.paymentTypeArr);
        filterColumns.set("shipToMobile", this.state.shipToMobile);
        filterColumns.set("shipFromMobile", this.state.shipFromPhone);
        filterColumns.set("orderUserId", this.state.orderUserId);
        filterColumns.set("marketingEventId", marketingEventId);
        filterColumns.set("shipToAddress", this.state.shipToAddress);
        filterColumns.set("comment", this.state.comment);
        filterColumns.set("referral", this.state.referral);
        filterColumns.set("productId", productId);
        filterColumns.set("startDate", this.state.startDate);
        filterColumns.set("endDate", this.state.endDate);
        filterColumns.set("scheduleStartDate", this.state.scheduleStartDate);
        filterColumns.set("scheduleEndDate", this.state.scheduleEndDate);
        filterColumns.set("country", this.state.checkedCountry);
        filterColumns.set("province", this.state.checkedProvince);
        filterColumns.set("city", this.state.checkedCity);
        filterColumns.set("shippingType", this.state.shippingType);
        filterColumns.set("deliveryProviderIds", this.state.deliveryProviderIds);
        filterColumns.set("fssReginId", this.state.fssReginIdList);
        filterColumns.set("includeScheduleTimeIsNull", this.state.includeScheduleTimeIsNull);
        let pickUpCode: Array<string> = [];
        this.state.pickUp.forEach(value => {
            let find = _.find(this.state.pickUpList, {'deliveryProviderName': value});
            if (find) {
                pickUpCode.push(find.id);
            }
        });
        filterColumns.set("pickUp", pickUpCode);
        this.loadData(filterColumns);
    }

    resetGroups() {
        this.setState({
            orderStatusArr: [],
            paymentStatusArr: [],
            paymentTypeArr: [],
            shipToMobile: "",
            shipFromPhone: "",
            orderUserId: "",
            marketingEvent: undefined,
            shipToAddress: "",
            comment: "",
            referral: "",
            product: undefined,
            startDate: "",
            endDate: "",
            scheduleStartDate: "",
            scheduleEndDate: "",
            checkedCountry: [],
            checkedProvince: [],
            checkedCity: [],
            shippingType: [],
            pickUp: [],
            fssReginIdList: [],
        });
    }

    loadData(filterColumns: Map<string, any>) {
        FtToast.showToastLoading("loading");
        FssOrderService.getStoreOrderList("", filterColumns, null, null, 0, 1,
            (result: FtPagedList<FssOrder>) => {
                FtToast.closeToast();
                if (result.content.length > 0) {
                    this.setState({filterColumns: filterColumns, showOrderListPage: true});
                } else {
                    FtSnackbar.showText(i18n("没有搜索到结果"));
                }
            });
    }

    onChangeProduct(item: any) {
        this.setState({product: item});
    }

    onChangeMarketingEvent(item: any) {
        this.setState({marketingEvent: item});
    }

    onBack() {
        this.setState({showOrderListPage: false});
    }

    render() {
        if (this.state.showOrderListPage) {
            return <FssOrderListPage key={this.props.location.pathname}
                                     history={this.props.history}
                                     location={this.props.location}
                                     match={this.props.match}
                                     orderPageType={EOrderPageType.FIND_ORDER}
                                     filterColumns={this.state.filterColumns}
                                     isProductDetailPage={false}
                                     onBack={this.onBack}/>;
        } else {
            return this.getMainFilterPanel();
        }
    }

    openPickUpDialog() {
        return <Dialog maxWidth={"md"} open={this.state.openCategoryDialog}
                       onClose={(e) => {
                           this.setState({openCategoryDialog: false});
                       }}
                       scroll={"paper"}>
            <DialogContent>
                <FtRow cellHorizontalSpace={16}>
                    {this.state.pickUpList.map(data => {
                        let targetIndex = -1;
                        if (this.state.pickUp) {
                            targetIndex = _.findIndex(this.state.pickUp, (tag: string) => {
                                return data.deliveryProviderName == tag;
                            });
                        }
                        return <Chip color={targetIndex > -1 ? "secondary" : "default"}
                                     label={data.deliveryProviderName}
                                     onClick={() => {
                                         if (targetIndex > -1) {
                                             this.handleDeleteChip(data.deliveryProviderName);
                                         } else {
                                             this.handleAddChip(data.deliveryProviderName);
                                         }
                                     }}/>;
                    })}
                </FtRow>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={(e) => {
                    this.setState({openCategoryDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    handleAddChip(chip: string) {
        let pickUpList: Array<string> = this.state.pickUp;
        let index = pickUpList.indexOf(chip);
        if (index == -1) {
            pickUpList.push(chip);
            this.setState({pickUp: pickUpList});
        }

    }

    handleDeleteChip(chip: string) {
        let pickUpList: Array<string> = this.state.pickUp;
        let index = pickUpList.indexOf(chip);
        if (index > -1) {
            pickUpList.splice(index, 1);
            this.setState({pickUp: pickUpList});
        }

    }

    getMainFilterPanel() {
        let fssReginIds = new Array<string>();
        let fssReginNames = new Array<string>();
        this.state.fssReginList.forEach((region: FssRegion) => {
            fssReginIds.push(region.id);
            fssReginNames.push(region.regionName);
        });
        return <FtPanel>
            <FtGrid>
                <FtRow cellWidthS={"1,1.5"}>
                    <Card variant={"outlined"}>
                        <CardContent>
                            <Typography variant="h6">{i18n("订单号查找")}</Typography>
                            <FtRow cellWidthS={"3,1"} style={{alignItems: "flex-end"}}>
                                <FtTextFieldString label={i18n("订单号")}
                                                   onChangeValue={(value?: string) => {
                                                       if (value != null) {
                                                           this.setState({orderCode: value});
                                                       }
                                                   }}
                                                   value={this.state.orderCode} style={{width: "100%"}}
                                                   onKeyDownEnter={this.findByOrderCode}/>
                                <Button variant={"outlined"} color={"primary"}
                                        onClick={this.findByOrderCode}>{i18n("查找订单")}</Button>
                            </FtRow>
                        </CardContent>
                    </Card>
                    <Card variant={"outlined"}>
                        <CardContent>
                            <Typography variant="h6">{i18n("关键字查找")}</Typography>
                            <FtRow cellWidthS={"3,1"} style={{alignItems: "flex-end"}}>
                                <FtTextFieldString label={i18n("收件人/收件人证件号码/发件人/发件人地址/备注")}
                                                   onChangeValue={(value?: string) => {
                                                       if (value != null) {
                                                           this.setState({keyword: value});
                                                       }
                                                   }}
                                                   value={this.state.keyword} style={{width: "100%"}}
                                                   onKeyDownEnter={this.findByKeyword}/>
                                <Button variant={"outlined"} color={"primary"}
                                        onClick={this.findByKeyword}>{i18n("查找订单")}</Button>
                            </FtRow>
                        </CardContent>
                    </Card>
                </FtRow>
            </FtGrid>
            <FtGrid style={{paddingRight: 16}}>
                <FtRow>
                    <Card variant={"outlined"}>
                        <CardContent>
                            <Typography variant="h6">{i18n("组合查找")}</Typography>
                            <FtRow cellWidthS={"1"}>
                                <FormControl style={{minWidth: 400}}>
                                    <FormLabel> {i18n("Order Status ")}</FormLabel>
                                    <FtCheckboxGroup row={true}
                                                     values={[EOrderStatus.CREATED, EOrderStatus.APPROVED, EOrderStatus.PICKING, EOrderStatus.PACKAGING,
                                                         EOrderStatus.SHIPPED, EOrderStatus.DELIVERED, EOrderStatus.CANCELED, EOrderStatus.RETURNED, EOrderStatus.DELETED, EOrderStatus.CLOSED]}
                                                     labels={[i18n("已创建"), i18n("审核通过"), i18n("拣货"), i18n("打包"), i18n("发货"), i18n("送达完成"), i18n("已取消"), i18n("已退货"), i18n("已删除"), i18n("已关闭")]}
                                                     checkedValues={this.state.orderStatusArr}
                                                     onCheck={(checkedValue) => {
                                                         this.setState({orderStatusArr: checkedValue});
                                                     }}>
                                    </FtCheckboxGroup>
                                </FormControl>
                                <FormControl style={{minWidth: 400}}>
                                    <FormLabel> {i18n("支付状态")}</FormLabel>
                                    <FtCheckboxGroup row={true}
                                                     values={[EPaymentStatus.WAIT, EPaymentStatus.PARTIAL_PAID, EPaymentStatus.PAID, EPaymentStatus.OVER_PAID, EPaymentStatus.REFUND]}
                                                     labels={[i18n("未支付金额"), i18n("部分支付"), i18n("已支付"), i18n("超额支付"), i18n("退款")]}
                                                     checkedValues={this.state.paymentStatusArr}
                                                     onCheck={(checkedValue) => {
                                                         this.setState({paymentStatusArr: checkedValue});
                                                     }}>
                                    </FtCheckboxGroup>
                                </FormControl>
                                {/*<FormControl style={{minWidth: 400}}>*/}
                                {/*    <FormLabel> {i18n("支付方式")} </FormLabel>*/}
                                {/*    <FtCheckboxGroup row={true}*/}
                                {/*                     values={[*/}
                                {/*                         EPaymentType.OFFLINE_COD, EPaymentType.ONLINE_ACCOUNT_BALANCE,*/}
                                {/*                         EPaymentType.PAY_AT_STORE, EPaymentType.OFFLINE_TRANSFER]}*/}
                                {/*                     labels={[i18n("线下支付"), i18n("账户余额"), i18n("到店付款"), i18n("其他")]}*/}
                                {/*                     checkedValues={this.state.paymentTypeArr}*/}
                                {/*                     onCheck={(checkedValue) => {*/}
                                {/*                         this.setState({paymentTypeArr: checkedValue});*/}
                                {/*                     }}>*/}
                                {/*    </FtCheckboxGroup>*/}
                                {/*</FormControl>*/}
                                <FormControl style={{minWidth: 400}}>
                                    <FtRow>
                                        <FormLabel> {i18n("配送服务地区")}</FormLabel>
                                    </FtRow>
                                    <FtCheckboxGroup row={true}
                                                     values={fssReginIds}
                                                     labels={fssReginNames}
                                                     checkedValues={this.state.fssReginIdList}
                                                     onCheck={(checkedValue) => {
                                                         this.setState({fssReginIdList: checkedValue});
                                                     }}>
                                    </FtCheckboxGroup>
                                </FormControl>
                                <FormControl style={{minWidth: 400}}>
                                    <FormLabel> {i18n("自取点")} </FormLabel>
                                    <FtRow>
                                        <Button color="secondary" variant="outlined" size={"small"}
                                                onClick={() => {
                                                    this.setState({
                                                        openCategoryDialog: true
                                                    });
                                                }}>{i18n("选择")}</Button>
                                        <ChipInput variant={"standard"}
                                                   value={this.state.pickUp}
                                                   style={{width: "100%"}}
                                                   onDelete={(chip, index) => this.handleDeleteChip(chip)}/>
                                    </FtRow>
                                </FormControl>
                                <FormControl style={{minWidth: 400}}>
                                    <FormLabel> {i18n("配送方式")} </FormLabel>
                                    <FtCheckboxGroup row={true}
                                                     values={[EShippingType.DELIVERY_TO_HOME, EShippingType.PICK_UP]}
                                                     labels={[i18n("送货上门"), i18n("自取")]}
                                                     checkedValues={this.state.shippingType}
                                                     onCheck={(checkedValue) => {
                                                         this.setState({shippingType: checkedValue});
                                                     }}>
                                    </FtCheckboxGroup>
                                </FormControl>
                                <FormControl style={{minWidth: 400}}>
                                    <FormLabel> {i18n("配送商")} </FormLabel>
                                    <FtCheckboxGroup row={true}
                                                     values={this.state.deliveryToHomeValues}
                                                     labels={this.state.deliveryToHomeLabels}
                                                     checkedValues={this.state.deliveryProviderIds}
                                                     onCheck={(checkedValue) => {
                                                         this.setState({deliveryProviderIds: checkedValue});
                                                     }}>
                                    </FtCheckboxGroup>
                                </FormControl>
                            </FtRow>
                            <FtRow cellWidthS={"1,1,1"}>
                                <FtTextFieldString label={i18n("收件人手机")} onChangeValue={(value) => {
                                    this.setState({shipToMobile: value});
                                }} value={this.state.shipToMobile} style={{width: "100%"}}/>
                                <FtTextFieldString label={i18n("发件人手机")} onChangeValue={(value) => {
                                    this.setState({shipFromPhone: value});
                                }} value={this.state.shipFromPhone} style={{width: "100%"}}/>
                                <FtTextFieldString label={i18n("用户ID")} onChangeValue={(value) => {
                                    this.setState({orderUserId: value});
                                }} value={this.state.orderUserId} style={{width: "100%"}}/>
                                <FtTextFieldString label={i18n("Address ")} onChangeValue={(value) => {
                                    this.setState({shipToAddress: value});
                                }} value={this.state.shipToAddress} style={{width: "100%"}}/>
                                <FtTextFieldString label={i18n("评论")} onChangeValue={(value) => {
                                    this.setState({comment: value});
                                }} value={this.state.comment} style={{width: "100%"}}/>
                                <FtTextFieldString label={i18n("推广来源")} onChangeValue={(value) => {
                                    this.setState({referral: value});
                                }} value={this.state.referral} style={{width: "100%"}}/>
                                <FssMarketingEventComboBox label={i18n("营销活动名称")}
                                                           onChange={this.onChangeMarketingEvent}
                                                           marketingEvent={this.state.marketingEvent}/>
                            </FtRow>
                            <FtRow cellWidthS={"1,1,1,1,1"}>
                                <FtTextFieldDate id="startDate"
                                                 label={i18n("下单开始时间")}
                                                 style={{width: "100%"}}
                                                 value={this.state.startDate}
                                                 InputLabelProps={{shrink: true,}}
                                                 onChangeValue={(value) => {
                                                     this.setState({startDate: value});
                                                 }}/>
                                <FtTextFieldDate id="endDate"
                                                 label={i18n("预约配送结束时间")}
                                                 style={{width: "100%"}}
                                                 value={this.state.endDate}
                                                 InputLabelProps={{shrink: true,}}
                                                 onChangeValue={(value) => {
                                                     this.setState({endDate: value});
                                                 }}/>
                                <FtTextFieldDate id="scheduleStartDate"
                                                 label={i18n("预约配送开始时间")}
                                                 style={{width: "100%"}}
                                                 value={this.state.scheduleStartDate}
                                                 InputLabelProps={{shrink: true,}}
                                                 onChangeValue={(value) => {
                                                     this.setState({scheduleStartDate: value});
                                                 }}/>
                                <FtTextFieldDate id="scheduleEndDate"
                                                 label={i18n("预约配送结束时间")}
                                                 style={{width: "100%"}}
                                                 value={this.state.scheduleEndDate}
                                                 InputLabelProps={{shrink: true,}}
                                                 onChangeValue={(value) => {
                                                     this.setState({scheduleEndDate: value});
                                                 }}/>
                                <FormControlLabel control={
                                    <Checkbox style={{marginLeft: "40"}} checked={this.state.includeScheduleTimeIsNull}
                                              onChange={(event, checked) => {
                                                  this.setState({includeScheduleTimeIsNull: checked})
                                                  this.forceUpdate();
                                              }}/>} label={i18n("包含配送时间为空的订单")}/>
                            </FtRow>
                            <FtRow style={{paddingTop: 10}}>
                                <Button variant={"outlined"} onClick={this.resetGroups}>{i18n("重置")}</Button>
                                <Button variant={"outlined"} color={"primary"}
                                        onClick={this.findByGroups}>{i18n("查找订单")}</Button>
                            </FtRow>
                        </CardContent>
                    </Card>
                </FtRow>
            </FtGrid>
            {this.openPickUpDialog()}
        </FtPanel>;
    }


}
