import * as React from "react";
import FtToast from "../ui/FtToast";
import {Button, Divider, Typography} from "@material-ui/core";
import {ChevronLeft, Print} from "@material-ui/icons";
import {RouteComponentProps} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {i18n} from "../util/I18n";
import FtRow from "../ui/FtRow";
import {grey} from "@material-ui/core/colors";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FssSingleOrderPrint from "./FssSingleOrderPrint";
import FtToolbar from "../ui/FtToolbar";
import FtPagedList from "../model/FtPagedList";
import _ from "lodash";
import AppClientInfo from "../data/AppClientInfo";
import FtUtil from "../util/FtUtil";
import FssMarketingEventSingleOrder from "../model/FssMarketingEventSingleOrder";
import FssOrder from "../model/FssOrder";
import FssMarketingEventService from "../service/FssMarketingEventService";
import FssSingleOrder from "../model/FssSingleOrder";
import FssClientData from "../data/FssClientData";
import FssOrderService from "../service/FssOrderService";
import {CSVLink} from "react-csv";
import {EDeliveryType} from "../model/FssDeliveryProvider";

export interface FssShippedSumOrderPageProp extends RouteComponentProps<{ id: string }> {
}

class FssShippedSumOrderPageState extends FtComponentState {
    orderList: Array<FssMarketingEventSingleOrder> = new Array();
    order: FssMarketingEventSingleOrder = new FssMarketingEventSingleOrder();
    csvData: Array<FssOrder> = [];
    csvHeader: Array<any> = [];
    orderIds: Array<string> = []
}


export default class FssShippedSumOrderPage extends FtComponent<FssShippedSumOrderPageProp, FssShippedSumOrderPageState> {
    print!: FssSingleOrderPrint | null;
    csvLink!: any;

    constructor(props: any) {
        super(props);

        this.state = {
            orderList: [], order: new FssMarketingEventSingleOrder(), csvData: [],
            csvHeader: [], orderIds: []
        };
        this.onCancel = this.onCancel.bind(this);
        this.onPrintOrderList58 = this.onPrintOrderList58.bind(this);
        this.onClickDownloadCsv = this.onClickDownloadCsv.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    loadData(): void {
        if (this.props.match.params.id && +this.props.match.params.id.length > 1) {
            FtToast.showToastLoading("loading");
            FssMarketingEventService.getSingleOrder(this.props.match.params.id,
                (result: Array<FssMarketingEventSingleOrder>) => {
                    FtToast.closeToast();
                    this.setState({orderList: result});
                });
        }
    }

    onCancel() {
        this.props.history.goBack();
    }

    render() {
        return <div style={{position: "relative"}}>
            <FtToolbar leftNode={<Button onClick={this.onCancel}><ChevronLeft/>{i18n("返回")}</Button>}
                       centerNode={<Typography variant="h6">{i18n("个别发货单")}</Typography>}
                       rightNode={<Button variant="contained" onClick={() => {
                           this.onPrintOrderList58()
                       }}><Print/>打印全部</Button>}/>
            {this.state.orderList.map((order: FssMarketingEventSingleOrder) => {
                return <div><FtPanel>
                    <FtGrid>
                        <FtRow style={{marginLeft: 20}} cellWidthS={"1,1"} cellVerticalSpace={8}
                               cellHorizontalAlign={"right"}>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellVerticalSpace={8}
                               cellHorizontalAlign={"left"}>
                            <FtRow>
                                <Typography
                                    style={{wordBreak: "break-all"}}>{"发货时间：" + order.scheduleDatetime}</Typography>
                                <div>
                                    <Button variant="contained" onClick={() => {
                                        this.setState({order: order}, () => {
                                            this.onClickDownloadCsv();
                                        });
                                    }}>导出订单</Button>
                                    <CSVLink data={this.state.csvData} headers={this.state.csvHeader}
                                             filename={i18n("订单列表.csv")}
                                             ref={(element: any) => {
                                                 this.csvLink = element
                                             }}>
                                    </CSVLink>
                                </div>
                                <Button variant="contained" onClick={() => {
                                    let orderIds = new Array();
                                    order.orderItemList.forEach((order, index, array) => {
                                        orderIds.push(order.orderId);
                                    })
                                    this.setState({orderIds: orderIds}, () => {
                                        this.printOrder()
                                    })
                                }}>打印订单</Button>
                            </FtRow>

                            <Typography
                                style={{wordBreak: "break-all"}}>{"团购标题：" + order.marketingEventName}</Typography>
                            <Typography
                                style={{wordBreak: "break-all"}}>{(order.deliveryType == EDeliveryType.SELF_PICK_UP ? "取货点：" : "配送商：") + order.deliveryProviderName}</Typography>
                        </FtRow>
                        <FtRow cellWidthS={"1,2,1,4,1"} style={{backgroundColor: grey[400]}}>
                            <Typography>{"订单号"}</Typography>
                            <Typography>{"联系人"}</Typography>
                            <Typography>{"支付"}</Typography>
                            <Typography>{"品项"}</Typography>
                            <Typography>{"备注"}</Typography>
                        </FtRow>
                        {order.orderItemList.map((item: FssSingleOrder, index) => {
                            return <FtRow cellWidthS={"1,2,1,4,1"} style={{
                                backgroundColor: index % 2 == 0 ? "white" : grey[100]
                            }} cellVerticalAlign={"top"}>
                                <Typography style={{wordBreak: "break-all"}}>{item.orderCode}</Typography>
                                <Typography style={{wordBreak: "break-all"}}>{item.contactMobile}</Typography>
                                <Typography
                                    style={{wordBreak: "break-all"}}>{item.paymentStatusText}<br/>{item.paymentTypeText}
                                </Typography>
                                <Typography style={{wordBreak: "break-all"}}
                                            dangerouslySetInnerHTML={{__html: item.productName}}></Typography>
                                <Typography style={{wordBreak: "break-all"}}>{item.userComment}</Typography>
                            </FtRow>
                        })}

                    </FtGrid>
                </FtPanel>
                    <Divider></Divider>
                </div>
            })}

            <FssSingleOrderPrint ref={(element) => this.print = element} orderList={this.state.orderList}/>
            <div>
                <form id={"printOrderLetterSizeForm"} target={"_blank"} method={"POST"}
                      action={AppClientInfo.clientInfo.apiServerUrl + "/api/print/batchPrintOrderList?" + FssClientData.getData().webSession.dataString}>
                    <input type={"hidden"} name={"orderIds"} value={FtUtil.arrayToString(this.state.orderIds)}></input>
                </form>
            </div>
        </div>
    }

    printOrder() {
        const form: any = window.document.getElementById("printOrderLetterSizeForm");
        if (form)
            form.submit();

    }

    onPrintOrderList58() {
        if (this.print) {
            window.setTimeout(() => {
                if (this.print)
                    this.print.print();
            }, 500);

        }
    }

    private onClickDownloadCsv() {
        let headers = [
            {label: i18n("订单编码"), key: "orderCode"},
            {label: i18n("是否子订单"), key: "isChildOrder"},
            {label: i18n("Order Status "), key: "orderStatusText"},
            {label: i18n("支付状态"), key: "paymentStatusText"},
            {label: i18n("支付方式"), key: "paymentTypeText"},
            {label: i18n("收件人"), key: "shipToName"},
            {label: i18n("收件人手机"), key: "shipToMobile"},
            {label: i18n("配送方式"), key: "shippingTypeText"},
            {label: i18n("收货城市"), key: "shipToCity"},
            {label: i18n("Address "), key: "shipToAddress"},
            {label: i18n("订单金额"), key: "total"},
            {label: i18n("已支付金额"), key: "totalPaid"},
            {label: i18n("商品总金额"), key: "subtotalProduct"},
            {label: i18n("品项"), key: "itemDesc"},
            {label: i18n("配送费"), key: "shippingFee"},
            {label: i18n("包装费"), key: "packageFee"},
            {label: i18n("手续费"), key: "paymentFee"},
            {label: i18n("折扣"), key: "discount"},
            {label: i18n("小费"), key: "tips"},
            {label: i18n("预约配送时间"), key: "scheduleDatetimeText"},
            {label: i18n("下单时间"), key: "createDatetimeText"},
            {label: i18n("用户ID"), key: "userId"},
            {label: i18n("推广来源"), key: "referral"}
        ];
        if (headers.length > 0) {
            FtToast.showToastLoading(i18n("正在加载，数据量较大时可能需几分钟"), 60000);
            let filterColumns = new Map<string, any>();
            let orderIds = new Array();
            this.state.order.orderItemList.forEach((order, index, array) => {
                orderIds.push(order.orderId);
            })
            filterColumns.set("orderIds", orderIds);
            FssOrderService.getStoreOrderList("", filterColumns,
                null, null, 0, 999999, (result: FtPagedList<FssOrder>) => {
                    FtToast.closeToast();
                    //把订单按照订单号排序，把子单和主单排在一起
                    result.content = _.sortBy(result.content, (order: FssOrder) => {
                        if (order.parentOrderId != null && order.parentOrderId.length > 0) {
                            return order.parentOrderId;
                        } else {
                            return order.id;
                        }
                    });
                    const data: Array<any> = [];
                    result.content.forEach((order, index: number) => {
                        const rowData: any = {};
                        rowData.orderCode = order.orderCode;
                        rowData.isChildOrder = order.parentOrderId != null && order.parentOrderId.length > 0;
                        rowData.orderStatusText = i18n(order.statusText);
                        rowData.paymentStatusText = i18n(order.paymentStatusText);
                        rowData.paymentTypeText = i18n(order.paymentTypeText);
                        rowData.shipToName = order.shipToName;
                        rowData.shipToMobile = order.shipToMobile;
                        rowData.shippingTypeText = order.shippingTypeText;
                        rowData.shipToCity = order.shipToCity;
                        rowData.shipToAddress = order.shipToAddress;
                        rowData.total = order.total;
                        rowData.totalPaid = order.totalPaid;
                        rowData.subtotalProduct = order.subtotalProduct;
                        rowData.itemDesc = order.items.map(value => {
                            return value.productName + " " + value.price + " x " + value.quantity + "\n";
                        });
                        rowData.shippingFee = order.shippingFee;
                        rowData.packageFee = order.packageFee;
                        rowData.paymentFee = order.paymentFee;
                        rowData.totalDiscount = order.discount;
                        rowData.tips = order.tips;
                        rowData.scheduleDatetimeText = order.scheduleDatetimeText;
                        rowData.createDatetimeText = FssClientData.formatDatetime(order.createDatetime);
                        rowData.userId = order.userId;
                        rowData.referral = order.referral;
                        data.push(rowData);
                    });
                    this.setState({csvData: data, csvHeader: headers}, () => {
                        if (this.csvLink && this.csvLink.link)
                            this.csvLink.link.click();
                    });
                });
        }
    }
}
