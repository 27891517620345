import {FtSortDirection} from "../model/FtSortDirection";

export enum ESearchRange {
    APP = "APP",
    STORE = "STORE"
}

export default class FssProductSearchCriteria {
    keyword?: string = "";
    sellerId?: string = "";
    sortBy?: Array<JtSortByItem> = [];
    pageNo?: number = 0;
    pageSize?: number = 20;
    range: ESearchRange = ESearchRange.STORE;
    tags?:Array<string>;
    categoryIds?:Array<string>;
    brands?:Array<string>;
    productIds?:Array<string>;
    marketingEventId?: string = "";

    constructor(sellerId?: string, categoryId?: string, keyword?: string) {
        if (sellerId != null && sellerId.trim().length>0) {
           this.sellerId =sellerId;
        }
        if (categoryId != null && categoryId.length > 0)
            this.categoryIds = [categoryId];
        if (keyword != null && keyword.trim().length > 0)
            this.keyword = keyword.trim();
    }

    getUri(): string {
        let uri: string = "/search";
        if (this.range == ESearchRange.APP)
            uri += "/app";
        else if (this.sellerId != null)
            uri += "/" + this.sellerId;
        else
            uri += "/0";
        if (this.categoryIds != null&&this.categoryIds.length>0)
            uri += "/" + this.categoryIds[0];
        else
            uri += "/0";
        if (this.keyword != null)
            uri += "/" + this.keyword;
        return uri;
    }

    static isEmpty(searchCriteria: FssProductSearchCriteria) {
        // if (searchCriteria.range == null || searchCriteria.range == ESearchRange.STORE)
        //     return (searchCriteria.keyword == null || searchCriteria.keyword.trim() == "")
        //         && (searchCriteria.storeId == null || searchCriteria.storeId == 0)
        //         && (searchCriteria.categoryId == null || searchCriteria.categoryId == 0);
        // else
            return (searchCriteria.keyword == null || searchCriteria.keyword.trim() == "")
                && (searchCriteria.categoryIds == null || searchCriteria.categoryIds.length == 0)
                && (searchCriteria.tags == null || searchCriteria.tags.length == 0)
                && (searchCriteria.brands == null || searchCriteria.brands.length == 0)
                && (searchCriteria.productIds == null || searchCriteria.productIds.length == 0);
    }
}

export interface JtSortByItem {
    name: string;
    order: FtSortDirection;
}

