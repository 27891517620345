import * as React from "react";
import {Box, ButtonBase, InputAdornment, TextField} from "@material-ui/core";
import {BaseTextFieldProps} from "@material-ui/core/TextField";
import {OutlinedInputProps} from "@material-ui/core/OutlinedInput";
import {FilledInputProps} from "@material-ui/core/FilledInput";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";
import {i18n} from "../util/I18n";
import FtPhoneCodeUtil from "../util/FtPhoneCodeUtil";
import FtFlagIconCA from "./FtFlagIconCA";
import FtFlagIconCN from "./FtFlagIconCN";
import FtFlagIconUS from "./FtFlagIconUS";
import FtOptionDialog, {FtOptionDialogItem} from "./FtOptionDialog";
import {ReactNode} from "react";

interface FtTextFieldPhoneProps extends BaseTextFieldProps {
    helperText?: string;
    originalValue?: unknown;
    variant?: "standard" | "filled" | "outlined";
    onChangeValue?: (value?: string) => void;
    onBlur?: StandardInputProps['onBlur'] | FilledInputProps["onBlur"] | OutlinedInputProps["onBlur"];
    onFocus?: StandardInputProps['onFocus'] | FilledInputProps["onFocus"] | OutlinedInputProps["onFocus"];
    value?: string;
    allowCountryIOSCodes?: Array<string>;
    endAdornment?: ReactNode;
    showHelperText?: boolean;
}

class FtTextFieldPhoneState {
    countryCode: string = "ca";
}

const charPhone = new RegExp("^[+][0-9]*$");

export default class FtTextFieldPhone extends React.Component<FtTextFieldPhoneProps, FtTextFieldPhoneState> {
    constructor(props: any) {
        super(props);
        let countryCode: string = "ca";
        if (this.props.allowCountryIOSCodes != null && this.props.allowCountryIOSCodes.length > 0)
            countryCode = this.props.allowCountryIOSCodes[0];
        this.state = {countryCode: countryCode};
        this.formatValue = this.formatValue.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeCapture = this.onChangeCapture.bind(this);
        this.getCountryComp = this.getCountryComp.bind(this);
        this.onClickCountry = this.onClickCountry.bind(this);
    }

    //暂时注释掉，和onClickCountry方法冲突,countryCode值被getDerivedStateFromProps方法覆盖
    // static getDerivedStateFromProps(props: FtTextFieldPhoneProps, state: FtTextFieldPhoneState) {
    //     if (props.value != null && props.value.length > 0) {
    //         const countryCode = FtPhoneCodeUtil.getCountryISOCode(props.value);
    //         if (countryCode != null && state.countryCode != countryCode)
    //             return {countryCode: countryCode};
    //     }
    //     return null;
    // }

    render() {
        const baseTextFieldProps: BaseTextFieldProps = this.props;
        const InputProps: any = {};
        InputProps.startAdornment = this.getCountryComp(this.state.countryCode);
        let variant: any = "standard";
        if (this.props.variant) {
            variant = this.props.variant;
        }
        if (this.props.endAdornment) {
            InputProps.endAdornment = <InputAdornment position="end">{this.props.endAdornment}</InputAdornment>;
        }
        const value = this.formatValue(this.state.countryCode, this.props.value);
        let error: boolean = false;
        let helperText: string | undefined = this.props.helperText ? this.props.helperText : "";
        if (value != null && value.length > 0 && this.props.value != null && this.props.value.length > 0 && (!FtPhoneCodeUtil.isPhoneNumber(value))) {
            if (this.props.showHelperText === undefined || this.props.showHelperText) {
                helperText = i18n("Invalid phone number");
                error = true;
            } else {
                helperText = undefined;
                error = false;
            }
        }
        if (this.props.originalValue && this.props.value != this.props.originalValue)
            return <TextField {...baseTextFieldProps} value={value} error={error} helperText={helperText}
                              variant={"filled"}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
        else
            return <TextField {...baseTextFieldProps} value={value} error={error} helperText={helperText}
                              variant={variant}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
    }

    private formatValue(countryCode?: string, value?: string) {
        const prefix = this.getCountryPrefix(countryCode);
        if (value != null && value.length > 0 && value.length > prefix.length) {
            if (value.startsWith(prefix))
                return value;
            else {
                return prefix + (value.replace("+", ""));
            }
        } else
            return prefix;
    }

    private getCountryPrefix(countryCode?: string) {
        if (countryCode != null && countryCode == "cn") {
            return "+86";
        } else
            return "+1";
    }

    private getCountryComp(countryCode?: string) {
        return <InputAdornment position="start">
            <ButtonBase onClick={this.onClickCountry}>
                <Box style={{marginBottom: 2, paddingLeft: 8, paddingRight: 8, paddingTop: 4, paddingBottom: 4}}>
                    {countryCode == null && <FtFlagIconCA/>}
                    {countryCode != null && countryCode == "ca" && <FtFlagIconCA/>}
                    {countryCode != null && countryCode == "cn" && <FtFlagIconCN/>}
                    {countryCode != null && countryCode == "us" && <FtFlagIconUS/>}
                </Box>
            </ButtonBase>
        </InputAdornment>;
    }

    private onClickCountry() {
        let allowCountryIOSCodes: Array<string> = ["ca"];
        if (this.props.allowCountryIOSCodes != null && this.props.allowCountryIOSCodes.length > 0)
            allowCountryIOSCodes = this.props.allowCountryIOSCodes;
        const options: Array<FtOptionDialogItem> = [];
        allowCountryIOSCodes.forEach(value => {
            if (value.toLowerCase() === "ca") {
                const canadaItem: FtOptionDialogItem = new FtOptionDialogItem("ca", "+1", "CANADA +1");
                canadaItem.icon = <FtFlagIconCA/>;
                options.push(canadaItem);
            } else if (value.toLowerCase() === "cn") {
                const cnItem: FtOptionDialogItem = new FtOptionDialogItem("cn", "+86", "中国 +86");
                cnItem.icon = <FtFlagIconCA/>;
                options.push(cnItem);
            } else if (value.toLowerCase() === "us") {
                const usItem: FtOptionDialogItem = new FtOptionDialogItem("us", "+1", "United State +1");
                usItem.icon = <FtFlagIconUS/>;
                options.push(usItem);
            }
        });
        FtOptionDialog.dialog.openDialog(i18n("请选择国家"), options, (option: FtOptionDialogItem, index: number) => {
            FtOptionDialog.dialog.closeDialog();
            this.setState({countryCode: option.key});
            if (this.props.onChangeValue)
                this.props.onChangeValue(option.value);
        });
    }

    private onChange(event: any) {
        const value = event.target.value;
        if (this.props.onChangeValue)
            this.props.onChangeValue(value);
    }

    private onChangeCapture(event: any) {
        let message = "";
        const value = event.target.value;
        if (!charPhone.test(value))
            message = i18n("请输入有效的手机号");
        if (message != null && message.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        } else
            return true;
    }
}
