import FssShippingSchedule from "./FssShippingSchedule";

export default class FssDeliveryProvider {
    id = "";
    deliveryProviderCode = "";
    deliveryProviderName = "";
    shortDescription = "";
    longDescription = "";
    comment = "";
    deliveryProviderStatus: EDeliveryProviderStatus = EDeliveryProviderStatus.NORMAL;
    deliveryType: EDeliveryType = EDeliveryType.DELIVERY_TO_DOOR;
    deliveryTypeText: string = "";
    apiType = "";
    apiOrderUrl = "";
    apiTrackUrl = "";
    apiQuoteUrl = "";
    apiAccessId = "";
    apiAccessToken = "";
    contactName = "";
    contactPhone = "";
    receiveAddress = "";
    pickUpAddress = "";
    pickUpCity = "";
    pickUpCountry = "";
    pickUpProvince = "";
    minMoney = 0;
    userHandleFee = 0;
    payAtStore: boolean = false;
    storeId = "";
    sellerId = "";
    createDatetime = "";
    createDatetimeText = "";
    fssShippingSchedule:FssShippingSchedule=new FssShippingSchedule();
    contactEmail = "";
}

export enum EDeliveryProviderStatus {
    NORMAL = "NORMAL",
    STOP = "STOP",
    DELETED = "DELETED",
}

export enum EDeliveryType {
    DELIVERY_TO_DOOR = "DELIVERY_TO_DOOR",
    SELF_PICK_UP = "SELF_PICK_UP",
}
