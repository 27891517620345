import * as React from "react";
import FtToast from "../ui/FtToast";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import {Typography} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import FssMarketingEventPickUpSumOrder from "../model/FssMarketingEventPickUpSumOrder";
import FssOrderItem from "../model/FssOrderItem";

interface FssPickUpSumOrderPrintProp {
    order?: FssMarketingEventPickUpSumOrder;
    orderList?: Array<FssMarketingEventPickUpSumOrder>;
}

class FssPickUpSumOrderPrintState {
    iframList: Array<HTMLIFrameElement | null> = new Array<HTMLIFrameElement>();
}

export default class FssPickUpSumOrderPrint extends React.Component<FssPickUpSumOrderPrintProp, FssPickUpSumOrderPrintState> {
    constructor(props: any) {
        super(props);
        this.state = { iframList: new Array<HTMLIFrameElement>() }
    }

    print() {
        FtToast.showToastLoading(i18n("Printing, please wait "));
        this.state.iframList.forEach((iframe: HTMLIFrameElement | null, index: number) => {
            if (iframe && iframe.contentWindow) {
                if (iframe.contentWindow.document.body)
                    iframe.contentWindow.document.body.innerHTML = "";
                if (iframe.children && iframe.children.length > 0) {
                    const item = iframe.children.item(0);
                    if (item != null) {
                        iframe.contentWindow.document.write(item.innerHTML);
                        window.setTimeout(() => {
                            if (iframe && iframe.contentWindow)
                                iframe.contentWindow.print();
                        }, 1000);
                    }
                }

            }
        });
    }

    render() {
        let orderList = this.props.orderList;
        if (orderList == null && this.props.order != null) {
            orderList = [this.props.order];
        }
        return <div style={{height: 0}}>
            {orderList != null && orderList.map((order: FssMarketingEventPickUpSumOrder, index: number) => (
                <iframe key={index} style={{ width: 0, height: 0, margin: 0, padding: 0, border: 0 }} ref={(element) => {
                    this.state.iframList[index] = element
                }}>
                    <html>
                    <head>
                    </head>
                    <body style={{ fontSize: 10, margin: 0, padding: 8 }}>
                    <div>
                        <FtRow style={{marginLeft: 20}} cellWidthS={"1"} cellVerticalSpace={8}
                               cellHorizontalAlign={"center"}>
                            <Typography variant={"h6"}>{"Invoice"}</Typography>
                        </FtRow>
                        <div>
                            <Typography>{"发货时间：" + order.scheduleDatetime}</Typography>
                            <Typography>{"团购标题：" + order.marketingEventName}</Typography>
                            <Typography>{"取货点：" + order.pickUpName}  {"联系人：" + order.contactName}  {"   联系电话：" + order.contactPhone}</Typography>
                            <Typography>{"地址：" + order.pickUpAddress}</Typography>
                            <Typography>{"总金额：" + FtUtil.toCurrencyText(order.total)}     {"总单数：" + order.totalOrderQuantity}     {"应收金额：" + FtUtil.toCurrencyText(order.totalPaid)}</Typography>
                        </div>
                        <table style={{ width: "100%", fontSize: 10, borderTop: "1px solid black" }}>
                            <thead>
                            <tr>
                                <th align="left">{i18n("品项")}</th>
                                <th align="left">{i18n("单价")}</th>
                                <th align="left">{i18n("数量")}</th>
                                <th align="left">{i18n("金额")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {order.orderItemList != null && order.orderItemList.map((orderItem: FssOrderItem, itemIndex: number) => {
                                if (orderItem.productName)
                                    return <tr key={"item" + itemIndex}>
                                        <td align="left" dangerouslySetInnerHTML={{__html:orderItem.productName}}></td>
                                        <td align="left">{FtUtil.toCurrencyText(orderItem.price)}</td>
                                        <td align="left">{orderItem.quantity}</td>
                                        <td align="left">{FtUtil.toCurrencyText(orderItem.subtotal)}</td>
                                    </tr>;
                                else
                                    return null;
                            })}
                            </tbody>
                        </table>
                        <table style={{ pageBreakAfter: "always" }}>
                        </table>
                    </div>
                    </body>
                    </html>
                </iframe>
            ))}
        </div>;
    }
}
