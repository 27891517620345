
export default class FtRegion {
    name:string="";
    code:string="";
    type:string="";
    subItems:Array<FtRegion>=[];
    constructor(){
        this.subItems=[];
    }
}
