import * as React from "react";
import {ReactNode} from "react";
import {Button, Typography} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import {Link} from "react-router-dom";
import {green, red} from "@material-ui/core/colors";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtPagedList from "../model/FtPagedList";
import FssTaxName, {ETaxHowToCompute, ETaxNameStatus} from "../model/FssTaxName";
import FtToast from "../ui/FtToast";
import {i18n} from "../util/I18n";
import FtState from "../ui/FtState";
import FtPage from "../ui/FtPage";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtSnackbar from "../ui/FtSnackbar";
import FssTaxNameService from "../service/FssTaxNameService";

class JtTaxSettingListPageState extends FtComponentState {
    tableAdapter: FtTableAdapter = new FtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class FtTableAdapter extends FtDataTableAdapter<FssTaxName> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssTaxNameService.getPagedList(this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssTaxName>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}
export default class JtTaxSettingListPage extends FtComponent<any, JtTaxSettingListPageState> {


    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("taxName", i18n("税/费"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const taxName: FssTaxName = rowData as FssTaxName;
            return <Link to={"/taxSettings/" + taxName.id}>{taxName.taxName}</Link>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("taxShortName", i18n("缩写"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("taxNameStatus", i18n("状态"));
        column3.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssTaxName: FssTaxName = rowData as FssTaxName;
            if(fssTaxName.taxNameStatus==ETaxNameStatus.NORMAL)
                return <span style={{color:green[500]}}>{i18n("正常")}</span>;
            else
                return <span style={{color:red[500]}}>{i18n("停用")}</span>;
        };
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("country", i18n("国家"));
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("howToCompute", i18n("计算方法"));
        column5.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssTaxName: FssTaxName = rowData as FssTaxName;
            if(fssTaxName.howToCompute==ETaxHowToCompute.PERCENTAGE)
                return i18n("按金额百分比");
            else
                return i18n("按数量");
        };
        columnProps.push(column5);

        let tableAdapter: FtTableAdapter;
        const cacheState: JtTaxSettingListPageState = FtState.pop("JtTaxSettingListPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new FtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = { tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "JtTaxSettingListPageState" };
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this)

    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <FtPage onCancelClick={()=>{this.props.history.goBack()}}
                                  toolbarLeftNode={<Typography variant="h6">{i18n("税费列表")}</Typography>}>
            <FtDataTable columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarWithSelect={this.getToolbarWithSelect()}
                         toolbarNoSelect={this.getToolbarNoSelect()}>
            </FtDataTable>
        </FtPage>;
    }

    private onClickDelete() {
        const rows = this.state.tableAdapter.selectedRow;
        if (rows != null && rows.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除选定的") + rows.length + i18n("条记录吗？"), () => {
                const ids: Array<string> = [];
                rows.forEach((value: FssTaxName, index: number) => {
                    if(value.id){
                        ids.push(value.id);
                    }
                });
                FssTaxNameService.permanentDelete(ids, (data: FssTaxName) => {
                    FtSnackbar.showText(i18n("删除成功"));
                    this.state.tableAdapter.loadData();
                });
            });
        }
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/taxSettings/0"}>
                <Button>
                    <Add />{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }
    getToolbarWithSelect(): ReactNode {
        return <div>
            <Button onClick={this.onClickDelete}>
                {i18n("删除")}
            </Button>
        </div>;
    }
}
