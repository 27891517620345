import * as React from "react";
import {CSSProperties, HTMLAttributes} from "react";
import {TextField} from "@material-ui/core";
import {Autocomplete, createFilterOptions} from "@material-ui/lab";
import FtUtil from "../util/FtUtil";

interface FtComboboxProp extends HTMLAttributes<HTMLDivElement> {
    label?: string;
    optionItems?: Array<any>;
    labelPropertyName?: string;
    valuePropertyName?: string;
    onSelectItem?: (item: any) => void;
    value?: any;
    addButtonLabel?: string;
    onClickAdd?: (value: string) => void;

}

export class FtComboboxState {
    optionItems: Array<any> = [];
    value?: any;
    selectedItem?: any;
}

export default class FtCombobox extends React.Component<FtComboboxProp, FtComboboxState> {
    inputElement!: HTMLInputElement;

    static getDerivedStateFromProps(props: FtComboboxProp, state: FtComboboxState) {
        if (props.optionItems !== state.optionItems || props.value !== state.value) {
            state.value = props.value;
            if (props.optionItems)
                state.optionItems = props.optionItems;
            if (props.optionItems != null && props.value != null) {
                let found: boolean = false;
                for (let i = 0; i < props.optionItems.length; i++) {
                    let optionItem = props.optionItems[i];
                    if (props.valuePropertyName != null) {
                        const optionValue = FtUtil.getProperty(optionItem, props.valuePropertyName);
                        if (optionValue === props.value) {
                            found = true;
                            state.selectedItem = optionItem;
                        }
                    } else {
                        if (optionItem === props.value) {
                            found = true;
                            state.selectedItem = optionItem;
                        }
                    }
                }
                if (found == false) {
                    state.value = undefined;
                    state.selectedItem = undefined;
                }
            }
            return state;
        }
        return null;
    }


    constructor(props: any) {
        super(props);
        let selectedItem: any;
        if (this.props.optionItems != null && props.value != null) {
            for (let i = 0; i < this.props.optionItems.length; i++) {
                let optionItem = this.props.optionItems[i];
                if (this.props.valuePropertyName != null) {
                    const optionValue = FtUtil.getProperty(optionItem, props.valuePropertyName);
                    if (optionValue == props.value)
                        selectedItem = optionItem;
                } else {
                    if (optionItem === props.value) {
                        selectedItem = optionItem;
                    }
                }
            }
        }
        this.state = {optionItems: [], selectedItem: selectedItem, value: this.props.value};
        this.onSelectItem = this.onSelectItem.bind(this);
        this.getOptionSelected = this.getOptionSelected.bind(this);
        this.getOptionLabel = this.getOptionLabel.bind(this);
    }

    onSelectItem(event: React.ChangeEvent<{}>, item: any) {
        if (this.props.addButtonLabel != null && item != null && item.title != null && item.title === this.props.addButtonLabel) {
            if (this.props.onClickAdd)
                return this.props.onClickAdd(item.inputValue as string);
        } else {
            this.setState({selectedItem: item});
            if (this.props.onSelectItem)
                this.props.onSelectItem(item);
        }
    }


    getOptionSelected(option: any, value: any): boolean {
        if (this.props.valuePropertyName != null) {
            const optionValue = FtUtil.getProperty(option, this.props.valuePropertyName);
            const valueValue = FtUtil.getProperty(value, this.props.valuePropertyName);
            if (optionValue == valueValue)
                return true;
        } else {
            if (option == value)
                return true;
        }
        return false;
    }

    getOptionLabel(option: any) {
        if (this.props.labelPropertyName != null) {
            if (this.props.addButtonLabel != null && option.title != null && option.title === this.props.addButtonLabel)
                return this.props.addButtonLabel + " " + option.inputValue;
            else
                return FtUtil.getProperty(option, this.props.labelPropertyName);
        } else {
            if (this.props.addButtonLabel != null && option.title != null && option.title === this.props.addButtonLabel)
                return this.props.addButtonLabel + " " + option.inputValue;
            else
                return option;
        }
    }

    render() {
        const styles: CSSProperties | undefined = this.props.style;
        const filter = createFilterOptions<any>();
        return <Autocomplete
            options={this.props.optionItems ? this.props.optionItems : []}
            getOptionLabel={this.getOptionLabel}
            style={styles} value={this.state.selectedItem ? this.state.selectedItem : null}
            getOptionSelected={this.getOptionSelected}
            renderInput={(params) => <TextField {...params} label={this.props.label} style={styles}/>}
            onChange={this.onSelectItem}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            filterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (this.props.addButtonLabel != null) {
                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            title: this.props.addButtonLabel,
                        });
                    }
                }
                return filtered;
            }}
        />;
    }
}
