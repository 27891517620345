import * as React from "react";
import {ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {TextField} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import {CSVLink} from "react-csv";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import {i18n} from "../util/I18n";
import {FssPaymentTransactionMonthly} from "../model/FssPaymentTransactionMonthly";
import FssPaymentTransactionService from "../service/FssPaymentTransactionService";


export interface FssPaymentTransactionMonthlyListPageProp {
}

class FssPaymentTransactionMonthlyListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    csvData: Array<FssPaymentTransactionMonthly> = new Array<FssPaymentTransactionMonthly>();
}
class JtTableAdapter extends FtDataTableAdapter<FssPaymentTransactionMonthly> {

    loadData(): void {
        FtToast.showToastLoading("loading");
        FssPaymentTransactionService.getFssPaymentTransactionMonthlyList(this.keyword,
            this.getFilterColumnValue("startMonth") ,this.getFilterColumnValue("endMonth"),
            this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssPaymentTransactionMonthly>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
        });
    }
}
export default class FssPaymentTransactionMonthlyListPage extends FtComponent<FssPaymentTransactionMonthlyListPageProp, FssPaymentTransactionMonthlyListPageState> {
    csvLink!:any;
    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("dataMonth", i18n("年月"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("payOrderMoney", i18n("订单付款金额"), "right");
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("refundOrderMoney", i18n("订单退款金额"), "right");
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("paymentAccountName", i18n("支付帐号"),"center");
        columnProps.push(column4);

        let tableAdapter: JtTableAdapter;
        const cacheState: FssPaymentTransactionMonthlyListPageState = FtState.pop("FssPaymentTransactionMonthlyListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            stateKey: "FssPaymentTransactionMonthlyListPage",
            csvData: new Array<FssPaymentTransactionMonthly>()};
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.downloadCsvFile = this.downloadCsvFile.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("按月汇总")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         filterPanel={this.getFilterPanel()}
                         toolbarNoSelect={this.downloadCsvFile()}>
            </FtDataTable>
        </div>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow>
            <TextField id="startMonth"
                label="开始年月"
                type="month"
                style={{width: 150}}
                value={this.state.tableAdapter.getFilterColumnValue("startMonth")}
                InputLabelProps={{
                   shrink: true,
                }}
                onChange={event => {
                   this.state.tableAdapter.filterColumns.set("startMonth", event.target.value);
                   this.forceUpdate();
                }}
            />
            <TextField id="endMonth"
                label="结束年月"
                type="month"
                style={{width: 150}}
                value={this.state.tableAdapter.getFilterColumnValue("endMonth")}
                InputLabelProps={{
                   shrink: true,
                }}
                onChange={event => {
                   this.state.tableAdapter.filterColumns.set("endMonth", event.target.value);
                   this.forceUpdate();
                }}
            />
        </FtRow>;
    }
    downloadCsvFile(): ReactNode {
        let headers = [
            { label: "月份", key: "dataMonth" },
            { label: "订单付款金额", key: "payOrderMoney" },
            { label: "订单退款金额", key: "refundOrderMoney" },
            { label: "支付帐号ID", key: "paymentAccountId" }
        ];
        return <div>
            <IconButton onClick={() => {
                FtToast.showToastLoading("loading");
                FssPaymentTransactionService.getFssPaymentTransactionMonthlyList(this.state.tableAdapter.keyword,
                    this.state.tableAdapter.getFilterColumnValue("startMonth"), this.state.tableAdapter.getFilterColumnValue("endMonth"),
                    0, 999999,
                    (result: FtPagedList<FssPaymentTransactionMonthly>) => {
                        FtToast.closeToast();
                        this.setState({csvData: result.content},()=>{
                            if(this.csvLink&&this.csvLink.link)
                                this.csvLink.link.click();
                        });
                    });
            }}><CloudDownloadOutlined/></IconButton>
            <CSVLink data={this.state.csvData} headers={headers} filename={"月汇总.csv"}
                     ref={(element:any)=>{this.csvLink=element}}>
            </CSVLink>
        </div>
    }
}
