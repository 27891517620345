import {SvgIcon} from "@material-ui/core";
import React from "react";


export default class FtFlagIconCN extends React.Component<any, any> {
    render() {
        return <SvgIcon viewBox="0 0 30 20" style={{}}>
            <rect width="30" height="20" fill="#de2910"/>
            <path id="s" d="M0,-1 0.587785,0.809017 -0.951057,-0.309017H0.951057L-0.587785,0.809017z" fill="#ffde00"
                  transform="translate(5,5) scale(3)"/>
            <path id="s" d="M0,-1 0.587785,0.809017 -0.951057,-0.309017H0.951057L-0.587785,0.809017z" fill="#ffde00"
                  transform="translate(10,2) rotate(23.036243)"/>
            <path id="s" d="M0,-1 0.587785,0.809017 -0.951057,-0.309017H0.951057L-0.587785,0.809017z" fill="#ffde00"
                  transform="translate(12,4) rotate(45.869898)"/>
            <path id="s" d="M0,-1 0.587785,0.809017 -0.951057,-0.309017H0.951057L-0.587785,0.809017z" fill="#ffde00"
                  transform="translate(12,7) rotate(69.945396)"/>
            <path id="s" d="M0,-1 0.587785,0.809017 -0.951057,-0.309017H0.951057L-0.587785,0.809017z" fill="#ffde00"
                  transform="translate(10,9) rotate(20.659808)"/>
        </SvgIcon>;
    }
}
