import FssHttpService from "./FssHttpService";
import FtPagedList from "../model/FtPagedList";
import FssMarketingEvent from "../model/FssMarketingEvent";
import FssProductReview from "../model/FssProductReview";
import FssOrder from "../model/FssOrder";
import FssMarketingEventDeliveryProvider from "../model/FssMarketingEventDeliveryProvider";
import FssMarketingEventPickUpSumOrder from "../model/FssMarketingEventPickUpSumOrder";
import FssMarketingEventShipSumOrder from "../model/FssMarketingEventShipSumOrder";
import FssMarketingEventSingleOrder from "../model/FssMarketingEventSingleOrder";

export default class FssMarketingEventService {
    static getPagedList(searchKeyword: string, filterColumns: Map<string, any>,
                                      sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                      pageNo: number, pageSize: number,
                                      successFunction: (result: FtPagedList<FssMarketingEvent>) => void,
                                      failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/marketingEvent/getPagedList", searchKeyword, filterColumns,
            sortPropertyName, sortDirection, pageNo, pageSize, successFunction,
            failFunction ? failFunction : FssHttpService.handleError);
    }

    static getAvailMarketingEventList(name: string, successFunction: (result: Array<FssMarketingEvent>) => void,
                                      failFunction: (error: any) => void): void {
        FssHttpService.get("/api/marketingEvent/getAvailMarketingEventList", {name: name}, successFunction, failFunction);
    }

    static getById(id: string, successFunction: (data: FssMarketingEvent) => void) {
        FssHttpService.get("/api/marketingEvent/getById", {id: id},
            successFunction, FssHttpService.handleError);
    }
    static getByPageUri(sellerId: string, pageUri: string, successFunction: (data: FssMarketingEvent) => void) {
        FssHttpService.get("/api/marketingEvent/getByPageUri", {sellerId: sellerId, pageUri: pageUri},
            successFunction, FssHttpService.handleError);
    }

    static deleteByIdList(idList: Array<string>, successFunction: (data: string) => void): void {
        FssHttpService.postJson("/api/marketingEvent/deleteByIdList", idList, successFunction, FssHttpService.handleError);
    }

    static save(data: FssMarketingEvent, successFunction: (data: FssMarketingEvent) => void): void {
        FssHttpService.postJson("/api/marketingEvent/save", data, successFunction, FssHttpService.handleError);
    }

    static updateViewsCount(id: string, successFunction: (data: FssMarketingEvent) => void): void {
        FssHttpService.postFormData("/api/marketingEvent/updateViewsCount", {id: id}, successFunction, FssHttpService.handleError);
    }

    static getMarketingEventOrders(id: string, successFunction: (pagedList: FtPagedList<FssOrder>) => void): void {
        FssHttpService.get("/api/marketingEvent/getMarketingEventOrders", {id: id}, successFunction, FssHttpService.handleError);
    }

    static getMarketingEventProductReviews(id: string,  successFunction: (pagedList: FtPagedList<FssProductReview>) => void): void {
        FssHttpService.get("/api/marketingEvent/getMarketingEventProductReviews", {id: id}, successFunction, FssHttpService.handleError);
    }

    static getPagedWithProductAndSeller(storeId: string, pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssMarketingEvent>) => void,
                                        failFunction?: (error: any) => void): void {
        let params = new Map<string, any>();
        params.set("storeId", storeId);
        FssHttpService.search("/api/marketingEvent/getPagedWithProductAndSeller", "", params,
            null, null, pageNo, pageSize, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static getMarketingEventByTags(tags: Array<string>, sellerId: string, storeId: string, successFunction: (data: Array<FssMarketingEvent>) => void): void {
        let params = {storeId: storeId, sellerId: sellerId, tags: tags};
        FssHttpService.postFormData("/api/marketingEvent/getMarketingEventByTags", params, successFunction, FssHttpService.handleError);
    }


    static savePublisherMarketingEvent(id: string, sellerId: string, storeId: string, successFunction: (data: FssMarketingEvent) => void): void {
        let params = {id: id, sellerId: sellerId, storeId: storeId};
        FssHttpService.postFormData("/api/marketingEvent/savePublisherMarketingEvent", params, successFunction, FssHttpService.handleError);
    }

    static getByParentMarketingEventId(parentMarketingEventId: string, sellerId: string, storeId: string, successFunction: (data: FssMarketingEvent) => void) {
        let params = {parentMarketingEventId: parentMarketingEventId, sellerId: sellerId, storeId: storeId};
        FssHttpService.get("/api/marketingEvent/getByParentMarketingEventId", params, successFunction, FssHttpService.handleError);
    }

    static getApplyForPublisherPagedList(searchKeyword: string, filterColumns: Map<string, any>, pageNo: number, pageSize: number,
                                         successFunction: (result: FtPagedList<FssMarketingEvent>) => void): void {
        FssHttpService.search("/api/marketingEvent/getApplyForPublisherPagedList", searchKeyword, filterColumns,
            null, null, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static saveMarketingEventDeliveryProvider(marketingEventId: string, deliveryProviders: Array<string>, successFunction: (data: Array<FssMarketingEventDeliveryProvider>) => void): void {
        let params = {marketingEventId: marketingEventId, deliveryProviders: deliveryProviders};
        FssHttpService.postFormData("/api/marketingEventDeliveryProvider/saveMarketingEventDeliveryProvider", params, successFunction, FssHttpService.handleError);
    }

    static getByMarketingListEventId(marketingEventId: string, successFunction: (data: Array<FssMarketingEventDeliveryProvider>) => void): void {
        let params = {marketingEventId: marketingEventId};
        FssHttpService.postFormData("/api/marketingEventDeliveryProvider/getByMarketingListEventId", params, successFunction, FssHttpService.handleError);
    }

    static copyMarketingEvent(marketingEventIds: Array<string>, successFunction: (data: Array<FssMarketingEvent>) => void): void {
        let params = {marketingEventIds: marketingEventIds};
        FssHttpService.postFormData("/api/marketingEvent/copyMarketingEvent", params, successFunction, FssHttpService.handleError);
    }

    static getShippedSumOrder(marketingEventId: string, successFunction: (result: FssMarketingEventShipSumOrder) => void): void {
        FssHttpService.get("/api/marketingEvent/getShippedSumOrder",{marketingEventId:marketingEventId}, successFunction, FssHttpService.handleError);
    }
    static getPickUpSumOrder(marketingEventId: string, successFunction: (result: Array<FssMarketingEventPickUpSumOrder>) => void): void {
        FssHttpService.get("/api/marketingEvent/getPickUpSumOrder",{marketingEventId:marketingEventId}, successFunction, FssHttpService.handleError);
    }

    static getSingleOrder(marketingEventId: string, successFunction: (result: Array<FssMarketingEventSingleOrder>) => void): void {
        FssHttpService.get("/api/marketingEvent/getSingleOrder",{marketingEventId:marketingEventId}, successFunction, FssHttpService.handleError);
    }
}
