import * as React from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtPanel from "../ui/FtPanel";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import Typography from "@material-ui/core/Typography";
import {red} from "@material-ui/core/colors";
import FssOrder, {EOrderStatus, EShippingType} from "../model/FssOrder";

interface FssMoneySectionCompProp {
    order?: FssOrder;
    shippingFee?: number | string;
    totalUnpaid?: number;
}

export class FssMoneySectionCompState extends FtComponentState {
}


export default class FssMoneySectionComp extends FtComponent<FssMoneySectionCompProp, FssMoneySectionCompState> {

    constructor(props: any) {
        super(props);
    }

    componentDidMount(): void {
    }

    render() {
        if (this.props.order) {
            // const appTipSetting:JtAppTipSetting|undefined=JtCommonClientData.getData().userAppInfo.attributes.appTipSetting;
            let tipLabel: string = i18n("小费");
            // if (appTipSetting!=null&&appTipSetting.tipFlag==true&&appTipSetting.tipLabel!=null
            //     &&appTipSetting.tipLabel.length>0) {
            //     tipLabel = appTipSetting.tipLabel;
            // }
            return <FtPanel elevation={0} style={{
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft: 16,
                paddingRight: 16,
                marginTop: 4,
                marginBottom: 4
            }}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>{i18n("商品金额")}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.subtotalProduct)}</div>
                </div>
                {this.props.order.taxNameList && this.props.order.taxNameList.length > 0 &&
                this.props.order.taxNameList.map((value, index) => {
                    if (this.props.order && this.props.order.taxMoneyList.length > index && this.props.order.taxMoneyList[index] != 0) {
                        return <div style={{display: "flex", justifyContent: "space-between"}}>
                            <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>{i18n(value)}</div>
                            <div style={{
                                width: "25%",
                                textAlign: "right"
                            }}>{FtUtil.toCurrencyText(this.props.order.taxMoneyList[index])}</div>
                        </div>;
                    }
                })}
                {this.props.order.tax != null && this.props.order.tax != 0 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>税费</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.tax)}</div>
                </div>}
                {this.props.order.packageFee != 0 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>{i18n("包装费")}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.packageFee)}</div>
                </div>}
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{
                        paddingRight: 16,
                        flexGrow: 2,
                        textAlign: "right"
                    }}>{i18n(this.props.order?.shippingType == EShippingType.DELIVERY_TO_HOME ? "运费" : "自取手续费")}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{this.props.shippingFee ? this.props.shippingFee : FtUtil.toCurrencyText(this.props.order.shippingFee)}</div>
                </div>
                {"CANADA" == this.props.order.shipToCountry &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>{i18n("运费税")}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.shippingFee * 0.05)}</div>
                </div>}

                {this.props.order.paymentFee != 0 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    {/*<div style={{*/}
                    {/*    paddingRight: 16, flexGrow: 2, textAlign: "right", display: "flex",*/}
                    {/*    justifyContent: "flex-end"*/}
                    {/*}}>{i18n("手续费")}*/}
                    {/*    {JtCommonClientData.getData().userAppInfo.userHandleFeeDesc != null && JtCommonClientData.getData().userAppInfo.userHandleFeeDesc.length > 0*/}
                    {/*    && <HelpOutline onClick={() => {*/}
                    {/*        FtSnackbar.showText(JtCommonClientData.getData().userAppInfo.userHandleFeeDesc)*/}
                    {/*    }}/>}*/}
                    {/*</div>*/}
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.paymentFee)}
                    </div>
                </div>}
                {this.props.order.discount != null && this.props.order.discount != 0 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>{i18n("优惠")}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.discount)}</div>
                </div>}
                {this.props.order.otherFee != null && this.props.order.otherFee != 0 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>{i18n("其他")}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.otherFee)}</div>
                </div>}
                {this.props.order.tips != null && this.props.order.tips != 0 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>{tipLabel}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.tips)}</div>
                </div>}
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right", color: red[500]}}>
                        <Typography color={"primary"} variant={"h5"}>{i18n("合计")}</Typography>
                    </div>
                    <div style={{
                        color: red[500],
                        width: "25%",
                        textAlign: "right"
                    }}><Typography color={"primary"}
                                   variant={"h5"}>
                        {FtUtil.toCurrencyText(this.props.order.total)}</Typography>
                    </div>
                </div>
                {this.props.order.totalPaid != null && this.props.order.totalPaid != 0 &&
                (this.props.order.status == EOrderStatus.CREATED ||
                    this.props.order.status == EOrderStatus.APPROVED ||
                    this.props.order.status == EOrderStatus.PICKING ||
                    this.props.order.status == EOrderStatus.PACKAGING ||
                    this.props.order.status == EOrderStatus.SHIPPED ||
                    this.props.order.status == EOrderStatus.DELIVERED) &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>{i18n("已支付金额")}</div>
                    <div style={{
                        width: "25%",
                        textAlign: "right"
                    }}>{FtUtil.toCurrencyText(this.props.order.totalPaid)}</div>
                </div>}
                {this.props.order.pointDeducted != null && this.props.order.pointDeducted != 0 &&
                (this.props.order.status == EOrderStatus.CREATED ||
                    this.props.order.status == EOrderStatus.APPROVED ||
                    this.props.order.status == EOrderStatus.PICKING ||
                    this.props.order.status == EOrderStatus.PACKAGING ||
                    this.props.order.status == EOrderStatus.SHIPPED ||
                    this.props.order.status == EOrderStatus.DELIVERED) &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right"}}>({i18n("积分支付")}&nbsp;
                        {FtUtil.toCurrencyText(this.props.order.pointDeducted)})
                    </div>
                    <div style={{width: "25%", textAlign: "right"}}/>
                </div>}
                {this.props.order.balanceUsed != null && this.props.order.balanceUsed != 0 &&
                (this.props.order.status == EOrderStatus.CREATED ||
                    this.props.order.status == EOrderStatus.APPROVED ||
                    this.props.order.status == EOrderStatus.PICKING ||
                    this.props.order.status == EOrderStatus.PACKAGING ||
                    this.props.order.status == EOrderStatus.SHIPPED ||
                    this.props.order.status == EOrderStatus.DELIVERED) &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{
                        paddingRight: 16,
                        flexGrow: 2,
                        textAlign: "right"
                    }}>({i18n("余额支付")}&nbsp;{FtUtil.toCurrencyText(this.props.order.balanceUsed)})
                    </div>
                    <div style={{width: "25%", textAlign: "right"}}></div>
                </div>}
                {this.props.totalUnpaid != null && this.props.totalUnpaid != 0 &&
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <div style={{paddingRight: 16, flexGrow: 2, textAlign: "right", color: red[500]}}>
                        <Typography color={"primary"}
                                    variant={"h5"}>{i18n("未支付金额")}</Typography></div>
                    <div style={{
                        color: red[500],
                        width: "25%",
                        textAlign: "right"
                    }}><Typography color={"primary"}
                                   variant={"h5"}>
                        {FtUtil.toCurrencyText(this.props.totalUnpaid)}
                    </Typography></div>
                </div>}
            </FtPanel>
        } else {
            return null;
        }
    }
}
