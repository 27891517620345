import {RouteComponentProps} from "react-router-dom";
import * as React from "react";
import {Button, Typography} from "@material-ui/core";
import FssSellerEmployee, {EStatus} from "../model/FssSellerEmployee";
import FtToast from "../ui/FtToast";
import {i18n} from "../util/I18n";
import FtSnackbar from "../ui/FtSnackbar";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FssSellerEmployeeService from "../service/FssSellerEmployeeService";

const regexpPassword = new RegExp('^[a-zA-Z0-9.!#$&*+?_@-]+$');

interface FasInviteUserCompProp extends RouteComponentProps {
    verificationCode: string;
    onClickOk: () => void;
}

export class FasInviteUserCompState {
    fssSellerEmployee: FssSellerEmployee = new FssSellerEmployee();
}

export default class FssInviteEmployeeComp extends React.Component<FasInviteUserCompProp, FasInviteUserCompState> {

    constructor(props: any) {
        super(props);

        this.state = {fssSellerEmployee: new FssSellerEmployee()};
        this.onClickOk=this.onClickOk.bind(this);
    }

    componentDidMount(): void {
        FtToast.showToastLoading();
        FssSellerEmployeeService.checkInviteUserVerification(this.props.verificationCode,(fssSellerEmployee: FssSellerEmployee) => {
            FtToast.closeToast();
            if (fssSellerEmployee.status == EStatus.NORMAL) {
                this.onClickOk();
            } else {
                this.setState({fssSellerEmployee: fssSellerEmployee});
            }
        });
    }

    onClickOk (): void {
        this.props.onClickOk();
    }

    render() {
        return <FtPanel marginHorizontal={16} marginVertical={16} style={{maxWidth: 480}}>
            <FtGrid marginVertical={16} marginHorizontal={16}>
                <FtRow cellWidthS={"1"} cellVerticalSpace={32} cellHorizontalAlign={"center"}>
                    <Typography variant={"h5"}>{i18n("网店邀请确认")}</Typography>
                    <JtTextField value={i18n("网店名称：") + this.state.fssSellerEmployee.seller?.fullName}
                                 style={{width: "100%"}} variant={"outlined"}/>
                    <Button onClick={this.onClickOk} color="primary" variant={"contained"}>
                        {i18n("确认加入店铺")}
                    </Button>
                </FtRow>
            </FtGrid>
        </FtPanel>;
    }
}
