import * as React from "react";
import {Dialog} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogContent from "@material-ui/core/DialogContent";

export class FtToastState {
    icon?: string;
    showToast: boolean = false;
    toastText?: string;
    toastTimer?: any;
}

export default class FtToast extends React.Component<any, FtToastState> {
    static FtToast: FtToast;

    static showToastLoading(text: string="", timeout: number = 3000): void {
        if (FtToast.FtToast){
            FtToast.FtToast.show(text, "loading", timeout);
        }
    }

    static showToast(text: string, icon?: string, timeout?: number): void {
        if (FtToast.FtToast)
            FtToast.FtToast.show(text, icon, timeout);
    }

    static closeToast(): void {
        if (FtToast.FtToast)
            FtToast.FtToast.closeMe();
    }

    constructor(props: any) {
        super(props);
        this.state = {showToast: false};
        this.closeMe = this.closeMe.bind(this);
        this.show = this.show.bind(this);
    }

    componentWillUnmount() {
        this.state.toastTimer && clearTimeout(this.state.toastTimer);
    }
    updateText(text:string){
        this.setState({toastText:text});
    }

    show(text: string, icon?: string, timeout?: number): void {
        if (this.state.showToast)
            this.closeMe();
        const timer = setTimeout(() => {
            this.closeMe();
        }, timeout);
        this.setState({showToast: true, toastText: text, icon: icon, toastTimer: timer});
    }

    closeMe(): void {
        clearTimeout(this.state.toastTimer);
        this.setState({showToast: false, toastText: "", toastTimer: null});
    }

    render() {
        return <Dialog open={this.state.showToast} maxWidth="xs" style={{zIndex:99999}}>
            <DialogContent>
                <DialogContentText>
                    {this.state.toastText}
                </DialogContentText>
                <CircularProgress size={50} thickness={3} color="primary"/>
            </DialogContent>
        </Dialog>
    }
}
