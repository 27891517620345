import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {ButtonGroup, IconButton, TextField, Tooltip} from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog";
import {green, grey, red} from "@material-ui/core/colors";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import FtTabs from "../ui/FtTabs";
import FtTabPanel from "../ui/FtTabPanel";
import Typography from "@material-ui/core/Typography";
import FtOptionDialog, {FtOptionDialogItem} from "../ui/FtOptionDialog";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtToolbar from "../ui/FtToolbar";
import {ChevronLeft, Edit, Help, ListAlt} from "@material-ui/icons";
import JtTextField from "../ui/JtTextField";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtRow from "../ui/FtRow";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Alert from "@material-ui/lab/Alert";
import {Link, RouteComponentProps} from "react-router-dom";
import {i18n} from "../util/I18n";
import FssOrderPrint58 from "./FssOrderPrint58";
import FssOrderPrintLetterSize from "./FssOrderPrintLetterSize";
import FssOrderWaybillPrint from "./FssOrderWaybillPrint";
import FssDeliveryProvider, {EDeliveryProviderStatus} from "../model/FssDeliveryProvider";
import FssOrder, {EOrderStatus, EPaymentStatus, EPaymentType, EShippingType} from "../model/FssOrder";
import FssOrderItemTax from "../model/FssOrderItemTax";
import FssOrderItem from "../model/FssOrderItem";
import FssDeliveryProviderService from "../service/FssDeliveryProviderService";
import FssOrderService from "../service/FssOrderService";
import FssOrderDiscount from "../model/FssOrderDiscount";
import FssOrderItemEditDialog from "./FssOrderItemEditDialog";
import FssUserBalanceTransaction, {EBalanceType} from "../model/FssUserBalanceTransaction";
import FssPointTransaction, {EScoreType} from "../model/FssPointTransaction";
import FssPaymentTransaction, {EStatus} from "../model/FssPaymentTransaction";
import FssRefundDialog from "./FssRefundDialog";
import FssOrderTrackLogComp from "./FssOrderTrackLogComp";
import FssDeliveryProviderDialog from "./FssDeliveryProviderDialog";
import FssClientData from "../data/FssClientData";
import FtPagedList from "../model/FtPagedList";
import FtApiResponse from "../model/FtApiResponse";
import lodash from "lodash";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import LinkIcon from '@material-ui/icons/Link';
import EditIcon from '@material-ui/icons/Edit';
import FtState from "../ui/FtState";
import FtRegion from "../model/FtRegion";
import FssCityService from "../service/FssCityService";
import FssOrderListPage, {EOrderPageType} from "./FssOrderListPage";
import FtTextFieldString from "../ui/FtTextFieldString";

interface FssOrderDetailPageProp extends RouteComponentProps<{ orderId: string }> {
}

export class FssOrderDetailPageState extends FtComponentState {
    order: FssOrder = new FssOrder();
    openMessageBoxDialog: boolean = false;
    sendToCreateUser: boolean = false;
    sendToShipFrom: boolean = false;
    sendToShipTo: boolean = false;
    smsContent: string = "";
    tabValue: string = "";
    changed: boolean = false;
    paymentTransactionList: Array<FssPaymentTransaction> = [];
    showPaymentTransactionDialog: boolean = false;
    balanceTransactionList?: Array<FssUserBalanceTransaction>;
    showBalanceTransactionDialog: boolean = false;
    pointTransactionList?: Array<FssPointTransaction>;
    orderDiscountList?: Array<FssOrderDiscount>;
    showpointTransactionDialog: boolean = false;
    showOrderDiscountDialog: boolean = false;
    showRefundDialog: boolean = false;
    shippingInfoChanged: boolean = false;
    orderShippingType?: EShippingType;
    taxTransactionList?: Array<FssOrderItemTax>;
    showRateTransactionDialog: boolean = false;
    openItemEditDialog: boolean = false;
    editOrderItem?: FssOrderItem;
    smsNotificationFlag: boolean = false;
    smsNotificationText: string = "";
    deliveryProviderList: Array<FssDeliveryProvider> = [];
    showShippingDialog: boolean = false;
    showOrderListDialog: boolean = false;
    country: FtRegion = new FtRegion();
}


export default class FssOrderDetailPage extends FtComponent<FssOrderDetailPageProp, FssOrderDetailPageState> {
    orderPrint58!: FssOrderPrint58 | null;
    orderPrintLetterSize!: FssOrderPrintLetterSize | null;
    orderWaybillPrint!: FssOrderWaybillPrint | null;
    deliveryProviderDialog!: FssDeliveryProviderDialog;

    constructor(props: any) {
        super(props);
        this.state = {
            order: new FssOrder(),
            openMessageBoxDialog: false,
            sendToCreateUser: false,
            sendToShipTo: false,
            sendToShipFrom: false,
            smsContent: "",
            tabValue: "basic",
            changed: false,
            paymentTransactionList: new Array<FssPaymentTransaction>(),
            showPaymentTransactionDialog: false,
            showBalanceTransactionDialog: false,
            showpointTransactionDialog: false,
            showOrderDiscountDialog: false,
            showRefundDialog: false,
            shippingInfoChanged: false,
            showRateTransactionDialog: false,
            openItemEditDialog: false,
            smsNotificationFlag: false,
            smsNotificationText: "",
            deliveryProviderList: [],
            showShippingDialog: false,
            showOrderListDialog: false,
            country: new FtRegion()
        };
        this.onChange = this.onChange.bind(this);
        this.saveOrder = this.saveOrder.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSelectAddress = this.onSelectAddress.bind(this);
        this.onClickPrint = this.onClickPrint.bind(this);
        this.onPrintWaybillClick = this.onPrintWaybillClick.bind(this);
        this.getShippingInfoPanel = this.getShippingInfoPanel.bind(this);
        this.onClickPaymentAction = this.onClickPaymentAction.bind(this);
        this.onClickShowPaymentTransaction = this.onClickShowPaymentTransaction.bind(this);
        this.getPaymentTransactionDialog = this.getPaymentTransactionDialog.bind(this);
        this.saveOrderNow = this.saveOrderNow.bind(this);
        this.onClickRefundPartial = this.onClickRefundPartial.bind(this);
        this.onClickPaymentPartial = this.onClickPaymentPartial.bind(this);
        this.onClickApproveOrder = this.onClickApproveOrder.bind(this);
        this.onClickCancelOrder = this.onClickCancelOrder.bind(this);
        this.onClickPickingOrders = this.onClickPickingOrders.bind(this);
        this.onClickPackOrders = this.onClickPackOrders.bind(this);
        this.onFinishOrders = this.onFinishOrders.bind(this);
        this.onClickShowBalanceTransaction = this.onClickShowBalanceTransaction.bind(this);
        this.getBalanceTransactionDialog = this.getBalanceTransactionDialog.bind(this);
        this.isChangeShippingInfo = this.isChangeShippingInfo.bind(this);
        this.checkOrderPaymentStatus = this.checkOrderPaymentStatus.bind(this);
        this.onClickShowpointTransaction = this.onClickShowpointTransaction.bind(this);
        this.getpointTransactionDialog = this.getpointTransactionDialog.bind(this);
        this.onClickCancelShipping = this.onClickCancelShipping.bind(this);
        this.recalculateShippingFeeDialog = this.recalculateShippingFeeDialog.bind(this);
        this.onClickShowRateTransaction = this.onClickShowRateTransaction.bind(this);
        this.getAddProductDialog = this.getAddProductDialog.bind(this);
        this.onItemChanged = this.onItemChanged.bind(this);
        this.onClickRestoreOrder = this.onClickRestoreOrder.bind(this);
        this.onClickResetShippedOrder = this.onClickResetShippedOrder.bind(this);
        this.getOrderDiscountDialog = this.getOrderDiscountDialog.bind(this);
        this.onClickShowOrderDiscount = this.onClickShowOrderDiscount.bind(this);
        this.onChangeSmsContent = this.onChangeSmsContent.bind(this);
        this.onClickShipped = this.onClickShipped.bind(this);
        this.getDeliveryProvider = this.getDeliveryProvider.bind(this);
        this.loadDeliveryProvider = this.loadDeliveryProvider.bind(this);
        this.onSubmitDone = this.onSubmitDone.bind(this);
        this.getScheduleDateComp = this.getScheduleDateComp.bind(this);
        this.getDeliveryProviderComp = this.getDeliveryProviderComp.bind(this);
        this.loadCountry = this.loadCountry.bind(this);
        this.calculate = this.calculate.bind(this);
        this.onClickSyncOrderWithDeliveryProvider = this.onClickSyncOrderWithDeliveryProvider.bind(this);
    }

    componentWillMount(): void {
        this.setState({tabValue: "basic"});
        if (this.props.match.params.orderId != null && this.props.match.params.orderId.length > 0) {
            let order: FssOrder = new FssOrder();
            order.id = this.props.match.params.orderId;
            this.setState({order: order,}, () => {
                this.load();
                this.loadDeliveryProvider();
            });
        }
    }

    loadCountry(countryInfoId: string) {
        FssCityService.getRegionByStoreId(countryInfoId, (result: FtRegion) => {
            this.setState({country: result});
        });
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        if (window.location.href.indexOf("/findOrderPage/") === -1) {
            FtState.pop("FssOrderFindPage");
        }
    }

    loadDeliveryProvider() {
        let filterColumns: Map<string, any> = new Map();
        filterColumns.set("status", EDeliveryProviderStatus.NORMAL);
        FssDeliveryProviderService.getPageDeliveryProviderList("", filterColumns, null, null, 0, 999, (result: FtPagedList<FssDeliveryProvider>) => {
            this.setState({deliveryProviderList: result.content});
        });
    }

    load() {
        if (this.state.order && this.state.order.id && this.state.order.id.length > 0) {
            FtToast.showToastLoading(i18n("获取订单信息"));
            FssOrderService.getOrder(this.state.order.id, (order: FssOrder) => {
                FtToast.closeToast();
                order = FtUtil.mergeObject(new FssOrder(), order);
                this.loadCountry(order.countryInfoId);
                this.setState({
                    order: order,
                    changed: false,
                    shippingInfoChanged: false,
                    orderShippingType: order.shippingType,
                    showShippingDialog: false,
                }, () => {
                    if (this.state.order != null) {
                        if (this.state.order.parentOrderId != null && this.state.order.parentOrderId.length > 0) {
                            FssOrderService.getOrder(this.state.order.parentOrderId, (parentOrder: FssOrder) => {
                                this.state.order.parentOrder = parentOrder;
                                this.forceUpdate();
                            });
                        }
                        FssOrderService.getListByParentId(this.state.order.id, (childOrderList: Array<FssOrder>) => {
                            this.state.order.childOrderList = childOrderList;
                            this.forceUpdate();
                        });
                    }
                });
            });
        }
    }

    onCancelClick(): void {
        if (this.state.changed) {
            FtMessageDialog.dialog.openDialog(i18n("内容已修改，现在保存吗？"), () => {
                this.recalculateShippingFeeDialog();
            }, () => {
                this.props.history.goBack();
            }, i18n("保存"), i18n("放弃修改"));
        } else {
            this.props.history.goBack();
        }
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        if (event.target.id == "shippingFee" || event.target.id == "packageFee") {
            FtUtil.setProperty(this.state.order, event.target.id, +event.target.value);
            this.calculate();
        } else {
            FtUtil.setProperty(this.state.order, event.target.id, event.target.value);
        }
        this.setState({changed: true, shippingInfoChanged: this.isChangeShippingInfo(event.target.id)});
        this.forceUpdate();
    }

    /**
     * 判断是否修改配送信息，修改父订单时需要同步子订单用到
     * @param targetId
     */
    isChangeShippingInfo(targetId: string) {
        if (targetId == "scheduleDate") {
            return true;
        } else if (targetId == "scheduleTime") {
            return true;
        } else if (targetId == "shipToName") {
            return true;
        } else if (targetId == "shipToMobile") {
            return true;
        } else if (targetId == "shipToCountry") {
            return true;
        } else if (targetId == "shipToProvince") {
            return true;
        } else if (targetId == "shipToCity") {
            return true;
        } else if (targetId == "shipToPostCode") {
            return true;
        } else if (targetId == "shipToIdNumber") {
            return true;
        } else if (targetId == "shipFromPhone") {
            return true;
        } else if (targetId == "shipFromAddress") {
            return true;
        } else if (targetId == "shippingType") {
            return true;
        } else if (targetId == "shipToAddress") {
            return true;
        }
        return false;
    }

    onChangeItem(index: number, propertyName: string, value: string | number | undefined) {
        let orderItem: FssOrderItem = this.state.order.items[index];
        if (propertyName == "price" || propertyName == "quantity") {
            FtUtil.setProperty(orderItem, propertyName, value);
            orderItem.subtotal = orderItem.price * orderItem.quantity;
            this.calculate();
        } else {
            FtUtil.setProperty(orderItem, propertyName, value);
        }
        this.setState({changed: true});
        this.forceUpdate();
    }

    /**
     * 检测订单金额
     * @param afterSaveFunction
     * @param recalculateFlag
     */
    checkOrderPaymentStatus() {
        //如果是已支付状态，则需要检查金额是否变化
        if (this.state.order.paymentStatus == EPaymentStatus.PAID) {
            FssOrderService.getOrder(this.state.order.id, (order: FssOrder) => {
                if (order.total > this.state.order.total) {
                    FtMessageDialog.dialog.openDialog(i18n("订单金额已修改，新金额小于已支付金额，保存后请给用户退差额，确定要保存吗？"), () => {
                        this.saveOrderNow();
                    });
                } else if (order.total < this.state.order.total) {
                    FtMessageDialog.dialog.openDialog(i18n("订单金额已修改，新金额大于已支付金额，保存后请通知用户补交差额，确定要保存吗？"), () => {
                        this.saveOrderNow();
                    });
                } else {
                    this.saveOrderNow();
                }
            });
        } else
            this.saveOrderNow();
    }

    saveOrder() {
        //如果是有子订单的主订单
        if (this.state.order.childOrderList != null && this.state.order.childOrderList.length > 0) {
            if (this.state.shippingInfoChanged) {
                FtMessageDialog.dialog.openDialog(i18n("此订单拥有子订单,修改配送信息将同步到子订单,是否修改?"), () => {
                    FtMessageDialog.dialog.closeDialog();
                    this.checkOrderPaymentStatus();
                }, () => {
                    // 否的话不提交
                    FtMessageDialog.dialog.closeDialog();
                }, i18n("是"), i18n("否"));
            } else {
                this.checkOrderPaymentStatus();
            }
        } else {
            //如果是普通订单或者子订单
            this.checkOrderPaymentStatus();
        }
    }

    recalculateShippingFeeDialog() {
        // 子订单不显示对话框，并且设置不重新计算运费
        if (this.state.order.parentOrder != null && this.state.order.parentOrder.id != null && this.state.order.parentOrder.id.length > 0) {
            this.state.order.recalculateFlag = false;
            this.forceUpdate();
            this.saveOrder();
        } else if (this.state.orderShippingType &&
            this.state.orderShippingType == this.state.order.shippingType &&
            this.state.orderShippingType == EShippingType.PICK_UP) {
            // 订单状态是自取，并且没有修改过状态，不弹出
            this.state.order.recalculateFlag = false;
            this.forceUpdate();
            this.saveOrder();
        } else {
            FtMessageDialog.dialog.closeDialog();
            this.state.order.recalculateFlag = false;
            this.forceUpdate();
            this.saveOrder();
        }
    }

    saveOrderNow() {
        FtToast.showToastLoading(i18n("正在提交订单"));
        FssOrderService.saveOrder(this.state.order, (data: FssOrder) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("订单保存成功！"));
            this.load();
        });
    }

    onSelectAddress(address: string, lat: number, lng: number, googleMapPlaceId?: string, city?: string, postalCode?: string,
                    province?: string, country?: string, id?: string) {
        if (address != null) {
            this.state.order.shipToAddress = address;
            this.state.order.shipToAddressLat = lat;
            this.state.order.shipToAddressLng = lng;
        }
        if (city != null)
            this.state.order.shipToCity = city;
        if (postalCode != null)
            this.state.order.shipToPostcode = postalCode;
        if (province != null)
            this.state.order.shipToProvince = province;
        this.forceUpdate();
    }

    onClickPrint(size: string) {
        if (size == "80") {
            if (this.orderPrint58) {
                this.orderPrint58.print();
            }
        } else if (size == "Letter") {
            if (this.orderPrintLetterSize) {
                this.orderPrintLetterSize.print();
            }
        }
    }

    onPrintWaybillClick() {
        if (this.orderWaybillPrint)
            this.orderWaybillPrint.print();
    }

    getDeliveryProvider() {
        if (this.state.order.deliveryProviderId === null || this.state.order.deliveryProviderId.trim().length === 0) {
            this.deliveryProviderDialog.show(this.state.order, this.onSubmitDone);
        } else {
            this.setState({showShippingDialog: true});
        }
    }

    onClickShipped() {
        if (this.state.order.status === EOrderStatus.CANCELED) {
            this.setState({openMessageBoxDialog: true});
        } else {
            FtToast.showToastLoading(i18n("正在发货"));
            FssOrderService.sendToDeliveryProvider(this.state.order.id, this.state.order.deliveryProviderId, this.state.order.trackingNumber,
                this.state.smsNotificationFlag, this.state.smsNotificationText, (resultOrder: FssOrder) => {
                    FtToast.closeToast();
                    FtSnackbar.showText(i18n("操作成功"));
                    this.load();
                });
        }
    }

    getShippingDialog(): ReactNode {
        return <Dialog open={this.state.showShippingDialog}
                       onClose={() => {
                           this.setState({showShippingDialog: false})
                       }}
                       PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle style={{paddingBottom: 0}}>{i18n("请确认是否发货")}</DialogTitle>
            <DialogContent>
                <ListItem style={{padding: 0}}>
                    <ListItemText
                        primary={this.state.order.deliveryProvider && this.state.order.deliveryProvider.deliveryProviderName}
                        secondary={FssClientData.formatDatetime(this.state.order.scheduleDatetime)}/>
                </ListItem>
                <FormControlLabel
                    control={<Checkbox
                        checked={this.state.smsNotificationFlag}
                        onChange={(event) => {
                            this.setState({smsNotificationFlag: event.target.checked})
                        }}
                        name="checkedB"
                        color="primary"/>}
                    label={i18n("通知用户已发货")}
                />
                <TextField id="smsNotificationText" label={i18n("通知内容")} value={this.state.smsNotificationText}
                           style={{width: "100%"}} onChange={(event) => {
                    this.setState({smsNotificationText: event.target.value})
                }}/>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => {
                    this.setState({showShippingDialog: false})
                }}>{i18n("关闭")}</Button>
                <Button variant="outlined" onClick={this.onClickShipped} color={"primary"}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>;
    }

    onClickCancelShipping() {
        FtMessageDialog.dialog.openDialog(i18n("取消发货后订单状态将改为【拣货】状态，确定要取消发货吗？"), () => {
            FtToast.showToastLoading(i18n("取消发货"));
            FssOrderService.cancelShipping(this.state.order.id, (order: FssOrder) => {
                FtToast.closeToast();
                this.load();
            });
        });
    }

    onClickPickingOrders() {
        FtToast.showToastLoading();
        FssOrderService.pickingOrders([this.state.order.id], (msg: string) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n(msg));
            this.load();
        });
    }

    onClickPackOrders() {
        FtToast.showToastLoading();
        FssOrderService.packOrders([this.state.order.id], (msg: string) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n(msg));
            this.load();
        });
    }

    onFinishOrders() {
        FtToast.showToastLoading();
        FssOrderService.finishOrders([this.state.order.id], (msg: string) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n(msg));
            this.load();
        });
    }

    onClickResetShippedOrder() {
        FtToast.showToastLoading();
        FssOrderService.resetShippedOrders([this.state.order.id], (msg: string) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n(msg));
            this.load();
        });
    }

    getStatusSteps(): Array<string> {
        return [i18n("创建订单"), i18n("审核"), i18n("拣货"), i18n("打包"), i18n("发货"), i18n("送达完成")];
    }

    getCurrentStatusStep(): number {
        switch (this.state.order.status) {
            case EOrderStatus.CREATED:
                return 0;
            case EOrderStatus.APPROVED:
                return 1;
            case EOrderStatus.PICKING:
                return 2;
            case EOrderStatus.PACKAGING:
                return 3;
            case EOrderStatus.SHIPPED:
                return 4;
            case EOrderStatus.DELIVERED:
                return 5;
        }
        return -1;
    }

    isStepCompleted(index: number): boolean {
        switch (this.state.order.status) {
            case EOrderStatus.CREATED:
                return index == 0;
            case EOrderStatus.APPROVED:
                return index <= 1;
            case EOrderStatus.PICKING:
                return index <= 2;
            case EOrderStatus.PACKAGING:
                return index <= 3;
            case EOrderStatus.SHIPPED:
                return index <= 4;
            case EOrderStatus.DELIVERED:
                return index <= 5;
        }
        return false;
    }

    render() {
        return <div style={{display: "flex", flexDirection: "column"}}>
            {this.getToolbar()}
            <div style={{overflow: "scroll", height: document.documentElement.clientHeight - 96}}>
                <FtTabs value={this.state.tabValue} indicatorColor="primary" textColor="primary"
                        onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                            this.setState({tabValue: newValue});
                        }}>
                    <FtTabPanel label={i18n("基本信息")} value="basic">{this.getBasicInfoPanel()}</FtTabPanel>
                    <FtTabPanel label={i18n("配送跟踪")} value="shipping">{this.getShippingInfoPanel()}</FtTabPanel>
                </FtTabs>
                {this.getPaymentTransactionDialog()}
                {this.getBalanceTransactionDialog()}
                {this.getpointTransactionDialog()}
                {this.getOrderDiscountDialog()}
                {this.getRateTransactionDialog()}
                {this.getMessageBoxDialog()}
                {this.getAddProductDialog()}
                {this.getShippingDialog()}
                {this.getOrderListDialog()}

                {this.state.showRefundDialog &&
                <FssRefundDialog open={this.state.showRefundDialog}
                                 onClose={() => {
                                     this.setState({showRefundDialog: false})
                                 }}
                                 order={this.state.order}
                                 onRefundSuccess={(order: FssOrder) => {
                                     FtSnackbar.showText(i18n("退款成功"));
                                     this.setState({showRefundDialog: false});
                                     this.load();
                                 }}
                                 onRefundFail={(apiResponse: any) => {
                                     this.setState({showRefundDialog: false});
                                     if (apiResponse && apiResponse.message)
                                         FtSnackbar.showText(apiResponse.message);
                                     this.load();
                                 }}/>}
                <FssOrderPrint58 ref={(element) => this.orderPrint58 = element}
                                 order={this.state.order}/>
                <FssOrderPrintLetterSize ref={(element) => this.orderPrintLetterSize = element}
                                         order={this.state.order}/>
                <FssOrderWaybillPrint ref={(element) => this.orderWaybillPrint = element}
                                      order={this.state.order}/>
                <FssDeliveryProviderDialog ref={(element: any) => {
                    this.deliveryProviderDialog = element
                }}/>
            </div>
        </div>;
    }

    private getToolbar() {
        if (this.state.order.status == EOrderStatus.CLOSED) {
            return <FtToolbar leftNode={<div>
                <Button onClick={() => {
                    this.props.history.goBack()
                }}><ChevronLeft/>{i18n("返回")}</Button>
            </div>}>
            </FtToolbar>;
        }
        if (!this.state.changed) {
            let isParentOrder: boolean = this.state.order.parentOrder == null;
            return <FtToolbar leftNode={<div>
                <Button onClick={this.onCancelClick}><ChevronLeft/>{i18n("返回")}</Button>
                <Button onClick={() => {
                    this.onClickPrint("80")
                }}>{i18n("打印订单80")}</Button>
                <Button onClick={() => {
                    this.onClickPrint("Letter")
                }}>{i18n("打印订单信纸")}</Button>
                <Button onClick={this.onPrintWaybillClick}>{i18n("打印快递单")}</Button>
                {(this.state.order.paymentStatus == EPaymentStatus.WAIT || this.state.order.paymentStatus == EPaymentStatus.PARTIAL_PAID)
                && <Button onClick={this.onClickPaymentAction}>{i18n("确认收款")}</Button>}
                {(this.state.order.paymentType == EPaymentType.PAYNICORN ||
                    this.state.order.paymentType == EPaymentType.ONLINE_ACCOUNT_BALANCE ||
                    this.state.order.paymentType == EPaymentType.ONLINE_POINT
                ) && (this.state.order.paymentStatus == EPaymentStatus.PAID || this.state.order.paymentStatus == EPaymentStatus.PARTIAL_PAID)
                && <Button onClick={() => {
                    this.setState({showRefundDialog: true})
                }}>{i18n("退款")}</Button>}
                {this.state.order.status == EOrderStatus.APPROVED && isParentOrder &&
                <Button onClick={this.onClickPickingOrders}>{i18n("拣货")}</Button>}
                {(this.state.order.status == EOrderStatus.APPROVED
                    || this.state.order.status == EOrderStatus.PICKING) && isParentOrder &&
                <Button onClick={this.onClickPackOrders}>{i18n("打包")}</Button>}
                {this.isStepCompleted(1) && this.state.order.status != EOrderStatus.SHIPPED
                && this.state.order.status != EOrderStatus.DELIVERED && isParentOrder &&
                <Button color="primary" onClick={this.getDeliveryProvider}>{i18n("发货")}</Button>}
                {this.isStepCompleted(1) && this.state.order.status == EOrderStatus.SHIPPED && isParentOrder &&
                <Button onClick={this.onClickCancelShipping}>{i18n("取消发货")}</Button>}
                {this.state.order.status == EOrderStatus.SHIPPED && isParentOrder &&
                <Button onClick={this.onFinishOrders}>{i18n("送达完成")}</Button>}
                {this.state.order.status == EOrderStatus.DELIVERED && isParentOrder &&
                <Button color="primary" onClick={this.onClickResetShippedOrder}>{i18n("取消送达完成")}</Button>}
                {this.state.order.status == EOrderStatus.CREATED && isParentOrder &&
                <Button color="primary" onClick={this.onClickApproveOrder}>{i18n("审核通过")}</Button>}
                {this.state.order.status != EOrderStatus.DELIVERED && this.state.order.status != EOrderStatus.CANCELED
                && this.state.order.status != EOrderStatus.DELETED &&
                <Button onClick={this.onClickCancelOrder}>{i18n("取消订单")}</Button>}
                {this.state.order.status == EOrderStatus.CANCELED &&
                <Button onClick={this.onClickRestoreOrder}>{i18n("恢复订单")}</Button>}
            </div>} rightNode={<Button onClick={this.onClickSyncOrderWithDeliveryProvider}>{i18n("同步运单")}</Button>}/>
        } else {
            return <FtToolbar leftNode={<FtRow>
                <Button onClick={this.onCancelClick}><ChevronLeft/>{i18n("返回")}</Button>
                <Typography color={"primary"}>{i18n("内容已修改，请及时保存")}</Typography>
                <ButtonGroup variant={"outlined"}>
                    <Button onClick={() => {
                        this.load();
                    }}>{i18n("放弃修改")}</Button>
                    <Button color="primary" onClick={() => {
                        this.recalculateShippingFeeDialog();
                    }}>{i18n("保存")}</Button>
                </ButtonGroup>
            </FtRow>}>
            </FtToolbar>;
        }
    }

    getShippingInfoPanel() {
        if (this.state.tabValue == 'shipping') {
            return <FssOrderTrackLogComp orderId={this.state.order.id} key={this.state.order.id}/>;
        } else {
            return null;
        }
    }

    getBasicInfoPanel() {
        let isEdit: boolean = false;
        if (this.state.order.orderCode != null && this.state.order.orderCode.length > 0)
            isEdit = true;
        const steps = this.getStatusSteps();
        const currentStep = this.getCurrentStatusStep();
        let isChildOrder: boolean = this.state.order.parentOrder != null;
        return <div>
            <FtPanel panelHeader={i18n("订单信息")} marginHorizontal={4} marginVertical={4}>
                <FtGrid>
                    {this.state.order.parentOrder != null && <FtRow cellWidthS={"1"}>
                        <Alert severity="error" style={{width: "100%"}}>
                            <AlertTitle>{i18n("这是一个子订单，主订单号")}：
                                <Link to={"/orderDetail/" + this.state.order.parentOrderId}
                                      target={"_blank"}>{this.state.order.parentOrder.orderCode}</Link>
                            </AlertTitle>
                        </Alert>
                    </FtRow>}
                    {this.state.order.childOrderList != null && this.state.order.childOrderList.length > 0 &&
                    <FtRow cellWidthS={"1"}>
                        <Alert severity="error" style={{width: "100%"}}>
                            <AlertTitle>{i18n("这是一个主订单，有 {0} 个子订单", this.state.order.childOrderList.length + "")}：
                                {this.state.order.childOrderList.map((childOrder: FssOrder) => {
                                    return <Link to={"/orderDetail/" + childOrder.id}
                                                 target={"_blank"}>{childOrder.orderCode} , </Link>
                                })}
                            </AlertTitle>
                        </Alert>
                    </FtRow>}
                    {this.state.order.marketingEvent != null && this.state.order.marketingEvent.id.length > 0 &&
                    <FtRow cellWidthS={"1"}>
                        <Alert severity="error" style={{width: "100%"}}>
                            <AlertTitle>{i18n("所属团购:")}
                                <Link to={"/marketingEventDetail/" + this.state.order.marketingEvent.id}
                                      target={"_blank"}>{this.state.order.marketingEvent.name}
                                </Link>
                            </AlertTitle>
                        </Alert>
                    </FtRow>}
                    <FtRow cellWidthM={"1"}>
                        <Stepper alternativeLabel nonLinear activeStep={currentStep}>
                            {steps.map((label, index) => {
                                return <Step key={label}>
                                    <StepButton completed={this.isStepCompleted(index)}>
                                        {label}
                                    </StepButton>
                                </Step>
                            })}
                        </Stepper>
                    </FtRow>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"}>
                        {isEdit &&
                        <TextField id="orderCode" label={i18n("订单编码")} value={this.state.order.orderCode}
                                   style={{width: "100%"}} disabled={true}/>}
                        {isEdit && <TextField id="createDatetime" label={i18n("创建时间")}
                                              value={FssClientData.formatDatetime(this.state.order.createDatetime)}
                                              style={{width: "100%"}} disabled={true}/>}
                        {isEdit && <JtTextField id="userId" label={i18n("用户编码")}
                                                value={this.state.order.user.userCode}
                                                style={{width: "100%"}}
                                                disabled={true}
                                                endAdornment={
                                                    <Tooltip title={i18n("查看订单")}>
                                                        <IconButton
                                                            onClick={() => {
                                                                this.setState({"showOrderListDialog": true})
                                                            }}><ListAlt/></IconButton>
                                                    </Tooltip>}
                        />}
                        {isEdit && <TextField id="userId" label={i18n("用户手机号")}
                                              value={this.state.order.user.mobile}
                                              style={{width: "100%"}} disabled={true}/>}
                        {isEdit && <FormControl style={{width: "100%"}}
                                                disabled={true}>
                            <InputLabel> {i18n("订单状态")} </InputLabel>
                            <Select id="orderStatus"
                                    value={this.state.order.status}
                                    onChange={(event: any, child) => {
                                        this.state.order.status = event.target.value;
                                        this.forceUpdate();
                                    }}>
                                <MenuItem value={EOrderStatus.CREATED}>{i18n("已创建")}</MenuItem>
                                <MenuItem value={EOrderStatus.APPROVED}>{i18n("审核通过")}</MenuItem>
                                <MenuItem value={EOrderStatus.PICKING}>{i18n("已拣货")}</MenuItem>
                                <MenuItem value={EOrderStatus.PACKAGING}>{i18n("已打包")}</MenuItem>
                                <MenuItem value={EOrderStatus.SHIPPED}>{i18n("发货")}</MenuItem>
                                <MenuItem value={EOrderStatus.DELIVERED}>{i18n("送达完成")}</MenuItem>
                                <MenuItem value={EOrderStatus.CANCELED}>{i18n("已取消")}</MenuItem>
                                <MenuItem value={EOrderStatus.RETURNED}>{i18n("已退货")}</MenuItem>
                                <MenuItem value={EOrderStatus.DELETED}>{i18n("已删除")}</MenuItem>
                            </Select>
                        </FormControl>}
                        <JtTextField label={i18n("推广来源")} value={this.state.order.referral}
                                     disabled={true} style={{width: "100%"}}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FtPanel panelHeader={i18n(this.state.order.shippingType === EShippingType.PICK_UP ? "自取信息" : "配送信息")}
                     marginHorizontal={4} marginVertical={4}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,2"} cellWidthL={"1,3"}>
                        <FormControl style={{width: "100%"}} disabled={isChildOrder}>
                            <InputLabel>{i18n("配送方式")}</InputLabel>
                            <Select id="shippingType" value={this.state.order.shippingType}
                                    onChange={(event, child) => {
                                        this.state.order.shippingType = event.target.value as EShippingType;
                                        this.state.order.deliveryProviderId = "";
                                        this.setState({
                                            changed: true,
                                            shippingInfoChanged: this.isChangeShippingInfo("shippingType")
                                        });
                                        this.forceUpdate();
                                    }}>
                                <MenuItem value={EShippingType.DELIVERY_TO_HOME}>{i18n("送货上门")}</MenuItem>
                                <MenuItem value={EShippingType.PICK_UP}>{i18n("自取")}</MenuItem>
                            </Select>
                        </FormControl>
                        {this.getDeliveryProviderComp()}
                    </FtRow>
                    {this.getScheduleDateComp()}
                    {this.state.order.shippingType === EShippingType.DELIVERY_TO_HOME &&
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"}>
                        <TextField value={this.state.order.shipToCountry} id={"shipToCountry"}
                                   label={i18n("国家")}
                                   style={{width: "100%", textAlign: "left"}} disabled={true}/>
                        <TextField id="shipToProvince" select label={i18n("省份")} value={this.state.order.shipToProvince}
                                   onChange={(event: any) => {
                                       this.state.order.shipToProvince = event.target.value;
                                       this.setState({
                                           changed: true,
                                           shippingInfoChanged: this.isChangeShippingInfo("shipToProvince")
                                       });
                                       this.forceUpdate();
                                   }}
                                   style={{width: "100%"}} disabled={isChildOrder}>
                            {this.state.country.subItems != null && this.state.country.subItems.length > 0 &&
                            this.state.country.subItems.map(province => {
                                return <MenuItem value={province.name}>{province.name}</MenuItem>
                            })}
                        </TextField>
                        <TextField id="shipToCity" label={i18n("收件人市")} value={this.state.order.shipToCity}
                                   onChange={this.onChange} style={{width: "100%"}} disabled={isChildOrder}/>
                    </FtRow>}
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"}>
                        <TextField id="shipToPostcode" label={i18n("邮编")}
                                   value={this.state.order.shipToPostcode}
                                   onChange={this.onChange} style={{width: "100%"}} disabled={isChildOrder}/>
                        <TextField id="shipToUnitNo" label="Unit#"
                                   value={this.state.order.shipToUnitNo}
                                   onChange={this.onChange} style={{width: "100%"}} disabled={isChildOrder}/>
                    </FtRow>
                    {this.state.order.shippingType === EShippingType.DELIVERY_TO_HOME &&
                    <FtRow cellWidthS={"3,1"}>
                        {this.getAddressInput()}
                    </FtRow>}
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"}>
                        <TextField id="shipToName" label={i18n("联系人")} value={this.state.order.shipToName}
                                   onChange={this.onChange} style={{width: "100%"}} disabled={isChildOrder}/>
                        <TextField id="shipToMobile" label={i18n("联系电话")}
                                   value={this.state.order.shipToMobile}
                                   onChange={this.onChange} style={{width: "100%"}} disabled={isChildOrder}/>
                    </FtRow>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"}>
                        <TextField id="shipToComment" label={i18n("用户地址备注")} value={this.state.order.shipToComment}
                                   onChange={this.onChange} style={{width: "100%"}} disabled={isChildOrder}/>
                    </FtRow>
                    {this.state.order.shippingType === EShippingType.PICK_UP &&
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"}>
                        <TextField label={i18n("自取点联系人")}
                                   value={this.state.order.deliveryProvider ? this.state.order.deliveryProvider.contactName : ""}
                                   style={{width: "100%"}} disabled={true}/>
                        <TextField label={i18n("自取点电话")}
                                   value={this.state.order.deliveryProvider ? this.state.order.deliveryProvider.contactPhone : ""}
                                   style={{width: "100%"}} disabled={true}/>
                        <TextField label={i18n("自取点Email")}
                                   value={this.state.order.deliveryProvider ? this.state.order.deliveryProvider.contactEmail : ""}
                                   style={{width: "100%"}} disabled={true}/>
                    </FtRow>}
                    {this.state.order.shippingType === EShippingType.PICK_UP &&
                    <FtRow cellWidthS={"1"}>
                        <TextField label={i18n("自取点地址")}
                                   value={this.state.order.deliveryProvider ? this.state.order.deliveryProvider.pickUpAddress : ""}
                                   style={{width: "75%"}} disabled={true}/>
                        <TextField label={i18n("简述")}
                                   value={this.state.order.deliveryProvider ? this.state.order.deliveryProvider.shortDescription : ""}
                                   style={{width: "75%"}} disabled={true}/>
                    </FtRow>}
                </FtGrid>
            </FtPanel>
            <FtPanel panelHeader={i18n("支付信息")} marginHorizontal={4} marginVertical={4}>
                <FtGrid>
                    <FtRow cellHorizontalSpace={8} style={{alignItems: "flex-end"}}>
                        <TextField id="paymentTypeText" label={i18n("支付方式")}
                                   value={i18n(this.state.order.paymentTypeText)}
                                   style={{width: 100}} disabled={true}/>
                        <TextField id="paymentStatusText" label={i18n("支付状态")}
                                   value={i18n(this.state.order.sellerPaymentStatusText)}
                                   style={{width: 100}} disabled={true}/>
                        <TextField id="total" label={i18n("总金额")}
                                   value={this.state.order.total}
                                   style={{width: 100}} disabled={true} inputProps={{
                            style: {textAlign: "right", color: red[500]},
                        }}/>
                        <FtTextFieldNumber id="otherFee" type={"currency"} label={i18n("总金额调整")}
                                           value={this.state.order.otherFee}
                                           style={{width: 100}} inputStyle={{textAlign: "right"}}
                                           onChangeValue={(value?: number | undefined) => {
                                               if (value)
                                                   this.state.order.otherFee = value;
                                               else
                                                   this.state.order.otherFee = 0;
                                               this.calculate();
                                               this.setState({changed: true});
                                           }}/>
                        <JtTextField id="totalPaid" label={i18n("已支付金额")} type={"float"} textAlign={"right"}
                                     value={FtUtil.toCurrency(this.state.order.totalPaid)}
                                     style={{width: 150}} disabled={true}
                                     endAdornment={
                                         <Tooltip title={i18n("查看支付记录")}>
                                             <IconButton
                                                 onClick={this.onClickShowPaymentTransaction}><ListAlt/></IconButton>
                                         </Tooltip>}/>
                        <JtTextField id="balanceDeductibleAmount" label={i18n("余额支付")}
                                     value={FtUtil.toCurrency(this.state.order.balanceUsed)}
                                     style={{width: 200}} onChange={this.onChange} textAlign={"right"} disabled={true}
                                     endAdornment={
                                         <Tooltip title={i18n("查看余额记录")}>
                                             <IconButton
                                                 onClick={this.onClickShowBalanceTransaction}><ListAlt/></IconButton>
                                         </Tooltip>}/>
                        <JtTextField id="balanceDeductibleAmount" label={i18n("积分支付")}
                                     value={FtUtil.toCurrency(this.state.order.pointDeducted)}
                                     style={{width: 200}} onChange={this.onChange} textAlign={"right"} disabled={true}
                                     endAdornment={
                                         <Tooltip title={i18n("查看积分记录")}>
                                             <IconButton
                                                 onClick={this.onClickShowpointTransaction}><ListAlt/></IconButton>
                                         </Tooltip>}/>
                        <JtTextField id="unpaidMoney" label={i18n("待收款项")}
                                     value={FtUtil.toCurrency(this.state.order.unpaidMoney)}
                                     style={{width: 200}} textAlign={"right"} disabled={true}/>
                        {(this.state.order.paymentType == EPaymentType.PAYNICORN ||
                            this.state.order.paymentType == EPaymentType.ONLINE_ACCOUNT_BALANCE ||
                            this.state.order.paymentType == EPaymentType.ONLINE_POINT) &&
                        this.state.order.total < this.state.order.totalPaid &&
                        <Button variant={"outlined"} color={"primary"} disabled={this.state.changed}
                                onClick={this.onClickRefundPartial}>
                            {i18n("退款（差额 {0} ）", FtUtil.toCurrencyText(this.state.order.total - this.state.order.totalPaid))}</Button>}
                    </FtRow>
                </FtGrid>
                <FtGrid>
                    <FtRow cellHorizontalSpace={8}>
                        <TextField id="subtotalProduct" label={i18n("商品金额")}
                                   value={this.state.order.subtotalProduct}
                                   style={{width: 100}} disabled={true} inputProps={{
                            style: {textAlign: "right"},
                        }}/>
                        <TextField id="totalTax" label={i18n("税费")}
                                   value={FtUtil.toCurrency(this.state.order.tax)}
                                   style={{width: 100}} disabled={true} inputProps={{
                            style: {textAlign: "right"},
                        }}/>
                        <JtTextField type="float" id="shippingFee"
                                     label={i18n(this.state.order.shippingType == EShippingType.DELIVERY_TO_HOME ? "用户运费" : "自取手续费")}
                                     value={this.state.order.shippingFee} textAlign={"right"}
                                     style={{width: 100}} onChange={this.onChange}
                                     endAdornment={this.state.order.shippingType == EShippingType.DELIVERY_TO_HOME &&
                                     <Tooltip title={i18n("查看运费描述")}>
                                         <IconButton
                                             onClick={() => {
                                                 FtMessageDialog.dialog.openDialog(this.state.order.shippingFeeDescription)
                                             }}><Help/></IconButton>
                                     </Tooltip>}/>
                        <TextField type="number" id="totalWeight" label={i18n("总重量(克)")}
                                   value={this.state.order.totalWeight} disabled={true}
                                   style={{width: 100}} onChange={this.onChange} inputProps={{
                            style: {textAlign: "right"},
                        }}/>
                        <TextField type="number" id="totalSize" label={i18n("总体积(立方米)")}
                                   value={this.state.order.totalSize} disabled={true}
                                   style={{width: 120}} onChange={this.onChange} inputProps={{
                            style: {textAlign: "right"},
                        }}/>
                        <TextField type="number" id="quantity" label={i18n("总商品件数")}
                                   value={this.state.order.quantity} disabled={true}
                                   style={{width: 100}} onChange={this.onChange} inputProps={{
                            style: {textAlign: "right"},
                        }}/>
                        <TextField type="number" id="packageFee" label={i18n("包装费")}
                                   value={this.state.order.packageFee}
                                   style={{width: 100}} onChange={this.onChange} inputProps={{
                            style: {textAlign: "right"},
                        }}/>
                        <JtTextField id="paymentFee" label={i18n("手续费")} disabled={true}
                                     value={this.state.order.paymentFee} type={"currency"}
                                     style={{width: 100}} onChange={this.onChange} inputProps={{
                            style: {textAlign: "right"},
                        }}/>
                        <JtTextField id="discount" label={i18n("总折扣")} disabled={true}
                                     value={this.state.order.discount}
                                     style={{width: 100}} inputProps={{style: {textAlign: "right"},}}
                                     endAdornment={
                                         <Tooltip title={i18n("查看折扣明细")}>
                                             <IconButton onClick={this.onClickShowOrderDiscount}>
                                                 <ListAlt/>
                                             </IconButton>
                                         </Tooltip>}/>
                        {/*<FormControl style={{minWidth: 100}}>*/}
                        {/*    <InputLabel>{i18n("小费比例")}</InputLabel>*/}
                        {/*    <Select id="tipsPercent" value={this.state.order.tipsPercent}*/}
                        {/*            onChange={(event, child) => {*/}
                        {/*                this.state.order.tipsPercent = event.target.value as number;*/}
                        {/*                this.calculate();*/}
                        {/*                this.setState({changed: true});*/}
                        {/*                this.forceUpdate();*/}
                        {/*            }}>*/}
                        {/*        <MenuItem value={0}>0</MenuItem>*/}
                        {/*        <MenuItem value={0.1}>10%</MenuItem>*/}
                        {/*        <MenuItem value={0.12}>12%</MenuItem>*/}
                        {/*        <MenuItem value={0.15}>15%</MenuItem>*/}
                        {/*        <MenuItem value={0.2}>20%</MenuItem>*/}
                        {/*        <MenuItem value={0.25}>25%</MenuItem>*/}
                        {/*        <MenuItem value={0.3}>30%</MenuItem>*/}
                        {/*    </Select>*/}
                        {/*</FormControl>*/}
                        <TextField id="tips" label={i18n("小费金额")} value={this.state.order.tips}
                                   style={{width: 100}} disabled={true} inputProps={{
                            style: {textAlign: "right"},
                        }}/>
                        {"CANADA" == this.state.order.shipToCountry &&
                        <TextField id="tips" label={i18n("运费税")}
                                   value={FtUtil.toCurrency(this.state.order.shippingFee * 0.05)}
                                   style={{width: 100}} disabled={true} inputProps={{
                            style: {textAlign: "right"},
                        }}/>}
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FtPanel panelHeader={i18n("评论")} marginHorizontal={4} marginVertical={4}>
                <FtGrid>
                    <FtRow cellWidthM={"1"}>
                        <TextField id="userComment" label={i18n("用户备注")} value={this.state.order.userComment}
                                   onChange={this.onChange} style={{width: "100%"}}/>
                        <TextField id="serviceComment" label={i18n("客服备注")}
                                   value={this.state.order.serviceComment}
                                   onChange={this.onChange} style={{width: "100%"}}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
            {this.getItemTable()}
        </div>;
    }

    getDeliveryProviderComp(): ReactNode {
        let label = "";
        let showTrackingNumber = false;
        if (this.state.order.shippingType === EShippingType.PICK_UP) {
            label = "自取点";
        } else if (this.state.order.shippingType === EShippingType.DELIVERY_TO_HOME) {
            label = "配送服务商";
            showTrackingNumber = true;
        }
        return <FtRow cellWidthS={"1"} cellWidthM={"1,1"} cellWidthL={"1,1,1"}>
            <JtTextField id="deliveryProviderId" label={i18n(label)} disabled={true} style={{width: "100%"}}
                         value={this.getDeliveryProviderName()}
                         endAdornment={(this.state.order.status === EOrderStatus.CREATED ||
                             this.state.order.status === EOrderStatus.APPROVED ||
                             this.state.order.status === EOrderStatus.PICKING ||
                             this.state.order.status === EOrderStatus.PACKAGING ||
                             this.state.order.shippingType === EShippingType.DELIVERY_TO_HOME ||
                             this.state.order.shippingType === EShippingType.PICK_UP) &&
                         this.state.order.parentOrder === null && <Tooltip title={i18n("修改" + label)}>
                             <IconButton onClick={() => {
                                 this.deliveryProviderDialog.show(this.state.order, this.onSubmitDone);
                             }}><Edit/></IconButton>
                         </Tooltip>}/>
            {showTrackingNumber &&
            <JtTextField id="trackingNumber"
                         label={i18n("运单号")}
                         style={{width: "100%"}}
                         value={this.state.order.trackingNumber}
                         onChange={(event: any) => {
                             this.state.order.trackingNumber = event.target.value;
                             this.setState({
                                 changed: true,
                             });
                             this.forceUpdate();
                         }}
                         helperText={"请不要修改该运单号，以免影响运单跟踪"}/>}
        </FtRow>
    }

    getScheduleDateComp(): ReactNode {
        let label = "";
        if (this.state.order.shippingType === EShippingType.PICK_UP) {
            label = "取货";
        } else if (this.state.order.shippingType === EShippingType.DELIVERY_TO_HOME) {
            label = "预约配送";
        }
        return <FtRow cellWidthS={"1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1,1"}>
            <TextField label={i18n(label + "日期")}
                       type="date"
                       id={"scheduleDate"}
                       value={this.state.order.scheduleDate}
                       style={{width: "100%", minWidth: 140}}
                       InputLabelProps={{shrink: true,}}
                       onChange={this.onChange}/>
            <TextField select label={i18n(label + "时段")}
                       id={"scheduleTime"}
                       value={this.state.order.scheduleTime}
                       onChange={(event) => {
                           FtUtil.setProperty(this.state.order, "scheduleTime", event.target.value);
                           this.setState({
                               changed: true,
                               shippingInfoChanged: this.isChangeShippingInfo("scheduleTime")
                           });
                       }}
                       style={{width: "100%"}}>
                <MenuItem key={"00:00:00"} value={"00:00:00"}>00:00 - 02:00</MenuItem>
                <MenuItem key={"02:00:00"} value={"02:00:00"}>02:00 - 04:00</MenuItem>
                <MenuItem key={"04:00:00"} value={"04:00:00"}>04:00 - 06:00</MenuItem>
                <MenuItem key={"06:00:00"} value={"06:00:00"}>06:00 - 08:00</MenuItem>
                <MenuItem key={"08:00:00"} value={"08:00:00"}>08:00 - 10:00</MenuItem>
                <MenuItem key={"10:00:00"} value={"10:00:00"}>10:00 - 12:00</MenuItem>
                <MenuItem key={"12:00:00"} value={"12:00:00"}>12:00 - 14:00</MenuItem>
                <MenuItem key={"14:00:00"} value={"14:00:00"}>14:00 - 16:00</MenuItem>
                <MenuItem key={"16:00:00"} value={"16:00:00"}>16:00 - 18:00</MenuItem>
                <MenuItem key={"18:00:00"} value={"18:00:00"}>18:00 - 20:00</MenuItem>
                <MenuItem key={"20:00:00"} value={"20:00:00"}>20:00 - 22:00</MenuItem>
                <MenuItem key={"22:00:00"} value={"22:00:00"}>22:00 - 24:00</MenuItem>
            </TextField>
        </FtRow>
    }

    onSubmitDone(order: FssOrder): void {
        this.setState({order: order, changed: true, shippingInfoChanged: true});
    }

    private getItemTable() {
        return <FtPanel panelHeader={i18n("商品信息")} marginHorizontal={4} marginVertical={4}>
            <FtGrid>
                <FtRow cellWidthS={"1"} cellWidthM={"3,2,3"} style={{backgroundColor: grey[300]}}>
                    <Typography>{i18n("商品")}</Typography>
                    <Typography>{i18n("描述")}</Typography>
                    <FtRow cellHorizontalSpace={8} cellWidthS={"1,1,1,1,2"}>
                        <Typography>{i18n("单价")}</Typography>
                        <Typography>{i18n("数量")}</Typography>
                        <Typography>{i18n("金额（不含税）")}</Typography>
                        <Typography>{i18n("国内物流公司")}</Typography>
                        <Typography>{i18n("国内运单号")}</Typography>
                    </FtRow>
                </FtRow>
                {this.state.order.items != null && this.state.order.items.map((orderItem: FssOrderItem, index: number) => {
                    return <FtRow cellWidthS={"1"} cellWidthM={"3,2,3"}
                                  style={{backgroundColor: index % 2 == 0 ? "white" : grey[200]}}>
                        <JtTextField value={orderItem.productName} disabled={true}
                                     style={{width: "100%"}}
                                     endAdornment={
                                         <div style={{display: "flex"}}>
                                             <Link to={"/productDetail/" + orderItem.productId} target={"_blank"}>
                                                 <Tooltip title={i18n("商品详情")}>
                                                     <IconButton color="secondary"><LinkIcon/></IconButton>
                                                 </Tooltip>
                                             </Link>
                                             <Tooltip title={i18n("修改商品")}>
                                                 <IconButton color="secondary"
                                                             disabled={this.state.order.status === EOrderStatus.CANCELED}
                                                             onClick={() => {
                                                                 this.setState({
                                                                     openItemEditDialog: true,
                                                                     editOrderItem: orderItem
                                                                 })
                                                             }}>
                                                     <EditIcon/>
                                                 </IconButton>
                                             </Tooltip>
                                         </div>
                                     }/>
                        <TextField id="orderItem.specification"
                                   value={orderItem.productCharacteristic}
                                   style={{width: "100%"}}
                                   disabled={true}/>
                        <FtRow cellHorizontalSpace={8} cellWidthS={"1,1,1,1,2"}>
                            <FtTextFieldNumber id="orderItem.price" value={orderItem.price}
                                               type="currency" style={{width: "100%"}} disabled={true}
                                               inputStyle={{textAlign: "center"}}
                                               onChangeValue={value => this.onChangeItem(index, "price", value)}/>
                            <FtTextFieldNumber id="orderItem.quantity" value={orderItem.quantity}
                                               style={{width: "100%"}} disabled={true}
                                               type="integer" inputStyle={{textAlign: "center"}}
                                               onChangeValue={value => this.onChangeItem(index, "quantity", value)}/>
                            <div style={{width: "100%"}}>{orderItem.subtotal}</div>
                            <FtTextFieldString id="orderItem.expressName" value={orderItem.expressName}
                                               disabled={true}/>
                            <FtTextFieldString id="orderItem.trackingNumber" value={orderItem.trackingNumber}
                                               disabled={true}/>
                        </FtRow>
                        {orderItem.comment != null && orderItem.comment.length > 0
                        && <Typography variant={"subtitle2"} color={"secondary"}>{orderItem.comment}</Typography>}
                    </FtRow>
                })}
            </FtGrid>
        </FtPanel>;
    }

    getAddProductDialog() {
        if (this.state.editOrderItem) {
            return <FssOrderItemEditDialog
                open={this.state.openItemEditDialog}
                onClose={() => {
                    this.setState({openItemEditDialog: false, editOrderItem: undefined});
                }}
                onItemChanged={this.onItemChanged}
                orderItem={this.state.editOrderItem}
                key={this.state.editOrderItem ? this.state.editOrderItem.lineNo : "new"}/>;
        } else {
            return null;
        }
    }

    onItemChanged(newPrice: number, newQuantity: number, subtotal: number, comment: string, expressName: string, trackingNumber: string) {
        if (this.state.editOrderItem) {
            this.state.editOrderItem.quantity = newQuantity;
            this.state.editOrderItem.price = newPrice;
            this.state.editOrderItem.subtotal = subtotal;
            this.state.editOrderItem.comment = comment;
            this.state.editOrderItem.expressName = expressName;
            this.state.editOrderItem.trackingNumber = trackingNumber;
            this.calculate();
            this.setState({changed: true});
        }
    }


    getMessageBoxDialog(): ReactNode {
        return <Dialog open={this.state.openMessageBoxDialog} onClose={(e) => {
            this.setState({openMessageBoxDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("发货失败")}</DialogTitle>
            <DialogContent>
                {i18n("订单已取消，不允许做『发货』操作")}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    onChangeSmsContent() {
        let smsContent = "您的订单#" + this.state.order.orderCode + "还有" + FtUtil.toCurrencyText(this.state.order.total - this.state.order.totalPaid) + "未支付，请尽快支付";
        this.setState({smsContent: smsContent});
    }

    getOrderListDialog(): ReactNode {
        let filterColumns = new Map<string, any>();
        filterColumns.set("orderUserId", this.state.order.user.id);
        let defaultFilter = new Map<string, any>();
        defaultFilter.set("orderStatus", [EOrderStatus.CREATED, EOrderStatus.APPROVED,
            EOrderStatus.PICKING, EOrderStatus.PACKAGING, EOrderStatus.SHIPPED, EOrderStatus.DELIVERED]);
        return <Dialog open={this.state.showOrderListDialog} onClose={(e) => {
            this.setState({showOrderListDialog: false});
        }} PaperProps={{style: {minWidth: 1000}}}>
            <DialogContent>
                <FssOrderListPage key={this.props.location.pathname}
                                  history={this.props.history}
                                  location={this.props.location}
                                  match={this.props.match}
                                  orderPageType={EOrderPageType.FIND_ORDER}
                                  filterColumns={filterColumns}
                                  isProductDetailPage={true}
                />;
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({showOrderListDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    getPaymentTransactionDialog(): ReactNode {
        return <Dialog open={this.state.showPaymentTransactionDialog} onClose={(e) => {
            this.setState({showPaymentTransactionDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("支付记录")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n("时间")}</TableCell>
                            <TableCell>{i18n("交易类型")}</TableCell>
                            <TableCell>{i18n("状态")}</TableCell>
                            <TableCell align={"right"}>{i18n("金额")}</TableCell>
                            <TableCell>{i18n("支付网关")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.paymentTransactionList.map((paymentTransaction: FssPaymentTransaction, index: number) => {
                        return <TableRow>
                            <TableCell>{paymentTransaction.createDatetimeText}</TableCell>
                            <TableCell>{paymentTransaction.typeText}</TableCell>
                            <TableCell>{paymentTransaction.statusText} {paymentTransaction.status == EStatus.FAILED && paymentTransaction.failedReason}</TableCell>
                            <TableCell
                                align={"right"}>{paymentTransaction.money && FtUtil.toCurrencyText(paymentTransaction.money)}</TableCell>
                            <TableCell>{paymentTransaction.paymentAccount && paymentTransaction.paymentAccount.paymentAccountName}</TableCell>
                        </TableRow>
                    })}
                </Table>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({showPaymentTransactionDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    getBalanceTransactionDialog(): ReactNode {
        return <Dialog open={this.state.showBalanceTransactionDialog} onClose={(e) => {
            this.setState({showBalanceTransactionDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("余额记录")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n("时间")}</TableCell>
                            <TableCell>{i18n("交易类型")}</TableCell>
                            <TableCell align={"right"}>{i18n("金额")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.balanceTransactionList && this.state.balanceTransactionList.map((balanceTransaction: FssUserBalanceTransaction, index: number) => {
                        return <TableRow>
                            <TableCell>{balanceTransaction.createDatetimeText}</TableCell>
                            <TableCell>{balanceTransaction.type}</TableCell>
                            <TableCell
                                align={"right"}>
                                {balanceTransaction.type == EBalanceType.OUT &&
                                <Typography
                                    style={{color: green[500]}}>-{balanceTransaction.balanceAmount && FtUtil.toCurrencyText(balanceTransaction.balanceAmount)}</Typography>}
                                {balanceTransaction.type == EBalanceType.IN &&
                                <Typography
                                    style={{color: red[500]}}>{balanceTransaction.balanceAmount && FtUtil.toCurrencyText(balanceTransaction.balanceAmount)}</Typography>}
                            </TableCell>
                        </TableRow>;
                    })}
                </Table>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({showBalanceTransactionDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    getpointTransactionDialog(): ReactNode {
        return <Dialog open={this.state.showpointTransactionDialog} onClose={(e) => {
            this.setState({showpointTransactionDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("积分记录")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n("时间")}</TableCell>
                            <TableCell>{i18n("交易类型")}</TableCell>
                            <TableCell align={"right"}>{i18n("积分")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.pointTransactionList && this.state.pointTransactionList.map((pointTransaction: FssPointTransaction, index: number) => {
                        return <TableRow>
                            <TableCell>{pointTransaction.createDatetimeText}</TableCell>
                            <TableCell>{pointTransaction.type}</TableCell>
                            <TableCell
                                align={"right"}>
                                {pointTransaction.type == EScoreType.OUT &&
                                <Typography style={{color: green[500]}}>{pointTransaction.pointAmount}</Typography>}
                                {pointTransaction.type == EScoreType.IN &&
                                <Typography style={{color: red[500]}}>{pointTransaction.pointAmount}</Typography>}
                            </TableCell>
                        </TableRow>;
                    })}
                </Table>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({showpointTransactionDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    getOrderDiscountDialog(): ReactNode {
        return <Dialog open={this.state.showOrderDiscountDialog} onClose={(e) => {
            this.setState({showOrderDiscountDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("折扣明细")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n("优惠券ID")}</TableCell>
                            <TableCell>{i18n("优惠券名字")}</TableCell>
                            <TableCell>{i18n("描述")}</TableCell>
                            <TableCell align={"right"}>{i18n("折扣")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.orderDiscountList && this.state.orderDiscountList.map((orderDiscount: FssOrderDiscount) => {
                        return <TableRow>
                            <TableCell>{orderDiscount.couponId}</TableCell>
                            <TableCell>{orderDiscount.discountTitle}</TableCell>
                            <TableCell>{orderDiscount.discountDescription}</TableCell>
                            <TableCell align={"right"}>{orderDiscount.discount}</TableCell>
                        </TableRow>;
                    })}
                </Table>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({showOrderDiscountDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    getRateTransactionDialog(): ReactNode {
        return <Dialog open={this.state.showRateTransactionDialog} onClose={(e) => {
            this.setState({showRateTransactionDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("税费记录")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n("税/费")}</TableCell>
                            <TableCell>{i18n("金额")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.taxTransactionList && this.state.taxTransactionList.map((orderItemtax: FssOrderItemTax, index: number) => {
                        return <TableRow>
                            <TableCell>{orderItemtax.taxName.taxName}</TableCell>
                            <TableCell>{orderItemtax.tax}</TableCell>
                        </TableRow>;
                    })}
                </Table>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({showRateTransactionDialog: false});
                }}>关闭</Button>
            </DialogActions>
        </Dialog>
    }

    private getAddressInput(): ReactNode {
        return <TextField id="shipToAddress" label={i18n("详细地址")} value={this.state.order.shipToAddress}
                          onChange={this.onChange} style={{width: "100%"}}/>;
    }

    onClickShowPaymentTransaction() {
        FtToast.showToastLoading(i18n("获取支付记录"));
        FssOrderService.getPaymentTransactionListByOrderId(this.state.order.id, (data: Array<FssPaymentTransaction>) => {
            FtToast.closeToast();
            this.setState({paymentTransactionList: data, showPaymentTransactionDialog: true});
        });
    }

    onClickShowBalanceTransaction() {
        FtToast.showToastLoading(i18n("获取余额记录"));
        FssOrderService.getBalanceTransactionListByOrderId(this.state.order.id, (data: Array<FssUserBalanceTransaction>) => {
            FtToast.closeToast();
            this.setState({balanceTransactionList: data, showBalanceTransactionDialog: true});
        });
    }

    onClickShowpointTransaction() {
        FtToast.showToastLoading(i18n("获取积分记录"));
        FssOrderService.getPointTransactionListByOrderId(this.state.order.id, (data: Array<FssPointTransaction>) => {
            FtToast.closeToast();
            this.setState({pointTransactionList: data, showpointTransactionDialog: true});
        });
    }

    onClickShowOrderDiscount() {
        FtToast.showToastLoading(i18n("获取折扣明细"));
        FssOrderService.getOrderDiscountByOrderId(this.state.order.id, (data: Array<FssOrderDiscount>) => {
            FtToast.closeToast();
            this.setState({orderDiscountList: data, showOrderDiscountDialog: true});
        });
    }

    onClickShowRateTransaction(index: number) {
        FtToast.showToastLoading(i18n("获取税费记录"));
        let orderItem: FssOrderItem = this.state.order.items[index];
        FssOrderService.getTaxListByOrderItemId(orderItem.id, (data: Array<FssOrderItemTax>) => {
            FtToast.closeToast();
            this.setState({taxTransactionList: data, showRateTransactionDialog: true});
        });
    }

    onClickPaymentAction() {
        if (this.state.changed)
            FtSnackbar.showText(i18n("订单内容已修改，请先保存后再执行此操作"));
        else {
            //收款
            const options: Array<FtOptionDialogItem> = new Array<FtOptionDialogItem>();
            options.push(new FtOptionDialogItem(EPaymentType.OFFLINE_COD, EPaymentType.OFFLINE_COD, i18n("线下支付")));
            // options.push(new FtOptionDialogItem(EPaymentType.ONLINE_EMT, EPaymentType.ONLINE_EMT, "Email Transfer"));
            // options.push(new FtOptionDialogItem(EPaymentType.OFFLINE_CHEQUE, EPaymentType.OFFLINE_CHEQUE, i18n("支票")));
            options.push(new FtOptionDialogItem(EPaymentType.ONLINE_CUSTOMER_SERVICE, EPaymentType.ONLINE_CUSTOMER_SERVICE, i18n("客服线上收取")));
            options.push(new FtOptionDialogItem(EPaymentType.OFFLINE_TRANSFER, EPaymentType.OFFLINE_TRANSFER, i18n("其他支付方式")));
            FtOptionDialog.dialog.openDialog(<div>
                <Typography variant={"h5"}
                            color={"primary"}>{i18n("收款金额")}：{FtUtil.toCurrencyText(this.state.order.total - this.state.order.totalPaid)}</Typography>
                <Typography variant={"subtitle1"}>{i18n("请在收到款项后，执行收款操作。请选择支付方式")}</Typography>
            </div>, options, (option: FtOptionDialogItem, index: number) => {
                FtOptionDialog.dialog.closeDialog();
                FtToast.showToastLoading(i18n(i18n("执行收款操作")));
                FssOrderService.confirmOrderPayment(this.state.order.id, option.value, (order: FssOrder) => {
                    FtToast.closeToast();
                    this.load();
                });
            });
        }
    }

    onClickRefundPartial() {
        this.setState({showRefundDialog: true});
    }

    onClickPaymentPartial() {
        FtMessageDialog.dialog.openDialog(i18n("确定要使用信用卡扣款，补差额 {0}元吗？", FtUtil.toCurrencyText(this.state.order.unpaidMoney)), () => {
            FtToast.showToastLoading();
            FssOrderService.creditPaymentPartial(this.state.order.id, (order: FssOrder) => {
                FtToast.closeToast();
                this.load();
            });
        })
    }

    onClickApproveOrder() {
        FtToast.showToastLoading();
        FssOrderService.approveOrder(this.state.order.id, (order: FssOrder) => {
            FtToast.closeToast();
            this.load();
        });
    }

    private onClickCancelOrder() {
        FtMessageDialog.dialog.openDialog(i18n("取消订单时会同时退款给用户，此操作不可撤销，确定要取消订单吗？"), () => {
            FtToast.showToastLoading();
            FssOrderService.cancelOrder(this.state.order.id, (order: FssOrder) => {
                FtToast.closeToast();
                this.load();
            });
        })
    }

    private onClickRestoreOrder() {
        if (this.state.order.parentOrderId && this.state.order.parentOrderId.length > 0) {
            let parentOrder = this.state.order.parentOrder;
            if (parentOrder && parentOrder.status != EOrderStatus.CREATED && parentOrder.status != EOrderStatus.APPROVED &&
                parentOrder.status != EOrderStatus.PICKING && parentOrder.status != EOrderStatus.PACKAGING) {
                FtSnackbar.showText(i18n("主订单" + parentOrder.orderCode + "状态为" + parentOrder.statusText + "，不能恢复子订单"));
                return;
            }
        }
        let msg = "订单状态将恢复成『已提交』，支付状态为『未支付』，确定要恢复订单吗？";
        if (this.state.order.paymentStatus == EPaymentStatus.PAID ||
            this.state.order.paymentStatus == EPaymentStatus.OVER_PAID) {
            msg = "订单状态将恢复成『已审核』，支付状态为『" + this.state.order.paymentStatusText + "』，确定要恢复订单吗？";
        } else if (this.state.order.paymentStatus == EPaymentStatus.PARTIAL_PAID) {
            msg = "订单状态将恢复成『已提交』，支付状态为『部分支付』，确定要恢复订单吗？";
        }
        FtMessageDialog.dialog.openDialog(i18n(msg), () => {
            FtToast.showToastLoading();
            FssOrderService.restoreOrders([this.state.order.id], (msg: string) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n(msg));
                this.load();
            }, (data: FtApiResponse) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n("操作失败 ") + data.message);
            });
        });
    }

    getDeliveryProviderName() {
        if (this.state.order.deliveryProviderId && this.state.order.deliveryProviderId.trim().length > 0) {
            let deliveryProvider = lodash.find(this.state.deliveryProviderList, (deliveryProvider: FssDeliveryProvider) => {
                return deliveryProvider.id === this.state.order.deliveryProviderId;
            })
            if (deliveryProvider !== undefined) {
                return deliveryProvider.deliveryProviderName;
            }
            return "其他配送商";
        } else {
            return "";
        }
    }

    calculate(): void {
        let totalQuantity = 0;
        let subtotalProduct = 0;
        let totalTax = 0;
        this.state.order.itemsMap = {};
        if (this.state.order.items) {
            for (let i = 0; i < this.state.order.items.length; i++) {
                if (this.state.order.items[i].quantity > 0) {
                    totalQuantity += this.state.order.items[i].quantity;
                    this.state.order.items[i].subtotal = FtUtil.toCurrency(this.state.order.items[i].price * this.state.order.items[i].quantity);
                    subtotalProduct += this.state.order.items[i].subtotal;
                    // 税费 tax 没有重算
                    totalTax += this.state.order.items[i].tax;
                    this.state.order.itemsMap[this.state.order.items[i].productId] = this.state.order.items[i];
                }
            }
        }
        this.state.order.quantity = totalQuantity;
        this.state.order.subtotalProduct = FtUtil.toCurrency(subtotalProduct);
        this.state.order.tax = FtUtil.toCurrency(totalTax);
        if (this.state.order.tipsPercent && this.state.order.tipsPercent > 0) {
            this.state.order.tips = FtUtil.toCurrency((this.state.order.subtotalProduct + this.state.order.shippingFee + this.state.order.discount) * this.state.order.tipsPercent);
        }
        if (this.state.order.tips < 0) {
            this.state.order.tips = 0;
        }
        this.state.order.total = FtUtil.toCurrency(this.state.order.subtotalProduct + this.state.order.shippingFee + this.state.order.discount + this.state.order.tax + this.state.order.packageFee + this.state.order.otherFee + this.state.order.tips + this.state.order.paymentFee);
        this.forceUpdate();
    }

    onClickSyncOrderWithDeliveryProvider() {
        FtToast.showToastLoading("正在同步运单信息，此过程可能持续数分钟，请耐心等等", 180 * 1000);
        FssOrderService.syncWithDeliveryProvider([this.state.order.id], (message: string) => {
            FtToast.closeToast();
            FtSnackbar.showText(message);
        });
    }
}

