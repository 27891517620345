import * as React from "react";
import {ReactNode} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtTranslation from "../model/FtTranslation";
import FtToast from "../ui/FtToast";
import FssTranslationService from "../service/FssTranslationService";
import FtPagedList from "../model/FtPagedList";
import {i18n} from "../util/I18n";
import FtState from "../ui/FtState";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtSnackbar from "../ui/FtSnackbar";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@material-ui/core";
import {Close, DeleteOutline} from "@material-ui/icons";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import {Language} from "../data/AppClientInfo";


interface FtTranslationListPageProp {

}

class FtTranslationListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    openContentDialog: boolean = false;
    selectedTranslation: FtTranslation = new FtTranslation();
}

class JtTableAdapter extends FtDataTableAdapter<FtTranslation> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssTranslationService.getTranslationPaged(this.keyword, this.filterColumns, null, null,
            this.pagedList.number, this.pagedList.size, (data: FtPagedList<FtTranslation>)=>{
                FtToast.closeToast();
                this.pagedList = data;
                this.fireUpdateData();
            });
    }
}

export default class FssTranslationListPage extends FtComponent<FtTranslationListPageProp, FtTranslationListPageState> {

    constructor(props: any) {
        super(props);

        const columnProps: Array<FtDataTableColumnProps> = [];
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("groupKey", i18n("分组"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("itemKey", i18n("子分类"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("lang", i18n("语言类型"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("content", i18n("内容"));
        columnProps.push(column4);

        let tableAdapter: JtTableAdapter;
        let stateKey = "FssTranslationListPage";
        const cacheState: FtTranslationListPageState = FtState.pop(stateKey);
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            openContentDialog: false,
            selectedTranslation: new FtTranslation(),
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
        this.getToolbarWithSelect = this.getToolbarWithSelect.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClickRow = this.onClickRow.bind(this);
        this.getContentDialog = this.getContentDialog.bind(this);
        this.saveTranslation = this.saveTranslation.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable columnProps={this.state.columnProps}
                         adapter={this.state.tableAdapter}
                         filterPanel={this.getFilterPanel()}
                         toolbarWithSelect={this.getToolbarWithSelect()}
                         onHighlightRow={this.onClickRow} />
            {this.getContentDialog()}
        </div>;
    }

    onClickRow(rowData: object, rowIndex: number) {
        let translation: FtTranslation = rowData as FtTranslation;
        let selectedTranslation: FtTranslation = FtTranslation.newInstance(translation.objectId, translation.groupKey, translation.itemKey, translation.lang, translation.content);
        this.setState({openContentDialog: true, selectedTranslation: selectedTranslation});
    }

    onDelete() {
        // 确认删除
        FtMessageDialog.dialog.openDialog(i18n("确定要删除吗?"),()=>{
            FtMessageDialog.dialog.closeDialog();
            let selectedRow = this.state.tableAdapter.selectedRow;
            FtToast.showToastLoading();
            FssTranslationService.deleteTranslation(selectedRow, (result: string)=>{
                FtToast.closeToast();
                this.state.tableAdapter.selectedRow = [];
                this.state.tableAdapter.loadData();
                FtSnackbar.showText(i18n("操作成功"));
            });
        },()=>{
            FtMessageDialog.dialog.closeDialog();
        },i18n("确认"),i18n("取消"));
    }

    getToolbarWithSelect(): ReactNode {
        return <Button onClick={this.onDelete}><DeleteOutline/> 批量删除</Button>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 200}} onKeyDownEnter={this.onChangePageNo}/>
        </FtRow>;
    }

    onChangePageNo () {
        this.state.tableAdapter.changePageNo(0);
    }

    getContentDialog(): ReactNode {
        return <Dialog open={this.state.openContentDialog} PaperProps={{style: {width: 400}}}>
            <DialogTitle style={{paddingBottom: 0, position: "relative"}}>{"多语言内容更新"}
                <IconButton onClick={this.onCloseDialog}
                            style={{position: "absolute", top: 5, right: 5}}>
                    <Close/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <FtRow cellWidthS={"1"} cellVerticalSpace={16}>
                    <JtTextField label={this.getLanguageText(this.state.selectedTranslation.lang)}
                                 onChange={(event)=>{
                                     this.state.selectedTranslation.content = event.target.value;
                                     this.forceUpdate();
                                 }}
                                 style={{width: "100%"}} variant={"outlined"}
                                 multiline={true}
                                 rows={3}
                                 value={this.state.selectedTranslation.content}/>
                </FtRow>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onCloseDialog}
                        variant={"contained"}>
                    {i18n("取消")}
                </Button>
                <Button onClick={this.saveTranslation} color="primary" variant={"contained"}>
                    {i18n("保存")}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    saveTranslation() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssTranslationService.saveTranslation(this.state.selectedTranslation, ()=>{
            FtToast.closeToast();
            this.onCloseDialog();
            this.state.tableAdapter.loadData();
            FtSnackbar.showText(i18n("保存成功！"));
        });
    }

    onCloseDialog() {
        this.setState({openContentDialog: false});
    }

    getLanguageText (language: string) {
        let text = "";
        switch (language as Language) {
            case Language.en_CA:
                text = "English";
                break;
            case Language.zh_CN:
                text = "简体中文";
                break;
            case Language.zh_TW:
                text = "繁体中文";
                break;
        }
        return text;
    }
}
