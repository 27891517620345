import * as React from "react";
import {ChangeEvent} from "react";
import {IconButton, TextField} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import JtUserMedia, {EMediaType} from "../model/FssUserMedia";

import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {i18n} from "../util/I18n";
import FssUserMediaService from "../service/FssUserMediaService";
import FssClientData from "../data/FssClientData";
import FtRow from "../ui/FtRow";
import CloseIcon from "@material-ui/icons/Close";
import FtFileUploadButtonToOss from "../ui/FtFileUploadButtonToOss";
import {grey} from "@material-ui/core/colors";
import {CropOriginal} from "@material-ui/icons";

interface JtUserMediaDetailDialogProp {
}

export class JtUserMediaDetailDialogState {
    jtUserMedia: JtUserMedia = new JtUserMedia();
    open: boolean = false;
    successFunction?: (data: Array<JtUserMedia> | null) => void;
    productList?: Array<JtUserMedia> = new Array<JtUserMedia>();
    productSelectOptions?: [] = [];
}


export default class FssUserMediaDetailDialog extends React.Component<JtUserMediaDetailDialogProp, JtUserMediaDetailDialogState> {


    constructor(props: any) {
        super(props);
        this.state = {jtUserMedia: new JtUserMedia(), open: false};
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.show = this.show.bind(this);
    }

    componentDidMount(): void {

    }

    show(jtUserMedia: JtUserMedia, successFunction?: (data: Array<JtUserMedia> | null) => void): void {
        if (!jtUserMedia.validate) {
            jtUserMedia = FtUtil.mergeObject(new JtUserMedia(), jtUserMedia);
        }
        this.setState({open: true, jtUserMedia: jtUserMedia, successFunction: successFunction});
    }

    onCancelClick(): void {
        this.setState({open: false});
        if (this.state.successFunction) {
            this.state.successFunction(null);
        }
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.jtUserMedia, event.target.id, event.target.value);
        this.forceUpdate();
    }


    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssUserMediaService.saveUserMedia(this.state.jtUserMedia,
            (data: Array<JtUserMedia>) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功"));
                this.setState({open: false});
                if (this.state.successFunction) {
                    this.state.successFunction(data);
                }
            });
    }

    private onFinishUpload(fileUrl: string, fileName: string) {
        if (this.state.jtUserMedia.multipleMediaUrls == null) {
            this.state.jtUserMedia.multipleMediaUrls = [];
        }
        if (fileUrl != null && fileUrl.length > 0) {
            this.state.jtUserMedia.multipleMediaUrls.push(fileUrl);
            this.forceUpdate();
        }
    }

    render() {
        let isEdit: boolean = false;
        if (this.state.jtUserMedia.id != null)
            isEdit = true;
        const open = this.state.open == null ? false : this.state.open;
        return <Dialog open={open} onClose={this.onCancelClick} fullScreen={true}>
            <Grid container justify="center">
                <Card style={{margin: 10}}>
                    <CardHeader title={i18n("素材信息")}/>
                    <CardContent>
                        {this.getUploadComp()}
                        <FtRow title="">
                            {isEdit &&
                            <TextField id="createDatetime" label={i18n("创建时间")}
                                       value={FssClientData.formatDatetime(this.state.jtUserMedia.createDatetime ? this.state.jtUserMedia.createDatetime : "")}
                                       style={{width: 200}} disabled={true}/>}
                            <div style={{width: "100%"}}/>
                            <TextField id="mediaName" label={i18n("模板名称")} defaultValue={this.state.jtUserMedia.mediaName}
                                       onChange={this.onChange} style={{width: 300}}/>
                            <div style={{width: "100%"}}/>
                            <TextField id="mediaTag" label={i18n("标签")}
                                       defaultValue={this.state.jtUserMedia.mediaTag}
                                       onChange={this.onChange} style={{width: "300"}}/>
                            <TextField id="mediaDescription" label={i18n("简述")}
                                       defaultValue={this.state.jtUserMedia.mediaDescription}
                                       onChange={this.onChange} style={{width: "100%"}}/>
                            <div style={{width: "100%"}}/>
                            <FormControl style={{minWidth: 150}}>
                                <InputLabel> {i18n("类型")} </InputLabel>
                                <Select id="productStatus" value={this.state.jtUserMedia.mediaType}
                                        onChange={(event, child) => {
                                            this.state.jtUserMedia.mediaType = event.target.value as EMediaType;
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={"PICTURE"}>{i18n("图片")}</MenuItem>
                                    <MenuItem value={"VIDEO"}>{i18n("视频")}</MenuItem>
                                </Select>
                            </FormControl>
                            <div style={{width: "100%"}}/>
                        </FtRow>
                    </CardContent>
                    <CardActions style={{justifyContent: "center"}}>
                        <Button variant="contained" onClick={this.onCancelClick}>{i18n("关闭")}</Button>
                        <Button variant="contained" color="primary" onClick={this.onClickSave}>{i18n("保存")}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Dialog>;
    }

    getUploadComp() {
        let multipleMediaUrls = this.state.jtUserMedia.multipleMediaUrls;
        return <FtRow cellHorizontalSpace={0} cellVerticalSpace={0} cellWidthS={"1"}>
            <FtRow cellHorizontalSpace={0} cellVerticalSpace={16} style={{alignItems: "flex-start"}}>
                {multipleMediaUrls != null && multipleMediaUrls.length > 0 &&
                multipleMediaUrls.map((pictureUrl: string, index: number) => {
                    return <div style={{position: "relative", width: 118, marginRight: 16}}>
                        <img src={pictureUrl} style={{width: "100%", cursor: "pointer"}}
                             onClick={(event) => {
                                 event.stopPropagation();
                             }}/>
                        <IconButton
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.75)", padding: 0,
                                height: 25, width: 25, top: 0, right: 0, position: "absolute",
                                borderRadius: 0, borderBottomLeftRadius: "100%",
                                justifyContent: "flex-end"
                            }}
                            onClick={() => {
                                this.state.jtUserMedia.multipleMediaUrls.splice(index, 1)
                                this.forceUpdate();
                            }}>
                            <CloseIcon style={{color: "white", marginLeft: 5}}/>
                        </IconButton>
                    </div>
                })}
                <FtFileUploadButtonToOss onFinish={this.onFinishUpload}
                                         buttonStyle={{
                                             cursor: "pointer", width: 118, height: 118, borderRadius: 3,
                                             backgroundColor: grey[100], border: "1px dashed grey"
                                         }}
                                         buttonSvg={
                                             <div style={{color: "grey", margin: "auto", textAlign: "center"}}>
                                                 <CropOriginal viewBox={"3 3 18 18"} style={{width: 40, height: 40}}/>
                                             </div>
                                         }
                                         multiple={true}
                                         buttonLabel={" "}
                                         fileType={"image/*"}
                                         formStyle={{height: 0}}/>
            </FtRow>
        </FtRow>
    }
}
