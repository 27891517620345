export enum EAccountType {
    FACEBOOK = "FACEBOOK",
    WECHAT = "WECHAT",
    GOOGLE = "GOOGLE",
    APPLE = "APPLE",
    TWITTER = "TWITTER",
    LINKEDIN = "LINKEDIN",
    PAYPAL = "PAYPAL",
    AMAZON = "AMAZONE"
}

export default class FssUserAccount {
    userAccountId?:number;
    userId?:number;
    accountType?:EAccountType=EAccountType.FACEBOOK;
    accountId?:string;
    accessToken?:string;
    refreshToken?:string;
    code?:string;
    scope?:string;
    expiresAt?:Date;
    data?:string;
}
