import * as React from "react";
import {CSSProperties, ReactNode} from "react";
import {InputAdornment, TextField} from "@material-ui/core";
import {BaseTextFieldProps} from "@material-ui/core/TextField";
import {OutlinedInputProps} from "@material-ui/core/OutlinedInput";
import {FilledInputProps} from "@material-ui/core/FilledInput";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";
import FtUtil from "../util/FtUtil";

interface FtTextFieldNumberProps extends BaseTextFieldProps {
    helperText?: string;
    originalValue?: unknown;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    variant?: "standard" | "filled" | "outlined";
    type?: "integer" | "float" | "currency";
    onChangeValue?: (value?: number | undefined) => void;
    onBlur?: StandardInputProps['onBlur'] | FilledInputProps["onBlur"] | OutlinedInputProps["onBlur"];
    onFocus?: StandardInputProps['onFocus'] | FilledInputProps["onFocus"] | OutlinedInputProps["onFocus"];
    value?: number;
    InputProps?: any;
    inputStyle?:CSSProperties;
}

class FtTextFieldNumberState {
    value?: number;
    text: string = "";
}

const charInteger = new RegExp("^[-+]?[0-9]*$");
const charFloat = new RegExp("^[-+]?[0-9]*[.]?[0-9]*$");

export default class FtTextFieldNumber extends React.Component<FtTextFieldNumberProps, FtTextFieldNumberState> {
    constructor(props: any) {
        super(props);
        this.state = {text: ""};
        this.onChange = this.onChange.bind(this);
        this.onChangeCapture = this.onChangeCapture.bind(this);
    }

    static getDerivedStateFromProps(props: FtTextFieldNumberProps, state: FtTextFieldNumberState) {
        if (props.value != state.value) {
            const textValue=FtTextFieldNumber.getNumberValue(state.text,props.type);
            //如果当前文本是负号，或者文本的数值和传入的数值相同则保留text
            if (state.text == "-" || props.value==textValue)
                return {value: props.value};
            else
                return {value: props.value, text: FtTextFieldNumber.formatValue(props.type, props.value)};
        }
        return state;
    }

    render() {
        const baseTextFieldProps: BaseTextFieldProps = this.props;
        let variant: any = "standard";
        if (this.props.variant)
            variant = this.props.variant;
        let InputProps: any = {};
        if (this.props.startAdornment)
            InputProps.startAdornment = <InputAdornment position="start">{this.props.startAdornment}</InputAdornment>;
        if (this.props.endAdornment)
            InputProps.endAdornment = <InputAdornment position="end">{this.props.endAdornment}</InputAdornment>;
        if (this.props.variant)
            variant = this.props.variant;
        if (this.props.InputProps) {
            InputProps = FtUtil.mergeObject(InputProps, this.props.InputProps);
        }
        let error: boolean|undefined = this.props.error;
        let helperText: string | undefined = this.props.helperText ? this.props.helperText : "";
        if (this.props.originalValue && this.props.value != this.props.originalValue)
            return <TextField {...baseTextFieldProps} value={this.state.text} error={error} helperText={helperText}
                              variant={"filled"} inputProps={{style: this.props.inputStyle}}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
        else
            return <TextField {...baseTextFieldProps} value={this.state.text} error={error} helperText={helperText}
                              variant={variant} inputProps={{style: this.props.inputStyle}}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
    }

    private static formatValue(type?: "integer" | "float" | "currency", value?: number): string {
        // const numberType = type ? type : "float";
        // let result: string = "";
        // if (value != null && value.length > 0) {
        //     for (let i = 0; i < value.length; i++) {
        //         const c = value.charCodeAt(i);
        //         //加号
        //         if (c == 43) {
        //         } else if (c == 45 && i == 0) {
        //             result += "-";
        //         } else if (c == 46) {
        //             //小数点
        //             if (numberType == "float" || numberType == "currency") {
        //                 if (i == 0) {
        //                     result += "0.";
        //                 } else
        //                     result += ".";
        //             }
        //         } else if (c >= 48 && c <= 57) {
        //             result += value.charAt(i);
        //         }
        //     }
        // }
        // return result;
        const result = value != null && value != undefined ? "" + value : "";
        return result;
    }

    static getNumberValue(text: string,type?: "integer" | "float" | "currency"): number | undefined {
        const numberType = type ? type : "float";
        if (numberType != null && numberType == "integer") {
            if (isNaN(parseInt(text)))
                return undefined;
        } else if (numberType != null && numberType == "float") {
            if (isNaN(parseFloat(text)))
                return undefined;
        }
        return text.length == 0 ? undefined : +text;
    }

    private onChange(event: any) {
        const value: string = event.target.value as string;
        this.setState({text: value});
        //检查修改之后的值是否会和props里的数值一样，如果不一样调用onchangevalue
        const oldValue = this.props.value;
        let newValue = undefined;
        if (value.trim().length > 0) {
            newValue = FtTextFieldNumber.getNumberValue(value,this.props.type);
        }
        if (this.props.onChangeValue && oldValue != newValue)
            this.props.onChangeValue(newValue);

    }

    private onChangeCapture(event: any) {
        let message = "";
        const value = event.target.value;
        if ((this.props.type == null || this.props.type == "float" || this.props.type == "currency") && !charFloat.test(value))
            message = "请输入有效的数字";
        if ((this.props.type == null || this.props.type == "integer") && !charInteger.test(value))
            message = "请输入有效的数字";
        if (message != null && message.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        } else
            return true;
    }
}
