import * as React from "react";
import {ChangeEvent} from "react";
import Button from "@material-ui/core/Button";
import {RouteComponentProps} from "react-router-dom";
import {Add, Delete, Save} from "@material-ui/icons";
import {FormLabel, TextField} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import FssProductExchangeRule, {EStatus} from "../model/FssProductExchangeRule";
import FssProduct from "../model/FssProduct";
import FssProductExchangeRuleService from "../service/FssProductExchangeRuleService";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import FtSnackbar from "../ui/FtSnackbar";
import FtToast from "../ui/FtToast";
import FtPage from "../ui/FtPage";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtComboboxDynamic from "../ui/FtComboboxDynamic";

interface FssProductExchangeRuleDetailPageProp extends RouteComponentProps<{ id: string }> {
}

export class FssProductExchangeRuleDetailPageState {
    jtProductExchangeRule: FssProductExchangeRule = new FssProductExchangeRule();
    productList: Array<FssProduct> = new Array<FssProduct>();
    productSelectOptions: Array<FssProduct> = new Array<FssProduct>();

}


export default class FssProductExchangeRuleDetailPage extends React.Component<FssProductExchangeRuleDetailPageProp, FssProductExchangeRuleDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            jtProductExchangeRule: new FssProductExchangeRule(),
            productList: new Array<FssProduct>(),
            productSelectOptions: new Array<FssProduct>()
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChangeProductInput = this.onChangeProductInput.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.load = this.load.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    //获取详情
    load() {
        if (this.props.match.params.id != null && this.props.match.params.id != "0") {
            FssProductExchangeRuleService.getFssProductExchangeRuleByRuleId(this.props.match.params.id, (result: FssProductExchangeRule) => {
                let jtProductExchangeRule: FssProductExchangeRule = FtUtil.mergeObject(this.state.jtProductExchangeRule, result);
                this.setState({
                    jtProductExchangeRule: jtProductExchangeRule,
                    productSelectOptions: jtProductExchangeRule.productList
                });
            });
        }

    }

    //返回
    onCancelClick(): void {
        this.props.history.goBack();
    }


    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.jtProductExchangeRule, event.target.id, event.target.value);
        this.forceUpdate();
    }

    //保存详情
    onClickSave() {
        if (this.state.jtProductExchangeRule.name == "") {
            FtSnackbar.showText(i18n("请填写名称"));
            return;
        }
        if (this.state.jtProductExchangeRule.startDatetimeString == "") {
            FtSnackbar.showText(i18n("请选择『换购开始时间』"));
            return;
        }
        if (this.state.jtProductExchangeRule.endDatetimeString == "") {
            FtSnackbar.showText(i18n("请选择『换购结束时间』"));
            return;
        }
        if (this.state.productSelectOptions.length<=0) {
            FtSnackbar.showText(i18n("请选择『换购商品』"));
            return;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        this.state.jtProductExchangeRule.productList=this.state.productSelectOptions;
        console.log(this.state.jtProductExchangeRule);
        FssProductExchangeRuleService.saveFssProductExchangeRule(this.state.jtProductExchangeRule, (data: FssProductExchangeRule) => {
            this.setState({jtProductExchangeRule: FtUtil.mergeObject(this.state.jtProductExchangeRule, data)});
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功！"));
            this.onCancelClick();
        });
    }

    onChangeProduct(item: any) {
        this.state.jtProductExchangeRule.product = item;
    }

    onChangeProductInput(keyword: string) {
        FssProductExchangeRuleService.getProductByExchange(keyword, 5, (productList: Array<FssProduct>) => {
            this.setState({productList: productList});
        });
    }

    render() {
        return <FtPage onCancelClick={this.onCancelClick}
                                  toolbarLeftNode={<Button variant="contained"
                                                           color="primary"
                                                           startIcon={<Save />}
                                                           onClick={this.onClickSave}>{i18n("保存")}</Button>}>
            <FtPanel panelHeader={i18n("换购规则设置")}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                           maxRowWidth={"l"} cellVerticalSpace={16}>
                        <TextField id="name" label="名称" value={this.state.jtProductExchangeRule.name} style={{width: "100%"}} onChange={this.onChange}/>
                        <FormControl style={{width: "100%"}}>
                            <InputLabel id="demo-simple-select-label"> {i18n("状态")} </InputLabel>
                            <Select id="status" labelId="demo-simple-select-label" value={this.state.jtProductExchangeRule.status}
                                    onChange={(event, child) => {
                                        this.state.jtProductExchangeRule.status = event.target.value as string;
                                        this.forceUpdate();
                                    }}>
                                <MenuItem value={EStatus.NORMAL}>{i18n("正常")}</MenuItem>
                                <MenuItem value={EStatus.STOP}>{i18n("停用")}</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField id="createDatetime" label={i18n("创建时间")} value={this.state.jtProductExchangeRule.createDatetimeText}
                                   style={{width: "100%"}} disabled={true}/>
                    </FtRow>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                           maxRowWidth={"l"} cellVerticalSpace={16}>
                        <TextField id="balanceAmountLimit" required={true} onChange={this.onChange}
                                   value={this.state.jtProductExchangeRule.balanceAmountLimit ? this.state.jtProductExchangeRule.balanceAmountLimit : ''}
                                   label={i18n("订单金额限制")} type={"number"} style={{width: "100%"}} />
                        <TextField id="startDatetimeString" type="datetime-local" onChange={this.onChange}
                                   required={true} InputLabelProps={{
                            shrink: true,
                        }} value={this.state.jtProductExchangeRule.startDatetimeString} label={i18n("换购开始时间")}
                                   style={{width: "100%"}} />
                        <TextField id="endDatetimeString" onChange={this.onChange} InputLabelProps={{
                            shrink: true,
                        }} required={true} value={this.state.jtProductExchangeRule.endDatetimeString}
                                   label={i18n("换购结束时间")} type="datetime-local"
                                   style={{width: "100%"}} />

                    </FtRow>
                </FtGrid>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellWidthL={"1,1,1,1,1"} cellHorizontalSpace={32}
                           maxRowWidth={"l"} cellVerticalSpace={16}>
                        <FtComboboxDynamic label={i18n("换购商品")} optionItems={this.state.productList}
                                           labelPropertyName={"title"}
                                           valuePropertyName={"productId"}
                                           onSelectItem={this.onChangeProduct}
                                           onChangeInput={this.onChangeProductInput}
                                           loading={false}
                                           style={{minWidth: 200}} />
                        <Button onClick={() => {
                            let productSelectOptions = this.state.productSelectOptions;
                            if (this.state.jtProductExchangeRule.product.title != '') {
                                let addFlag = true;
                                productSelectOptions.forEach((product: FssProduct) => {
                                    if (product.id == this.state.jtProductExchangeRule.product.id) {
                                        addFlag = false;
                                        return;
                                    }
                                });
                                if (addFlag) {
                                    let jtProduct: FssProduct = FtUtil.mergeObject({}, this.state.jtProductExchangeRule.product);
                                    productSelectOptions.push(jtProduct);
                                    this.setState({productSelectOptions: productSelectOptions});
                                } else {
                                    FtSnackbar.showText(i18n("此商品已经添加过了"));
                                }
                            }
                        }} color={"primary"} variant="outlined"><Add/>{i18n("添加")}</Button>
                    </FtRow>
                    <div style={{width: "100%"}}>
                        {this.state.productSelectOptions.length>0 &&  <FormLabel component="legend">{i18n("已选商品")}</FormLabel>}
                        {this.state.productSelectOptions.map((product: FssProduct, i: number) => (
                            <FtRow key={i} cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellWidthL={"1,1,1,1,1"}
                                   cellHorizontalSpace={32} maxRowWidth={"l"} cellVerticalSpace={16}>
                                <div>{product.title}</div>
                                <Button onClick={() => {
                                    let productSelectOptions = this.state.productSelectOptions;
                                    productSelectOptions.splice(productSelectOptions.indexOf(product), 1);
                                    this.forceUpdate();
                                }
                                } variant="outlined"><Delete/>{i18n("删除")}</Button>
                            </FtRow>
                        ))}
                    </div>
                </FtGrid>
                <FtGrid>
                    <div style={{display: "flex", flexDirection: "column", marginTop: 50,marginLeft:10}}>
                        <Typography variant="subtitle2">{i18n("换购规则设置说明")}</Typography>
                        <Typography variant="caption">{i18n("1.平台根据订单金额，取上限显示可换购的商品，比如同时有满100元和满200元换购规则是，如果用户订单超过200，只显示参与200元换购的商品")}</Typography>
                        <Typography variant="caption">{i18n("2.订单金额设置为0表示不限制")}</Typography>
                        <Typography variant="caption">{i18n("3.可换购的商品，在商品表里面选取类型为可换购的显示")}</Typography>
                        <Typography variant="caption">{i18n("4.如换购规则状态为暂停或者停用的，在用户端不显示换购相关的提示")}</Typography>
                    </div>
                </FtGrid>
            </FtPanel>
        </FtPage>;
    }
}
