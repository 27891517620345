export default class FssRegion {
    id: string = "";
    regionCode = "";
    regionName = "";
    type: EType = EType.REGION;
    status: EStatus = EStatus.NORMAL;
    county = "";
    province = "";
    city = "";
    postalCode = "";
    boundary = "";
    distance: number = 0;
    centerAddress = "";
    centerLat: number = 0;
    centerLng: number = 0;
    createDatetime = "";
    createDatetimeText = "";
    storeId = "";
    sellerId = "";
    countyList: Array<string>= new Array<string>();
    provinceList: Array<string>= new Array<string>();
    cityList: Array<string>= new Array<string>();
    postalCodeList: Array<string>= new Array<string>();
    boundaryList: Array<string>= new Array<string>();
}

export enum EType {
    REGION = "REGION",
    POSTAL = "POSTAL",
    BOUNDARY = "BOUNDARY",
    DISTANCE = "DISTANCE",
}

export enum EStatus {
    NORMAL = "NORMAL",
    STOP = "STOP",
    DELETE = "DELETE",
}
