import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {Link} from "react-router-dom";
import FtState from "../ui/FtState";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button, TextField} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import FssCoupon from "../model/FssCoupon";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {i18n} from "../util/I18n";
import FssCouponService from "../service/FssCouponService";
import FssClientData from "../data/FssClientData";
import FtRow from "../ui/FtRow";

export interface JtCouponListPagePorp {

}

class JtCouponListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class JtTableAdapter extends FtDataTableAdapter<FssCoupon> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssCouponService.getJtCouponList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssCoupon>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssCouponListPage extends FtComponent<JtCouponListPagePorp, JtCouponListPageState>{

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("couponName", i18n("优惠券名字"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const coupon: FssCoupon = rowData as FssCoupon;
            return <Link to={"/coupon/FssCouponDetailPage/" + coupon.id}>{columnValue}</Link>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("couponTypeText", i18n("类型"));
        columnProps.push(column2);
        // const column3: FtDataTableColumnProps = new FtDataTableColumnProps("description", i18n("描述"));
        // columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column4);
        // const column5: FtDataTableColumnProps = new FtDataTableColumnProps("couponCode", i18n("优惠码"));
        // columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("创建时间"));
        column6.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const coupon: FssCoupon = rowData as FssCoupon;
            if(coupon.createDatetime)
                return <span>{FssClientData.formatDatetime(coupon.createDatetime)}</span>;
            else
                return <span></span>;
        };
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("receiveAmount", i18n("领取数量"));
        columnProps.push(column7);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("usedAmount", i18n("使用数量"));
        columnProps.push(column8);
        let tableAdapter: JtTableAdapter;
        const cacheState: JtCouponListPageState = FtState.pop("FssCouponListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = { tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssCouponListPage" };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
    }
    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }
    render() {
        return <div>
            <FtDataTable title={i18n("优惠券列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                toolbarNoSelect={this.getToolbarNoSelect()}
                filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }
    getFilterPanel(): ReactNode {
        return <FtRow>
            <TextField label={i18n("优惠券名称")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("couponName", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("couponName")} style={{ width: 200 }} />
            <FormControl style={{ minWidth: 150 }}>
                <InputLabel> {i18n("优惠券类型")} </InputLabel>
                <Select id="couponType" value={this.state.tableAdapter.getFilterColumnValue("couponType")}
                    onChange={(event: any) => {
                        this.state.tableAdapter.filterColumns.set("couponType", event.target.value);
                        this.forceUpdate();
                    }}>
                    <MenuItem value={"PERCENT"} key={"PERCENT"}>{i18n("折扣劵")}</MenuItem>
                    <MenuItem value={"MONEY"} key={"MONEY"}>{i18n("满减劵")}</MenuItem>
                </Select>
            </FormControl>
            <TextField label={i18n("优惠码")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("couponCode", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("couponCode")} style={{ width: 200 }} />

        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/coupon/FssCouponDetailPage/0"}>
                <Button>
                    <Add />{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }
}
