import FssHttpService from "../service/FssHttpService";
import FssUserCoupon from "../model/FssUserCoupon";
import FtPagedList from "../model/FtPagedList";
import FssCoupon from "../model/FssCoupon";


export default class FssCouponService {

    static getValidCouponListByUserId(successFunction: (data: Array<FssUserCoupon>) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/coupon/getValidCouponListByUserId", null, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static getUsedCouponListByUserId(successFunction: (data: Array<FssUserCoupon>) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/coupon/getUsedCouponListByUserId", null, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static getExpiredCouponListByUserId(successFunction: (data: Array<FssUserCoupon>) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/coupon/getExpiredCouponListByUserId", null, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    /**
     *
     * @param 获取优惠券列表
     */
    static getJtCouponList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                           pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssCoupon>) => void,
                           failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/coupon/getJtCouponList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }

    /**
     *
     * @param 获取优惠券详情
     */
    static getJtCouponById(couponId: string, successFunction: (data: FssCoupon) => void,
                           failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/coupon/getJtCouponById", {couponId: couponId}, successFunction, FssHttpService.handleError);
    }

    /**
     *
     * @param 保存优惠券详情
     */
    static saveJtCoupon(JtCoupon: FssCoupon, successFunction: (data: FssCoupon) => void,
                        failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/coupon/saveJtCoupon", JtCoupon, successFunction, FssHttpService.handleError);
    }

    static getAvailCouponList(keyword: string, maxCount: number, successFunction: (couponList: Array<FssCoupon>) => void): void {
        FssHttpService.get("/api/coupon/getAvailCouponList", {
            keyword: keyword,
            maxCount: maxCount
        }, successFunction, FssHttpService.handleError);
    }

    static getCouponListForGuest(sellerId: string, successFunction: (data: FtPagedList<FssCoupon>) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/coupon/getCouponListForGuest", {sellerId: sellerId}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static getUserCouponListForLanding(sellerId: string, successFunction: (data: Array<FssUserCoupon>) => void) {
        FssHttpService.get("/api/userCoupon/getUserCouponListForLanding", {sellerId: sellerId}, successFunction, FssHttpService.handleError);
    }

    static createUserCoupon(couponId: string | undefined, successFunction: (FssUserCoupon: FssUserCoupon) => void, failFunction ?: (error: any) => void) {
        FssHttpService.postFormData("/api/userCoupon/createUserCoupon", {couponId: couponId}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }
}
