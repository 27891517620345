import * as React from "react";
import {Typography} from "@material-ui/core";
import FssHtmlCompEditor from "./FssHtmlCompEditor";
import FssProductListCompEditor from "./FssProductListCompEditor";
import FssImageListCompEditor from "./FssImageListCompEditor";
import FssLogoBannerCompEditor from "./FssLogoBannerCompEditor";
import FssCategoryListCompEditor from "./FssCategoryListCompEditor";
import FssCategoryGridCompEditor from "./FssCategoryGridCompEditor";
import FssCategoryNavigationCompEditor from "./FssCategoryNavigationCompEditor";
import FssPageComponent from "../../model/FssPageComponent";
import FssComponent, {EComponentType} from "../../model/FssComponent";
import FssSearchBoxCompEditor from "./FssSearchBoxCompEditor";


interface FssComponentEditorProp {
    pageComponent: FssPageComponent;
    index?: number;
    onChange?: (pageComponent: FssPageComponent, index?: number) => void;
    onChangeDraggableFlag?: (draggableFlag: boolean) => void;
}

export class FssComponentEditorState {
}


export default class FssComponentEditor extends React.Component<FssComponentEditorProp, FssComponentEditorState> {

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onChangeComponent = this.onChangeComponent.bind(this);
    }

    componentDidMount(): void {
    }

    render() {
        return <div>
            {this.props.pageComponent.reference &&
            <Typography variant={"h6"}>i18n("这是一个引用，修改组件内容将影响所有引用了的页面")</Typography>}
            {this.getComponentEditor(this.props.pageComponent)}
        </div>;
    }

    private getComponentEditor(pageComponent: FssPageComponent) {
        if (pageComponent.component) {
            switch (pageComponent.component.componentType) {
                case EComponentType.CONTAINER:
                    return <FssHtmlCompEditor component={pageComponent.component}
                                              onChange={this.onChangeComponent}/>;
                case EComponentType.IMAGE_LIST:
                    return <FssImageListCompEditor component={pageComponent.component}
                                                   onChangeComponent={this.onChangeComponent}/>;
                case EComponentType.PRODUCT_LIST:
                    return <FssProductListCompEditor component={pageComponent.component}
                                                     onChangeComponent={this.onChangeComponent}
                                                     onChangeDraggableFlag={this.props.onChangeDraggableFlag}/>;
                // case EComponentType.CATEGORY_LIST:
                //     return <FssCategoryListCompEditor component={pageComponent.component}
                //                                       onChangeComponent={this.onChangeComponent}/>;
                case EComponentType.CATEGORY_GRID:
                    return <FssCategoryGridCompEditor component={pageComponent.component} type={EComponentType.CATEGORY_NAVIGATION}
                                                      onChangeComponent={this.onChangeComponent}
                                                      key={pageComponent.component.id}
                                                      onChangeDraggableFlag={this.props.onChangeDraggableFlag}/>;
                case EComponentType.STORE_CATEGORY_GRID:
                    return <FssCategoryGridCompEditor component={pageComponent.component} type={EComponentType.STORE_CATEGORY_NAVIGATION}
                                                      onChangeComponent={this.onChangeComponent}
                                                      key={pageComponent.component.id}
                                                      onChangeDraggableFlag={this.props.onChangeDraggableFlag}/>;
                case EComponentType.HTML:
                    return <FssHtmlCompEditor component={pageComponent.component} onChange={this.onChangeComponent}/>;
                case EComponentType.LOGO_BANNER:
                    return <FssLogoBannerCompEditor component={pageComponent.component}
                                                    onChangeComponent={this.onChangeComponent}/>;
                case EComponentType.VIDEO:
                    return <FssHtmlCompEditor component={pageComponent.component} onChange={this.onChangeComponent}/>;
                case EComponentType.SEARCH_BOX:
                    return <FssSearchBoxCompEditor component={pageComponent.component}
                                                   onChangeComponent={this.onChangeComponent}/>;
                case EComponentType.CATEGORY_NAVIGATION:
                    return <FssCategoryNavigationCompEditor component={pageComponent.component} type={EComponentType.CATEGORY_NAVIGATION}
                                                            onChangeComponent={this.onChangeComponent}/>;
                case EComponentType.STORE_CATEGORY_NAVIGATION:
                    return <FssCategoryNavigationCompEditor component={pageComponent.component} type={EComponentType.STORE_CATEGORY_NAVIGATION}
                                                            onChangeComponent={this.onChangeComponent}/>;

            }
        }
    }

    private onChangeComponent(component: FssComponent) {
        this.props.pageComponent.component = component;
        if (this.props.onChange)
            this.props.onChange(this.props.pageComponent, this.props.index);
    }
}
