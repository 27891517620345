import FtPagedList from "../model/FtPagedList";
import {FssPaymentTransactionDaily} from "../model/FssPaymentTransactionDaily";
import FssHttpService from "./FssHttpService";
import {FssPaymentTransactionMonthly} from "../model/FssPaymentTransactionMonthly";
import FssSellerStatement from "../model/FssSellerStatement";

export default class FssPaymentTransactionService {


    static getFssPaymentTransactionDailyList(searchKeyword: string, startDate: string, endDate: string,
                                             pageNo: number = 0, pageSize: number = 20,
                                             successFunction: (result: FtPagedList<FssPaymentTransactionDaily>) => void): void {
        let params = {searchKeyword: searchKeyword, startDate: startDate, endDate: endDate, page: pageNo, size: pageSize};
        FssHttpService.get("/api/paymentTransaction/getFssPaymentTransactionDailyList", params, successFunction, FssHttpService.handleError);
    }

    static getFssPaymentTransactionMonthlyList(searchKeyword: string, startMonth: string, endMonth: string,
                                               pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssPaymentTransactionMonthly>) => void): void {
        let params = {searchKeyword: searchKeyword, startMonth: startMonth, endMonth: endMonth, page: pageNo, size: pageSize};
        FssHttpService.get("/api/paymentTransaction/getFssPaymentTransactionMonthlyList", params, successFunction, FssHttpService.handleError);
    }

    static getFssSellerStatementList(searchKeyword: string, startDate: string, endDate: string,orderCode:string,type:string,
                                     pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssSellerStatement>) => void) {
        let params = {searchKeyword: searchKeyword, startDate: startDate, endDate: endDate, orderCode:orderCode, type:type, page: pageNo, size: pageSize};
        FssHttpService.get("/api/paymentTransaction/getFssSellerStatementList", params, successFunction, FssHttpService.handleError);
    }

    static getFssPaymentTransactionDailyListForAdmin(storeId:string,sellerId:string,searchKeyword: string, startDate: string, endDate: string,
                                             pageNo: number = 0, pageSize: number = 20,
                                             successFunction: (result: FtPagedList<FssPaymentTransactionDaily>) => void): void {
        let params = {storeId:storeId,sellerId:sellerId,searchKeyword: searchKeyword, startDate: startDate, endDate: endDate, page: pageNo, size: pageSize};
        FssHttpService.get("/api/paymentTransaction/getFssPaymentTransactionDailyListForAdmin", params, successFunction, FssHttpService.handleError);
    }

    static getFssPaymentTransactionMonthlyListForAdmin(storeId:string,sellerId:string,searchKeyword: string, startMonth: string, endMonth: string,
                                               pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssPaymentTransactionMonthly>) => void): void {
        let params = {storeId:storeId,sellerId:sellerId,searchKeyword: searchKeyword, startMonth: startMonth, endMonth: endMonth, page: pageNo, size: pageSize};
        FssHttpService.get("/api/paymentTransaction/getFssPaymentTransactionMonthlyListForAdmin", params, successFunction, FssHttpService.handleError);
    }

    static getFssSellerStatementListForAdmin(storeId:string,sellerId:string,searchKeyword: string, startDate: string, endDate: string,orderCode:string,type:string,
                                     pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssSellerStatement>) => void) {
        let params = {storeId:storeId,sellerId:sellerId,searchKeyword: searchKeyword, startDate: startDate, endDate: endDate, orderCode:orderCode, type:type, page: pageNo, size: pageSize};
        FssHttpService.get("/api/paymentTransaction/getFssSellerStatementListForAdmin", params, successFunction, FssHttpService.handleError);
    }
}
