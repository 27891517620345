import FssPaymentAccount from "./FssPaymentAccount";


export default class FssPaymentTransaction {
    
    id: string = "";
    storeId: string = "";
    userId: string = "";
    sellerId: string = "";
    money: number = 0;
    createDatetime: string = "";
    createDatetimeText: string = "";
    comment: string = "";
    status: EStatus = EStatus.PENDING;
    statusText: string = "";
    failedReason: string = "";
    paymentProfileId: string = "";
    paymentAccountId: string = "";
    gatewayTransactionId: string = "";
    gatewayTransactionId2: string = "";
    gatewayOrderId: string = "";
    orderId: string = "";
    type: EType = EType.PAY_ORDER;
    typeText: string = "";
    handleFee: number = 0;
    refundMoney: number = 0;
    typeBalanceRecharge: string = "";
    paymentAccount: FssPaymentAccount = new FssPaymentAccount();
}

export enum EType {
    PAY_ORDER = "PAY_ORDER",
    REFUND_ORDER = "REFUND_ORDER",
    PAY_BALANCE_RECHARGE = "PAY_BALANCE_RECHARGE",
    REFUND_BALANCE_RECHARGE = "REFUND_BALANCE_RECHARGE",
    PAY_INVOICE = "PAY_INVOICE",
}

export  enum EStatus {
    PENDING = "PENDING",
    CONFIRM = "CONFIRM",
    DONE = "DONE",
    FAILED = "FAILED",
}
