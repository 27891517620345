import * as React from "react";
import {ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {
    Button,
    ButtonGroup,
    Checkbox,
    Chip,
    Divider,
    FormControlLabel,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {Link, RouteComponentProps} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {ChevronLeft, ListAlt, Print} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
import FtOptionDialog, {FtOptionDialogItem} from "../ui/FtOptionDialog";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FtUtil from "../util/FtUtil";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtSnackbar from "../ui/FtSnackbar";
import {i18n} from "../util/I18n";
import MenuItem from "@material-ui/core/MenuItem";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import FtRow from "../ui/FtRow";
import FtDivFlex from "../ui/FtDivFlex";
import CloseIcon from "@material-ui/icons/Close";
import FssOrder, {EOrderStatus, EPaymentStatus, EShippingType} from "../model/FssOrder";
import FssOrderItem from "../model/FssOrderItem";
import FssOrderService from "../service/FssOrderService";
import FssOrderPrint58 from "./FssOrderPrint58";
import FssOrderPrintLetterSize from "./FssOrderPrintLetterSize";
import FssOrderWaybillPrint from "./FssOrderWaybillPrint";
import FssOrderPrintPickingList from "./FssOrderPrintPickingList";
import FtToolbar from "../ui/FtToolbar";
import {EMenuitem} from "../mainframe/FssSellerAdminMainPage";
import FssClientData from "../data/FssClientData";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FssDeliveryProviderService from "../service/FssDeliveryProviderService";
import FssDeliveryProvider, {EDeliveryType} from "../model/FssDeliveryProvider";
import FssSellerService from "../service/FssSellerService";

export enum EOrderPageType {
    WAIT_PAY_ORDER = "WAIT_PAY_ORDER",
    EXCEPTION_ORDER = "EXCEPTION_ORDER",
    SHIPPED_ORDER = "SHIPPED_ORDER",
    WAIT_SHIPPING_ORDER = "WAIT_SHIPPING_ORDER",
    WAIT_APPROVE_ORDER = "WAIT_APPROVE_ORDER",
    USER_ORDER = "USER_ORDER",
    FIND_ORDER = "FIND_ORDER",
    TOTAL_ORDER = "TOTAL_ORDER",
    MARKETING_ORDER = "MARKETING_ORDER",
}

interface FssOrderListPageProp extends RouteComponentProps {
    title?: string;
    orderPageType?: EOrderPageType;
    filterColumns?: Map<string, any>;
    onBack?: () => void;
    isProductDetailPage?: boolean;
}

class FssOrderListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    selectedDataList: Array<FssOrder> = [];
    deliveryToHomeList: Array<FssDeliveryProvider> = [];
    openDeliveryProviderDialog: boolean = false;
    smsNotificationFlag: boolean = false;
    exceptionOrderCount: number = 0;
    openBatchPrintPickOrdersDialog: boolean = false;
    openBatchPrintPickOrdersDialogBy: string = "";

    printOrdersSort: string = "";
    printOrderGroupBy: "" | "groupByCourier" | "groupByCount" = "";
    printOrdersCountPerGroup: number = 0;
    printOrdersFlag: boolean = true;
}

class JtTableAdapter extends FtDataTableAdapter<FssOrder> {
    loadData(): void {
        FtToast.showToastLoading("loading", 60000);
        if (this.pagedList.size == 0) {
            this.pagedList.size = 200;
        }
        this.filterColumns.set("includeOrderItemList", true);
        FssOrderService.getStoreOrderList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size, (result: FtPagedList<FssOrder>) => {
                this.pagedList = result;
                this.fireUpdateData();
                window.setTimeout(() => {
                    FtToast.closeToast();
                }, 1000);
            });
    }
}

export default class FssOrderListPage extends FtComponent<FssOrderListPageProp, FssOrderListPageState> {
    orderPrint58!: FssOrderPrint58 | null;
    orderPrintLetterSize!: FssOrderPrintLetterSize | null;
    orderWaybillPrint!: FssOrderWaybillPrint | null;
    orderPrintPickingList!: FssOrderPrintPickingList | null;

    constructor(props: any) {
        super(props);
        let tableAdapter: JtTableAdapter = new JtTableAdapter();
        if (this.props.filterColumns) {
            tableAdapter.filterColumns = this.props.filterColumns;
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: this.createColumnDef(),
            stateKey: "FssOrderListPage_" + this.props.orderPageType,
            selectedDataList: [],
            openDeliveryProviderDialog: false,
            smsNotificationFlag: false,
            exceptionOrderCount: 0,
            openBatchPrintPickOrdersDialog: false,
            openBatchPrintPickOrdersDialogBy: "",
            printOrdersSort: "deliverySequence",
            printOrderGroupBy: "groupByCount",
            printOrdersCountPerGroup: 5,
            printOrdersFlag: true,
            deliveryToHomeList: [],
        };
        this.onClickPrint = this.onClickPrint.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onClickPrintMarketing = this.onClickPrintMarketing.bind(this);
        this.getToolbarWithSelect = this.getToolbarWithSelect.bind(this);
        this.onClickSendToDeliverProvider = this.onClickSendToDeliverProvider.bind(this);
        this.sendToDeliveryProvider = this.sendToDeliveryProvider.bind(this);
        this.onClickDownloadCsv = this.onClickDownloadCsv.bind(this);
        this.onClickPickOrders = this.onClickPickOrders.bind(this);
        this.onFinishOrders = this.onFinishOrders.bind(this);
        this.onFinishOrders = this.onFinishOrders.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
        this.getExceptionOrders = this.getExceptionOrders.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onClickSyncOrderWithDeliveryProvider = this.onClickSyncOrderWithDeliveryProvider.bind(this);
        this.onClickExportExpressOrder = this.onClickExportExpressOrder.bind(this);
        this.loadDeliveryProvider = this.loadDeliveryProvider.bind(this);
    }

    createColumnDef(): Array<FtDataTableColumnProps> {
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("orderCode", i18n("编码"));
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const order: FssOrder = rowData as FssOrder;
            if (this.props.isProductDetailPage) {
                return <Link to={"/orderDetail/" + order.id} target={"_blank"}>{order.orderCode}</Link>;
            } else {
                if (order.parentOrderId != null && order.parentOrderId.length > 0) {
                    return <Link to={"/orderDetail/" + order.id}>{order.orderCode}<Chip size={"small"}
                                                                                        label={i18n("子订单")}/></Link>;
                } else {
                    return <Link to={"/orderDetail/" + order.id}>{order.orderCode}</Link>;
                }
            }
        };
        columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("SellerStatusText", i18n("Order Status "));
        column1.renderCell = (rowData: object) => {
            const order: FssOrder = rowData as FssOrder;
            return <Chip size={"small"} variant="outlined" label={i18n(order.sellerStatusText)}/>;
        };
        columnProps.push(column1);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("shipToAddress", i18n("收件人信息"));
        column3.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const order: FssOrder = rowData as FssOrder;
            return <div>
                {order.shippingType == EShippingType.PICK_UP &&
                <Chip variant="outlined" label={i18n(order.shippingTypeText)} size={"small"}/>}
                <small>{i18n(order.shipToMobile)} ，{i18n(order.shipToName)}</small><br/>
                <small>
                    {i18n(order.shipToAddress) + "," + order.shipToCity + "," + order.shipToProvince + "," + order.shipToCountry}
                </small>
            </div>
        };
        columnProps.push(column3);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("paymentStatusText", i18n("支付状态"));
        column2.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const order: FssOrder = rowData as FssOrder;
            let color: "default" | "primary" = "default";
            if (order.paymentStatus == EPaymentStatus.WAIT || order.paymentStatus == EPaymentStatus.PARTIAL_PAID) {
                color = "primary";
            }
            return <div>
                <Chip size={"small"} variant="outlined" color={color} label={i18n(order.sellerPaymentStatusText)}/>
                <br/>
                <small>{i18n(order.paymentTypeText)}</small>
            </div>

        };
        columnProps.push(column2);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("total1", i18n("总金额"), "right");
        column5.renderHeader = () => {
            return i18n("总金额");
        }
        column5.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const order: FssOrder = rowData as FssOrder;
            return FtUtil.toCurrency(order.total + Math.abs(order.discount));
        };
        columnProps.push(column5);
        const column52: FtDataTableColumnProps = new FtDataTableColumnProps("total2", i18n("已支付"), "right");
        column52.renderHeader = () => {
            return i18n("已支付");
        }
        column52.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const order: FssOrder = rowData as FssOrder;
            return FtUtil.toCurrency(order.totalPaid);
        };
        columnProps.push(column52);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("shippingTypeText", i18n("配送类型"));
        column4.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const order: FssOrder = rowData as FssOrder;
            return <div>
                {this.getDeliveryProviderName(order)}
                <br/>
                <small>{FssClientData.formatDatetime(order.scheduleDatetime)}</small>
            </div>;
        };
        columnProps.push(column4);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("productName", i18n("商品"), "left", false, false);
        column8.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const order: FssOrder = rowData as FssOrder;
            return <div>
                {this.getProductDetail(order, rowIndex)}
            </div>;
        };
        columnProps.push(column8);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("下单时间"));
        columnProps.push(column6);
        return columnProps;
    }

    getProductDetail(order: FssOrder, rowIndex: number) {
        if (order.items != null && order.items.length > 0) {
            const itemArr = order.items;
            const itemsElement = itemArr.map((orderItem: FssOrderItem, index: number) => {
                let label: string = "";
                label += orderItem.productName + ", ";
                label += orderItem.productCharacteristic;
                label = label.length > 20 ? label.substr(0, 20) : label;
                label += " x " + orderItem.quantity;
                return <div>{label}</div>;
            });
            return <Tooltip title={itemsElement}>
                <Chip label={i18n("共{0}件", order.quantity + "")} variant={"outlined"} size={"small"}/>
            </Tooltip>;
        } else {
            return <Tooltip title={""} onOpen={() => {
                FssOrderService.getOrder(order.id, (orderWithItem: FssOrder) => {
                    this.state.tableAdapter.pagedList.content[rowIndex] = orderWithItem;
                    this.setState({tableAdapter: this.state.tableAdapter})
                });
            }
            }>
                <Chip label={i18n("共{0}件", order.quantity + "")} variant={"outlined"} size={"small"}/>
            </Tooltip>;
        }

    }

    getDeliveryProviderName(order: FssOrder) {
        if (order.shippingType === EShippingType.PICK_UP) {
            if (order.deliveryProvider !== null) {
                return <small>{"自取：" + i18n(order.deliveryProvider.deliveryProviderName)}</small>
            } else {
                return <small>{i18n(order.shippingTypeText)}</small>
            }
        } else {
            if (order.deliveryProvider !== null) {
                return <div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <small>{"送货：" + i18n(order.deliveryProvider.deliveryProviderName)}</small>
                        {order.trackingNumber != null && order.trackingNumber.length > 0 &&
                        <span title={order.trackingNumber} style={{lineHeight: 1}}>
                            <ListAlt fontSize={"small"} />
                        </span>}
                    </div>
                    <div style={{display: "flex", alignItems: "center"}}>
                        {order.courierName != null && order.courierName.length > 0 &&
                        <small>司机:{order?.courierName}</small>}
                        {order?.deliverySequence && <small style={{
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderRadius: 8,
                            paddingLeft: 4,
                            paddingRight: 4,
                            paddingTop: 0,
                            paddingBottom: 0
                        }}>{order?.deliverySequence}</small>}
                    </div>
                </div>
            } else {
                return <small>{i18n(order.shippingTypeText)}</small>
            }
        }
        return null;
    }

    componentDidMount(): void {
        this.resetSelectedRow();
        this.state.tableAdapter.loadData();
        this.loadDeliveryProvider();
        if (this.props.orderPageType && this.props.orderPageType == EOrderPageType.WAIT_SHIPPING_ORDER) {
            this.getExceptionOrders();
        }
        FssSellerService.isDefaultSeller((data: boolean) => {
            if (data) {
                let columnProps = this.state.columnProps;
                const column7: FtDataTableColumnProps = new FtDataTableColumnProps("seller.shortName", i18n("店铺名称"));
                columnProps.push(column7);
                this.setState({columnProps: columnProps});
            }
        });
    }

    getExceptionOrders(): void {
        let orderStatus: Array<EOrderStatus> = [EOrderStatus.CANCELED];
        let paymentStatus: Array<EPaymentStatus> = [EPaymentStatus.PAID];
        let filterColumns: Map<string, any> = new Map<string, any>();
        filterColumns.set("orderStatus", orderStatus);
        filterColumns.set("paymentStatus", paymentStatus);
        FssOrderService.getStoreOrderList("", filterColumns, null, null, 0, 1,
            (result: FtPagedList<FssOrder>) => {
                FtToast.closeToast();
                this.setState({exceptionOrderCount: result.totalElements});
            });
    }

    onBack() {
        this.props.history.goBack();
    }

    render() {
        return <div>
            {this.props.onBack &&
            <FtToolbar leftNode={<Button onClick={this.props.onBack}><ChevronLeft/>{i18n("返回")}</Button>}/>}
            {this.getFtDataTable()}
            {this.getDeliveryProviderDialog()}
            {this.getBatchPrintPickOrdersDialog()}
            <FssOrderPrint58 ref={(element) => this.orderPrint58 = element} orderList={this.state.selectedDataList}/>
            <FssOrderPrintLetterSize ref={(element) => this.orderPrintLetterSize = element}
                                     orderList={this.state.selectedDataList}/>
            <FssOrderWaybillPrint ref={(element) => this.orderWaybillPrint = element}
                                  orderList={this.state.selectedDataList}/>
            <FssOrderPrintPickingList ref={(element) => this.orderPrintPickingList = element}
                                      orderList={this.state.selectedDataList}
                                      printOrdersSort={this.state.printOrdersSort}
                                      printOrderGroupBy={this.state.printOrderGroupBy}
                                      printOrdersFlag={this.state.printOrdersFlag}
                                      printOrdersCountPerGroup={this.state.printOrdersCountPerGroup}/>
        </div>;
    }

    getFtDataTable(): ReactNode {
        let title: string = i18n("订单列表");
        if (this.props.title) {
            title = i18n(this.props.title);
        }
        return <FtDataTable title={title} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                            toolbarWithSelect={this.getToolbarWithSelect()}
                            toolbarNoSelect={this.getToolbarNoSelect()}>
        </FtDataTable>
    }

    onChangePageNo() {
        this.state.tableAdapter.changePageNo(0);
    }

    getToolbarNoSelect(): ReactNode {
        if (this.props.isProductDetailPage) {
            return null;
        } else if (this.props.orderPageType && this.props.orderPageType == EOrderPageType.EXCEPTION_ORDER) {
            return <Button onClick={this.onBack}>
                {i18n("返回")}
            </Button>;
        } else if (this.props.orderPageType && this.props.orderPageType == EOrderPageType.MARKETING_ORDER) {
            return <div style={{display: "flex"}}>
                <Button onClick={this.onBack}>
                    {i18n("返回")}
                </Button>
                <ButtonGroup variant={"outlined"}>
                    <Tooltip title={i18n("报表")}>
                        <Button onClick={this.onClickPrintMarketing}>
                            {i18n("报表")}
                        </Button>
                    </Tooltip></ButtonGroup></div>;
        } else {
            return <div>
                <IconButton onClick={this.onClickDownloadCsv}><CloudDownloadOutlined/></IconButton>
                {this.props.orderPageType && this.props.orderPageType == EOrderPageType.WAIT_SHIPPING_ORDER && this.state.exceptionOrderCount > 0 &&
                <Link to={"/order/list/" + EMenuitem.ExceptionOrderList + "/0"}>
                    <Button color={"primary"}>{i18n("有{0}个异常订单需要人工处理", this.state.exceptionOrderCount + "")}</Button>
                </Link>}
            </div>;
        }
    }

    onClickPrintMarketing() {
        this.setState({selectedDataList: []}, () => {
            const options: Array<FtOptionDialogItem> = [];
            options.push(new FtOptionDialogItem("shippedSumOrder", "shippedSumOrder", i18n("出货总单")));
            options.push(new FtOptionDialogItem("pickUpSumOrder", "pickUpSumOrder", i18n("取货点总单")));
            options.push(new FtOptionDialogItem("singleOrder", "singleOrder", i18n("个别发货单")));
            FtOptionDialog.dialog.openDialog(i18n("请选择"), options, (option: FtOptionDialogItem, index: number) => {
                if (option.value == "shippedSumOrder") {
                    this.props.history.push("/shippedSumOrder/" + this.state.tableAdapter.filterColumns.get("marketingEventId"));
                } else if (option.value == "pickUpSumOrder") {
                    this.props.history.push("/pickUpSumOrder/" + this.state.tableAdapter.filterColumns.get("marketingEventId"));
                } else if (option.value == "singleOrder") {
                    this.props.history.push("/singleOrder/" + this.state.tableAdapter.filterColumns.get("marketingEventId"));
                }
                FtOptionDialog.dialog.closeDialog();
            });
        });
    }

    private onClickDownloadCsv() {
        const options: Array<FtOptionDialogItem> = [];
        options.push(new FtOptionDialogItem("exportOrderBasicInfo", "exportOrderBasicInfo", i18n("导出订单基本信息"), i18n("不包括商品信息")));
        options.push(new FtOptionDialogItem("exportOrderAllInfo", "exportOrderAllInfo", i18n("导出订单全部信息"), i18n("包括全部商品信息")));
        FtOptionDialog.dialog.openDialog(i18n("请选择要导出的内容"), options, (option: FtOptionDialogItem, index: number) => {
            if (option.value == "exportOrderBasicInfo") {
                FtToast.showToastLoading(i18n("正在加载，数据量较大时可能需几分钟"), 600000);
                this.state.tableAdapter.filterColumns.set("includeOrderItemList", false);
                FssOrderService.exportOrderExcel(this.state.tableAdapter.keyword, this.state.tableAdapter.filterColumns,
                    this.state.tableAdapter.sortColumn ? this.state.tableAdapter.sortColumn.propertyName : null,
                    this.state.tableAdapter.sortDirection, 0, 999999, (response: Response) => {
                        response.blob().then(blob => {
                            let blobUrl = window.URL.createObjectURL(blob);
                            var aElement = document.createElement('a');
                            let filename = response.headers.get('filename') + "";//设置文件名称
                            aElement.href = blobUrl;//设置a标签路径
                            aElement.download = filename;
                            aElement.click();
                            window.URL.revokeObjectURL(blobUrl);
                            FtToast.closeToast();
                        });
                    });
            } else if (option.value == "exportOrderAllInfo") {
                FtToast.showToastLoading(i18n("正在加载，数据量较大时可能需几分钟"), 600000);
                this.state.tableAdapter.filterColumns.set("includeOrderItemList", true);
                FssOrderService.exportOrderExcel(this.state.tableAdapter.keyword, this.state.tableAdapter.filterColumns,
                    this.state.tableAdapter.sortColumn ? this.state.tableAdapter.sortColumn.propertyName : null,
                    this.state.tableAdapter.sortDirection, 0, 999999, (response: Response) => {
                        response.blob().then(blob => {
                            let blobUrl = window.URL.createObjectURL(blob);
                            var aElement = document.createElement('a');
                            let filename = response.headers.get('filename') + "";//设置文件名称
                            aElement.href = blobUrl;//设置a标签路径
                            aElement.download = filename;
                            aElement.click();
                            window.URL.revokeObjectURL(blobUrl);
                            FtToast.closeToast();
                        });
                    });
            }
        });
    }

    getToolbarWithSelect(): ReactNode {
        if (this.props.isProductDetailPage) {
            return null;
        } else if (this.props.orderPageType && this.props.orderPageType == EOrderPageType.EXCEPTION_ORDER) {
            return <Button onClick={this.onBack}>{i18n("返回")}</Button>;
        } else if (this.props.orderPageType && this.props.orderPageType == EOrderPageType.MARKETING_ORDER) {
            return <Button onClick={this.onBack}>{i18n("返回")}</Button>;
        } else {
            let isShippedOrderPage = this.props.orderPageType && this.props.orderPageType == EOrderPageType.SHIPPED_ORDER;
            return <div style={{display: "flex"}}>
                <ButtonGroup variant={"outlined"}>
                    <Tooltip title={i18n("打印")}>
                        <Button onClick={this.onClickPrint}>
                            <Print/>{i18n("打印")}
                        </Button>
                    </Tooltip>
                    <Button variant={"outlined"}
                            onClick={this.onClickSyncOrderWithDeliveryProvider}>{i18n("同步运单")}</Button>
                    <Button variant={"outlined"} onClick={(e) => {
                        this.setState({openBatchPrintPickOrdersDialog: true, openBatchPrintPickOrdersDialogBy: "pick"})
                    }}>{i18n("拣货打包")}</Button>
                    <Button variant={"outlined"} onClick={this.onClickExportExpressOrder}>{i18n("导出运单")}</Button>
                    {/*待收获页面不显示发货*/}
                    {!isShippedOrderPage &&
                    <Button color="primary" onClick={this.onClickSendToDeliverProvider}>{i18n("发货")}</Button>}
                    {/*完成发货,只有待发货页面有*/}
                    {(isShippedOrderPage || (this.props.orderPageType && this.props.orderPageType == EOrderPageType.FIND_ORDER)) &&
                    <Button color="primary" onClick={this.onFinishOrders}>{i18n("送达完成")}</Button>}
                </ButtonGroup>
                {this.props.orderPageType && this.props.orderPageType == EOrderPageType.WAIT_SHIPPING_ORDER && this.state.exceptionOrderCount > 0 &&
                <Link to={"/order/list/" + EMenuitem.ExceptionOrderList + "/0"}>
                    <Button color={"primary"}>{i18n("有{0}个异常订单需要人工处理", this.state.exceptionOrderCount + "")}</Button>
                </Link>}
            </div>;
        }
    }

    onClickCancel() {
        this.setState({openBatchPrintPickOrdersDialog: false});
    }

    getBatchPrintPickOrdersDialog(): ReactNode {
        const rows = this.state.tableAdapter.selectedRow;
        const orderIds: Array<string> = [];
        const childOrderIds: Array<string> = [];
        rows.forEach((order: FssOrder) => {
            if (order.parentOrderId != null && order.parentOrderId.length > 0) {
                childOrderIds.push(order.id);
            } else {
                orderIds.push(order.id);
            }
        });
        return <Dialog open={this.state.openBatchPrintPickOrdersDialog} onClose={(e) => {
            this.setState({openBatchPrintPickOrdersDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <FtDivFlex justifyContent={"space-between"}>
                <div>{i18n("批量打印拣货单")}</div>
                <IconButton onClick={this.onClickCancel}>
                    <CloseIcon/>
                </IconButton>
            </FtDivFlex>
            <Divider/>
            <DialogContent>
                <FtPanel panelHeader={i18n("共选择{0}个主订单（{1}个子订单）", orderIds.length + "", childOrderIds.length + "")}
                         elevation={0}>
                    <FtGrid>
                        <FtRow cellWidthS={"1"}>
                            <TextField select={true} label={"订单打印顺序"} value={this.state.printOrdersSort}
                                       onChange={(event: any) => {
                                           this.setState({printOrdersSort: event.target.value as unknown as string});
                                           this.forceUpdate();
                                       }}>
                                <MenuItem value={"deliverySequence"} key={"deliverySequence"}>{i18n("按配送顺序")}</MenuItem>
                                <MenuItem value={"createTime"} key={"createTime"}>{i18n("按下单时间")}</MenuItem>
                            </TextField>
                            <FormControlLabel
                                control={<Checkbox checked={this.state.printOrderGroupBy != ""} onChange={(event) => {
                                    this.setState({printOrderGroupBy: event.target.checked ? "groupByCount" : ""});
                                }}/>} label="分组打印"/>
                            <RadioGroup style={{paddingLeft: 16}} value={this.state.printOrderGroupBy}
                                        onChange={(event, value: any) => {
                                            this.setState({printOrderGroupBy: value});
                                        }}>
                                <FormControlLabel value="groupByCount"
                                                  control={<Radio disabled={this.state.printOrderGroupBy === ""}/>}
                                                  label={<FtRow>{i18n("每")}
                                                      <FtTextFieldNumber id="printOrdersCountPerGroup"
                                                                         style={{width: 60}} type={"integer"}
                                                                         value={this.state.printOrdersCountPerGroup}
                                                                         onChangeValue={(value) => {
                                                                             if (value) {
                                                                                 this.setState({printOrdersCountPerGroup: value})
                                                                             }
                                                                         }}
                                                                         disabled={this.state.printOrderGroupBy != "groupByCount"}/>{i18n("个订单一组")}
                                                  </FtRow>}/>
                                <FormControlLabel value="groupByCourier"
                                                  control={<Radio disabled={this.state.printOrderGroupBy === ""}/>}
                                                  label="按配送员分组"/>
                            </RadioGroup>
                            <FormControlLabel
                                control={<Checkbox id="printOrdersFlag" color="secondary"
                                                   checked={this.state.printOrdersFlag}
                                                   onChange={(event) => {
                                                       this.setState({printOrdersFlag: event.target.checked})
                                                   }}/>}
                                label={i18n("打印订单")}/>

                        </FtRow>
                    </FtGrid>
                </FtPanel>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({
                        openBatchPrintPickOrdersDialog: false,
                        openBatchPrintPickOrdersDialogBy: "",
                        printOrdersSort: "deliverySequence",
                        printOrderGroupBy: "groupByCount",
                        printOrdersFlag: true,
                        printOrdersCountPerGroup: 5
                    });
                }}>{i18n("关闭")}</Button>
                <Button variant="contained" onClick={this.onClickPickOrders} color={"secondary"}>{i18n("开始打印")}</Button>
            </DialogActions>
        </Dialog>
    }

    private onClickPickOrders() {
        this.setState({openBatchPrintPickOrdersDialog: false, openBatchPrintPickOrdersDialogBy: ""});
        const rows = this.state.tableAdapter.selectedRow;
        const orderIds: Array<string> = [];
        rows.forEach((order: FssOrder, index: number) => {
            if (order.parentOrderId != null && order.parentOrderId.length > 0) {
                // 子订单不能捡货
            } else {
                orderIds.push(order.id);
            }
        });
        if (orderIds.length > 0) {
            if (this.state.openBatchPrintPickOrdersDialogBy == "pick") {
                FtToast.showToastLoading(i18n("生成拣货单"));
                FssOrderService.pickingOrders(orderIds, (msg: string) => {
                    FtToast.closeToast();
                    this.state.tableAdapter.loadData();
                    FtSnackbar.showText(msg);
                    this.onPrintPickingList(true);
                });
            } else {
                this.onPrintPickingList(false);
            }
        } else {
            FtSnackbar.showText(i18n("请选择订单,子订单不能单独拣货打包"));
        }
    }

    onClickSendToDeliverProvider() {
        const rows = this.state.tableAdapter.selectedRow;
        let message: string = "";
        rows.map((order: FssOrder, index: number) => {
            if (order.status != EOrderStatus.APPROVED && order.status != EOrderStatus.PICKING && order.status != EOrderStatus.PACKAGING) {
                message += "#" + order.orderCode + " : " + i18n(order.statusText) + " , ";
            }
        });
        if (message.length > 0) {
            message = i18n("只有状态为已审核、拣货、打包的订单才可以发货。") + message;
            FtMessageDialog.dialog.openDialog(message);
        } else {
            this.setState({openDeliveryProviderDialog: true});
        }
    }

    onFinishOrders() {
        const rows = this.state.tableAdapter.selectedRow;
        let orderIds: Array<string> = [];
        rows.map((order: FssOrder) => {
            if (order.status == EOrderStatus.SHIPPED && (order.parentOrderId == null || order.parentOrderId.length == 0)) {
                orderIds.push(order.id);
            }
        });
        if (orderIds.length > 0) {
            FtToast.showToastLoading();
            FssOrderService.finishOrders(orderIds, (msg: string) => {
                FtToast.closeToast();
                this.resetSelectedRow();
                this.state.tableAdapter.loadData();
                FtSnackbar.showText(i18n(msg));
            });
        } else {
            FtSnackbar.showText(i18n("请选择「已发货」的订单,子订单不能单独送达完成"));
        }
    }

    getDeliveryProviderDialog(): ReactNode {
        return <Dialog open={this.state.openDeliveryProviderDialog} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle style={{paddingBottom: 0}}>{i18n("发货确认")}</DialogTitle>
            <DialogContent style={{paddingBottom: 20}}>
                <FormControlLabel
                    control={<Checkbox
                        checked={this.state.smsNotificationFlag}
                        onChange={(event) => {
                            this.setState({smsNotificationFlag: event.target.checked})
                        }}
                        name="checkedB"
                        color="primary"/>}
                    label={i18n("短信通知用户已发货")}
                />
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openDeliveryProviderDialog: false});
                }}>{i18n("关闭")}</Button>
                <Button variant="outlined" onClick={this.sendToDeliveryProvider} color={"primary"}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }

    sendToDeliveryProvider() {
        FtToast.showToastLoading(i18n("正在提交"));
        const rows = this.state.tableAdapter.selectedRow;
        //判断如果是未发货的才进行操作
        let successCount = 0;
        let failCount = 0;
        rows.map((order: FssOrder, index: number) => {
            if (order.parentOrderId != null && order.parentOrderId.length > 0) {
                // 子订单不能发货
                failCount += 1;
            } else {
                if (order.status == EOrderStatus.APPROVED
                    || order.status == EOrderStatus.PICKING
                    || order.status == EOrderStatus.PACKAGING) {
                    successCount += 1;
                    FssOrderService.sendToDeliveryProvider(order.id, order.deliveryProviderId, "",
                        this.state.smsNotificationFlag, "", (resultOrder: FssOrder) => {
                            let number = this.state.tableAdapter.pagedList.content.indexOf(order);
                            this.state.tableAdapter.pagedList.content[number] = FtUtil.mergeObject(new FssOrder(), resultOrder);
                            this.setState({tableAdapter: this.state.tableAdapter})
                        });
                } else {
                    failCount += 1;
                }
            }
            if ((index + 1) === rows.length) {
                let message = "";
                if (successCount > 0) {
                    message += "成功发货 " + successCount + " 个订单";
                }
                if (failCount > 0) {
                    if (message.length > 0) {
                        message += "，";
                    }
                    message += "失败了 " + failCount + " 个订单。子订单不能单独发货";
                }
                FtSnackbar.showText(message);
            }
        });
        this.resetSelectedRow();
        this.setState({openDeliveryProviderDialog: false}, () => {
            FtToast.closeToast();
        })
    }

    resetSelectedRow() {
        this.state.tableAdapter.selectedRow = [];
    }

    onClickPrint() {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            this.setState({selectedDataList: []}, () => {
                const options: Array<FtOptionDialogItem> = [];
                options.push(new FtOptionDialogItem("PrintOrderList58", "PrintOrderList58", i18n("打印订单(小票)")));
                options.push(new FtOptionDialogItem("PrintOrderListLetterSize", "PrintOrderListLetterSize", i18n("打印订单(信纸)")));
                options.push(new FtOptionDialogItem("PrintWaybillList", "PrintWaybillList", i18n("打印快递单")));
                options.push(new FtOptionDialogItem("PrintPickingList", "PrintPickingList", i18n("打印拣货单")));
                FtOptionDialog.dialog.openDialog(i18n("请选择打印内容"), options, (option: FtOptionDialogItem, index: number) => {
                    if (option.value == "PrintOrderList58") {
                        this.sortOrderItems(this.state.tableAdapter.selectedRow);
                        this.onPrintOrderList58();
                        FtOptionDialog.dialog.closeDialog();
                    } else if (option.value == "PrintOrderListLetterSize") {
                        this.sortOrderItems(this.state.tableAdapter.selectedRow);
                        this.onPrintOrderListLetterSize();
                        FtOptionDialog.dialog.closeDialog();
                    } else if (option.value == "PrintWaybillList") {
                        this.onPrintWaybillList();
                        FtOptionDialog.dialog.closeDialog();
                    } else if (option.value == "PrintPickingList") {
                        FtOptionDialog.dialog.closeDialog();
                        this.setState({openBatchPrintPickOrdersDialog: true, openBatchPrintPickOrdersDialogBy: "print"})
                    }
                });
            });
        }
    }

    onPrintOrderList58() {
        if (this.orderPrint58) {
            this.setState({selectedDataList: this.state.tableAdapter.selectedRow}, () => {
                window.setTimeout(() => {
                    if (this.orderPrint58)
                        this.orderPrint58.print();
                }, 500);
            });
        }
    }

    onPrintOrderListLetterSize() {
        if (this.orderPrintLetterSize) {
            this.setState({selectedDataList: this.state.tableAdapter.selectedRow}, () => {
                window.setTimeout(() => {
                    if (this.orderPrintLetterSize)
                        this.orderPrintLetterSize.print();
                }, 1000);
            });
        }
    }

    onPrintWaybillList() {
        if (this.orderWaybillPrint) {
            this.setState({selectedDataList: this.state.tableAdapter.selectedRow}, () => {
                window.setTimeout(() => {
                    if (this.orderWaybillPrint)
                        this.orderWaybillPrint.print();
                }, 500);
            });
        }
    }

    onPrintPickingList(resetRowSelected: boolean) {
        if (this.orderPrintPickingList) {
            this.setState({selectedDataList: this.state.tableAdapter.selectedRow}, () => {
                if (resetRowSelected) {
                    this.resetSelectedRow();
                }
            });
            window.setTimeout(() => {
                if (this.orderPrintPickingList)
                    this.orderPrintPickingList.print();
            }, 500);

        }
    }

    // 按照 productName 排序
    sortOrderItems(orderList: Array<FssOrder>) {
        orderList.forEach((order: FssOrder) => {
            order.items.sort(function (a, b) {
                if (a["productName"] > b["productName"]) {
                    return 1;
                } else if (a["productName"] < b["productName"]) {
                    return -1;
                } else {
                    return 0;
                }
            });
        });
        return orderList;
    }

    private onClickSyncOrderWithDeliveryProvider() {
        const rows = this.state.tableAdapter.selectedRow;
        const orderIds: Array<string> = new Array();
        //判断如果是未发货的才进行操作
        rows.map((order: FssOrder, index: number) => {
            orderIds.push(order.id);
        });
        FtToast.showToastLoading("正在同步运单信息，此过程可能持续数分钟，请耐心等等", 180 * 1000);
        FssOrderService.syncWithDeliveryProvider(orderIds, (message: string) => {
            FtToast.closeToast();
            FtSnackbar.showText(message);
        });
    }

    onClickExportExpressOrder() {
        const options: Array<FtOptionDialogItem> = new Array<FtOptionDialogItem>();
        this.state.deliveryToHomeList.forEach((deliveryProvider: FssDeliveryProvider) => {
            options.push(new FtOptionDialogItem(deliveryProvider.id, deliveryProvider.id, deliveryProvider.deliveryProviderName));
        });
        FtOptionDialog.dialog.openDialog(i18n("请选配送商"), options, (option: FtOptionDialogItem, index: number) => {
            const rows = this.state.tableAdapter.selectedRow;
            let orderIds: string = "";
            //判断如果是未发货的才进行操作
            rows.map((order: FssOrder, index: number) => {
                orderIds += "," + order.id;
            });
            FtToast.showToastLoading();
            FssOrderService.exportExpressOrder(orderIds, option.value, (response: Response) => {
                response.blob().then(blob => {
                    let blobUrl = window.URL.createObjectURL(blob);
                    var aElement = document.createElement('a');
                    let filename = response.headers.get('filename') + "";//设置文件名称
                    aElement.href = blobUrl;//设置a标签路径
                    aElement.download = filename;
                    aElement.click();
                    window.URL.revokeObjectURL(blobUrl);
                    FtToast.closeToast();
                });
            });
            FtOptionDialog.dialog.closeDialog();
        });
    }

    loadDeliveryProvider() {
        FssDeliveryProviderService.getPageDeliveryProviderList("", new Map<string, any>(), null, null, 0, 999,
            (result: FtPagedList<FssDeliveryProvider>) => {
                const data = result.content;
                const deliveryToHomeList: Array<FssDeliveryProvider> = [];
                if (data != null) {
                    data.forEach((item: FssDeliveryProvider) => {
                        if (item.deliveryType == EDeliveryType.DELIVERY_TO_DOOR) {
                            deliveryToHomeList.push(item);
                        }
                    });
                    this.setState({deliveryToHomeList: deliveryToHomeList});
                }
            });
    }
}
