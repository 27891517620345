import * as React from "react";
import {ReactNode} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import FtToast from "../ui/FtToast";
import {CheckCircle} from "@material-ui/icons";
import {TextField} from "@material-ui/core";
import FtSnackbar from "../ui/FtSnackbar";
import {i18n} from "../util/I18n";
import FtRow from "../ui/FtRow";
import FssOrder, {EShippingType} from "../model/FssOrder";
import FssDeliveryProviderService from "../service/FssDeliveryProviderService";
import FssDeliveryProviderDTO from "../model/FssDeliveryProviderDTO";
import _ from "lodash";

interface FssDeliveryProviderDialogProp {

}

export class FssDeliveryProviderDialogState extends FtComponentState {
    deliveryProviderId: string = "";
    trackingNumber: string = "";
    shippingFeeRuleId: string = "";
    regionId: string = "";
    fssDeliveryProviderList: Array<FssDeliveryProviderDTO> = [];
    shippingType?: EShippingType;
    isOtherDeliveryProvider: boolean = false;

    open: boolean = false;
    orderId: string = "";
    order: FssOrder = new FssOrder();
    onSubmitDone?: (order: FssOrder) => void;
}

export default class FssDeliveryProviderDialog extends FtComponent<FssDeliveryProviderDialogProp, FssDeliveryProviderDialogState> {

    constructor(props: any) {
        super(props);
        this.state = {
            deliveryProviderId: "", trackingNumber: "", shippingFeeRuleId: "", regionId: "", order: new FssOrder(),
            fssDeliveryProviderList: [], open: false, orderId: "", isOtherDeliveryProvider: false};
        this.updateDeliveryProvider = this.updateDeliveryProvider.bind(this);
        this.onClose = this.onClose.bind(this);
    }

    show(order: FssOrder, onSubmitDone?: (order: FssOrder) => void): void {
        this.setState({
            open: true,
            deliveryProviderId: order.deliveryProviderId,
            trackingNumber: order.trackingNumber,
            shippingFeeRuleId: order.shippingFeeRuleId,
            regionId: order.regionId,
            orderId: order.id,
            fssDeliveryProviderList: [],
            shippingType: order.shippingType,
            isOtherDeliveryProvider: false,
            order: order,
            onSubmitDone: onSubmitDone
        }, ()=>{
            FtToast.showToastLoading();
            FssDeliveryProviderService.getListByOrderId(order.id, order.shippingType,(data: Array<FssDeliveryProviderDTO>) => {
                FtToast.closeToast();
                if (data && data.length > 0) {
                    this.setState({fssDeliveryProviderList: _.uniqBy(data, 'deliveryProviderId')});
                }
            });
        })
    }

    render() {
        return <Dialog open={this.state.open} onClose={this.onClose} PaperProps={{ style: {width: 450, maxHeight: 600 } }}>
            <DialogTitle>{i18n("请选择配送服务商")}</DialogTitle>
            <DialogContent>
                <Paper>
                    {this.state.shippingType && this.state.shippingType === EShippingType.DELIVERY_TO_HOME && this.getDeliveryToDoorList()}
                    {this.state.shippingType && this.state.shippingType === EShippingType.PICK_UP && this.getSelfPickUp()}
                </Paper>
            </DialogContent>
            <DialogActions style={{ justifyContent: "space-between" }}>
                <Button variant="outlined" onClick={this.onClose}>{i18n("关闭")}</Button>
                <Button variant="outlined" onClick={this.updateDeliveryProvider} color={"primary"}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>;
    }

    getSelfPickUp(): ReactNode {
        return <List component="nav">
            {this.state.fssDeliveryProviderList.map((item: FssDeliveryProviderDTO) => {
                return <ListItem button onClick={() => {
                    this.setState({
                        deliveryProviderId: item.deliveryProviderId,
                        shippingFeeRuleId: "",
                        regionId: "",
                        trackingNumber: ""});
                }}>
                    <ListItemIcon>{this.state.deliveryProviderId === item.deliveryProviderId ?
                        <CheckCircle color={"primary"} /> : <span />}</ListItemIcon>
                    <ListItemText primary={item.deliveryProviderName} secondary={item.shortDescription} />
                </ListItem>
            })}
        </List>
    }

    getDeliveryToDoorList(): ReactNode {
        return <List component="nav">
            {this.state.fssDeliveryProviderList.map((item: FssDeliveryProviderDTO) => {
                return <ListItem button onClick={() => {
                    this.setState({
                        deliveryProviderId: item.deliveryProviderId,
                        shippingFeeRuleId: item.shippingFeeRuleId,
                        regionId: item.regionId, trackingNumber: "",
                        isOtherDeliveryProvider: false});
                }}>
                    <ListItemIcon>{this.state.deliveryProviderId === item.deliveryProviderId ?
                        <CheckCircle color={"primary"} /> : <span />}</ListItemIcon>
                    <ListItemText primary={item.deliveryProviderName} secondary={item.shippingFeeTemplateDescription} />
                </ListItem>
            })}
            <ListItem button onClick={() => {
                this.setState({ deliveryProviderId: "", shippingFeeRuleId: "", regionId: "", trackingNumber: "", isOtherDeliveryProvider: true});
            }}>
                <ListItemIcon>{this.checkIsOtherDeliveryProvide() ? <CheckCircle color={"primary"} />: <span />}</ListItemIcon>
                <ListItemText primary={i18n("其他")} secondary={i18n("请填写配送服务商及运单号")} />
            </ListItem>
            {this.checkIsOtherDeliveryProvide() &&
            <ListItem>
                <FtRow cellWidthS={"1,1"}>
                    <TextField id="deliveryProviderId" label={i18n("配送服务商")} value={this.state.deliveryProviderId}
                               style={{ width: "100%" }} onChange={(event) => {
                        this.setState({ deliveryProviderId: event.target.value })
                    }} />
                    <TextField id="deliveryNoteId" label={i18n("快递号")} value={this.state.trackingNumber}
                               style={{ width: "100%" }} onChange={(event) => {
                        this.setState({ trackingNumber: event.target.value })
                    }} />
                </FtRow>
            </ListItem>}
        </List>
    }

    checkIsOtherDeliveryProvide(): boolean {
        if ((this.state.deliveryProviderId !== null && this.state.deliveryProviderId.length > 0 &&
            this.state.trackingNumber !== null && this.state.trackingNumber.length > 0) ||
            this.state.isOtherDeliveryProvider) {
            return true;
        } else {
            return false;
        }
    }

    onClose() {
        this.setState({open: false});
    }

    updateDeliveryProvider() {
        if (this.state.deliveryProviderId == "") {
            FtSnackbar.showText(i18n("请选择配送服务商"));
        } else {
            if (this.state.onSubmitDone) {
                let order = this.state.order;
                order.regionId = this.state.regionId;
                order.shippingFeeRuleId = this.state.shippingFeeRuleId;
                order.trackingNumber = this.state.trackingNumber;
                order.deliveryProviderId = this.state.deliveryProviderId;
                this.state.onSubmitDone(order);
            }
            this.onClose();
        }
    }
}
