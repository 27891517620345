import FtPagedList from "../model/FtPagedList";
import FssProductSearchCriteria from "../model/FssProductSearchCriteria";
import FssProduct from "../model/FssProduct";
import FssHttpService from "./FssHttpService";
import FssTaxName from "../model/FssTaxName";
import FssUserFavorite from "../model/FssUserFavorite";
import FssProductReview, {EReviewStatus} from "../model/FssProductReview";

export default class FssProductService {
    static getProductListByCategoryId(searchCriteria: FssProductSearchCriteria,
                                      successFunction: (data: FtPagedList<FssProduct>) => void,
                                      failFunction ?: (error: any) => void): void {
        FssHttpService.postJson("/api/product/getProductListByCategoryId", searchCriteria, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static updateProductCategoryByCategoryId(categoryId: string, toCategoryId: string, successFunction: (data: string) => void): void {
        FssHttpService.postFormData("/api/product/updateProductCategoryByCategoryId", {
            categoryId: categoryId,
            toCategoryId: toCategoryId
        }, successFunction, FssHttpService.handleError);
    }

    static getProduct(productId: string, successFunction: (result: FssProduct) => void) {
        FssHttpService.get("/api/product/getProduct", {
            productId: productId
        }, successFunction, FssHttpService.handleError);
    }

    static getProductWebSite(productId: string, successFunction: (result: FssProduct) => void) {
        FssHttpService.get("/api/product/getProductWebSite", {
            productId: productId
        }, successFunction, FssHttpService.handleError);
    }

    static saveProduct(product: FssProduct, successFunction: (product: FssProduct) => void): void {
        //为了避免子商品引用主商品造成 json 循环，要把子商品去掉
        product.childProductList = new Array<FssProduct>();
        //为了避免子商品引用主商品造成 json 循环，要把主商品去掉
        product.parentProduct = undefined;
        //拼接taxNameId
        const taxNameIds = product.taxNameList?.reduce((result: string, item: FssTaxName, index: number, []): string => {
            return result + "," + item.id;
        }, "");
        if (taxNameIds != null && taxNameIds.length > 0)
            product.taxNameIds = taxNameIds.substring(1);
        else
            product.taxNameIds = undefined;
        FssHttpService.postJson("/api/product/saveProduct", product, successFunction, FssHttpService.handleError);
    }

    static getStoreProductList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                               pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssProduct>) => void): void {
        FssHttpService.search("/api/product/getStoreProductList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static updateProductStockAmount(productId: string, stockAmountType: string, stockAmount: number, successFunction: (product: FssProduct) => void): void {
        FssHttpService.postFormData("/api/product/updateProductStockAmount", {
            productId: productId,
            stockAmountType: stockAmountType,
            stockAmount: stockAmount
        }, successFunction, FssHttpService.handleError);
    }

    static updateProductSortIndex(productId: string, sortIndex: number, successFunction: (product: FssProduct) => void): void {
        FssHttpService.postFormData("/api/product/updateProductSortIndex", {
            productId: productId,
            sortIndex: sortIndex
        }, successFunction, FssHttpService.handleError);
    }

    static getAvailProductList(keyword: string, maxCount: number, productStatus: Array<string>, successFunction: (productList: Array<FssProduct>) => void, failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/product/getAvailProductList", {
            keyword: keyword,
            maxCount: maxCount,
            productStatus: productStatus
        }, successFunction, failFunction);
    }

    static getRecommendProductList(productId: string, maxCount: number, successFunction: (productList: Array<FssProduct>) => void, failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/product/getRecommendProductList", {
            productId: productId,
            maxCount: maxCount
        }, successFunction, failFunction);
    }

    static getStoreTagsList(successFunction: (result: Array<string>) => void) {
        FssHttpService.get("/api/product/getStoreTagsList", {}, successFunction, FssHttpService.handleError);
    }

    static permanentDelete(productIds: Array<string>, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/permanentDelete", {productIds: productIds}, successFunction, FssHttpService.handleError);
    }

    static setBatchCategory(productIds: string, categoryId: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/setBatchCategory", {
            productIds: productIds,
            categoryId: categoryId
        }, successFunction, FssHttpService.handleError);
    }

    static setBatchStoreCategory(productIds: string, categoryId: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/setBatchStoreCategory", {
            productIds: productIds,
            categoryId: categoryId
        }, successFunction, FssHttpService.handleError);
    }

    static setBatchShippingFeeTemplate(productIds: string, shippingFeeTemplateIdList: Array<string>, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/setBatchShippingFeeTemplate", {
            productIds: productIds,
            shippingFeeTemplateIds: shippingFeeTemplateIdList
        }, successFunction, FssHttpService.handleError);
    }

    static setBatchProductTax(productIds: string, taxIds: Array<string>, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/setBatchProductTax", {
            productIds: productIds,
            taxIds: taxIds
        }, successFunction, FssHttpService.handleError);
    }

    static setBatchProductTags(productIds: string, tags: Array<string>, type: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/setBatchProductTags", {
            productIds: productIds,
            tags: tags,
            type: type
        }, successFunction, FssHttpService.handleError);
    }

    static setBatchProductStatus(productIds: string, productStatus: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/setBatchProductStatus", {
            productIds: productIds,
            productStatus: productStatus
        }, successFunction, FssHttpService.handleError);
    }

    static batchSetPickingGroup(productIds: Array<string>, pickingGroup: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/batchSetPickingGroup", {
            productIds: productIds,
            pickingGroup: pickingGroup
        }, successFunction, FssHttpService.handleError);
    }

    static batchSetStorageLocation(productIds: Array<string>, storageLocation: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/batchSetStorageLocation", {
            productIds: productIds,
            storageLocation: storageLocation
        }, successFunction, FssHttpService.handleError);
    }

    static batchUpdateSortIndex(productIds: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/product/batchUpdateSortIndex", {
            productIds: productIds
        }, successFunction, FssHttpService.handleError);
    }

    static copyProduct(productId: string, successFunction: (product: FssProduct) => void): void {
        FssHttpService.get("/api/product/copyProduct", {productId: productId}, successFunction, FssHttpService.handleError);
    }

    static exportProductCsv(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                            pageNo: number, pageSize: number, successFunction: (fileUrl: string) => void): void {
        FssHttpService.search("/api/product/exportProductCsv", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static getOnlineListByTag(eventId: string, tag: string, storeId: string, sellerId: string, successFunction: (productList: FtPagedList<FssProduct>) => void): void {
        let params = {eventId: eventId, tag: tag, storeId: storeId, sellerId: sellerId};
        FssHttpService.get("/api/product/getOnlineListByTag", params, successFunction, FssHttpService.handleError);
    }

    static getAvailProductByBrandAndCategoryList(keyword: string, maxCount: number, successFunction: (productList: Array<FssProduct>) => void): void {
        FssHttpService.get("/api/product/getAvailProductByBrandAndCategoryList", {
            keyword: keyword,
            maxCount: maxCount
        }, successFunction, FssHttpService.handleError);
    }

    static getProductListByIdArray(productIds: Array<string>, successFunction: (categoryList: Array<FssProduct>) => void) {
        FssHttpService.get("/api/product/getProductListByIds", {productIds: productIds}, successFunction);
    }

    static getProductListByTagList(storeId: string, sellerId: string, tagList: Array<string>, successFunction: (productList: Array<FssProduct>) => void) {
        FssHttpService.get("/api/product/getProductListByTagList", {
            storeId: storeId,
            sellerId: sellerId,
            tagList: tagList
        }, successFunction);
    }

    // 获取换购商品
    static getExchangeProductList(orderBalance: number, sellerId: string, successFunction: (productList: Array<FssProduct>) => void) {
        FssHttpService.get("/api/product/getExchangeProductList", {
            orderBalance: orderBalance,
            sellerId: sellerId
        }, successFunction, FssHttpService.handleError);
    }

    static getViewProductHistory(successFunction: (productList: Array<FssProduct>) => void, failFunction ?: (error: any) => void) {
        FssHttpService.postFormData("/api/product/getViewProductHistory", {}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static getUserFavoriteProdyctList(successFunction: (productList: Array<FssProduct>) => void) {
        FssHttpService.get("/api/product/getUserFavoriteProdyctList", {}, successFunction, FssHttpService.handleError);
    }

    static getUserFavorite(productId: string, successFunction: (data: FssUserFavorite) => void) {
        FssHttpService.get("/api/product/getUserFavorite", {productId: productId}, successFunction, FssHttpService.handleError);
    }

    static saveUserFavorite(productId: string, successFunction: (data: FssUserFavorite) => void, failFunction ?: (error: any) => void) {
        FssHttpService.postFormData("/api/product/saveUserFavorite", {productId: productId}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static saveProductReview(productReview: FssProductReview, successFunction: (productReview: FssProductReview) => void,
                             failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/product/saveProductReview", productReview, successFunction, FssHttpService.handleError);
    }

    static deleteProductReview(productReviewId: string, reviewStatus: EReviewStatus, successFunction: (msg: string) => void, failFunction?: (error: any) => void): void {

        FssHttpService.postFormData("/api/product/deleteProductReview", {
            productReviewId: productReviewId,
            reviewStatus: reviewStatus
        }, successFunction, FssHttpService.handleError)
    }

    static getProductReviewPagedList(filterColumns: Map<string, any>, pageNo: number, pageSize: number, successFunction: (pagedList: FtPagedList<FssProductReview>) => void,
                                     failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/product/getProductReviewPagedList", "", filterColumns, null, null, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static getProductReviewPagedListAdmin(filterColumns: Map<string, any>, pageNo: number, pageSize: number, successFunction: (pagedList: FtPagedList<FssProductReview>) => void,
                                          failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/product/getProductReviewPagedListAdmin", "", filterColumns, null, null, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static getProductReviewById(productReviewId: string, successFunction: (productReview: FssProductReview) => void,
                                failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/product/getProductReviewById", {productReviewId: productReviewId}, successFunction, FssHttpService.handleError);
    }

    static saveUserHistoryProduct(productId: string, userId: string, successFunction: (data: string) => void,
                                  failFunction?: (error: any) => void): void {
        FssHttpService.postFormData("/api/product/saveUserHistoryProduct", {
            productId: productId,
            userId: userId
        }, successFunction, FssHttpService.handleError);
    }

    static marketingEventCopyProductByTag(marketingEventId: string, successFunction: (msg: string) => void): void {
        let params = {marketingEventId: marketingEventId};
        FssHttpService.postFormData("/api/product/marketingEventCopyProductByTag", params, successFunction, FssHttpService.handleError);
    }

    static importProduct(file: any, successFunction: (data: Array<FssProduct>) => void, progressFunction: (percent: number) => void, failFunction: (error: any) => void): void {
        FssHttpService.upload("/api/product/importProduct", file, undefined, (data: any) => {
            console.log(data)
            if (data && data.code != null && data.code === "OK") {
                successFunction(data.content);
            }
        }, progressFunction, failFunction);
    }

    static saveProductList(productList: Array<FssProduct>, successFunction: (data: string) => void) {
        FssHttpService.postJson("/api/product/saveProductList", productList, successFunction, FssHttpService.handleError);
    }

}
