import * as React from "react";
import {ReactNode} from "react";
import {Link} from "react-router-dom";
import {Button, Typography} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FssProductExchangeRule, {EStatus} from "../model/FssProductExchangeRule";
import FtToast from "../ui/FtToast";
import FssProductExchangeRuleService from "../service/FssProductExchangeRuleService";
import FtPagedList from "../model/FtPagedList";
import FtState from "../ui/FtState";
import {i18n} from "../util/I18n";
import FtPage from "../ui/FtPage";
import FtRow from "../ui/FtRow";

export interface FssProductExchangeRuleListPagePorp {

}

class FssProductExchangeRuleListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class JtTableAdapter extends FtDataTableAdapter<FssProductExchangeRule> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssProductExchangeRuleService.getExchangeRuleList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssProductExchangeRule>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssProductExchangeRuleListPage extends FtComponent<any, FssProductExchangeRuleListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("name", "名称");
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const rule: FssProductExchangeRule = rowData as FssProductExchangeRule;
            return <Link to={"/fssExchangeRuleDetail/" + rule.id}>{columnValue}</Link>
        };
        columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("balanceAmountLimit", i18n("订单金额限制"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("startDatetimeText", i18n("换购开始日期"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("endDatetimeText", i18n("换购结束日期"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("创建时间"));
        columnProps.push(column5);
        let tableAdapter: JtTableAdapter;
        const cacheState: FssProductExchangeRuleListPageState = FtState.pop("FssProductExchangeRuleListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssProductExchangeRuleListPage"};
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.getFilterPanel = this.getFilterPanel.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <FtPage onCancelClick={() => {
            this.props.history.goBack()
        }}
                       toolbarLeftNode={<Typography variant="h6">{i18n("换购规则列表")}</Typography>}>
            <FtDataTable columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarNoSelect={this.getToolbarNoSelect()}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </FtPage>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow>

            <FormControl style={{minWidth: 150}}>
                <InputLabel> {i18n("状态")} </InputLabel>
                <Select id="status" value={this.state.tableAdapter.getFilterColumnValue("status")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("status", event.target.value);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={EStatus.NORMAL} key={"NORMAL"}>{i18n("正常")}</MenuItem>
                    <MenuItem value={EStatus.STOP} key={"STOP"}>{i18n("停用")}</MenuItem>
                </Select>
            </FormControl>

        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/fssExchangeRuleDetail/0"}>
                <Button>
                    <Add/>{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }
}
