import * as React from "react";
import FssCategoryGridCompData from "./data/FssCategoryGridCompData";
import FtRow from "../../ui/FtRow";
import FtIconLabel from "../../ui/FtIconLabel";
import FssAbstractCompProp from "./FssAbstractCompProp";
import FtComponent, {FtComponentState} from "../../ui/FtComponent";
import AppClientInfo from "../../data/AppClientInfo";
import FssCategory from "../../model/FssCategory";
import FssCategoryService from "../../service/FssCategoryService";

interface FssCategoryGridCompProp extends FssAbstractCompProp {
    sellerId?: string;
}

export class FssCategoryGridCompState extends FtComponentState {
    categoryList: Array<FssCategory> = [];
}

export default class FssCategoryGridComp extends FtComponent<FssCategoryGridCompProp, FssCategoryGridCompState> {
    constructor(props: any) {
        super(props);
        this.state = {categoryList: [], stateKey: "FssCategoryGridComp" + this.props.component.id};
        if (this.props.history.action == "POP") {
            super.popStateCache();
        }
    }

    componentDidMount(): void {
        const compData = this.props.component.data as FssCategoryGridCompData;
        if (this.state.categoryList.length == 0 && compData != null && compData.categoryIdArray != null && compData.categoryIdArray.length > 0) {
            FssCategoryService.getCategoryListByIds(compData.categoryIdArray, (categoryList: Array<FssCategory>) => {
                this.setState({categoryList: categoryList});
            });
        }
    }

    render() {
        let categoryList: Array<FssCategory> = this.state.categoryList;
        const compData = this.props.component.data as FssCategoryGridCompData;
        let columnAmount=compData.mobileColumnAmount;
        if(AppClientInfo.clientInfo.viewMode!=null&&AppClientInfo.clientInfo.viewMode=="pc")
            columnAmount=compData.pcColumnAmount;
        let cellWidth = "";
        for (let i = 0; i < columnAmount; i++)
            cellWidth += ",1";

        if (categoryList.length > 0)
            return <FtRow cellWidthM={cellWidth.substring(1)} cellHorizontalAlign={"center"} cellHorizontalSpace={8}
                          cellVerticalSpace={8}
                          justifyContent={"center"}
                          style={{
                              fontSize: compData.fontSize, padding: 4,
                              marginLeft: compData.marginLeft,
                              marginRight: compData.marginRight,
                              marginTop: compData.marginTop,
                              marginBottom: compData.marginBottom,
                              borderTopLeftRadius: compData.borderTopLeftRadius,
                              borderTopRightRadius: compData.borderTopRightRadius,
                              borderBottomLeftRadius: compData.borderBottomLeftRadius,
                              borderBottomRightRadius: compData.borderBottomRightRadius,
                              backgroundColor: compData.backgroundColor,
                              color:compData.color
                          }}>
                {categoryList.map((category: FssCategory, index: number) => {
                    return <FtIconLabel label={category.categoryName} iconSrc={category.coverImageUrl}
                                     labelStyle={{fontSize: compData.fontSize}}
                                     onClickMe={() => {
                                         this.props.history.push("/s@"+this.props.sellerId+"/page/category/c@" + category.id);
                                     }}
                                     iconStyle={{
                                         width: "100%",
                                         boxSizing: "border-box",
                                     }} />;
                })}
            </FtRow>;
        else
            return null;
    }

}
