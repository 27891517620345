import FssHttpService from "./FssHttpService";
import FssConversation from "../model/FssConversation";
import FssConversationUser from "../model/FssConversationUser";

export default class FssMessageService {

    static getUnreadMessageCountForSeller(successFunction: (data: number) => void) {
        FssHttpService.get("/api/message/getUnreadMessageCountForSeller", null, successFunction, FssHttpService.handleError);
    }

    static getConversationListFromSeller(successFunction: (data: Array<FssConversation>) => void) {
        FssHttpService.get("/api/message/getConversationListFromSeller", null, successFunction, FssHttpService.handleError);
    }

    static getConversationById(id: string, successFunction: (data: FssConversation) => void) {
        FssHttpService.get("/api/message/getConversationById", {id: id}, successFunction, FssHttpService.handleError);
    }

    static markRead(conversationId: string, messageId: string, successFunction: (data: FssConversationUser) => void) {
        let params = {conversationId: conversationId, messageId: messageId}
        FssHttpService.postFormData("/api/message/markRead", params, successFunction, FssHttpService.handleError);
    }
}
