import * as React from "react";
import FtToast from "../ui/FtToast";
import {Button, Divider, Typography} from "@material-ui/core";
import {ChevronLeft, Print} from "@material-ui/icons";
import {RouteComponentProps} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {i18n} from "../util/I18n";
import FtRow from "../ui/FtRow";
import {grey} from "@material-ui/core/colors";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FssPickUpSumOrderPrint from "./FssPickUpSumOrderPrint";
import FtToolbar from "../ui/FtToolbar";
import FtUtil from "../util/FtUtil";
import FssMarketingEventPickUpSumOrder from "../model/FssMarketingEventPickUpSumOrder";
import FssMarketingEventService from "../service/FssMarketingEventService";
import FssOrderItem from "../model/FssOrderItem";

export interface FssShippedSumOrderPageProp extends RouteComponentProps<{ id: string }> {
}

class FssShippedSumOrderPageState extends FtComponentState {
    orderList: Array<FssMarketingEventPickUpSumOrder> = new Array();
    order: FssMarketingEventPickUpSumOrder = new FssMarketingEventPickUpSumOrder();
}


export default class FssShippedSumOrderPage extends FtComponent<FssShippedSumOrderPageProp, FssShippedSumOrderPageState> {
    print!: FssPickUpSumOrderPrint | null;

    constructor(props: any) {
        super(props);

        this.state = {orderList: [], order: new FssMarketingEventPickUpSumOrder()};
        this.onCancel = this.onCancel.bind(this);
        this.onPrintOrderList58 = this.onPrintOrderList58.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    loadData(): void {
        if (this.props.match.params.id && +this.props.match.params.id.length > 1) {
            FtToast.showToastLoading("loading");
            FssMarketingEventService.getPickUpSumOrder(this.props.match.params.id,
                (result: Array<FssMarketingEventPickUpSumOrder>) => {
                    FtToast.closeToast();
                    this.setState({orderList: result});
                });
        }
    }

    onCancel() {
        this.props.history.goBack();
    }

    render() {
        return <div style={{position: "relative"}}>
            <FtToolbar leftNode={<Button onClick={this.onCancel}><ChevronLeft/>{i18n("返回")}</Button>}
                       centerNode={<Typography variant="h6">{i18n("取货点总单")}</Typography>}
                       rightNode={ <Button variant="contained" onClick={() => {this.onPrintOrderList58()}}><Print/>打印全部</Button>}/>
            {this.state.orderList.map((order: FssMarketingEventPickUpSumOrder) => {
                return <div><FtPanel>
                    <FtGrid>
                        <FtRow justifyContent={"center"}>
                            <Typography></Typography>
                            <Typography variant={"h6"}>{"Invoice"}</Typography>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellVerticalSpace={8}
                               cellHorizontalAlign={"left"}>
                            <Typography style={{wordBreak: "break-all"}}>{"发货时间：" + order.scheduleDatetime}</Typography>
                            <Typography
                                style={{wordBreak: "break-all"}}>{"团购标题：" + order.marketingEventName}</Typography>
                            <Typography
                                style={{wordBreak: "break-all"}}>{"取货点：" + order.pickUpName} {"联系人：" + order.contactName} {"联系电话：" + order.contactPhone}</Typography>
                            <Typography style={{wordBreak: "break-all"}}>{"地址：" + order.pickUpAddress}</Typography>
                            <Typography
                                style={{wordBreak: "break-all"}}>{"总金额：" + FtUtil.toCurrencyText(order.total)} {"总单数：" + order.totalOrderQuantity} {"应收金额：" + FtUtil.toCurrencyText(order.totalPaid)}</Typography>
                        </FtRow>
                        <FtRow cellWidthS={"1,1,1,1"} style={{backgroundColor: grey[400]}}>
                            <Typography>{"品项"}</Typography>
                            <Typography>{"单价"}</Typography>
                            <Typography>{"数量"}</Typography>
                            <Typography>{"金额"}</Typography>
                        </FtRow>
                        {order.orderItemList.map((item: FssOrderItem, index) => {
                            return <FtRow cellWidthS={"1,1,1,1"} style={{
                                backgroundColor: index % 2 == 0 ? "white" : grey[100]
                            }}>
                                <Typography style={{wordBreak: "break-all"}} dangerouslySetInnerHTML={{__html:item.productName}}></Typography>
                                <Typography>{item.price}</Typography>
                                <Typography>{item.quantity}</Typography>
                                <Typography>{FtUtil.toCurrencyText(item.subtotal)}</Typography>
                            </FtRow>
                        })}

                    </FtGrid>
                </FtPanel>
                    <Divider></Divider>
                </div>
            })}

            <FssPickUpSumOrderPrint ref={(element) => this.print = element} orderList={this.state.orderList}/>
        </div>;
    }

    onPrintOrderList58() {
        if (this.print) {
            window.setTimeout(() => {
                if (this.print)
                    this.print.print();
            }, 500);

        }
    }

}
