import * as React from "react";
import {i18n} from "../util/I18n";
import {
    Button,
    FormControlLabel,
    FormLabel,
    ListItemText,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {Save} from "@material-ui/icons";
import {grey} from "@material-ui/core/colors";
import FtToast from "../ui/FtToast";
import FssTimeZone from "../model/FssTimeZone";
import FssAppWebsiteService from "../service//FssAppWebsiteService";
import FssAppWebsite from "../model/FssAppWebsite";
import FtPage from "../ui/FtPage";
import FtPanel from "../ui/FtPanel";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import {Language} from "../data/AppClientInfo";
import FtDrawer from "../ui/FtDrawer";
import FssTranslationListPage from "../translation/FssTranslationListPage";
import FtSnackbar from "../ui/FtSnackbar";
import FssSellerService from "../service/FssSellerService";

class JtLanguageSettingPageState {
    appWebsite: FssAppWebsite = new FssAppWebsite();
    timeZoneList:Array<FssTimeZone> = new Array<FssTimeZone>();
    openDrawer: boolean = false;
}

export default class FssLanguageSettingPage extends React.Component<any, JtLanguageSettingPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            appWebsite: new FssAppWebsite(),
            timeZoneList: [],
            openDrawer: false,
        };
        this.loadData = this.loadData.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onOpenDrawer = this.onOpenDrawer.bind(this);
    }

    componentDidMount() {
        FssSellerService.getAllTimeZone((data: Array<FssTimeZone>) => {
            this.setState({timeZoneList: data});
        });
        this.loadData();
    }

    loadData () {
        FtToast.showToastLoading();
        FssAppWebsiteService.getAppWebsite((website: FssAppWebsite) => {
            FtToast.closeToast();
            this.setState({appWebsite: website});
        });
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssAppWebsiteService.saveAppWebsite(this.state.appWebsite,
            (appWebsite: FssAppWebsite) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
            });
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    render() {
        const paperStyle: any = {padding: 15, marginBottom: 15};
        return <FtPage onCancelClick={this.onCancelClick}
                                  toolbarLeftNode={<Button variant="contained"
                                                           color="primary"
                                                           startIcon={<Save />}
                                                           onClick={this.onClickSave}>{i18n("保存")}</Button>}
                                  toolbarCenterNode={<Typography variant="h6">{i18n("时区和语言设置")}</Typography>}>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("时区设置")}</Typography>}>
                <TextField select id="timeZone" label={i18n("所处时区")} style={{width: 400}}
                           value={this.state.appWebsite.timeZone?this.state.appWebsite.timeZone:""} onChange={(event:any) => {
                    this.state.appWebsite.timeZone = event.target.value as string;
                    this.forceUpdate();
                }}>
                    {this.state.timeZoneList.map((value, index) => {
                        return <MenuItem value={value.timeZoneId}>{value.timeZoneName}</MenuItem>
                    })}
                </TextField>
            </FtPanel>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<ListItemText primary={<Typography color={"secondary"}>{i18n("网店商品多语言设置（本功能在推广期内免费使用）")}</Typography>}
                                                secondary={i18n("设置范围包括商品的标题、分类、描述，优惠券的标题和描述，以及店铺信息相关的部分，这部分由后台再各个有多语言设置的部分录入")} />}>
                <FtRow cellWidthS={"1"}>
                    <FormControl>
                        <FormLabel> 支持的语言 </FormLabel>
                        <FtCheckboxGroup row={true}
                                         values={[Language.en_CA,Language.zh_CN,Language.zh_TW]}
                                         labels={[ "English","简体中文", "繁體中文"]}
                                         checkedValues={this.state.appWebsite.supportLanguages}
                                         onCheck={(checkedValue) => {
                                             this.state.appWebsite.supportLanguages = checkedValue;
                                             this.forceUpdate();
                                         }} >
                        </FtCheckboxGroup>
                    </FormControl>
                    <Button color={"secondary"} variant={"contained"} onClick={this.onOpenDrawer}>查看多语言对照表</Button>
                </FtRow>
            </FtPanel>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<ListItemText primary={<Typography color={"secondary"}>{i18n("网店默认显示语言设置")}</Typography>}
                                                secondary={i18n("设置范围包括用户在浏览网店时，网店的按钮、导航等系统提供的语言")} />}>
                <FtRow cellWidthS={"1"}>
                    <FormControl>
                        <RadioGroup row value={this.state.appWebsite.language ? this.state.appWebsite.language : ""}
                                    onChange={(event, value)=>{
                                        this.state.appWebsite.language = value;
                                        this.forceUpdate();
                                    }}>
                            <FormControlLabel value={Language.en_CA} control={<Radio />} label="English" />
                            <FormControlLabel value={Language.zh_CN} control={<Radio />} label="简体中文" />
                            <FormControlLabel value={Language.zh_TW} control={<Radio />} label="繁体中文" />
                        </RadioGroup>
                    </FormControl>
                </FtRow>
            </FtPanel>

            {this.getDrawerComp()}
        </FtPage>
    }

    getDrawerComp() {
        return <FtDrawer open={this.state.openDrawer} anchor={"right"}
                         PaperProps={{style: {width: "50%", backgroundColor: grey[300]}}}>
            <FtPage onCancelClick={this.onOpenDrawer}
                               toolbarCenterNode={<Typography variant="h6">{i18n("多语言对照表")}</Typography>}
                               childHeight={document.documentElement.clientHeight}>
                <FssTranslationListPage />
            </FtPage>
        </FtDrawer>;
    }

    onOpenDrawer () {
        this.setState({openDrawer: !this.state.openDrawer});
    }
}
