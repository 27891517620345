
export default class FtTranslation{
    groupKey:string="";
    itemKey:string="";
    objectId:string="";
    lang:string="";
    content:string="";

    static newInstance(objectId: string, groupKey: string, itemKey: string, lang: string, content: string): FtTranslation {
        const translation: FtTranslation = new FtTranslation();
        translation.objectId = objectId;
        translation.groupKey = groupKey;
        translation.itemKey = itemKey;
        translation.lang = lang;
        translation.content = content;
        return translation;
    }
}
