import * as React from "react";
import FssProductSupplier, {EType} from "../model/FssProductSupplier";
import FtComboboxDynamic from "../ui/FtComboboxDynamic";
import FtRow from "../ui/FtRow";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip} from "@material-ui/core";
import {i18n} from "../util/I18n";
import {ListAlt} from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell/TableCell";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FtMessageDialog from "../ui/FtMessageDialog";
import _ from "lodash";
import FssProductSupplierService from "../service/FssProductSupplierService";

interface FssProductSupplierComboBoxProp {
    label?: string;
    selectedSupplierName?: string;
    type: EType;
    onChangeProductSupplier?: (productSupplier?: FssProductSupplier) => void;
}

class FssProductSupplierComboBoxState {
    productSupplierList: Array<FssProductSupplier> = [];
    productSupplier?: FssProductSupplier;
    loading: boolean = false;
    showTableDialog: boolean = false;
    allList: Array<FssProductSupplier> = [];
}

export default class FssProductSupplierComboBox extends React.Component<FssProductSupplierComboBoxProp, FssProductSupplierComboBoxState> {
    constructor(props: any) {
        super(props);
        this.state = {
            productSupplierList: [],
            allList: [],
            loading: false,
            showTableDialog: false
        };
        this.onChangeProductSupplierInput = this.onChangeProductSupplierInput.bind(this);
        this.onChangeProductSupplier = this.onChangeProductSupplier.bind(this);
        this.getProductSupplierListDialog = this.getProductSupplierListDialog.bind(this);
        this.onOpenDialog = this.onOpenDialog.bind(this);
        this.onLoad = this.onLoad.bind(this);
        this.onDeleteProductSupplier = this.onDeleteProductSupplier.bind(this);
        this.onSaveProductSupplier = this.onSaveProductSupplier.bind(this);
    }

    componentDidMount(): void {
        this.onLoad();
    }

    onLoad() {
        let filterColumns = new Map<string, any>();
        filterColumns.set("type", this.props.type);
        FssProductSupplierService.getProductSupplierList("", filterColumns,
            null, null, 0, 10000,
            (result: Array<FssProductSupplier>) => {
                this.setState({allList: result});
            });
    }

    render() {
        let productSupplier: FssProductSupplier;
        if (this.state.productSupplier) {
            productSupplier = this.state.productSupplier;
        } else {
            productSupplier = new FssProductSupplier();
            productSupplier.supplierName = this.props.selectedSupplierName ? this.props.selectedSupplierName : "";
        }
        let disabled = true;
        if (this.state.productSupplier && this.state.productSupplier.supplierName.trim().length > 0) {
            let supplierName = this.state.productSupplier.supplierName.trim();
            let index = _.findIndex(this.state.allList, (item: { supplierName: string; }) => item.supplierName === supplierName);
            if (index == -1) {
                disabled = false;
            }
        }
        let title = "";
        if (this.props.type == EType.SUPPLIER) {
            title = "供应商列表";
        } else if (this.props.type == EType.PICKING_GROUP) {
            title = "拣货分类列表";
        } else if (this.props.type == EType.STORAGE_LOCATION) {
            title = "库存位置列表";
        }
        return <FtRow cellWidthS={"1,1"} style={{alignItems: "flex-end"}} key={this.props.type}>
            <FtComboboxDynamic label={this.props.label}
                               optionItems={this.state.productSupplierList}
                               labelPropertyName={"supplierName"}
                               valuePropertyName={"id"}
                               selectedItem={productSupplier}
                               onSelectItem={this.onChangeProductSupplier}
                               onChangeInput={this.onChangeProductSupplierInput}
                               loading={this.state.loading} style={{width: "100%"}}/>
            <div style={{display: "flex"}}>
                <Button color="secondary"
                        style={{padding: 3}}
                        onClick={this.onSaveProductSupplier}
                        disabled={disabled}>
                    {i18n("添加为常用")}
                </Button>
                <Tooltip title={i18n(title)}>
                    <IconButton style={{padding: 8}} onClick={this.onOpenDialog}><ListAlt/></IconButton>
                </Tooltip>
            </div>
            {this.getProductSupplierListDialog()}
        </FtRow>;
    }

    onOpenDialog() {
        this.setState({showTableDialog: !this.state.showTableDialog});
    }

    getProductSupplierListDialog() {
        let title = "";
        if (this.props.type == EType.SUPPLIER) {
            title = "供应商";
        } else if (this.props.type == EType.PICKING_GROUP) {
            title = "拣货分类";
        } else if (this.props.type == EType.STORAGE_LOCATION) {
            title = "库存位置";
        }
        return <Dialog open={this.state.showTableDialog}
                       PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n(title + "列表")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{i18n(title)}</TableCell>
                            <TableCell align={"center"}>{i18n("操作")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {this.state.allList && this.state.allList.map((productSupplier: FssProductSupplier) => {
                        return <TableRow>
                            <TableCell style={{cursor: "pointer"}} onClick={() => {
                                this.setState({productSupplier: productSupplier, showTableDialog: false}, () => {
                                    this.onChangeProductSupplier(productSupplier);
                                });
                            }}>{productSupplier.supplierName}</TableCell>
                            <TableCell align={"center"}>
                                <Button onClick={(event) => {
                                    FtMessageDialog.dialog.openDialog(i18n("确定要删除吗?"), () => {
                                            FtMessageDialog.dialog.closeDialog();
                                            this.onDeleteProductSupplier(productSupplier);
                                        },
                                        () => {
                                            FtMessageDialog.dialog.closeDialog();
                                        }
                                    );
                                }}>{i18n("删除")}</Button>
                            </TableCell>
                        </TableRow>;
                    })}
                </Table>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={this.onOpenDialog}>
                    {i18n("关闭")}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    onChangeProductSupplierInput(keyword: string) {
        let productSupplier = new FssProductSupplier();
        productSupplier.supplierName = keyword;
        this.setState({productSupplier: productSupplier}, () => {
            this.onChangeProductSupplier(productSupplier);
        });
        if (!this.state.loading) {
            this.setState({loading: true, productSupplier: productSupplier}, () => {
                let filterColumns = new Map<string, any>();
                filterColumns.set("type", this.props.type);
                if (this.state.productSupplier) {
                    FssProductSupplierService.getProductSupplierList(this.state.productSupplier.supplierName, filterColumns,
                        null, null, 0, 5,
                        (result: Array<FssProductSupplier>) => {
                            this.setState({productSupplierList: result, loading: false});
                        });
                }
            });
        }
    }

    onChangeProductSupplier(item: FssProductSupplier) {
        if (this.props.onChangeProductSupplier)
            this.props.onChangeProductSupplier(item);
    }

    onSaveProductSupplier() {
        if (this.state.productSupplier) {
            FtToast.showToastLoading(i18n("正在保存"));
            this.state.productSupplier.type = this.props.type;
            FssProductSupplierService.saveProductSupplier(this.state.productSupplier, (result: FssProductSupplier) => {
                FtToast.closeToast();
                let allList = this.state.allList;
                allList.push(result);
                this.setState({allList: allList});
                FtSnackbar.showText(i18n("保存成功"));
            });
        }
    }

    onDeleteProductSupplier(productSupplier: FssProductSupplier) {
        FtToast.showToastLoading(i18n("正在删除"));
        FssProductSupplierService.deleteProductSupplier(productSupplier.id, (result: string) => {
            FtToast.closeToast();
            let allList = this.state.allList;
            allList.splice(allList.indexOf(productSupplier), 1);
            this.setState({allList: allList});
            FtSnackbar.showText(i18n("删除成功"));
        });
    }
}
