import * as React from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {Chip, ListItemSecondaryAction, ListItemText, MenuItem, MenuList} from "@material-ui/core";
import FtGrid from "../ui/FtGrid";
import FtPanel from "../ui/FtPanel";
import {blue} from "@material-ui/core/colors";
import {topBarHeight} from "../mainframe/FssSellerAdminMainPage";
import FssChatConversationComp from "./FssChatConversationComp";
import FssChatClientData from "./FssChatClientData";
import FssConversation from "../model/FssConversation";
import FssClientData from "../data/FssClientData";
import FtToast from "../ui/FtToast";

export interface FssChatPageProp {
    onChangeUnreadMessageCount: (count: number) => void;
}

export class FssChatPageState extends FtComponentState {

}

export default class FssChatPage extends FtComponent<FssChatPageProp, FssChatPageState> {
    chatConversationComp!: FssChatConversationComp;

    constructor(props: any) {
        super(props);
        this.state = {};

        this.getConversationMenuList = this.getConversationMenuList.bind(this);
        this.onClickConversation = this.onClickConversation.bind(this);
    }

    componentDidMount(): void {
        const currentConversation = FssChatClientData.getData().currentConversation;
        if (currentConversation == null) {
            FtToast.showToastLoading();
            window.setTimeout(()=>{
                let conversationList = FssChatClientData.getData().conversationList;
                if (conversationList != null && conversationList.length > 0) {
                    this.onClickConversation(conversationList[0]);
                }
                FtToast.closeToast();
            }, 2000);
        } else {
            this.onClickConversation(currentConversation);
        }
    }

    render() {
        let conversation = FssChatClientData.getData().currentConversation;
        return <FtPanel style={{display: "flex"}}>
            <div style={{width: "25%", borderRight: "solid 1px grey"}}>
                {this.getConversationMenuList()}
            </div>
            <div style={{width: "75%"}}>
                <FtGrid style={{height: document.documentElement.clientHeight - topBarHeight}}>
                    {conversation != null &&
                    <FssChatConversationComp height={document.documentElement.clientHeight - 64}
                                             conversation={conversation}
                                             inputPosition={"stickyBottom"}
                                             ref={element => {
                                                 if (element) this.chatConversationComp = element;
                                             }}/>}
                </FtGrid>
            </div>
        </FtPanel>
    }

    getConversationMenuList() {
        let conversationList = FssChatClientData.getData().conversationList;
        if (conversationList != null && conversationList.length > 0) {
            return <FtGrid style={{overflow: "scroll", height: document.documentElement.clientHeight - topBarHeight}}>
                <MenuList>
                    {conversationList.map((value: FssConversation) => {
                        let isCurrent: boolean = false;
                        let currentConversation = FssChatClientData.getData().currentConversation;
                        if (value.id && currentConversation && value.id === currentConversation.id) {
                            isCurrent = true;
                        }
                        return <MenuItem selected={isCurrent} dense={true}
                                         style={{backgroundColor: isCurrent ? blue[50] : undefined}}
                                         onClick={() => this.onClickConversation(value)}>
                            <ListItemText primary={value.user.nickName}
                                          secondary={FssClientData.formatYYYYmmddHHmmss(value.lastMessageTime)}/>
                            {value.unreadMessageCount != null && value.unreadMessageCount > 0 &&
                            <ListItemSecondaryAction>
                                <Chip label={value.unreadMessageCount} variant={"outlined"} color={"primary"}/>
                            </ListItemSecondaryAction>}
                        </MenuItem>
                    })}
                </MenuList>
            </FtGrid>
        }
        return null;
    }

    private onClickConversation(conversation: FssConversation) {
        let unreadMessageCount = conversation.unreadMessageCount ? conversation.unreadMessageCount : 0;
        FssChatClientData.getData().currentConversation = conversation;
        this.forceUpdate(() => {
            if (this.chatConversationComp && conversation && conversation.messages) {
                this.chatConversationComp.onMessagesChanged(conversation.messages, () => {
                    if (unreadMessageCount > 0) {
                        this.props.onChangeUnreadMessageCount(0 - unreadMessageCount);
                        conversation.unreadMessageCount = 0;
                    }
                    this.forceUpdate();
                });
            }
        });
    }
}
