import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button, Dialog, DialogActions, DialogTitle, TextField} from "@material-ui/core";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtRow from "../ui/FtRow";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Link} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import {i18n} from "../util/I18n";
import FssSellerEmployee, {EStatus} from "../model/FssSellerEmployee";
import FssSellerEmployeeService from "../service/FssSellerEmployeeService";
import FssClientData from "../data/FssClientData";
import {Add} from "@material-ui/icons";
import Divider from "@material-ui/core/Divider";
import DialogContent from "@material-ui/core/DialogContent";
import FtGrid from "../ui/FtGrid";
import JtTextField from "../ui/JtTextField";
import FtDivFlex from "../ui/FtDivFlex";
import FtSnackbar from "../ui/FtSnackbar";

export interface FssSellerEmployeeListPageProp {
}

class FssSellerEmployeeListPageState extends FtComponentState{
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();

    open: boolean = false;
    email: string = "";

}

class JtTableAdapter extends FtDataTableAdapter<FssSellerEmployee> {

    loadData(): void {
        FtToast.showToastLoading("loading");
        FssSellerEmployeeService.getPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssSellerEmployee>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssSellerEmployeeListPage extends FtComponent<FssSellerEmployeeListPageProp, FssSellerEmployeeListPageState> {


    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("userId", "手机号");
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const sellerEmployee: FssSellerEmployee = rowData as FssSellerEmployee;
            return <Link to={"/employee/detail/" + sellerEmployee.id}>{sellerEmployee.user?.mobile}</Link>
        };
        columnProps.push(column0);
        const column10: FtDataTableColumnProps = new FtDataTableColumnProps("email", "Email");
        column10.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const sellerEmployee: FssSellerEmployee = rowData as FssSellerEmployee;
            return <Link to={"/employee/detail/" + sellerEmployee.id}>{sellerEmployee.user?.email}</Link>
        };
        columnProps.push(column10);
        // const column1: FtDataTableColumnProps = new FtDataTableColumnProps("roles", i18n("角色"));
        // columnProps.push(column1);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("status", i18n("状态"));
        column3.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            if (columnValue === EStatus.NORMAL) {
                return i18n("启用");
            } else if (columnValue === EStatus.DISABLED) {
                return i18n("未激活");
            } else if (columnValue === EStatus.STOP) {
                return i18n("停用");
            }else {
                return columnValue;
            }
        };
        columnProps.push(column3);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetime", i18n("创建时间"));
        column2.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const sellerEmployee: FssSellerEmployee = rowData as FssSellerEmployee;
            if(sellerEmployee.createDatetime)
                return <span>{FssClientData.formatDatetime(sellerEmployee.createDatetime)}</span>;
            else
                return <span></span>;
        };
        columnProps.push(column2);

        let tableAdapter: JtTableAdapter;
        const cacheState: FssSellerEmployeeListPageState = FtState.pop("FssSellerEmployeeListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = {tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssSellerEmployeeListPage",open:false,email:""};
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.inviteCustomerMember = this.inviteCustomerMember.bind(this);
        this.invitedMembers = this.invitedMembers.bind(this);
        this.getInviteDialog = this.getInviteDialog.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("用户列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarNoSelect={this.getToolbarNoSelect()}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
            {this.getInviteDialog()}
        </div>;
    }
    onChangePageNo () {
        this.state.tableAdapter.changePageNo(0);
    }
    getFilterPanel(): ReactNode {
        return <FtRow>
            <TextField id="mobile" label={i18n("收件电话")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("mobile", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("mobile") as string} style={{width: 150}}/>

            <TextField id="email" label={i18n("Email")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("email", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("email") as string} style={{width: 150}}/>
            <FormControl style={{minWidth: 150}}>
                <InputLabel> {i18n("用户状态")} </InputLabel>
                <Select id="status" value={this.state.tableAdapter.getFilterColumnValue("status")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("status", event.target.value as string);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={""}>{i18n("全部商品")}</MenuItem>
                    <MenuItem value={EStatus.NORMAL}>{i18n("启用")}</MenuItem>
                    <MenuItem value={EStatus.DISABLED}>{i18n("禁用")}</MenuItem>
                </Select>
            </FormControl>
        </FtRow>;
    }
    invitedMembers(): void {
        this.setState({open:true});
        this.forceUpdate();
    }
    getToolbarNoSelect(): ReactNode {
        return <div>
            <Button onClick={this.invitedMembers}>
                <Add />{i18n("邀请")}
            </Button>
        </div>;
    }
    handleClose(): void {
        this.setState({open: false,email:""});
        this.forceUpdate();
    }
    //邀请成员
    inviteCustomerMember(): void {
        FtToast.showToastLoading(i18n("正在发送邮件"));
        FssSellerEmployeeService.inviteFssSellerEmployee(this.state.email, (data: FssSellerEmployee) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("邀请成员成功，请及时通知成员确认，确认后请及时为成员设置网店权限。"));
            this.handleClose();
        });
    }
    getInviteDialog(): ReactNode {
        let invitedMembersDialogWidth: string = "";
        if (window.screen.width > 1000) {
            invitedMembersDialogWidth= "450px"
        } else {
             invitedMembersDialogWidth= "100%" ;
        }
        return <Dialog open={this.state.open} onClose={this.handleClose} maxWidth={"lg"} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{i18n("邀请成员")}</DialogTitle>
            <DialogContent>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"1"} cellHorizontalSpace={8}>
                        <JtTextField id="keyword" label={i18n("请输入邮箱")} value={this.state.email} type={"email"}
                                     style={{ width:invitedMembersDialogWidth }}
                                     onChange={(e)=>{
                                         this.setState({email:e.target.value});
                                         this.forceUpdate();
                                     }} />
                    </FtRow>
                </FtGrid>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                    <Button  variant={"contained"} onClick={this.handleClose}>
                        {i18n("取消")}
                    </Button>
                    <Button color={"primary"} variant={"contained"} onClick={this.inviteCustomerMember}>
                        {i18n("邀请")}
                    </Button>
            </DialogActions>
        </Dialog>
    }
}
