import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtToast from "../ui/FtToast";
import {Button, Chip, FormLabel, Typography} from "@material-ui/core";
import {Add, ChevronLeft} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import FssMarketingEvent from "../model/FssMarketingEvent";
import {Link, RouteComponentProps} from "react-router-dom";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import Tooltip from "@material-ui/core/Tooltip";
import FtState from "../ui/FtState";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtSnackbar from "../ui/FtSnackbar";
import FormControl from "@material-ui/core/FormControl";
import FtMessageDialog from "../ui/FtMessageDialog";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtRow from "../ui/FtRow";
import FtGrid from "../ui/FtGrid";
import FssMarketingEventService from "../service/FssMarketingEventService";
import FtPagedList from "../model/FtPagedList";
import FssClientData from "../data/FssClientData";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import FtUtil from "../util/FtUtil";
import {ETwoStatus} from "../model/FtAbstractModel";
import FtToolbar from "../ui/FtToolbar";
import AppClientInfo from "../data/AppClientInfo";
import IconButton from "@material-ui/core/IconButton";

export interface FssMarketingEventListPageProp extends RouteComponentProps {
}

class FssMarketingEventListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
    selectedDataList: Array<FssMarketingEvent> = new Array<FssMarketingEvent>();
}

class JtTableAdapter extends FtDataTableAdapter<FssMarketingEvent> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssMarketingEventService.getPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssMarketingEvent>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssMarketingEventListPage extends FtComponent<FssMarketingEventListPageProp, FssMarketingEventListPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("coverImageUrl", i18n("封面图"));
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const model: FssMarketingEvent = rowData as FssMarketingEvent;
            return <img style={{width: 60, height: 60}} src={model.coverImageUrl}></img>
        };
        columnProps.push(column0);

        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("name", i18n("模板名称"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const model: FssMarketingEvent = rowData as FssMarketingEvent;
            return <Button color={"primary"} onClick={()=>{this.props.history.push("/marketingEventDetail/" + model.id)}}>{model.name}</Button>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("title", i18n("标题"));
        columnProps.push(column2);

        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("status", i18n("状态"),
            "left",false,false,true);
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("type", i18n("类型"));
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("startDatetime",
            i18n("有效期"));
        column5.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const model: FssMarketingEvent = rowData as FssMarketingEvent;
            return <span>{model.startDatetime?FssClientData.formatDatetime(model.startDatetime):""}
             - {model.endDatetime?FssClientData.formatDatetime(model.endDatetime):""}</span>;
        };
        columnProps.push(column5);

        let tableAdapter: JtTableAdapter;
        const cacheState: FssMarketingEventListPageState = FtState.pop("FssMarketingEventListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            stateKey: "FssMarketingEventListPage",
            selectedDataList: new Array<FssMarketingEvent>()
        };
        this.renderRowForMobile=this.renderRowForMobile.bind(this);
        this.onCancel=this.onCancel.bind(this);
        this.onClickDelete=this.onClickDelete.bind(this);
        this.onCopyMarketingEvent=this.onCopyMarketingEvent.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    onCancel() {
        this.props.history.goBack();
    }

    render() {
        let backElement = <FtToolbar leftNode={<Button onClick={this.onCancel}><ChevronLeft/>{i18n("返回")}</Button>}/>;
        if (AppClientInfo.clientInfo.viewMode == "mobile") {
            backElement = <IconButton style={{position: "absolute", left: 0, top: 0, padding: 8}} onClick={this.onCancel}>
                <ChevronLeft style={{color: "black"}}/>
            </IconButton>;
        }
        return <div style={{position: "relative"}}>
            {backElement}
            <FtDataTable title={i18n("营销活动列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarWithSelect={this.getToolbarWithSelect()}
                         toolbarNoSelect={this.getToolbarNoSelect()}
                         filterPanel={this.getFilterPanel()}
                         renderRowForMobile={this.renderRowForMobile}>
            </FtDataTable>
        </div>;
    }
    renderRowForMobile(rowData: any, rowIndex: number) {
        const marketingEvent:FssMarketingEvent=rowData as FssMarketingEvent;
        return <FtRow cellWidthS={"3,7"} cellVerticalAlign={"top"}  style={{overflowWrap:"anywhere"}}>
            <Link to={"/marketingEventDetail/" + marketingEvent.id}>
                <img style={{width: 60, height: 60}} src={marketingEvent.coverImageUrl?marketingEvent.coverImageUrl:""} />
            </Link>
            <FtRow cellWidthS={"1"} cellVerticalAlign={"top"}>
                <Link to={"/marketingEventDetail/" + marketingEvent.id}>{marketingEvent.title}</Link>
                <FtRow>
                    <Typography variant={"caption"}>{i18n(marketingEvent.status)}</Typography>
                    <Chip size={"small"} label={i18n(marketingEvent.type)} variant={"outlined"} />
                </FtRow>
            </FtRow>
        </FtRow>
    }
    getFilterPanel(): ReactNode {
        return <FtGrid>
            <FtRow cellWidthS={"1,1"} cellWidthM={"1,1,1,1"}>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword}/>
            <TextField label="页面URI" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("eventPageUri", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("eventPageUri")} />
                <FormControl style={{minWidth: 400}}>
                    <FormLabel> {i18n("状态")} </FormLabel>
                    <FtCheckboxGroup row={true}
                                     values={[ETwoStatus.NORMAL, ETwoStatus.DISABLED]}
                                     labels={[i18n("启用"), i18n("禁用")]}
                                     checkedValues={this.state.tableAdapter.filterColumns.get("status")}
                                     onCheck={(checkedValue) => {
                                         this.state.tableAdapter.filterColumns.set("status", checkedValue);
                                         this.forceUpdate();
                                     }} />
                </FormControl>
        </FtRow>
        </FtGrid>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/marketingEventDetail"}>
                <Button>
                    <Add/>{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }

    getToolbarWithSelect(): ReactNode {
        return <div>
            <Tooltip title={i18n("永久彻底删除活动")}>
                <Button onClick={this.onClickDelete}>
                    {i18n("删除")}
                </Button>
            </Tooltip>
            <Button onClick={this.onCopyMarketingEvent}>
                {i18n("批量复制活动")}
            </Button>
        </div>;
    }

    onCopyMarketingEvent(): void {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定复制 "+this.state.tableAdapter.selectedRow.length+" 条活动吗？"), () => {
                FtToast.showToastLoading(i18n("正在复制"));
                const marketingEventIds: Array<string> =[];
                this.state.tableAdapter.selectedRow.forEach((item: FssMarketingEvent) => {
                    marketingEventIds.push(item.id);
                });
                FssMarketingEventService.copyMarketingEvent(marketingEventIds, (data: Array<FssMarketingEvent>)=>{
                    FtToast.closeToast();
                    if (data != null && data.length > 0) {
                        FtSnackbar.showText(i18n("成功复制 " + data.length + " 条记录"));
                        this.state.tableAdapter.changePageNo(0);
                    }
                });
            });
        }
    }

    private onClickDelete(): void {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("此操作不可撤销，确定要永久彻底删除选中的记录吗？"),()=>{
                const idList: Array<string> =[];
                this.state.tableAdapter.selectedRow.forEach((item: FssMarketingEvent) => {
                    if(item.id)
                        idList.push(item.id);
                });
                FtToast.showToastLoading();
                FssMarketingEventService.deleteByIdList(idList, (message: string) => {
                    FtToast.closeToast();
                    if (message) {
                        FtSnackbar.showText(message);
                    }
                    this.state.tableAdapter.loadData();
                });
            });
        }
    }
}
