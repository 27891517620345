import FssProductExchangeRule from "../model/FssProductExchangeRule";
import FssHttpService from "./FssHttpService";
import FssProduct from "../model/FssProduct";
import FtPagedList from "../model/FtPagedList";


export default class FssProductExchangeRuleService {


    static getExchangeRuleList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                           pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssProductExchangeRule>) => void,
                           failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/exchangeRule/getExchangeRuleList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }

    static getFssProductExchangeRuleByRuleId(id: string, successFunction: (data: FssProductExchangeRule) => void,
                           failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/exchangeRule/getFssProductExchangeRuleByRuleId", { id: id }, successFunction, FssHttpService.handleError);
    }

    static saveFssProductExchangeRule(FssProductExchangeRule: FssProductExchangeRule, successFunction: (data: FssProductExchangeRule) => void,
                        failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/exchangeRule/saveFssProductExchangeRule", FssProductExchangeRule, successFunction, FssHttpService.handleError);
    }
    static getProductByExchange(keyword: string, maxCount: number, successFunction: (productList: Array<FssProduct>) => void): void {
        FssHttpService.get("/api/exchangeRule/getProductByExchange", {keyword: keyword, maxCount: maxCount }, successFunction, FssHttpService.handleError);
    }

}
