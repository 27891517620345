import {i18n} from "../util/I18n";

export default class FssCategory {
    id: string = "";
    sellerId: string = "";
    storeId: string = "";
    parentCategoryId: string = "";
    categoryName: string = "";
    title: string = "";
    shortDescription: string = "";
    description: string = "";
    sortIndex: number = 0;
    coverImageUrl: string = "";
    categoryStatus: string = "";
    createDatetimeText: string = "";
    subCategoryList: Array<FssCategory> = [];

    constructor(id?: string, categoryName?: string) {
        if (categoryName)
            this.categoryName = categoryName;
        if (id)
            this.id = id;
    }

    static validate(category: FssCategory): string {
        if (category.categoryName == null || category.categoryName.trim().length == 0)
            return i18n("分类名称不能为空");
        return "";
    }
}
