import * as React from "react";
import {ZoomInOutlined} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import {ChangeEvent, CSSProperties, HTMLAttributes} from "react";
import FtDivFlex from "../ui/FtDivFlex";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import {grey, red} from "@material-ui/core/colors";
import {Checkbox} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import FtMediaViewerDialog from "../ui/FtMediaViewerDialog";
import FtUtil from "../util/FtUtil";

interface JtUserMediaCompProp extends HTMLAttributes<HTMLDivElement> {
    label?: string;
    mediaUrl?: string;
    mediaType?: "image" | "video";
    onClickRemove?: (id?: string) => void;
    onClickImage?: (mediaUrl?: string, id?: string) => void;
    onClickCheckbox?: (checked: boolean, mediaUrl: string, id?: string) => void;
    selected?: boolean;
    className?: string;
}

export class JtUserMediaCompState {
}


export default class FssUserMediaComp extends React.Component<JtUserMediaCompProp, JtUserMediaCompState> {


    constructor(props: any) {
        super(props);
        this.state = {};
        this.onClickImage = this.onClickImage.bind(this);
        this.onClickRemove = this.onClickRemove.bind(this);
        this.onClickCheckbox = this.onClickCheckbox.bind(this);
        this.onClickZoomOut = this.onClickZoomOut.bind(this);
    }

    componentDidMount(): void {

    }

    onClickImage(event: any) {
        if (this.props.onClickImage) {
            this.props.onClickImage(this.props.mediaUrl, this.props.id);
        }
    }

    onClickZoomOut(event: any) {
        event.stopPropagation();
        if (this.props.mediaUrl && this.props.mediaUrl.length > 0) {
            FtMediaViewerDialog.FtMediaViewerDialog.show(this.props.mediaUrl, this.props.mediaType, this.props.label);
        }
    }

    onClickRemove(event: any) {
        event.stopPropagation();
        if (this.props.onClickRemove)
            this.props.onClickRemove(this.props.id);
    }

    onClickCheckbox(event: ChangeEvent<HTMLInputElement>) {
        event.stopPropagation();
        if (this.props.onClickCheckbox && this.props.mediaUrl != null)
            this.props.onClickCheckbox(event.target.checked, this.props.mediaUrl, this.props.id);
    }

    render() {
        let styles: CSSProperties = {};
        styles.position = "relative";
        styles.backgroundImage = "url('" + this.props.mediaUrl + "')";
        styles.backgroundSize = "contain";
        styles.backgroundRepeat = "no-repeat";
        styles.backgroundColor = "#eeeeee";
        styles.minWidth = 100;
        styles.minHeight = 100;
        if (this.props.style) {
            styles = FtUtil.mergeObject(styles, this.props.style);
        }
        return <div style={styles} onClick={this.onClickImage} className={this.props.className?this.props.className:""}>
            {this.props.selected &&
            <IconButton style={{backgroundColor: red[500], color: "#ffffff"}}><CheckIcon/></IconButton>}
            <div style={{
                position: "absolute",
                left: 0,
                top: 0,
            }}>
                {this.props.mediaUrl && this.props.mediaUrl.length > 0 &&
                <IconButton onClick={this.onClickZoomOut} style={{
                    backgroundColor: grey[200], padding: 0,
                }}><ZoomInOutlined color={"primary"}/></IconButton>}
                {this.props.onClickRemove && this.props.mediaUrl && this.props.mediaUrl.length > 0 &&
                <IconButton onClick={this.onClickRemove} style={{
                    backgroundColor: grey[200], padding: 0
                }}><RemoveIcon color={"primary"}/></IconButton>}
            </div>
            {this.props.onClickCheckbox && this.props.mediaUrl != null && this.props.mediaUrl.length > 0 &&
            <Checkbox defaultChecked={this.props.selected} onChange={this.onClickCheckbox} color={"primary"} style={{
                position: "absolute",
                left: 0,
                top: 0,
                backgroundColor: grey[200]
            }}/>}
            <FtDivFlex justifyContent={"center"} style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.3)",
                color: "white"
            }}>
                {this.props.label}
            </FtDivFlex>
        </div>;
    }

}
