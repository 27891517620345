import FssHttpService from "./FssHttpService";
import FssNotificationSetting from "../model/FssNotificationSetting";
import FtPagedList from "../model/FtPagedList";

export default class FssNotificationSettingService {

    static saveNotificationSetting(notificationSettingList: Array<FssNotificationSetting>,
                                   successFunction: (notificationSettingList: Array<FssNotificationSetting>) => void,
                                   failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/notificationSetting/saveNotificationSetting", notificationSettingList, successFunction,
            failFunction ? failFunction : FssHttpService.handleError);
    }

    static getNotificationSettingList(filterColumns: Map<string, any>,
                                      successFunction: (result: FtPagedList<FssNotificationSetting>) => void,
                                      failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/notificationSetting/getNotificationSettingList", "", filterColumns,
            null, null, 0, 99999, successFunction,
            failFunction ? failFunction : FssHttpService.handleError);
    }
}
