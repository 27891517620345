import * as React from "react";
import {ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button, IconButton, Tooltip} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {Link} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import FtSnackbar from "../ui/FtSnackbar";
import FtMessageDialog from "../ui/FtMessageDialog";
import {i18n} from "../util/I18n";
import FtRow from "../ui/FtRow";
import FssShippingScheduleService from "../service/FssShippingScheduleService";
import FssShippingSchedule, {EPeriodTypes} from "../model/FssShippingSchedule";

interface JtShippingFeeTempListPageProp {
    deliveryProviderId: string;

}
class JtShippingScheduleListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();

}

class JtTableAdapter extends FtDataTableAdapter<FssShippingSchedule> {
    defaultFilter: Map<string, any> = new Map<string, any>();
    loadData(): void {
        FtToast.showToastLoading("loading");
        let deliveryProviderId:string = this.defaultFilter.get("deliveryProviderId");
        FssShippingScheduleService.getPagedList(this.keyword,deliveryProviderId, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssShippingSchedule>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
    resetSearch() {
        super.resetSearch();
        if (this.defaultFilter) {
            this.defaultFilter.forEach((value, key) => {
                this.filterColumns.set(key, value);
            });
        }
    }
}
export default class JtShippingScheduleListPage extends FtComponent<JtShippingFeeTempListPageProp, JtShippingScheduleListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("regionName", i18n("地区"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const jtShippingScheduleSetting: FssShippingSchedule = rowData as FssShippingSchedule;
            return <Link to={"/shippingScheduleDetail/" + jtShippingScheduleSetting.id+"/"+jtShippingScheduleSetting.deliveryProviderId}>{jtShippingScheduleSetting.regionName}</Link>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("peroidType", i18n("配送周期"));
        column2.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const FssShippingSchedule: FssShippingSchedule = rowData as FssShippingSchedule;
            switch (FssShippingSchedule.periodType) {
                case EPeriodTypes.DAILY:
                    return i18n("每日配送");
                case EPeriodTypes.WEEKLY:
                    return i18n("每周配送");
                case EPeriodTypes.MONTHLY:
                    return i18n("每月配送");
            }
        };
        columnProps.push(column2);
       /* const column7: FtDataTableColumnProps = new FtDataTableColumnProps("periodValues", i18n("配送日"));
        columnProps.push(column7);*/
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("availableDaysCount", i18n("显示几个可选时间"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("startTime", i18n("开始时间"));
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("endTime", i18n("结束时间"));
        columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("stopOrderDayTime", i18n("截单时间"));
        columnProps.push(column6);

        let tableAdapter: JtTableAdapter;
        const cacheState: JtShippingScheduleListPageState = FtState.pop("JtShippingScheduleListPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.defaultFilter.set("deliveryProviderId",this.props.deliveryProviderId);
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            stateKey: "JtShippingScheduleListPageState",
        };
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this)
        this.loadData = this.loadData.bind(this)

    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
        this.loadData();
    }

    render() {
        return <FtRow cellWidthS={"1"}>
            <FtDataTable title={i18n("送货时间")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarWithSelect={this.getToolbarWithSelect()}
                         toolbarNoSelect={this.getToolbarNoSelect()}>
            </FtDataTable>
        </FtRow>;
    }

    loadData () {
    }

    private onClickDelete() {
        const rows = this.state.tableAdapter.selectedRow;
        if (rows != null && rows.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除选定的{0}条记录吗？",rows.length+""), () => {
                const ids: Array<string> = [];
                rows.forEach((value: FssShippingSchedule, index: number) => {
                    if(value.id){
                        ids.push(value.id);
                    }

                });
                FssShippingScheduleService.permanentDelete(ids, (data: FssShippingSchedule) => {
                    FtSnackbar.showText(i18n("删除成功！"));
                    this.state.tableAdapter.loadData();
                });
            });
        }
    }

    getToolbarNoSelect(): ReactNode {
        return <Link to={"/shippingScheduleDetail/0/"+this.props.deliveryProviderId}>
            <Button>
                <Add />{i18n("新增")}
            </Button>
        </Link>;
    }
    getToolbarWithSelect(): ReactNode {
        return <div>
            <Button onClick={this.onClickDelete}>
                {i18n("删除")}
            </Button>
        </div>;
    }
}
