import * as React from "react";
import FtPanel from "../../ui/FtPanel";
import FtRow from "../../ui/FtRow";
import FssCompHeaderEditor from "./FssCompHeaderEditor";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FssProductListSelected from "./FssProductListSelected";
import JtTextField from "../../ui/JtTextField";
import {i18n} from "../../util/I18n";
import FssComponent from "../../model/FssComponent";
import FssProductListCompData, {
    EProductListDataSource,
    EProductSortType
} from "../../page/component/data/FssProductListCompData";
import FssProductService from "../../service/FssProductService";
import FtGrid from "../../ui/FtGrid";
import {Checkbox, Chip, FormControlLabel, FormLabel, TextField, Typography} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import _ from "lodash";
import {EImageListLayout} from "../../page/component/data/FssImageListCompData";

interface FssProductListCompEditorProp {
    component: FssComponent;
    onChangeComponent?:(component:FssComponent)=>void;
    onChangeDraggableFlag?: (draggableFlag: boolean)=>void;
}

export class FssProductListCompEditorState {
    tabValue: string = "";
    defaultTagsList: Array<string>=[];
    tagsList: Array<string>=[];
}


export default class FssProductListCompEditor extends React.Component<FssProductListCompEditorProp, FssProductListCompEditorState> {

    constructor(props: any) {
        super(props);
        this.state = {tabValue:"selectedTab",defaultTagsList:[],tagsList:[]};
        this.onChangeComponent=this.onChangeComponent.bind(this);
        this.handleAddChip=this.handleAddChip.bind(this);
        this.handleDeleteChip=this.handleDeleteChip.bind(this);
    }

    componentDidMount(): void {
        FssProductService.getStoreTagsList((result: Array<string>) => {
            this.setState({defaultTagsList:result});
        });
    }

    render() {
        const component=this.props.component;
        const productListCompContent = component.data as FssProductListCompData;
        if(!productListCompContent.sortType||productListCompContent.sortType==''){
            productListCompContent.sortType = EProductSortType.SPECIFIED;
        }
        if(!productListCompContent.mobileLineNum)
            productListCompContent.mobileLineNum = 1;
        if(!productListCompContent.pcLineNum)
            productListCompContent.pcLineNum = 6;
        if(!productListCompContent.productListDataSource)
            productListCompContent.productListDataSource = EProductListDataSource.SPECIFIED;
        if(!productListCompContent.tagsList){
            productListCompContent.tagsList = [];
        }

        return <div key={component.id}>
            <FssCompHeaderEditor component={this.props.component} onChangeComponent={this.onChangeComponent}></FssCompHeaderEditor>
            <FtPanel panelHeader={<Typography variant={"h6"}>{i18n("通用参数")}</Typography>} style={{padding: 16}}>
                <FtRow cellWidthM={"1"} cellHorizontalAlign={"left"}>
                    <JtTextField id="title" label={i18n("标题")} value={productListCompContent.title}
                                 onChange={(event) => {
                                     productListCompContent.title = event.target.value;
                                     this.onChangeComponent();
                                 }} style={{width: 300}}/>
                    <FormControlLabel label="显示更多"
                                      control={<Checkbox checked={productListCompContent.showMore} color="primary"
                                                         onChange={(event, checked) => {
                                                             productListCompContent.showMore = checked;
                                                             this.onChangeComponent();
                                                         }}/>}/>
                    <JtTextField id="title" label={i18n("更多链接URL")} value={productListCompContent.moreLink}
                                 disabled={!productListCompContent.showMore}
                                 onChange={(event) => {
                                     productListCompContent.moreLink = event.target.value;
                                     this.onChangeComponent();
                                 }} style={{width: 500}}/>
                </FtRow>
            </FtPanel>
            <FtRow cellWidthM={"1,1"} cellHorizontalAlign={"left"}>
                <FtPanel panelHeader={i18n("手机版")}>
                    <FormControl style={{width:220, padding: 8}}>
                        <InputLabel>{i18n("每行显示数量")}</InputLabel>
                        <Select id="mobileLineNum" value={productListCompContent.mobileLineNum}
                            onChange={(event) => {
                                productListCompContent.mobileLineNum = event.target.value as number;
                                this.onChangeComponent();
                            }}>
                            <MenuItem value={1}>{i18n("每行【一件】商品")}</MenuItem>
                            <MenuItem value={2}>{i18n("每行【两件】商品")}</MenuItem>
                            <MenuItem value={3}>{i18n("每行【三件】商品")}</MenuItem>
                        </Select>
                    </FormControl>
                </FtPanel>
                <FtPanel panelHeader={i18n("电脑版")}>
                    <FormControl style={{width:220, padding: 8}}>
                        <InputLabel>{i18n("每行显示数量")}</InputLabel>
                        <Select id="pcLineNum" value={productListCompContent.pcLineNum}
                                onChange={(event) => {
                                    productListCompContent.pcLineNum = event.target.value as number;
                                    this.onChangeComponent();
                                }}>
                            <MenuItem value={6}>{i18n("每行【六件】商品")}</MenuItem>
                        </Select>
                    </FormControl>
                </FtPanel>
            </FtRow>
            <FtPanel marginVertical={8}>
                <FormControl style={{width:220, padding: 8}}>
                    <InputLabel>{i18n("数据来源类型")}</InputLabel>
                    <Select id="productListDataSource" value={productListCompContent.productListDataSource}
                            onChange={(event) => {
                                productListCompContent.productListDataSource = event.target.value as EProductListDataSource;
                                this.onChangeComponent();
                            }}>
                        <MenuItem value={EProductListDataSource.SPECIFIED}>{i18n("已选商品")}</MenuItem>
                        <MenuItem value={EProductListDataSource.SEARCH}>{i18n("搜索结果")}</MenuItem>
                        <MenuItem value={EProductListDataSource.ONLYLABEL}>{i18n("指定标签")}</MenuItem>
                    </Select>
                </FormControl>
            </FtPanel>
            <FtPanel marginVertical={8}>
                {productListCompContent.productListDataSource==EProductListDataSource.SPECIFIED&&
                <FssProductListSelected productListCompData={productListCompContent}
                                        onChangeComponent={this.onChangeComponent}
                                        onChangeDraggableFlag={this.props.onChangeDraggableFlag}
                                        key={component.id}/>}
                {productListCompContent.productListDataSource==EProductListDataSource.SEARCH&&
                <div style={{minHeight: 280, paddingLeft: 15}} key={component.id}>
                    <div style={{width: "100%"}}>
                        <JtTextField id="href" label={i18n("目标链接URL")} value={productListCompContent.href}
                           onChange={(event)=>{
                               productListCompContent.href = event.target.value;
                               this.onChangeComponent();
                           }} style={{width: "95%"}}>
                        </JtTextField>
                    </div>
                    <div style={{width: "100%"}}>
                        <JtTextField id="showProductNum" type={"integer"} label={i18n("显示商品个数")} value={productListCompContent.showProductNum}
                           onChange={(event)=>{
                                productListCompContent.showProductNum = +event.target.value;
                               this.onChangeComponent();
                        }} style={{width: "25%"}}>
                        </JtTextField>
                    </div>
                </div>}
                {productListCompContent.productListDataSource==EProductListDataSource.ONLYLABEL&&
                <div style={{minHeight: 280, paddingLeft: 15}} key={component.id}>
                    <FtGrid>
                        <FtRow cellWidthS={"1"}>
                            <ChipInput label={""}
                                       variant={"standard"}
                                       placeholder={i18n("请输入标签")}
                                       alwaysShowPlaceholder={true}
                                       value={productListCompContent.tagsList}
                                       onAdd={(chip) => this.handleAddChip(chip,productListCompContent)}
                                       onDelete={(chip, index) => this.handleDeleteChip(chip,productListCompContent)}
                                       style={{width: "100%"}}/>
                        </FtRow>
                    </FtGrid>
                    <FtGrid >
                        <FtRow cellHorizontalSpace={16} >
                            {this.state.defaultTagsList.map((data: string, index: number) => {
                                let targetIndex = -1;
                                if(productListCompContent.tagsList){
                                    targetIndex = _.findIndex(productListCompContent.tagsList, (tag: string) => {
                                        return data == tag;
                                    });
                                }
                                return <Chip color={targetIndex>-1 ? "secondary" : "default"}
                                             label={data}
                                             onClick={() => {
                                                 if(targetIndex>-1){
                                                     this.handleDeleteChip(data,productListCompContent);
                                                 }else {
                                                     this.handleAddChip(data,productListCompContent);
                                                 }

                                             }} />;
                            })}
                        </FtRow>
                    </FtGrid>
                </div>}
            </FtPanel>
        </div>

    }
    handleAddChip(chip: string,productListCompContent:FssProductListCompData) {
        let index = productListCompContent.tagsList.indexOf(chip);
        if (index == -1) {
            productListCompContent.tagsList.push(chip);
            this.forceUpdate();
            this.onChangeComponent();
        }
    }

    handleDeleteChip(chip: string,productListCompContent:FssProductListCompData) {
        let index = productListCompContent.tagsList.indexOf(chip);
        if (index >-1) {
            productListCompContent.tagsList.splice(index, 1)
            this.forceUpdate();
            this.onChangeComponent();
        }

    }
    onChangeComponent(){
        if(this.props.onChangeComponent)
            this.props.onChangeComponent(this.props.component);
    }
}
