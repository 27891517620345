import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {Link} from "react-router-dom";
import FtState from "../ui/FtState";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {Button, Chip, TextField} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import FtRow from "../ui/FtRow";
import FssUserCoupon from "../model/FssUserCoupon";
import {i18n} from "../util/I18n";
import FssCouponComboBox from "./FssCouponComboBox";
import FssCoupon from "../model/FssCoupon";
import FssUserCouponService from "../service/FssUserCouponService";

export interface JtUserCouponListPagePorp {

}

class JtUserCouponListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
    coupon?: FssCoupon;
}

class JtTableAdapter extends FtDataTableAdapter<FssUserCoupon> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssUserCouponService.getJtUserCouponList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssUserCoupon>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssUserCouponListPage extends FtComponent<JtUserCouponListPagePorp, JtUserCouponListPageState>{

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();

        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("receiveUserName", i18n("用户名字"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("coupon.couponName", i18n("优惠券ID"));
        columnProps.push(column3);
        // const column4: FtDataTableColumnProps = new FtDataTableColumnProps("orderId", i18n("订单ID"));
        // column4.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
        //     const userCoupon: FssUserCoupon = rowData as FssUserCoupon;
        //     return <Link to={"/orderDetail/" + userCoupon.orderId}>{userCoupon.orderId}</Link>;
        // };
        // columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("创建时间"));
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("usedDatetimeText", i18n("使用时间"));
        columnProps.push(column7);
        let tableAdapter: JtTableAdapter;
        const cacheState: JtUserCouponListPageState = FtState.pop("FssUserCouponListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = { tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssUserCouponListPage"};
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onChangeCoupon = this.onChangeCoupon.bind(this);
    }
    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    onChangeCoupon(item: FssCoupon| undefined) {
        this.setState({coupon: item}, ()=>{
            let couponName;
            if (item) {
                couponName = item.couponName;
            }
            this.state.tableAdapter.filterColumns.set("couponName", couponName);
        });
    }

    render() {
        return <div>
            <FtDataTable title={i18n("用户领取优惠券列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                toolbarNoSelect={this.getToolbarNoSelect()}
                filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }
    getFilterPanel(): ReactNode {
        let coupon: FssCoupon| undefined = this.state.coupon;
        if (this.state.tableAdapter.getFilterColumnValue("couponName") == '') {
            coupon = undefined;
        }
        return <FtRow>
            <FtRow cellWidthS={"1,1"} cellWidthM={"1,1"} cellWidthL={"1,1"}>
                {/*<TextField label={i18n("用户ID")} onChange={(event: ChangeEvent<HTMLInputElement>) => {*/}
                {/*    this.state.tableAdapter.filterColumns.set("receiveUserId", event.target.value);*/}
                {/*    this.forceUpdate();*/}
                {/*}} value={this.state.tableAdapter.getFilterColumnValue("receiveUserId")} style={{ width: 200 }} />*/}
                <TextField label={i18n("用户名字")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.state.tableAdapter.filterColumns.set("receiveUserName", event.target.value);
                    this.forceUpdate();
                }} value={this.state.tableAdapter.getFilterColumnValue("receiveUserName")} style={{ width: 200 }} />
                {/*<TextField label={i18n("优惠码ID")} onChange={(event: ChangeEvent<HTMLInputElement>) => {*/}
                {/*    this.state.tableAdapter.filterColumns.set("couponId", event.target.value);*/}
                {/*    this.forceUpdate();*/}
                {/*}} value={this.state.tableAdapter.getFilterColumnValue("couponId")} style={{ width: 200 }} />*/}
                {/*<TextField label={i18n("订单ID")} onChange={(event: ChangeEvent<HTMLInputElement>) => {*/}
                {/*    this.state.tableAdapter.filterColumns.set("orderId", event.target.value);*/}
                {/*    this.forceUpdate();*/}
                {/*}} value={this.state.tableAdapter.getFilterColumnValue("orderId")} style={{ width: 200 }} />*/}
                <FssCouponComboBox label={i18n("优惠券名字")}
                                   onChangeCoupon={this.onChangeCoupon}
                                   selectedCoupon={coupon} />
            </FtRow>
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/userCoupon/FssUserCouponDetailPage/0"}>
                <Button>
                        <Add />{i18n("发放优惠券")}
                </Button>
            </Link>
        </div>;
    }
}
