import * as React from "react";
import {HTMLAttributes} from "react";

export interface JtTabPanelProp extends HTMLAttributes<HTMLDivElement>{
    label:string;
    value:string;
}

export default class FtTabPanel extends React.Component<JtTabPanelProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        return <div>
            {this.props.children}
        </div>;
    }
}
