

export default class FssSellerStatement {
    payDatetime:string="";
    money:number=0;
    typeText:string="";
    paymentAccountName:string="";
    gatewayOrderId:string="";
    orderId:number=0;
    orderCode:number=0;
    orderDatetimeText:string="";
    orderStatusText:string="";
    paymentType:string="";
    paymentStatus:string="";
    totalPayment:number=0;
    userHandleFee:number=0;
    tips: number = 0;
    startDate?:string;// 开始时间
    endDate?:string;// 结束时间
    storeId:string="";
    sellerId:string="";
    type: EType = EType.ALL;
}

export enum EType {
    PAY_ORDER = "PAY_ORDER",
    REFUND_ORDER = "REFUND_ORDER",
    PAY_BALANCE_RECHARGE = "PAY_BALANCE_RECHARGE",
    REFUND_BALANCE_RECHARGE = "REFUND_BALANCE_RECHARGE",
    ALL = "ALL"
}
