import FtPagedList from "../model/FtPagedList";
import FssPage from "../model/FssPage";
import FssHttpService from "./FssHttpService";

export default class FssPageService {

    static savePage(page: FssPage, successFunction: (page: FssPage) => void) {
        FssHttpService.postJson("/api/page/savePage", page, successFunction, FssHttpService.handleError);
    }

    static getPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                        pageNo: number, pageSize: number, successFunction: (orderList: FtPagedList<FssPage>) => void,
                        failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/page/getPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static resetPageTemplate(templateName: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/page/resetPageTemplate", {templateName:templateName}, successFunction, FssHttpService.handleError);
    }

    static deletePage(pageIds: Array<string>, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/page/deletePageByIds", {pageIds:pageIds}, successFunction, FssHttpService.handleError);
    }

    static getPageById(pageId: string, successFunction: (result: FssPage) => void) {
        FssHttpService.postFormData("/api/page/getPageById", {pageId: pageId}, successFunction);
    }
    static getPageByIdAdmin(pageId: string, successFunction: (result: FssPage) => void) {
        FssHttpService.postFormData("/api/page/getPageByIdAdmin", {pageId: pageId}, successFunction);
    }

    static getSellerPageByUri(sellerId:string,pageUri:string, successFunction: (data: FssPage) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/page/getSellerPageByUri", {sellerId:sellerId,pageUri: pageUri}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }
}
