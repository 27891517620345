import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import AppClientInfo from "./data/AppClientInfo";
import FtUtil from "./util/FtUtil";
import FssAppWebsiteService from "./service//FssAppWebsiteService";
import FssClientData from "./data/FssClientData";
import I18n from "./util/I18n";


//1. 初始化设备
declare function initDevice(deviceReadyFunction: Function, resumeFunction: Function): void;
declare function getAppClientInfo(): any;

initDevice(initApp, initApp);

function initApp() {
    //从入口html得到客户端的信息
    const clientInfo = getAppClientInfo();
    AppClientInfo.clientInfo = FtUtil.mergeObject(AppClientInfo.clientInfo, clientInfo);
    FssClientData.loadFromLocalStorage();
    //先创建会话
    FssAppWebsiteService.checkWebSession(data => {
        if(data) {
            FssClientData.updateWebSession(data);
            //获取网站基本信息
            FssAppWebsiteService.getAppWebsite(data => {
                if(data) {
                    FssClientData.getData().appWebsite = data;
                    //如果本地还没有设置过语言，先设置为网店默认语言
                    if(data.language!=null&&data.language.length>0){
                        FssClientData.updateLanguage(data.language);
                        FssAppWebsiteService.getLanguageText((textMap: Array<any>) => {
                            I18n.setTextMap(textMap);
                            renderApp();
                        });
                    }else
                        renderApp();
                }else
                    alert("Can not get website data.");
            });
        }else
            alert("Can not get website data.");
    });
}

function renderApp() {
    ReactDOM.render(
        <React.StrictMode>
            <App/>
        </React.StrictMode>,
        document.getElementById('root')
    );
}

