import * as React from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import {i18n} from "../util/I18n";
import Button from "@material-ui/core/Button";
import FssProduct from "../model/FssProduct";
import FssProductDetailComp from "./FssProductDetailComp";
import {RouteComponentProps} from "react-router-dom";
import FtToast from "../ui/FtToast";
import FssProductService from "../service/FssProductService";
import FtSnackbar from "../ui/FtSnackbar";

interface FssProductLiteDialogProp extends RouteComponentProps{

}

export class FssProductLiteDialogState {
    open: boolean = false;
    product: FssProduct = new FssProduct();
    onSuccessFunction?: (product: FssProduct) => void;
}


export default class FssProductLiteDialog extends React.Component<FssProductLiteDialogProp, FssProductLiteDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            product: new FssProduct(),
        };
        this.onChange = this.onChange.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.show = this.show.bind(this);
        this.onSaveProduct = this.onSaveProduct.bind(this);
    }

    onChange(product: FssProduct) {
        this.setState({product: product});
    }

    show(product: FssProduct, onSuccessFunction?: (product: FssProduct) => void) {
        this.setState({open: true, product: product, onSuccessFunction: onSuccessFunction});
    }

    onCancelClick(): void {
        this.setState({open: false});
    }

    render() {
        return <Dialog open={this.state.open} PaperProps={{style: {height: "80vh", maxWidth: "unset"}}}>
            <DialogTitle style={{textAlign: "center"}}>
                {this.state.product.id != null && this.state.product.id.trim().length > 0 && i18n("商品详情")}
                {this.state.product.id == null || this.state.product.id.trim().length == 0 && i18n("添加商品")}
            </DialogTitle>
            <DialogContent dividers style={{padding: 0}}>
                <FssProductDetailComp {...this.props} fssProduct={this.state.product} onChange={this.onChange} hideTagsPanel={true}/>
            </DialogContent>
            <DialogActions style={{border: "none"}}>
                <FtRow justifyContent={"space-between"} cellHorizontalAlign={"center"} style={{width: "100%"}}>
                    <Button variant="outlined" onClick={this.onCancelClick}>{i18n("取消")}</Button>
                    <Button variant="outlined" color="primary" onClick={this.onSaveProduct}>{i18n("保存")}</Button>
                </FtRow>
            </DialogActions>
        </Dialog>
    }

    onSaveProduct() {
        FtToast.showToastLoading();
        FssProductService.saveProduct(this.state.product, (product: FssProduct) => {
            FtToast.closeToast();
            if (this.state.onSuccessFunction) {
                this.state.onSuccessFunction(product);
            }
            this.onCancelClick();
        });
    }
}
