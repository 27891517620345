import * as React from "react";
import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider} from '@material-ui/core';
import FtSnackbar from "../ui/FtSnackbar";
import FtUtil from "../util/FtUtil";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FssUser from "../model/FssUser";

export interface FssUpdateEmailDialogProp {
    open: boolean;
    handleClose: (open: boolean) => void;
    email: string;
    sendEmail: (email: string) => void;
}

export class FssUpdateEmailDialogState {
    fasUser: FssUser = new FssUser();
    email: string = "";
}


export default class FssUpdateEmailDialog extends React.Component<FssUpdateEmailDialogProp, FssUpdateEmailDialogState> {

    constructor(props: FssUpdateEmailDialogProp) {
        super(props);
        this.state = {
            fasUser: new FssUser(),
            email: "",
        };
        this.updateEmail = this.updateEmail.bind(this);
    }

    /**
     * 保存修改的值
     */
    updateEmail(): void {
        if (!this.state.email) {
            FtSnackbar.showText("请输入邮箱", 3000);
            return;
        } else if (!FtUtil.isEmail(this.state.email)){
            FtSnackbar.showText("请输入正确的邮箱", 3000);
            return;
        } else if (this.state.email === this.props.email) {
            FtSnackbar.showText("修改失败：新邮箱与旧邮箱账号一致", 3000);
            return;
        }
        this.props.handleClose(false);
        this.props.sendEmail(this.state.email);
    }
    render() {
        return <Dialog open={this.props.open} maxWidth="xs"
                       PaperProps={{style: {width: 400}}}>
            <DialogTitle>输入邮箱</DialogTitle>
            <Divider />
            <DialogContent>
                <FtRow cellWidthS={"1"} maxRowWidth={"s"} cellHorizontalSpace={8}>
                    <JtTextField id="email" label="输入邮箱" value={this.state.email} type={"email"}
                                 onChange={(e)=>{this.setState({email: e.target.value})}}
                                 required={true} style={{ width: "100%" }} variant={"outlined"}/>
                </FtRow>
            </DialogContent>
            <DialogActions>
                <FtRow justifyContent={"space-between"} style={{width: "100%", padding: "0px 16px"}}>
                    <Button variant={"contained"} onClick={()=>{this.props.handleClose(false)}}>取消</Button>
                    <Button color={"primary"} variant={"contained"} onClick={this.updateEmail}>确认</Button>
                </FtRow>
            </DialogActions>
        </Dialog>
    }
}
