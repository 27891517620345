import FssHttpService from "../../service/FssHttpService";
import DealVo from "./DealVo";

export default class GrubmarketService{

    static viewData(token:string,successFunction: (data:Array<DealVo>) => void) {
        FssHttpService.get("/api/data/viewData", {token:token}, successFunction, FssHttpService.handleError);
    }
    static importData(token:string,dealIds:Array<string>,successFunction: (data:string) => void) {
        FssHttpService.get("/api/data/importData", {token:token,dealIds:dealIds}, successFunction, FssHttpService.handleError);
    }
}
