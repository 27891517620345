import FssSeller from "./FssSeller";
import FssWebsiteRegion from "./FssWebsiteRegion";
import {ETwoStatus} from "./FtAbstractModel";

export default class FssStore {
    id: string = "";
    countryInfoIds: string = "";
    storeName: string = "";
    defaultSellerId: string = "";
    createDatetime: string = "";
    isPlatform: boolean = false;
    status?: ETwoStatus;
    logoUrl?: string;
    description?: string;
    defaultSeller?: FssSeller;
    websiteRegion?: FssWebsiteRegion;
}
