import FssHttpService from "./FssHttpService";
import FssTimeZone from "../model/FssTimeZone";
import FssSeller from "../model/FssSeller";
import FssSellerEmployee from "../model/FssSellerEmployee";
import FtPagedList from "../model/FtPagedList";

export default class FssSellerService extends FssHttpService {
    static registerSeller(seller: FssSeller, successFunction: (data: FssSellerEmployee) => void): void {
        this.postJson("/api/seller/registerSeller", seller, successFunction, FssHttpService.handleError);
    }

    static getAllTimeZone(successFunction: (productList: Array<FssTimeZone>) => void): void {
        FssHttpService.get("/api/setting/getAllTimeZone", {}, successFunction, FssHttpService.handleError);
    }

    static saveMySeller(seller: FssSeller, successFunction: (data: FssSeller) => void): void {
        this.postJson("/api/seller/saveMySeller", seller, successFunction, FssHttpService.handleError);
    }

    static getSellerById(sellerId: string, successFunction: (seller: FssSeller) => void) {
        FssHttpService.get("/api/seller/getSellerById", {sellerId: sellerId}, successFunction, FssHttpService.handleError);
    }

    static getSellerByIdAdmin(successFunction: (seller: FssSeller) => void) {
        FssHttpService.get("/api/seller/getSellerByIdAdmin", {}, successFunction, FssHttpService.handleError);
    }

    static getMySellerEmployeeList(successFunction: (data: Array<FssSellerEmployee>) => void): void {
        FssHttpService.get("/api/seller/getMySellerEmployeeList", {}, successFunction);
    }

    static getSellerPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                              pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssSeller>) => void) {
        FssHttpService.search("/api/seller/getPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction);
    }

    static getMyPublisherSeller(successFunction: (seller: FssSeller) => void) {
        FssHttpService.get("/api/seller/getMyPublisherSeller", null, successFunction, FssHttpService.handleError);
    }

    static getStoreSellerList(successFunction: (data: Array<FssSeller>) => void) {
        FssHttpService.get("/api/seller/getStoreSellerList", null, successFunction, FssHttpService.handleError);
    }

    static isDefaultSeller(successFunction: (data: boolean) => void) {
        FssHttpService.get("/api/seller/isDefaultSeller", null, successFunction, FssHttpService.handleError);
    }

}
