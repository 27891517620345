import {Theme} from "@material-ui/core";
import { createMuiTheme } from '@material-ui/core/styles'
import {grey} from "@material-ui/core/colors";

export let theme: Theme;
theme = createMuiTheme({
    palette: {
        primary: {
            light: "#75a7ff",
            main: "#1c73e8",
            dark: "#2979ff",
            contrastText: "#fff"
        },
        secondary: {
            light: "#ff5f52",
            main: "#c62828",
            dark: "#8e0000",
            contrastText: "#fff"
        }
    },
    mixins: {
        toolbar: {
            minHeight: 48,
            "@media (min-width:0px) and (orientation: landscape)": {minHeight: 48},
            "@media (min-width:600px)": {minHeight: 48}
        }
    },
    overrides: {
        MuiTableCell: {
            root: {
                paddingLeft: 10,
                paddingRight: 10
            },
            head: {
                backgroundColor: "#e6e6e6"
            }
        },
        MuiDialogActions: {
            root: {
                justifyContent: "space-between",
                borderTopColor: grey[500],
                borderTopStyle: "solid",
                borderTopWidth: 1
            }
        }
    }
});
