import FssProduct from "./FssProduct";


export enum EStatus {
    NORMAL="NORMAL", STOP="STOP"
}

export default class FssProductExchangeRule {
    id: string = "";
    startDatetime?: string = "";
    startDatetimeText?: string = "";
    startDatetimeString?: string = "";
    endDatetime?: string = "";
    endDatetimeText?: string = "";
    endDatetimeString?: string = "";
    createDatetime?: string = "";
    createDatetimeText?: string = "";
    productList: Array<FssProduct> = [];
    balanceAmountLimit:number=0;
    status?: string = EStatus.NORMAL;
    name:string = "";

    product: FssProduct = new FssProduct();
}
