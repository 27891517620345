import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {Checkbox, Chip, FormControlLabel, FormLabel, TextField, Typography} from "@material-ui/core";
import {CSVLink} from "react-csv";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {i18n} from "../util/I18n";
import ChipInput from "material-ui-chip-input";
import _ from "lodash";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import lodash from "lodash";
import {ProductSaleInfo} from "../model/ProductSaleInfo";
import FssProductSupplier from "../model/FssProductSupplier";
import FssProductSupplierService from "../service/FssProductSupplierService";
import FssCategory from "../model/FssCategory";
import FssProductService from "../service/FssProductService";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import {EOrderStatus} from "../model/FssOrder";
import FssProductReportService from "../service/FssProductReportService";
import FtDataTable, { FtDataTableAdapter, FtDataTableColumnProps } from "../ui/FtDataTable";
import FtPagedList from "../model/FtPagedList";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtToast from "../ui/FtToast";
import FtState from "../ui/FtState";
import FtPage from "../ui/FtPage";
import FtRow from "../ui/FtRow";
import FssCategoryService from "../service//FssCategoryService";
import FtGrid from "../ui/FtGrid";

class JtProductSaleListState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
    csvData: Array<ProductSaleInfo> = new Array<ProductSaleInfo>();
    supplierList: Array<FssProductSupplier> = [];
    categoryList: Array<FssCategory> =[];
    openCategoryDialog: boolean = false;
    openTagsDialog: boolean = false;
    defaultTagsList: Array<string>=[];

}

class JtTableAdapter extends FtDataTableAdapter<ProductSaleInfo> {
    loadData(): void {
        if (this.getFilterColumnValue("dateType") == "") {
            this.filterColumns.set("dateType", "createOrderTime");
        }
        if (this.getFilterColumnValue("startDate") == "") {
            this.filterColumns.set("startDate", "2021-08-01T00:00");
        }
        FtToast.showToastLoading("loading");
        FssProductReportService.getJtProductSaleList(this.keyword,
            this.getFilterColumnValue("dateType"),
            this.getFilterColumnValue("startDate"), this.getFilterColumnValue("endDate"),
            this.getFilterColumnValue("supplierName"),this.filterColumns.get("categoryList"),
            this.filterColumns.get("orderStatus"),this.filterColumns.get("tagsList"),this.filterColumns.get("includeScheduleTimeIsNull"),
            this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<ProductSaleInfo>) => {
                FtToast.closeToast();
                this.pagedList =result;
                this.fireUpdateData();
            });
    }
}

export default class FssProductSaleListPage extends FtComponent<any, JtProductSaleListState> {
    csvLink!: any;

    constructor(props: any) {

        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        // const column0: FtDataTableColumnProps = new FtDataTableColumnProps("productImageUrl", i18n("商品图片"));
        // column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
        //     const info: ProductSaleInfo = rowData as ProductSaleInfo;
        //     return <img src={info.coverImageUrl_t}></img>
        // };
        // columnProps.push(column0);
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("productName", i18n("商品名称"));
        columnProps.push(column0);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("salesPrice", i18n("销售单价"));
        columnProps.push(column2);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("purchasePrice", i18n("产品成本"), "right");
        columnProps.push(column6);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("salesAmount", i18n("支付件数"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("salesTotalMoney", i18n("销售总金额"));
        columnProps.push(column4);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("purchaseTotalMoney", i18n("总成本"));
        columnProps.push(column7);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("profitTotalMoney", i18n("毛利润"));
        columnProps.push(column8);
        /*const column9: FtDataTableColumnProps = new FtDataTableColumnProps("supplierName", i18n("供应商"));
        columnProps.push(column9);
        const column10: FtDataTableColumnProps = new FtDataTableColumnProps("categoryName", i18n("分类"));
        columnProps.push(column10);*/
        const column11: FtDataTableColumnProps = new FtDataTableColumnProps("grossProfitMargin", i18n("毛利率"));
        columnProps.push(column11);
        const column12: FtDataTableColumnProps = new FtDataTableColumnProps("returnTimes", i18n("退货次数"));
        columnProps.push(column12);
        const column13: FtDataTableColumnProps = new FtDataTableColumnProps("viewsCount", i18n("商品浏览量"));
        columnProps.push(column13);
        const column14: FtDataTableColumnProps = new FtDataTableColumnProps("conversionRate", i18n("转化率"));
        columnProps.push(column14);
        const column15: FtDataTableColumnProps = new FtDataTableColumnProps("rateMoney", i18n("税费"));
        columnProps.push(column15);

        let tableAdapter: JtTableAdapter;
        const cacheState: JtProductSaleListState = FtState.pop("JtProductSaleListState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter, columnProps: columnProps,
            stateKey: "JtProductSaleListState", csvData: new Array<ProductSaleInfo>(),
            supplierList: [],
            categoryList: [],
            openCategoryDialog: false,
            openTagsDialog: false,
            defaultTagsList:[]
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.downloadCsvFile = this.downloadCsvFile.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.openCategoryDialog = this.openCategoryDialog.bind(this);
        this.openTagsDialog = this.openTagsDialog.bind(this);
        this.handleAddChipTags = this.handleAddChipTags.bind(this);
        this.handleDeleteChipTags = this.handleDeleteChipTags.bind(this);
    }

    componentDidMount(): void {
        let filterColumns = new Map<string, any>();
        filterColumns.set("type", "SUPPLIER");

        FssProductSupplierService.getProductSupplierList("", filterColumns,
            null, null, 0, 10000,
            (result: Array<FssProductSupplier>) => {
                this.setState({supplierList: result});
            });
        this.loadCategoryData();
        this.loadTagsData();
        this.state.tableAdapter.loadData();

    }
    private loadCategoryData(): void {
        FssCategoryService.getStoreCategoryList("", 0, 999, '', (result: FtPagedList<FssCategory>) => {
            this.setState({categoryList: result.content});
        });
    }
    private loadTagsData(): void {
        FssProductService.getStoreTagsList( (result: Array<string>) => {
            this.setState({defaultTagsList: result});
        });
    }
    handleAddChip(chip: string) {
        let categoryList: Array<string> = this.state.tableAdapter.filterColumns.get("categoryList");
        let index = categoryList.indexOf(chip);
        if (index == -1) {
            categoryList.push(chip);
            this.state.tableAdapter.filterColumns.set("categoryList", categoryList);
            this.forceUpdate();
        }

    }

    handleDeleteChip(chip: string) {
        let categoryList: Array<string> = this.state.tableAdapter.filterColumns.get("categoryList");
        let index = categoryList.indexOf(chip);
        if (index > -1) {
            categoryList.splice(index, 1);
            this.state.tableAdapter.filterColumns.set("categoryList", categoryList);
            this.forceUpdate();
        }

    }
    handleAddChipTags(chip: string) {
        let tagsList: Array<string> = this.state.tableAdapter.filterColumns.get("tagsList");
        let index = tagsList.indexOf(chip);
        if (index == -1) {
            tagsList.push(chip);
            this.state.tableAdapter.filterColumns.set("tagsList", tagsList);
            this.forceUpdate();
        }

    }

    handleDeleteChipTags(chip: string) {
        let tagsList: Array<string> = this.state.tableAdapter.filterColumns.get("tagsList");
        let index = tagsList.indexOf(chip);
        if (index > -1) {
            tagsList.splice(index, 1);
            this.state.tableAdapter.filterColumns.set("tagsList", tagsList);
            this.forceUpdate();
        }

    }
    render() {
        this.state.tableAdapter.filterColumns.set("showFilterPanel", true);
        return <FtPage onCancelClick={() => {
            this.props.history.goBack()
        }}
                                  toolbarLeftNode={<Typography variant="h6">{i18n("报表")}</Typography>}>
            <FtDataTable title={i18n("商品销售报表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         filterPanel={this.getFilterPanel()}
                         toolbarNoSelect={this.downloadCsvFile()}>
            </FtDataTable>
            {this.openCategoryDialog()}
            {this.openTagsDialog()}
        </FtPage>;
    }

    getFilterPanel(): ReactNode {
        let categoryList: Array<string> = [];
        if (this.state.tableAdapter.filterColumns.get("categoryList") == null) {
            this.state.tableAdapter.filterColumns.set("categoryList", categoryList);
        }
        let tagsList: Array<string> = [];
        if (this.state.tableAdapter.filterColumns.get("tagsList") == null) {
            this.state.tableAdapter.filterColumns.set("tagsList", tagsList);
        }
        if (this.state.tableAdapter.filterColumns.get("orderStatus") == null) {
            this.state.tableAdapter.filterColumns.set("orderStatus", "APPROVED,PICKING,PACKAGING,DELIVERED,SHIPPED");
        }
        return  <FtGrid>
            <FormControl style={{minWidth: 150}}>
                <InputLabel> {i18n("时间类型")} </InputLabel>
                <Select id="dateType" value={this.state.tableAdapter.getFilterColumnValue("dateType")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("dateType", event.target.value);
                            this.forceUpdate();
                        }}>

                         <MenuItem value={"createOrderTime"}>{i18n("下单时间")}</MenuItem>
                        <MenuItem value={"scheduleOrderTime"}>{i18n("配送时间")}</MenuItem>
                </Select>
            </FormControl>
            <TextField id="startDate"
                       label={i18n("开始时间")}
                       type="datetime-local"
                       style={{width: 200}}
                       value={this.state.tableAdapter.getFilterColumnValue("startDate")}
                       InputLabelProps={{
                           shrink: true,
                       }}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("startDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            <TextField id="endDate"
                       label={i18n("结束时间")}
                       type="datetime-local"
                       style={{width: 200}}
                       value={this.state.tableAdapter.getFilterColumnValue("endDate")}
                       InputLabelProps={{
                           shrink: true,
                       }}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("endDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            {this.state.tableAdapter.getFilterColumnValue("dateType") == "scheduleOrderTime" &&
                <FormControlLabel control={
                    <Checkbox checked={this.state.tableAdapter.filterColumns.get("includeScheduleTimeIsNull") as boolean}
                              onChange={(event, checked) => {
                                  this.state.tableAdapter.filterColumns.set("includeScheduleTimeIsNull", checked);
                                  this.forceUpdate();
                              }}/>} label={i18n("包含配送时间为空的订单")} />}
            <div style={{width: "100%"}}/>
            <FormControl style={{minWidth: 200}}>
                <InputLabel> {i18n("供货商")} </InputLabel>
                <Select id="supplierName" value={this.state.tableAdapter.getFilterColumnValue("supplierName")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("supplierName", event.target.value);
                            this.forceUpdate();
                        }}>
                    {this.state.supplierList.map(a => {
                        return <MenuItem value={a.supplierName}
                                         key={a.supplierName}>{i18n(a.supplierName)}</MenuItem>
                    })}
                </Select>
            </FormControl>
            <FormControl style={{minWidth: 400}}>
                <FormLabel> 订单状态 </FormLabel>
                <FtCheckboxGroup row={true}
                                 values={[EOrderStatus.APPROVED, EOrderStatus.PICKING, EOrderStatus.PACKAGING,
                                     EOrderStatus.SHIPPED, EOrderStatus.DELIVERED, EOrderStatus.CLOSED]}
                                 labels={[i18n("审核通过"), i18n("拣货"), i18n("打包"), i18n("发货"), i18n("送达完成"), i18n("已关闭")]}
                                 checkedValues={this.state.tableAdapter.filterColumns.get("orderStatus")?.split(",")}
                                 onCheck={(checkedValue) => {
                                     console.log(checkedValue);
                                     this.state.tableAdapter.filterColumns.set("orderStatus",checkedValue.join(","));
                                     this.forceUpdate()
                                 }}>
                </FtCheckboxGroup>
            </FormControl>
            <FormControl style={{minWidth: 400}}>
                <FormLabel> 分类 </FormLabel>
                <FtRow>
                    <Button color="secondary" variant="outlined" size={"small"}
                            onClick={() => {
                                this.setState({
                                    openCategoryDialog: true
                                });}}>{i18n("选择")}</Button>
                    <ChipInput
                        variant={"standard"}
                        value={this.state.tableAdapter.filterColumns.get("categoryList")}
                        style={{width:"100%"}}
                        onDelete={(chip, index) => this.handleDeleteChip(chip)}/>
                </FtRow>
            </FormControl>
            <FormControl style={{minWidth: 400}}>
                <FormLabel> 标签 </FormLabel>
                <FtRow>
                    <Button color="secondary" variant="outlined" size={"small"}
                            onClick={() => {
                                this.setState({
                                    openTagsDialog: true
                                });}}>{i18n("选择")}</Button>
                    <ChipInput
                        variant={"standard"}
                        value={this.state.tableAdapter.filterColumns.get("tagsList")}
                        style={{width:"100%"}}
                        onDelete={(chip, index) => this.handleDeleteChipTags(chip)}/>
                </FtRow>
            </FormControl>

            <div style={{width: "100%"}}/>
            {this.state.tableAdapter.getFilterColumnValue("dateType") == "scheduleOrderTime" &&
            <FtRow>
                <Typography variant="caption" gutterBottom color={"primary"}>
                    {i18n("按配送时间查询报表时，未指定配送时间的商品会同时显示出来，请及时处理")}
                </Typography>
            </FtRow>
            }

        </FtGrid>;
    }
    openTagsDialog() {
        return <Dialog maxWidth={"md"} open={this.state.openTagsDialog}
                       onClose={(e) => {
                           this.setState({openTagsDialog: false});
                       }}
                       scroll={"paper"}>
            <DialogContent>
                <FtRow cellHorizontalSpace={16} >
                    {this.state.defaultTagsList.map(data => {
                        let targetIndex = -1;
                        if(this.state.tableAdapter.filterColumns.get("tagsList")){
                            targetIndex = _.findIndex(this.state.tableAdapter.filterColumns.get("tagsList"), (tag: string) => {
                                return data == tag;
                            });
                        }
                        return <Chip color={targetIndex>-1 ? "secondary" : "default"}
                                     label={data}
                                     onClick={() => {
                                         if(targetIndex>-1){
                                             this.handleDeleteChipTags(data);
                                         }else {
                                             this.handleAddChipTags(data);
                                         }
                                     }} />;
                    })}
                </FtRow>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={(e) => {
                    this.setState({openTagsDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }
    openCategoryDialog() {
        return <Dialog maxWidth={"md"} open={this.state.openCategoryDialog}
                       onClose={(e) => {
                           this.setState({openCategoryDialog: false});
                       }}
                       scroll={"paper"}>
            <DialogContent>
                <FtRow cellHorizontalSpace={16} >
                    {this.state.categoryList.map(data => {
                        let targetIndex = -1;
                        if(this.state.tableAdapter.filterColumns.get("categoryList")){
                            targetIndex = _.findIndex(this.state.tableAdapter.filterColumns.get("categoryList"), (tag: string) => {
                                return data.categoryName == tag;
                            });
                        }
                        return <Chip color={targetIndex>-1 ? "secondary" : "default"}
                                     label={data.categoryName}
                                     onClick={() => {
                                         if(targetIndex>-1){
                                             this.handleDeleteChip(data.categoryName);
                                         }else {
                                             this.handleAddChip(data.categoryName);
                                         }
                                     }} />;
                    })}
                </FtRow>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={(e) => {
                    this.setState({openCategoryDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

    downloadCsvFile(): ReactNode {
        let headers = [

            {label: i18n("商品名称"), key: "productName"},
            {label: i18n("销售区间"), key: "startDate"},
            {label: i18n("productCode"), key: "productCode"},
            {label: i18n("销售单价"), key: "salesPrice"},
            {label: i18n("产品成本"), key: "purchasePrice"},
            {label: i18n("支付件数"), key: "salesAmount"},
            {label: i18n("销售总金额"), key: "salesTotalMoney"},

            {label: i18n("总成本"), key: "purchaseTotalMoney"},
            {label: i18n("毛利润"), key: "profitTotalMoney"},
            /*{label: i18n("供应商"), key: "supplierName"},
            {label: i18n("分类"), key: "categoryName"}*/
            {label: i18n("毛利率"), key: "grossProfitMargin"},
            {label: i18n("退货次数"), key: "returnTimes"},
            {label: i18n("商品浏览量"), key: "viewsCount"},
            {label: i18n("转化率"), key: "conversionRate"},
            {label: i18n("总税费"), key: "rateMoney"},
        ];
        let fileName = "product_sales";
        let startDate = this.state.tableAdapter.getFilterColumnValue("startDate")
        let endDate = this.state.tableAdapter.getFilterColumnValue("endDate")
        if (startDate != null && startDate != "") {
            fileName += "_" + lodash.replace(startDate,"T"," ") ;
        } else {
            fileName += "_startDate";
        }
        if (endDate != null && endDate != "") {
            fileName += "_" + lodash.replace(endDate,"T"," ") ;
        } else {
            fileName += "_endDate";
        }
        fileName += ".csv";
        return <div>
            <IconButton onClick={() => {
                FtToast.showToastLoading("loading");
                FssProductReportService.getJtProductSaleList(this.state.tableAdapter.keyword,
                    this.state.tableAdapter.getFilterColumnValue("dateType"),
                    this.state.tableAdapter.getFilterColumnValue("startDate"), this.state.tableAdapter.getFilterColumnValue("endDate"),
                    this.state.tableAdapter.getFilterColumnValue("supplierName"),this.state.tableAdapter.filterColumns.get("categoryList"),
                    this.state.tableAdapter.filterColumns.get("orderStatus"),this.state.tableAdapter.filterColumns.get("tagsList"),this.state.tableAdapter.filterColumns.get("includeScheduleTimeIsNull"),
                    0, 999999,
                    (result: FtPagedList<ProductSaleInfo>) => {
                        FtToast.closeToast();
                        this.setState({csvData: result.content}, () => {
                            if (this.csvLink && this.csvLink.link)
                                this.csvLink.link.click();
                        });
                    });
            }}><CloudDownloadOutlined/></IconButton>
            <CSVLink data={this.state.csvData} headers={headers} filename={i18n(fileName)}
                     ref={(element: any) => {
                         this.csvLink = element
                     }}>
            </CSVLink>
        </div>
    }
}
