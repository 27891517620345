import FtPagedList from "../model/FtPagedList";
import FssStore from "../model/FssStore";
import FssHttpService from "./FssHttpService";

export default class FssStoreService {
    static getStorePagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                             pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssStore>) => void) {
        FssHttpService.search("/api/store/getPagedList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction);
    }

    static getStoreById(storeId: string, successFunction: (store: FssStore) => void) {
        FssHttpService.get("/api/store/getById", {storeId: storeId}, successFunction);
    }

    static saveStore(store: FssStore, successFunction: (data: FssStore) => void): void {
        FssHttpService.postJson("/api/store/saveStore", store, successFunction);
    }
}
