import * as React from "react";
import FssAbstractCompProp from "./FssAbstractCompProp";
import FtComponent, {FtComponentState} from "../../ui/FtComponent";

interface FssContainerCompProp extends FssAbstractCompProp{
}


export class FssContainerCompState extends FtComponentState{
}


export default class FssContainerComp extends FtComponent<FssContainerCompProp, FssContainerCompState> {

    constructor(props: any) {
        super(props);
        this.state = {stateKey: "FssContainerComp" + this.props.location.pathname};
    }

    componentDidMount(): void {
    }

    render() {
        return  <div>
            FssContainerComp Component {this.props.component.componentName}
        </div>;
    }
}
