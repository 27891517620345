export enum ECalculateUnit {
    WEIGHT = "WEIGHT",//按重量计费，单位：克
    AMOUNT = "AMOUNT",//按件数计费
    SIZE = "SIZE"//按体积计费，单位：立方米
}

export default class FssShippingFeeRule {
    id: string = "";
    storeId: string = "";
    sellerId: string = "";
    shippingFeeTemplateId: string = "";
    calculateUnit: ECalculateUnit = ECalculateUnit.WEIGHT;
    baseValue: number = 0;
    basePrice: number = 0;
    continueValue: number = 0;
    continuePrice: number = 0;
    country: string = "";
    province: string = "";
    city: string = "";
    minMoney: number = 0;
    description: string = "";
    maxMoney: number = 0;
    freeShipBaseMinimum: number = 0;
    freeShipAllMinimum: number = 0;
    minimumOrderMoney: number = 0;
    minimumOrderWeight: number = 0;
    splitByProductAmount: number = 0;
    splitByTotalAmount: number = 0;
    splitByTotalWeight: number = 0;
    boundLatLng: string = "";
    freeShipBaseCumulate: boolean = false;
    priority: number = 0;
    regionId: string = "";
    regionName: string = "";

    validate(): string {
        return "";
    }
}
