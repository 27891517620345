import * as React from "react";
import FtPanel from "../../ui/FtPanel";
import FtRow from "../../ui/FtRow";
import FssCompHeaderEditor from "./FssCompHeaderEditor";
import _ from "lodash";
import {IconButton} from "@material-ui/core";
import {AddOutlined, ChevronRight, ExpandMore, RemoveOutlined} from "@material-ui/icons";
import {TreeItem, TreeView} from "@material-ui/lab";
import {i18n} from "../../util/I18n";
import FssComponent, {EComponentType} from "../../model/FssComponent";
import FssCategoryNavigationCompData from "../../page/component/data/FssCategoryNavigationCompData";
import FssCategory from "../../model/FssCategory";
import FssCategoryService from "../../service/FssCategoryService";
import FssStoreCategory from "../../model/FssStoreCategory";
import FssStoreCategoryService from "../../service/FssStoreCategoryService";

interface FssCategoryNavigationCompEditorProp {
    type: EComponentType.STORE_CATEGORY_NAVIGATION | EComponentType.CATEGORY_NAVIGATION;
    component: FssComponent;
    onChangeComponent?: (component: FssComponent) => void;
}

export class FssCategoryNavigationCompEditorState {
    category: FssCategory | FssStoreCategory = new FssCategory();
}


export default class FssCategoryNavigationCompEditor extends React.Component<FssCategoryNavigationCompEditorProp, FssCategoryNavigationCompEditorState> {

    constructor(props: any) {
        super(props);
        let category: FssCategory | FssStoreCategory = new FssCategory()
        if (this.props.type == EComponentType.STORE_CATEGORY_NAVIGATION) {
            category = new FssStoreCategory();
        }
        this.state = {category: category};
        this.onChangeComponent = this.onChangeComponent.bind(this);
        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.getCategoryListItem = this.getCategoryListItem.bind(this);
        this.getTreeItemLabel = this.getTreeItemLabel.bind(this);
        this.addCategory = this.addCategory.bind(this);
        this.removeCategory = this.removeCategory.bind(this);
    }

    componentDidMount(): void {
        if (this.props.type == EComponentType.CATEGORY_NAVIGATION) {
            FssCategoryService.getRootCategory((result: FssCategory) => {
                this.setState({category: result});
            })
        } else if (this.props.type == EComponentType.STORE_CATEGORY_NAVIGATION) {
            FssStoreCategoryService.getRootCategory((result: FssStoreCategory) => {
                this.setState({category: result});
            })
        }
    }

    componentWillUnmount() {

    }

    onChangeCategory(item: any) {
        this.setState({category: item});
        this.forceUpdate();
    }

    getCategoryListItem(type: boolean) {
        let category = this.state.category;
        const component = this.props.component;
        const categoryNavigationCompData = component.data as FssCategoryNavigationCompData;
        if (!categoryNavigationCompData.categoryIdArray) {
            categoryNavigationCompData.categoryIdArray = [];
        }
        let categoryIdArray = categoryNavigationCompData.categoryIdArray;

        if (category && category.subCategoryList) {
            return <TreeView defaultCollapseIcon={<ExpandMore/>} defaultExpandIcon={<ChevronRight/>}>
                {category.subCategoryList.map((parentCategory: FssCategory | FssStoreCategory) => {
                    if (!type && categoryIdArray.indexOf(parentCategory.id) == -1) {
                        return null;
                    }
                    return <TreeItem nodeId={parentCategory.id} label={this.getTreeItemLabel(parentCategory, type)}>
                        {parentCategory.subCategoryList && parentCategory.subCategoryList.map((childCategory: FssCategory | FssStoreCategory) => {
                            if (!type && categoryIdArray.indexOf(childCategory.id) == -1) {
                                return null;
                            }
                            return <TreeItem nodeId={childCategory.id} label={this.getTreeItemLabel(childCategory, type)}/>
                        })}
                    </TreeItem>;
                })}
            </TreeView>;
        } else {
            return null;
        }
    }

    getTreeItemLabel(category: FssCategory | FssStoreCategory, type: boolean) {
        const component = this.props.component;
        const categoryNavigationCompData = component.data as FssCategoryNavigationCompData;
        let categoryIdArray = categoryNavigationCompData.categoryIdArray;

        return <div style={{display: "flex", justifyContent: "space-between", height: 30, lineHeight: "30px"}}>
            <div>{category.categoryName}</div>
            {type && (!categoryIdArray || categoryIdArray.indexOf(category.id) == -1) &&
            <IconButton style={{height: 30, width: 30}} onClick={(event) => {
                event.stopPropagation();
                this.addCategory(category);
            }}><AddOutlined/></IconButton>}
            {type && categoryIdArray && categoryIdArray.indexOf(category.id) > -1 &&
            <div>{i18n("已添加")}</div>}
            {!type && <IconButton style={{height: 30, width: 30}} onClick={(event) => {
                event.stopPropagation();
                this.removeCategory(category);
            }}><RemoveOutlined/></IconButton>}
        </div>
    }

    removeCategory(category: FssCategory | FssStoreCategory) {
        const component = this.props.component;
        const categoryNavigationCompData = component.data as FssCategoryNavigationCompData;
        if (!categoryNavigationCompData.categoryIdArray) {
            categoryNavigationCompData.categoryIdArray = [];
        }
        let selectesIds = categoryNavigationCompData.categoryIdArray;
        // 删除父分类时，把子分类也删除掉
        if (category.parentCategoryId.length == 0) {
            let parentCategory = _.find(this.state.category.subCategoryList, {'id': category.id}) as FssCategory | FssStoreCategory;
            if (parentCategory != null && parentCategory.subCategoryList.length > 0) {
                parentCategory.subCategoryList.map((removeCategory: FssCategory | FssStoreCategory) => {
                    _.remove(selectesIds, (item) => item === removeCategory.id);
                })
            }
        }
        // 然后在删除父分类
        _.remove(selectesIds, (item) => item === category.id);
        this.onChangeComponent();
    }

    addCategory(category: FssCategory | FssStoreCategory) {
        const component = this.props.component;
        const categoryNavigationCompData = component.data as FssCategoryNavigationCompData;
        if (!categoryNavigationCompData.categoryIdArray) {
            categoryNavigationCompData.categoryIdArray = [];
        }
        let selectesIds = categoryNavigationCompData.categoryIdArray;
        //  添加子分类时，判断如果没有添加父分类时，先添加父分类
        if (category.parentCategoryId.length > 0 && (selectesIds.indexOf(category.parentCategoryId) == -1)) {
            selectesIds.push(category.parentCategoryId);
        }
        // 添加子分类
        if (selectesIds.indexOf(category.id) == -1) {
            selectesIds.push(category.id);
        }
        this.onChangeComponent();
    }


    render() {
        return <div key={this.props.component.id}>
            <FssCompHeaderEditor component={this.props.component} onChangeComponent={this.onChangeComponent}/>
            <FtPanel marginVertical={8}>
                <FtRow cellWidthM={"1,1"} cellHorizontalAlign={"left"} cellVerticalAlign={"top"}>
                    <FtPanel panelHeader={i18n("所有分类")}>
                        {this.getCategoryListItem(true)}
                    </FtPanel>
                    <FtPanel panelHeader={i18n("已添加分类")}>
                        {this.getCategoryListItem(false)}
                    </FtPanel>
                </FtRow>
            </FtPanel>
        </div>
    }

    onChangeComponent() {
        if (this.props.onChangeComponent) {
            this.props.onChangeComponent(this.props.component);
        }
    }
}
