import * as React from "react";
import {Button, Typography} from "@material-ui/core";
import {RouteComponentProps} from "react-router-dom";
import FssUser from "../model/FssUser";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FssResetPasswordDialog from "./FssResetPasswordDialog";
import FtToast from "../ui/FtToast";
import FssUserService from "../service/FssUserService";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import FtPanel from "../ui/FtPanel";
import FtPage from "../ui/FtPage";
import FtToolbar from "../ui/FtToolbar";
import FssUserDetailComp from "./FssUserDetailComp";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtMessageDialog from "../ui/FtMessageDialog";

export interface FssMyUserDetailPageProp extends RouteComponentProps {
}

export class FssMyUserDetailPageState extends FtComponentState {
    user: FssUser = new FssUser();
}

export default class FssMyUserDetailPage extends FtComponent<FssMyUserDetailPageProp, FssMyUserDetailPageState> {
    jtResetPasswordDialog!: FssResetPasswordDialog;

    constructor(props: any) {
        super(props);
        this.state = { user: new FssUser()};
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
        this.onClickResetPassword = this.onClickResetPassword.bind(this);
    }

    componentDidMount(): void {
        FtToast.showToastLoading();
        FssUserService.getMyUserDetail((data: FssUser) => {
            FtToast.closeToast();
            this.setState({ user: data });
        });
    }

    onChange(id: string, value: string) {
        FtUtil.setProperty(this.state.user, id, value);
        this.forceUpdate();
    }

    render() {
        return <FtPage>
            <FtToolbar position={"sticky"} centerNode={<Typography variant={"h6"}>{i18n("我的资料")}</Typography>} />
            <FtPanel elevation={3}>
                <FssUserDetailComp user={this.state.user} onChangeValue={this.onChange} />
                <FtGrid paddingVertical={32}>
                    <FtRow cellHorizontalSpace={16} justifyContent={"center"}>
                        {/*<Button variant={"outlined"} onClick={this.onClickBack}>{i18n("返回")}</Button>*/}
                        <Button variant={"outlined"} onClick={this.onClickResetPassword}>{i18n("修改密码")}</Button>
                        <Button variant={"contained"} color={"primary"} onClick={this.onClickSave}>{i18n("保存")}</Button>
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FssResetPasswordDialog ref={(element: any) => {
                this.jtResetPasswordDialog = element;
            }} />
        </FtPage>;
    }

    private onClickSave() {
        const message: string = "";
        if (message.length > 0) {
            FtMessageDialog.dialog.openDialog(message);
        } else {
            FtToast.showToastLoading(i18n("正在保存"));
            FssUserService.saveMyUserDetail(this.state.user, (data: FssUser) => {
                FtToast.closeToast();
                this.setState({ user: data });
                window.location.reload();
            });
        }
    }
    private onClickBack() {
        this.props.history.goBack();
    }
    private onClickResetPassword() {
        this.jtResetPasswordDialog.show();
    }
}
