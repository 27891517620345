import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FssShippingFeeTemplate, {ECalculateUnit, EStatus} from "../model/FssShippingFeeTemplate";
import {Add, Save} from "@material-ui/icons";
import {FtComponentState} from "../ui/FtComponent";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtState from "../ui/FtState";
import FtPagedList from "../model/FtPagedList";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import FtMessageDialog from "../ui/FtMessageDialog";
import {InputAdornment, TextField, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FssShippingFeeRule from "../model/FssShippingFeeRule";
import {i18n} from "../util/I18n";
import {Link, RouteComponentProps} from "react-router-dom";
import FtPanel from "../ui/FtPanel";
import FtRow from "../ui/FtRow";
import FssProductShippingFeeTemplateService from "../service/FssProductShippingFeeTemplateService";
import FssProduct from "../model/FssProduct";
import FssProductService from "../service/FssProductService";
import FtPage from "../ui/FtPage";
import FssShippingFeeRuleService from "../service/FssShippingFeeRuleService";
import FssShippingFeeTemplateService from "../service/FssShippingFeeTemplateService";

interface JtShippingfeeTempDetailProp extends RouteComponentProps<{ shippingFeeTemplateId: string, deliveryProviderId: string }> {

}

export class JtShippingfeeTempDetailState extends FtComponentState {
    shippingFeeTemplate: FssShippingFeeTemplate = new FssShippingFeeTemplate();
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();

    productList: Array<FssProduct> = new Array<FssProduct>();
    productSelectOptions: Array<FssProduct> = new Array<FssProduct>();
    product: FssProduct = new FssProduct();

}

class JtTableAdapter extends FtDataTableAdapter<FssShippingFeeRule> {

    loadData(): void {
        FtToast.showToastLoading("loading");
        FssShippingFeeRuleService.getShippingFeeRulePaged("", this.filterColumns,
            this.sortColumn ? this.sortColumn.propertyName : null, this.sortDirection,
            this.pagedList.number, this.pagedList.size, (data: FtPagedList<FssShippingFeeRule>) => {
                FtToast.closeToast();
                this.pagedList = data;
                this.fireUpdateData();
            });
    }
}

export default class JtShippingFeeTempDetailPage extends React.Component<JtShippingfeeTempDetailProp, JtShippingfeeTempDetailState> {

    constructor(props: any) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.getToolbarWithSelect = this.getToolbarWithSelect.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.loadData = this.loadData.bind(this);
        this.loadProduct = this.loadProduct.bind(this);
        this.onChangeProductInput = this.onChangeProductInput.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);

        const columnProps: Array<FtDataTableColumnProps> = [];
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("regionName", i18n("服务地区"));
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const shippingFeeRule: FssShippingFeeRule = rowData as FssShippingFeeRule;
            return <Link
                to={"/shippingFeeRuleDetail/" + shippingFeeRule.id + "/" + this.props.match.params.shippingFeeTemplateId}>
                {shippingFeeRule.regionName}
            </Link>
        };
        columnProps.push(column0);
        const column19: FtDataTableColumnProps = new FtDataTableColumnProps("priority", i18n("优先级"));
        columnProps.push(column19);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("baseValue", i18n("首重"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("basePrice", i18n("首重价格"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("continueValue", i18n("续重"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("continuePrice", i18n("续重价格"));
        columnProps.push(column4);

        let tableAdapter: JtTableAdapter;
        const cacheState: JtShippingfeeTempDetailState = FtState.pop("shippingfeeTempDetailState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        let shippingFeeTemplate: FssShippingFeeTemplate = new FssShippingFeeTemplate();
        shippingFeeTemplate.deliveryProviderId = this.props.match.params.deliveryProviderId;
        this.state = {
            shippingFeeTemplate: shippingFeeTemplate,
            stateKey: "shippingfeeTempDetailState",
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            productList: new Array<FssProduct>(),
            productSelectOptions: new Array<FssProduct>(),
            product: new FssProduct()
        };
    }

    componentDidMount(): void {
        if (this.props.match.params.shippingFeeTemplateId != "0") {
            this.loadData();
        }
    }

    loadData() {
        FtToast.showToastLoading("loading");
        FssShippingFeeTemplateService.getShippingFeeTemplate(this.props.match.params.shippingFeeTemplateId, (result: FssShippingFeeTemplate) => {
            FtToast.closeToast();
            this.setState({shippingFeeTemplate: result}, () => {
                if (result && result.id && result.id.length > 0) {
                    this.loadProduct();
                    const filterColumns: Map<string, any> = new Map<string, any>();
                    filterColumns.set("shippingFeeTemplateId", result.id);
                    let tableAdapter = this.state.tableAdapter;
                    tableAdapter.filterColumns = filterColumns;
                    this.setState({tableAdapter: tableAdapter}, () => {
                        this.state.tableAdapter.loadData();
                    });
                }

            });
        });
    }

    loadProduct(): void {
        FssProductShippingFeeTemplateService.getByShippingFeeTemplateId(this.state.shippingFeeTemplate.id, (data: Array<FssProduct>) => {
            FtToast.closeToast();
            if (data != null && data.length > 0) {
                this.setState({productSelectOptions: data});
            }
        });
    }

    onChangeProduct(item: any) {
        this.setState({product: item});
        this.forceUpdate();
    }

    onChangeProductInput(keyword: string) {
        FssProductService.getAvailProductByBrandAndCategoryList(keyword, 5, (productList: Array<FssProduct>) => {
            this.setState({productList: productList});
        });
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.shippingFeeTemplate, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        const message: string = '';
        if (message == null || message == "") {
            FtToast.showToastLoading(i18n("正在保存"));
            this.state.shippingFeeTemplate.ruleList = this.state.tableAdapter.pagedList.content;
            let productIds: Array<string> = new Array<string>();
            this.state.productSelectOptions.forEach(value => {
                productIds.push(value.id);
            })
            this.state.shippingFeeTemplate.productIds = productIds.join(",");
            FssShippingFeeTemplateService.saveShippingFeeTemplate(this.state.shippingFeeTemplate,
                (data: FssShippingFeeTemplate) => {
                    this.setState({shippingFeeTemplate: FtUtil.mergeObject(this.state.shippingFeeTemplate, data)});
                    FtToast.closeToast();
                    FtSnackbar.showText(i18n("保存成功！"));
                    this.onCancelClick();
                });
        } else {
            FtSnackbar.showText(message);
        }
    }

    getToolbarWithSelect(): ReactNode {
        return <div><Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={this.onClickDelete}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
        </div>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Button variant="contained" size="small" onClick={() => {
                this.props.history.push("/shippingFeeRuleDetail/0/" + this.props.match.params.shippingFeeTemplateId);
            }}>
                <Add/>{i18n("新增")}
            </Button>
        </div>;
    }

    onClickDelete() {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除{0}个记录吗？", this.state.tableAdapter.selectedRow.length + ""), () => {
                    FtMessageDialog.dialog.closeDialog();
                    const idList: Array<string> = new Array<string>();
                    this.state.tableAdapter.selectedRow.forEach((rowData: any, index: number) => {
                        idList.push((rowData as FssShippingFeeRule).id);
                    });
                    FssShippingFeeRuleService.deleteShippingFeeRule(idList, (data: string) => {
                        if (data)
                            FtSnackbar.showText(data);
                        this.state.tableAdapter.selectedRow = new Array<any>();
                        this.state.tableAdapter.loadData();
                    });
                },
                () => {
                    FtMessageDialog.dialog.closeDialog();
                }
            )
        }
    }

    getFtDataTable(): ReactNode {
        return <FtDataTable title={i18n("运费模板规则列表")}
                            columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                            toolbarNoSelect={this.getToolbarNoSelect()}
                            toolbarWithSelect={this.getToolbarWithSelect()}/>
    }

    render() {
        let isEdit: boolean = false;
        if (this.state.shippingFeeTemplate.id != null && this.props.match.params.shippingFeeTemplateId != "0") {
            isEdit = true;
        }
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="contained"
                                                color="primary"
                                                startIcon={<Save/>}
                                                onClick={this.onClickSave}>{i18n("保存")}</Button>}>
            <FtPanel panelHeader={<Typography variant={"h6"}>{i18n("运费模板信息")}</Typography>}
                     style={{padding: 16, marginBottom: 16}}>
                <FtRow cellWidthS={"1,1,1"}>
                    <TextField id="templateName" label={i18n("模板名称")}
                               value={this.state.shippingFeeTemplate.templateName}
                               onChange={this.onChange} style={{width: "100%"}}/>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel> {i18n("优先级")} </InputLabel>
                        <Select id="priority" value={this.state.shippingFeeTemplate.priority}
                                onChange={(event, child) => {
                                    this.state.shippingFeeTemplate.priority = event.target.value as number;
                                    this.forceUpdate();
                                }}>
                            <MenuItem value={0}> 0 </MenuItem>
                            <MenuItem value={1}> 1 </MenuItem>
                            <MenuItem value={2}> 2 </MenuItem>
                            <MenuItem value={3}> 3 </MenuItem>
                        </Select>
                    </FormControl>
                </FtRow>
                <FtRow cellWidthS={"1,1,1,1"}>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel> {i18n("模板状态")} </InputLabel>
                        <Select id="status" value={this.state.shippingFeeTemplate.status}
                                onChange={(event, child) => {
                                    this.state.shippingFeeTemplate.status = EStatus[event.target.value as keyof typeof EStatus];
                                    this.forceUpdate();
                                }}>
                            <MenuItem value={EStatus.NORMAL}>{i18n("正常")}</MenuItem>
                            <MenuItem value={EStatus.STOP}>{i18n("禁用")}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl style={{width: "100%"}}>
                        <InputLabel> {i18n("计费类型")} </InputLabel>
                        <Select id="calculateUnit" value={this.state.shippingFeeTemplate.calculateUnit}
                                onChange={(event, child) => {
                                    this.state.shippingFeeTemplate.calculateUnit = event.target.value as ECalculateUnit;
                                    this.forceUpdate();
                                }}>
                            <MenuItem value={ECalculateUnit.WEIGHT}>{i18n("重量")}</MenuItem>
                            <MenuItem value={ECalculateUnit.AMOUNT}>{i18n("数量")}</MenuItem>
                            <MenuItem value={ECalculateUnit.SIZE}>{i18n("大小")}</MenuItem>
                            <MenuItem value={ECalculateUnit.DISTANCE}>{i18n("距离")}</MenuItem>
                            <MenuItem value={ECalculateUnit.FIXED}>{i18n("固定值")}</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField id="packagingWeight" label={i18n("包装重量")}
                               value={this.state.shippingFeeTemplate.packagingWeight == 0 ? '' : this.state.shippingFeeTemplate.packagingWeight}
                               onChange={this.onChange} style={{width: "100%"}}
                               type="number"
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                               }}/>
                    <TextField id="packagingFee" label={i18n("包装费")}
                               value={this.state.shippingFeeTemplate.packagingFee}
                               onChange={this.onChange} style={{width: "100%"}}/>
                </FtRow>
                <FtRow cellWidthS={"1"}>
                    <TextField id="description" label={i18n("模板说明")}
                               value={this.state.shippingFeeTemplate.description}
                               onChange={this.onChange} style={{width: "100%"}}/>
                </FtRow>
            </FtPanel>
            {isEdit && this.getFtDataTable()}
        </FtPage>;
    }
}
