import * as React from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {Button, Menu, MenuItem, MenuProps, Typography, withStyles} from "@material-ui/core";
import {ArrowDropDown} from "@material-ui/icons";
import {i18n} from "../util/I18n";

interface FssMarketingEventDetailRightMenuCompProp {
    onCopyMarketingEvent?: () => void;
    onOrderList?: () => void;
    onPublisherList?: () => void;
}

export class FssMarketingEventDetailRightMenuCompState extends FtComponentState {
    showRightDrawer: boolean = false;
}

export default class FssMarketingEventDetailRightMenuComp extends FtComponent<FssMarketingEventDetailRightMenuCompProp, FssMarketingEventDetailRightMenuCompState> {
    anchorElement!:HTMLElement|null;
    constructor(props: any) {
        super(props);
        this.state = {
            showRightDrawer: false,
        };

        this.onClose = this.onClose.bind(this);
    }

    componentDidMount(): void {

    }

    onClose() {
        this.setState({showRightDrawer: false});
    }

    render() {
        return <div>
            <Button endIcon={<ArrowDropDown/>} onClick={() => {
                this.setState({showRightDrawer: true});
            }} ref={(e)=>{this.anchorElement=e;}}>
                活动管理
            </Button>
            <StyledMenu open={this.state.showRightDrawer} anchorEl={this.anchorElement} onClose={this.onClose}>
                <MenuItem onClick={() => {
                    if (this.props.onPublisherList) {
                        this.props.onPublisherList();
                    }
                    this.onClose();
                }}>
                    <Typography variant="inherit">{i18n("开团申请")}</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    if (this.props.onOrderList) {
                        this.props.onOrderList();
                    }
                    this.onClose();
                }}>
                    <Typography variant="inherit">{i18n("管理订单")}</Typography>
                </MenuItem>
                <MenuItem onClick={() => {
                    if (this.props.onCopyMarketingEvent) {
                        this.props.onCopyMarketingEvent();
                    }
                    this.onClose();
                }}>
                    <Typography variant="inherit">{i18n("复制活动")}</Typography>
                </MenuItem>
            </StyledMenu>
        </div>
    }
}
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
