import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssRegion from "../model/FssRegion";

export default class FssRegionService extends FssHttpService {

    static getPageRegionList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssRegion>) => void) {
        FssHttpService.search("/api/region/getPageRegionList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }
    static delete(regionIds: Array<string>, successFunction: (data: FssRegion) => void): void {
        this.postFormData("/api/region/delete", { regionIds: regionIds }, successFunction, FssHttpService.handleError);
    }

    static getById(regionId: string, successFunction: (data: FssRegion) => void): void {
        FssHttpService.get("/api/region/get", { regionId: regionId }, successFunction, FssHttpService.handleError);
    }
    static save(fssRegion: FssRegion, successFunction: (data: FssRegion) => void): void {
        this.postJson("/api/region/save", fssRegion, successFunction, FssHttpService.handleError);
    }
}
