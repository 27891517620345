import FssProductSupplier from "../model/FssProductSupplier";
import FssHttpService from "./FssHttpService";

export default class FssProductSupplierService {
    static getProductSupplierList(searchKeyword: string,
                                  filterColumns: Map<string, any>,
                                  sortPropertyName: string | null,
                                  sortDirection: "asc" | "desc" | null,
                                  pageNo: number,
                                  pageSize: number,
                                  successFunction: (result: Array<FssProductSupplier>) => void): void {
        FssHttpService.search("/api/supplier/getProductSupplierList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }
    static saveProductSupplier(productSupplier: FssProductSupplier, successFunction: (result: FssProductSupplier) => void) {
        FssHttpService.postJson("/api/supplier/saveProductSupplier", productSupplier, successFunction, FssHttpService.handleError);
    }

    static deleteProductSupplier(productSupplierId: string, successFunction: (result: string) => void) {
        FssHttpService.postFormData("/api/supplier/deleteProductSupplier", {productSupplierId: productSupplierId}, successFunction, FssHttpService.handleError);
    }

}
