import FssHttpService from "./FssHttpService";
import FtPagedList from "../model/FtPagedList";
import FssPublisher from "../model/FssPublisher";

export default class FssPublisherService {

    static getMyPublisher(successFunction: (fssPublisher: FssPublisher) => void) {
        FssHttpService.get("/api/publisher/getMyPublisher", null, successFunction, FssHttpService.handleError);
    }

    static getPublisherList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssPublisher>) => void) {
        FssHttpService.search("/api/publisher/getPublisherList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction);
    }

    static savePublisher(fssPublisher: FssPublisher,
                           successFunction: (fssPublisher: FssPublisher) => void,
                           failFunction ?: (error: any) => void): void {
        FssHttpService.postJson("/api/publisher/savePublisher", fssPublisher, successFunction);
    }

    static getPublisherById(id: string, successFunction: (fssPublisher: FssPublisher) => void) {
        FssHttpService.get("/api/publisher/getPublisherById", {id: id}, successFunction);
    }
    static getPublisherByUserId(userId: string, successFunction: (fssPublisher: FssPublisher) => void) {
        FssHttpService.get("/api/publisher/getPublisherByUserId", {userId: userId}, successFunction);
    }

    static getPublisherBySellerId(sellerId: string, successFunction: (publisher: FssPublisher) => void) {
        FssHttpService.get("/api/publisher/getPublisherBySellerId", {sellerId: sellerId}, successFunction);
    }
}
