import {SvgIcon} from "@material-ui/core";
import React from "react";


export default class FtFlagIconUS extends React.Component<any, any>{
    render(){
        return <SvgIcon x="0px" y="0px" viewBox="0 0 7410 3900">
            <rect width="7410" height="3900" fill="#b22234"/>
            <path d="M0,450H7410m0,600H0m0,600H7410m0,600H0m0,600H7410m0,600H0" stroke="#fff" stroke-width="300"/>
            <rect width="2964" height="2100" fill="#3c3b6e"/>
            <path  y="420" d="M247,90 317.534230,307.082039 132.873218,172.917961H361.126782L176.465770,307.082039z"/>
            <path  y="840" d="M247,90 317.534230,307.082039 132.873218,172.917961H361.126782L176.465770,307.082039z"/>
            <path  y="1260" d="M247,90 317.534230,307.082039 132.873218,172.917961H361.126782L176.465770,307.082039z"/>
            <path  y="1680" d="M247,90 317.534230,307.082039 132.873218,172.917961H361.126782L176.465770,307.082039z"/>


        </SvgIcon>;
    }
}
