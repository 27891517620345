import * as React from "react";
import {ChangeEvent} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import {IconButton} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import JtProduct from "../model/FssProduct";
import JtUserMedia from "../model/FssUserMedia";
import FtPanel from "../ui/FtPanel";
import {Refresh} from "@material-ui/icons";
import FtMessageDialog from "../ui/FtMessageDialog";
import JtPaginationComp from "../ui/JtPaginationComp";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import Input from "@material-ui/core/Input";
import {i18n} from "../util/I18n";
import FssUserMediaService from "../service/FssUserMediaService";
import FssUserMediaDetailDialog from "./FssUserMediaDetailDialog";
import FssUserMediaComp from "./FssUserMediaComp";
import FtRow from "../ui/FtRow";
import AppClientInfo from "../data/AppClientInfo";

export interface JtUserMediaListPageProp {
    onClickItem?: (userMedia: JtUserMedia) => void;
    selectedList?: Array<JtUserMedia>;
    onRemoveItem?: (userMediaId: string) => void;
}

class JtUserMediaListPageState {
    searchKeyword: string = "";
    pagedList: FtPagedList<JtUserMedia> = new FtPagedList<JtUserMedia>();
    order?: JtProduct;
    dataIndex?: number = -1;
    inputComposition:boolean=false;
}

export default class FssUserMediaListPage extends React.Component<JtUserMediaListPageProp, JtUserMediaListPageState> {
    jtUserMediaDetailDialog!: FssUserMediaDetailDialog;

    constructor(props: any) {
        super(props);
        this.state = {searchKeyword: "", pagedList: new FtPagedList(),inputComposition:false};
        this.onLoadData = this.onLoadData.bind(this);
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickRemove = this.onClickRemove.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onChangeKeyword=this.onChangeKeyword.bind(this);
        this.onClickSearch=this.onClickSearch.bind(this);
        this.onCompositionStart=this.onCompositionStart.bind(this);
        this.onCompositionEnd=this.onCompositionEnd.bind(this);
    }

    componentDidMount(): void {
        this.onLoadData();
    }

    onLoadData(): void {
        FtToast.showToastLoading("loading");
        FssUserMediaService.getUserMediaList(this.state.searchKeyword, this.state.pagedList.number, this.state.pagedList.size,
            (result: FtPagedList<JtUserMedia>) => {
                FtToast.closeToast();
                this.setState({pagedList: result});
            });
    }

    onChangePage(pageNo: number, pageSize: number) {
        this.state.pagedList.number = pageNo;
        this.state.pagedList.size = pageSize;
        this.onLoadData();
    }

    onClickAdd() {
        this.jtUserMediaDetailDialog.show(new JtUserMedia(), () => {
            this.onLoadData();
        });
    }

    onClickRemove(id?: string) {
        if (id) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除吗?"), () => {
                FssUserMediaService.deleteUserMedia(id, () => {
                    this.onLoadData();
                    if (this.props.onRemoveItem && id != null)
                        this.props.onRemoveItem(id);
                });

            });
        }
    }
    onChangeKeyword(event:ChangeEvent<HTMLInputElement>){
        this.setState({searchKeyword:event.target.value},()=>{
            if(!this.state.inputComposition)
                this.onLoadData();
        });
    }
    onCompositionStart(event:any){
        this.setState({inputComposition:true});
    }
    onCompositionEnd(event:any){
        this.setState({inputComposition:false});
        this.onLoadData();
    }
    onClickSearch(){
        this.state.pagedList.number = 0;
        this.onLoadData();
    }

    isSelectedItem(item: JtUserMedia): boolean {
        let found: boolean = false;
        if (this.props.selectedList != null)
            this.props.selectedList.forEach((userMedia: JtUserMedia, index: number) => {
                if (userMedia.id == item.id) {
                    found = true;
                }
            });
        return found;
    }

    render() {
        let minWidth: string| number = 600;
        if (AppClientInfo.clientInfo.viewMode === "mobile") {
            minWidth = "unset";
        } else if (AppClientInfo.clientInfo.viewMode === "pad") {
            minWidth = 400;
        }
        return <FtPanel style={{minWidth: minWidth}}>
            <FtRow cellWidthS={"1,2"}>
                <FtRow>
                    <IconButton onClick={() => {this.onLoadData()}}>
                        <Refresh/>
                    </IconButton>
                    <IconButton onClick={() => {this.onClickAdd()}}>
                        <AddIcon/>
                    </IconButton>
                </FtRow>
                <div style={{flexGrow: 1, textAlign: "right"}}>
                    <Input defaultValue="" startAdornment={
                        <InputAdornment position="end" onClick={this.onClickSearch}>
                            <SearchIcon/>
                        </InputAdornment>
                    } onChange={this.onChangeKeyword} onCompositionStart={this.onCompositionStart} onCompositionEnd={this.onCompositionEnd}/>
                </div>
            </FtRow>
            <FtPanel>
                <JtPaginationComp onChange={this.onChangePage}  pageNo={this.state.pagedList.number} pageSize={this.state.pagedList.size}
                                  totalPageCount={this.state.pagedList.totalPages} />
                <FtRow cellWidthS={"1,1"} cellWidthM={"1,1,1,1"}>
                    {this.state.pagedList.content.map((userMedia: JtUserMedia, index: number) => {
                        return <FssUserMediaComp label={userMedia.mediaName} mediaUrl={userMedia.mediaUrl}
                                                 style={{width: "100%", height: 0, paddingBottom: "100%", minHeight: "unset", minWidth: "unset"}}
                                                 id={userMedia.id}
                                                 selected={this.isSelectedItem(userMedia)}
                                                 onClickRemove={this.onClickRemove} onClickImage={() => {
                            if (this.props.onClickItem) {
                                this.props.onClickItem(userMedia);
                            } else {
                                this.jtUserMediaDetailDialog.show(userMedia, () => {
                                    this.onLoadData();
                                });
                            }
                        }} />
                    })}
                </FtRow>
            </FtPanel>
            <FssUserMediaDetailDialog ref={(element: any) => {
                this.jtUserMediaDetailDialog = element;
            }} />
        </FtPanel>;
    }

}
