import FtApiResponse from "../model/FtApiResponse";
import FtPagedList from "../model/FtPagedList";
import FssCategory from "../model/FssCategory";
import FssHttpService from "./FssHttpService";

export default class FssCategoryService {

    static getRootCategory(successFunction: (data: FssCategory) => void): void {
        FssHttpService.get("/api/category/getRootCategory", {}, successFunction);
    }
    static getRootCategoryWebSite(sellerId:string,successFunction: (data: FssCategory) => void): void {
        FssHttpService.get("/api/category/getRootCategoryWebSite", {sellerId:sellerId}, successFunction);
    }
    static getStoreCategoryList(searchKeyword: string, pageNo: number, pageSize: number, status: string, successFunction: (result: FtPagedList<FssCategory>) => void) {
        FssHttpService.get("/api/category/getStoreCategoryList", {
            searchKeyword: searchKeyword,
            page: pageNo,
            size: pageSize,
            status: status
        }, successFunction, FssHttpService.handleError);
    }
    static getCategoryById(categoryId: string, successFunction: (result: FssCategory) => void) {
        FssHttpService.get("/api/category/getCategoryById", {categoryId: categoryId}, successFunction);
    }
    static saveCategory(data: FssCategory, successFunction: (data: FssCategory) => void): void {
        FssHttpService.postJson("/api/category/saveCategory", data, successFunction, FssHttpService.handleError);
    }
    static deleteCategory(idList: Array<string>, successFunction: (data: FtApiResponse) => void): void {
        FssHttpService.postJson("/api/category/deleteCategory", idList, successFunction, FssHttpService.handleError);
    }
    static getCategoryListByIds(categoryIds: Array<string>, successFunction: (categoryList: Array<FssCategory>) => void) {
        FssHttpService.postFormData("/api/category/getCategoryListByIds", {categoryIds: categoryIds}, successFunction);
    }
    static getCategoryByProductTag(marketingEventId: string, successFunction: (categoryList: Array<FssCategory>) => void) {
        FssHttpService.postFormData("/api/category/getCategoryByProductTag", {marketingEventId: marketingEventId}, successFunction);
    }
}
