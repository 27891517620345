import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {RouteComponentProps} from "react-router-dom";
import {ChevronLeft, DeleteOutline, Save} from "@material-ui/icons";
import FtToolbar from "../ui/FtToolbar";
import FtDivFlex from "../ui/FtDivFlex";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import {Breadcrumbs, Drawer, MenuItem} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import FssComponentPanel from "./FssComponentPanel";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import lodash from "lodash";
import FtColorInput from "../ui/FtColorInput";
import {i18n} from "../util/I18n";
import FssPage, {EScreenType, EStatus} from "../model/FssPage";
import FssPageComponent from "../model/FssPageComponent";
import FssComponent, {EComponentType} from "../model/FssComponent";
import FssPageRender from "../component/FssPageRender";
import FssComponentEditor from "../component/editor/FssComponentEditor";
import FssNavigationCompData from "../page/component/data/FssNavigationCompData";
import FssPageService from "../service/FssPageService";
import FssClientData from "../data/FssClientData";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import FssImageListCompData from "./component/data/FssImageListCompData";

declare const WEBSITE_URL: string;

interface FssPageDetailPageProp extends RouteComponentProps<{ pageId: string }> {
}

export class FssPageDetailPageState {
    fssPage: FssPage = new FssPage();
    showDrawer: boolean = true;
    selectedPageComponentIndex: number = -1;
    selectPageComponent?: FssPageComponent;
    openMessageBoxDialog: boolean = false;
    pageRenderDraggableFlag: boolean = true;
}


export default class FssPageDetailPage extends React.Component<FssPageDetailPageProp, FssPageDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            fssPage: new FssPage(),
            showDrawer: true,
            selectedPageComponentIndex: -1,
            openMessageBoxDialog: false,
            pageRenderDraggableFlag: true
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.load = this.load.bind(this);
        this.onInsertComponent = this.onInsertComponent.bind(this);
        this.onClickPageComponent = this.onClickPageComponent.bind(this);
        this.onChangePageComponent = this.onChangePageComponent.bind(this);
        this.onChangePage = this.onChangePage.bind(this);
        this.onRemoveComponent = this.onRemoveComponent.bind(this);
        this.getMessageBoxDialog = this.getMessageBoxDialog.bind(this);
        this.pageComponentList = this.pageComponentList.bind(this);
        this.onChangeDraggableFlag = this.onChangeDraggableFlag.bind(this);
        this.getPageSettingEditor = this.getPageSettingEditor.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        if (this.props.match.params.pageId != null && this.props.match.params.pageId.length > 0) {
            FssPageService.getPageByIdAdmin(this.props.match.params.pageId, (result: FssPage) => {
                const page: FssPage = FtUtil.mergeObject(new FssPage(), result);
                this.setState({fssPage: page});
            });
        }
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(fssPage: FssPage) {
        this.setState({fssPage: fssPage});
    }

    onChangePage(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        FtUtil.setProperty(this.state.fssPage, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssPageService.savePage(this.state.fssPage,
            (data: FssPage) => {
                this.setState({fssPage: FtUtil.mergeObject(this.state.fssPage, data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功"));
                this.props.history.replace("/pageDetail/" + data.id);
            });
    }

    render() {
        const page = this.state.fssPage;
        return <div>
            <FtToolbar leftNode={<div>
                <Button color="default" onClick={this.onCancelClick}><ChevronLeft/>{i18n("返回")}</Button>
                <Button color="primary" onClick={this.onClickSave}><Save/>{i18n("保存")}</Button>
                <a href={WEBSITE_URL + this.state.fssPage.sellerId + "/page" + this.state.fssPage.pageUri}
                   target={"_blank"}>
                    <Button>预览</Button>
                </a>
            </div>} />
            <FtDivFlex alignItems={"flex-start"}
                       style={{paddingRight: this.state.showDrawer ? 208 : 8, flexFlow: "row"}}>
                <FtPanel marginHorizontal={8}
                         style={{
                             width: "30%",
                             overflow: "scroll",
                             height: document.documentElement.clientHeight - 96
                         }}>
                    <FssPageRender fssPage={this.state.fssPage} onClickPageComponent={this.onClickPageComponent}
                                   pageComponentList={this.state.fssPage.pageComponentList}
                                   onPageComponentList={this.pageComponentList}
                                   editMode={true} selectedIndex={this.state.selectedPageComponentIndex}
                                   history={this.props.history}
                                   pageRenderDraggableFlag={this.state.pageRenderDraggableFlag}
                                   match={this.props.match} location={this.props.location} />
                </FtPanel>
                <div style={{
                    flexGrow: 2,
                    width: "70%",
                    overflow: "scroll",
                    height: document.documentElement.clientHeight - 96
                }}>
                    <FtRow style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                        <Breadcrumbs aria-label="breadcrumb">
                            <Button onClick={() => {
                                this.setState({selectedPageComponentIndex: -1, selectPageComponent: undefined})
                            }}>{i18n("页面")}</Button>
                            {this.state.selectPageComponent &&
                            <Button>{i18n("组件")}：{this.state.selectPageComponent?.component?.componentName}</Button>}
                        </Breadcrumbs>
                        {this.state.selectPageComponent &&
                        <Button color="default" onClick={() => {
                            this.setState({openMessageBoxDialog: true});
                        }}><DeleteOutline/>{i18n("移除组件")}</Button>}
                    </FtRow>
                    {this.state.selectPageComponent &&
                    <FssComponentEditor pageComponent={this.state.selectPageComponent}
                                        onChangeDraggableFlag={this.onChangeDraggableFlag}
                                        onChange={this.onChangePageComponent}
                                        index={this.state.selectedPageComponentIndex}
                                        key={this.state.selectPageComponent?.createDatetimeText} />}
                    {!this.state.selectPageComponent && this.getPageSettingEditor(page)}
                </div>
            </FtDivFlex>
            <Drawer anchor={"right"} variant={"persistent"} open={this.state.showDrawer}
                    PaperProps={{
                        style: {
                            top: 80,
                            backgroundColor: grey[800],
                            color: grey[50],
                            width: 200,
                            boxSizing: "border-box"
                        }
                    }}>
                <FssComponentPanel onInsertComponent={this.onInsertComponent} />
            </Drawer>
            {this.getMessageBoxDialog()}
        </div>;
    }

    private getPageSettingEditor(page: FssPage) {
        return <FtPanel panelHeader={i18n("页面属性 (ID: {0})",this.state.fssPage.id)}>
            <FtGrid>
                <FtRow cellWidthM={"2,2,1,1"} cellWidthS={"1"} cellHorizontalSpace={8}
                       cellHorizontalAlign={"left"}>
                    <JtTextField id={"pageUri"} label={i18n("页面地址URI")} value={page.pageUri}
                                 style={{width: "100%"}} onChange={this.onChangePage} />
                    <JtTextField id={"template"} label={i18n("页面模板")} value={page.template}
                                 style={{width: "100%"}} onChange={this.onChangePage} />
                    <JtTextField id="screenType" select label={i18n("适用屏幕")} value={page.screenType}
                                 style={{width: "100%"}} onChange={(event) => {
                        FtUtil.setProperty(this.state.fssPage, "screenType", event.target.value);
                        this.forceUpdate();
                    }}>
                        <MenuItem key={EScreenType.MOBILE} value={EScreenType.MOBILE}> {i18n("收件人手机")} </MenuItem>
                        <MenuItem key={EScreenType.PC} value={EScreenType.PC}> {i18n("电脑")} </MenuItem>
                    </JtTextField>
                    <JtTextField id="status" select label={i18n("状态")} value={page.status}
                                 style={{width: "100%"}} onChange={(event) => {
                        FtUtil.setProperty(this.state.fssPage, "status", event.target.value);
                        this.forceUpdate();
                    }}>
                        <MenuItem key={EStatus.NORMAL} value={EStatus.NORMAL}> {i18n("启用")} </MenuItem>
                        <MenuItem key={EStatus.STOP} value={EStatus.STOP}> {i18n("禁用")} </MenuItem>
                    </JtTextField>
                </FtRow>
                <FtRow cellWidthM={"1"}>
                    <JtTextField id={"title"} label={i18n("标题")} value={page.title}
                                 style={{width: "100%"}} onChange={this.onChangePage} endAdornment={this.getTranslationIcon("title", page.title)}/>
                </FtRow>
                <FtRow cellWidthS={"1,1"}>
                    <FtColorInput label={i18n("背景颜色")} backgroundColor={page.pageData.backgroundColor}
                                  onChangeColor={(color?: string) => {
                                      if (color)
                                          page.pageData.backgroundColor = color;
                                      else
                                          page.pageData.backgroundColor = "";
                                      this.forceUpdate();
                                  }} />
                </FtRow>
            </FtGrid>
        </FtPanel>;
    }
    getTranslationIcon(itemKey: string, defaultText: string, rows?: number): ReactNode {
        if (this.state.fssPage.id.length > 0) {
            let appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite && appWebsite.supportLanguages !== null &&appWebsite.supportLanguages.length>0) {
                return <FssTranslationDialogComp objectId={this.state.fssPage.id}
                                                 groupKey={"fssPage"} itemKey={itemKey}
                                                 rows={rows}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
    private onChangeDraggableFlag(draggableFlag: boolean) {
        this.setState({pageRenderDraggableFlag: draggableFlag});
    }

    private onRemoveComponent() {
        this.state.fssPage.pageComponentList.splice(this.state.selectedPageComponentIndex, 1);
        let selectedPageComponentIndex = this.state.selectedPageComponentIndex - 1;
        if (selectedPageComponentIndex == -1 && this.state.fssPage.pageComponentList.length > -1) {
            selectedPageComponentIndex = 0;
        }
        let selectPageComponent = this.state.fssPage.pageComponentList[selectedPageComponentIndex];
        this.setState({
            selectedPageComponentIndex: selectedPageComponentIndex,
            selectPageComponent: selectPageComponent
        });
    }

    private onInsertComponent(sourcComp: FssComponent, reference?: boolean) {
        // 初始化导航组件的属性
        if(sourcComp.componentType==EComponentType.NAVIGATION){
            let selectedIndex: number = 0;
            if(this.state.fssPage&&this.state.fssPage.pageUri){
                if(this.state.fssPage.pageUri == "/index"){
                    selectedIndex=0;
                } else if(this.state.fssPage.pageUri == "/category"){
                    selectedIndex=1;
                } else if(this.state.fssPage.pageUri == "/myAccount/home"){
                    selectedIndex=2;
                }
            }
            let navigationCompData = new FssNavigationCompData();
            navigationCompData.selectedIndex = selectedIndex;
            navigationCompData.size = "medium";
            navigationCompData.position = "bottom";
            sourcComp.data = navigationCompData;
        }
        if (sourcComp.componentType == EComponentType.IMAGE_LIST) {
            let imageListComp = new FssImageListCompData();
            imageListComp.likeMobile = true;
            sourcComp.data = imageListComp;
        }
        const pageComponent: FssPageComponent = new FssPageComponent(this.state.fssPage.id, sourcComp);
        pageComponent.createDatetimeText = "" + new Date().getTime();
        if (this.state.selectedPageComponentIndex < 0) {
            this.state.fssPage.pageComponentList.splice(0, 0, pageComponent);
            this.setState({selectedPageComponentIndex: 0, selectPageComponent: pageComponent});
        } else {
            this.state.fssPage.pageComponentList.splice(this.state.selectedPageComponentIndex, 0, pageComponent);
            this.setState({selectPageComponent: pageComponent});
        }
    }

    private onClickPageComponent(pageComponent: FssPageComponent, index?: number) {
        this.setState({selectedPageComponentIndex: index != null ? index : -1, selectPageComponent: pageComponent});
    }

    private pageComponentList(pageComponentList: Array<FssPageComponent>) {
        this.state.fssPage.pageComponentList = pageComponentList;
        const index = lodash.findIndex(pageComponentList, (item: { id: string; }) => item.id === this.state.selectPageComponent?.id);
        this.setState({fssPage: this.state.fssPage, selectedPageComponentIndex: index != null ? index : -1});
    }

    private onChangePageComponent(pageComponent: FssPageComponent, index?: number) {
        if (index)
            this.state.fssPage.pageComponentList[index] = pageComponent;
        this.setState({selectedPageComponentIndex: index != null ? index : -1, selectPageComponent: pageComponent});
    }

    private getMessageBoxDialog(): ReactNode {
        return <Dialog open={this.state.openMessageBoxDialog} onClose={(e) => {
            this.setState({openMessageBoxDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("确认")}</DialogTitle>
            <DialogContent>
                {i18n("确定要删除")}『{this.state.selectPageComponent?.component?.componentName}』{i18n("吗?")}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                }}>{i18n("取消")}</Button>
                <Button variant="outlined" color="primary" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                    this.onRemoveComponent();
                }}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }
}
