import * as React from "react";
import {BottomNavigation, BottomNavigationAction, Typography} from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import Publish from "@material-ui/icons/Publish";
import CategoryIcon from "@material-ui/icons/ViewModule";
import {AccountCircle} from "@material-ui/icons";
import FtComponent, {FtComponentState} from "../../ui/FtComponent";
import {i18n} from "../../util/I18n";
import FtAppBar from "../../ui/FtAppBar";


interface FssNavigationCompProp {
    marketPageTitle?: string;
}

export class FssNavigationCompState extends FtComponentState {

}

export default class FssNavigationComp extends FtComponent<FssNavigationCompProp, FssNavigationCompState> {
    constructor(props: any) {
        super(props);
        this.state = {

        };
    }

    componentDidMount(): void {

    }

    render() {
        return <BottomNavigation showLabels color={"primary"} style={{marginTop: 90}}>
            <BottomNavigationAction
                label={<Typography variant={"h6"} color={"primary"}>{i18n("首页")}</Typography>}
                icon={<HomeIcon color={"primary"}/>}
                style={{minWidth: 64}} color={"primary"}/>
            <BottomNavigationAction
                label={<Typography variant={"h6"} color={"primary"}>{i18n("分类")}</Typography>}
                icon={<CategoryIcon color={"primary"}/>}
                style={{minWidth: 64}}/>
            {this.props.marketPageTitle != null && this.props.marketPageTitle.trim().length > 0 &&
            <BottomNavigationAction
                label={<Typography variant={"h6"}
                                   color={"primary"}>{i18n(this.props.marketPageTitle.trim())}</Typography>}
                icon={<Publish color={"primary"}/>}
                style={{minWidth: 64}}/>}
            <BottomNavigationAction
                label={<Typography variant={"h6"} color={"primary"}>{i18n("我的")}</Typography>}
                icon={<AccountCircle color={"primary"}/>}
                style={{minWidth: 64}}/>
        </BottomNavigation>
    }
}
