import FssAdvert, {EPosition} from "../model/FssAdvert";
import FssHttpService from "./FssHttpService";
import FtPagedList from "../model/FtPagedList";

export default class FssAdvertService{
    static getByPosition(sellerId:string,position:EPosition,success:(data:Array<FssAdvert>)=>void){
        FssHttpService.get("/api/showSpot/getByPosition", {sellerId:sellerId,position:position}, success, FssHttpService.handleError);
    }
    static getShowSpotList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                           pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssAdvert>) => void) {
        FssHttpService.search("/api/showSpot/getShowSpotList", searchKeyword, filterColumns, null, null, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static getShowSpotById(id: string, successFunction: (data: FssAdvert) => void): void {
        FssHttpService.get("/api/showSpot/getShowSpotById", { id: id }, successFunction, FssHttpService.handleError);
    }

    static deleteShowSpot(idList: Array<string>, successFunction: (data: string) => void): void {
        FssHttpService.postJson("/api/showSpot/deleteShowSpot", idList, successFunction, FssHttpService.handleError);
    }

    static saveShowSpot(data: FssAdvert, successFunction: (data: FssAdvert) => void): void {
        FssHttpService.postJson("/api/showSpot/saveShowSpot", data, successFunction, FssHttpService.handleError);
    }
}
