import FtPagedList from "../model/FtPagedList";
import FssProduct from "./FssProduct";
import FssSeller from "./FssSeller";
import FssShoppingCartItem from "./FssShoppingCartItem";
import FssPublisher from "./FssPublisher";

export enum EType {
    JIE_LONG = "JIE_LONG", LUCKY_SPIN = "LUCKY_SPIN"
}

export enum EStatus {
    NORMAL = "NORMAL", DISABLED = "DISABLED", PENDING = "PENDING"
}

export default class FssMarketingEvent {
    id = "";
    sellerId: string="";
    storeId: string="";
    name: string="";
    title: string="";
    description: string="";
    coverImageUrl: string="";
    status: EStatus=EStatus.NORMAL;
    type: EType=EType.JIE_LONG;
    createDatetime: string="";
    modifiedDatetime: string="";
    startDatetime: string="";
    endDatetime: string="";
    endDatetimeCountdown: number = 0;
    eventPageUri: string="";
    productTag: string="";
    expired:boolean=false;
    started:boolean=true;
    timeLimited:boolean=false;
    viewsCount: number = 0;
    orderCount: number = 0;
    userId: string = "";
    seller?: FssSeller;
    parentMarketingEventSeller?: FssSeller;
    productPaged?: FtPagedList<FssProduct>;
    timeSpanDesc: string = "";
    checkoutSeparate: boolean = false;
    parentMarketingEventId: string = "";
    deliveryProviderStatus: boolean = false;
    publishToStore: boolean = true;
    shippingDate: string = "";
    shippingFormatDate: string = "";
    shoppingCartItemList:Array<FssShoppingCartItem>=[];
    //
    publicToPublisher: boolean = false;
    publisherCommissionRate: number = 0;
    instructionsForPublisher : string = "";
    publisher: FssPublisher = new FssPublisher();
}
