import * as React from "react";
import {CSSProperties} from "react";
import {RouteComponentProps} from "react-router-dom";
import FtTabPanel from "../ui/FtTabPanel";
import {i18n} from "../util/I18n";
import FtTabs from "../ui/FtTabs";
import FtState from "../ui/FtState";
import {Typography} from "@material-ui/core";
import FtPage from "../ui/FtPage";
import FssSellerStatementListPage from "./FssSellerStatementListPage";
import FssPaymentTransactionDailyListPage from "./FssPaymentTransactionDailyListPage";
import FssPaymentTransactionMonthlyListPage from "./FssPaymentTransactionMonthlyListPage";

export enum EPageTab {
    STORE_STATEMENT = "STORE_STATEMENT",
    PAYMENT_TRANSACTION_DAILY = "PAYMENT_TRANSACTIONDAILY",
    PAYMENT_TRANSACTION_MONTHLY = "PAYMENT_TRANSACTION_MONTHLY",
}

interface FssPaymentTransactionPageProp extends RouteComponentProps{
}

class FssPaymentTransactionPageState{
    tabValue: string = "";
}

export default class FssPaymentTransactionPage extends React.Component<FssPaymentTransactionPageProp, FssPaymentTransactionPageState> {
    stateKey = "FssPaymentTransactionPageState";
    constructor(props: any) {
        super(props);
        let tabValue = FtState.get(this.stateKey);
        if (tabValue != null) {
            this.state = {tabValue: tabValue};
        } else {
            this.state = {tabValue: EPageTab.STORE_STATEMENT};
            FtState.put(this.stateKey, EPageTab.STORE_STATEMENT);
        }
    }

    componentDidMount(): void {

    }

    render() {
        const styles: CSSProperties = {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            backgroundColor: "#d5d5d5",
            color: "black"
        };
        const selectedStyles: CSSProperties = {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            backgroundColor: "white",
        };
        return <FtPage onCancelClick={()=>{this.props.history.goBack()}}
                       toolbarLeftNode={<Typography variant="h6">{i18n("报表")}</Typography>}>
            <FtTabs value={this.state.tabValue}
                    indicatorColor="primary" textColor="primary"
                    onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                        this.setState({tabValue: newValue});
                        FtState.put(this.stateKey, newValue);
                    }}>
                <FtTabPanel style={this.state.tabValue == EPageTab.STORE_STATEMENT ? selectedStyles:styles}
                            label={i18n("交易明细")} value={EPageTab.STORE_STATEMENT}>
                    <FssSellerStatementListPage/>
                </FtTabPanel>
                <FtTabPanel style={this.state.tabValue == EPageTab.PAYMENT_TRANSACTION_DAILY ? selectedStyles:styles}
                            label={i18n("按日汇总")} value={EPageTab.PAYMENT_TRANSACTION_DAILY}>
                    <FssPaymentTransactionDailyListPage/>
                </FtTabPanel>
                <FtTabPanel style={this.state.tabValue == EPageTab.PAYMENT_TRANSACTION_MONTHLY ? selectedStyles:styles}
                            label={i18n("按月汇总")} value={EPageTab.PAYMENT_TRANSACTION_MONTHLY}>
                    <FssPaymentTransactionMonthlyListPage/>
                </FtTabPanel>
            </FtTabs>
        </FtPage>;
    }
}
