import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {RouteComponentProps} from "react-router-dom";
import {Add, Delete, Save} from "@material-ui/icons";
import {Chip, FormLabel, TextField} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import FtComboboxDynamic from "../ui/FtComboboxDynamic";
import FssProduct from "../model/FssProduct";
import FssBrand from "../model/FssBrand";
import FssCategory from "../model/FssCategory";
import FssUserMediaComp from "../userMedia/FssUserMediaComp";
import FssUserMedia from "../model/FssUserMedia";
import FssUserMediaSelectDialog from "../userMedia/FssUserMediaSelectDialog";
import FtTextFieldPercent from "../ui/FtTextFieldPercent";
import {i18n} from "../util/I18n";
import ChipInput from "material-ui-chip-input";
import _ from "lodash";
import FtPage from "../ui/FtPage";
import {EPaymentType} from "../model/FssOrder";
import FssCoupon, {EOrderChannel, EStatus} from "../model/FssCoupon";
import FssClientData from "../data/FssClientData";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import FssCouponService from "../service/FssCouponService";
import FssProductService from "../service/FssProductService";
import FtRegion from "../model/FtRegion";

interface JtCouponDetailPageProp extends RouteComponentProps<{ couponId: string }> {
}

export class JtCouponDetailPageState {
    fssCoupon: FssCoupon = new FssCoupon();
    brandList: Array<FssBrand> = new Array<FssBrand>();
    brandSelectOptions: Array<FssBrand> = new Array<FssBrand>();
    categoryList: Array<FssCategory> = new Array<FssCategory>();
    categorySelectOptions: Array<FssCategory> = new Array<FssCategory>();
    productList: Array<FssProduct> = new Array<FssProduct>();
    productSelectOptions: Array<FssProduct> = new Array<FssProduct>();

    countryList: Array<FtRegion> = new Array<FtRegion>();
    provinceList: Array<FtRegion> = new Array<FtRegion>();
    cityList: Array<FtRegion> = new Array<FtRegion>();
    allCountry: boolean = false; //所有国家，后面需要用到这个判断是否不可点击
    allProvince: boolean = false;//所有省份，后面需要用到这个判断是否不可点击
    allCity: boolean = false;//所有城市，后面需要用到这个判断是否不可点击
    selectCountry: Array<string> = new Array<string>();// 选中的结果
    selectProvince: Array<string> = new Array<string>();// 选中的结果
    selectCity: Array<string> = new Array<string>();// 选中的结果
    startDatetimeLabel: string = "";
    endDatetimeLabel: string = "";
    loadingBrandList: boolean = false;
    loadingCategoryList: boolean = false;
}


export default class FssCouponDetailPage extends React.Component<JtCouponDetailPageProp, JtCouponDetailPageState> {
    all: string = "ALL";
    jtUserMediaSelectDialog!: FssUserMediaSelectDialog;

    constructor(props: any) {
        super(props);
        let jtconpon = new FssCoupon();
        this.state = {
            fssCoupon: jtconpon,
            brandList: new Array<FssBrand>(),
            brandSelectOptions: new Array<FssBrand>(),
            categoryList: new Array<FssCategory>(),
            categorySelectOptions: new Array<FssCategory>(),
            productList: new Array<FssProduct>(),
            productSelectOptions: jtconpon.productList,

            countryList: new Array<FtRegion>(),
            allCountry: false,
            allProvince: false,
            allCity: false,
            provinceList: new Array<FtRegion>(),
            cityList: new Array<FtRegion>(),
            selectCountry: new Array<string>(),
            selectProvince: new Array<string>(),
            selectCity: new Array<string>(),
            startDatetimeLabel: "",
            endDatetimeLabel: "",
            loadingBrandList: false,
            loadingCategoryList: false,
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChangeProductInput = this.onChangeProductInput.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.load = this.load.bind(this);
        this.getbrandNames = this.getbrandNames.bind(this);
        this.getCategoryIds = this.getCategoryIds.bind(this);
        this.onClickImage = this.onClickImage.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
    }

    componentDidMount(): void {
        let countryList = new Array<FtRegion>();
        this.setState({countryList: countryList});
        this.load();
    }

    //获取详情
    load() {
        if (this.props.match.params.couponId != null && this.props.match.params.couponId != "0") {
            FssCouponService.getJtCouponById(this.props.match.params.couponId, (result: FssCoupon) => {
                let fssCoupon: FssCoupon = FtUtil.mergeObject(this.state.fssCoupon, result);

                let startDatetimeLabel = i18n("领取开始时间");
                let endDatetimeLabel = i18n("领取结束时间");
                if (fssCoupon.howToUse == "SYSTEM" || fssCoupon.howToUse == "COUPONCODE") {
                    startDatetimeLabel = i18n("开始时间");
                    endDatetimeLabel = i18n("结束时间");
                }

                let allCountry = false;
                let allProvince = false;
                let allCity = false;
                let selectCountry = new Array<string>();
                let selectProvince = new Array<string>();
                let selectCity = new Array<string>();
                let provinceList = new Array<FtRegion>();
                let cityList = new Array<FtRegion>();
                if (fssCoupon.country.toUpperCase() == this.all) {
                    allCountry = true;
                } else {
                    if (fssCoupon.province.toUpperCase() == this.all) {
                        allProvince = true;
                    } else {
                        if (fssCoupon.city.toUpperCase() == this.all) {
                            allCity = true;
                        } else {
                            selectCity = fssCoupon.city.split(",");
                        }
                        selectProvince = fssCoupon.province.split(",");
                    }
                    selectCountry = fssCoupon.country.split(",");
                    //
                    if (this.state.countryList) {
                        this.state.countryList.forEach((region: FtRegion, index: number) => {
                            if (region.subItems && selectCountry.indexOf(region.name) != -1) {
                                region.subItems.forEach((childRegion: FtRegion, childIndex: number) => {
                                    if (index == 0 && childIndex == 0) {// index == 0 表示中国的
                                        return;
                                    }
                                    if (selectProvince.indexOf(childRegion.code) != -1 && childRegion.subItems) {
                                        childRegion.subItems.forEach((child2Region: FtRegion) => {
                                            cityList.push(child2Region);
                                        });
                                    }
                                    provinceList.push(childRegion);
                                });
                            }
                        });
                    }
                }
                this.setState({
                    fssCoupon: fssCoupon,
                    brandSelectOptions: fssCoupon.brandList,
                    categorySelectOptions: fssCoupon.categoryList,
                    productSelectOptions: fssCoupon.productList,

                    selectCountry: selectCountry,
                    selectProvince: selectProvince,
                    selectCity: selectCity,
                    provinceList: provinceList,
                    cityList: cityList,
                    allCountry: allCountry,
                    allProvince: allProvince,
                    allCity: allCity,
                    startDatetimeLabel: startDatetimeLabel,
                    endDatetimeLabel: endDatetimeLabel
                });
            });
        } else {
            FssProductService.getStoreTagsList((result: Array<string>) => {
                this.state.fssCoupon.defaultTagsList = result;
                this.forceUpdate();
            });
        }

    }

    handleAddChip(chip: string) {
        let index = this.state.fssCoupon.tagsList.indexOf(chip);
        if (index == -1) {
            this.state.fssCoupon.tagsList.push(chip);
            this.forceUpdate();
        }
    }

    handleDeleteChip(chip: string) {
        let index = this.state.fssCoupon.tagsList.indexOf(chip);
        if (index > -1) {
            this.state.fssCoupon.tagsList.splice(index, 1)
            this.forceUpdate();
        }

    }

    //返回
    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.fssCoupon, event.target.id, event.target.value);
        this.forceUpdate();
    }

    //保存详情
    onClickSave() {
        if (this.state.allCountry) {
            FtUtil.setProperty(this.state.fssCoupon, "country", this.all);
            FtUtil.setProperty(this.state.fssCoupon, "province", this.all);
            FtUtil.setProperty(this.state.fssCoupon, "city", this.all);
        } else {
            if (this.state.allProvince) {
                FtUtil.setProperty(this.state.fssCoupon, "province", this.all);
                FtUtil.setProperty(this.state.fssCoupon, "city", this.all);
            } else {
                if (this.state.allCity) {
                    FtUtil.setProperty(this.state.fssCoupon, "city", this.all);
                } else {
                    let city = "";
                    this.state.selectCity.forEach((value: string, index: number) => {
                        let endWith = index == 0 ? "" : ",";
                        city += endWith + value;
                    })
                    FtUtil.setProperty(this.state.fssCoupon, "city", city);
                }
                let province = "";
                this.state.selectProvince.forEach((value: string, index: number) => {
                    let endWith = index == 0 ? "" : ",";
                    province += endWith + value;
                })
                FtUtil.setProperty(this.state.fssCoupon, "province", province);
            }
            let country = "";
            this.state.selectCountry.forEach((value: string, index: number) => {
                let endWith = index == 0 ? "" : ",";
                country += endWith + value;
            })
            FtUtil.setProperty(this.state.fssCoupon, "country", country);
        }
        if (this.state.fssCoupon.status == "") {
            FtSnackbar.showText(i18n("请选择『状态』"));
            return;
        }
        if (this.state.fssCoupon.couponType == "") {
            FtSnackbar.showText(i18n("请选择『类型』"));
            return;
        }
        if (this.state.fssCoupon.actionType == "") {
            FtSnackbar.showText(i18n("请选择『抵扣金类型』"));
            return;
        }
        if (this.state.fssCoupon.howToUse == "") {
            FtSnackbar.showText(i18n("请选择『使用方式』"));
            return;
        }
        if (this.state.fssCoupon.expireStandard == "") {
            FtSnackbar.showText(i18n("请选择『优惠券有效期标准』"));
            return;
        }
        if (this.state.fssCoupon.howToReceive == "") {
            FtSnackbar.showText(i18n("请选择『如何发放』"));
            return;
        }
        if (this.state.fssCoupon.userAmountLimit <= 0) {
            FtSnackbar.showText(i18n("请填写每个用户限领数量"));
            return;
        }
        if (this.state.fssCoupon.country != "" && this.state.fssCoupon.country != this.all && this.state.fssCoupon.province == "") {
            FtSnackbar.showText(i18n("请选择『省份』"));
            return;
        } else if (this.state.fssCoupon.country != "" && this.state.fssCoupon.country != this.all && this.state.fssCoupon.province != this.all && this.state.fssCoupon.city == "") {
            FtSnackbar.showText(i18n("请选择『城市』"));
            return;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        console.log(this.state.fssCoupon);
        FssCouponService.saveJtCoupon(this.state.fssCoupon, (data: FssCoupon) => {
            this.setState({fssCoupon: FtUtil.mergeObject(this.state.fssCoupon, data)});
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功！"));
            this.onCancelClick();
        });
    }


    onChangeProduct(item: any) {
        this.state.fssCoupon.product = item;
    }


    onChangeProductInput(keyword: string) {
        let brand = this.getbrandNames();
        let category = this.getCategoryIds();
        FssProductService.getAvailProductByBrandAndCategoryList(keyword, 5, (productList: Array<FssProduct>) => {
            this.setState({productList: productList});
        });
    }

    getbrandNames() {
        let brand = "";
        this.state.brandSelectOptions.forEach((jtBrand: FssBrand) => {
            brand += "," + jtBrand.name;
        });
        brand = brand.substring(1);
        return brand;
    }

    getCategoryIds() {
        let categoryIds = "";
        this.state.categorySelectOptions.forEach((jtCategory: FssCategory) => {
            categoryIds += "," + jtCategory.id;
        });
        categoryIds = categoryIds.substring(1);
        return categoryIds;
    }

    onClickImage() {
        this.jtUserMediaSelectDialog.show("single", (userMediaList: Array<FssUserMedia> | null) => {
            if (userMediaList != null && userMediaList.length > 0) {
                this.state.fssCoupon.flyerImageUrl = userMediaList[0].mediaUrl;
                this.forceUpdate();
            }
        });
    }

    render() {
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="contained"
                                                color="primary"
                                                startIcon={<Save/>}
                                                onClick={this.onClickSave}>{i18n("保存")}</Button>}>
            <FtRow cellWidthS={"1"} cellVerticalSpace={16}>
                <FtPanel panelHeader={i18n("基础信息")}>
                    <FtGrid>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            <JtTextField id="couponName" onChange={this.onChange}
                                         value={this.state.fssCoupon.couponName}
                                         label={i18n("优惠券名称")} required={true} style={{width: "100%"}}
                                         endAdornment={this.getTranslationIcon("couponName", this.state.fssCoupon.couponName)}/>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("状态")} </InputLabel>
                                <Select id="status" labelId="demo-simple-select-label"
                                        value={this.state.fssCoupon.status}
                                        onChange={(event, child) => {
                                            this.state.fssCoupon.status = event.target.value as string;
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={EStatus.NORMAL}>{i18n("正常")}</MenuItem>
                                    <MenuItem value={EStatus.STOP}>{i18n("停用")}</MenuItem>
                                </Select>
                            </FormControl>
                            <TextField id="createDatetime" label={i18n("创建时间")}
                                       value={FssClientData.formatDatetime(this.state.fssCoupon.createDatetime)}
                                       style={{width: "100%"}} disabled={true}/>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("优惠券类型")} </InputLabel>
                                <Select id="couponType" labelId="demo-simple-select-label"
                                        value={this.state.fssCoupon.couponType} onChange={(event, child) => {
                                    this.state.fssCoupon.couponType = event.target.value as string;
                                    this.forceUpdate();
                                }} disabled={this.state.fssCoupon.receiveAmount > 0}>
                                    <MenuItem value={"PERCENT"}>{i18n("打折劵")}</MenuItem>
                                    <MenuItem value={"MONEY"}>{i18n("满减劵")}</MenuItem>
                                </Select>
                            </FormControl>
                            {this.state.fssCoupon.couponType == "PERCENT" &&
                            <FtTextFieldPercent id="discount" onChangeValue={(value) => {
                                this.state.fssCoupon.discount = value;
                                this.forceUpdate();
                            }} value={this.state.fssCoupon.discount}
                                                label={i18n("折扣(%)")} required={true} style={{width: "100%"}}
                                                disabled={this.state.fssCoupon.receiveAmount > 0}/>
                            }
                            {this.state.fssCoupon.couponType == "MONEY" &&
                            <JtTextField id="discount" onChange={this.onChange} value={this.state.fssCoupon.discount}
                                         label={i18n("降价(元)")} required={true} style={{width: "100%"}}
                                         disabled={this.state.fssCoupon.receiveAmount > 0}/>
                            }
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("抵扣金额类型")} </InputLabel>
                                <Select id="actionType" labelId="demo-simple-select-label"
                                        value={this.state.fssCoupon.actionType} onChange={(event, child) => {
                                    this.state.fssCoupon.actionType = event.target.value as string;
                                    this.forceUpdate();
                                }} disabled={this.state.fssCoupon.receiveAmount > 0}>
                                    <MenuItem value={"TOTALMONEY"}>{i18n("全部金额")}</MenuItem>
                                    <MenuItem value={"PRODUCTMONEY"}>{i18n("仅限商品金额")}</MenuItem>
                                    <MenuItem value={"SHIPMONEY"}>{i18n("仅限运费")}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{width: "100%"}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("如何发放")} </InputLabel>
                                <Select id="howToReceive" labelId="demo-simple-select-label"
                                        value={this.state.fssCoupon.howToReceive} onChange={(event, child) => {
                                    this.state.fssCoupon.howToReceive = event.target.value as string;
                                    this.forceUpdate();
                                }} disabled={this.state.fssCoupon.receiveAmount > 0}>
                                    {/*<MenuItem value={"USER_ACTIVE"}>用户主动领取</MenuItem>*/}
                                    {/*<MenuItem value={"REGISTER_AFTER"}>注册后发放</MenuItem>*/}
                                    <MenuItem value={"LANDING_PAGE"}>{i18n("着陆页发放")}</MenuItem>
                                    {/*<MenuItem value={"ORDER_PAYMENT"}>订单支付完成发放</MenuItem>*/}
                                    <MenuItem value={"STORE_ADMIN"}>{i18n("商家后台发放")}</MenuItem>
                                    <MenuItem value={"LUCK_DRAW"}>{i18n("抽奖")}</MenuItem>
                                </Select>
                            </FormControl>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            <FormControl style={{minWidth: 200}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("使用方式")} </InputLabel>
                                <Select id="howToUse" labelId="demo-simple-select-label"
                                        value={this.state.fssCoupon.howToUse} onChange={(event, child) => {
                                    this.state.fssCoupon.howToUse = event.target.value as string;
                                    let startDatetimeLabel = i18n("领取开始时间");
                                    let endDatetimeLabel = i18n("领取结束时间");
                                    if (this.state.fssCoupon.howToUse == "SYSTEM" || this.state.fssCoupon.howToUse == "COUPONCODE") {
                                        startDatetimeLabel = i18n("开始时间");
                                        endDatetimeLabel = i18n("结束时间");
                                    }
                                    this.setState({
                                        startDatetimeLabel: startDatetimeLabel,
                                        endDatetimeLabel: endDatetimeLabel
                                    });
                                    this.forceUpdate();
                                }} disabled={this.state.fssCoupon.receiveAmount > 0}>
                                    <MenuItem value={"CHOOSE"}>{i18n("用户选择")}</MenuItem>
                                    {this.state.fssCoupon.howToReceive == 'LANDING_PAGE' &&
                                    <MenuItem value={"SYSTEM"}>{i18n("系统自动匹配")}</MenuItem>}
                                    {this.state.fssCoupon.howToReceive == 'LANDING_PAGE' &&
                                    <MenuItem value={"COUPONCODE"}>{i18n("输入优惠码")}</MenuItem>}
                                </Select>
                            </FormControl>
                            {this.state.fssCoupon.howToUse == "COUPONCODE" &&
                            <JtTextField id="couponCode" onChange={this.onChange}
                                         value={this.state.fssCoupon.couponCode}
                                         label={i18n("优惠券编码")} required={true} style={{width: "100%"}}
                                         disabled={this.state.fssCoupon.receiveAmount > 0}/>
                            }

                            <TextField id="startDatetimeString" type="datetime-local" onChange={this.onChange}
                                       required={true} InputLabelProps={{
                                shrink: true,
                            }} value={this.state.fssCoupon.startDatetimeString} label={this.state.startDatetimeLabel}
                                       style={{width: "100%"}} disabled={this.state.fssCoupon.receiveAmount > 0}/>
                            <TextField id="endDatetimeString" onChange={this.onChange} InputLabelProps={{
                                shrink: true,
                            }} required={true} value={this.state.fssCoupon.endDatetimeString}
                                       label={this.state.endDatetimeLabel} type="datetime-local"
                                       style={{width: "100%"}}/>

                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            {this.state.fssCoupon.howToUse == "CHOOSE" &&
                            <FormControl style={{minWidth: 200}}>
                                <InputLabel id="demo-simple-select-label"> {i18n("使用有效期标准")}</InputLabel>
                                <Select id="howToUse" labelId="demo-simple-select-label"
                                        value={this.state.fssCoupon.expireStandard} onChange={(event, child) => {
                                    this.state.fssCoupon.expireStandard = event.target.value as string;
                                    this.forceUpdate();
                                }} disabled={this.state.fssCoupon.receiveAmount > 0}>
                                    <MenuItem value={"EQUALS"}>{i18n("与母劵有效期一致")}</MenuItem>
                                    <MenuItem value={"DELAY"}>{i18n("领取时间延后")}</MenuItem>
                                </Select>
                            </FormControl>
                            }
                            {this.state.fssCoupon.howToUse == "CHOOSE" && this.state.fssCoupon.expireStandard == "DELAY" &&
                            <TextField id="expireDays" required={true} onChange={this.onChange}
                                       value={this.state.fssCoupon.expireDays ? this.state.fssCoupon.expireDays : ''}
                                       label={i18n("有效期(日)")} type={"number"} style={{width: "100%"}}
                                       disabled={this.state.fssCoupon.receiveAmount > 0}/>
                            }
                            <TextField id="totalAmountLimit" required={true} onChange={this.onChange}
                                       value={this.state.fssCoupon.totalAmountLimit ? this.state.fssCoupon.totalAmountLimit : ''}
                                       label={i18n("总发放数")} type={"number"} style={{width: "100%"}}/>
                            <TextField id="receiveAmount" disabled={true}
                                       value={this.state.fssCoupon.receiveAmount ? this.state.fssCoupon.receiveAmount : ''}
                                       label={i18n("已领取数")} type={"number"} style={{width: "100%"}}/>
                            <TextField id="userAmountLimit" required={true} onChange={this.onChange}
                                       value={this.state.fssCoupon.userAmountLimit ? this.state.fssCoupon.userAmountLimit : ''}
                                       label={i18n("每个用户限领数量")} type={"number"} style={{width: "100%"}}/>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"4"} cellWidthL={"4"} cellHorizontalSpace={32}
                               maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <FormControl style={{minWidth: 600}}>
                                <FssUserMediaComp label={i18n("海报图")} mediaType={"image"}
                                                  style={{width: 300, height: 100}}
                                                  mediaUrl={this.state.fssCoupon.flyerImageUrl}
                                                  onClickRemove={() => {
                                                      this.state.fssCoupon.flyerImageUrl = "";
                                                      this.forceUpdate();
                                                  }}
                                                  onClickImage={this.onClickImage}/>
                            </FormControl>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"4"} cellWidthL={"4"} cellHorizontalSpace={32}
                               maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <JtTextField id="description" multiline onChange={this.onChange}
                                         value={this.state.fssCoupon.description} label={i18n("描述")} type={"text"}
                                         style={{width: "100%"}}
                                         endAdornment={this.getTranslationIcon("description", this.state.fssCoupon.description, 1)}/>
                        </FtRow>
                    </FtGrid>
                </FtPanel>
                <FtPanel panelHeader={i18n("用户条件限制")}>
                    <FtGrid>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            <FormControl style={{minWidth: 200}} disabled={this.state.fssCoupon.receiveAmount > 0}>
                                <InputLabel id="demo-simple-select-label"> {i18n("是否限制新用户")}</InputLabel>
                                <Select id="newUserOnly" labelId="demo-simple-select-label"
                                        value={this.state.fssCoupon.newUserOnly} onChange={(event, child) => {
                                    this.state.fssCoupon.newUserOnly = event.target.value as boolean;
                                    this.forceUpdate();
                                }}>
                                    <MenuItem value={"true"}>{i18n("是")}</MenuItem>
                                    <MenuItem value={"false"}>{i18n("否")}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{minWidth: 200}} disabled={this.state.fssCoupon.receiveAmount > 0}>
                                <InputLabel>{i18n("用户级别")}</InputLabel>
                                <Select
                                    id="user.userLevel" value={this.state.fssCoupon.userLevel}
                                    onChange={(event, child) => {
                                        this.state.fssCoupon.userLevel = event.target.value as number;
                                        this.forceUpdate();
                                    }}>
                                    <MenuItem value={0}>{i18n("无")}</MenuItem>
                                    <MenuItem value={1}>{i18n("一级别")}</MenuItem>
                                    <MenuItem value={2}>{i18n("二级别")}</MenuItem>
                                    <MenuItem value={3}>{i18n("三级别")}</MenuItem>
                                </Select>
                            </FormControl>
                        </FtRow>
                    </FtGrid>
                </FtPanel>
                {this.getOrderConditionPanel()}
                <FtPanel panelHeader={i18n("商品条件限制")}>
                    <FtGrid>
                        <FormLabel component="legend">指定商品名称</FormLabel>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellWidthL={"1,1,1,1,1"}
                               cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            <FtComboboxDynamic label={"请输入名称"} optionItems={this.state.productList}
                                               labelPropertyName={"title"}
                                               valuePropertyName={"productId"}
                                               onSelectItem={this.onChangeProduct}
                                               onChangeInput={this.onChangeProductInput}
                                               loading={false}
                                               style={{minWidth: 200}}/>
                            <Button onClick={() => {
                                let productSelectOptions = this.state.productSelectOptions;
                                if (this.state.fssCoupon.product.title != '') {
                                    let addFlag = true;
                                    productSelectOptions.forEach((product: FssProduct) => {
                                        if (product.id == this.state.fssCoupon.product.id) {
                                            addFlag = false;
                                            return;
                                        }
                                    });
                                    if (addFlag) {
                                        let jtProduct: FssProduct = FtUtil.mergeObject({}, this.state.fssCoupon.product);
                                        productSelectOptions.push(jtProduct);
                                        this.setState({productSelectOptions: productSelectOptions});
                                    } else {
                                        FtSnackbar.showText("此商品已经添加过了");
                                    }
                                }
                            }} color={"primary"} variant="outlined"
                                    disabled={this.state.fssCoupon.receiveAmount > 0}
                            ><Add/>添加</Button>
                        </FtRow>
                        <div style={{width: "100%"}}>
                            {this.state.productSelectOptions != null && this.state.productSelectOptions.length > 0 &&
                            <FormLabel component="legend">已选商品</FormLabel>}
                            {this.state.productSelectOptions.map((product: FssProduct, i: number) => (
                                <FtRow key={i} cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellWidthL={"1,1,1,1,1"}
                                       cellHorizontalSpace={32} maxRowWidth={"l"} cellVerticalSpace={16}>
                                    <div>{product.title}</div>
                                    <Button onClick={() => {
                                        let productSelectOptions = this.state.productSelectOptions;
                                        productSelectOptions.splice(productSelectOptions.indexOf(product), 1);
                                        this.forceUpdate();
                                    }
                                    } variant="outlined"
                                            disabled={this.state.fssCoupon.receiveAmount > 0}
                                    ><Delete/>删除</Button>
                                </FtRow>
                            ))}
                        </div>
                    </FtGrid>
                    <FtGrid>
                        <FtRow cellWidthS={"1"}>
                            <FormLabel component="legend">指定商品标签</FormLabel>
                            <ChipInput label={""} disabled={this.state.fssCoupon.receiveAmount > 0}
                                       variant={"standard"}
                                       placeholder={i18n("请输入标签")}
                                       alwaysShowPlaceholder={true}
                                       value={this.state.fssCoupon.tagsList}
                                       onAdd={(chip) => this.handleAddChip(chip)}
                                       onDelete={(chip, index) => this.handleDeleteChip(chip)}
                                       style={{width: "100%"}}/>
                        </FtRow>
                    </FtGrid>
                    <FtGrid>
                        <FtRow cellHorizontalSpace={16}>
                            {this.state.fssCoupon.defaultTagsList.map((data: string, index: number) => {
                                let targetIndex = -1;
                                if (this.state.fssCoupon.tagsList) {
                                    targetIndex = _.findIndex(this.state.fssCoupon.tagsList, (tag: string) => {
                                        return data == tag;
                                    });
                                }
                                return <Chip color={targetIndex > -1 ? "secondary" : "default"}
                                             label={data}
                                             disabled={this.state.fssCoupon.receiveAmount > 0}
                                             onClick={() => {
                                                 if (targetIndex > -1) {
                                                     this.handleDeleteChip(data);
                                                 } else {
                                                     this.handleAddChip(data);
                                                 }

                                             }}/>;
                            })}
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellWidthL={"1,1,1,1,1"}
                               cellHorizontalSpace={32}
                               maxRowWidth={"l"} cellVerticalSpace={16}>
                            <TextField id="productMoney" onChange={this.onChange}
                                       value={this.state.fssCoupon.productMoney == 0 ? '' : this.state.fssCoupon.productMoney}
                                       label={i18n("指定商品金额要求")} type={"number"} style={{width: "100%"}}
                                       disabled={this.state.fssCoupon.receiveAmount > 0}/>
                        </FtRow>
                    </FtGrid>

                </FtPanel>
            </FtRow>
            <FssUserMediaSelectDialog ref={(element: any) => {
                this.jtUserMediaSelectDialog = element
            }}/>
        </FtPage>;
    }

    private getOrderConditionPanel() {
        return <FtPanel panelHeader={i18n("订单条件限制")}>
            <FtGrid>
                <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellWidthL={"1,1,1,1,1"} cellHorizontalSpace={32}
                       maxRowWidth={"l"} cellVerticalSpace={16}>
                    <FormControl style={{minWidth: 600}} disabled={this.state.fssCoupon.receiveAmount > 0}>
                        <FormLabel> {i18n("支付方式限制")} </FormLabel>
                        <FtCheckboxGroup row={true}
                                         values={[EPaymentType.PAYNICORN, EPaymentType.ONLINE_ACCOUNT_BALANCE]}
                                         labels={[i18n("Paynicorn"), i18n("账号余额")]}
                                         checkedValues={this.state.fssCoupon.paymentType?.split(",")}
                                         onCheck={(checkedValue) => {
                                             this.state.fssCoupon.paymentType = checkedValue.join(",");
                                             this.forceUpdate();
                                         }}>
                        </FtCheckboxGroup>
                    </FormControl>
                </FtRow>
                <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellWidthL={"1,1,1,1,1"} cellHorizontalSpace={32}
                       maxRowWidth={"l"} cellVerticalSpace={16}>
                    <FormControl style={{minWidth: 600}} disabled={this.state.fssCoupon.receiveAmount > 0}>
                        <FormLabel> {i18n("下单渠道限制")} </FormLabel>
                        <FtCheckboxGroup row={true}
                                         values={[EOrderChannel.APP_ANDROID, EOrderChannel.APP_IOS, EOrderChannel.MOBILE_WEB,
                                             EOrderChannel.PC_WEB, EOrderChannel.WECHAT_MINI_PROGRAM, EOrderChannel.WECHAT_OFFICIAL_ACCOUNT, EOrderChannel.ALIPAY_H5]}
                                         labels={[i18n("android应用"), i18n("ios应用"), i18n("手机网页"), i18n("PC网页"), i18n("微信小程序"), i18n("微信公众号"), i18n("支付宝H5")]}
                                         checkedValues={this.state.fssCoupon.orderChannel?.split(",")}
                                         onCheck={(checkedValue) => {
                                             this.state.fssCoupon.orderChannel = checkedValue.join(",");
                                             this.forceUpdate();
                                         }}>
                        </FtCheckboxGroup>
                    </FormControl>
                </FtRow>
                <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellWidthL={"1,1,1,1,1"} cellHorizontalSpace={32}
                       maxRowWidth={"l"} cellVerticalSpace={16}>
                    <TextField id="orderTotalMoney" onChange={this.onChange}
                               value={this.state.fssCoupon.orderTotalMoney == 0 ? '' : this.state.fssCoupon.orderTotalMoney}
                               label={i18n("订单金额要求")} type={"number"} style={{width: "100%"}}
                               disabled={this.state.fssCoupon.receiveAmount > 0}/>
                    <TextField id="orderReferral" onChange={this.onChange} value={this.state.fssCoupon.orderReferral}
                               label={i18n("订单来源")} type={"text"} style={{width: "100%"}}
                               disabled={this.state.fssCoupon.receiveAmount > 0}/>
                </FtRow>
            </FtGrid>
        </FtPanel>
    }

    getTranslationIcon(itemKey: string, defaultText?: string, rows?: number): ReactNode {
        if (this.state.fssCoupon.id && this.state.fssCoupon.id.length > 0) {
            const appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite.supportLanguages != null && appWebsite.supportLanguages.length > 0) {
                return <FssTranslationDialogComp objectId={this.state.fssCoupon.id + ""}
                                                 groupKey={"coupon"} itemKey={itemKey}
                                                 rows={rows}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
