import * as React from "react";
import FtPanel from "../../ui/FtPanel";
import FtGrid from "../../ui/FtGrid";
import FtRow from "../../ui/FtRow";
import JtMceEditor from "../../common/JtMceEditor";
import FssCompHeaderEditor from "./FssCompHeaderEditor";
import FssComponent from "../../model/FssComponent";
import FssHtmlCompData from "../../page/component/data/FssHtmlCompData";

interface FssHtmlCompEditorProp {
    component: FssComponent;
    onChange?:(component:FssComponent)=>void;
}

export class FssHtmlCompEditorState {
}


export default class FssHtmlCompEditor extends React.Component<FssHtmlCompEditorProp, FssHtmlCompEditorState> {
    jtMceEditor!: JtMceEditor;

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onChangeHtml=this.onChangeHtml.bind(this);
        this.onChangeComponent=this.onChangeComponent.bind(this);
    }

    componentDidMount(): void {
    }

    render() {
        const component=this.props.component;
        const htmlCompContent = component.data as FssHtmlCompData;
        return <div>
            <FssCompHeaderEditor component={this.props.component} onChangeComponent={this.onChangeComponent} />
            <FtPanel panelHeader={"HTML"} marginVertical={8}>
                <FtGrid>
                    <FtRow cellWidthM={"1"}>
                        <JtMceEditor ref={(element: any) => {
                            this.jtMceEditor = element;
                        }} onChangeContent={this.onChangeHtml}
                                     initialValue={htmlCompContent.htmlCode}
                                     height={(document.documentElement.clientHeight - 320)+"px"} width={"100%"} />
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </div>

    }
    onChangeHtml(content: string) {
        const htmlCompContent = this.props.component.data as FssHtmlCompData;
        htmlCompContent.htmlCode = content;
        this.onChangeComponent();
    }

    onChangeComponent(){
        if(this.props.onChange)
            this.props.onChange(this.props.component);
    }
}
