import FssTaxRate from "./FssTaxRate";

export enum ETaxNameStatus {
    NORMAL="NORMAL",
    STOP="STOP"
}
export enum ETaxHowToCompute {
    PERCENTAGE="PERCENTAGE",
    FIXED_PER_ITEM="FIXED_PER_ITEM"
}

export default class FssTaxName{
    id:string="";
    country:string="";
    createDatetime:number=0;
    taxName:string="";
    taxNameStatus:ETaxNameStatus=ETaxNameStatus.NORMAL;
    description:string="";
    taxShortName:string="";
    howToCompute:ETaxHowToCompute = ETaxHowToCompute.PERCENTAGE;
    taxRateList?:Array<FssTaxRate>;
}
