import * as React from "react";
import {HTMLAttributes, ReactNode} from "react";
import FssTaxName, {ETaxHowToCompute} from "../model/FssTaxName";
import FtGrid from "../ui/FtGrid";
import FtPanel from "../ui/FtPanel";
import {
    Checkbox,
    CircularProgress,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    SvgIcon
} from "@material-ui/core";
import _ from "lodash";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FssTaxRate from "../model/FssTaxRate";
import FtRow from "../ui/FtRow";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import FtTextFieldPercent from "../ui/FtTextFieldPercent";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import FssTaxNameService from "../service/FssTaxNameService";
import FssCityService from "../service/FssCityService";
import FtRegion from "../model/FtRegion";
import FtToast from "../ui/FtToast";

interface FssProductTaxPanelProp extends HTMLAttributes<HTMLDivElement> {
    onSelectChanged?: (selected: Array<FssTaxName>) => void;
    selectedItems?: Array<FssTaxName>;
}

export class FssProductTaxPanelState {
    taxNameList?: Array<FssTaxName>;
    loading: boolean = false;
    openTaxDetailDialog:boolean = false;
    jtRegionSetting: FtRegion = new FtRegion();
    taxName: FssTaxName=new FssTaxName();
}


export default class FssProductTaxPanel extends React.Component<FssProductTaxPanelProp, FssProductTaxPanelState> {


    constructor(props: any) {
        super(props);
        this.state = {loading: false,openTaxDetailDialog : false, jtRegionSetting: new FtRegion(),taxName: new FssTaxName()};
        this.onClickPercentIcon = this.onClickPercentIcon.bind(this);
        this.getSelectedIndex = this.getSelectedIndex.bind(this);
    }

    componentDidMount(): void {
        this.setState({loading: true});
        FssTaxNameService.getTaxNameValidList("", (data: Array<FssTaxName>) => {
            this.setState({taxNameList: data, loading: false});
        })
    }

    private getSelectedIndex(taxName: FssTaxName): number {
        return _.findIndex(this.props.selectedItems, (item) => {
            return item.id == taxName.id
        });
    }

    private handleToggle(taxName: FssTaxName) {
        const currentIndex: number = this.getSelectedIndex(taxName);
        let newChecked:Array<FssTaxName> =[];
        if(this.props.selectedItems)
           newChecked=[...this.props.selectedItems];
        if (currentIndex === -1) {
            newChecked.push(taxName);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        if (this.props.onSelectChanged)
            this.props.onSelectChanged(newChecked);
    }

    render() {
        return <FtPanel panelHeader={i18n("税")} marginHorizontal={16}
                        marginVertical={16}>
            <FtGrid>
                {this.state.loading && <CircularProgress size={24} thickness={3} color="primary"/>}
                <List>
                    {this.state.taxNameList?.map((taxName: FssTaxName, index: number) => {
                        return <ListItem key={index} dense button onClick={() => {
                            this.handleToggle(taxName)
                        }}>
                            <ListItemIcon>
                                <Checkbox
                                    edge="start"
                                    checked={this.getSelectedIndex(taxName) >= 0}
                                    tabIndex={-1}
                                    disableRipple
                                />
                            </ListItemIcon>
                            <ListItemText primary={taxName.taxName} secondary={taxName.description}/>
                            <ListItemSecondaryAction>

                                <IconButton edge="end" onClick={() => {
                                    this.onClickPercentIcon(taxName)
                                }}>
                                    {taxName.howToCompute == ETaxHowToCompute.PERCENTAGE &&
                                    <SvgIcon>
                                        <path
                                            d={"M18.5 3.5L20.5 5.5L5.5 20.5L3.5 18.5L18.5 3.5M7 4C8.66 4 10 5.34 10 7C10 8.66 8.66 10 7 10C5.34 10 4 8.66 4 7C4 5.34 5.34 4 7 4M17 14C18.66 14 20 15.34 20 17C20 18.66 18.66 20 17 20C15.34 20 14 18.66 14 17C14 15.34 15.34 14 17 14M7 6C6.45 6 6 6.45 6 7C6 7.55 6.45 8 7 8C7.55 8 8 7.55 8 7C8 6.45 7.55 6 7 6M17 16C16.45 16 16 16.45 16 17C16 17.55 16.45 18 17 18C17.55 18 18 17.55 18 17C18 16.45 17.55 16 17 16Z"}></path>
                                    </SvgIcon>
                                    }
                                    {taxName.howToCompute == ETaxHowToCompute.FIXED_PER_ITEM &&
                                    <AttachMoneyIcon />
                                    }
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>;
                    })}
                </List>
            </FtGrid>
            {this.getTaxDetailDialog()}
        </FtPanel>
    }

    private onClickPercentIcon(taxName: FssTaxName) {
        FtToast.showToastLoading("loading");
        // if(taxName.taxName!=null && taxName.country=="CANADA") {
        //     FssCityService.getCanadaRegion((result: FtRegion) => {
        //         FtToast.closeToast();
        //         const jtRegionSetting: FtRegion = FtUtil.mergeObject(new FtRegion(), result);
        //         this.setState({jtRegionSetting: jtRegionSetting});
        //     })
        // }else if(taxName.taxName!=null && taxName.country=="US") {
        //     FtToast.closeToast();
        //     FssCityService.getAmericaRegion((result: FtRegion) => {
        //         const jtRegionSetting: FtRegion = FtUtil.mergeObject(new FtRegion(), result);
        //         this.setState({jtRegionSetting: jtRegionSetting});
        //     })
        // }
        this.setState({openTaxDetailDialog: true,taxName:taxName});
    }
    getTaxDetailDialog(): ReactNode {
        return <Dialog open={this.state.openTaxDetailDialog} onClose={(e) => {
            this.setState({openTaxDetailDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{this.state.taxName.taxName}--{this.state.taxName.howToCompute==ETaxHowToCompute.FIXED_PER_ITEM?"按数量":"按金额百分比"}
            </DialogTitle>
            <DialogContent>
                <div>
                    <FtRow cellWidthS={"1,1,1"}>

                        {
                            this.state.jtRegionSetting.subItems.map((item: FtRegion, index: number) => {
                                let targetIndex = 0;
                                if(this.state.taxName.taxRateList){
                                    targetIndex = _.findIndex(this.state.taxName.taxRateList, (taxRate: FssTaxRate) => {
                                        return taxRate.province == item.code;
                                    });
                                    if(targetIndex<0){
                                        let initialTaxRate = new FssTaxRate();
                                        initialTaxRate.taxRate = 0.0;
                                        initialTaxRate.province = item.code;
                                        initialTaxRate.country = this.state.taxName.country;
                                        targetIndex = this.state.taxName.taxRateList.push(initialTaxRate) - 1;
                                    }

                                }else{
                                    this.state.taxName.taxRateList = new Array<FssTaxRate>();
                                    let initialTaxRate = new FssTaxRate();
                                    initialTaxRate.taxRate = 0.0;
                                    initialTaxRate.province = item.code;
                                    initialTaxRate.country = "";
                                    targetIndex = this.state.taxName.taxRateList.push(initialTaxRate) - 1;

                                }
                                if (this.state.taxName.howToCompute != null && this.state.taxName.howToCompute == ETaxHowToCompute.PERCENTAGE)
                                    return <FtTextFieldPercent id="specificationName" label={item.name}
                                                               value={this.state.taxName.taxRateList[targetIndex].taxRate}
                                                               placeholder={i18n("税率")+":"} type={"float"}
                                                               disabled
                                                               style={{width: "100%"}}/>;
                                else
                                    return <FtTextFieldNumber id="specificationName" label={item.name}
                                                              value={this.state.taxName.taxRateList[targetIndex].taxRate}
                                                              placeholder={i18n("税值")+":"} type={"float"}
                                                              disabled
                                                              style={{width: "100%"}}/>;

                            })
                        }
                    </FtRow>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openTaxDetailDialog: false});
                }}>{i18n("关闭")}</Button>
            </DialogActions>
        </Dialog>
    }

}
