

export default class FssProductSpecification {
    id:string = "";
    product_id: string = "";
    name: string = "";
    value: string = "";


}
