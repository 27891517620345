import * as React from "react";
import FssOrder from "../model/FssOrder";
import FtUtil from "../util/FtUtil";
import FssClientData from "../data/FssClientData";
import FtToast from "../ui/FtToast";
import AppClientInfo from "../data/AppClientInfo";

interface JtOrderPrintPickingListProp {
    order?: FssOrder;
    orderList?: Array<FssOrder>;
    printOrdersSort?: string;
    printOrderGroupBy?: "" | "groupByCourier" | "groupByCount";
    printOrdersCountPerGroup?: number;
    printOrdersFlag?: boolean;

}

class JtOrderPrintPickingListState {
    orderIds: Array<string> = [];
}

export default class FssOrderPrintPickingList extends React.Component<JtOrderPrintPickingListProp, JtOrderPrintPickingListState> {
    constructor(props: any) {
        super(props);
        this.state = {orderIds:new Array()};
    }

    print() {
        const orderIds: Array<string> = [];
        if (this.props.orderList != null && this.props.orderList.length > 0) {
            this.props.orderList.forEach((order: FssOrder, index: number) => {
                orderIds.push(order.id);
            });
        } else if (this.props.order != null && this.props.order.id != null && this.props.order.id.length > 0) {
            orderIds.push(this.props.order.id);
        }
        this.setState({orderIds:orderIds},()=>{
            const form:any=window.document.getElementById("printParamForm");
            if(form)
                form.submit();
        });
    }
    render() {
        return <div>
                <form id={"printParamForm"} target={"_blank"} method={"POST"}
                      action={AppClientInfo.clientInfo.apiServerUrl + "/api/print/printPickingList?"+FssClientData.getData().webSession.dataString}>
                    <input type={"hidden"} name={"printOrdersSort"} value={this.props.printOrdersSort}></input>
                    <input type={"hidden"} name={"printOrderGroupBy"} value={this.props.printOrderGroupBy}></input>
                    <input type={"hidden"} name={"printOrdersCountPerGroup"} value={this.props.printOrdersCountPerGroup}></input>
                    <input type={"hidden"} name={"printOrdersFlag"} value={this.props.printOrdersFlag?"true":"false"}></input>
                    <input type={"hidden"} name={"groupBy"} value={"pickingGroup"}></input>
                    <input type={"hidden"} name={"orderIds"} value={FtUtil.arrayToString(this.state.orderIds)}></input>
                </form>
        </div>;
    }
}
