import FssCategory from "./FssCategory";
import FssShippingFeeTemplate from "./FssShippingFeeTemplate";
import FssTaxName from "./FssTaxName";
import FssSeller from "./FssSeller";
import FssDeliveryProviderPublic from "./FssDeliveryProviderPublic";
import FssProductSpecification from "./FssProductSpecification";
import FssProductDetail from "./FssProductDetail";

export enum EInventoryControlType {
    ALLOW_OVER_SELL = "ALLOW_OVER_SELL",
    OVER_SELL_STOP = "OVER_SELL_STOP",
    OVER_SELL_DISABLE = "OVER_SELL_DISABLE"
}

export enum EProductStatus {
    ENABLE = "ENABLE",
    DISABLE = "DISABLE",
    STOP_SELL = "STOP_SELL",
    ARCHIVE = "ARCHIVE"
}

export enum EBusinessType {
    NORMAL = "NORMAL",
    EXCHANGE = "EXCHANGE",
    MARKETING_EVENT = "MARKETING_EVENT"
}

export enum EControlType {
    MANUAL = "MANUAL",
    AUTO = "AUTO"
}

export default class FssProduct {
    id: string = "";
    storeId: string = "";
    sellerId: string = ""
    name: string = "";
    title: string = "";
    createDatetime: number = 0;
    createDatetimeText: string = "";
    shortDescription: string = "";
    tags: string = "";
    shortDescriptionCompact: string = "";
    supplierName: string = "";
    coverImageUrl: string = "";
    productStatus: EProductStatus = EProductStatus.ENABLE;
    productStatusText: string = "";
    salesPriceUsd: number = 0;
    regularPriceUsd: number = 0;
    purchasePriceUsd: number = 0;
    sortIndex: number = 0;
    stockAmount: number = 0;
    images: string[] = [];
    category?: FssCategory;
    categoryId: string = "";
    categoryName: string = "";
    storeCategoryId: string = "";
    storeCategoryName: string = "";
    productCode: string = "";
    barcode: string = "";
    sellUnitAmount: number = 1;
    weight: number = 0;
    size: number = 0;
    brand: string = "";
    specificationList: Array<FssProductSpecification> = [];
    sellUnit: string = "";
    shippingFeeTemplateId: string = "";
    shippingFeeTemplate?: FssShippingFeeTemplate;
    salesAmount: number = 0;
    userAmountLimit: number = 0;
    inventoryControlType: EInventoryControlType = EInventoryControlType.OVER_SELL_STOP;

    productImageList: Array<string> = [];
    favoriteCount: number = 0;

    fssSeller: FssSeller = new FssSeller();
    productDetail: FssProductDetail = new FssProductDetail();
    parentProductId: string = "";
    parentProduct?: FssProduct;
    childProductList: Array<FssProduct> = [];
    productCharacteristic: string = "";
    taxNameIds?: string;
    taxNameList?: Array<FssTaxName>;
    businessType: EBusinessType = EBusinessType.NORMAL;
    pickingGroup: string = "";
    storageLocation: string = "";
    tagsList: Array<string> = new Array<string>();
    defaultTagsList: Array<string> = new Array<string>();
    modifiedDatetime?: string = "";
    modifiedDatetimeText?: string = "";
    productStatusControlType: EControlType = EControlType.MANUAL;
    importSourceId: string = "";
    deliveryProviderList: Array<FssDeliveryProviderPublic> = [];
    shippingFeeTemplateIdList: Array<string> = [];
    specificationValueList: Array<string> = [];
    specificationNameList: Array<string> = [];

    viewsCount: number = 0;

    productImage1: string = "";
    productImage2: string = "";
    productImage3: string = "";
    productImage4: string = "";
    specificationName: string = "";
    specificationValue: string = "";
    toTop: boolean = false;

    public constructor(id?: string, name?: string, title?: string) {
        if (name)
            this.name = name;
        if (id)
            this.id = id;
        if (title)
            this.title = title;
    }

    public validate(): string {
        return "";
    }
}
