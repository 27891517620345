import * as React from "react";
import Button from "@material-ui/core/Button";
import {RouteComponentProps} from "react-router-dom";
import {Save} from "@material-ui/icons";
import {Typography} from "@material-ui/core";
import FssProductReview, {EReviewStatus} from "../model/FssProductReview";
import FssProductService from "../service/FssProductService";
import FtToast from "../ui/FtToast";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtSnackbar from "../ui/FtSnackbar";
import FtRow from "../ui/FtRow";
import {i18n} from "../util/I18n";
import FtPage from "../ui/FtPage";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import FtMediaViewerDialog from "../ui/FtMediaViewerDialog";
import FssClientData from "../data/FssClientData";

interface FssProductReviewDetailPageProp extends RouteComponentProps<{ id: string}> {

}

export class FssProductReviewDetailPageState {
    fssProductReview: FssProductReview = new FssProductReview();
}

export default class FssProductReviewDetailPage extends React.Component<FssProductReviewDetailPageProp, FssProductReviewDetailPageState> {
    constructor(props: any) {
        super(props);

        this.state = {
            fssProductReview: new FssProductReview(),
        };

        this.onCancelClick = this.onCancelClick.bind(this);
        this.loadData = this.loadData.bind(this);
        this.getLeftButton = this.getLeftButton.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onApproved = this.onApproved.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    loadData () {
        if (this.props.match.params.id && this.props.match.params.id != "0") {
            FtToast.showToastLoading("loading");
            FssProductService.getProductReviewById(this.props.match.params.id, (fssProductReview: FssProductReview)=>{
                FtToast.closeToast();
                this.setState({fssProductReview: fssProductReview});
            });
        }
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onClickSave() {
        let fssProductReview = this.state.fssProductReview;
        if (fssProductReview.content && fssProductReview.content.trim().length > 0 && fssProductReview.reviewStatus !== EReviewStatus.APPROVE) {
            FtMessageDialog.dialog.openDialog("是否确认保存回复并且将用户评价状态改成已审核？", ()=>{
                FtMessageDialog.dialog.closeDialog();
                FtToast.showToastLoading();
                this.onApproved();
            }, ()=>{
                FtMessageDialog.dialog.closeDialog();
                this.onSubmit();
            });
        } else {
            this.onSubmit();
        }
    }

    onSubmit() {
        FtToast.showToastLoading();
        FssProductService.saveProductReview(this.state.fssProductReview, (fssProductReview: FssProductReview)=>{
            FtToast.closeToast();
            this.setState({fssProductReview: fssProductReview});
            FtSnackbar.showText("保存成功");
        });
    }

    onDelete() {
        FtMessageDialog.dialog.openDialog("确定要删除吗？", ()=>{
            FtMessageDialog.dialog.closeDialog();
            FtToast.showToastLoading();
            FssProductService.deleteProductReview(this.state.fssProductReview.id||"", EReviewStatus.ADMIN_DELETED, (apiResponse: string)=>{
                FtToast.closeToast();
                FtSnackbar.showText("删除成功");
                this.loadData();
            });
        }, ()=>{
            FtMessageDialog.dialog.closeDialog();
        });
    }

    onApproved() {
        this.state.fssProductReview.reviewStatus = EReviewStatus.APPROVE;
        this.forceUpdate(this.onClickSave);
    }

    getLeftButton() {
        let fssProductReview = this.state.fssProductReview;
        if (fssProductReview.reviewStatus === EReviewStatus.APPROVE) {
            return <FtRow cellHorizontalSpace={8}>
                <Button variant="contained"
                        color="primary"
                        startIcon={<Save />}
                        onClick={this.onClickSave}>{i18n("保存")}</Button>
                <Button variant="contained"
                        onClick={this.onDelete}>{i18n("删除")}</Button>
            </FtRow>;
        } else if (fssProductReview.reviewStatus === EReviewStatus.TO_BE_APPROVED) {
            return <FtRow cellHorizontalSpace={8}>
                <Button variant="contained"
                        color="primary"
                        startIcon={<Save />}
                        onClick={this.onClickSave}>{i18n("保存")}</Button>
                <Button variant="contained"
                        color="secondary"
                        onClick={this.onApproved}>{i18n("通过审核")}</Button>
                <Button variant="contained"
                        onClick={this.onDelete}>{i18n("删除")}</Button>
            </FtRow>;
        } else {
            return null;
        }
    }

    render() {
        let fssProductReview = this.state.fssProductReview;
        return <FtPage onCancelClick={this.onCancelClick}
                                  toolbarLeftNode={this.getLeftButton()}>
            <FtPanel panelHeader={i18n("商品评价详情")}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"}>
                        <FtTextFieldString label={i18n("用户昵称")}
                                           value={fssProductReview.userNickName}
                                           disabled={true}
                                           style={{width: "100%"}}/>
                        <FtTextFieldString label={i18n("状态")}
                                           value={fssProductReview.reviewStatusText}
                                           disabled={true}
                                           style={{width: "100%"}}/>
                        <FtTextFieldString label={i18n("评价时间")}
                                           value={FssClientData.formatDatetime(fssProductReview.createDatetime)}
                                           disabled={true}
                                           style={{width: "100%"}}/>
                    </FtRow>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"}>
                        <FtTextFieldString label={i18n("商品名称")}
                                           value={fssProductReview.productName}
                                           disabled={true}
                                           style={{width: "100%"}}/>
                        <FtTextFieldNumber label={i18n("评价等级")}
                                           value={fssProductReview.starCount}
                                           disabled={true}
                                           style={{width: "100%"}}
                                           inputStyle={{textAlign: "right"}}/>
                    </FtRow>
                    <FtRow cellWidthS={"1,1"}>
                        <FtTextFieldString label={i18n("评价内容")}
                                           value={fssProductReview.content}
                                           disabled={true}
                                           rows={3}
                                           variant={"outlined"}
                                           multiline={true}
                                           style={{width: "100%"}}/>
                    </FtRow>
                    {((fssProductReview.reviewImage1 && fssProductReview.reviewImage1.trim().length>0)||
                        (fssProductReview.reviewImage1 && fssProductReview.reviewImage1.trim().length>0)||
                        (fssProductReview.reviewImage1 && fssProductReview.reviewImage1.trim().length>0)) &&
                    <Typography variant="caption">评价图片</Typography>}
                    <FtRow cellWidthS={"1,1,1,1,1,1,1"}>
                        {fssProductReview.reviewImage1 && fssProductReview.reviewImage1.trim().length>0&&
                        <img src={fssProductReview.reviewImage1} style={{width: "100%", cursor: "pointer"}}
                             onClick={(event)=>{
                                 event.stopPropagation();
                                 this.onClickZoomOut(fssProductReview.reviewImage1);
                             }}/>}
                        {fssProductReview.reviewImage2 && fssProductReview.reviewImage2.trim().length>0&&
                        <img src={fssProductReview.reviewImage2} style={{width: "100%", cursor: "pointer"}}
                             onClick={(event)=>{
                                 event.stopPropagation();
                                 this.onClickZoomOut(fssProductReview.reviewImage2);
                             }}/>}
                        {fssProductReview.reviewImage3 && fssProductReview.reviewImage3.trim().length>0&&
                        <img src={fssProductReview.reviewImage3} style={{width: "100%", cursor: "pointer"}}
                             onClick={(event)=>{
                                 event.stopPropagation();
                                 this.onClickZoomOut(fssProductReview.reviewImage3);
                             }}/>}
                    </FtRow>
                    <FtRow cellWidthS={"1,1"}>
                        <FtTextFieldString label={i18n("商家回复")}
                                           value={fssProductReview.storeReply}
                                           rows={3}
                                           variant={"outlined"}
                                           onChangeValue={value => {
                                               if (value === undefined) {
                                                   this.state.fssProductReview.storeReply = "";
                                                   this.forceUpdate();
                                               } else if (value.length <= 200) {
                                                   this.state.fssProductReview.storeReply = value;
                                                   this.forceUpdate();
                                               }
                                           }}
                                           disabled={fssProductReview.reviewStatus === EReviewStatus.ADMIN_DELETED || fssProductReview.reviewStatus === EReviewStatus.USER_DELETED}
                                           multiline={true}
                                           style={{width: "100%"}}
                                           helperText={"("+this.state.fssProductReview.storeReply.length+"/200字)"}
                                           InputLabelProps={{shrink: true,}}
                                           FormHelperTextProps={{style: {textAlign: "end"}}}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FtMediaViewerDialog ref={(element: any) => {
                FtMediaViewerDialog.FtMediaViewerDialog = element;
            }}/>
        </FtPage>;
    }

    onClickZoomOut(mediaUrl: string) {
        if (mediaUrl.length > 0) {
            FtMediaViewerDialog.FtMediaViewerDialog.show(mediaUrl, "image");
        }
    }
}
