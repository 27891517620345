import * as React from "react";
import FtToast from "../ui/FtToast";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Typography from "@material-ui/core/Typography";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import {CheckCircleOutline} from "@material-ui/icons";
import FssOrderTrackData, {FssOrderTrackLog} from "../model/FssOrderTrackData";
import FssOrderService from "../service//FssOrderService";


interface FssOrderTrackLogCompProp {
    orderId: string;
}

class FssOrderTrackLogCompState {
    orderTrackData: FssOrderTrackData = new FssOrderTrackData();
}

export default class FssOrderTrackLogComp extends React.Component<FssOrderTrackLogCompProp, FssOrderTrackLogCompState> {

    constructor(props: any) {
        super(props);
        this.state = {
            orderTrackData: new FssOrderTrackData()
        };
        this.load = this.load.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        FtToast.showToastLoading();
        if (this.props.orderId.length > 0) {
            FssOrderService.getOrderTrackData(this.props.orderId, (orderTrackData: FssOrderTrackData) => {
                FtToast.closeToast();
                this.setState({orderTrackData: orderTrackData});
            });
        }
    }

    render() {
        return <FtPanel>
            <FtGrid>
                <FtRow cellWidthS={"1"}>
                    <Typography variant={"h5"}>运单号: {this.state.orderTrackData?.deliveryNoteId}</Typography>
                </FtRow>
            </FtGrid>
            {this.state.orderTrackData.trackLogList != null && this.state.orderTrackData.trackLogList.length > 0 &&
            <Stepper orientation="vertical">
                {this.state.orderTrackData.trackLogList.map((item: FssOrderTrackLog, index: number) => {
                    return <Step key={index} active={true}>
                        <StepLabel StepIconComponent={CheckCircleOutline}>{item.operateDatetime}</StepLabel>
                        <StepContent>
                            {item.description}
                        </StepContent>
                    </Step>
                })}
            </Stepper>}
        </FtPanel>;
    }
}
