

export default class FssNotificationSetting {
    id = "";
    sellerId: string = "";
    storeId: string = "";
    attributeKey: EAttributeKey = EAttributeKey.CREATED;
    attributeValue: string = "";
    createDatetime: string = "";
    modifiedDatetime: string = "";
    notificationType: ENotificationType = ENotificationType.SMS;

    checked: boolean = false;
}

export enum ENotificationType {
    SMS = "SMS",
    EMAIL = "EMAIL"
}
export enum EAttributeKey {
    CREATED = "CREATED",
    PAY_DONE = "PAY_DONE",
    REFUND = "REFUND",
    OVERDUE = "OVERDUE",
    SELF_PICK_UP = "SELF_PICK_UP",
    DELIVERY_TO_DOOR = "DELIVERY_TO_DOOR",
    PROMPT_PAYMENT = "PROMPT_PAYMENT",
    TO_BE_SHIPPED = "TO_BE_SHIPPED",
}
