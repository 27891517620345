import * as React from "react";
import {CSSProperties} from "react";
import {RouteComponentProps} from "react-router-dom";
import FtTabPanel from "../ui/FtTabPanel";
import {i18n} from "../util/I18n";
import FtTabs from "../ui/FtTabs";
import FtState from "../ui/FtState";
import {Typography} from "@material-ui/core";
import DistributionSetting from "../deliveryProvider/FssDeliveryProviderListPage";
import FssRegionListPage from "../region/FssRegionListPage";
import FtPage from "../ui/FtPage";

interface JtShippingSettingTabsPageProp extends RouteComponentProps{
}

class JtShippingSettingTabsPageState{
    tabValue: string = "";
}

export default class JtShippingSettingTabsPage extends React.Component<JtShippingSettingTabsPageProp, JtShippingSettingTabsPageState> {
    stateKey = "JtShippingSettingTabsPageState";
    constructor(props: any) {
        super(props);
        let tabValue = FtState.get(this.stateKey);
        if (tabValue != null) {
            this.state = {tabValue: tabValue};
        } else {
            this.state = {tabValue: "shippingMethod"};
            FtState.put(this.stateKey, "shippingMethod");
        }
    }

    componentDidMount(): void {

    }

    render() {
        const styles: CSSProperties = {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            backgroundColor: "#d5d5d5",
            color: "black"
        };
        const selectedStyles: CSSProperties = {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            backgroundColor: "white",
        };
        return <FtPage onCancelClick={()=>{this.props.history.goBack()}}
                       toolbarLeftNode={<Typography variant="h6">{i18n("配送设置")}</Typography>}>
            <FtTabs value={this.state.tabValue}
                    indicatorColor="primary" textColor="primary"
                    onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                        this.setState({tabValue: newValue});
                        FtState.put(this.stateKey, newValue);
                    }}>
                <FtTabPanel style={this.state.tabValue == "shippingMethod" ? selectedStyles:styles}
                            label={i18n("配送服务商")} value="shippingMethod">{<DistributionSetting/>}</FtTabPanel>
                <FtTabPanel style={this.state.tabValue == "fssRegion" ? selectedStyles:styles}
                            label={i18n("服务地区")} value="fssRegion">{<FssRegionListPage/>}</FtTabPanel>
                {/*<FtTabPanel style={this.state.tabValue == "shippingFeeTemplate" ? selectedStyles:styles}
                            label={i18n("运费模版")} value="shippingFeeTemplate">{<JtShippingFeeTempListPage/>}</FtTabPanel>*/}
            </FtTabs>
        </FtPage>;
    }
}
