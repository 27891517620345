import * as React from "react";
import {ChangeEvent, CSSProperties, ReactNode} from "react";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import Button from "@material-ui/core/Button";
import {
    AddBoxOutlined,
    DeleteOutline,
    ExpandLess,
    ExpandMore,
    MoveToInboxOutlined,
    SaveOutlined
} from "@material-ui/icons";
import FtToolbar from "../ui/FtToolbar";
import {
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText
} from "@material-ui/core";
import {blue} from "@material-ui/core/colors";
import FtRow from "../ui/FtRow";
import FssUserMediaComp from "../userMedia/FssUserMediaComp";
import JtTextField from "../ui/JtTextField";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FtUtil from "../util/FtUtil";
import JtUserMedia from "../model/FssUserMedia";
import FssUserMediaSelectDialog from "../userMedia/FssUserMediaSelectDialog";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FssProductSearchCriteria from "../model/FssProductSearchCriteria";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtCombobox from "../ui/FtCombobox";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import {i18n} from "../util/I18n";
import {RouteComponentProps} from "react-router-dom";
import FssCategory from "../model/FssCategory";
import FssProduct from "../model/FssProduct";
import FssCategoryService from "../service/FssCategoryService";
import FssProductService from "../service/FssProductService";
import FtApiResponse from "../model/FtApiResponse";
import FtPagedList from "../model/FtPagedList";
import FssClientData from "../data/FssClientData";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import _ from "lodash";

interface JtCategoryListNewPageProp extends RouteComponentProps<{ pageId: string }> {
}

export class JtCategoryListNewPageState {
    category: FssCategory = new FssCategory();
    theSelectCategory?: FssCategory;
    categoryList?: Array<FssCategory> = new Array<FssCategory>();
    expandMoreList: Array<string> = [];
}

export default class FssCategoryListNewPage extends React.Component<JtCategoryListNewPageProp, JtCategoryListNewPageState> {
    jtUserMediaSelectDialog!: FssUserMediaSelectDialog;

    constructor(props: any) {
        super(props);
        this.state = {category: new FssCategory(), expandMoreList: []};

        this.loadCategoryData = this.loadCategoryData.bind(this);
        this.getCategoryListItem = this.getCategoryListItem.bind(this);
        this.onClickCategoryItem = this.onClickCategoryItem.bind(this);
        this.getCategoryDetail = this.getCategoryDetail.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeImage = this.onChangeImage.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onMessageDialog = this.onMessageDialog.bind(this);
        this.loadCategoryList = this.loadCategoryList.bind(this);
        this.onMoveProductDialog = this.onMoveProductDialog.bind(this);
    }

    componentDidMount(): void {
        // 加载分类列表
        this.loadCategoryData();
        // 加载分类 FtCombobox
        this.loadCategoryList();
    }

    loadCategoryData() {
        FssCategoryService.getRootCategory((result: FssCategory) => {
            this.setState({category: result});
        })
    }

    loadCategoryList() {
        FssCategoryService.getStoreCategoryList("", 0, 999, '', (result: FtPagedList<FssCategory>) => {
            this.setState({categoryList: result.content});
        });
    }

    onClickCategoryItem(category: FssCategory) {
        this.setState({theSelectCategory: category});
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.theSelectCategory, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeImage() {
        this.jtUserMediaSelectDialog.show("single", (userMediaList: Array<JtUserMedia> | null) => {
            if (userMediaList != null && userMediaList.length > 0) {
                if (this.state.theSelectCategory) {
                    this.state.theSelectCategory.coverImageUrl = userMediaList[0].mediaUrl;
                    this.forceUpdate();
                }
            }
        });
    }

    onClickSave() {
        if (this.state.theSelectCategory != null) {
            const message: string = FssCategory.validate(this.state.theSelectCategory);
            if (message == null || message == "") {
                FtToast.showToastLoading(i18n("正在保存"));
                FssCategoryService.saveCategory(this.state.theSelectCategory,
                    (data: FssCategory) => {
                        FtToast.closeToast();
                        let category = this.state.category;
                        if (data.parentCategoryId.length > 0) {
                            // 添加子分类
                            let parentCategory = this.findCategory(category, data.parentCategoryId);
                            if (parentCategory) {
                                let indexOf = this.findCategoryIndex(parentCategory, data.id);
                                if (indexOf == -1) {
                                    parentCategory.subCategoryList.push(data);
                                }
                            }
                        } else {
                            // 添加父分类
                            let indexOf = this.findCategoryIndex(category, data.id);
                            if (indexOf == -1) {
                                category.subCategoryList.push(data);
                            }
                        }
                        this.setState({category: category, theSelectCategory: data});
                        this.loadCategoryList();
                        this.loadCategoryData();
                        FtSnackbar.showText(i18n("保存成功！"));
                    });
            } else {
                FtSnackbar.showText(message);
            }
        }
    }

    onMessageDialog() {
        if (this.state.theSelectCategory && this.state.theSelectCategory.id.length > 0) {
            let theSelectCategory = this.state.theSelectCategory;
            // 确认删除
            FtMessageDialog.dialog.openDialog(i18n("确定要删除「{0}」吗？", theSelectCategory.categoryName), () => {
                FtMessageDialog.dialog.closeDialog();
                this.onClickDelete();
            }, () => {
                FtMessageDialog.dialog.closeDialog();
            }, i18n("确认"), i18n("取消"));
        }
    }

    onMoveProductDialog() {
        if (this.state.theSelectCategory && this.state.theSelectCategory.id.length > 0) {
            let toCategoryId = "";
            let theSelectCategory = this.state.theSelectCategory;
            let toCategoryName = "";
            let combobox = <FtCombobox label={i18n("所有分类")} optionItems={this.state.categoryList}
                                       labelPropertyName={"categoryName"}
                                       valuePropertyName={"categoryId"}
                                       value={toCategoryId}
                                       onSelectItem={(item: object) => {
                                           if (item == undefined || item == null)
                                               toCategoryId = "";
                                           else {
                                               toCategoryId = (item as FssCategory).id;
                                               toCategoryName = (item as FssCategory).categoryName;
                                           }
                                       }} style={{minWidth: 300}}/>;
            FtMessageDialog.dialog.openDialog(combobox, () => {
                FtMessageDialog.dialog.openDialog(i18n("确定要把「{0}」下面的商品全部转移到「{1}」吗？", theSelectCategory.categoryName, toCategoryName),
                    () => {
                        FtMessageDialog.dialog.closeDialog();
                        FtToast.showToastLoading(i18n("正在移动商品"));
                        FssProductService.updateProductCategoryByCategoryId(theSelectCategory.id, toCategoryId,
                            (data: string) => {
                                FtToast.closeToast();
                                FtSnackbar.showText(i18n("移动成功！"));
                            });
                    }, () => {
                        FtMessageDialog.dialog.closeDialog();
                    }, i18n("确认"), i18n("取消"));
            }, () => {
                FtMessageDialog.dialog.closeDialog();
            }, i18n("确认"), i18n("取消"));
        }
    }

    onClickDelete() {
        if (this.state.theSelectCategory && this.state.theSelectCategory.id.length > 0) {
            let theSelectCategory = this.state.theSelectCategory;
            let categoryIdArray: Array<string> = [theSelectCategory.id];
            // 判断如果是父分类，那么同时查询子分类
            if (this.state.theSelectCategory.parentCategoryId == "") {
                let parentCategory = this.findCategory(this.state.category, theSelectCategory.id);
                if (parentCategory) {
                    parentCategory.subCategoryList.map((category: FssCategory) => {
                        categoryIdArray.push(category.id);
                    });
                }
            }
            // 查询时候有商品
            const searchCriteria: FssProductSearchCriteria = new FssProductSearchCriteria();
            searchCriteria.categoryIds = categoryIdArray;
            FssProductService.getProductListByCategoryId(searchCriteria, (result: FtPagedList<FssProduct>) => {
                if (result.totalElements > 0) {
                    FtSnackbar.showText(i18n("删除失败。此分类已添加商品，不能被删除"));
                } else {
                    // 删除分类
                    FssCategoryService.deleteCategory(categoryIdArray, (data: FtApiResponse) => {
                        if (this.state.theSelectCategory) {
                            let category = this.state.category;
                            // 删除的是父分类
                            if (this.state.theSelectCategory.parentCategoryId.length == 0) {
                                let indexOf = this.findCategoryIndex(category, theSelectCategory.id);
                                category.subCategoryList.splice(indexOf, 1);
                            } else {
                                let parentCategory = this.findCategory(category, theSelectCategory.parentCategoryId);
                                if (parentCategory) {
                                    let indexOf = this.findCategoryIndex(parentCategory, theSelectCategory.id);
                                    parentCategory.subCategoryList.splice(indexOf, 1);
                                }
                            }
                            this.setState({category: category, theSelectCategory: undefined});
                        }
                        FtSnackbar.showText(i18n("删除成功"));
                    });
                }
            });
        }
    }

    findCategory(category: FssCategory, targetCategoryId: string) {
        return category.subCategoryList.find((category: FssCategory) => {
            return category.id == targetCategoryId;
        });
    }

    findCategoryIndex(category: FssCategory, targetCategoryId: string) {
        return category.subCategoryList.findIndex((category: FssCategory) => {
            return category.id == targetCategoryId;
        });
    }

    render() {
        return <FtPanel style={{display: "flex"}}>
            <FtGrid style={{
                width: "30%",
                borderRight: "solid 1px grey"
            }}>
                <FtToolbar style={{minHeight: 36}}
                           leftNode={<Button color="primary" onClick={() => {
                               let jtCategory = new FssCategory();
                               jtCategory.categoryStatus = "STOP";
                               this.setState({theSelectCategory: jtCategory});
                           }}><AddBoxOutlined/>{i18n("新增")}</Button>}>
                </FtToolbar>
                <FtGrid style={{
                    overflow: "scroll",
                    height: document.documentElement.clientHeight - 96
                }}>
                    {this.getCategoryListItem()}
                </FtGrid>
            </FtGrid>
            <FtGrid style={{
                width: "70%"
            }}>
                <FtToolbar style={{minHeight: 36}}
                           leftNode={<div>
                               {this.state.theSelectCategory && this.state.theSelectCategory.id.length > 0 &&
                               <Button color="primary"
                                       onClick={this.onMoveProductDialog}><MoveToInboxOutlined/>{i18n("移动商品")}
                               </Button>}
                               {this.state.theSelectCategory && this.state.theSelectCategory.id.length > 0 &&
                               <Button color="primary" onClick={this.onMessageDialog}><DeleteOutline/>{i18n("删除")}
                               </Button>}
                               {this.state.theSelectCategory &&
                               <Button color="primary" onClick={this.onClickSave}><SaveOutlined/>{i18n("保存")}
                               </Button>}
                           </div>}>
                </FtToolbar>
                <FtGrid style={{
                    height: document.documentElement.clientHeight - 96
                }}>
                    {this.getCategoryDetail()}
                </FtGrid>
            </FtGrid>
            <FssUserMediaSelectDialog ref={(element: any) => {
                this.jtUserMediaSelectDialog = element
            }}/>
        </FtPanel>
    }

    getCategoryListItem() {
        let category = this.state.category;
        if (category && category.subCategoryList) {
            return <List>
                {category.subCategoryList.map((parentCategory: FssCategory) => {
                    let styles: CSSProperties = {};
                    if (this.state.theSelectCategory && this.state.theSelectCategory.id == parentCategory.id) {
                        styles.backgroundColor = blue[50];
                    }
                    let parentCategoryStatus = parentCategory.categoryStatus == "NORMAL";
                    let findIndex = _.findIndex(this.state.expandMoreList, (categoryId: string) => {
                        return categoryId == parentCategory.id;
                    });
                    let open = (findIndex != null && findIndex > -1);
                    return <div>
                        <ListItem key={parentCategory.id} button style={styles} onClick={() => {
                            this.onClickCategoryItem(parentCategory)
                        }}>
                            <ListItemIcon>
                                <IconButton size={"small"}
                                            disabled={parentCategory.subCategoryList == null || parentCategory.subCategoryList.length == 0}
                                            onClick={(event) => {
                                                event.stopPropagation();
                                                let expandMoreList: Array<string> = [];
                                                let add = true;
                                                this.state.expandMoreList.forEach((categoryId: string) => {
                                                    if (categoryId != parentCategory.id) {
                                                        expandMoreList.push(categoryId);
                                                    } else {
                                                        add = false;
                                                    }
                                                });
                                                if (add) {
                                                    expandMoreList.push(parentCategory.id);
                                                }
                                                this.setState({expandMoreList: expandMoreList});
                                            }}>
                                    {parentCategory.subCategoryList != null && parentCategory.subCategoryList.length > 0 && open && <ExpandLess/>}
                                    {parentCategory.subCategoryList != null && parentCategory.subCategoryList.length > 0 && !open && <ExpandMore/>}
                                </IconButton>
                            </ListItemIcon>
                            <ListItemText primary={parentCategory.categoryName}
                                          primaryTypographyProps={{style: {color: parentCategoryStatus ? "black" : "grey"}}}/>
                            {parentCategoryStatus && <ListItemSecondaryAction>
                                <IconButton size={"small"} onClick={() => {
                                    let theSelectCategory = new FssCategory();
                                    theSelectCategory.parentCategoryId = parentCategory.id;
                                    theSelectCategory.categoryStatus = "STOP";
                                    this.setState({theSelectCategory: theSelectCategory});
                                }}>
                                    <AddBoxOutlined/>
                                </IconButton>
                            </ListItemSecondaryAction>}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <List style={{padding: 0}}>
                                {parentCategory.subCategoryList && parentCategory.subCategoryList.map((childCategory: FssCategory) => {
                                    let childStyles: CSSProperties = {paddingLeft: 50};
                                    if (this.state.theSelectCategory && this.state.theSelectCategory.id == childCategory.id) {
                                        childStyles.backgroundColor = blue[50];
                                    }
                                    let childCategoryStatus = childCategory.categoryStatus == "NORMAL";
                                    return <ListItem key={childCategory.id} button style={childStyles} onClick={() => {
                                        this.onClickCategoryItem(childCategory)
                                    }}>
                                        <ListItemText primary={childCategory.categoryName}
                                                      primaryTypographyProps={{style: {color: parentCategoryStatus ? (childCategoryStatus ? "black" : "grey") : "grey"}}}/>
                                    </ListItem>
                                })}
                            </List>
                        </Collapse>
                    </div>
                })}
            </List>;
        } else {
            return null;
        }
    }

    getCategoryDetail() {
        if (this.state.theSelectCategory) {
            return <div>
                <FtRow cellWidthS={"1"} cellWidthM={"1,3"}>
                    <FssUserMediaComp id={"coverImageUrl"} label={i18n("分类封面图")}
                                      mediaUrl={this.state.theSelectCategory.coverImageUrl}
                                      mediaType={"image"} style={{width: 150, height: 150}}
                                      onClickImage={this.onChangeImage}/>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1"}>
                        {/*<JtTextField id="categoryId" label={i18n("分类ID")} value={this.state.theSelectCategory.id}*/}
                        {/*             style={{width: "100%"}} disabled={true}/>*/}
                        <FormControl style={{width: "100%"}}>
                            <InputLabel shrink>{i18n("状态")}</InputLabel>
                            <Select style={{marginTop: 0}}
                                    id="categoryStatus" value={this.state.theSelectCategory.categoryStatus}
                                    onChange={(event) => {
                                        if (this.state.theSelectCategory) {
                                            FtUtil.setProperty(this.state.theSelectCategory, "categoryStatus", event.target.value);
                                            this.forceUpdate();
                                        }
                                    }}>
                                <MenuItem value={"NORMAL"}>{i18n("启用")}</MenuItem>
                                <MenuItem value={"STOP"}>{i18n("禁用")}</MenuItem>
                            </Select>
                        </FormControl>
                        <FtCombobox label={i18n("父分类")} optionItems={this.state.categoryList}
                                    labelPropertyName={"categoryName"}
                                    valuePropertyName={"id"}
                                    value={this.state.theSelectCategory.parentCategoryId}
                                    onSelectItem={(item: object) => {
                                        if (this.state.theSelectCategory && (item == undefined || item == null))
                                            this.state.theSelectCategory.parentCategoryId = "";
                                        else if (this.state.theSelectCategory)
                                            this.state.theSelectCategory.parentCategoryId = (item as FssCategory).id;
                                    }} style={{width: "100%"}}/>
                        <FtTextFieldNumber id="sortIndex" label={i18n("排序系数")}
                                           helperText={i18n("正整数倒序")}
                                           value={this.state.theSelectCategory.sortIndex}
                                           onChangeValue={(value?: number) => {
                                               if (this.state.theSelectCategory && value)
                                                   this.state.theSelectCategory.sortIndex = value;
                                               else if (this.state.theSelectCategory)
                                                   this.state.theSelectCategory.sortIndex = 0;
                                           }} style={{width: "100%"}}/>
                        <JtTextField id="categoryName" label={i18n("分类名称")}
                                     value={this.state.theSelectCategory.categoryName}
                                     onChange={this.onChange} style={{width: "100%"}}
                                     endAdornment={this.getTranslationIcon("categoryName", this.state.theSelectCategory.categoryName)}/>
                        <JtTextField id="createDatetime" label={i18n("创建时间")}
                                     value={this.state.theSelectCategory.createDatetimeText}
                                     style={{width: "100%"}} disabled={true}/>
                    </FtRow>
                </FtRow>
            </div>
        } else {
            return null;
        }
    }

    getTranslationIcon(itemKey: string, defaultText?: string): ReactNode {
        if (this.state.theSelectCategory && this.state.theSelectCategory.id && this.state.theSelectCategory.id.length > 0) {
            const appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite.supportLanguages != null && appWebsite.supportLanguages.length > 0) {
                return <FssTranslationDialogComp objectId={this.state.theSelectCategory.id + ""}
                                                 groupKey={"category"} itemKey={itemKey}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
