import * as React from "react";
import {ReactNode} from "react";
import {Button, Grid, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FssProduct from "../model/FssProduct";
import AppClientInfo from "../data/AppClientInfo";
import {i18n} from "../util/I18n";

export interface MoveProductItemProp {
    productOptions: Array<FssProduct>;
    onMoveOptions: (productOptions: Array<FssProduct>) => void;
}
class MoveProductItemState {
    dragging: boolean = false;
    draggingIndex: number = -1;
    startPageY: number = 0;
    offsetPageY: number = 0;
    openMessageBoxDialog: boolean = false;
    messageText: string = "";
    productOptions: Array<FssProduct> = [];
    deleteProduct: FssProduct = new FssProduct();
}

class MoveProductItem extends React.Component<MoveProductItemProp, MoveProductItemState> {

    constructor(props: any) {
        super(props);
        this.getMessageBoxDialog = this.getMessageBoxDialog.bind(this);
        this.state = {
            dragging: false,
            draggingIndex: -1,
            startPageY: 0,
            offsetPageY: 0,
            openMessageBoxDialog: false,
            messageText: "",
            productOptions: this.props.productOptions,
            deleteProduct: new FssProduct()
        };
    }

    handleMounseDown = (evt: { pageY: any; }, index: any) => {
        this.setState({
            dragging: true,
            startPageY: evt.pageY,
            offsetPageY: 0,
            draggingIndex: index
        });
    };
    handleMouseUp = () => {
        this.setState({ dragging: false, startPageY: 0, draggingIndex: -1 });
    };
    handleMouseMove = (evt: { pageY: number; }) => {
        let offset = evt.pageY - this.state.startPageY;
        const draggingIndex = this.state.draggingIndex;
        if (offset > lineHeight && draggingIndex < this.state.productOptions.length - 1) {
            // move down
            offset -= lineHeight;
            let productOptions = move(this.state.productOptions, draggingIndex, draggingIndex + 1);
            this.setState({
                productOptions: productOptions,
                draggingIndex: draggingIndex + 1,
                startPageY: this.state.startPageY + lineHeight,
            });
            this.props.onMoveOptions(productOptions);
        } else if (offset < -lineHeight && draggingIndex > 0) {
            // move up
            offset += lineHeight;
            let productOptions = move(this.state.productOptions, draggingIndex, draggingIndex - 1);
            this.setState({
                productOptions: productOptions,
                draggingIndex: draggingIndex - 1,
                startPageY: this.state.startPageY - lineHeight,
            });
            this.props.onMoveOptions(productOptions);
        }
        this.setState({ offsetPageY: offset });
    };

    getDraggingStyle(index: number) {
        if (index !== this.state.draggingIndex) {
            return {
                cursor: "default",
                listStyle: "none",
                margin: 0,
                minWidth: "100%",
                height: lineHeight+`px`
            };
        }
        return {
            cursor: "default",
            listStyle: "none",
            margin: 0,
            minWidth: "100%",
            transform: `translate(0px, ${this.state.offsetPageY}px)`,
            opacity: 0.5,
            height: lineHeight+`px`
        };
    }
    render() {
        return (
            <div>
                {this.state.productOptions.map((product: FssProduct, i: number) => (
                    this.getProductItem(product, i)
                ))}
                {this.state.dragging && (
                    <div
                        style={{
                            position: "fixed",
                            left: 0,
                            right: 0,
                            top: 0,
                            bottom: 0
                        }}
                        onMouseMove={this.handleMouseMove}
                        onMouseUp={this.handleMouseUp}
                    />
                )}
                {this.getMessageBoxDialog()}
            </div>
        );
    }

    getProductItem(product : FssProduct, index: number): ReactNode {
        let coverImageElement = <div></div>;
        if (product.coverImageUrl) {
            coverImageElement =
                <img alt="complex" src={product.coverImageUrl} style={{
                    width: "100%",
                    height: AppClientInfo.clientInfo.product3ColImageHeight > 0 ? AppClientInfo.clientInfo.product3ColImageHeight : "auto"
                }}/>;
        } else {
            coverImageElement = <img alt="complex"
                                     src={"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/B8AAusB9FD6Pn0AAAAASUVORK5CYII="}
                                     style={{
                                         width: "100%",
                                         height: AppClientInfo.clientInfo.product3ColImageHeight > 0 ? AppClientInfo.clientInfo.product3ColImageHeight : "auto"
                                     }}/>;
        }
        return <Grid container key={product.id} style={this.getDraggingStyle(index)}>
            <Grid style={{paddingTop: 4,paddingBottom: 4,paddingLeft: 4, height: lineHeight+"px", width: lineHeight+"px"}} onMouseDown={evt => this.handleMounseDown(evt, index)}>
                {coverImageElement}
            </Grid>
            <Grid item xs={8} md={4} container style={{borderBottom: "1px solid #eee", paddingTop: 4, paddingBottom: 4, paddingLeft: 4}}>
                <Grid item xs container direction="column" spacing={1} justify={"space-between"}>
                    <div style={{flexGrow: 2, textAlign: "left", paddingTop: 4, paddingLeft: 8, paddingRight: 8}} onMouseDown={evt => this.handleMounseDown(evt, index)}>
                        <div style={{fontSize: "90%", fontWeight: 700}}>
                            {product.title}
                        </div>
                        <div style={{fontSize: "70%", color: "#696969"}}>
                            {product.productCharacteristic}
                        </div>
                    </div>
                    <div style={{display: "flex", justifyContent: "space-between", paddingLeft: 8, alignItems: "center"}}>
                        <div style={{alignItems: "center",display: "flex",paddingTop: 4, fontSize: "150%", color: "#ff2800", fontWeight: 500, textAlign: "left"}}
                             onMouseDown={evt => this.handleMounseDown(evt, index)}>
                            <Typography variant="subtitle1" style={{fontSize: "100%", fontWeight: 500}}>
                                <small style={{fontSize: "75%", fontWeight: 100}}>$</small>
                                {product.salesPriceUsd}</Typography>
                            <div style={{fontSize: "80%", textDecoration: "line-through",
                                color: "grey", marginLeft: 8}}>
                                <span>$</span>
                                <span style={{
                                    fontSize: "80%",
                                    fontWeight: 450
                                }}>{product.regularPriceUsd}</span>
                            </div>
                        </div>
                        <div style={{textAlign: "right"}}>
                            <IconButton aria-label="Delete" onClick={()=>{
                                console.info("######");
                                this.setState({openMessageBoxDialog: true, messageText: i18n("确定删除『{0}』吗?",product.title), deleteProduct: product});
                            }}><DeleteIcon/>
                            </IconButton>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>;
    }
    getMessageBoxDialog(): ReactNode {
        return <Dialog open={this.state.openMessageBoxDialog} onClose={(e) => {
            this.setState({openMessageBoxDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("确认")}</DialogTitle>
            <DialogContent>
                {this.state.messageText}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                }}>{i18n("取消")}</Button>
                <Button variant="outlined" color="primary" onClick={(e) => {
                    let productOptions = this.state.productOptions;
                    let deleteProduct = this.state.deleteProduct;
                    if (productOptions.indexOf(deleteProduct) != -1) {
                        productOptions.splice(productOptions.indexOf(deleteProduct), 1);
                        this.props.onMoveOptions(productOptions);
                    }
                    this.setState({openMessageBoxDialog: false, productOptions: productOptions});
                }}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }
}
const move = (arr: any[], startIndex: number, toIndex: number) => {
    arr = arr.slice();
    arr.splice(toIndex, 0, arr.splice(startIndex, 1)[0]);
    return arr;
};
const lineHeight = 130;
export default MoveProductItem;
