/**
 * 设备信息，兼容cordova的Device
 */
import FtUtil from "../util/FtUtil";
import {FtTheme} from "../ui/FtTheme";

export enum Language {
    zh_CN = "zh-CN", zh_TW = "zh-TW", en_CA = "en-CA",
}

export default class AppClientInfo {
    public static clientInfo: AppClientInfo = new AppClientInfo();
    serverEnv: string = "";
    apiServerUrl: string = "";
    appToken: string = "";
    appServerInfoUrl: string = "";
    clientVersion: number = 1;
    clientVersionLabel?: string;
    available?: boolean = false;
    version?: string = "";
    cordova?: string = "";
    model?: string = "";
    manufacturer?: string = "";
    isVirtual?: string = "";
    serial?: string = "";
    isWechatInstalled: boolean = false;
    inviteCode?: string = "";
    bundleJsVersion?: number = 0;
    platform: string = "browser";
    uuid: string = "";
    versionCode: string = "";
    versionNumber: string = "";
    packageName: string = "";
    availHeight: number = 0;
    availWidth: number = 0;
    product3ColImageHeight: "auto" | number = "auto";
    product2ColImageHeight: "auto" | number = "auto";
    viewMode: "mobile" | "pad" | "pc" = "mobile";
    insetTop: number = 0;
    insetBottom: number = 0;
    insetRight: number = 0;
    insetLeft: number = 0;
    apiFasShortLinkUrl: string = "";
    language: Language | string = "";
    nativeAppPackageName?: string;
    theme?: FtTheme;

    isIOS(): boolean {
        return (this && this.platform != null && this.platform == "iOS")
    }

    isAndroid(): boolean {
        return (this && this.platform != null && this.platform == "Android")
    }

    isDebug(): boolean {
        return (this && this.platform != null && this.platform == "debug")
    }

    isCordova(): boolean {
        return (this && this.cordova != null && this.cordova.length > 0);
    }

    static isSupportWechatPay(): boolean {
        //如果是微信浏览器内，或 PC 端，或者 PAD 都可以支持，手机 APP 内不支持
        if (FtUtil.isWechatBroswer() || AppClientInfo.clientInfo.viewMode == "pad" || AppClientInfo.clientInfo.viewMode == "pc")
            return true;
        else
            return false;
    }

    isNativeApp() {
        return this.nativeAppPackageName != null && this.nativeAppPackageName.length > 0;
    }

    resetInsetForNativeApp() {
        const insetTopString = getComputedStyle(document.body).getPropertyValue("--insetTop");
        if (insetTopString != null && insetTopString.length > 0) {
            if (insetTopString.endsWith("px"))
                AppClientInfo.clientInfo.insetTop = +insetTopString.substring(0, insetTopString.length - 2);
            else
                AppClientInfo.clientInfo.insetTop = +insetTopString;
        }
        const insetBottomString = getComputedStyle(document.body).getPropertyValue("--insetBottom");
        if (insetBottomString != null && insetBottomString.length > 0) {
            if (insetBottomString.endsWith("px"))
                AppClientInfo.clientInfo.insetBottom = +insetBottomString.substring(0, insetBottomString.length - 2);
            else
                AppClientInfo.clientInfo.insetBottom = +insetBottomString;
        }
        const insetRightString = getComputedStyle(document.body).getPropertyValue("--insetRight");
        if (insetRightString != null && insetRightString.length > 0) {
            if (insetRightString.endsWith("px"))
                AppClientInfo.clientInfo.insetRight = +insetRightString.substring(0, insetRightString.length - 2);
            else
                AppClientInfo.clientInfo.insetRight = +insetRightString;
        }
        const insetLeftString = getComputedStyle(document.body).getPropertyValue("--insetLeft");
        if (insetLeftString != null && insetLeftString.length > 0) {
            if (insetLeftString.endsWith("px"))
                AppClientInfo.clientInfo.insetLeft = +insetLeftString.substring(0, insetLeftString.length - 2);
            else
                AppClientInfo.clientInfo.insetLeft = +insetLeftString;
        }
    }
}
