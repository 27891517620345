import FssSingleOrder from "./FssSingleOrder";
import {EDeliveryType} from "./FssDeliveryProvider";

export default class FssMarketingEventSingleOrder {
    scheduleDatetime: string = "";
    marketingEventName: string = "";
    deliveryProviderName: string = "";
    deliveryType:EDeliveryType =EDeliveryType.SELF_PICK_UP;
    orderItemList: Array<FssSingleOrder> = [];
}
