import * as React from "react";
import {ChangeEvent} from "react";
import {Button, IconButton, Typography} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import FtAppBar from "../ui/FtAppBar";
import {ChevronLeft} from "@material-ui/icons";
import FtToast from "../ui/FtToast";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FtPanel from "../ui/FtPanel";
import {i18n} from "../util/I18n";
import JtLanguageOptionDialog from "../ui/JtLanguageOptionDialog";
import {grey} from "@material-ui/core/colors";
import {FssWebSession} from "../model/FssWebSession";
import FssRegisterDialog from "./FssRegisterDialog";
import FssResetPasswordDialog from "./FssResetPasswordDialog";
import FssUserService from "../service/FssUserService";
import FssClientData from "../data/FssClientData";
import AppClientInfo from "../data/AppClientInfo";
import FtSnackbar from "../ui/FtSnackbar";
import {EAccountType} from "../model/FssUserAccount";
import {EOAuthAction} from "../model/FtOAuthData";
import FtUtil from "../util/FtUtil";

interface FssLoginDialogProp {
    defaultOnSuccess?: () => void;
}

export class FssLoginDialogState {
    open: boolean = false;
    loginName: string = "";
    password: string = "";
    message: string = "";
    successFunction?: (data: FssWebSession | null) => void;
    onCancel?: () => void;
}


export default class FssLoginDialog extends React.Component<FssLoginDialogProp, FssLoginDialogState> {
    static loginDialog: FssLoginDialog;
    registerDialog!: FssRegisterDialog;
    resetPasswordDialog!: FssResetPasswordDialog;

    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            loginName: "",
            password: "",
            message: ""
        };
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onClickRegister = this.onClickRegister.bind(this);
        this.onClickResetPassword = this.onClickResetPassword.bind(this);
        this.onClickLanguage = this.onClickLanguage.bind(this);
        this.onClickLoginByFacebook = this.onClickLoginByFacebook.bind(this);
        this.onClickLoginByWechat = this.onClickLoginByWechat.bind(this);
    }

    componentDidMount(): void {
    }

    onCancelClick(): void {
        this.setState({open: false});
        if (this.state.onCancel) {
            this.state.onCancel();
        }
    }

    onLoginClick(): void {
        if (this.state.loginName == "") {
            //this.setState({message: i18n("请输入手机号或者邮箱")});
            FtSnackbar.FtSnackbar.show(i18n("请输入手机号或者邮箱"));
            return;
        }
        if (this.state.password == "") {
            //this.setState({message: i18n("请输入密码")});
            FtSnackbar.FtSnackbar.show(i18n("请输入密码"));
            return;
        }
        FtToast.showToastLoading(i18n("正在登录"), 10000);
        FssUserService.login(this.state.loginName, this.state.password, (data: FssWebSession) => {
            FtToast.closeToast();
            FssClientData.updateWebSession(data);
            this.setState({open: false});
            if (this.state.successFunction) {
                this.state.successFunction(data);
            } else if (this.props.defaultOnSuccess) {
                this.props.defaultOnSuccess();
            }
        });
    }

    onClickRegister(): void {
        this.registerDialog.show((data: FssWebSession) => {
            this.setState({open: false});
            if (this.state.successFunction) {
                this.state.successFunction(data);
            }
        });
    }

    onClickResetPassword(): void {
        this.resetPasswordDialog.show((data: FssWebSession) => {
            this.setState({open: false});
            if (this.state.successFunction) {
                this.state.successFunction(data);
            }
        });
    }

    // onClickFaceBook(): void {
    //     FtToast.showToastLoading(i18n("正在跳转"), 10000);
    //     JtUserService.getOAuthData((data: FtOAuthData) => {
    //         FtToast.closeToast();
    //         //this.props.history.push(data.redirectUrl as string);
    //         if(data.action.toString()=="REDIRECT"){
    //             window.location.href = data.redirectUrl as string;
    //         }
    //
    //     });
    // }

    show(successFunction?: (data: FssWebSession | null) => void, onCancel?: () => void): void {
        this.setState({open: true, successFunction: successFunction, onCancel: onCancel});
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        if (event.target.id == "loginName")
            this.setState({loginName: event.target.value});
        else if (event.target.id == "password")
            this.setState({password: event.target.value});
    }

    render() {
        const fullScreen: boolean = AppClientInfo.clientInfo.viewMode != null && AppClientInfo.clientInfo.viewMode == "mobile";
        return <Dialog open={this.state.open} fullScreen={fullScreen}
                       PaperProps={{
                           className: "paddingTopSafeAreaInset",
                           style: {backgroundColor: grey[200]}
                       }} onClose={this.onCancelClick}>
            {fullScreen && <FtAppBar title={i18n("请登录")} position={"fixedTop"}
                                     leftNode={<IconButton onClick={this.onCancelClick}> <ChevronLeft
                                         style={{}}/>
                                     </IconButton>}>
            </FtAppBar>}
            {fullScreen && <div style={{paddingTop: 64 + AppClientInfo.clientInfo.insetTop}}/>}
            {!fullScreen && <div style={{paddingTop: 16 + AppClientInfo.clientInfo.insetTop}}/>}
            <FtPanel style={{marginLeft: 16, marginTop: 0, marginBottom: 16, marginRight: 16}}>
                <FtGrid style={{marginLeft: 16, marginTop: 0, marginBottom: 16, marginRight: 16}}>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={16} cellHorizontalAlign={"center"}>
                        <Typography variant={"h5"}>{i18n("请登录")}</Typography>
                        <JtTextField type={"text"} id="loginName" label={i18n("请输入手机号 / Email")} required={true}
                                     style={{width: "100%"}} variant={"outlined"}
                                     value={this.state.loginName}
                                     onChange={(event) => {
                                         this.setState({loginName: event.target.value})
                                     }}/>
                        <JtTextField id="password" type="password" label={i18n("密码")} onChange={this.onChange}
                                     required={true}
                                     style={{width: "100%"}} variant={"outlined"}/>
                    </FtRow>
                    <FtRow cellWidthS={"1,1"} justifyContent={"space-between"} cellHorizontalAlign={"justify"}>
                        <Button onClick={this.onClickResetPassword} variant={"outlined"} style={{marginRight: 16}}>
                            {i18n("忘记密码")}
                        </Button>
                        <Button onClick={this.onLoginClick} color="primary" variant={"contained"}>
                            {i18n("登录")}
                        </Button>
                    </FtRow>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={8} cellHorizontalAlign={"center"}
                           style={{marginTop: 16}}>
                        <div>{i18n("如果您还没有注册，请")} <Button variant={"outlined"} color={"primary"}
                                                          onClick={this.onClickRegister}>{i18n("注册")}</Button></div>
                    </FtRow>
                    {!FtUtil.isNativeApp() &&
                    <FtRow cellWidthS={"1"} cellVerticalSpace={8} cellHorizontalAlign={"center"}
                           style={{marginTop: 16}}>
                        {(FssClientData.getData().appWebsite.facebookLoginFlag || FssClientData.getData().appWebsite.wechatLoginFlag) && <div>{"其他登录方式"}</div>}
                        <FtRow justifyContent={"center"} cellHorizontalAlign={"center"} cellHorizontalSpace={16}>
                            {FssClientData.getData().appWebsite.facebookLoginFlag &&<span className="facebook" onClick={this.onClickLoginByFacebook}>
                                <i className="fab fa-facebook-f"></i>
                            </span>}
                            {FssClientData.getData().appWebsite.wechatLoginFlag && <span className="weixin" onClick={this.onClickLoginByWechat}>
                                    <i className="fab fa-weixin"></i>
                            </span>}
                        </FtRow>
                    </FtRow>}

                    <FtRow cellWidthS={"1"} cellVerticalSpace={8} cellHorizontalAlign={"center"} style={{}}>
                        <Typography
                            variant={"subtitle2"}><small>{i18n("版本: ")} {AppClientInfo.clientInfo.clientVersion}</small></Typography>
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FssRegisterDialog ref={(element: any) => {
                this.registerDialog = element;
            }}/>
            <FssResetPasswordDialog ref={(element: any) => {
                this.resetPasswordDialog = element;
            }}/>
        </Dialog>;
    }

    private onClickLanguage() {
        JtLanguageOptionDialog.languageOptionDialog.openDialog();
    }

    private onClickLoginByFacebook() {
        FtToast.showToastLoading();
        FssUserService.getOAuthData(EAccountType.FACEBOOK, "LOGIN", window.location.href, data => {
            FtToast.closeToast();
            if (data.action == EOAuthAction.REDIRECT) {
                window.location.href = data.redirectUrl as string;
            }
        });
    }

    private onClickLoginByWechat() {
        FtToast.showToastLoading();
        FssUserService.getOAuthData(EAccountType.WECHAT, "LOGIN", window.location.href, data => {
            FtToast.closeToast();
            if (data.action == EOAuthAction.REDIRECT) {
                window.location.href = data.redirectUrl as string;
            }
        });
    }
}
