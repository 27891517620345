import * as React from "react";
import {ChangeEvent} from "react";
import {i18n} from "../util/I18n";
import {
    Button,
    Checkbox,
    FormControlLabel,
    ListItemText,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import FtUtil from "../util/FtUtil";
import MenuItem from "@material-ui/core/MenuItem";
import JtTextField from "../ui/JtTextField";
import FtToast from "../ui/FtToast";
import FssOrderService from "../service/FssOrderService";
import FtSnackbar from "../ui/FtSnackbar";
import FtPanel from "../ui/FtPanel";
import FtPage from "../ui/FtPage";
import {Save} from "@material-ui/icons";
import {ReactNode} from "react";
import {EPrintVersion, ETipType, FssOrderSetting} from "../model/FssOrderSetting";
import FssClientData from "../data/FssClientData";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import FormControl from "@material-ui/core/FormControl";
import {Language} from "../data/AppClientInfo";

class FssOrderSettingPageState {
    appOrderSetting: FssOrderSetting = new FssOrderSetting();
}

export default class FssOrderSettingPage extends React.Component<any, FssOrderSettingPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            appOrderSetting: new FssOrderSetting(),
        };

        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    loadData () {
        FtToast.showToastLoading();
        FssOrderService.getOrderSetting((orderSetting: FssOrderSetting) => {
            FtToast.closeToast();
            this.setState({appOrderSetting: orderSetting});
        });
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.type == "checkbox") {
            FtUtil.setProperty(this.state.appOrderSetting, event.target.id, event.target.checked);
        } else {
            FtUtil.setProperty(this.state.appOrderSetting, event.target.id, event.target.value);
        }
        this.forceUpdate();
    }



    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));

        if (this.state.appOrderSetting.tipFlag == null){
            this.state.appOrderSetting.tipFlag = false;
        }

        FssOrderService.saveOrUpdateOrderSetting(this.state.appOrderSetting,
            (orderSetting: FssOrderSetting) => {
                FtToast.closeToast();
                this.setState({appOrderSetting: orderSetting});
                FtSnackbar.showText(i18n("保存成功！"));
            });
    }

    render () {
        let tipFlag = this.state.appOrderSetting.tipFlag == null ? true : this.state.appOrderSetting.tipFlag;
        const paperStyle: any = {padding: 15, marginBottom: 15};
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="contained"
                                                           color="primary"
                                                           startIcon={<Save />}
                                                           onClick={this.onClickSave}>{i18n("保存")}</Button>}
                       toolbarCenterNode={<Typography variant="h6">{i18n("订单设置")}</Typography>}>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("订单发货合并设置")}</Typography>}>
                <FormControlLabel
                    control={<Checkbox id="orderMergeDeliveryFlag" color="primary"
                                       checked={this.state.appOrderSetting.orderMergeDeliveryFlag}
                                       onChange={this.onChange}/>}
                    label={i18n("开启订单发货合并")} />
            </FtPanel>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("小费设置")}</Typography>}>
                <FormControlLabel control={<Checkbox id="tipFlag" color="primary"
                                                     checked={tipFlag}
                                                     onChange={this.onChange}/>}
                                  label={i18n("开启小费")} />
                <FtRow cellWidthS={"1,1"}>
                    <JtTextField id="tipLabel"
                                 label={i18n("标题")} value={this.state.appOrderSetting.tipLabel}
                                 disabled={!tipFlag} style={{width: "100%"}}
                                 onChange={this.onChange}
                                 endAdornment={this.getTranslationIcon("tipLabel", this.state.appOrderSetting.tipLabel)}
                    />
                    <TextField select value={this.state.appOrderSetting.tipType} id={"tipType"}
                               label={i18n("计算类型")}
                               disabled={!tipFlag} style={{width: "100%", textAlign: "left"}}
                               onChange={(event: any)=>{
                                   this.state.appOrderSetting.tipType = event.target.value;
                                   this.forceUpdate();
                               }}>
                        <MenuItem value={ETipType.PECENTAGE} key={ETipType.PECENTAGE}>{i18n("百分比")}</MenuItem>
                        <MenuItem value={ETipType.VALUE} key={ETipType.VALUE}>{i18n("金额")}</MenuItem>
                    </TextField>
                    {this.state.appOrderSetting.tipType == ETipType.VALUE &&
                    <TextField id="defaultTip"
                               label={i18n("默认金额")}
                               disabled={!tipFlag} style={{width: "100%"}}
                               value={this.state.appOrderSetting.defaultTip}
                               onChange={this.onChange} type={"number"}/>}
                    {this.state.appOrderSetting.tipType == ETipType.VALUE &&
                    <JtTextField id="tipOptions" label={i18n("可选金额值(逗号分隔)")}
                                 disabled={!tipFlag} style={{width: "100%"}}
                                 value={this.state.appOrderSetting.tipOptionsString}
                                 onChange={(event => {
                                     this.state.appOrderSetting.tipOptionsString=event.target.value;
                                     this.forceUpdate();
                                 })} />}
                    {this.state.appOrderSetting.tipType == ETipType.PECENTAGE &&
                    <TextField id="defaultTip"
                               label={i18n("默认百分比(小数 如: 0.01)")}
                               disabled={!tipFlag} style={{width: "100%"}}
                               value={this.state.appOrderSetting.defaultTip}
                               onChange={this.onChange} type={"number"}/>}
                    {this.state.appOrderSetting.tipType == ETipType.PECENTAGE &&
                    <JtTextField id="tipOptions"
                                 label={i18n("可选百分比(逗号分隔)")}
                                 value={this.state.appOrderSetting.tipOptionsString}
                                 disabled={!tipFlag} style={{width: "100%"}}
                                 onChange={(event => {
                                     this.state.appOrderSetting.tipOptionsString=event.target.value;
                                     this.forceUpdate();
                                 })} />}
                </FtRow>
            </FtPanel>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("关闭已送达订单时间")}</Typography>}>
                <TextField id="closeOrderWaitDays" label={i18n("天")} value={this.state.appOrderSetting.closeOrderWaitDays==null?0:this.state.appOrderSetting.closeOrderWaitDays}
                           onChange={this.onChange} type={"number"}/>
            </FtPanel>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("超时自动取消未支付订单时间")}</Typography>}>
                <TextField id="cancelUnpaidOrderWaitMinutes" label={i18n("分")} value={this.state.appOrderSetting.cancelUnpaidOrderWaitMinutes==null?0:this.state.appOrderSetting.cancelUnpaidOrderWaitMinutes}
                           onChange={this.onChange} type={"number"}/>
                <FtRow>
                    <FormControlLabel
                        control={<Checkbox id="cancelUnpaidIncludePartialPaidFlag" color="primary"
                                           checked={this.state.appOrderSetting.cancelUnpaidIncludePartialPaidFlag}
                                           onChange={this.onChange}/>}
                        label={i18n("同时取消部分支付订单")} />
                </FtRow>
            </FtPanel>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<ListItemText primary={<Typography color={"secondary"}>{i18n("默认拣货单打印版本")}</Typography>}
                                                secondary={i18n("已对账")}
                     />}>
                <FtRow cellWidthS={"1"}>
                    <FormControl>
                        <RadioGroup row value={this.state.appOrderSetting.printVersion ? this.state.appOrderSetting.printVersion : ""}
                                    onChange={(event, value)=>{
                                        this.state.appOrderSetting.printVersion = value as EPrintVersion;
                                        this.forceUpdate();
                                    }}>
                            <FormControlLabel value={EPrintVersion.NEW} control={<Radio />} label="打印新版拣货单" />
                            <FormControlLabel value={EPrintVersion.OLD} control={<Radio />} label="打印旧版拣货单" />
                        </RadioGroup>
                    </FormControl>
                </FtRow>
            </FtPanel>
        </FtPage>;
    }

    getTranslationIcon(itemKey: string, defaultText: string, rows?: number): ReactNode {
        if (this.state.appOrderSetting.id.length > 0) {
            let appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite && appWebsite.supportLanguages !== null &&appWebsite.supportLanguages.length>0) {
                return <FssTranslationDialogComp objectId={this.state.appOrderSetting.id}
                                                 groupKey={"appOrderSetting"} itemKey={itemKey}
                                                 rows={rows}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
