import * as React from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {Button, Dialog, Typography} from "@material-ui/core";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtUtil from "../util/FtUtil";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FssOrderItem from "../model/FssOrderItem";

interface FssOrderItemEditDialogProp {
    open: boolean;
    onClose?: () => void;
    orderItem: FssOrderItem;
    onItemChanged?: (newPrice: number, newQuantity: number, subtotal: number, comment: string, expressName: string, trackingNumber: string) => void;
}

class FssOrderItemEditDialogState extends FtComponentState {
    newPrice: number = 0;
    newQuantity: number = 0;
    newTaxFee: number = 0;
    newSubTotal: number = 0;
    comment: string = "";
    newComment: string = "";
    trackingNumber: string = "";
    expressName: string = "";
}

export default class FssOrderItemEditDialog extends FtComponent<FssOrderItemEditDialogProp, FssOrderItemEditDialogState> {
    constructor(props: any) {
        super(props);
        this.state = {
            newPrice: this.props.orderItem.price,
            newQuantity: this.props.orderItem.quantity,
            newTaxFee: this.props.orderItem.tax ? this.props.orderItem.tax : 0,
            newSubTotal: this.props.orderItem.subtotal,
            comment: this.props.orderItem.comment,
            newComment: "",
            trackingNumber: this.props.orderItem.trackingNumber,
            expressName: this.props.orderItem.expressName,
        };
        this.onClickReset = this.onClickReset.bind(this);
        this.calculate = this.calculate.bind(this);
        this.onClickOK = this.onClickOK.bind(this);
    }

    render() {
        let priceDifferent = FtUtil.toCurrency(this.state.newPrice) - FtUtil.toCurrency(this.props.orderItem?.price);
        let amountDifferent = FtUtil.toCurrency(this.state.newQuantity) - FtUtil.toCurrency(this.props.orderItem.quantity);
        let subTotalNoTaxDifferent = this.state.newSubTotal - this.props.orderItem.subtotal;

        return <Dialog open={this.props.open} onClose={this.props.onClose} PaperProps={{style: {minWidth: 360}}}>
            <FtPanel>
                <FtGrid>
                    <FtRow>
                        <Typography variant={"h5"}>商品：{this.props.orderItem?.productName}</Typography>
                    </FtRow>
                    <FtRow cellWidthM={"1,1,1,1"} cellHorizontalAlign={"right"}
                           cellHorizontalSpace={32}>
                        <Typography></Typography>
                        <Typography>{i18n("原值")}</Typography>
                        <Typography>{i18n("新值")}</Typography>
                        <Typography>{i18n("变化")}</Typography>
                        <Typography>{i18n("价格")}</Typography>
                        <Typography>{FtUtil.toCurrencyText(this.props.orderItem?.price)}</Typography>
                        <Typography><FtTextFieldNumber type={"currency"} value={this.state.newPrice}
                                                       style={{width: 60}} inputStyle={{textAlign: "right"}}
                                                       onChangeValue={(value => {
                                                           this.setState({newPrice: FtUtil.toCurrency(value)});
                                                           this.calculate(FtUtil.toCurrency(value), this.state.newQuantity);
                                                       })}/></Typography>
                        <Typography color={priceDifferent != 0 ? "primary" : undefined}>
                            {FtUtil.toCurrencyText(priceDifferent)}</Typography>
                        <Typography>{i18n("数量")}</Typography>
                        <Typography>{this.props.orderItem?.quantity}</Typography>
                        <Typography><FtTextFieldNumber type={"currency"} value={this.state.newQuantity}
                                                       style={{width: 60}} inputStyle={{textAlign: "right"}}
                                                       onChangeValue={(value => {
                                                           this.setState({newQuantity: FtUtil.toCurrency(value)});
                                                           this.calculate(this.state.newPrice, FtUtil.toCurrency(value));
                                                       })}/></Typography>
                        <Typography
                            color={amountDifferent != 0 ? "primary" : undefined}>{FtUtil.toCurrencyText(amountDifferent)}</Typography>
                        <Typography>{i18n("小计")}</Typography>
                        <Typography>{FtUtil.toCurrencyText(this.props.orderItem?.subtotal)}</Typography>
                        <Typography><FtTextFieldNumber type={"currency"} value={this.state.newSubTotal}
                                                       style={{width: 60}} inputStyle={{textAlign: "right"}}
                                                       onChangeValue={(value => {
                                                           this.setState({newSubTotal: FtUtil.toCurrency(value)});
                                                           let newPrice = FtUtil.toCurrency(value) / this.state.newQuantity
                                                           this.setState({newPrice: FtUtil.toCurrency(newPrice)});
                                                       })}/></Typography>
                        <Typography color={subTotalNoTaxDifferent != 0 ? "primary" : undefined}>
                            {FtUtil.toCurrencyText(subTotalNoTaxDifferent)}
                        </Typography>
                    </FtRow>
                    <FtRow cellWidthS={"1,1"}>
                        <FtTextFieldString label={i18n("国内物流快递公司")} value={this.state.expressName}
                                           style={{width: "100%"}}
                                           onChangeValue={(value => {
                                               this.setState({expressName: value + ""});
                                           })}/>
                        <FtTextFieldString label={i18n("国内运单号")} value={this.state.trackingNumber}
                                           style={{width: "100%"}}
                                           onChangeValue={(value => {
                                               this.setState({trackingNumber: value + ""});
                                           })}/>
                    </FtRow>
                    <FtRow cellWidthS={"1"}>
                        <FtTextFieldString label={i18n("评论")} value={this.state.comment} style={{width: "100%"}}
                                           helperText={"请输入修改原因，此备注用户可见"}
                                           onChangeValue={(value: string | undefined) => {
                                               this.setState({comment: value ? value : ""});
                                           }}>
                        </FtTextFieldString>
                    </FtRow>
                    <FtRow cellWidthS={"1,1"} justifyContent={"space-between"} cellHorizontalAlign={"justify"}>
                        <FtRow>
                            <Button variant={"outlined"} onClick={this.props.onClose}>{i18n("取消")}</Button>
                            <Button variant={"outlined"} onClick={this.onClickReset}>{i18n("重置")}</Button>
                        </FtRow>
                        <Button variant={"outlined"} color={"primary"} onClick={this.onClickOK}>{i18n("确定")}</Button>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </Dialog>;
    }

    private onClickReset() {
        this.setState({
            newPrice: this.props.orderItem.price,
            newQuantity: this.props.orderItem.quantity,
            newTaxFee: this.props.orderItem.tax ? this.props.orderItem.tax : 0,
            newSubTotal: this.props.orderItem.subtotal,
        });
    }

    private onClickOK() {
        if (this.props.onItemChanged) {
            this.props.onItemChanged(this.state.newPrice, this.state.newQuantity, this.state.newSubTotal,
                this.state.comment, this.state.expressName, this.state.trackingNumber);
        }
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private calculate(price: number, amount: number) {
        let newSubTotal = FtUtil.toCurrency(price * amount);
        this.setState({newSubTotal: newSubTotal});
    }
}
