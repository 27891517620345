import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssDeliveryProvider from "../model/FssDeliveryProvider";
import FssDeliveryProviderDTO from "../model/FssDeliveryProviderDTO";
import FssShippingInfo from "../model/FssShippingInfo";
import {EShippingType} from "../model/FssOrder";


export default class FssDeliveryProviderService {

    static getPageDeliveryProviderList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                                       pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssDeliveryProvider>) => void) {
        FssHttpService.search("/api/deliveryProvider/getPageDeliveryProviderList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }

    static getListByOrderId(orderId: string, shippingType: EShippingType | undefined, successFunction: (data: Array<FssDeliveryProviderDTO>) => void): void {
        let params = {orderId: orderId, shippingType: shippingType};
        FssHttpService.get("/api/deliveryProvider/getDTOListByOrderId", params, successFunction, FssHttpService.handleError);
    }

    /**
     * 根据id获取配送服务详情
     */
    static getDeliveryProviderById(deliveryProviderId: string, successFunction: (data: FssDeliveryProvider) => void): void {
        FssHttpService.get("/api/deliveryProvider/getDeliveryProviderById", {deliveryProviderId: deliveryProviderId}, successFunction, FssHttpService.handleError);
    }

    /**
     * 修改 / 添加 配送服务记录
     */
    static saveDeliveryProvider(deliveryProvider: FssDeliveryProvider, successFunction: (data: FssDeliveryProvider) => void): void {
        FssHttpService.postJson("/api/deliveryProvider/saveDeliveryProvider", deliveryProvider, successFunction, FssHttpService.handleError);
    }

    /**
     * 删除配送服务记录
     */
    static deleteDeliveryProvider(deliveryProviderIds: Array<string>, successFunction: (data: FssDeliveryProvider) => void): void {
        FssHttpService.postFormData("/api/deliveryProvider/deleteDeliveryProvider", {deliveryProviderIds: deliveryProviderIds}, successFunction, FssHttpService.handleError);
    }

    static getDeliveryProviderList(storeId: string, sellerId: string, successFunction: (data: Array<FssDeliveryProvider>) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/deliveryProvider/getDeliveryProviderList", {
            storeId: storeId,
            sellerId: sellerId
        }, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static getShipingInfo(successFunction: (data: FssShippingInfo) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/deliveryProvider/getShipingInfo", {}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static saveShipingInfo(fssShippingInfo: FssShippingInfo, successFunction: (data: FssShippingInfo) => void,
                           failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/deliveryProvider/saveShipingInfo", fssShippingInfo, successFunction, FssHttpService.handleError);
    }

    static getPublisherShipingInfo(userId: string, successFunction: (data: FssShippingInfo) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/deliveryProvider/getPublisherShipingInfo", {userId: userId}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static getSellerShipingInfo(successFunction: (data: FssShippingInfo) => void, failFunction ?: (error: any) => void) {
        FssHttpService.get("/api/deliveryProvider/getSellerShipingInfo", null, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static savePublisherShipingInfo(fssShippingInfo: FssShippingInfo, successFunction: (data: FssShippingInfo) => void,
                                    failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/deliveryProvider/savePublisherShipingInfo", fssShippingInfo, successFunction, FssHttpService.handleError);
    }

    static savePublisherPickUpDeliveryProvider(deliveryProvider: FssDeliveryProvider, successFunction: (data: FssDeliveryProvider) => void): void {
        FssHttpService.postJson("/api/deliveryProvider/savePublisherPickUpDeliveryProvider", deliveryProvider, successFunction, FssHttpService.handleError);
    }
    static savePickUpDeliveryProvider(deliveryProvider: FssDeliveryProvider, successFunction: (data: FssDeliveryProvider) => void): void {
        FssHttpService.postJson("/api/deliveryProvider/savePickUpDeliveryProvider", deliveryProvider, successFunction, FssHttpService.handleError);
    }
    static deletePublisherDeliveryProvider(deliveryProviderId: string, successFunction: (data: string) => void): void {
        FssHttpService.postFormData("/api/deliveryProvider/deletePublisherDeliveryProvider", {deliveryProviderId: deliveryProviderId}, successFunction, FssHttpService.handleError);
    }


}
