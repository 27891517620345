import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {Button, CardMedia, IconButton} from "@material-ui/core";
import {CropOriginal, PhonelinkSetup} from "@material-ui/icons";
import MenuItem from "@material-ui/core/MenuItem";
import FssUser from "../model/FssUser";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtPanel from "../ui/FtPanel";
import FtUtil from "../util/FtUtil";
import FtRow from "../ui/FtRow";
import FtGrid from "../ui/FtGrid";
import JtTextField from "../ui/JtTextField";
import {i18n} from "../util/I18n";
import {Language} from "../data/AppClientInfo";
import FssUpdateMobileDialog from "./FssUpdateMobileDialog";
import FssUpdateEmailDialog from "./FssUpdateEmailDialog";
import FtToast from "../ui/FtToast";
import FssUserService from "../service/FssUserService";
import FtSnackbar from "../ui/FtSnackbar";
import FtApiResponse from "../model/FtApiResponse";
import CloseIcon from "@material-ui/icons/Close";
import {grey} from "@material-ui/core/colors";
import FtFileUploadButtonToOss from "../ui/FtFileUploadButtonToOss";

export interface FssUserDetailCompProp {
    user: FssUser;
    onChangeValue?: (id: string, value: any) => void;
}

export class FssUserDetailCompState extends FtComponentState {
    open: boolean = false;
    openEmailDialog: boolean = false;
}

export default class FssUserDetailComp extends FtComponent<FssUserDetailCompProp, FssUserDetailCompState> {
    constructor(props: any) {
        super(props);
        this.state = {open: false, openEmailDialog: false};
        this.onChange = this.onChange.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.updateMobile = this.updateMobile.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.getEmailEndAdornment = this.getEmailEndAdornment.bind(this);
        this.onCheckEmail = this.onCheckEmail.bind(this);
        this.updateEmail = this.updateEmail.bind(this);
        this.sendEmail = this.sendEmail.bind(this);
        this.handleCloseEmail = this.handleCloseEmail.bind(this);
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        if (this.props.onChangeValue) {
            this.props.onChangeValue(event.target.id, event.target.value);
        }
    }

    /**
     *
     * 关闭弹出的对话框
     */
    handleClose(open: boolean): void {
        this.setState({open: open});
    }

    handleCloseEmail(open: boolean): void {
        this.setState({openEmailDialog: open});
    }

    /**
     *
     * 修改当前页面的手机号
     */
    updateMobile(mobile: string): void {
        FtUtil.setProperty(this.props.user.mobile, "mobile", mobile);
        if (this.props.onChangeValue) {
            this.props.onChangeValue("mobile", mobile);
        }
    }

    updateEmail(email: string): void {
        FtUtil.setProperty(this.props.user.email, "email", email);
        if (this.props.onChangeValue) {
            this.props.onChangeValue("email", email);
            this.props.onChangeValue("emailVerified", false);
        }
    }

    render() {
        return <FtPanel style={{paddingLeft: 8}}>
            <FtRow cellWidthS={"1"} cellWidthM={"2,10"} cellHorizontalSpace={8} maxRowWidth={"l"}>
                {this.getUploadComp()}
                <FtGrid paddingVertical={0} paddingHorizontal={0}>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1"} cellHorizontalSpace={12}
                           cellVerticalSpace={16}>
                        <JtTextField id="nickName" onChange={this.onChange}
                                     value={this.props.user.nickName ? this.props.user.nickName : ""} label={i18n("昵称")}
                                     type={"text"} required={true} style={{width: "100%"}}/>
                        <JtTextField id="mobile" onChange={this.onChange}
                                     value={this.props.user.mobile ? this.props.user.mobile : ""} label={i18n("收件电话")}
                                     type={"phone"} disabled={true} required={true} style={{width: "100%"}}
                                     endAdornment={<IconButton onClick={() => {
                                         this.setState({open: true});
                                     }}><PhonelinkSetup/></IconButton>}/>
                        <JtTextField id="email" disabled={this.props.user.emailVerified}
                                     value={this.props.user.email ? this.props.user.email : ""} label="Email"
                                     type={"email"}
                                     style={{width: "100%"}} onChange={this.onChange}
                                     endAdornment={this.getEmailEndAdornment()}/>
                        <JtTextField id="defaultLanguage" select label={i18n("默认语言")} style={{width: "100%"}}
                                     value={this.props.user.defaultLanguage ? this.props.user.defaultLanguage : ""}
                                     onChange={(event) => {
                                         this.props.user.defaultLanguage = event.target.value as string;
                                         this.forceUpdate();
                                     }}>
                            <MenuItem value={Language.en_CA}>{"English"}</MenuItem>
                            <MenuItem value={Language.zh_CN}>{"简体中文"}</MenuItem>
                            <MenuItem value={Language.zh_TW}>{"繁体中文"}</MenuItem>
                        </JtTextField>
                    </FtRow>
                </FtGrid>
            </FtRow>
            <FssUpdateMobileDialog open={this.state.open} updateMobile={this.updateMobile}
                                   handleClose={this.handleClose}/>
            <FssUpdateEmailDialog open={this.state.openEmailDialog} sendEmail={this.onCheckEmail}
                                  handleClose={this.handleCloseEmail}
                                  email={this.props.user.email ? this.props.user.email : ""}/>
        </FtPanel>;
    }

    /**
     *
     * 上传头像
     */
    private onFinishUpload(fileUrl: string, fileName: string) {
        if (fileUrl != null && fileUrl.length > 0) {
            if (this.props.onChangeValue) {
                this.props.onChangeValue("avatarUrl", fileUrl);
            }
        }
    }

    private getEmailEndAdornment(): ReactNode {
        if (!this.props.user.email || this.props.user.email.trim().length == 0) {
            return <Button color={"secondary"} onClick={() => {
                this.setState({openEmailDialog: true});
            }}>{i18n("绑定邮箱")}</Button>;
        } else if (this.props.user.emailVerified) {
            return <Button color={"default"} onClick={() => {
                this.setState({openEmailDialog: true});
            }}>{i18n("修改邮箱")}</Button>;
        } else if (!this.props.user.emailVerified) {
            return <Button color={"secondary"} onClick={() => {
                this.sendEmail(this.props.user.email);
            }}>{i18n("邮箱验证")}</Button>;
        }
        return null;
    }

    private onCheckEmail(email: string) {
        FtToast.showToastLoading(i18n("正在验证邮箱"));
        let userId = this.props.user.id ? this.props.user.id : "0";
        FssUserService.checkAccountStatus("", email, userId,
            (apiResponse: FtApiResponse) => {
                FtToast.closeToast();
                this.updateEmail(email);
                this.sendEmail(email);
            }, (apiResponse: FtApiResponse) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n(apiResponse.message));
            });
    }

    private sendEmail(email: string) {
        FtToast.showToastLoading(i18n("正在发送"));
        FssUserService.userEmailVerification(email, (apiResponse: any) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("发送成功"));
            if (email) {
                this.updateEmail(email);
            }
        });
    }

    getUploadComp() {
        if (this.props.user.avatarUrl && this.props.user.avatarUrl.trim().length > 0) {
            return <div style={{position: "relative", width: 120, height: 120}}>
                <CardMedia image={this.props.user.avatarUrl} style={{width: 120, height: 120}}
                           onClick={(event) => {
                               event.stopPropagation();
                           }}>
                </CardMedia>
                <IconButton
                    style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)", padding: 0,
                        height: 25, width: 25, top: 0, right: 0, position: "absolute",
                        borderRadius: 0, borderBottomLeftRadius: "100%",
                        justifyContent: "flex-end"
                    }}
                    onClick={() => {
                        if (this.props.onChangeValue) {
                            this.props.onChangeValue("avatarUrl", "");
                        }
                    }}>
                    <CloseIcon style={{color: "white", marginLeft: 5}}/>
                </IconButton>
            </div>
        } else {
            let buttonStyle = {
                cursor: "pointer",
                width: 118, height: 118,
                backgroundColor: grey[100],
                border: "1px dashed grey", borderRadius: 3
            };
            let svgDiv = <div style={{color: "grey", margin: "auto", textAlign: "center"}}>
                <CropOriginal viewBox={"3 3 18 18"} style={{width: 40, height: 40}}/>
                {/*<Typography>添加LOGO</Typography>*/}
            </div>;
            return <FtFileUploadButtonToOss onFinish={this.onFinishUpload}
                                            buttonStyle={buttonStyle}
                                            buttonSvg={svgDiv}
                                            multiple={false}
                                            buttonLabel={" "}
                                            formStyle={{height: 0}}/>
        }
    }
}
