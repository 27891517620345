import * as React from "react";
import {HTMLAttributes} from "react";
import {IconButton} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import FtUtil from "../util/FtUtil";
import FtRow from "./FtRow";
import FtToolbar from "./FtToolbar";

export interface FtPageProp extends HTMLAttributes<HTMLDivElement> {
    onCancelClick?: ()=> void;
    toolbarLeftNode?: React.ReactNode;
    toolbarCenterNode?: React.ReactNode;
    toolbarRightNode?: React.ReactNode;
    childHeight?: number;
}

export default class FtPage extends React.Component<FtPageProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let clientHeight = document.documentElement.clientHeight;
        let topBarHeight = 40;
        let bodyPadding = 16;
        let cancelBarHeight = 0;
        if (this.props.toolbarLeftNode != null ||
            this.props.toolbarCenterNode != null ||
            this.props.toolbarRightNode != null ||
            this.props.onCancelClick !== undefined) {
            cancelBarHeight = 48;
        }
        let height: number = clientHeight - topBarHeight - (bodyPadding * 2) - cancelBarHeight;
        if (this.props.childHeight) {
            height = this.props.childHeight - cancelBarHeight;
        }
        let style = {
            overflow: "scroll",
            height: height,
            borderRadius: 5
        };
        if(this.props.style) {
            style=FtUtil.mergeObject(style,this.props.style);
        }
        let leftNode = <FtRow cellHorizontalSpace={8}>
            {this.props.onCancelClick && <IconButton style={{padding: 5}}>
                <ArrowBack style={{width: 30, height: 30}} onClick={this.props.onCancelClick}/>
            </IconButton>}
            {this.props.toolbarLeftNode != null ? this.props.toolbarLeftNode : <div />}
        </FtRow>;

        return <div>
            {cancelBarHeight !== 0 &&
            <FtToolbar leftNode={leftNode}
                       centerNode={this.props.toolbarCenterNode}
                       rightNode={this.props.toolbarRightNode}/>}
            <div style={style}>
                {this.props.children}
            </div>
        </div>;
    }
}
