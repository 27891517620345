import * as React from "react";
import {CSSProperties, ReactNode} from "react";
import {InputAdornment, TextField} from "@material-ui/core";
import {BaseTextFieldProps} from "@material-ui/core/TextField";
import {OutlinedInputProps} from "@material-ui/core/OutlinedInput";
import {FilledInputProps} from "@material-ui/core/FilledInput";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";

interface FtTextFieldDateProps extends BaseTextFieldProps {
    helperText?: string;
    originalValue?: unknown;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    variant?: "standard" | "filled" | "outlined";
    onChangeValue?: (value?: string | undefined) => void;
    onBlur?: StandardInputProps['onBlur'] | FilledInputProps["onBlur"] | OutlinedInputProps["onBlur"];
    onFocus?: StandardInputProps['onFocus'] | FilledInputProps["onFocus"] | OutlinedInputProps["onFocus"];
    value?: string;
    inputStyle?: CSSProperties;
}

class FtTextFieldDateState {
    text: string = "";
}


export default class FtTextFieldDate extends React.Component<FtTextFieldDateProps, FtTextFieldDateState> {
    constructor(props: any) {
        super(props);
        this.state = {text: ""};
        this.onChange = this.onChange.bind(this);
    }

    static getDerivedStateFromProps(props: FtTextFieldDateProps, state: FtTextFieldDateState) {
        if (props.value != null)
            if (props.value.length > 10) {
                return {text: props.value.substr(0, 10)};
            }
        return {text: props.value};
    }

    render() {
        const baseTextFieldProps: BaseTextFieldProps = this.props;
        let variant: any = "standard";
        if (this.props.variant)
            variant = this.props.variant;
        const InputProps: any = {};
        if (this.props.startAdornment)
            InputProps.startAdornment = <InputAdornment position="start">{this.props.startAdornment}</InputAdornment>;
        if (this.props.endAdornment)
            InputProps.endAdornment = <InputAdornment position="end">{this.props.endAdornment}</InputAdornment>;
        if (this.props.variant)
            variant = this.props.variant;
        let error: boolean | undefined = this.props.error;
        let helperText: string | undefined = this.props.helperText ? this.props.helperText : "";
        if (this.props.originalValue && this.props.value != this.props.originalValue)
            return <TextField type={"date"} {...baseTextFieldProps} value={this.state.text}
                              error={error} helperText={helperText}
                              variant={"filled"} inputProps={{style: this.props.inputStyle}}
                              InputProps={InputProps} onChange={this.onChange}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
        else
            return <TextField type={"date"} {...baseTextFieldProps} value={this.state.text}
                              error={error} helperText={helperText}
                              variant={variant} inputProps={{style: this.props.inputStyle}}
                              InputProps={InputProps} onChange={this.onChange}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
    }

    private onChange(event: any) {
        if (this.props.onChangeValue)
            this.props.onChangeValue(event.target.value);

    }

}
