import * as React from "react";
import FtToast from "../ui/FtToast";
import FtUtil from "../util/FtUtil";
import Divider from "@material-ui/core/Divider";
import {i18n} from "../util/I18n";
import FssOrder from "../model/FssOrder";
import FssOrderItem from "../model/FssOrderItem";
import FssMoneySectionComp from "../order/FssMoneySectionComp";
import FssClientData from "../data/FssClientData";

interface FssOrderPrint58Prop {
    order?: FssOrder;
    orderList?: Array<FssOrder>;

}

class FssOrderPrint58State {
    iframList: Array<HTMLIFrameElement | null> = new Array<HTMLIFrameElement>();
}

export default class FssOrderPrint58 extends React.Component<FssOrderPrint58Prop, FssOrderPrint58State> {
    constructor(props: any) {
        super(props);
        this.state = {iframList: new Array<HTMLIFrameElement>()}
    }

    print() {
        FtToast.showToastLoading(i18n("Printing, please wait "));
        this.state.iframList.forEach((iframe: HTMLIFrameElement | null, index: number) => {
            if (iframe && iframe.contentWindow) {
                if (iframe.contentWindow.document.body)
                    iframe.contentWindow.document.body.innerHTML = "";
                if (iframe.children && iframe.children.length > 0) {
                    const item = iframe.children.item(0);
                    if (item != null) {
                        iframe.contentWindow.document.write(item.innerHTML);
                        window.setTimeout(() => {
                            if (iframe && iframe.contentWindow)
                                iframe.contentWindow.print();
                        }, 1000);
                    }
                }

            }
        });
    }

    render() {
        let orderList = this.props.orderList;
        if (orderList == null && this.props.order != null) {
            orderList = [this.props.order];
        }
        return <div>
            {orderList != null && orderList.map((order: FssOrder, index: number) => (
                <iframe key={index} style={{width: 0, height: 0, margin: 0, padding: 0}} ref={(element) => {
                    this.state.iframList[index] = element
                }}>
                    <html>
                    <head>
                    </head>
                    <body style={{fontSize: 10, margin: 0, padding: 8}}>
                    <div>
                        {/*<JtPrintHeader order={order} fromPage="P58" store={JtCommonClientData.getData().userSession.store} />*/}
                        <table style={{width: "100%", fontSize: 10, borderTop: "1px solid black"}}>
                            <thead>
                            <tr>
                                <th align="left">{i18n("商品名称")}</th>
                                <th align="right">{i18n("单价")}</th>
                                <th align="right">{i18n("数量")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {order.items != null && order.items.map((orderItem: FssOrderItem, itemIndex: number) => {
                                if (orderItem.productName)
                                    return <tr key={"item" + itemIndex}>
                                        <td align="left">{orderItem.productName}</td>
                                        <td align="right">{FtUtil.toCurrencyText(orderItem.price)}</td>
                                        <td align="right">{orderItem.quantity}</td>
                                    </tr>;
                                else
                                    return null;
                            })}
                            </tbody>
                        </table>
                        <Divider/>
                        <FssMoneySectionComp order={order} totalUnpaid={
                            order.total > order.totalPaid ? order.total - order.totalPaid : 0}/>
                        {order.userComment != null && order.userComment.length > 0 &&
                        <div style={{fontSize: 10, fontWeight: 700}}>{i18n("评论")}：{order.userComment}</div>
                        }
                        <div style={{fontSize: 10}}>{i18n("支付方式")}：{i18n(order.paymentTypeText)}</div>
                        <div style={{fontSize: 10}}>{i18n("支付状态")}：{i18n(order.paymentStatusText)}</div>
                        <div
                            style={{fontSize: 10}}>{i18n("下单时间")}：{FssClientData.formatDatetime(order.createDatetime)}</div>
                        <div style={{fontSize: 10}}>unit#：{order.shipToUnitNo}</div>
                        <div style={{fontSize: 10}}>BuzzCode：{order.shipToBuzzCode}</div>
                        {/*<div style={{ display: "flex", flexWrap: "wrap", borderTop: "1px solid black" }}>*/}
                        {/*    <div style={{ minWidth: "80mm", textAlign: "center", padding: "1mm" }}>*/}
                        {/*        {JtCommonClientData.getData().userSession.store.gstNo != null && JtCommonClientData.getData().userSession.store.gstNo.length > 0 &&*/}
                        {/*            <div>GST #{JtCommonClientData.getData().userSession.store.gstNo}</div>}*/}
                        {/*        {JtCommonClientData.getData().userSession.store.pstNo != null && JtCommonClientData.getData().userSession.store.pstNo.length > 0 &&*/}
                        {/*            <div>PST #{JtCommonClientData.getData().userSession.store.pstNo}</div>}*/}
                        {/*        {JtCommonClientData.getData().userSession.store.hstNo != null && JtCommonClientData.getData().userSession.store.hstNo.length > 0 &&*/}
                        {/*            <div>HST #{JtCommonClientData.getData().userSession.store.hstNo}</div>}*/}
                        {/*    </div>*/}
                        {/*    <div style={{ minWidth: "80mm", textAlign: "center", padding: "1mm" }}>*/}
                        {/*        <JtPrintFooter store={JtCommonClientData.getData().userSession.store} /></div>*/}
                        {/*</div>*/}
                        <table style={{pageBreakAfter: "always"}}>
                        </table>
                    </div>
                    </body>
                    </html>
                </iframe>
            ))}
        </div>;
    }
}
