export enum EComponentType {
    CONTAINER = "CONTAINER",
    HTML = "HTML",
    LOGO_BANNER = "LOGO_BANNER",
    PRODUCT_LIST = "PRODUCT_LIST",
    IMAGE_LIST = "IMAGE_LIST",
    VIDEO = "VIDEO",
    CATEGORY_GRID = "CATEGORY_GRID",
    STORE_CATEGORY_GRID = "STORE_CATEGORY_GRID",
    CATEGORY_NAVIGATION = "CATEGORY_NAVIGATION",
    STORE_CATEGORY_NAVIGATION = "STORE_CATEGORY_NAVIGATION",
    // CATEGORY_LIST = "CATEGORY_LIST",
    PRODUCT_CARD = "PRODUCT_CARD",
    CATEGORY_CARD = "CATEGORY_CARD",
    STORE_SELECT = "STORE_SELECT",
    SEARCH_BOX = "SEARCH_BOX",
    NAVIGATION = "NAVIGATION",
    SHOPPING_CART_ICON = "SHOPPING_CART_ICON",
    CONTACTS_ICON = "CONTACTS_ICON",
    JIE_LONG = "JIE_LONG",
    JIE_LONG_LIST = "JIE_LONG_LIST",
}

export enum EStatus {
    NORMAL = "NORMAL", STOP = "STOP"
}

export default class FssComponent {
    id: string = "";
    storeId: string = "";
    sellerId: string = "";
    createDatetimeText: string = "";
    status: EStatus = EStatus.NORMAL;
    componentName: string = "";
    componentType: EComponentType = EComponentType.HTML;
    content?: any;
    inLibrary: boolean = false;
    data: any;
}
