import * as React from "react";
import Button from "@material-ui/core/Button";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FssCoupon from "../model/FssCoupon";
import {RouteComponentProps} from "react-router-dom";
import {TextField} from "@material-ui/core";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import {i18n} from "../util/I18n";
import FssCouponComboBox from "./FssCouponComboBox";
import {Save} from "@material-ui/icons";
import FssUserCouponService from "../service/FssUserCouponService";
import FtPage from "../ui/FtPage";

interface JtUserCouponDetailPageProp extends RouteComponentProps<{ couponId: string }> {
}

export class JtUserCouponDetailPageState {
    couponList: Array<FssCoupon> = new Array<FssCoupon>();
    couponSelectOptions: Array<FssCoupon> = new Array<FssCoupon>();
    loading: boolean = false;
    coupon: FssCoupon = new FssCoupon();
    userCodes: string = "";
}

export default class FssUserCouponDetailPage extends React.Component<JtUserCouponDetailPageProp, JtUserCouponDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            couponList: new Array<FssCoupon>(),
            couponSelectOptions: new Array<FssCoupon>(),
            loading: false,
            coupon: new FssCoupon(),
            userCodes: "",
        };
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChangeCoupon = this.onChangeCoupon.bind(this);
    }

    componentDidMount(): void {
    }

    //返回
    onCancelClick(): void {
        this.props.history.goBack();
    }

    //保存详情
    onClickSave() {
        if (this.state.userCodes === null || this.state.userCodes === "") {
            FtSnackbar.showText(i18n("请输入用户编码"));
            return;
        }
        if (this.state.coupon.id === null || this.state.coupon.id === "") {
            FtSnackbar.showText(i18n("请选择优惠券"));
            return;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        FssUserCouponService.saveJtUserCoupon(this.state.coupon.id + "", this.state.userCodes, (mobileList: Array<string>) => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功"));
            this.onCancelClick();
        });
    }

    onChangeCoupon(item: FssCoupon | undefined) {
        if (item) {
            this.setState({coupon: item});
        }
    }

    render() {
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="contained"
                                                color="primary"
                                                startIcon={<Save/>}
                                                onClick={this.onClickSave}>{i18n("保存")}</Button>}>
            <FtPanel panelHeader={i18n("补发优惠券")}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"4"} cellWidthL={"4"} cellHorizontalSpace={32} maxRowWidth={"l"}
                           cellVerticalSpace={16}>
                        <TextField id="userCodes" multiline required={true}
                                   onChange={(event) => {
                                       this.setState({userCodes: event.target.value ? event.target.value : ""});
                                   }}
                                   value={this.state.userCodes} label={i18n("用户编码,逗号分隔")}
                                   type={"text"} style={{width: "100%"}}/>
                        <FssCouponComboBox label={i18n("优惠券")}
                                           onChangeCoupon={this.onChangeCoupon}
                                           selectedCoupon={this.state.coupon}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </FtPage>;
    }
}
