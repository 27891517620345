import * as React from "react";
import {CSSProperties, HTMLAttributes, ReactElement} from "react";
import {ButtonBase, Typography} from "@material-ui/core";
import FtComponent, {FtComponentState} from "./FtComponent";

export interface FtIconLabelProp extends HTMLAttributes<HTMLDivElement> {
    labelNode?: ReactElement;
    label?: string;
    iconNode?: ReactElement;
    iconSrc?: string;
    onClickMe?: (id?: string) => void;
    labelStyle?:CSSProperties;
    iconStyle?:CSSProperties;
}

export class FtIconLabelState extends FtComponentState {

}

export default class FtIconLabel extends FtComponent<FtIconLabelProp, FtIconLabelState> {
    constructor(props: any) {
        super(props);
    }

    render() {
        let labelElement: ReactElement = <div></div>;
        if (this.props.labelNode) {
            labelElement = this.props.labelNode;
        } else if (this.props.label)
            labelElement = <Typography style={this.props.labelStyle}>{this.props.label}</Typography>;
        let iconElement: ReactElement = <div></div>;
        if (this.props.iconNode) {
            iconElement = this.props.iconNode;
        } else if (this.props.iconSrc)
            iconElement = <img src={this.props.iconSrc} style={this.props.iconStyle?this.props.iconStyle:{width: "100%"}}></img>;
        return <div style={this.props.style}>
            <ButtonBase style={{display: "block", width: "100%", height: "100%"}} onClick={() => {
                if (this.props.onClickMe)
                    this.props.onClickMe(this.props.id);
            }}>
                {iconElement}
                {labelElement}
            </ButtonBase>
        </div>;
    }
}
