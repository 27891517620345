import * as React from "react";
import {ReactElement} from "react";
import {IconButton, Input, InputAdornment} from "@material-ui/core";
import {Search} from "@material-ui/icons";
import FssSearchBoxCompData from "./data/FssSearchBoxCompData";
import FssAbstractCompProp from "./FssAbstractCompProp";
import FtComponent, {FtComponentState} from "../../ui/FtComponent";
import {i18n} from "../../util/I18n";
import FtAppBar from "../../ui/FtAppBar";
import {theme} from "../../mainframe/FssSellerAdminTheme";

interface FssSearchBoxCompProp extends FssAbstractCompProp {
    sellerId?: string;
    editMode?: boolean;
    rightNode?: ReactElement;
    showBackIcon?: boolean;
}

export class FssSearchBoxCompState extends FtComponentState {
}

export default class FssSearchBoxComp extends FtComponent<FssSearchBoxCompProp, FssSearchBoxCompState> {
    constructor(props: any) {
        super(props);
        this.state = {
            stateKey: "FssSearchBoxComp" + this.props.location.pathname
        };
        this.onClickSearchInput = this.onClickSearchInput.bind(this);
        if (this.props.history.action == "POP") {
            super.popStateCache();
        }
    }

    componentDidMount(): void {

    }

    render() {
        const compData = this.props.component.data as FssSearchBoxCompData;
        if (compData != null) {
            return <FtAppBar position={"static"} cellWidthS={"2,8,2"}
                             style={{backgroundColor: compData.backgroundColor, marginTop: 20}}
                             leftNode={<IconButton/>}
                             centerNode={<Input
                                 disabled={true}
                                 defaultValue=""
                                 placeholder={i18n("查找订单")}
                                 disableUnderline={true}
                                 startAdornment={
                                     <InputAdornment position="start">
                                         <Search/>
                                     </InputAdornment>
                                 }
                                 style={{
                                     color: theme.palette.primary.main,
                                     backgroundColor: "white",
                                     paddingLeft: 8,
                                     borderRadius: 5,
                                     width: "100%"
                                 }}
                                 onClick={this.onClickSearchInput}/>}
                             rightNode={this.props.rightNode}>
            </FtAppBar>
        } else {
            return null;
        }
    }

    onClickSearchInput() {
        //如果是平台APP，且当前商家是默认商家，则显示 APP 范围的产品
        if (this.props.sellerId != null && this.props.sellerId.length > 0) {
            this.props.history.push("/search/" + this.props.sellerId);
        } else {
            this.props.history.push("/search/website/");
        }
    }

}
