import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssShippingFeeRule from "../model/FssShippingFeeRule";

export default class FssShippingFeeRuleService extends FssHttpService {


    static getShippingFeeRulePaged(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null,
                                   sortDirection: "asc" | "desc" | null, pageNo: number, pageSize: number,
                                   successFunction: (data: FtPagedList<FssShippingFeeRule>) => void): void {
        FssHttpService.search("/api/shippingFeeRule/getShippingFeeRulePaged", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }
    static deleteShippingFeeRule(idList: Array<string>, successFunction: (data: string) => void): void {
        this.postJson("/api/shippingFeeRule/deleteShippingFeeRule", idList, successFunction, this.handleError);
    }
    static getShippingFeeRuleById(shippingFeeRuleId: string, successFunction: (data: FssShippingFeeRule) => void): void {
        FssHttpService.get("/api/shippingFeeRule/getShippingFeeRuleById", {shippingFeeRuleId: shippingFeeRuleId}, successFunction, FssHttpService.handleError);
    }
    static saveShippingFeeRule(data: FssShippingFeeRule, successFunction: (data: FssShippingFeeRule) => void): void {
        this.postJson("/api/shippingFeeRule/saveShippingFeeRule", data, successFunction, FssHttpService.handleError);
    }

}
