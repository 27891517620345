import * as React from "react";
import { ChangeEvent, ReactNode } from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import { Button, TextField } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import FtDataTable, { FtDataTableAdapter, FtDataTableColumnProps } from "../ui/FtDataTable";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import FtComponent, { FtComponentState } from "../ui/FtComponent";
import FtState from "../ui/FtState";
import FssDeliveryProvider, { EDeliveryType, EDeliveryProviderStatus } from "../model/FssDeliveryProvider";
import FtSnackbar from "../ui/FtSnackbar";
import FtMessageDialog from "../ui/FtMessageDialog";
import {i18n} from "../util/I18n";
import FssDeliveryProviderService from "../service/FssDeliveryProviderService";
import FtRow from "../ui/FtRow";

export interface FssDeliveryProviderListPageProp {
}

class FssDeliveryProviderListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class JtTableAdapter extends FtDataTableAdapter<FssDeliveryProvider> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssDeliveryProviderService.getPageDeliveryProviderList(this.keyword, this.filterColumns, null, null, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssDeliveryProvider>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}
export default class FssDeliveryProviderListPage extends FtComponent<FssDeliveryProviderListPageProp, FssDeliveryProviderListPageState> {


    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        // const column0: FtDataTableColumnProps = new FtDataTableColumnProps("deliveryProviderId", "Id");
        // column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
        //     const jtDistributionSetting: FssDeliveryProvider = rowData as FssDeliveryProvider;
        //     return <Link to={"/distributionSettings/" + jtDistributionSetting.id}>{jtDistributionSetting.id}</Link>
        // };
        // columnProps.push(column0);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("deliveryProviderName", i18n("模板名称"));
        column2.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const jtDistributionSetting: FssDeliveryProvider = rowData as FssDeliveryProvider;
            return <Link to={"/distributionSettings/" + jtDistributionSetting.id}>{jtDistributionSetting.deliveryProviderName}</Link>
        };
        columnProps.push(column2);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("deliveryProviderCode", i18n("编码"));
        columnProps.push(column1);
        /*const column3: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("配送时间"));
        columnProps.push(column3);*/
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("deliveryProviderStatusText", i18n("状态"));
        columnProps.push(column5);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("deliveryTypeText", i18n("配送类型"));
        columnProps.push(column4);
        /*const column6: FtDataTableColumnProps = new FtDataTableColumnProps("apiTrackUrl", i18n("订单跟踪API网址"));
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("apiOrderUrl", i18n("下单API网址"));
        columnProps.push(column7);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("contactName", i18n("联系人"));
        columnProps.push(column8);
        const column9: FtDataTableColumnProps = new FtDataTableColumnProps("pickUpAddress", i18n("自取地址"));
        columnProps.push(column9);
        const column10: FtDataTableColumnProps = new FtDataTableColumnProps("contactPhone", i18n("联系电话"));
        columnProps.push(column10);*/


        let tableAdapter: JtTableAdapter;
        const cacheState: FssDeliveryProviderListPageState = FtState.pop("FssDeliveryProviderListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = { tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssDeliveryProviderListPage" };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this)

    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("配送列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                toolbarWithSelect={this.getToolbarWithSelect()}
                toolbarNoSelect={this.getToolbarNoSelect()}
                filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }


    private onClickDelete() {
        const rows = this.state.tableAdapter.selectedRow;
        if (rows != null && rows.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除选定的{0}条记录吗？",rows.length+""), () => {
                const ids: Array<string> = [];
                rows.forEach((value: FssDeliveryProvider, index: number) => {
                    ids.push(value.id);
                });
                FssDeliveryProviderService.deleteDeliveryProvider(ids, (data: FssDeliveryProvider) => {
                    FtSnackbar.showText(i18n("删除成功！"));
                    this.state.tableAdapter.loadData();
                });
            });
        }
    }
    getFilterPanel(): ReactNode {
        return <FtRow>
            <TextField required id="startDate" label={i18n("开始日期")} type="date" style={{ width: 150 }}
                value={this.state.tableAdapter.getFilterColumnValue("startDate") as string}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.state.tableAdapter.filterColumns.set("startDate", event.target.value);
                    // this.setState({ dateSection: -1 });
                    this.forceUpdate();
                }}
            />
            <TextField required id="endDate" label={i18n("结束日期")} type="date" style={{ width: 150 }}
                value={this.state.tableAdapter.getFilterColumnValue("endDate") as string}
                InputLabelProps={{
                    shrink: true,
                }} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    this.state.tableAdapter.filterColumns.set("endDate", event.target.value);
                    // this.setState({ dateSection: -1 });
                    this.forceUpdate();
                }}
            />


            <FormControl style={{ minWidth: 150 }}>
                <InputLabel> {i18n("状态")} </InputLabel>
                <Select id="status" value={this.state.tableAdapter.getFilterColumnValue("status")}
                    onChange={(event: any) => {
                        this.state.tableAdapter.filterColumns.set("status", event.target.value as number);
                        this.forceUpdate();
                    }}>
                    <MenuItem value={EDeliveryProviderStatus.NORMAL}>{i18n("正常")}</MenuItem>
                    <MenuItem value={EDeliveryProviderStatus.STOP}>{i18n("停用")}</MenuItem>
                </Select>
            </FormControl>

            <FormControl style={{ minWidth: 150 }}>
                <InputLabel>{i18n("配送类型")}</InputLabel>
                <Select
                    id="deliveryType" value={this.state.tableAdapter.getFilterColumnValue("deliveryType")}
                    onChange={(event: any) => {
                        this.state.tableAdapter.filterColumns.set("deliveryType", event.target.value as number);
                        this.forceUpdate();
                    }}>
                    <MenuItem value={EDeliveryType.DELIVERY_TO_DOOR}>{i18n("送货到家")}</MenuItem>
                    <MenuItem value={EDeliveryType.SELF_PICK_UP}>{i18n("货到自取")}</MenuItem>

                </Select>
            </FormControl>
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/distributionSettings/0"}>
                <Button>
                    <Add />{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }
    getToolbarWithSelect(): ReactNode {
        return <div>
            <Button onClick={this.onClickDelete}>
                {i18n("删除")}
            </Button>
        </div>;
    }
}
