import * as React from "react";
import {ReactNode} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {i18n} from "../util/I18n";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import JtTextField from "../ui/JtTextField";
import FtRow from "../ui/FtRow";
import {Language} from "../data/AppClientInfo";
import FssTranslation from "../model/FssTranslation";
import FtSnackbar from "../ui/FtSnackbar";
import FtToast from "../ui/FtToast";
import TranslateIcon from '@material-ui/icons/Translate';
import FssClientData from "../data/FssClientData";
import FssTranslationService from "../service/FssTranslationService";

export interface FtTranslationDialogCompProp{
    objectId: string;
    groupKey: string;
    itemKey: string;
    fullScreen?: boolean;
    dialogTitle?: string;
    defaultText?: string;
    showCloseIcon?: boolean;
    rows?: number;
}

class FtTranslationDialogCompState {
    zh_CN: string = "";
    zh_TW: string = "";
    en_CA: string = "";
    updateMap: Map<string, boolean> = new Map<string, boolean>();
    open: boolean = false;
}

export default class FssTranslationDialogComp extends React.Component<FtTranslationDialogCompProp, FtTranslationDialogCompState> {
    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            zh_CN: "",
            zh_TW: "",
            en_CA: "",
            updateMap: new Map<string, boolean>()
        };

        this.getLanguageNode = this.getLanguageNode.bind(this);
        this.getDialogContent = this.getDialogContent.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.saveTranslation = this.saveTranslation.bind(this);

        this.getTranslationIcon = this.getTranslationIcon.bind(this);
        this.getDialog = this.getDialog.bind(this);
        this.onDialogClick = this.onDialogClick.bind(this);
        this.loadTranslationContent = this.loadTranslationContent.bind(this);
    }

    onClickSave () {
        const translationArr = new Array<FssTranslation>();
        if (this.state.updateMap.get(Language.zh_CN) || this.state.zh_CN.trim().length > 0) {
            const translation: FssTranslation = FssTranslation.newInstance(this.props.objectId,this.props.groupKey,this.props.itemKey,Language.zh_CN,this.state.zh_CN);
            translationArr.push(translation);
        }
        if (this.state.updateMap.get(Language.zh_TW) || this.state.zh_TW.trim().length > 0) {
            const translation: FssTranslation = FssTranslation.newInstance(this.props.objectId,this.props.groupKey,this.props.itemKey,Language.zh_TW,this.state.zh_TW);
            translationArr.push(translation);
        }
        if (this.state.updateMap.get(Language.en_CA) || this.state.en_CA.trim().length > 0) {
            const translation: FssTranslation = FssTranslation.newInstance(this.props.objectId,this.props.groupKey,this.props.itemKey,Language.en_CA,this.state.en_CA);
            translationArr.push(translation);
        }
        if (translationArr.length > 0) {
            this.saveTranslation(translationArr);
        } else {
            FtSnackbar.showText(i18n("请输入任意一项进行保存"));
        }
    }

    saveTranslation (translationArr: Array<FssTranslation>) {
        FtToast.showToastLoading(i18n("正在保存"));
        translationArr.forEach((translation: FssTranslation, index: number)=>{
            FssTranslationService.saveTranslation(translation, ()=>{
                if ((index + 1) == translationArr.length) {
                    FtToast.closeToast();
                    FtSnackbar.showText(i18n("保存成功！"));
                    this.onDialogClick();
                }
            });
        });
    }

    onDialogClick () {
        this.setState({open: !this.state.open});
    }

    render() {
        return <div>
            {this.getTranslationIcon()}
            {this.getDialog()}
        </div>;
    }

    getDialog(): ReactNode {
        return <Dialog open={this.state.open} fullScreen={this.props.fullScreen}>
            <DialogTitle style={{paddingBottom: 0, position: "relative"}}>{this.props.dialogTitle}
                {this.props.showCloseIcon &&
                <IconButton onClick={this.onDialogClick}
                            style={{position: "absolute", top: 5, right: 5}}>
                    <CloseIcon/>
                </IconButton>}
            </DialogTitle>
            <DialogContent>
                {this.getDialogContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={this.onDialogClick}
                        variant={"contained"}>
                    {i18n("取消")}
                </Button>
                <Button onClick={this.onClickSave} color="primary" variant={"contained"}>
                    {i18n("保存")}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    getTranslationIcon(): ReactNode {
        const appWebsite=FssClientData.getData().appWebsite;
        if (appWebsite.supportLanguages !=null && appWebsite.supportLanguages.length>0) {
            return <IconButton onClick={this.loadTranslationContent}>
                <TranslateIcon />
            </IconButton>;
        } else {
            return null;
        }
    }

    loadTranslationContent() {
        FtToast.showToastLoading();
        FssTranslationService.getTranslationContent(this.props.objectId, this.props.groupKey, this.props.itemKey,"",(data: Array<FssTranslation>)=>{
            FtToast.closeToast();
            let zh_CN = "";
            let zh_TW = "";
            let en_CA = "";
            const updateMap = new Map<Language, boolean>();
            if (data && data.length > 0) {
                data.forEach((translation: FssTranslation)=>{
                    switch (translation.lang as Language) {
                        case Language.en_CA:
                            en_CA = translation.content;
                            updateMap.set(Language.en_CA, true);
                            break;
                        case Language.zh_CN:
                            zh_CN = translation.content;
                            updateMap.set(Language.zh_CN, true);
                            break;
                        case Language.zh_TW:
                            zh_TW = translation.content;
                            updateMap.set(Language.zh_TW, true);
                            break;
                    }
                });
            };
            this.setState({open: true, zh_CN: zh_CN, zh_TW: zh_TW, en_CA: en_CA, updateMap: updateMap});
        });
    }

    getDialogContent () {
        let rows = 0;
        if (this.props.rows !== undefined && this.props.rows > 1) {
            rows = this.props.rows;
        }
        const appWebsite=FssClientData.getData().appWebsite;
        if (appWebsite.language&&appWebsite.supportLanguages) {
            return <FtRow cellWidthS={"1"} cellVerticalSpace={16}>
                <JtTextField label={this.getLanguageText(appWebsite.language) + "（系统默认）"}
                             disabled={true}
                             multiline={this.props.rows !== undefined}
                             rows={rows}
                             style={{width: "100%"}} variant={"outlined"}
                             value={this.props.defaultText}/>
                { (appWebsite.supportLanguages !=null && appWebsite.supportLanguages.length>0) &&
                    appWebsite.supportLanguages.map((language: string)=>{
                    if (language === appWebsite.language) {
                        return null;
                    } else {
                        return this.getLanguageNode(language, rows);
                    }
                })}
            </FtRow>;
        }
    }

    getLanguageNode (language: string, rows: number) {
        let textField: ReactNode = null;
        switch (language as Language) {
            case Language.en_CA:
                textField = <JtTextField label={this.getLanguageText(language)}
                                         onChange={(event)=>{this.setState({en_CA: event.target.value});}}
                                         style={{width: "100%"}} variant={"outlined"}
                                         multiline={this.props.rows !== undefined}
                                         rows={rows}
                                         value={this.state.en_CA}/>;
                break;
            case Language.zh_CN:
                textField = <JtTextField label={this.getLanguageText(language)}
                                         onChange={(event)=>{this.setState({zh_CN: event.target.value});}}
                                         style={{width: "100%"}} variant={"outlined"}
                                         multiline={this.props.rows !== undefined}
                                         rows={rows}
                                         value={this.state.zh_CN}/>;
                break;
            case Language.zh_TW:
                textField = <JtTextField label={this.getLanguageText(language)}
                                         onChange={(event)=>{this.setState({zh_TW: event.target.value});}}
                                         style={{width: "100%"}} variant={"outlined"}
                                         multiline={this.props.rows !== undefined}
                                         rows={rows}
                                         value={this.state.zh_TW}/>;
                break;
        }
        return textField;
    }

    getLanguageText (language: string) {
        let text = "";
        switch (language as Language) {
            case Language.en_CA:
                text = "English";
                break;
            case Language.zh_CN:
                text = "简体中文";
                break;
            case Language.zh_TW:
                text = "繁体中文";
                break;
        }
        return text;
    }
}







