import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import Typography from "@material-ui/core/Typography";
import FtUtil from "../util/FtUtil";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FtSnackbar from "../ui/FtSnackbar";
import {red} from "@material-ui/core/colors";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtToast from "../ui/FtToast";
import {i18n} from "../util/I18n";
import FssOrder from "../model/FssOrder";
import FssOrderService from "../service/FssOrderService";

interface FssRefundDialogProp {
    open: boolean;
    onClose?: () => void;
    order: FssOrder;
    onRefundSuccess:(order: FssOrder)=>void;
    onRefundFail:(error:any)=>void;
}

class FssRefundDialogState {
    refundAmountOption: "all" | "partial" | "overPaid" = "all";
    refundTo: "balance" | "source" = "balance";
    refundAmount?: number;
    reason:string= "";
}

export default class FssRefundDialog extends React.Component<FssRefundDialogProp, FssRefundDialogState> {
    constructor(props: any) {
        super(props);
        let refundAmount:number=0;
        let refundAmountOption:"all" | "partial" | "overPaid"="partial";
        if (this.props.order.totalPaid > this.props.order.total) {
            refundAmount = FtUtil.toCurrency(this.props.order.totalPaid - this.props.order.total);
            refundAmountOption= "overPaid";
        }
        this.state = {
            refundAmountOption: refundAmountOption,
            refundAmount: refundAmount,
            refundTo: "balance",
            reason: ""
        };
        this.onClickRefund = this.onClickRefund.bind(this);
    }

    componentDidMount(): void {

    }

    render() {
        let refundScoreOnly:boolean=false;
        if(this.state.refundAmount!=null&&this.props.order.pointDeducted>this.state.refundAmount)
            refundScoreOnly=true;

        return <Dialog open={this.props.open} onClose={this.props.onClose} maxWidth={"sm"}>
            <FtPanel style={{padding: 16}}>
                <FtGrid>
                    <FtRow cellWidthS={"1"}>
                        <FtTextFieldNumber type={"currency"} label={i18n("本次退款金额")} style={{width: "100%"}}
                                           inputStyle={{fontSize: 36, textAlign: "center", color: red[500]}}
                                           value={FtUtil.toCurrency(this.state.refundAmount != null ? this.state.refundAmount : this.props.order.totalPaid)}
                                           helperText={i18n("订单可退金额：") + FtUtil.toCurrencyText(this.props.order.totalPaid)}
                                           onChangeValue={(value) => {
                                               this.setState({refundAmount: value})
                                           }}
                                           disabled={this.state.refundAmountOption == "all" || this.state.refundAmountOption == "overPaid"}>
                        </FtTextFieldNumber>
                        {this.props.order.pointDeducted>0&&<Typography color={"secondary"}>
                            {i18n("将优先退回积分支付的金额 (订单使用{0}积分, 支付{1}）",this.props.order.pointUsed+"",FtUtil.toCurrencyText(this.props.order.pointDeducted))}
                        </Typography>}
                    </FtRow>

                    <FtRow cellWidthS={"1"} style={{minWidth: 300}}>
                        <RadioGroup row value={this.state.refundAmountOption} onChange={(event: any) => {
                            if (event.target.value != null && event.target.value == "all")
                                this.setState({
                                    refundAmountOption: event.target.value,
                                    refundAmount: this.props.order.totalPaid
                                });
                            else if (event.target.value != null && event.target.value == "overPaid")
                                this.setState({
                                    refundAmountOption: event.target.value,
                                    refundAmount: this.props.order.totalPaid - this.props.order.total
                                });
                            else
                                this.setState({refundAmountOption: event.target.value,refundAmount:0});
                        }}>
                            <FormControlLabel value="all" control={<Radio color="primary"/>} label={i18n("全部退款")}/>
                            {this.props.order.totalPaid > this.props.order.total
                            && <FormControlLabel value="overPaid" control={<Radio color="primary"/>} label={i18n("退差额")}/>}
                        </RadioGroup>
                    </FtRow>
                    {refundScoreOnly==false&&<FtRow cellWidthS={"1"} style={{minWidth: 200}}>
                        <RadioGroup row value={this.state.refundTo} onChange={(event: any) => {
                            this.setState({refundTo: event.target.value})
                        }}>
                            <FormControlLabel value="balance" control={<Radio color="primary"/>} label={i18n("退入账户余额")}/>
                            <FormControlLabel value="source" control={<Radio color="primary"/>} label={i18n("原路退回")}/>
                        </RadioGroup>
                    </FtRow>}
                </FtGrid>
            </FtPanel>
            <DialogActions>
                <FtRow cellHorizontalAlign={"justify"} style={{width: "100%"}} cellWidthS={"1,1"}>
                    <Button onClick={this.props.onClose}>{i18n("关闭")}</Button>
                    <Button color={"primary"} variant={"outlined"} onClick={this.onClickRefund}>
                        {i18n("退款")} {this.state.refundAmount != null ? FtUtil.toCurrencyText(this.state.refundAmount) : FtUtil.toCurrencyText(this.props.order.totalPaid)}
                    </Button>
                </FtRow>
            </DialogActions>
        </Dialog>
    }

    onClickRefund() {
        if (this.state.refundAmount != null && this.state.refundAmount > this.props.order.totalPaid) {
            FtSnackbar.showText(i18n("退款金额不能大于可退金额"));
            return;
        }
        if (this.state.refundAmount == null || this.state.refundAmount <= 0) {
            FtSnackbar.showText(i18n("请输入退款金额"));
            return;
        }
        FtMessageDialog.dialog.openDialog(i18n("此操作不可撤销！确定要给用户退款{0}元吗？",FtUtil.toCurrencyText(this.state.refundAmount)), () => {
            FtToast.showToastLoading();
            FssOrderService.refundOrder(this.state.refundTo, this.props.order.id, this.state.refundAmountOption,
                this.state.refundAmount, this.state.reason,this.props.onRefundSuccess,this.props.onRefundFail);
        })
    }
}
