import FssShippingFeeRule from "./FssShippingFeeRule";

export enum EStatus{
    NORMAL="NORMAL",STOP="STOP"
}
export enum ECalculateUnit {
    WEIGHT="WEIGHT",
    AMOUNT="AMOUNT",
    SIZE="SIZE",
    DISTANCE="DISTANCE",
    FIXED="FIXED"
}

export default class FssShippingFeeTemplate {
    id: string="";
    sellerId: string = "";
    storeId: string = "";
    templateName: string="";
    priority: number=0;
    status: EStatus=EStatus.NORMAL;
    ruleList:Array<FssShippingFeeRule>=[];
    description: string="";
    calculateUnit?: ECalculateUnit=ECalculateUnit.WEIGHT;
    packagingWeight: number=0;
    packagingFee: number=0;
    deliveryProviderId:string="";
    productIds:string="";
    templateNameText: string="";

    validate(): string {
        return "";
    }
}

