import React, {ReactNode} from "react";
import {Avatar, Button, Typography} from "@material-ui/core";
import FtTextFieldString from "../ui/FtTextFieldString";
import {chatWebsocket} from "./FssChatWebsocket";
import {green, grey} from "@material-ui/core/colors";
import FssMessage from "../model/FssMessage";
import FssClientData from "../data/FssClientData";
import {i18n} from "../util/I18n";
import FssConversation from "../model/FssConversation";
import FtRow from "../ui/FtRow";
import FssMessageService from "../service/FssMessageService";
import FssConversationUser from "../model/FssConversationUser";

interface FssChatConversationCompProp {
    inputPosition?: "" | 'fixedBottom' | 'fixedTop' | 'absolute' | 'stickyTop' | 'stickyBottom' | 'static' | 'relative';
    height: number;
    conversation: FssConversation;
}

export class FssChatConversationCompState {
    inputContent: string = "";
}

export default class FssChatConversationComp extends React.Component<FssChatConversationCompProp, FssChatConversationCompState> {
    mainElement!: HTMLDivElement;
    bottomElement!: HTMLDivElement;
    messageListElement!: HTMLDivElement;

    constructor(props: any) {
        super(props);
        this.state = {inputContent: ""};
        this.onClickSend = this.onClickSend.bind(this);
        this.onKeyDown = this.onKeyDown.bind(this);
        this.getMessageListDiv = this.getMessageListDiv.bind(this);
        this.onMessagesChanged = this.onMessagesChanged.bind(this);
    }

    componentDidMount(): void {
    }

    componentWillUnmount() {

    }

    onMessagesChanged(messages: Array<FssMessage>, markReadCallback?: () => void) {
        const conversation: FssConversation = this.props.conversation;
        if (messages != null && messages.length > 0 && conversation != null) {
            const lastMessage: FssMessage = messages[messages.length - 1];
            FssMessageService.markRead(conversation.id, lastMessage.id, (data: FssConversationUser) => {
                if (markReadCallback) {
                    markReadCallback();
                }
            });
        }
        this.forceUpdate(() => {
            const messageListElement: HTMLElement | null = document.getElementById("messageListDiv");
            const mainElement: HTMLElement | null = document.getElementById("mainDiv");
            if (messageListElement != null && mainElement != null) {
                messageListElement.scrollTo(0, messageListElement.scrollHeight);
            }
        });
        if (this.messageListElement) {
            this.messageListElement.scrollTo({top: this.messageListElement.scrollHeight});
        }
    }

    render() {
        return <div id={"mainDiv"}
                    ref={element => {
                        if (element) this.mainElement = element;
                    }}>
            {this.getMessageListDiv()}
            <div style={{width: "100%", position: "sticky", bottom: 0, height: 48}}>
                <FtTextFieldString style={{width: "100%", flexGrow: 2, backgroundColor: "white"}}
                                   multiline={true} onKeyPress={this.onKeyDown}
                                   variant={"outlined"}
                                   value={this.state.inputContent}
                                   onChangeValue={value => {
                                       this.setState({inputContent: value ? value : ""})
                                   }}
                                   InputProps={{style: {borderRadius: 0, maxHeight: 53}}}
                                   endAdornment={<Button size={"small"} variant={"contained"} color={"primary"}
                                                         onClick={this.onClickSend}>{i18n("Send")}</Button>}/>
            </div>
        </div>;
    }

    private getMessageListDiv() {
        const conversation: FssConversation = this.props.conversation;
        if (conversation != null) {
            return <div id={"messageListDiv"}
                        style={{
                            height: this.props.height - 53 - 16,
                            overflowY: "scroll",
                            paddingTop: 8,
                            paddingBottom: 8
                        }}
                        ref={(element: any) => {
                            if (element) {
                                this.messageListElement = element
                            }
                        }}>
                {conversation.messages != null && conversation.messages.map((value: FssMessage) => {
                    const isMine: boolean = value.fromUserId != null && value.fromUserId === FssClientData.getData().webSession.userId;
                    if (isMine) {
                        return <FtRow justifyContent={"flex-end"} cellWidthS={"3,7"} cellVerticalSpace={8}>
                            <div/>
                            <div style={{display: "flex", justifyContent: "flex-end", alignItems: "flex-start"}}>
                                {this.getMessageItem(value, isMine)}
                                <Avatar style={{maxWidth: 40}} src={value.fromUserAvatarUrl}/>
                            </div>
                        </FtRow>
                    } else {
                        return <FtRow justifyContent={"flex-end"} cellWidthS={"7,3"} cellVerticalSpace={8}>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <Avatar style={{maxWidth: 40}} src={value.fromUserAvatarUrl}/>
                                {this.getMessageItem(value, isMine)}
                            </div>
                            <div/>
                        </FtRow>
                    }
                })}
            </div>
        }
        return <div style={{height: "100%"}}/>
    }

    private getMessageItem(value: FssMessage, isMine: boolean) {
        return <div style={{paddingLeft: isMine ? 0 : 4, paddingRight: isMine ? 4 : 0, position: "relative"}}>
            {!isMine &&
            <div style={{
                borderTop: "6px solid transparent", borderBottom: "6px solid transparent",
                borderRight: "6px solid", position: "absolute", top: 8, left: 4,
                borderRightColor: grey[300]
            }}/>}
            {isMine &&
            <div style={{
                borderTop: "6px solid transparent", borderBottom: "6px solid transparent",
                borderLeft: "6px solid", position: "absolute", top: 8, right: 4,
                borderLeftColor: green[300]
            }}/>}
            <div>
                <Typography variant={"subtitle2"} style={{
                    color: "black",
                    backgroundColor: isMine ? green[300] : grey[300],
                    padding: 4,
                    borderRadius: 2,
                    marginLeft: isMine ? 0 : 6,
                    marginRight: isMine ? 6 : 0,
                    width: "fit-content",
                    wordBreak: "break-all",
                    float: isMine ? "right" : "left",
                }}>{this.getContentComp(value.content)}
                </Typography>
            </div>
            <div style={{
                paddingLeft: 4,
                paddingRight: 4,
                display: "flex",
                justifyContent: "flex-end",
                float: isMine ? "right" : "left",
            }}>
                <Typography variant={"subtitle2"} style={{fontSize: "60%", color: grey[500]}}>
                    {FssClientData.formatYYYYmmddHHmmss(value.messageTime)}</Typography>
            </div>
        </div>
    }

    getContentComp(content: string): ReactNode | string {
        if (content != null && (content.startsWith("http://") || content.startsWith("https://"))) {
            return <a target={"_blank"} href={content}
                      style={{textDecoration: "underline", color: "black"}}>{content}</a>
        }
        return content;
    }

    private onKeyDown(event: any) {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.onClickSend();
        }
    }

    private onClickSend() {
        if (this.state.inputContent != null && this.state.inputContent.trim().length > 0) {
            const conversation: FssConversation = this.props.conversation;
            const message: FssMessage = new FssMessage();
            message.content = this.state.inputContent;
            message.conversationId = conversation.id;
            chatWebsocket.publish(message);
            console.log("send message: " + this.state.inputContent);
            this.setState({inputContent: ""});
        }
    }
}
