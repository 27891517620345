import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssShippingSchedule from "../model/FssShippingSchedule";

export default class FssShippingScheduleService extends FssHttpService {

    /**
     * 获取配送时间列表
     */
    static getPagedList(searchKeyword: string,deliveryProviderId:string, pageNo: number, pageSize: number,
                        successFunction: (data: FtPagedList<FssShippingSchedule>) => void): void {
        FssHttpService.get("/api/shippingSchedule/getShippingSchedulePagedList", {
            searchKeyword: searchKeyword,
            deliveryProviderId:deliveryProviderId,
            page: pageNo,
            size: pageSize
        }, successFunction, FssHttpService.handleError);
    }

    /**
     * 根据id获取配送时间详情
     */
    static getShippingScheduleById(shippingScheduleId: string, successFunction: (data: FssShippingSchedule) => void): void {
        FssHttpService.get("/api/shippingSchedule/getShippingScheduleById", { shippingScheduleId: shippingScheduleId }, successFunction, FssHttpService.handleError);
    }

    /**
     * 修改 / 添加 配送时间
     */
    static saveShippingSchedule(shippingSchedule: FssShippingSchedule, successFunction: (data: FssShippingSchedule) => void): void {
        FssHttpService.postJson("/api/shippingSchedule/saveShippingSchedule",  shippingSchedule, successFunction, FssHttpService.handleError);
    }

    /**
     * 删除配送时间
     */
    static permanentDelete(shippingScheduleIds:Array<string>, successFunction: (data: FssShippingSchedule) => void) {
        FssHttpService.postFormData("/api/shippingSchedule/deleteBatchShippingSchedule", {shippingScheduleIds:shippingScheduleIds}, successFunction, FssHttpService.handleError);
    }

}
