import * as React from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import FssShippingFeeTemplate from "../model/FssShippingFeeTemplate";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtState from "../ui/FtState";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {ReactNode} from "react";
import FtRow from "../ui/FtRow";
import {Button, TextField} from "@material-ui/core";
import {ChangeEvent} from "react";
import {Link} from "react-router-dom";
import {Add} from "@material-ui/icons";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FssShippingFeeTemplateService from "../service/FssShippingFeeTemplateService";

interface JtShippingFeeTempListPageProp {
    deliveryProviderId: string;

}
class JtShippingFeeTempListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}
class JtTableAdapter extends FtDataTableAdapter<FssShippingFeeTemplate> {
    defaultFilter: Map<string, any> = new Map<string, any>();
    loadData(): void {
        FtToast.showToastLoading("loading");
        let deliveryProviderId:number = this.defaultFilter.get("deliveryProviderId");
        FssShippingFeeTemplateService.getStoreShippingFeeTemplateList(this.keyword, deliveryProviderId,this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssShippingFeeTemplate>) => {
            FtToast.closeToast();
            this.pagedList = result;
            this.fireUpdateData();
        })
    }
    resetSearch() {
        super.resetSearch();
        if (this.defaultFilter) {
            this.defaultFilter.forEach((value, key) => {
                this.filterColumns.set(key, value);
            });
        }
    }
}
export default class JtShippingFeeTempListPage extends FtComponent<JtShippingFeeTempListPageProp, JtShippingFeeTempListPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        // const column0: FtDataTableColumnProps = new FtDataTableColumnProps("shippingFeeTemplateId", i18n("ID"));
        // column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
        //     const shippingFeeTemplate: FssShippingFeeTemplate = rowData as FssShippingFeeTemplate;
        //     return <Link to={"/store/shippingFeeTemplateDetail/" + shippingFeeTemplate.id+"/"+shippingFeeTemplate.deliveryProviderId}>{shippingFeeTemplate.id}</Link>
        // };
        // columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("templateName", i18n("模板名称"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const shippingFeeTemplate: FssShippingFeeTemplate = rowData as FssShippingFeeTemplate;
            return <Link to={"/store/shippingFeeTemplateDetail/" + shippingFeeTemplate.id+"/"+shippingFeeTemplate.deliveryProviderId}>{shippingFeeTemplate.templateName}</Link>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("description", i18n("说明"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("priority", i18n("优先级"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("status", i18n("状态"));
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("calculateUnit", i18n("计费类型"));
        columnProps.push(column5);
        /*const column6: FtDataTableColumnProps = new FtDataTableColumnProps("packagingWeight", i18n("包装重量"), "right");
        columnProps.push(column6);*/
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("packagingFee", i18n("包装费"));
        columnProps.push(column7);

        let tableAdapter: JtTableAdapter;
        const cacheState: JtShippingFeeTempListPageState = FtState.pop("shippingFeeTempListPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.defaultFilter.set("deliveryProviderId",this.props.deliveryProviderId);
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
    });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            stateKey: "shippingFeeTempListPageState"
        }
        this.onChangePageNo = this.onChangePageNo.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            {this.getFtDataTable()}
        </div>;
    }

    getFtDataTable(): ReactNode {
        return <FtDataTable title={i18n("送货运费模板")}
                            columnProps={this.state.columnProps}
                            adapter={this.state.tableAdapter}
                            filterPanel={this.getFilterPanel()}
                            toolbarNoSelect={this.getToolbarNoSelect()}>
        </FtDataTable>
    }
    onChangePageNo () {
        this.state.tableAdapter.changePageNo(0);
    }
    getFilterPanel(): ReactNode {
        return <FtRow>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 200}} onKeyDownEnter={this.onChangePageNo}/>
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/store/shippingFeeTemplateDetail/0/"+this.props.deliveryProviderId}>
                <Button>
                    <Add/>{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }
}
