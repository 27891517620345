import * as React from "react";
import {CSSProperties} from "react";
import {RouteComponentProps} from "react-router-dom";
import FtTabPanel from "../ui/FtTabPanel";
import {i18n} from "../util/I18n";
import FtTabs from "../ui/FtTabs";
import FtState from "../ui/FtState";
import {Typography} from "@material-ui/core";
import FssCouponListPage from "../coupon/FssCouponListPage";
import FssUserCouponListPage from "../userCoupon/FssUserCouponListPage";
import FtPage from "../ui/FtPage";


interface JtCouponSettingTabsPageProp extends RouteComponentProps{
}

class JtCouponSettingTabsPageState{
    tabValue: string = "";
}

export default class FssCouponSettingTabsPage extends React.Component<JtCouponSettingTabsPageProp, JtCouponSettingTabsPageState> {
    stateKey = "JtCouponSettingTabsPageState";
    constructor(props: any) {
        super(props);
        let tabValue = FtState.get(this.stateKey);
        if (tabValue != null) {
            this.state = {tabValue: tabValue};
        } else {
            this.state = {tabValue: "coupon"};
            FtState.put(this.stateKey, "coupon");
        }
    }

    componentDidMount(): void {

    }

    render() {
        const styles: CSSProperties = {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            backgroundColor: "#d5d5d5",
            color: "black"
        };
        const selectedStyles: CSSProperties = {
            borderTopLeftRadius: 25,
            borderTopRightRadius: 25,
            backgroundColor: "white",
        };
        return <FtPage onCancelClick={()=>{this.props.history.goBack()}}
                       toolbarLeftNode={<Typography variant="h6">{i18n("优惠券")}</Typography>}>
            <FtTabs value={this.state.tabValue}
                    indicatorColor="primary" textColor="primary"
                    onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                        this.setState({tabValue: newValue});
                        FtState.put(this.stateKey, newValue);
                    }}>
                <FtTabPanel style={this.state.tabValue == "coupon" ? selectedStyles:styles}
                            label={i18n("优惠券列表")} value="coupon">{<FssCouponListPage/>}</FtTabPanel>
                <FtTabPanel style={this.state.tabValue == "userCoupon" ? selectedStyles:styles}
                            label={i18n("已领取优惠券列表")} value="userCoupon">{<FssUserCouponListPage/>}</FtTabPanel>
            </FtTabs>
        </FtPage>;
    }
}
