import {StompSubscription} from "@stomp/stompjs";
import FssConversation from "../model/FssConversation";
import WatchableData from "../data/WatchableData";
import FssMessageService from "../service/FssMessageService";
import {chatWebsocket} from "./FssChatWebsocket";

export class FssChatClientDataState {
    conversationList?: Array<FssConversation>;
    currentConversation?: FssConversation;
}

export default class FssChatClientData extends WatchableData {
    private static data: FssChatClientDataState = new FssChatClientDataState();

    static getData(): FssChatClientDataState {
        return FssChatClientData.data;
    }

    static loadConversationList(successFunction: () => void) {
        FssMessageService.getConversationListFromSeller((data: Array<FssConversation>) => {
            FssChatClientData.getData().conversationList = [];
            if (data != null && data.length > 0) {
                FssChatClientData.getData().conversationList = data;
            }
            successFunction();
        })
    }

    static getConversationIds() {
        let conversationIds: Array<string> = [];
        let conversationList = FssChatClientData.getData().conversationList;
        if (conversationList != null && conversationList.length > 0) {
            conversationList.forEach((conversation: FssConversation) => {
                if (conversation.id != null) {
                    conversationIds.push(conversation.id);
                }
            })
        }
        return conversationIds;
    }

    static removeAllConversationSubscription() {
        let conversationIds = this.getConversationIds();
        let subscriptionList = chatWebsocket.subscriptionList;
        if (subscriptionList != null && subscriptionList.length > 0) {
            subscriptionList.forEach((subscription: StompSubscription) => {
                if (conversationIds.indexOf(subscription.id) > -1) {
                    subscription.unsubscribe();
                }
            })
        }
    }
}
