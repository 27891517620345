import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssShippingFeeTemplate from "../model/FssShippingFeeTemplate";

export default class FssShippingFeeTemplateService extends FssHttpService {


    static getStoreShippingFeeTemplateList(searchKeyword: string,deliveryProviderId:number, pageNo: number, pageSize: number,
                                           successFunction: (data: FtPagedList<FssShippingFeeTemplate>) => void,
                                           failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/template/getStoreShippingFeeTemplateList", {
            searchKeyword: searchKeyword,
            deliveryProviderId:deliveryProviderId,
            page: pageNo,
            size: pageSize
        }, successFunction, FssHttpService.handleError);
    }
    static getShippingFeeTemplate(shippingFeeTemplateId: string, successFunction: (data: FssShippingFeeTemplate) => void,
                                  failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/template/getShippingFeeTemplate", {
            shippingFeeTemplateId: shippingFeeTemplateId
        }, successFunction, FssHttpService.handleError);
    }
    static saveShippingFeeTemplate(data: FssShippingFeeTemplate, successFunction: (data: FssShippingFeeTemplate) => void): void {
        this.postJson("/api/template/saveShippingFeeTemplate", data, successFunction, FssHttpService.handleError);
    }
    static getNormalShippingFeeTemplateList(successFunction: (data: Array<FssShippingFeeTemplate>) => void,
                                           failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/template/getNormalShippingFeeTemplateList", {}, successFunction, FssHttpService.handleError);
    }

}
