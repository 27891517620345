
export default class FssTranslation{
    groupKey:string="";
    itemKey:string="";
    objectId:string="";
    lang:string="";
    content:string="";

    static newInstance(objectId: string, groupKey: string, itemKey: string, lang: string, content: string): FssTranslation {
        const translation: FssTranslation = new FssTranslation();
        translation.objectId = objectId;
        translation.groupKey = groupKey;
        translation.itemKey = itemKey;
        translation.lang = lang;
        translation.content = content;
        return translation;
    }
}
