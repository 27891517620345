import * as React from "react";
import {ReactNode} from "react";
import {
    AppBar,
    Collapse,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    SvgIcon
} from "@material-ui/core";
import {ExpandLess, ExpandMore, Menu} from "@material-ui/icons";
import {grey} from "@material-ui/core/colors";
import FtAdminMenu from "../model/FtAdminMenu";
import FtComponent, {FtComponentState} from "./FtComponent";
import {BackgroundColorProperty, ColorProperty} from "csstype";
import {i18n} from "../util/I18n";
import {theme} from "../mainframe/FssSellerAdminTheme";
import {topBarHeight} from "../mainframe/FssSellerAdminMainPage";

export interface FtAdminFrameProp {
    topBar?: ReactNode;
    leftDrawerMenu?: Array<FtAdminMenu>;
    onClickLeftDrawerMenu?: (menuCode: string) => void;
    onOpenLeftDrawer?: (isOpen: boolean) => void;
    leftDrawerWidth?: number;
    leftDrawerOpenDefault?: boolean;
    leftBottomNode?: ReactNode;
    backgroundColor?: BackgroundColorProperty;
    color?: ColorProperty;
}


export class FtAdminFrameState extends FtComponentState {
    showDrawer: boolean = true;
}

export default class FtAdminFrame extends FtComponent<FtAdminFrameProp, FtAdminFrameState> {
    constructor(props: any) {
        super(props);
        this.state = {showDrawer: this.props.leftDrawerOpenDefault != null ? this.props.leftDrawerOpenDefault : true};
        this.onClickMenuItem = this.onClickMenuItem.bind(this);
        this.onClickMenuItemExpand = this.onClickMenuItemExpand.bind(this);
    }

    render() {
        return <div>
            {this.props.topBar &&
            <AppBar position={"fixed"} style={{
                minHeight: 32,
                backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : theme.palette.primary.main,
                color: this.props.color ? this.props.color : grey[50],
            }}>
                <div style={{display: "flex", height: topBarHeight, alignItems: "center"}}>
                    <IconButton size={"small"} onClick={() => {
                        this.setState({showDrawer: !this.state.showDrawer});
                        if (this.props.onOpenLeftDrawer)
                            this.props.onOpenLeftDrawer(!this.state.showDrawer);
                    }} color={"inherit"} style={{flexGrow: 1}}><Menu></Menu></IconButton>
                    {this.props.topBar}
                </div>
            </AppBar>}
            <Drawer anchor={"left"} variant={"persistent"} open={this.state.showDrawer} style={{top: 40}} PaperProps={{
                style: {
                    top: 40,
                    backgroundColor: this.props.backgroundColor ? this.props.backgroundColor : theme.palette.primary.main,
                    color: this.props.color ? this.props.color : grey[50],
                    width: this.props.leftDrawerWidth ? this.props.leftDrawerWidth : 160,
                    paddingLeft: 8,
                    boxSizing: "border-box",
                    height: "calc(100% - 40px)"
                }
            }}>
                <List component="nav">
                    {this.props.leftDrawerMenu && this.getMenuSection(this.props.leftDrawerMenu, 0)}
                </List>
                <div style={{position: "absolute", bottom: 8}}>
                    {this.props.leftBottomNode}
                </div>
            </Drawer>
            {this.props.children}
        </div>
    }

    private getMenuSection(memuList: Array<FtAdminMenu>, level: number) {
        const result: Array<ReactNode> = [];
        if (memuList && memuList.length > 0) {
            memuList.forEach((menu: FtAdminMenu, index: number) => {
                    result.push(<ListItem style={{paddingTop: 4, paddingBottom: 4}} id={menu.menuCode} button
                                          disableGutters={true} onClick={() => {
                        this.onClickMenuItem(menu);
                    }}>
                        {menu.iconSvg && menu.iconSvg.length > 0 && <ListItemIcon style={{color: grey[50], minWidth: 28}}>
                            <SvgIcon fontSize={"small"}>
                                <path d={menu.iconSvg}/>
                            </SvgIcon>
                        </ListItemIcon>}
                        {menu.primaryText && menu.primaryText.length > 0 &&
                        <ListItemText primary={i18n(menu.primaryText)} secondary={i18n(menu.secondaryText)} />}
                        {menu.itemSecondaryAction &&
                        <ListItemSecondaryAction>
                            {menu.itemSecondaryAction}
                        </ListItemSecondaryAction>}
                        {menu.subMenuList && menu.subMenuList.length > 0 && ((menu.expand != null && menu.expand) ?
                            <ExpandLess onClick={(event) => {
                                this.onClickMenuItemExpand(menu);
                                event.stopPropagation();
                            }}/> : <ExpandMore onClick={(event) => {
                                this.onClickMenuItemExpand(menu);
                                event.stopPropagation();
                            }}/>)}
                    </ListItem>);
                    if (menu.subMenuList && menu.subMenuList.length > 0) {
                        result.push(<Collapse in={menu.expand} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding={true} style={{paddingLeft: (level + 1) * 16}}>
                                {this.getMenuSection(menu.subMenuList, level + 1)}
                            </List>
                        </Collapse>);
                    }
                }
            );
        }
        return result;
    }

    private onClickMenuItem(menu: FtAdminMenu) {
        if (menu.subMenuList && menu.subMenuList.length > 0) {
            this.onClickMenuItemExpand(menu);
        } else if (this.props.onClickLeftDrawerMenu) {
            if (menu.menuCode) {
                this.props.onClickLeftDrawerMenu(menu.menuCode);
                this.setState({showDrawer: this.props.leftDrawerOpenDefault != null ? this.props.leftDrawerOpenDefault : true}, () => {
                    if (this.props.onOpenLeftDrawer)
                        this.props.onOpenLeftDrawer(this.state.showDrawer);
                });
            }
        }
    }

    private onClickMenuItemExpand(menu: FtAdminMenu) {
        menu.expand = !menu.expand;
        this.forceUpdate();
    }
}

