import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtToast from "../ui/FtToast";
import {
    Button,
    Chip,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {Add, Edit} from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import JtProduct from "../model/FssProduct";
import FssProduct, {EBusinessType, EProductStatus} from "../model/FssProduct";
import {Link, RouteComponentProps} from "react-router-dom";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import Tooltip from "@material-ui/core/Tooltip";
import FtState from "../ui/FtState";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtOptionDialog, {FtOptionDialogItem} from "../ui/FtOptionDialog";
import FtSnackbar from "../ui/FtSnackbar";
import FssCategory from "../model/FssCategory";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import FormControl from "@material-ui/core/FormControl";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import FtCombobox from "../ui/FtCombobox";
import FssProductChildDetailComp from "./FssProductChildDetailComp";
import FtMessageDialog from "../ui/FtMessageDialog";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtRow from "../ui/FtRow";
import _ from "lodash";
import FssProductTaxPanel from "./FssProductTaxPanel";
import FssTaxName from "../model/FssTaxName";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import ChipInput from "material-ui-chip-input";
import FssProductService from "../service/FssProductService";
import FssCategoryService from "../service/FssCategoryService";
import FssShippingFeeTemplate from "../model/FssShippingFeeTemplate";
import FssProductSupplier, {EType} from "../model/FssProductSupplier";
import FssProductSupplierComboBox from "../productSupplier/FssProductSupplierComboBox";
import FtPagedList from "../model/FtPagedList";
import FssShippingFeeTemplateService from "../service/FssShippingFeeTemplateService";
import FssClientData from "../data/FssClientData";
import FssStoreCategory from "../model/FssStoreCategory";
import FssStoreCategoryService from "../service/FssStoreCategoryService";
import FssSeller from "../model/FssSeller";
import FssSellerService from "../service/FssSellerService";

export interface JtProductListPageProp extends RouteComponentProps {
    onClickItem?: (jtProduct: JtProduct) => void;
    selectedList?: Array<JtProduct>;
    onRemoveItem?: (jtProductId: number) => void;
}

class JtProductListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    selectedDataList: Array<JtProduct> = new Array<JtProduct>();
    csvData: Array<any> = new Array<any>();
    openMessageBoxDialog: boolean = false;
    openUpdateStockAmountDialog: boolean = false;
    messageText: string = "";
    updateItemValue: string = "";
    categoryId: string = "";
    storeCategoryId: string = "";
    shippingFeeTemplateId: string = "";
    productStatus: string = "";
    categoryList: Array<FssCategory> = [];
    storeCategoryList: Array<FssStoreCategory> = [];
    shippingFeeTemplateList: Array<FssShippingFeeTemplate> = new Array<FssShippingFeeTemplate>();
    openChildProductDialog: boolean = false;
    childProduct: JtProduct = new JtProduct();
    childProductIndex: number = -1;
    stockAmountType: string = "";
    stockAmount: number = 0;
    openPickingGroupDialog: boolean = false;
    pickingGroup: string = "";
    shippingFeeTemplateIdList: Array<string> = [];
    updateShippingTemplateDialog: boolean = false;
    updateTaxDialog: boolean = false;
    taxNameList: Array<FssTaxName> = [];
    updateTagDialog: boolean = false;
    defaultTagsList: Array<string> = [];
    tagsList: Array<string> = [];
    tagsType: string = "add";
    openUpdateSortIndexDialog: boolean = false;
    openStorageLocationDialog: boolean = false;
    storageLocation: string = "";
    sortIndex: number = 0;
    sellerList: Array<FssSeller> = [];
}

class JtTableAdapter extends FtDataTableAdapter<FssProduct> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        if (this.pagedList.size == 0) {
            this.pagedList.size = 20;
        }
        FssProductService.getStoreProductList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssProduct>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssProductListPage extends FtComponent<JtProductListPageProp, JtProductListPageState> {
    csvLink!: any;

    constructor(props: any) {
        super(props);
        const columnProps = this.getDefaultColumn();
        let tableAdapter: JtTableAdapter;
        const cacheState: JtProductListPageState = FtState.pop("FssProductListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            stateKey: "FssProductListPage",
            selectedDataList: new Array<FssProduct>(),
            csvData: new Array<any>(),
            openMessageBoxDialog: false,
            messageText: "",
            updateItemValue: "",
            categoryId: "",
            storeCategoryId: "",
            shippingFeeTemplateId: "",
            productStatus: "",
            categoryList: new Array<FssCategory>(),
            storeCategoryList: [],
            shippingFeeTemplateList: new Array<FssShippingFeeTemplate>(),
            childProductIndex: -1,
            childProduct: new FssProduct(),
            openChildProductDialog: false,
            openUpdateStockAmountDialog: false,
            stockAmountType: "",
            stockAmount: 0,
            openPickingGroupDialog: false,
            pickingGroup: "",
            shippingFeeTemplateIdList: [],
            updateShippingTemplateDialog: false,
            updateTaxDialog: false,
            taxNameList: [],
            updateTagDialog: false,
            defaultTagsList: [],
            tagsList: [],
            tagsType: "add",
            openUpdateSortIndexDialog: false,
            sortIndex: 0,
            openStorageLocationDialog: false,
            storageLocation: "",
            sellerList: [],
        };
        this.onClickAdd = this.onClickAdd.bind(this);
        this.onClickChangeStatus = this.onClickChangeStatus.bind(this);
        this.onClickUpdateStatus = this.onClickUpdateStatus.bind(this);
        this.updateCategory = this.updateCategory.bind(this);
        this.updateStoreCategory = this.updateStoreCategory.bind(this);
        this.getMessageBoxDialog = this.getMessageBoxDialog.bind(this);
        this.updateStoreShippingFeeTemplate = this.updateStoreShippingFeeTemplate.bind(this);
        this.onCloseChildProductDialog = this.onCloseChildProductDialog.bind(this);
        this.onClickSaveChild = this.onClickSaveChild.bind(this);
        this.exportProductCsv = this.exportProductCsv.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.onUpdateStockAmount = this.onUpdateStockAmount.bind(this);
        this.onUpdateSortIndex = this.onUpdateSortIndex.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
        this.onOpenPickingGroupDialog = this.onOpenPickingGroupDialog.bind(this);
        this.onChangeProductPickingGroup = this.onChangeProductPickingGroup.bind(this);
        this.onUpdatePickingGroup = this.onUpdatePickingGroup.bind(this);
        this.updatePickingGroups = this.updatePickingGroups.bind(this);
        this.updateShippingTemplate = this.updateShippingTemplate.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.onOpenStorageLocationDialog = this.onOpenStorageLocationDialog.bind(this);
        this.updateStorageLocations = this.updateStorageLocations.bind(this);
        this.onChangeStorageLocation = this.onChangeStorageLocation.bind(this);
        this.loadStoreCategoryData = this.loadStoreCategoryData.bind(this);
        this.loadCategoryData = this.loadCategoryData.bind(this);
        this.loadStoreSeller = this.loadStoreSeller.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
        this.loadCategoryData();
        this.loadStoreCategoryData();
        this.loadShippingFeeTemplateData();
        this.loadTagsData();
        this.loadStoreSeller();
    }

    onClickAdd() {
        this.props.history.push("/productDetail/0");
    }

    isSelectedItem(item: JtProduct): boolean {
        let found: boolean = false;
        if (this.props.selectedList != null)
            this.props.selectedList.forEach((fssProduct: FssProduct, index: number) => {
                if (fssProduct.id == item.id) {
                    found = true;
                }
            });
        return found;
    }

    render() {
        return <div>
            <FtDataTable title={i18n("商品列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarWithSelect={this.getToolbarWithSelect()}
                         toolbarNoSelect={this.getToolbarNoSelect()}
                         onHighlightRow={(rowData: object) => {
                             if (this.props.onClickItem) this.props.onClickItem(rowData as FssProduct);
                         }}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
            {this.getMessageBoxDialog()}
            <Dialog maxWidth={"md"} open={this.state.openChildProductDialog} onClose={this.onCloseChildProductDialog}
                    scroll={"paper"}>
                <DialogContent>
                    <FssProductChildDetailComp fssProduct={this.state.childProduct}
                                               onChange={(product: FssProduct) => {
                                                   this.setState({childProduct: product});
                                               }} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={this.onCloseChildProductDialog}>{i18n("关闭")}</Button>
                    <Button variant="contained" color="primary" onClick={this.onClickSaveChild}>{i18n("保存并关闭")}</Button>
                </DialogActions>
            </Dialog>
            {this.onUpdateStockAmount()}
            {this.onUpdateSortIndex()}
            {this.onUpdatePickingGroup()}
            {this.onUpdateStorageLocation()}
            {this.updateShippingTemplate()}
            {this.updateTax()}
            {this.updateTag()}
        </div>;
    }

    onCloseChildProductDialog() {
        this.setState({openChildProductDialog: false, childProduct: new JtProduct(), childProductIndex: -1});
    }

    onClickSaveChild() {
        if (this.state.childProduct) {
            FtToast.showToastLoading(i18n("正在保存"));
            //为了避免子商品引用主商品造成 json 循环，要把子商品去掉
            this.state.childProduct.parentProduct = undefined;
            FssProductService.saveProduct(this.state.childProduct, (childProduct: JtProduct) => {
                FtToast.closeToast();
                this.onCloseChildProductDialog();
                this.state.tableAdapter.loadData();
            });
        }
    }

    onChangePageNo() {
        this.state.tableAdapter.changePageNo(0);
        this.forceUpdate();
    }

    getFilterPanel(): ReactNode {
        if (this.state.tableAdapter.filterColumns.get("productStatus") == null) {
            let productStatus = [EProductStatus.ENABLE, EProductStatus.DISABLE, EProductStatus.STOP_SELL]
            this.state.tableAdapter.filterColumns.set("productStatus", productStatus);
        }
        return <FtRow cellHorizontalSpace={8} cellVerticalSpace={0} style={{marginLeft: 4}}>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 200}}
                               onKeyDownEnter={this.onChangePageNo}/>
            <FormControl style={{minWidth: 150}}>
                <InputLabel> {i18n("业务类型")} </InputLabel>
                <Select id="status" value={this.state.tableAdapter.getFilterColumnValue("businessType")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("businessType", event.target.value);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={EBusinessType.NORMAL} key={"NORMAL"}>{i18n("普通商品")}</MenuItem>
                    <MenuItem value={EBusinessType.EXCHANGE} key={"EXCHANGE"}>{i18n("换购商品")}</MenuItem>
                    <MenuItem value={EBusinessType.MARKETING_EVENT}
                              key={"MARKETING_EVENT"}>{i18n("营销活动专属商品")}</MenuItem>
                </Select>
            </FormControl>
            <div style={{width: "100%"}}/>
            <FtCombobox label={i18n("分类")} optionItems={this.state.categoryList}
                        labelPropertyName={"categoryName"}
                        valuePropertyName={"id"}
                        value={this.state.tableAdapter.filterColumns.get("categoryId") || ""}
                        onSelectItem={(item: object) => {
                            if (item != null)
                                this.state.tableAdapter.filterColumns.set("categoryId", (item as FssCategory).id);
                            else
                                this.state.tableAdapter.filterColumns.set("categoryId", undefined);
                            this.forceUpdate();
                        }} style={{minWidth: 150}}/>
            <FtCombobox label={i18n("店铺分类")} optionItems={this.state.storeCategoryList}
                        labelPropertyName={"categoryName"}
                        valuePropertyName={"id"}
                        value={this.state.tableAdapter.filterColumns.get("storeCategoryId") || ""}
                        onSelectItem={(item: object) => {
                            if (item != null)
                                this.state.tableAdapter.filterColumns.set("storeCategoryId", (item as FssStoreCategory).id);
                            else
                                this.state.tableAdapter.filterColumns.set("storeCategoryId", undefined);
                            this.forceUpdate();
                        }} style={{minWidth: 150}}/>
            <div style={{width: "100%"}}/>
            <FormControl style={{minWidth: 400}}>
                <FormLabel> {i18n("状态")} </FormLabel>
                <FtCheckboxGroup row={true}
                                 values={[EProductStatus.ENABLE, EProductStatus.DISABLE, EProductStatus.STOP_SELL, EProductStatus.ARCHIVE]}
                                 labels={[i18n("上架销售"), i18n("下架"), i18n("上架售罄"), i18n("已存档")]}
                                 checkedValues={this.state.tableAdapter.filterColumns.get("productStatus")}
                                 onCheck={(checkedValue) => {
                                     this.state.tableAdapter.filterColumns.set("productStatus", checkedValue);
                                     this.forceUpdate();
                                 }}/>
            </FormControl>
            <div style={{width: "100%"}}/>
            <TextField label="品牌" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("brand", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("brand")} style={{width: 200}}/>
            <TextField label="简述" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("shortDescription", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("shortDescription")} style={{width: 200}}/>
            <TextField label="商品介绍" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("description", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("description")} style={{width: 200}}/>
            <TextField label="供货商" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("supplierName", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("supplierName")} style={{width: 200}}/>
            <TextField label="标签" onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("tagName", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("tagName")} style={{width: 200}}/>
            {this.state.sellerList != null && this.state.sellerList.length > 0 &&
            <FtCombobox label={i18n("卖家")} optionItems={this.state.sellerList}
                        labelPropertyName={"shortName"}
                        valuePropertyName={"id"}
                        value={this.state.tableAdapter.filterColumns.get("sellerId") || ""}
                        onSelectItem={(item: object) => {
                            if (item != null) {
                                this.state.tableAdapter.filterColumns.set("sellerId", (item as FssSeller).id);
                            } else {
                                this.state.tableAdapter.filterColumns.set("sellerId", undefined);
                            }
                            this.forceUpdate();
                        }} style={{minWidth: 150}}/>}
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/productDetail/0"}>
                <Button>
                    <Add/>{i18n("新增")}
                </Button>
            </Link>
            <Tooltip title={i18n("导出商品到CSV文件")}>
                <Button onClick={this.exportProductCsv}>
                    {i18n("导出CSV")}
                </Button>
            </Tooltip>
            <Link to={"/import/importProduct"}>
                <Button>
                    {i18n("导入商品")}
                </Button>
            </Link>
        </div>;
    }

    getToolbarWithSelect(): ReactNode {
        return <div><Tooltip title={i18n("批量修改")}>
            <Button onClick={this.onClickUpdateStatus}>
                <Edit/>{i18n("批量修改")}
            </Button>
        </Tooltip>
            <Tooltip title={i18n("永久彻底删除商品")}>
                <Button onClick={this.onClickDelete}>
                    {i18n("删除")}
                </Button>
            </Tooltip>
        </div>;
    }

    private exportProductCsv() {
        FtToast.showToastLoading("loading");
        FssProductService.exportProductCsv(this.state.tableAdapter.keyword,
            this.state.tableAdapter.filterColumns, this.state.tableAdapter.sortColumn ? this.state.tableAdapter.sortColumn.propertyName : null,
            this.state.tableAdapter.sortDirection, 0, 100,
            (fileUrl: string) => {
                FtToast.closeToast();
                var element = document.createElement('a');
                element.setAttribute("href", fileUrl);
                element.style.display = 'none';
                document.body.appendChild(element);
                element.click();
                document.body.removeChild(element);
            });
    }

    private loadTagsData(): void {
        FssProductService.getStoreTagsList((result: Array<string>) => {
            this.setState({defaultTagsList: result});
        });
    }

    private loadStoreSeller(): void {
        FssSellerService.getStoreSellerList((sellerList: Array<FssSeller>)=>{
            if (sellerList != null && sellerList.length > 0) {
                let column = this.getColumn();
                this.setState({sellerList: sellerList, columnProps: column})
            }
        });
    }

    private loadCategoryData(): void {
        FssCategoryService.getStoreCategoryList("", 0, 999, '', (result: FtPagedList<FssCategory>) => {
            result.content.unshift(new FssCategory());
            this.setState({categoryList: result.content});
        });
    }

    private loadStoreCategoryData(): void {
        FssStoreCategoryService.getStoreCategoryList(undefined, undefined, (result: Array<FssStoreCategory>) => {
            this.setState({storeCategoryList: result});
        });
    }

    private loadShippingFeeTemplateData(): void {
        FssShippingFeeTemplateService.getNormalShippingFeeTemplateList((result: Array<FssShippingFeeTemplate>) => {
            this.setState({shippingFeeTemplateList: result});
        });
    }

    handleAddChip(chip: string) {
        let index = this.state.tagsList.indexOf(chip);
        if (index == -1) {
            this.state.tagsList.push(chip);
            this.forceUpdate();
        }
    }

    handleDeleteChip(chip: string) {
        let index = this.state.tagsList.indexOf(chip);
        if (index > -1) {
            this.state.tagsList.splice(index, 1)
            this.forceUpdate();
        }

    }

    private onClickUpdateStatus(): void {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            const options: Array<FtOptionDialogItem> = new Array<FtOptionDialogItem>();
            options.push(new FtOptionDialogItem("0", "0", i18n("修改本店分类")));
            options.push(new FtOptionDialogItem("8", "8", i18n("修改网站分类")));
            options.push(new FtOptionDialogItem("1", "1", i18n("修改状态(原上架/下架)")));
            options.push(new FtOptionDialogItem("2", "2", i18n("修改运费模板")));
            options.push(new FtOptionDialogItem("3", "3", i18n("修改拣货分组")));
            options.push(new FtOptionDialogItem("4", "4", i18n("修改税费")));
            options.push(new FtOptionDialogItem("5", "5", i18n("修改标签")));
            options.push(new FtOptionDialogItem("6", "6", i18n("修改库存位置")));
            options.push(new FtOptionDialogItem("7", "7", i18n("一键置顶")));
            FtOptionDialog.dialog.openDialog(i18n("请选择修改类型"), options, (option: FtOptionDialogItem, index: number) => {
                this.setState({updateItemValue: option.value})
                if (option.value == "0") {
                    this.updateCategory();
                } else if (option.value == "1") {
                    this.onClickChangeStatus();
                } else if (option.value == "2") {
                    this.updateStoreShippingFeeTemplate();
                    FtOptionDialog.dialog.closeDialog();
                } else if (option.value == "3") {
                    this.onOpenPickingGroupDialog();
                    FtOptionDialog.dialog.closeDialog();
                } else if (option.value == "4") {
                    this.updateProductTax();
                    FtOptionDialog.dialog.closeDialog();
                } else if (option.value == "5") {
                    this.updateProductTag();
                    FtOptionDialog.dialog.closeDialog();
                } else if (option.value == "6") {
                    this.onOpenStorageLocationDialog();
                    FtOptionDialog.dialog.closeDialog();
                } else if (option.value == "7") {
                    this.batchUpdateSortIndex();
                    FtOptionDialog.dialog.closeDialog();
                } else if (option.value == "8") {
                    this.updateStoreCategory();
                }
            });
        }
    }

    private onClickDelete(): void {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("此操作不可撤销，确定要永久彻底删除选中的商品吗？"), () => {
                const productIds: Array<string> = this.state.tableAdapter.selectedRow.map((item: FssProduct) => {
                    return item.id;
                });
                FtToast.showToastLoading();
                FssProductService.permanentDelete(productIds, (message: string) => {
                    FtToast.closeToast();
                    if (message)
                        FtSnackbar.showText(message);
                    window.location.reload();
                    //this.state.tableAdapter.loadData();
                });
            });
        }
    }

    private onClickChangeStatus(): void {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            const options: Array<FtOptionDialogItem> = new Array<FtOptionDialogItem>();
            options.push(new FtOptionDialogItem(EProductStatus.ENABLE, EProductStatus.ENABLE, i18n("上架销售")));
            options.push(new FtOptionDialogItem(EProductStatus.DISABLE, EProductStatus.DISABLE, i18n("下架")));
            options.push(new FtOptionDialogItem(EProductStatus.STOP_SELL, EProductStatus.STOP_SELL, i18n("上架售罄")));
            options.push(new FtOptionDialogItem(EProductStatus.ARCHIVE, EProductStatus.ARCHIVE, i18n("存档")));
            FtOptionDialog.dialog.openDialog(i18n("请选择商品状态"), options, (option: FtOptionDialogItem, index: number) => {
                this.setState({
                    messageText: i18n("确定将选择的商品状态更改为『{0}』吗?", option.primaryText),
                    openMessageBoxDialog: true,
                    productStatus: option.key
                });
            });
        }
    }

    updateCategory(): void {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            const options: Array<FtOptionDialogItem> = new Array<FtOptionDialogItem>();
            this.state.categoryList.map((category: FssCategory, index: number) => {
                if (category.id.length > 0) {
                    options.push(new FtOptionDialogItem(category.id + "", category.categoryName, category.categoryName));
                }
            });
            FtOptionDialog.dialog.openDialog("请选择商品类型", options, (option: FtOptionDialogItem, index: number) => {
                this.setState({
                    messageText: i18n("确定将选择商品的分类更改为『{0}』吗?", option.value),
                    openMessageBoxDialog: true,
                    categoryId: option.key
                });
            });
        }
    }

    updateStoreCategory(): void {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            const options: Array<FtOptionDialogItem> = new Array<FtOptionDialogItem>();
            this.state.storeCategoryList.map((category: FssStoreCategory, index: number) => {
                if (category.id.length > 0) {
                    options.push(new FtOptionDialogItem(category.id + "", category.categoryName, category.categoryName));
                }
            });
            FtOptionDialog.dialog.openDialog("请选择店铺分类", options, (option: FtOptionDialogItem, index: number) => {
                this.setState({
                    messageText: i18n("确定将选择商品的店铺分类更改为『{0}』吗?", option.value),
                    openMessageBoxDialog: true,
                    storeCategoryId: option.key
                });
            });
        }
    }

    updateShippingTemplate() {
        let templateIds: string[] = [];
        let templateNames: string[] = [];
        this.state.shippingFeeTemplateList.map(value => {
            templateIds.push(value.id);
            templateNames.push(value.templateNameText);
        });
        return <Dialog open={this.state.updateShippingTemplateDialog} onClose={(e) => {
            this.setState({updateShippingTemplateDialog: false, shippingFeeTemplateIdList: []});
        }}>
            <DialogTitle>修改运费模版</DialogTitle>
            <DialogContent>
                <div>
                    <FtRow cellWidthS={"1"}>
                        <FormControl style={{minWidth: 400}}>
                            <FtCheckboxGroup row={false}
                                             values={templateIds}
                                             labels={templateNames}
                                             checkedValues={this.state.shippingFeeTemplateIdList}
                                             onCheck={(checkedValue) => {
                                                 this.setState({shippingFeeTemplateIdList: checkedValue})
                                                 this.forceUpdate();
                                             }}>
                            </FtCheckboxGroup>
                        </FormControl>
                    </FtRow>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => {
                    this.setState({updateShippingTemplateDialog: false, shippingFeeTemplateIdList: []});
                }}>取消</Button>
                <Button variant="outlined" color="primary" onClick={() => {
                    let templateNames: string[] = [];
                    this.state.shippingFeeTemplateList.map(template => {
                        this.state.shippingFeeTemplateIdList.map(templateId => {
                            if (templateId == template.id) {
                                templateNames.push(template.templateNameText);
                            }
                        })
                    })
                    this.setState({
                        updateShippingTemplateDialog: false,
                        messageText: "确定将选择内容运费模板更改为『" + templateNames.join(",") + "』吗?",
                        openMessageBoxDialog: true
                    });
                }}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    updateTax() {
        return <Dialog open={this.state.updateTaxDialog} onClose={(e) => {
            this.setState({updateTaxDialog: false, taxNameList: []});
        }}>

            <div>
                <FssProductTaxPanel selectedItems={this.state.taxNameList}
                                    onSelectChanged={(taxNameList: Array<FssTaxName>) => {
                                        this.setState({taxNameList: taxNameList});
                                        this.forceUpdate();
                                    }}/>
            </div>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => {
                    this.setState({updateTaxDialog: false, taxNameList: []});
                }}>取消</Button>
                <Button variant="outlined" color="primary" onClick={() => {
                    let taxNames: string[] = [];
                    this.state.taxNameList.map(taxName => {
                        taxNames.push(taxName.taxName);
                    })
                    this.setState({
                        updateTaxDialog: false,
                        messageText: "确定将选择内容税费更改为『" + taxNames.join(",") + "』吗?",
                        openMessageBoxDialog: true
                    });
                }}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    updateTag() {
        return <Dialog open={this.state.updateTagDialog} onClose={(e) => {
            this.setState({updateTagDialog: false, tagsList: [], tagsType: "add"});
        }}>

            <div>

                <FtPanel panelHeader={i18n("修改标签")} marginHorizontal={16} marginVertical={16}>
                    <FtGrid>
                        <FtRow cellWidthS={"1"}>
                            <FormControl style={{minWidth: "100%"}}>
                                <FormHelperText>{i18n("操作类型")}</FormHelperText>
                                <RadioGroup row value={this.state.tagsType}
                                            onChange={(event, value) => {
                                                this.setState({tagsType: value});
                                                this.forceUpdate();
                                            }}>
                                    <FormControlLabel value={"add"} control={<Radio/>} label={i18n("增加")}/>
                                    <FormControlLabel value={"delete"} control={<Radio/>} label={i18n("删除")}/>
                                </RadioGroup>
                                <div style={{display: "flex", flexDirection: "column"}}>
                                    <Typography
                                        variant="caption">{i18n("1.增加:输入后循环判断，如该商品没有这个标签就加上，有这个标签就跳过")}</Typography>
                                    <Typography variant="caption">{i18n("2.删除:把选择的所有商品的指定标签删除")}</Typography>
                                </div>
                            </FormControl>
                        </FtRow>
                        <FtRow cellWidthS={"1"}>
                            <FormControl style={{minWidth: "100%"}}>
                                <FormHelperText>{i18n("标签")}</FormHelperText>
                                <ChipInput label={""}
                                           variant={"standard"}
                                           placeholder={i18n("请输入标签")}
                                           alwaysShowPlaceholder={true}
                                           value={this.state.tagsList}
                                           onAdd={(chip) => this.handleAddChip(chip)}
                                           onDelete={(chip, index) => this.handleDeleteChip(chip)}
                                           style={{width: "100%"}}/>
                            </FormControl>
                        </FtRow>
                    </FtGrid>
                    <FtGrid>
                        <FtRow cellHorizontalSpace={16}>
                            {this.state.defaultTagsList.map((data: string, index: number) => {
                                let targetIndex = -1;
                                if (this.state.tagsList) {
                                    targetIndex = _.findIndex(this.state.tagsList, (tag: string) => {
                                        return data == tag;
                                    });
                                }
                                return <Chip color={targetIndex > -1 ? "secondary" : "default"}
                                             label={data}
                                             onClick={() => {
                                                 if (targetIndex > -1) {
                                                     this.handleDeleteChip(data);
                                                 } else {
                                                     this.handleAddChip(data);
                                                 }

                                             }}/>;
                            })}
                        </FtRow>
                    </FtGrid>
                </FtPanel>
            </div>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => {
                    this.setState({updateTagDialog: false, tagsList: [], tagsType: "add"});
                }}>取消</Button>
                <Button variant="outlined" color="primary" onClick={() => {
                    let tagsTypeName = "删除";
                    if (this.state.tagsType == "add") {
                        tagsTypeName = "新增";
                    }

                    this.setState({
                        updateTagDialog: false,
                        messageText: "确定" + tagsTypeName + "标签『" + this.state.tagsList.join(",") + "』吗?",
                        openMessageBoxDialog: true
                    });
                }}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    private updateStoreShippingFeeTemplate(): void {
        this.setState({updateShippingTemplateDialog: true});
    }

    private updateProductTax(): void {
        this.setState({updateTaxDialog: true});
    }

    private updateProductTag(): void {
        this.setState({updateTagDialog: true});
    }

    getMessageBoxDialog(): ReactNode {
        return <Dialog open={this.state.openMessageBoxDialog} onClose={(e) => {
            this.setState({openMessageBoxDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>确认</DialogTitle>
            <DialogContent>
                {this.state.messageText}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false, shippingFeeTemplateIdList: []});
                }}>取消</Button>
                <Button variant="outlined" color="primary" onClick={(e) => {
                    FtToast.showToast("批量修改商品状态");
                    let productIds: string = "";
                    this.state.tableAdapter.selectedRow.forEach((jtProduct: FssProduct, index: number) => {
                        productIds += "," + jtProduct.id;
                    });
                    if (this.state.updateItemValue == "0") {
                        FssProductService.setBatchCategory(productIds, this.state.categoryId, (data: string) => {
                            FtSnackbar.showText(data);
                            this.state.tableAdapter.loadData();
                        });
                    } else if (this.state.updateItemValue == "1") {
                        FssProductService.setBatchProductStatus(productIds, this.state.productStatus, (resp: string) => {
                            FtSnackbar.showText(resp);
                            this.state.tableAdapter.loadData();
                        });
                    } else if (this.state.updateItemValue == "2") {
                        FssProductService.setBatchShippingFeeTemplate(productIds, this.state.shippingFeeTemplateIdList, (resp: string) => {
                            FtSnackbar.showText(resp);
                            this.setState({shippingFeeTemplateIdList: []});
                            this.state.tableAdapter.loadData();
                        });
                    } else if (this.state.updateItemValue == "4") {
                        let taxIds: Array<string> = [];
                        this.state.taxNameList.map(value => {
                            taxIds.push(value.id);
                        })
                        FssProductService.setBatchProductTax(productIds, taxIds, (resp: string) => {
                            FtSnackbar.showText(resp);
                            this.setState({taxNameList: []});
                            this.state.tableAdapter.loadData();
                        });
                    } else if (this.state.updateItemValue == "5") {
                        FssProductService.setBatchProductTags(productIds, this.state.tagsList, this.state.tagsType, (resp: string) => {
                            FtSnackbar.showText(resp);
                            this.setState({tagsList: []});
                            this.state.tableAdapter.loadData();
                        });
                    } else if (this.state.updateItemValue == "7") {
                        FssProductService.batchUpdateSortIndex(productIds, (data: string) => {
                            FtToast.closeToast();
                            if (data) {
                                FtSnackbar.showText(data);
                            }
                            this.state.tableAdapter.loadData();
                        });
                    } if (this.state.updateItemValue == "8") {
                        FssProductService.setBatchStoreCategory(productIds, this.state.storeCategoryId, (data: string) => {
                            FtSnackbar.showText(data);
                            this.state.tableAdapter.loadData();
                        });
                    }
                    this.state.tableAdapter.selectedRow = new Array<any>();
                    this.setState({openMessageBoxDialog: false});
                    FtOptionDialog.dialog.closeDialog();
                }}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    onUpdateStockAmount() {
        return <Dialog open={this.state.openUpdateStockAmountDialog} onClose={(e) => {
            this.setState({openUpdateStockAmountDialog: false});
        }}>
            <DialogTitle>修改库存数量</DialogTitle>
            <DialogContent>
                <div>
                    <Typography variant="subtitle1">
                        当前库存数量【{this.state.childProduct.stockAmount}】
                    </Typography>
                    <FormControl>
                        <RadioGroup row onChange={(event, value) => {
                            this.setState({messageText: "", stockAmountType: value});
                        }}>
                            <FormControlLabel value={"IN"} control={<Radio color="primary"/>} label="入库"/>
                            <FormControlLabel value={"OUT"} control={<Radio color="primary"/>} label="出库"/>
                        </RadioGroup>
                    </FormControl>
                    <div style={{width: "100%"}}/>
                    <FtTextFieldNumber label={"数量"} type={"float"} size={"medium"} onChangeValue={(value) => {
                        if (value) {
                            this.setState({messageText: "", stockAmount: value});
                        }
                    }}/>
                    <Typography variant="body1" color={"primary"} style={{height: 30}}>
                        {this.state.messageText}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => {
                    this.setState({openUpdateStockAmountDialog: false});
                }}>取消</Button>
                <Button variant="outlined" color="primary" onClick={() => {
                    if (this.state.stockAmountType == "" || this.state.stockAmountType.length == 0) {
                        this.setState({messageText: "请选择入库或者出库"});
                        return;
                    } else if (this.state.stockAmount == 0) {
                        this.setState({messageText: "请选择数量"});
                        return;
                    } else {
                        FssProductService.updateProductStockAmount(this.state.childProduct.id,
                            this.state.stockAmountType, this.state.stockAmount, (product: FssProduct) => {
                                this.setState({openUpdateStockAmountDialog: false, messageText: ""});
                                this.state.tableAdapter.loadData();
                            });
                    }
                }}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    onUpdateSortIndex() {
        return <Dialog open={this.state.openUpdateSortIndexDialog} onClose={(e) => {
            this.setState({openUpdateSortIndexDialog: false});
        }}>
            <DialogTitle>调整商品显示位置</DialogTitle>
            <DialogContent>
                <div>
                    <Typography variant="body1">
                        当前显示位置：{this.state.childProduct.sortIndex}
                    </Typography>
                    <div style={{width: "100%"}}/>
                    <FtTextFieldNumber label={"移动后位置序："} type={"integer"} size={"medium"} value={this.state.sortIndex}
                                       onChangeValue={(value) => {
                                           if (value) {
                                               this.setState({messageText: "", sortIndex: value});
                                           }
                                       }}/>
                    <Typography variant="body1" color={"primary"} style={{height: 30}}>
                        {this.state.messageText}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => {
                    this.setState({openUpdateSortIndexDialog: false});
                }}>取消</Button>
                <Button variant="outlined" color="primary" onClick={() => {
                    FssProductService.updateProductSortIndex(this.state.childProduct.id,
                        this.state.sortIndex, (product: FssProduct) => {
                            this.setState({openUpdateSortIndexDialog: false, messageText: ""});
                            this.state.tableAdapter.loadData();
                        });

                }}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    onOpenStorageLocationDialog() {
        this.setState({openStorageLocationDialog: !this.state.openStorageLocationDialog});
    }

    onOpenPickingGroupDialog() {
        this.setState({openPickingGroupDialog: !this.state.openPickingGroupDialog});
    }

    onChangeProductPickingGroup(item?: FssProductSupplier) {
        let pickingGroup = item ? item.supplierName : "";
        this.setState({pickingGroup: pickingGroup});
    }

    onChangeStorageLocation(item?: FssProductSupplier) {
        let storageLocation = item ? item.supplierName : "";
        this.setState({storageLocation: storageLocation});
    }

    updatePickingGroups() {
        const productIds: Array<string> = this.state.tableAdapter.selectedRow.map((item: FssProduct) => {
            return item.id;
        });
        FtToast.showToastLoading();
        FssProductService.batchSetPickingGroup(productIds, this.state.pickingGroup, (data: string) => {
            FtToast.closeToast();
            if (data)
                FtSnackbar.showText(data);
            this.state.tableAdapter.selectedRow = new Array<any>();
            this.onOpenPickingGroupDialog();
        });
    }

    updateStorageLocations() {
        const productIds: Array<string> = this.state.tableAdapter.selectedRow.map((item: FssProduct) => {
            return item.id;
        });
        FtToast.showToastLoading();
        FssProductService.batchSetStorageLocation(productIds, this.state.storageLocation, (data: string) => {
            FtToast.closeToast();
            if (data) {
                FtSnackbar.showText(data);
            }
            this.state.tableAdapter.selectedRow = new Array<any>();
            this.onOpenStorageLocationDialog();
        });
    }

    onUpdatePickingGroup() {
        return <Dialog open={this.state.openPickingGroupDialog} onClose={this.onOpenPickingGroupDialog}
                       PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>修改拣货分组</DialogTitle>
            <DialogContent>
                <FssProductSupplierComboBox label={i18n("拣货分组")}
                                            type={EType.PICKING_GROUP}
                                            onChangeProductSupplier={this.onChangeProductPickingGroup}
                                            selectedSupplierName={this.state.pickingGroup}/>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={this.onOpenPickingGroupDialog}>取消</Button>
                <Button variant="outlined" color="primary" onClick={this.updatePickingGroups}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    onUpdateStorageLocation() {
        return <Dialog open={this.state.openStorageLocationDialog} onClose={this.onOpenStorageLocationDialog}
                       PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>修改库存位置</DialogTitle>
            <DialogContent>
                <FssProductSupplierComboBox label={i18n("库存位置")}
                                            type={EType.STORAGE_LOCATION}
                                            onChangeProductSupplier={this.onChangeStorageLocation}
                                            selectedSupplierName={this.state.storageLocation}/>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={this.onOpenStorageLocationDialog}>取消</Button>
                <Button variant="outlined" color="primary" onClick={this.updateStorageLocations}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    batchUpdateSortIndex() {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            this.setState({
                messageText: i18n("确定将选择的商品置顶吗?"),
                openMessageBoxDialog: true,
            });
        }
    }

    getDefaultColumn() {
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column100: FtDataTableColumnProps = new FtDataTableColumnProps("productCode", i18n("编码"));
        column100.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            if (fssProduct.parentProductId != null && fssProduct.parentProductId.length > 0) {
                return <div>
                    <Link to={"#"} onClick={() => {
                        this.setState({
                            childProduct: fssProduct,
                            openChildProductDialog: true,
                            childProductIndex: rowIndex
                        });
                    }}>{fssProduct.productCode}</Link>
                    <br/>
                    <Chip size={"small"} label={i18n("子商品")} variant={"outlined"}/>
                </div>
            } else
                return <Link to={"/productDetail/" + fssProduct.id}>{fssProduct.productCode}</Link>
        };
        columnProps.push(column100);
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("coverImageUrl");
        column0.renderHeader = () => {
            return i18n("封面图");
        }
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            return <img style={{width: 60, height: 60}} src={fssProduct.coverImageUrl}/>
        };
        columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("title", i18n("标题"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            if (fssProduct.parentProductId != null && fssProduct.parentProductId.length > 0) {
                return <div>
                    <Link to={"#"} onClick={() => {
                        this.setState({
                            childProduct: fssProduct,
                            openChildProductDialog: true,
                            childProductIndex: rowIndex
                        });
                    }}>{fssProduct.title}</Link>
                    <br/>
                    {/*<Chip size={"small"} label={i18n("子商品")} variant={"outlined"}></Chip>*/}
                </div>
            } else
                return <Link to={"/productDetail/" + fssProduct.id}>{fssProduct.title}</Link>
        };
        columnProps.push(column1);
        const column11: FtDataTableColumnProps = new FtDataTableColumnProps("tags", i18n("标签"),
            "left", false, false, true);
        columnProps.push(column11);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("productStatusText", i18n("状态"),
            "left", false, false, true);
        columnProps.push(column2);
        const column44: FtDataTableColumnProps = new FtDataTableColumnProps("sortIndex", i18n("排序系数"), "right");
        column44.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            return <Chip size={"small"} label={fssProduct.sortIndex} variant={"outlined"}
                         onClick={() => {
                             this.setState({
                                 childProduct: fssProduct,
                                 openUpdateSortIndexDialog: true,
                                 messageText: "",
                                 sortIndex: 0
                             });
                         }}/>
        };
        columnProps.push(column44);
        const category: FtDataTableColumnProps = new FtDataTableColumnProps("categoryName", i18n("本店分类"));
        columnProps.push(category);
        const storeCategory: FtDataTableColumnProps = new FtDataTableColumnProps("storeCategoryName", i18n("网站分类"));
        columnProps.push(storeCategory);

        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("stockAmount", i18n("库存"), "right");
        column4.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            return <Chip size={"small"} label={fssProduct.stockAmount} variant={"outlined"}
                         onClick={() => {
                             this.setState({
                                 childProduct: fssProduct,
                                 openUpdateStockAmountDialog: true,
                                 messageText: "",
                                 stockAmountType: "",
                                 stockAmount: 0
                             });
                         }}/>
        };
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("salesPriceUsd", i18n("销售价"), "right");
        columnProps.push(column5);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("salesAmount", i18n("销售数量"), "right");
        columnProps.push(column7);
        const column9: FtDataTableColumnProps = new FtDataTableColumnProps("modifiedDatetime", i18n("最后修改时间"), "right");
        column9.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const product: FssProduct = rowData as FssProduct;
            if (product.modifiedDatetime)
                return <span>{FssClientData.formatDatetime(product.modifiedDatetime)}</span>;
            else
                return null;
        };
        columnProps.push(column9);
        const column10: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetime", i18n("创建时间"), "right");
        column10.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const product: FssProduct = rowData as FssProduct;
            if (product.createDatetime)
                return <span>{FssClientData.formatDatetime(product.createDatetime)}</span>;
            else
                return null;
        };
        columnProps.push(column10);
        return columnProps;
    }

    getColumn() {
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column100: FtDataTableColumnProps = new FtDataTableColumnProps("productCode", i18n("编码"));
        column100.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            if (fssProduct.parentProductId != null && fssProduct.parentProductId.length > 0) {
                return <div>
                    <Link to={"#"} onClick={() => {
                        this.setState({
                            childProduct: fssProduct,
                            openChildProductDialog: true,
                            childProductIndex: rowIndex
                        });
                    }}>{fssProduct.productCode}</Link>
                    <br/>
                    <Chip size={"small"} label={i18n("子商品")} variant={"outlined"}/>
                </div>
            } else
                return <Link to={"/productDetail/" + fssProduct.id}>{fssProduct.productCode}</Link>
        };
        columnProps.push(column100);
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("coverImageUrl");
        column0.renderHeader = () => {
            return i18n("封面图");
        }
        column0.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            return <img style={{width: 60, height: 60}} src={fssProduct.coverImageUrl}/>
        };
        columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("title", i18n("标题"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            if (fssProduct.parentProductId != null && fssProduct.parentProductId.length > 0) {
                return <div>
                    <Link to={"#"} onClick={() => {
                        this.setState({
                            childProduct: fssProduct,
                            openChildProductDialog: true,
                            childProductIndex: rowIndex
                        });
                    }}>{fssProduct.title}</Link>
                    <br/>
                </div>
            } else
                return <Link to={"/productDetail/" + fssProduct.id}>{fssProduct.title}</Link>
        };
        columnProps.push(column1);
        const column11: FtDataTableColumnProps = new FtDataTableColumnProps("tags", i18n("标签"),
            "left", false, false, true);
        columnProps.push(column11);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("productStatusText", i18n("状态"),
            "left", false, false, true);
        columnProps.push(column2);
        const column44: FtDataTableColumnProps = new FtDataTableColumnProps("sortIndex", i18n("排序系数"), "right");
        column44.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            return <Chip size={"small"} label={fssProduct.sortIndex} variant={"outlined"}
                         onClick={() => {
                             this.setState({
                                 childProduct: fssProduct,
                                 openUpdateSortIndexDialog: true,
                                 messageText: "",
                                 sortIndex: 0
                             });
                         }}/>
        };
        columnProps.push(column44);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("sellerName", i18n("卖家"));
        columnProps.push(column3);
        const category: FtDataTableColumnProps = new FtDataTableColumnProps("categoryName", i18n("本店分类"));
        columnProps.push(category);
        const storeCategory: FtDataTableColumnProps = new FtDataTableColumnProps("storeCategoryName", i18n("网站分类"));
        columnProps.push(storeCategory);

        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("stockAmount", i18n("库存"), "right");
        column4.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProduct: FssProduct = rowData as FssProduct;
            return <Chip size={"small"} label={fssProduct.stockAmount} variant={"outlined"}
                         onClick={() => {
                             this.setState({
                                 childProduct: fssProduct,
                                 openUpdateStockAmountDialog: true,
                                 messageText: "",
                                 stockAmountType: "",
                                 stockAmount: 0
                             });
                         }}/>
        };
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("salesPriceUsd", i18n("销售价"), "right");
        columnProps.push(column5);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("salesAmount", i18n("销售数量"), "right");
        columnProps.push(column7);
        const column9: FtDataTableColumnProps = new FtDataTableColumnProps("modifiedDatetime", i18n("最后修改时间"), "right");
        column9.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const product: FssProduct = rowData as FssProduct;
            if (product.modifiedDatetime)
                return <span>{FssClientData.formatDatetime(product.modifiedDatetime)}</span>;
            else
                return null;
        };
        columnProps.push(column9);
        const column10: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetime", i18n("创建时间"), "right");
        column10.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const product: FssProduct = rowData as FssProduct;
            if (product.createDatetime)
                return <span>{FssClientData.formatDatetime(product.createDatetime)}</span>;
            else
                return null;
        };
        columnProps.push(column10);
        return columnProps;
    }
}
