export enum ETipType {
    PECENTAGE="PECENTAGE",VALUE="VALUE"
}
export enum EPrintVersion {
    NEW="NEW",OLD="OLD"
}
export class FssOrderSetting{
    id:string="";
    orderMergeDeliveryFlag: boolean = false;
    closeOrderWaitDays: number = 0;
    cancelUnpaidOrderWaitMinutes: number = 0;
    cancelUnpaidIncludePartialPaidFlag:boolean = false;
    tipLabel:string="";
    tipType:ETipType =ETipType.PECENTAGE;
    defaultTip:number=0;
    tipOptionsString:string="";
    tipFlag:boolean=false;
    tipOptions: Array<number> = [];
    printVersion:EPrintVersion = EPrintVersion.NEW;
}
