import React from 'react';
import './App.css';
import {HashRouter, Route} from "react-router-dom";
import FtOptionDialog from "./ui/FtOptionDialog";
import FtMessageDialog from "./ui/FtMessageDialog";
import FtMediaViewerDialog from "./ui/FtMediaViewerDialog";
import FtToast from "./ui/FtToast";
import FtSnackbar from "./ui/FtSnackbar";
import {MuiThemeProvider, withStyles} from "@material-ui/core";
import {theme} from "./mainframe/FssSellerAdminTheme";
import FssSellerAdminMainPage from "./mainframe/FssSellerAdminMainPage";
import FssLoginDialog from "./user/FssLoginDialog";
import FtUtil from "./util/FtUtil";
import FssEmailResultPage from "./invite/FssEmailResultPage";
import FssSelectSellerDialog from "./mainframe/FssSelectSellerDialog";

const GlobalCss = withStyles({
    '@global': {
        '.MuiButton-root': {
            textTransform: "none"
        }
    },
})(() => null);

function App() {
    let url = window.location.href;
    const verificationCode = FtUtil.getParameterByName("verificationCode", url);
    if (verificationCode != null && verificationCode.length > 0) {
        return <HashRouter>
            <MuiThemeProvider theme={theme}>
                <GlobalCss/>
                <Route path="/emailVerification" component={FssEmailResultPage}/>
            </MuiThemeProvider>
        </HashRouter>;
    }
    return <HashRouter>
        <MuiThemeProvider theme={theme}>
            <GlobalCss/>
            <div>
                <FtToast ref={(element: any) => {
                    FtToast.FtToast = element;
                }}/>
                <FtSnackbar ref={(element: any) => {
                    FtSnackbar.FtSnackbar = element;
                }}/>
                <FtMessageDialog ref={(element: any) => {
                    FtMessageDialog.dialog = element;
                }}/>
                <FtOptionDialog ref={(element: any) => {
                    FtOptionDialog.dialog = element;
                }}/>
                <FssLoginDialog defaultOnSuccess={() => {
                    window.location.reload();
                }} ref={(element: any) => {
                    FssLoginDialog.loginDialog = element;
                }}/>
                <FtMediaViewerDialog ref={(element: any) => {
                    FtMediaViewerDialog.FtMediaViewerDialog = element;
                }}/>
                <FssSelectSellerDialog ref={(element: any) => {
                    FssSelectSellerDialog.dialog = element;
                }}/>
            </div>
            <Route path="/" component={FssSellerAdminMainPage} exact={false}/>
        </MuiThemeProvider>
    </HashRouter>;
}

export default App;
