import FssPageComponent from "./FssPageComponent";

export enum EScreenType {
    MOBILE = "MOBILE",
    PC = "PC"
}

export enum EStatus {
    NORMAL = "NORMAL", STOP = "STOP"
}

export default class FssPage {
    storeId: string = "";
    sellerId: string = "";
    createDatetimeText: string = "";
    id: string = "";
    pageUri: string = "";
    screenType: EScreenType = EScreenType.MOBILE;
    status: EStatus = EStatus.NORMAL;
    template: string = "";
    title: string = "";
    pageComponentList: Array<FssPageComponent> = [];
    pageData: FssPageData = new FssPageData();
    marketPageTitle?: string;

    validate() {
        return "";
    }
}

export class FssPageData {
    backgroundColor: string = "";
    fontSize: string = "";
}
