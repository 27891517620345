export default class FssOrderTrackData {
//快递公司编码
    deliveryProviderCode?: string;
    //    快递公司名称
    deliveryProviderName?: string;
    //    快递单号
    deliveryNoteId?: string;
    deliveredDatetimeText?: string;
    scheduleDate?: string;
    scheduleDateText?: string;
    scheduleTimeSlot?: string;
    estimateDatetime?: string;
    estimateDatetimeText?: string;
    shippingTypeText?: string;
    shipmentStatusText?: string;
    trackLogList?: Array<FssOrderTrackLog>;
}

export class FssOrderTrackLog {
    orderId?: string;
    operatorName?: string;
    operateDatetime?: string;
    description?: string;
}
