import * as React from "react";
import {ReactNode} from "react";
import Button from "@material-ui/core/Button";
import FtDivFlex from "../ui/FtDivFlex";
import FtPanel from "../ui/FtPanel";
import DialogContent from "@material-ui/core/DialogContent";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import FssProduct, {EBusinessType} from "../model/FssProduct";
import FtTabPanel from "../ui/FtTabPanel";
import FtTabs from "../ui/FtTabs";
import Dialog from "@material-ui/core/Dialog";
import FssProductChildDetailComp from "./FssProductChildDetailComp";
import DialogActions from "@material-ui/core/DialogActions";
import FtToast from "../ui/FtToast";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import FssProductBaseInfoTabPanel from "./FssProductBaseInfoTabPanel";
import {i18n} from "../util/I18n";
import FtRow from "../ui/FtRow";
import TranslateIcon from '@material-ui/icons/Translate';
import {Language} from "../data/AppClientInfo";
import FtToolbar from "../ui/FtToolbar";
import FtSnackbar from "../ui/FtSnackbar";
import JtMceEditor from "../common/JtMceEditor";
import FssProductService from "../service/FssProductService";
import {EOrderStatus} from "../model/FssOrder";
import FssOrderListPage, {EOrderPageType} from "../order/FssOrderListPage";
import {RouteComponentProps} from "react-router-dom";
import FssClientData from "../data/FssClientData";
import FtTranslation from "../model/FtTranslation";
import FssTranslationService from "../service/FssTranslationService";

interface JtProductDetailCompProp extends RouteComponentProps{
    fssProduct: FssProduct;
    onChange?: (product: FssProduct) => void;
    hideTagsPanel?: boolean;
}

export class JtProductDetailCompState {
    tabValue: string = "";
    lang: string = "";
    openChildProductDialog: boolean = false;
    childProduct: FssProduct = new FssProduct();
    childProductIndex: number = -1;
    productDescription: string = "";
}

export default class FssProductDetailComp extends React.Component<JtProductDetailCompProp, JtProductDetailCompState> {
    jtMceEditor!: JtMceEditor;

    constructor(props: any) {
        super(props);
        let appWebsite = FssClientData.getData().appWebsite;
        let lang ="";
        if(appWebsite && appWebsite.language){
            lang = appWebsite.language
        }
        this.state = {
            tabValue: "basic",
            lang: lang,
            openChildProductDialog: false,
            childProduct: new FssProduct(),
            childProductIndex: -1,
            productDescription: ""
        };

        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onCloseChildProductDialog = this.onCloseChildProductDialog.bind(this);
        this.onClickSaveChild = this.onClickSaveChild.bind(this);
        this.onChangeChildProduct = this.onChangeChildProduct.bind(this);
        this.onClickLangBtn = this.onClickLangBtn.bind(this);
        this.saveTranslation = this.saveTranslation.bind(this);
        this.onChangeTranslation = this.onChangeTranslation.bind(this);
    }

    componentDidMount(): void {
        const appWebsite=FssClientData.getData().appWebsite;
        if (appWebsite.supportLanguages !=null && appWebsite.supportLanguages.length>0) {
            appWebsite.supportLanguages.some((language: string) => {
                if (language !== appWebsite.language) {
                    this.onClickLangBtn(language);
                    return true;
                }
            });
        }
    }

    onChangeDescription(content: string) {
        this.props.fssProduct.productDetail.description = content;
        if (this.props.onChange){
            this.props.onChange(this.props.fssProduct);
        }
    }

    onChangeTranslation(content: string) {
        this.setState({productDescription: content});
    }

    saveTranslation() {
        FtToast.showToastLoading(i18n("正在保存"));
        let content = this.state.productDescription;
        const translation: FtTranslation = FtTranslation.newInstance(this.props.fssProduct.productDetail.id, "productDetail", "description", this.state.lang, content);
        FssTranslationService.saveTranslation(translation, ()=>{
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功！"));
        });
    }

    render() {
        let isEdit: boolean = false;
        if (this.props.fssProduct.id != null && this.props.fssProduct.id.length != 0)
            isEdit = true;
        let isMainProduct: boolean = true;
        if (this.props.fssProduct.parentProductId != null && this.props.fssProduct.parentProductId.length > 0)
            isMainProduct = false;
        let isNormalProduct = true;
        if(this.props.fssProduct.businessType == EBusinessType.EXCHANGE){
            isNormalProduct = false;
        }
        return <FtTabs value={this.state.tabValue} indicatorColor="primary" textColor="primary"
                       onChange={(event: React.ChangeEvent<{}>, newValue: string) => {
                           this.setState({tabValue: newValue});
                       }}>
            <FtTabPanel label={i18n("基本信息")} value="basic">{this.getBasicInfoPanel()}</FtTabPanel>
            {isMainProduct &&
            <FtTabPanel label={i18n("商品介绍")} value="description">
                {this.getDescriptionPanel()}
            </FtTabPanel>}
            {isMainProduct && isEdit && isNormalProduct &&
            <FtTabPanel label={this.props.fssProduct.childProductList && this.props.fssProduct.childProductList.length > 0 ? i18n("共有") + this.props.fssProduct.childProductList.length + i18n("已对账") : i18n("子商品")}
                        value="childProduct">
                {this.getChildProductPanel()}
            </FtTabPanel>}
            {isEdit &&<FtTabPanel label={i18n("订单记录")} value="orderHistory">
                {this.getOrderListPage()}
            </FtTabPanel>}
        </FtTabs>
    }

    private getOrderListPage() {
        let filterColumns = new Map<string, any>();
        filterColumns.set("productId", this.props.fssProduct.id);
        filterColumns.set("containChildProdut", true);
        let defaultFilter = new Map<string, any>();
        defaultFilter.set("orderStatus", [EOrderStatus.CREATED, EOrderStatus.APPROVED,
            EOrderStatus.PICKING, EOrderStatus.PACKAGING, EOrderStatus.SHIPPED, EOrderStatus.DELIVERED]);
        return <FssOrderListPage key={this.props.location.pathname}
                                 history={this.props.history}
                                 location={this.props.location}
                                 match={this.props.match}
                                 orderPageType={EOrderPageType.FIND_ORDER}
                                 filterColumns={filterColumns}
                                 isProductDetailPage={true}
        />;
    }

    private getBasicInfoPanel() {
        return <FssProductBaseInfoTabPanel fssProduct={this.props.fssProduct}
                                           hideTagsPanel={this.props.hideTagsPanel}
                                           onChangeProduct={this.props.onChange} />;
    }

    onClickLangBtn(lang: string) {
        this.setState({lang: lang}, ()=>{
            const appWebsite=FssClientData.getData().appWebsite;
            if (appWebsite && this.state.lang === appWebsite.language) {
                this.setState({productDescription: this.props.fssProduct.productDetail.description});
            } else {
                FssTranslationService.getTranslationContent(this.props.fssProduct.productDetail.id, "productDetail", "description", this.state.lang,(data: Array<FtTranslation>)=>{
                    FtToast.closeToast();
                    if (data && data.length > 0) {
                        const translation: FtTranslation = data[0];
                        this.setState({productDescription: translation.content});
                    } else {
                        this.setState({productDescription: ""});
                    }
                });
            }
        });
    }

    private getDescriptionPanel(): ReactNode {
        let defauleLangNode: ReactNode| undefined = undefined;
        const langArr: Array<ReactNode> = [];
        const appWebsite=FssClientData.getData().appWebsite;
        if (appWebsite.language) {
            defauleLangNode = <Button variant={"outlined"}>
                {this.getLanguageText(appWebsite.language) + "(系统默认)"}
            </Button>;
            if (appWebsite.supportLanguages && appWebsite.supportLanguages.length > 0) {
                appWebsite.supportLanguages.map((language: string)=>{
                    if (language !== appWebsite.language) {
                        langArr.push(<Button variant={"outlined"} onClick={()=>{this.onClickLangBtn(language)}}
                                             color={this.state.lang === language ? "primary" : "default"}>
                            {this.getLanguageText(language)}
                        </Button>);
                    }
                });
            }
        }
        return <FtRow cellWidthS={"1,1"}>
            <FtPanel>
                <FtToolbar centerNode={i18n("商品详情")}
                           leftNode={langArr.length>0&&
                           <FtRow>
                               <TranslateIcon />
                               {defauleLangNode}
                           </FtRow>}/>
                <JtMceEditor ref={(element: any) => {this.jtMceEditor = element;}}
                             onChangeContent={this.onChangeDescription}
                             initialValue={this.props.fssProduct.productDetail.description}
                             height={"500px"} width={"100%"} />
            </FtPanel>
            {this.props.fssProduct.id.length > 0 && defauleLangNode !== undefined && langArr.length > 0 &&
            <FtPanel>
                <FtToolbar rightNode={<Button variant={"contained"} color={"primary"} onClick={this.saveTranslation}>保存</Button>}
                           leftNode={langArr.length>0&&
                           <FtRow>
                               <TranslateIcon />
                               {langArr.map((node: ReactNode)=>{
                                   return node;
                               })}
                           </FtRow>}/>
                <JtMceEditor ref={(element: any) => {this.jtMceEditor = element;}}
                             onChangeContent={this.onChangeTranslation}
                             initialValue={this.state.productDescription}
                             height={"500px"} width={"100%"} />
            </FtPanel>}
        </FtRow>;
    }

    private getChildProductPanel() {
        return <div>
            <FtDivFlex justifyContent={"flex-start"} style={{width: "100%", margin: 16}}>
                <Button variant="contained" size="small" onClick={() => {
                    let specificationList = this.props.fssProduct.specificationList;
                    let canAdd=false;
                    specificationList.forEach(spec=>{
                        if(spec.name!=null&& spec.name!=""&& spec.value!=null && spec.value!=""){
                            canAdd=true;
                        }
                    })
                    if(!canAdd){
                        FtSnackbar.showText(i18n("请先完善主商品规格信息"));
                        return;
                    }

                    const fssProduct: FssProduct = new FssProduct();
                    fssProduct.parentProductId = this.props.fssProduct.id;
                    fssProduct.parentProduct = this.props.fssProduct;
                    fssProduct.businessType=this.props.fssProduct.businessType
                    fssProduct.categoryId=this.props.fssProduct.categoryId;
                    fssProduct.name = this.props.fssProduct.name;
                    fssProduct.title = this.props.fssProduct.title;
                    fssProduct.shortDescription=this.props.fssProduct.shortDescription;
                    fssProduct.brand = this.props.fssProduct.brand;
                    fssProduct.shippingFeeTemplateId = this.props.fssProduct.shippingFeeTemplateId;
                    fssProduct.salesPriceUsd = this.props.fssProduct.salesPriceUsd;
                    fssProduct.regularPriceUsd = this.props.fssProduct.regularPriceUsd;
                    fssProduct.coverImageUrl = this.props.fssProduct.coverImageUrl;
                    let productImageList: Array<string> = [];
                    if(this.props.fssProduct.productImageList!=null&& this.props.fssProduct.productImageList.length>0){
                        this.props.fssProduct.productImageList.forEach(value => {
                            productImageList.push(value);
                        })
                    }
                    fssProduct.productImageList = productImageList;
                    fssProduct.specificationList = this.props.fssProduct.specificationList;
                    fssProduct.taxNameIds=this.props.fssProduct.taxNameIds;
                    fssProduct.taxNameList=this.props.fssProduct.taxNameList;
                    fssProduct.tagsList=this.props.fssProduct.tagsList;
                    fssProduct.defaultTagsList=this.props.fssProduct.defaultTagsList;
                    fssProduct.deliveryProviderList=this.props.fssProduct.deliveryProviderList;
                    fssProduct.shippingFeeTemplateIdList=this.props.fssProduct.shippingFeeTemplateIdList;
                    this.setState({childProduct: fssProduct, openChildProductDialog: true, childProductIndex: -1});
                }}>
                    <AddIcon/>
                    {i18n("新增子商品")}
                </Button>
            </FtDivFlex>
            <Table style={{}}>
                <TableHead>
                    <TableRow>
                        <TableCell>{i18n("图片")}</TableCell>
                        <TableCell>{i18n("模板名称")}</TableCell>
                        <TableCell>{i18n("规格")}</TableCell>
                        <TableCell align={"right"}>{i18n("销售价")}</TableCell>
                        <TableCell align={"right"}>{i18n("库存数量")}</TableCell>
                        <TableCell>{i18n("模板名称")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.props.fssProduct.childProductList && this.props.fssProduct.childProductList.map((childProduct: FssProduct, index: number) => {
                        if (childProduct.parentProduct == null)
                            childProduct.parentProduct = this.props.fssProduct;
                        return <TableRow key={childProduct.id} onClick={() => {
                            this.setState({
                                childProduct: childProduct,
                                openChildProductDialog: true,
                                childProductIndex: index
                            });
                        }}>
                            <TableCell component="th" scope="row">
                                <img style={{width: 60, height: 60}} src={childProduct.coverImageUrl}></img>
                            </TableCell>
                            <TableCell>{childProduct.name}</TableCell>
                            <TableCell>{childProduct.specificationValue}</TableCell>
                            <TableCell align={"right"}>{childProduct.salesPriceUsd}</TableCell>
                            <TableCell align={"right"}>{childProduct.stockAmount}</TableCell>
                            <TableCell>{childProduct.shippingFeeTemplate && childProduct.shippingFeeTemplate.templateName}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
            <Dialog maxWidth={"md"} open={this.state.openChildProductDialog} onClose={this.onCloseChildProductDialog}
                    scroll={"paper"}>
                <DialogContent>
                    <FssProductChildDetailComp fssProduct={this.state.childProduct}
                                               onChange={this.onChangeChildProduct} />
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" onClick={this.onCloseChildProductDialog}>{i18n("关闭")}</Button>
                    <Button variant="contained" color="primary" onClick={this.onClickSaveChild}>{i18n("保存并关闭")}</Button>
                </DialogActions>
            </Dialog>
        </div>
    }

    onChangeChildProduct(fssProduct: FssProduct) {
        this.setState({childProduct: fssProduct});
    }

    onCloseChildProductDialog() {
        this.setState({openChildProductDialog: false, childProduct: new FssProduct(), childProductIndex: -1});
    }

    onClickSaveChild() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssProductService.saveProduct(this.state.childProduct, (childProduct: FssProduct) => {
            FtToast.closeToast();
            this.state.childProduct.parentProduct = this.props.fssProduct;
            if (this.state.childProductIndex < 0)
                this.props.fssProduct.childProductList.push(childProduct);
            else
                this.props.fssProduct.childProductList[this.state.childProductIndex] = childProduct;
            if (this.props.onChange)
                this.props.onChange(this.props.fssProduct);
            this.onCloseChildProductDialog();
        });
    }
    getLanguageText (language: string) {
        let text = "";
        switch (language as Language) {
            case Language.en_CA:
                text = "English";
                break;
            case Language.zh_CN:
                text = "简体中文";
                break;
            case Language.zh_TW:
                text = "繁体中文";
                break;
        }
        return text;
    }

}
