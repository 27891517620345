import * as React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AppClientInfo, {Language} from "../data/AppClientInfo";
import {FtOptionDialogItem} from "./FtOptionDialog";
import FtComponent, {FtComponentState} from "./FtComponent";
import {i18n} from "../util/I18n";
import {Typography} from "@material-ui/core";


export interface JtLanguageOptionDialogProps {
    languageOptions:Array<string>;
    onSelectLanguage?: (option: FtOptionDialogItem, index: number) => void;
}

export class JtLanguageOptionDialogPropsState extends FtComponentState{
    open: boolean = false;
    content?: string | React.ReactNode;
    options: Array<FtOptionDialogItem> = [];
}

export default class JtLanguageOptionDialog extends FtComponent<JtLanguageOptionDialogProps, JtLanguageOptionDialogPropsState> {
    static languageOptionDialog: JtLanguageOptionDialog;
    constructor(props: any) {
        super(props);
        this.state = {open: false, options: []};
        this.openDialog = this.openDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.onSelectLanguage=this.onSelectLanguage.bind(this);
    }

    openDialog(content?: Element | string) {
        const itemOptions: Array<FtOptionDialogItem> = [];
        this.props.languageOptions.forEach(value => {
            if (value == Language.zh_CN) {
                itemOptions.push(new FtOptionDialogItem(Language.zh_CN, Language.zh_CN, "简体中文"));
            } else if (value == Language.en_CA) {
                itemOptions.push(new FtOptionDialogItem(Language.en_CA, Language.en_CA, "English"));
            } else if (value == Language.zh_TW) {
                itemOptions.push(new FtOptionDialogItem(Language.zh_TW, Language.zh_TW, "繁體中文"));
            }
        });
        this.setState({
            open: true,
            content: content!=null?content:i18n("请选择界面语言"),
            options: itemOptions
        });
    }

    closeDialog() {
        this.setState({open: false});
    }

    render() {
        return (
            <Dialog open={this.state.open} maxWidth={"md"} style={{zIndex: 1500}}>
                <DialogContent style={{margin: 8}}>
                    {this.state.content}
                    <List component="nav">
                        {this.state.options && this.state.options.map((option: FtOptionDialogItem, index: number) => {
                            return <ListItem key={option.key} button onClick={() => {
                                this.onSelectLanguage(option, index);
                            }}>
                                {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
                                <ListItemText primary={<Typography color={"secondary"}>{option.primaryText}</Typography>} secondary={option.secondaryText}/>
                            </ListItem>
                        })}
                    </List>
                </DialogContent>
            </Dialog>
        );
    }

    private onSelectLanguage(option:FtOptionDialogItem,index:number){
        JtLanguageOptionDialog.languageOptionDialog.closeDialog();
        AppClientInfo.clientInfo.language=option.value;
        if (this.props.onSelectLanguage) {
            this.props.onSelectLanguage(option, index);
        }
    }
};
