import {ETwoStatus, FtAbstractModel} from "../model/FtAbstractModel";

export enum EPaymentProvider {
    Stripe = "Stripe",
    Moneris = "Moneris",
    AlphaPay = "AlphaPay",
    IOTPay = "IOTPay",
    IOTPayV2 = "IOTPayV2",
    SnapPay = "SnapPay",
    MotionPay = "MotionPay",
    OttPay = "OttPay",
    PayPal = "PayPal",
    PAYNICORN = "PAYNICORN",
}

export enum EPaymentChannel {
    PAYNICORN = "PAYNICORN",
    PAYNICORN_MINIPROGRAM = "PAYNICORN_MINIPROGRAM",
    PAYNICORN_APP = "PAYNICORN_APP",
}

export enum EAccountStatus {
    NORMAL = 0,
    DISABLED = 99,
}

export enum EVerifyType {
    VERIFY_CVD = "VERIFY_CVD",
    VERIFY_AVS = "VERIFY_AVS"
}

export enum EBalanceRechargeDefault {
    BALANCE_RECHARGE_STORE_ID = "BALANCE_RECHARGE_STORE_ID",
    BALANCE_RECHARGE_SELLER_ID = "BALANCE_RECHARGE_SELLER_ID"
}

export default class FssPaymentAccount extends FtAbstractModel {
    paymentAccountName: string = "";
    description?: string;
    createDatetimeText: string = "";
    accountStatus?: ETwoStatus;
    paymentMethod?: EPaymentMethod;
    paymentMethodText: string = "";
    paymentProvider?: EPaymentProvider;
    paymentChannel?: EPaymentChannel;
    verifyType: EVerifyType = EVerifyType.VERIFY_CVD;
    paymentProviderKey0: string = "";
    paymentProviderKey1: string = "";
    paymentProviderKey2: string = "";
    paymentProviderKey3: string = "";
    accountOrder?: number;
    storeId: string = "";
    sellerId: string = "";
}

export enum EPaymentMethod {
    PAYNICORN = "PAYNICORN"
}
