import * as React from "react";
import FtUtil from "../util/FtUtil";
import {
    Checkbox,
    FormControl,
    FormControlLabel,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import {RouteComponentProps} from "react-router-dom";
import {Save} from "@material-ui/icons";
import FssRegion from "../model/FssRegion";
import FssRegionService from "../service/FssRegionService";
import FtPagedList from "../model/FtPagedList";
import FssShippingFeeRuleService from "../service/FssShippingFeeRuleService";
import FtPage from "../ui/FtPage";
import FssShippingFeeRule from "../model/FssShippingFeeRule";

interface JtShippingFeeRuleDetailPageProp extends RouteComponentProps<{ id: string,shippingFeeTemplateId: string }> {

}

export class JtShippingFeeRuleDetailPageState {
    shippingFeeRule: FssShippingFeeRule = new FssShippingFeeRule();
    fssReginList: Array<FssRegion> = [];
}

export enum ECountry {
    ALL = "ALL"
}

export default class JtShippingFeeRuleDetailPage extends React.Component<JtShippingFeeRuleDetailPageProp, JtShippingFeeRuleDetailPageState> {
    constructor(props: any) {
        super(props);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.loadData = this.loadData.bind(this);

        const shippingFeeRule = new FssShippingFeeRule();
        shippingFeeRule.shippingFeeTemplateId = this.props.match.params.shippingFeeTemplateId;
        this.state = {
            shippingFeeRule: shippingFeeRule,
            fssReginList: new Array<FssRegion>()
        };
    }

    componentDidMount(): void {
        this.loadData();
        this.loadFssRigion();
    }

    loadFssRigion(){
        let filterColumns = new Map<string, any>();
        filterColumns.set("status", "NORMAL");
        FssRegionService.getPageRegionList("",filterColumns, null, null, 0, 999,
            (result: FtPagedList<FssRegion>) => {
                FtToast.closeToast();
                this.setState({fssReginList:result.content});
                this.forceUpdate();
            });
    }
    loadData () {
        if (this.props.match.params.id && this.props.match.params.id!="0") {
            FtToast.showToastLoading("loading");
            FssShippingFeeRuleService.getShippingFeeRuleById(this.props.match.params.id, (shippingFeeRule: FssShippingFeeRule)=>{
                FtToast.closeToast();
                if (shippingFeeRule) {

                    this.setState({
                        shippingFeeRule: shippingFeeRule
                    });
                }
            });
        }
    }


    onCancelClick(): void {
        this.props.history.goBack();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssShippingFeeRuleService.saveShippingFeeRule(this.state.shippingFeeRule,
            (data: FssShippingFeeRule) => {
                this.setState({shippingFeeRule: FtUtil.mergeObject(this.state.shippingFeeRule, data)}, ()=>{
                    this.onCancelClick();
                });
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
            });
    }

    onChangeValue(id: string, value?: unknown) {
        FtUtil.setProperty(this.state.shippingFeeRule, id, value);
        this.forceUpdate();
    }

    render() {
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="contained"
                                                           color="primary"
                                                           startIcon={<Save />}
                                                           onClick={this.onClickSave}>{i18n("保存")}</Button>}>
            <FtPanel panelHeader={i18n("模板规则信息")}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"}>
                        <FtTextFieldNumber id="baseValue" label={i18n("首重(克)/件/体积(立方米)/距离(米)")}
                                           value={this.state.shippingFeeRule.baseValue}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("baseValue", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{textAlign: "right"}}
                        />
                        <FtTextFieldNumber id="basePrice" label={i18n("首重(克)/件/体积(立方米)/距离(米)价格")}
                                           value={this.state.shippingFeeRule.basePrice}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("basePrice", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{textAlign: "right"}}
                        />
                        <FtTextFieldNumber id="continueValue" label={i18n("续重(克)/件/体积(立方米)/距离(米)")}
                                           value={this.state.shippingFeeRule.continueValue}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("continueValue", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="continuePrice" label={i18n("续重(克)/件/体积(立方米)/距离(米)价格")}
                                           value={this.state.shippingFeeRule.continuePrice}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("continuePrice", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="maxMoney" label={i18n("最大金额")}
                                           value={this.state.shippingFeeRule.maxMoney}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("maxMoney", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="minMoney" label={i18n("最小金额")}
                                           value={this.state.shippingFeeRule.minMoney}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("minMoney", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="minimumOrderMoney" label={i18n("起送金额")}
                                           value={this.state.shippingFeeRule.minimumOrderMoney}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("minimumOrderMoney", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="minimumOrderWeight" label={i18n("起送重量")}
                                           value={this.state.shippingFeeRule.minimumOrderWeight}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("minimumOrderWeight", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }} endAdornment={<InputAdornment position="end">Kg</InputAdornment>}/>
                        <FtTextFieldNumber id="freeShipBaseMinimum" label={i18n("免基础运费订单金额")}
                                           value={this.state.shippingFeeRule.freeShipBaseMinimum}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("freeShipBaseMinimum", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="freeShipAllMinimum" label={i18n("免全部运费订单金额")}
                                           value={this.state.shippingFeeRule.freeShipAllMinimum}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("freeShipAllMinimum", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="splitByProductAmount" label={i18n("单一商品分箱数量")}
                                           value={this.state.shippingFeeRule.splitByProductAmount}
                                           type="integer"
                                           onChangeValue={value => this.onChangeValue("splitByProductAmount", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="splitByTotalAmount" label={i18n("全部商品分箱数量")}
                                           value={this.state.shippingFeeRule.splitByTotalAmount}
                                           type="integer"
                                           onChangeValue={value => this.onChangeValue("splitByTotalAmount", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <FtTextFieldNumber id="splitByTotalWeight" label={i18n("按重量分箱")}
                                           value={this.state.shippingFeeRule.splitByTotalWeight}
                                           type="float"
                                           onChangeValue={value => this.onChangeValue("splitByTotalWeight", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                        <TextField id="description" label={i18n("规则说明")}
                                   value={this.state.shippingFeeRule.description}
                                   onChange={(event)=>{this.onChangeValue("description", event.target.value)}} style={{width: "55%"}}/>
                        <FormControlLabel
                            style={{width: "100%"}}
                            control={
                                <Checkbox
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        this.state.shippingFeeRule.freeShipBaseCumulate = event.target.checked;
                                        this.forceUpdate();
                                    }}
                                    id="freeShipBaseCumulate"
                                    color="primary"
                                    checked={this.state.shippingFeeRule.freeShipBaseCumulate}
                                />
                            }
                            label={i18n("免基础运费累积")}
                        />
                        <FtTextFieldNumber id="priority" label={i18n("优先级")}
                                           value={this.state.shippingFeeRule.priority}
                                           type="integer"
                                           onChangeValue={value => this.onChangeValue("priority", value)}
                                           style={{width: "100%"}}
                                           inputStyle={{
                                               textAlign: "right"
                                           }}/>
                    </FtRow>
                    <FtRow>
                        <FtTextFieldString id="boundLatLng" label={i18n("边界")} multiline={true} rows={5}
                                           value={this.state.shippingFeeRule.boundLatLng}
                                           onChangeValue={(value)=>{
                                               this.state.shippingFeeRule.boundLatLng = value ? value : "";
                                               this.forceUpdate();
                                           }} style={{width: "300"}}/>
                    </FtRow>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"}>
                    <FormControl style={{width: '100%'}}>
                        <InputLabel> {i18n("地区")} </InputLabel>
                        <Select id="regionId" value={this.state.shippingFeeRule.regionId}
                                onChange={(event, child) => {
                                    this.state.shippingFeeRule.regionId = event.target.value as unknown as string;
                                    this.forceUpdate();
                                }}>
                            {this.state.fssReginList.map(value => {
                                return <MenuItem value={value.id}>{value.regionName}</MenuItem>
                            })
                            }

                        </Select>
                    </FormControl>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </FtPage>;
    }
}
