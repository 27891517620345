import * as React from "react";
import FtDivFlex from "./FtDivFlex";
import IconButton from "@material-ui/core/IconButton";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigatePrevIcon from "@material-ui/icons/NavigateBefore";

export interface JtPaginationCompProp {
    pageNo: number;
    pageSize: number;
    onChange?: (pageNo: number, pageSize: number) => void;
    totalRowCount?: number;
    totalPageCount?: number;
}

export class JtPaginationCompState {
}

export default class JtPaginationComp extends React.Component<JtPaginationCompProp, JtPaginationCompState> {
    constructor(props: any) {
        super(props);
        this.state = {pageNo: 0, pageSize: 20};
        this.onClickPrev = this.onClickPrev.bind(this);
        this.onClickNext = this.onClickNext.bind(this);
    }

    onClickPrev() {
        if (this.props.onChange)
            this.props.onChange(this.props.pageNo - 1, this.props.pageSize);
    }

    onClickNext() {
        this.setState({pageNo: this.props.pageNo + 1});
        if (this.props.onChange)
            this.props.onChange(this.props.pageNo + 1, this.props.pageSize);
    }

    render() {
        return <FtDivFlex justifyContent={"right"}>
            <div>Rows per page: {this.props.pageSize}</div>
            <IconButton onClick={this.onClickPrev} disabled={this.props.pageNo == 0}><NavigatePrevIcon/></IconButton>
            <div>Page {this.props.pageNo + 1} / {this.props.totalPageCount}</div>
            <IconButton onClick={this.onClickNext}
                        disabled={this.props.pageNo == (this.props.totalPageCount ? this.props.totalPageCount - 1 : 0)}><NavigateNextIcon/></IconButton>

        </FtDivFlex>
    }
}
