import * as React from "react";
import {Box, withStyles} from "@material-ui/core";
import FssAbstractCompProp from "./FssAbstractCompProp";
import FtComponent, {FtComponentState} from "../../ui/FtComponent";
import FssHtmlCompData from "./data/FssHtmlCompData";

interface FssHtmlCompProp extends FssAbstractCompProp {
}

export class FssHtmlCompState extends FtComponentState{
}

const StyledBox = withStyles(theme => ({
    root: {
        border: "1px solid #cccccc",
        boxSizing: "border-box",
        "&:hover": {
            border: "1px solid #cccccc"
        }
    }
}))(Box);

export default class FssHtmlComp extends FtComponent<FssHtmlCompProp, FssHtmlCompState> {

    constructor(props: any) {
        super(props);
        this.state = {
            stateKey: "FssHtmlComp" + this.props.location.pathname};
        if (this.props.history.action == "POP") {
            super.popStateCache();
        }
    }

    componentDidMount(): void {
    }

    render() {
        const compData = this.props.component.data as FssHtmlCompData;
        if (compData != null)
            return <div key={this.props.component.id}
                        dangerouslySetInnerHTML={{__html: compData.htmlCode}} style={{
                marginLeft: compData.marginLeft,
                marginRight: compData.marginRight,
                marginTop: compData.marginTop,
                marginBottom: compData.marginBottom,
                borderTopLeftRadius: compData.borderTopLeftRadius,
                borderTopRightRadius: compData.borderTopRightRadius,
                borderBottomLeftRadius: compData.borderBottomLeftRadius,
                borderBottomRightRadius: compData.borderBottomRightRadius
            }}>
            </div>;
        else
            return null;
    }

}
