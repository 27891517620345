import * as React from "react";
import FssComponentEditWrapper from "./editor/FssComponentEditWrapper";
import {Fab, Typography} from "@material-ui/core";
import lodash from "lodash";
import {HeadsetMic, ShoppingCart} from "@material-ui/icons";
import {i18n} from "../util/I18n";
import {RouteComponentProps} from "react-router-dom";
import FssPage from "../model/FssPage";
import FssPageComponent from "../model/FssPageComponent";
import {EComponentType} from "../model/FssComponent";
import FssContainerComp from "../page/component/FssContainerComp";
import FssImageListComp from "../page/component/FssImageListComp";
import FssCategoryGridComp from "../page/component/FssCategoryGridComp";
import FssProductListCompData from "../page/component/data/FssProductListCompData";
import FssCategoryNavigationCompData from "../page/component/data/FssCategoryNavigationCompData";
import FssCategoryListCompData from "../page/component/data/FssCategoryListCompData";
import FssHtmlComp from "../page/component/FssHtmlComp";
import FssLogoBannerComp from "../page/component/FssLogoBannerComp";
import FssVideoComp from "../page/component/FssVideoComp";
import FssSearchBoxComp from "../page/component/FssSearchBoxComp";
import FssNavigationComp from "../page/component/FssNavigationComp";

interface FssPageRenderProp extends RouteComponentProps {
    fssPage: FssPage;
    onClickPageComponent?: (pageComponent: FssPageComponent, index?: number) => void;
    pageComponentList?: Array<FssPageComponent>;
    onPageComponentList?: (pageComponentList: Array<FssPageComponent>) => void;
    editMode?: boolean;
    selectedIndex?: number;
    pageRenderDraggableFlag?: boolean;
}

export class FssPageRenderState {
    dragElement: FssPageComponent = new FssPageComponent();
    lock: boolean = true;
}


export default class FssPageRender extends React.Component<FssPageRenderProp, FssPageRenderState> {

    constructor(props: any) {
        super(props);
        this.state = {dragElement: new FssPageComponent(), lock: true};
        this.onClickPage = this.onClickPage.bind(this);
    }

    componentDidMount(): void {
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    }

    componentWillUnmount() {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    }

    preventDefault = (e: Event) => {
        e.preventDefault();
    }

    onDragStart = (pageComponent: FssPageComponent) => {
        this.setState({dragElement: pageComponent});
    }

    onDragEnter = (pageComponent: FssPageComponent) => {
        if (this.props.pageRenderDraggableFlag) {
            let pageComponentList = this.props.pageComponentList;
            if (pageComponentList) {
                let pageComponentId = pageComponent.id;
                if (pageComponentId !== this.state.dragElement.id) {
                    const oldDragIndex = lodash.findIndex(pageComponentList, (item: { id: string; }) => item.id === this.state.dragElement.id);
                    const oldEnterIndex = lodash.findIndex(pageComponentList, (item: { id: string; }) => item.id === pageComponent.id);
                    if (oldDragIndex > oldEnterIndex) {
                        const newDataArray = pageComponentList.filter(item => item.id !== this.state.dragElement.id);
                        const insertIndex = lodash.findIndex(newDataArray, (item: { id: string; }) => item.id === pageComponent.id);
                        newDataArray.splice(insertIndex, 0, this.state.dragElement);
                        this.onClickPage(newDataArray);
                    } else {
                        const newDataArray = pageComponentList.filter(item => item.id !== this.state.dragElement.id);
                        const insertIndex = lodash.findIndex(newDataArray, (item: { id: string; }) => item.id === pageComponent.id) + 1;
                        newDataArray.splice(insertIndex, 0, this.state.dragElement);
                        this.onClickPage(newDataArray);
                    }
                }
            }
        }
    }

    onDragLeave = (pageComponent: FssPageComponent) => {
        if (this.props.pageRenderDraggableFlag) {
            let pageComponentList = this.props.pageComponentList;
            if (pageComponentList) {
                if (pageComponent.id !== this.state.dragElement.id) {
                    if (this.state.lock && pageComponent.id === (pageComponentList[pageComponentList.length - 1]).id) {
                        const newDataArray = pageComponentList.filter(item => item.id !== this.state.dragElement.id);
                        newDataArray.push(this.state.dragElement);
                        this.setState({lock: false,}, () => {
                            this.onClickPage(newDataArray);
                        });
                    } else {
                        this.setState({lock: true,});
                    }
                }
            }
        }
    }

    onClickPage(pageComponentList: Array<FssPageComponent>) {
        if (this.props.onPageComponentList) {
            this.props.onPageComponentList(pageComponentList);
        }
    }

    render() {
        return <div key={this.props.fssPage.id}>
            {this.props.pageComponentList && this.props.pageComponentList.map((pageComponent: FssPageComponent, index: number) => {
                if (this.props.editMode) {
                    return <div key={pageComponent.id}
                                draggable={this.props.pageRenderDraggableFlag}
                                onDragStart={() => {
                                    this.onDragStart(pageComponent)
                                }}
                                onDragEnd={(e) => {
                                    e.preventDefault();
                                }}
                                onDragEnter={() => {
                                    this.onDragEnter(pageComponent)
                                }}
                                onDragLeave={() => {
                                    this.onDragLeave(pageComponent)
                                }}>
                        <FssComponentEditWrapper
                            pageComponent={pageComponent} onClickMe={this.props.onClickPageComponent}
                            selected={this.props.selectedIndex != null && this.props.selectedIndex == index}
                            index={index}>
                            <div style={{pointerEvents: "none"}}>
                                {this.renderComponent(pageComponent, index)}
                            </div>
                        </FssComponentEditWrapper>
                    </div>
                } else {
                    return this.renderComponent(pageComponent, index);
                }
            })}
        </div>;
    }

    private renderComponent(pageComponent: FssPageComponent, index: number) {
        if (pageComponent.component) {
            switch (pageComponent.component.componentType) {
                case EComponentType.CONTAINER:
                    return <FssContainerComp pageData={this.props.fssPage.pageData} component={pageComponent.component}
                                             history={this.props.history}
                                             match={this.props.match} location={this.props.location}/>
                case EComponentType.IMAGE_LIST:
                    return <FssImageListComp pageData={this.props.fssPage.pageData} component={pageComponent.component}
                                             history={this.props.history} editMode={true}
                                             match={this.props.match} location={this.props.location}/>
                case EComponentType.PRODUCT_LIST:
                    const compData = pageComponent.component.data as FssProductListCompData;
                    return <div>
                        <Typography variant={"h5"}>{i18n("数据来源")}：{compData.productListDataSource}</Typography>
                        <Typography variant={"h5"}>{i18n("排序")}： {compData.sortType}</Typography>
                    </div>;
                case EComponentType.CATEGORY_GRID:
                    return <FssCategoryGridComp pageData={this.props.fssPage.pageData}
                                                component={pageComponent.component} history={this.props.history}
                                                match={this.props.match}
                                                location={this.props.location}/>
                case EComponentType.CATEGORY_NAVIGATION:
                    return <div>
                        <Typography variant={"h5"}>{i18n("分类、商品组合")}</Typography>
                    </div>;
                // case EComponentType.CATEGORY_LIST:
                //     const categoryListCompData = pageComponent.component.data as FssCategoryListCompData;
                //     return <div>
                //         <Typography
                //             variant={"h5"}>{i18n("共 {0} 个分类", categoryListCompData.categoryIdArray?.length + "")}</Typography>
                //     </div>;
                case EComponentType.HTML:
                    return <FssHtmlComp pageData={this.props.fssPage.pageData} component={pageComponent.component}
                                        history={this.props.history}
                                        match={this.props.match} location={this.props.location}/>
                case EComponentType.LOGO_BANNER:
                    return <FssLogoBannerComp pageData={this.props.fssPage.pageData} component={pageComponent.component}
                                              history={this.props.history} editMode={true}
                                              match={this.props.match}
                                              location={this.props.location}/>
                case EComponentType.VIDEO:
                    return <FssVideoComp pageData={this.props.fssPage.pageData} component={pageComponent.component}
                                         history={this.props.history}
                                         match={this.props.match} location={this.props.location}/>
                case EComponentType.SEARCH_BOX:
                    return <FssSearchBoxComp sellerId={pageComponent.sellerId} pageData={this.props.fssPage.pageData}
                                             editMode={true}
                                             component={pageComponent.component} history={this.props.history}
                                             match={this.props.match} location={this.props.location}/>
                case EComponentType.NAVIGATION:
                    return <FssNavigationComp {...this.props} marketPageTitle={this.props.fssPage.marketPageTitle}/>
                case EComponentType.SHOPPING_CART_ICON:
                    return <Fab size={"small"} style={{backgroundColor: "white", left: 15, top: 90}}>
                        <ShoppingCart color="primary"/>
                    </Fab>
                case EComponentType.CONTACTS_ICON:
                    return <Fab size={"small"} style={{backgroundColor: "white", left: 15, top: 90}}>
                        <HeadsetMic color="primary"/>
                    </Fab>
            }
        }
    }
}
