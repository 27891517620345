import * as React from "react";
import FtRow from "../ui/FtRow";
import {Button, TextField, Typography} from "@material-ui/core";
import {i18n} from "../util/I18n";
import MenuItem from "@material-ui/core/MenuItem";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";

import {Save} from "@material-ui/icons";
import {ReactNode} from "react";
import FssAppWebsite, {EUserHandleFeeType} from "../model/FssAppWebsite";
import FssAppWebsiteService from "../service/FssAppWebsiteService";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FtPage from "../ui/FtPage";
import FtPanel from "../ui/FtPanel";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import FssClientData from "../data/FssClientData";
import * as Fs from "fs";
import FssSellerService from "../service/FssSellerService";

export class JtApplicationSettingsPageState {
    appWebsite: FssAppWebsite = new FssAppWebsite();
}

export default class JtApplicationSettingsPage extends React.Component<any, JtApplicationSettingsPageState> {

    constructor(props: any) {
        super(props);
        this.state = {appWebsite: new FssAppWebsite()};

        this.loadData = this.loadData.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    loadData () {
        FtToast.showToastLoading();
        FssAppWebsiteService.getAppWebsite((website: FssAppWebsite) => {
            FtToast.closeToast();
            this.setState({appWebsite: website});
        });
    }

    onChange (appWebsite: FssAppWebsite) {
        this.setState({appWebsite: appWebsite});
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssAppWebsiteService.saveAppWebsite(this.state.appWebsite,
            (appWebsite: FssAppWebsite) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
            });
    }

    render() {
        let website = this.state.appWebsite;

        const paperStyle: any = {padding: 15, marginBottom: 15};
        return <FtPage onCancelClick={this.onCancelClick}
                                  toolbarLeftNode={<Button variant="contained"
                                                           color="primary"
                                                           startIcon={<Save />}
                                                           onClick={this.onClickSave}>{i18n("保存")}</Button>}
                                  toolbarCenterNode={<Typography variant="h6">{i18n("应用参数设置")}</Typography>}>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={<Typography variant="subtitle1" color={"secondary"}>{i18n("基本信息")}</Typography>}>
                <FtRow cellWidthS={"1,1"} cellHorizontalSpace={16}>
                    <FtTextFieldString id="appId" label={i18n("应用ID")} style={{width: "100%"}} value={website.id?website.id:""} disabled={true}/>
                    <FtTextFieldString id="appName" label={i18n("应用名称")} value={website.websiteName?website.websiteName:""}
                                       style={{width: "100%"}}
                                       onChangeValue={(value)=>{
                                           website.websiteName = value ? value: "";
                                           this.onChange(website);
                                       }}/>
                    <FtTextFieldString id="createDatetimeText" label={i18n("创建时间")} value={website.createDatetime?FssClientData.formatDatetime(website.createDatetime):""}
                                       style={{width: "100%"}} disabled={true}/>
                    <FtTextFieldString id="userDomainName" label={i18n("用户端域名")} value={website.userDomainName}
                                       style={{width: "100%"}} disabled={true}/>

                    <TextField select value={website.appVisibility} id={"appVisibility"}
                               label={i18n("可见性")} style={{width: "100%"}}
                               onChange={(event: any) => {
                                   website.appVisibility = event.target.value;
                                   this.onChange(website);
                               }}>
                        <MenuItem value={"PUBLIC"} key={"PUBLIC"}>{i18n("所有人可见")}</MenuItem>
                        <MenuItem value={"REGISTER_USER"} key={"REGISTER_USER"}>{i18n("注册用户可见")}</MenuItem>
                        <MenuItem value={"USER_LEVEL_1"} key={"USER_LEVEL_1"}>{i18n("用户等级1可见")}</MenuItem>
                    </TextField>
                    <TextField select value={website.userHandleFeeType} id={"userHandleFeeType"}
                               label={i18n("用户手续费")} style={{width: "100%"}}
                               onChange={(event: any) => {
                                   website.userHandleFeeType = event.target.value;
                                   website.userHandleFee = 0;
                                   this.onChange(website);
                               }}>
                        <MenuItem value={"NONE"} key={"NONE"}>{i18n("不收取")}</MenuItem>
                        <MenuItem value={"PAYMENT_FEE"} key={"PAYMENT_FEE"}>{i18n("收取支付手续费")}</MenuItem>
                        <MenuItem value={"PAYMENT_AND_TRANSACTION"} key={"PAYMENT_AND_TRANSACTION"}>{i18n("收取支付手续费+交易手续费")}</MenuItem>
                        <MenuItem value={"FIXED_AMOUNT"} key={"FIXED_AMOUNT"}>{i18n("固定金额")}</MenuItem>
                        <MenuItem value={"FIXED_PERCENT"} key={"FIXED_PERCENT"}>{i18n("固定百分比")}</MenuItem>
                    </TextField>
                    {website.userHandleFeeType != EUserHandleFeeType.NONE
                    && <FtTextFieldString id="userHandleFeeDesc" label={i18n("手续费说明")}
                                          style={{width: "100%"}} value={website.userHandleFeeDesc}
                                          onChangeValue={(value)=>{
                                              website.userHandleFeeDesc = value ? value: "";
                                              this.onChange(website);
                                          }}
                                          endAdornment={this.getTranslationIcon("userHandleFeeDesc", website.userHandleFeeDesc)}/>}
                    {website.userHandleFeeType == EUserHandleFeeType.FIXED_AMOUNT
                    && <FtTextFieldNumber id="userHandleFee" label={i18n("手续费")} value={website.userHandleFee}
                                          onChangeValue={(value)=>{
                                              website.userHandleFee = value ? value: 0;
                                              this.onChange(website);
                                          }} style={{width: "100%"}} type={"currency"}/>}
                    {website.userHandleFeeType == EUserHandleFeeType.FIXED_PERCENT
                    && <FtTextFieldNumber id="userHandleFee" label={i18n("手续费百分比")} value={website.userHandleFee}
                                          onChangeValue={(value)=>{
                                              website.userHandleFee = value ? value: 0;
                                              this.onChange(website);
                                          }} style={{width: "100%"}} type={"float"}/>}
                </FtRow>

            </FtPanel>
        </FtPage>;
    };

    getTranslationIcon (itemKey: string, defaultText?: string): ReactNode {
        if (this.state.appWebsite && this.state.appWebsite.id && this.state.appWebsite.id.length > 0) {
            let appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite && appWebsite.supportLanguages !== undefined && appWebsite.supportLanguages.length>0) {
                return <FssTranslationDialogComp objectId={this.state.appWebsite.id}
                                                groupKey={"appInfo"} itemKey={itemKey}
                                                dialogTitle={"多语言编辑"} defaultText={defaultText}/>;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
