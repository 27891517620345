export default class FssUserMedia {
    id: string = "";
    userId: string = "";
    storeId: string = "";
    sellerId: string = "";
    createDatetime ?: Date;
    mediaType: EMediaType = EMediaType.PICTURE;
    mediaName: string = "";
    mediaTag: string = "";
    mediaDescription: string = "";
    mediaUrl: string = "";
    mediaSourceCode: string = "";
    multipleMediaUrls: Array<string> = new Array<string>();

    validate() {
        return "";
    }
}

export enum EMediaType {
    PICTURE = "PICTURE",
    VIDEO = "VIDEO",
    TENGCENT_VIDEO = "TENGCENT_VIDEO",
    YOUTUBE_VIDEO = "YOUTUBE_VIDEO"
}
