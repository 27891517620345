import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import FtMessageDialog from "../ui/FtMessageDialog";
import {Link, RouteComponentProps} from "react-router-dom";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Add} from "@material-ui/icons";
import FtSnackbar from "../ui/FtSnackbar";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FssPage from "../model/FssPage";
import FssPageContent from "../model/FssPageContent";
import FssPageService from "../service/FssPageService";
import FtRow from "../ui/FtRow";


export interface FssPageListPageProp extends RouteComponentProps {
    onClickItem?: (jtPageContent: FssPage) => void;
    selectedList?: Array<FssPage>;
    onRemoveItem?: (pageContentId: number) => void;
}

class FssPageListPageState extends FtComponentState {
    inputComposition: boolean = false;
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
}

class JtTableAdapter extends FtDataTableAdapter<FssPage> {

    loadData(): void {
        FtToast.showToastLoading("loading");
        FssPageService.getPagedList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size, (result: FtPagedList<FssPage>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssPageListPage extends FtComponent<FssPageListPageProp, FssPageListPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const columnPageUri: FtDataTableColumnProps = new FtDataTableColumnProps("pageUri", i18n("页面URI"));
        columnProps.push(columnPageUri);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("title", i18n("标题"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const page: FssPage = rowData as FssPage;
            return <Button onClick={()=>{this.props.history.push("/pageDetail/" + page.id)}} disabled={page.pageUri == "/account"}>
                {page.title}
            </Button>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("template", i18n("模板"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("screenType", i18n("类型"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("status", i18n("状态"), "right");
        columnProps.push(column4);

        let tableAdapter: JtTableAdapter;
        const cacheState: FssPageListPageState = FtState.pop("FssPageListPage");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            inputComposition: false,
            stateKey: "FssPageListPage"
        };
        this.onCompositionStart = this.onCompositionStart.bind(this);
        this.onCompositionEnd = this.onCompositionEnd.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);
        this.getToolbarWithSelect = this.getToolbarWithSelect.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.onChangePageNo = this.onChangePageNo.bind(this);
        this.onClickResetAllPage = this.onClickResetAllPage.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    onCompositionStart(event: any) {
        this.setState({inputComposition: true});
    }

    onCompositionEnd(event: any) {
        this.setState({inputComposition: false});
    }

    render() {
        return <div>
            <FtDataTable title={i18n("页面列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarWithSelect={this.getToolbarWithSelect()}
                         toolbarNoSelect={this.getToolbarNoSelect()}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }

    onChangePageNo() {
        this.state.tableAdapter.changePageNo(0);
    }

    getFilterPanel(): ReactNode {
        return <FtRow>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 200}} onKeyDownEnter={this.onChangePageNo}/>
            <TextField id="template" label={i18n("模板")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("template", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("template")} style={{width: 200}}/>
            <FormControl style={{minWidth: 150}}>
                <InputLabel> {i18n("状态")} </InputLabel>
                <Select id="status" value={this.state.tableAdapter.getFilterColumnValue("status")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("status", event.target.value);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={"NORMAL"} key={"NORMAL"}>{i18n("启用")}</MenuItem>
                    <MenuItem value={"STOP"} key={"STOP"}>{i18n("禁用")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{minWidth: 150}}>
                <InputLabel> {i18n("屏幕类型")} </InputLabel>
                <Select id="screenType" value={this.state.tableAdapter.getFilterColumnValue("screenType")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("screenType", event.target.value);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={""} key={"ALL"}>{i18n("全部商品")}</MenuItem>
                    <MenuItem value={"MOBILE"} key={"MOBILE"}>{i18n("收件人手机")}</MenuItem>
                    <MenuItem value={"PC"} key={"PC"}>{i18n("电脑")}</MenuItem>
                </Select>
            </FormControl>
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/pageDetail/0"}>
                <Button>
                    <Add/>{i18n("新增")}
                </Button>
            </Link>
            <Button onClick={this.onClickResetAllPage}>{i18n("重置全部页面")}</Button>
        </div>;
    }

    getToolbarWithSelect(): ReactNode {
        return <div><Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={this.onClickDelete}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
        </div>;
    }

    onClickDelete() {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除{0}个记录吗？", this.state.tableAdapter.selectedRow.length + ""), () => {
                    FtMessageDialog.dialog.closeDialog();
                    const idList: Array<string> = [];
                    this.state.tableAdapter.selectedRow.forEach((rowData: any, index: number) => {
                        idList.push((rowData as FssPageContent).id);
                    });
                    FssPageService.deletePage(idList, (data: string) => {
                        FtSnackbar.showText(data);
                        this.state.tableAdapter.selectedRow = new Array<any>();
                        this.state.tableAdapter.loadData();
                    });
                },
                () => {
                    FtMessageDialog.dialog.closeDialog();
                }
            )
        }
    }

    onClickResetAllPage() {
        FtMessageDialog.dialog.openDialog(i18n("确定要重置所有页面吗？"), () => {
                FtMessageDialog.dialog.closeDialog();
                FssPageService.resetPageTemplate("default", (data: string) => {
                    FtSnackbar.showText(data);
                    this.state.tableAdapter.selectedRow = new Array<any>();
                    this.state.tableAdapter.loadData();
                });
            },
            () => {
                FtMessageDialog.dialog.closeDialog();
            }
        )
    }
}
