import FssAppWebsite from "../model/FssAppWebsite";
import FssHttpService from "./FssHttpService";
import {FssWebSession} from "../model/FssWebSession";
import FssStore from "../model/FssStore";
import FasAppEmail from "../model/FasAppEmail";

export default class FssAppWebsiteService {
    static checkWebSession(success: (data: FssWebSession) => void) {
        FssHttpService.get("/api/appWebsite/checkWebSession", null, success);
    }

    static getAppWebsite(success: (data: FssAppWebsite) => void) {
        FssHttpService.get("/api/appWebsite/getAppWebsite", null, success);
    }

    static saveAppWebsite(appWebsite: FssAppWebsite, successFunction: (appWebsite: FssAppWebsite) => void) {
        FssHttpService.postJson("/api/appWebsite/saveAppWebsite", appWebsite, successFunction, FssHttpService.handleError);
    }

    static getAvailableStoreList(success: (data: Array<FssStore>) => void) {
        FssHttpService.get("/api/appWebsite/getAvailableStoreList", null, success);
    }

    static updateAdminSession(storeId: string | undefined, sellerId: string | undefined, success: (data: FssWebSession) => void) {
        FssHttpService.postFormData("/api/appWebsite/updateAdminSession", {
            storeId: storeId ? storeId : "",
            sellerId: sellerId ? sellerId : ""
        }, success);
    }
    static saveFasAppEmail(fasAppEmail: FasAppEmail, successFunction: (fasAppEmail: FasAppEmail) => void) {
        FssHttpService.postJson("/api/appWebsite/saveFasAppEmail", fasAppEmail, successFunction, FssHttpService.handleError);
    }

    static getFasAppEmail(successFunction: (fasAppEmail: FasAppEmail) => void) {
        FssHttpService.get("/api/appWebsite/getFasAppEmail", null, successFunction, FssHttpService.handleError);
    }

    static checkFasAppEmail(fasAppEmail: FasAppEmail, successFunction: (fasAppEmail: FasAppEmail) => void, failFunction ?: (error: string) => void) {
        FssHttpService.postJson("/api/appWebsite/checkFasAppEmail", fasAppEmail, successFunction, failFunction?failFunction:FssHttpService.handleError);
    }
    static getLanguageText(successFunction: (textMap: Array<any>) => void) {
        FssHttpService.get("/api/appWebsite/getLanguageText", null, successFunction, FssHttpService.handleError);
    }
}
