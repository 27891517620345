import * as React from "react";
import FssAbstractCompProp from "./FssAbstractCompProp";
import FtComponent, {FtComponentState} from "../../ui/FtComponent";
import FssVideoCompData from "./data/FssVideoCompData";

interface FssVideoCompProp extends FssAbstractCompProp {
}


export class FssVideoCompState extends FtComponentState {
}


export default class FssVideoComp extends FtComponent<FssVideoCompProp, FssVideoCompState> {

    constructor(props: any) {
        super(props);
        this.state = {stateKey: "FssVideoComp" + this.props.location.pathname};
        if (this.props.history.action == "POP") {
            super.popStateCache();
        }
    }

    componentDidMount(): void {
    }

    render() {
        const compData = this.props.component.data as FssVideoCompData;
        if (compData != null)
            return <div dangerouslySetInnerHTML={{__html: compData.htmlCode}} style={{
                marginLeft: compData.marginLeft,
                marginRight: compData.marginRight,
                marginTop: compData.marginTop,
                marginBottom: compData.marginBottom,
                borderTopLeftRadius: compData.borderTopLeftRadius,
                borderTopRightRadius: compData.borderTopRightRadius,
                borderBottomLeftRadius: compData.borderBottomLeftRadius,
                borderBottomRightRadius: compData.borderBottomRightRadius
            }}>
            </div>;
        else
            return null;
    }

}
