import * as React from "react";
import {CSSProperties, ReactNode} from "react";
import {InputAdornment, TextField} from "@material-ui/core";
import {BaseTextFieldProps} from "@material-ui/core/TextField";
import {OutlinedInputProps} from "@material-ui/core/OutlinedInput";
import {FilledInputProps} from "@material-ui/core/FilledInput";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";
import _ from "lodash";

interface FtTextFieldPercentProps extends BaseTextFieldProps {
    helperText?: string;
    originalValue?: unknown;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    variant?: "standard" | "filled" | "outlined";
    onChangeValue?: (value?: number | undefined) => void;
    onBlur?: StandardInputProps['onBlur'] | FilledInputProps["onBlur"] | OutlinedInputProps["onBlur"];
    onFocus?: StandardInputProps['onFocus'] | FilledInputProps["onFocus"] | OutlinedInputProps["onFocus"];
    value?: number;
    inputStyle?: CSSProperties;
}

class FtTextFieldPercentState {
    value?: number;
    text: string = "";
}

const charFloat = new RegExp("^[-+]?[0-9]*[.]?[0-9]*%$");

export default class FtTextFieldPercent extends React.Component<FtTextFieldPercentProps, FtTextFieldPercentState> {
    constructor(props: any) {
        super(props);
        this.state = {text: ""};
        this.onChange = this.onChange.bind(this);
        this.onChangeCapture = this.onChangeCapture.bind(this);
    }

    static getDerivedStateFromProps(props: FtTextFieldPercentProps, state: FtTextFieldPercentState) {
        if (props.value != state.value) {
            const textValue = FtTextFieldPercent.getNumberValue(state.text);
            //如果当前文本是负号，或者文本的数值和传入的数值相同则保留text
            if (state.text == "-%" || props.value == textValue)
                return {value: props.value};
            else
                return {value: props.value, text: FtTextFieldPercent.formatValue(props.value)};
        }
        return state;
    }

    render() {
        const baseTextFieldProps: BaseTextFieldProps = this.props;
        let variant: any = "standard";
        if (this.props.variant)
            variant = this.props.variant;
        const InputProps: any = {};
        if (this.props.startAdornment)
            InputProps.startAdornment = <InputAdornment position="start">{this.props.startAdornment}</InputAdornment>;
        if (this.props.endAdornment)
            InputProps.endAdornment = <InputAdornment position="end">{this.props.endAdornment}</InputAdornment>;
        if (this.props.variant)
            variant = this.props.variant;
        let error: boolean | undefined = this.props.error;
        let helperText: string | undefined = this.props.helperText ? this.props.helperText : "";
        if (this.props.originalValue && this.props.value != this.props.originalValue)
            return <TextField {...baseTextFieldProps} value={this.state.text} error={error} helperText={helperText}
                              variant={"filled"} inputProps={{style: this.props.inputStyle}}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
        else
            return <TextField {...baseTextFieldProps} value={this.state.text} error={error} helperText={helperText}
                              variant={variant} inputProps={{style: this.props.inputStyle}}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
    }

    private static formatValue(value?: number): string {
        const result = value != null && value != undefined ? (_.round(value*100,6))+"%" : "%";
        return result;
    }

    static getNumberValue(text: string): number | undefined {
        if(text==null||text==""||text=="%"||text=="-%")
            return undefined;
        const numberValue=text.substring(0,text.length-1);
        if (isNaN(parseFloat(numberValue)))
            return undefined;
        return _.round(parseFloat(numberValue)/100,6);
    }

    private onChange(event: any) {
        const value: string = event.target.value as string;
        //不允许删掉百分号
        if(value!=null&&(!value.trim().endsWith("%")))
            return;

        this.setState({text: value});
        //检查修改之后的值是否会和props里的数值一样，如果不一样调用onchangevalue
        const oldValue = this.props.value;
        let newValue = undefined;
        if (value.trim().length > 0) {
            newValue = FtTextFieldPercent.getNumberValue(value);
        }
        if (this.props.onChangeValue && oldValue != newValue)
            this.props.onChangeValue(newValue);

    }

    private onChangeCapture(event: any) {
        let message = "";
        const value = event.target.value;
        if (!charFloat.test(value))
            message = "请输入有效的数字";
        if (message != null && message.length > 0) {
            event.preventDefault();
            event.stopPropagation();
            return false;
        } else
            return true;
    }
}
