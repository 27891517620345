import * as React from "react";
import FssAbstractCompProp from "./FssAbstractCompProp";
import FtComponent, {FtComponentState} from "../../ui/FtComponent";
import FssImageListCompData, {EImageListLayout, FssImageLink} from "./data/FssImageListCompData";
import FtRow from "../../ui/FtRow";
import {Typography} from "@material-ui/core";
import AppClientInfo from "../../data/AppClientInfo";
import {i18n} from "../../util/I18n";
import FssClientData from "../../data/FssClientData";
import {Carousel} from "react-responsive-carousel";

interface FssImageListCompProp extends FssAbstractCompProp {
    editMode?: boolean;
}


export class FssImageListCompState extends FtComponentState {
}


export default class FssImageListComp extends FtComponent<FssImageListCompProp, FssImageListCompState> {

    constructor(props: any) {
        super(props);
        this.state = {stateKey: "FssImageListComp" + this.props.location.pathname};
        if (this.props.history.action == "POP") {
            super.popStateCache();
        }
    }

    componentDidMount(): void {

    }

    render() {
        if (this.props.editMode != null && this.props.editMode)
            return this.renderEditMode();
        else {
            const compData = this.props.component.data as FssImageListCompData;
            let imageLinkArray = compData.imageLinkArray;
            if (AppClientInfo.clientInfo.viewMode != null && AppClientInfo.clientInfo.viewMode == "pc")
                imageLinkArray = compData.pcImageLinkArray;

            if (imageLinkArray != null && imageLinkArray.length > 0) {
                if (compData.layout != null && compData.layout == EImageListLayout.CAROUSEL) {
                    return <div className={"FssCarousel"} style={{
                        marginLeft: compData.marginLeft,
                        marginRight: compData.marginRight,
                        marginTop: compData.marginTop,
                        marginBottom: compData.marginBottom,
                    }}>
                        <Carousel showThumbs={false} infiniteLoop={true}
                                  showStatus={false} autoPlay={true}
                                  interval={4000}>
                            {imageLinkArray.map((imageLink: FssImageLink, index: number) => {
                                return this.getImageDiv(imageLink, index, compData);
                            })}
                        </Carousel>
                    </div>;
                } else if (compData.layout != null && compData.layout == EImageListLayout.ROW) {
                    let cellWidthS = "1";
                    if (compData.mobileLineNum == 2)
                        cellWidthS = "1,1";
                    else if (compData.mobileLineNum == 3)
                        cellWidthS = "1,1,1";
                    return <FtRow cellWidthS={cellWidthS} cellVerticalSpace={0} cellHorizontalSpace={0} style={{
                        marginLeft: compData.marginLeft,
                        marginRight: compData.marginRight,
                        marginTop: compData.marginTop,
                        marginBottom: compData.marginBottom,
                        borderTopLeftRadius: compData.borderTopLeftRadius,
                        borderTopRightRadius: compData.borderTopRightRadius,
                        borderBottomLeftRadius: compData.borderBottomLeftRadius,
                        borderBottomRightRadius: compData.borderBottomRightRadius
                    }}>
                        {imageLinkArray.map((imageLink: FssImageLink, index: number) => {
                            return this.getImageDiv(imageLink, index, compData);
                        })}
                    </FtRow>;
                }
            } else {
                return null;
            }
        }
    }

    private getImageDiv(imageLink: FssImageLink, index: number, compData: FssImageListCompData) {
        if (imageLink.link != null && imageLink.link.length > 0) {
            const indexDomainName = imageLink.link.toLowerCase().indexOf(FssClientData.getData().appWebsite.userDomainName.toLowerCase());
            if (indexDomainName > 0) {
                const targetUrl: URL = new URL(imageLink.link);
                return <div onClick={() => {
                    let uri = "";
                    if (targetUrl.hash != null && targetUrl.hash.length > 0)
                        uri = targetUrl.hash.substr(1);
                    this.props.history.push(uri + targetUrl.search)
                }} key={index}>
                    {this.getImg(compData, imageLink.imageUrl)}
                </div>;
            } else
                return <div>
                    <a href={imageLink.link}>{this.getImg(compData, imageLink.imageUrl)}</a>
                </div>;
        } else
            return <div key={index}>{this.getImg(compData, imageLink.imageUrl)}</div>;
    }

    private getImg(compData: FssImageListCompData, src?: string) {
        return <img src={src}
                    style={{
                        width: "100%", display: "block",
                        borderTopLeftRadius: compData.borderTopLeftRadius,
                        borderTopRightRadius: compData.borderTopRightRadius,
                        borderBottomLeftRadius: compData.borderBottomLeftRadius,
                        borderBottomRightRadius: compData.borderBottomRightRadius
                    }}/>
    }

    private renderEditMode() {
        const compData = this.props.component.data as FssImageListCompData;
        return <div>
            <Typography variant={"h5"}>{i18n("显示方式") + ":" + compData.layout}</Typography>
            <Typography variant={"h5"}>{i18n("共{0}个图片", compData.imageLinkArray?.length + "")}</Typography>
        </div>;
    }
}
