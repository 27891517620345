import * as React from "react";
import {Box, withStyles} from "@material-ui/core";
import {grey, red} from "@material-ui/core/colors";
import FssPageComponent from "../../model/FssPageComponent";


interface FssComponentEditWrapperProp {
    pageComponent: FssPageComponent;
    index?: number;
    selected?: boolean;
    onClickMe?: (pageComponent: FssPageComponent, index?: number) => void;
}

export class FssComponentEditWrapperState {
}

const StyledBox = withStyles(theme => ({
    root: {
        borderWidth: 1,
        borderColor: grey[200],
        borderStyle: "solid",
        boxSizing: "border-box",
        overflow: "scroll",
        height: 200,
        "&:hover": {
            borderWidth: 1,
            borderColor: theme.palette.primary.light,
            borderStyle: "solid"
        }
    },
}))(Box);
const SelectedStyledBox = withStyles(theme => ({
    root: {
        borderWidth: 1,
        borderColor: theme.palette.primary.light,
        borderStyle: "solid",
        boxSizing: "border-box",
        overflow: "scroll",
        height: 200
    },
}))(Box);

export default class FssComponentEditWrapper extends React.Component<FssComponentEditWrapperProp, FssComponentEditWrapperState> {

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onClickMe = this.onClickMe.bind(this);
    }

    componentDidMount(): void {
    }

    render() {
        if (this.props.selected)
            return <SelectedStyledBox onClick={this.onClickMe}>
                <div style={{width: "100%"}}>{this.props.pageComponent.component?.componentName}</div>
                {this.props.children}
            </SelectedStyledBox>;
        else
            return <StyledBox onClick={this.onClickMe}>
                <div style={{width: "100%"}}>{this.props.pageComponent.component?.componentName}</div>
                {this.props.children}
            </StyledBox>;
    }

    private onClickMe() {
        if (this.props.onClickMe)
            this.props.onClickMe(this.props.pageComponent, this.props.index);
    }
}
