import * as React from "react";
import {ReactNode} from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {Button, FormLabel} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Link, RouteComponentProps} from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import FssProductService from "../service/FssProductService";
import FssProductReview, {EReviewStatus} from "../model/FssProductReview";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtPagedList from "../model/FtPagedList";
import {i18n} from "../util/I18n";
import FtState from "../ui/FtState";
import FtPage from "../ui/FtPage";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import FtToast from "../ui/FtToast";
import FssClientData from "../data/FssClientData";


export interface FssProductReviewListPageProp extends RouteComponentProps {
}

class FssProductReviewListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
}

class JtTableAdapter extends FtDataTableAdapter<FssProductReview> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssProductService.getProductReviewPagedListAdmin(this.filterColumns, this.pagedList.number, this.pagedList.size, (pagedList: FtPagedList<FssProductReview>)=>{
            FtToast.closeToast();
            this.pagedList = pagedList;
            this.fireUpdateData();
        });
    }
}

export default class FssProductReviewListPage extends FtComponent<FssProductReviewListPageProp, FssProductReviewListPageState> {

    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("productName", i18n("商品名称"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProductReview: FssProductReview = rowData as FssProductReview;
            return <Link to={"/fssProductReviewDetailPage/" + fssProductReview.id}>
                <Typography style={{wordWrap: "break-word", width: 120}}>{fssProductReview.productName}</Typography>
            </Link>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("userNickName", i18n("用户昵称"));
        column2.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProductReview: FssProductReview = rowData as FssProductReview;
            return <Typography style={{wordWrap: "break-word", width: 120}}>{fssProductReview.userNickName}</Typography>
        };
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("starCount", i18n("评价等级"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("content", i18n("评价内容"));
        column4.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProductReview: FssProductReview = rowData as FssProductReview;
            return <Typography style={{wordWrap: "break-word", width: 300}}>{fssProductReview.content}</Typography>
        };
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("", i18n("评价图片"));
        column5.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProductReview: FssProductReview = rowData as FssProductReview;
            let showBtn = false;
            if (fssProductReview.reviewImage1 && fssProductReview.reviewImage1.trim().length > 0) {
                showBtn = true;
            } else if (fssProductReview.reviewImage2 && fssProductReview.reviewImage2.trim().length > 0) {
                showBtn = true;
            } else if (fssProductReview.reviewImage3 && fssProductReview.reviewImage3.trim().length > 0) {
                showBtn = true;
            }
            if (showBtn) {
                return <Button variant={"outlined"} style={{width: 100}}
                               onClick={() => {
                                   this.props.history.push("/fssProductReviewDetailPage/" + fssProductReview.id)
                               }}>查看图片</Button>
            } else {
                return <Button variant={"outlined"} style={{width: 100}} disabled={true}>暂无图片</Button>
            }
        };
        columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("reviewStatusText", i18n("状态"));
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetimeText", i18n("评价时间"));
        column7.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssProductReview: FssProductReview = rowData as FssProductReview;
            return <Typography>{FssClientData.formatDatetime(fssProductReview.createDatetime)}</Typography>
        };
        columnProps.push(column7);

        console.info(this.state);
        let tableAdapter: JtTableAdapter;
        const cacheState: FssProductReviewListPageState = FtState.get("fssProductReviewListPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            stateKey: "fssProductReviewListPageState"
        };

        this.onChangePageNo = this.onChangePageNo.bind(this);
        this.getFilterPanel = this.getFilterPanel.bind(this);
    }

    onChangePageNo () {
        this.state.tableAdapter.changePageNo(0);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <FtPage onCancelClick={()=>{this.props.history.goBack()}}
                                  toolbarLeftNode={<Typography variant="h6">{i18n("商品评价列表")}</Typography>}>
            <FtDataTable columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </FtPage>;
    }

    getFilterPanel(): ReactNode {
        return <div>
            <FtTextFieldString label={i18n("关键字")} value={this.state.tableAdapter.filterColumns.get("searchKeyword")}
                               onChangeValue={(value) => {
                                   this.state.tableAdapter.filterColumns.set("searchKeyword", value);
                                   this.forceUpdate();
                               }} onKeyDownEnter={this.onChangePageNo}/>
            {/*<FtTextFieldNumber type={"integer"} label={"商品ID"} value={this.state.tableAdapter.filterColumns.get("productId")}*/}
            {/*                   onChangeValue={(value)=>{*/}
            {/*                       this.state.tableAdapter.filterColumns.set("productId", value);*/}
            {/*                       this.forceUpdate();*/}
            {/*                   }}/>*/}
            <FormControl>
                <FormLabel> {i18n("状态")} </FormLabel>
                <FtCheckboxGroup row={true}
                                 values={[EReviewStatus.TO_BE_APPROVED, EReviewStatus.APPROVE, EReviewStatus.ADMIN_DELETED, EReviewStatus.USER_DELETED]}
                                 labels={[i18n("待审核"), i18n("审核通过"), i18n("商家屏蔽"), i18n("用户屏蔽")]}
                                 checkedValues={this.state.tableAdapter.filterColumns.get("reviewStatusArray")}
                                 onCheck={(checkedValue) => {
                                     this.state.tableAdapter.filterColumns.set("reviewStatusArray", checkedValue);
                                     this.forceUpdate();
                                 }} />
            </FormControl>
        </div>;
    }
}
