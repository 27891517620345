import * as React from "react";
import {CSSProperties} from "react";
import {CardHeader} from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import StoreIcon from '@material-ui/icons/Store';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SettingsIcon from '@material-ui/icons/Settings';
import LanguageIcon from '@material-ui/icons/Language';
import EmailIcon from '@material-ui/icons/Email';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import FtPanel from "../ui/FtPanel";
import {grey} from "@material-ui/core/colors";
import {i18n} from "../util/I18n";
import PersonAddIcon from '@material-ui/icons/PersonAdd';

class FssSettingMainPageState {
    statisticsList: Array<number> = [];
    statisticsTextList: Array<string> = [];
    updateList: Array<any> = [];
}

export default class FssSettingMainPage extends React.Component<any, FssSettingMainPageState> {

    constructor(props: any) {
        super(props);
        this.state = {statisticsList: [], statisticsTextList: [], updateList: []};
    }

    render() {
        let iconSize = 40;
        let cardStyle: CSSProperties = {cursor: "pointer", width: 220, marginRight: 8, marginBottom: 8}
        return <FtPanel elevation={0} marginVertical={12} marginHorizontal={8} style={{backgroundColor: grey[200]}}>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <Card style={cardStyle} onClick={() => this.props.history.push("/sellerProfile")}>
                    <CardHeader id={"sellerDetail"}
                                avatar={<StoreIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("商家信息")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/orderSetting")}>
                    <CardHeader avatar={<LibraryBooksIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("订单设置")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/notificationSetting")}>
                    <CardHeader avatar={<EmailIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("通知设置")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/shippingSetting")}>
                    <CardHeader avatar={<LocalShippingIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("配送设置")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/taxSetting")}>
                    <CardHeader avatar={<MonetizationOnIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("税/费")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/languageSetting")}>
                    <CardHeader avatar={<LanguageIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("时区和语言")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/applicationSetting")}>
                    <CardHeader avatar={<SettingsIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("应用参数")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/sellerEmployeeList")}>
                    <CardHeader avatar={<PersonAddIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("成员管理")}
                                subheader={i18n("")}
                    />
                </Card>
            </div>
        </FtPanel>
    }
}
{/*<Card style={cardStyle}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/lightSpeedSetting");}}*/
}
{/*                avatar={<AllInclusiveIcon style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title="LightSpeed"*/
}
{/*                subheader={i18n("第三方对接描述")}*/
}
{/*    />*/
}
{/*</Card>*/
}
{/*<Card style={cardStyle}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/appSetting");}}*/
}
{/*                avatar={<SettingsEthernet style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title="其他"*/
}
{/*                subheader="其他描述"*/
}
{/*    />*/
}
{/*</Card>*/
}
{/*<Card style={cardStyle}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/domainLoginSetting");}}*/
}
{/*                avatar={<PeopleIcon style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title={i18n("注册与登录设置")}*/
}
{/*                subheader={i18n("注册与登录设置")}*/
}
{/*    />*/
}
{/*</Card>*/
}
{/*<Card style={cardStyle}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/facebookSetting");}}*/
}
{/*                avatar={<Facebook style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title={i18n("Facebook设置")}*/
}
{/*                subheader={i18n("Facebook设置")}*/
}
{/*    />*/
}
{/*</Card>*/
}
{/*<Card style={cardStyle}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/googleSetting");}}*/
}
{/*                avatar={<ShowChart style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title={i18n("Google设置")}*/
}
{/*                subheader={i18n("设置GoogleAnalytics信息")}*/
}
{/*    />*/
}
{/*</Card>*/
}
{/*<Card style={cardStyle}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/synData");}}*/
}
{/*                avatar={<ScoreIcon style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title={i18n("同步旧数据")}*/
}
{/*                subheader={i18n("同步旧数据")}*/
}
{/*    />*/
}
{/*</Card>*/
}
