import * as React from "react";
import {CSSProperties} from "react";
import JtProduct, {EProductStatus} from "../model/FssProduct";
import FtComboboxDynamic from "../ui/FtComboboxDynamic";
import {i18n} from "../util/I18n";
import FssProduct from "../model/FssProduct";
import FssProductService from "../service/FssProductService";
import FssHttpService from "../service//FssHttpService";

interface JtProductComboBoxProp {
    label?: string;
    selectedProduct?: FssProduct;
    onChangeProduct?: (product?: FssProduct) => void;
}

class JtProductComboBoxState {
    productList: Array<FssProduct> = [];
    product?:FssProduct;
    loading:boolean=false;
}

export default class FssProductComboBox extends React.Component<JtProductComboBoxProp, JtProductComboBoxState> {
    constructor(props: any) {
        super(props);
        this.state = {productList:[],loading:false};
        this.onChangeProductInput=this.onChangeProductInput.bind(this);
        this.onChangeProduct=this.onChangeProduct.bind(this);
    }


    componentDidMount(): void {

    }

    render() {
        //如果没有商品列表，则至少要把当前选中的商品放入列表，否则不显示
        let productList=this.state.productList;
        if((productList==null||productList.length==0)&&this.props.selectedProduct!=null)
            productList=[this.props.selectedProduct];
        return <FtComboboxDynamic label={i18n("商品")} optionItems={productList}
                                  labelPropertyName={"title"}
                                  valuePropertyName={"id"}
                                  selectedItem={this.props.selectedProduct}
                                  onSelectItem={this.onChangeProduct}
                                  onChangeInput={this.onChangeProductInput}
                                  loading={this.state.loading} style={{width:"100%"}}></FtComboboxDynamic>;
    }


    onChangeProductInput(keyword: string) {
        if(!this.state.loading) {
            this.setState({loading: true}, () => {
                FssProductService.getAvailProductList(keyword, 5, [EProductStatus.ENABLE, EProductStatus.STOP_SELL, EProductStatus.DISABLE], (productList: Array<JtProduct>) => {
                    this.setState({productList: productList, loading: false});
                },(error:any)=>{
                    FssHttpService.handleError(error);
                    this.setState({loading:false});
                });
            });
        }
    }

    onChangeProduct(item: any) {
        this.setState({product: item});
        if(this.props.onChangeProduct)
            this.props.onChangeProduct(item);
    }
}
