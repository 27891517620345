import * as React from "react";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {RouteComponentProps} from "react-router-dom";
import {EOrderStatus, EPaymentStatus} from "../model/FssOrder";
import FssOrderListPage, {EOrderPageType} from "./FssOrderListPage";
import {EMenuitem} from "../mainframe/FssSellerAdminMainPage";

interface FssOrderTabsPageProp extends RouteComponentProps<{orderType: string, objectId: string}> {

}

class FssOrderTabsPageState extends FtComponentState {
}

export default class FssOrderTabsPage extends FtComponent<FssOrderTabsPageProp, FssOrderTabsPageState> {
    constructor(props: FssOrderTabsPageProp) {
        super(props);

        this.getFilterColumns = this.getFilterColumns.bind(this);
        this.getOrderTitle = this.getOrderTitle.bind(this);
        this.getOrderPageType = this.getOrderPageType.bind(this);
    }

    getMarketingOrderFilter(): Map<string, any> {
        const userFilter: Map<string, any> = new Map<string, any>();
        if (this.props.match.params.objectId && this.props.match.params.objectId !== '0') {
            userFilter.set("marketingEventId", this.props.match.params.objectId);
        }
        return userFilter;
    }

    getUserOrderFilter(): Map<string, any> {
        const userFilter: Map<string, any> = new Map<string, any>();
        if (this.props.match.params.objectId && this.props.match.params.objectId !== '0') {
            userFilter.set("orderUserId", this.props.match.params.objectId);
        }
        return userFilter;
    }

    getExceptionOrderFilter(): Map<string, any> {
        const exceptionFilter: Map<string, any> = new Map<string, any>();
        exceptionFilter.set("orderStatus", [EOrderStatus.CANCELED]);
        exceptionFilter.set("paymentStatus", [EPaymentStatus.PAID]);
        return exceptionFilter;
    }

    getWaitShippingOrderFilter(): Map<string, any> {
        const waitShippingFilter: Map<string, any> = new Map<string, any>();
        waitShippingFilter.set("orderStatus", [EOrderStatus.APPROVED, EOrderStatus.PICKING, EOrderStatus.PACKAGING]);
        return waitShippingFilter;
    }

    getShippedOrderFilter(): Map<string, any> {
        const shippedFilter: Map<string, any> = new Map<string, any>();
        shippedFilter.set("orderStatus", [EOrderStatus.SHIPPED]);
        return shippedFilter;
    }

    getWaitApproveOrderFilter(): Map<string, any> {
        const waitApproveFilter: Map<string, any> = new Map<string, any>();
        waitApproveFilter.set("orderStatus", [EOrderStatus.CREATED]);
        return waitApproveFilter;
    }

    getWaitPayOrderFilter(): Map<string, any> {
        const waitPayFilter: Map<string, any> = new Map<string, any>();
        let paymentStatus: Array<EPaymentStatus> = new Array<EPaymentStatus>();
        paymentStatus.push(EPaymentStatus.WAIT);
        paymentStatus.push(EPaymentStatus.PARTIAL_PAID);
        let orderStatus: Array<EOrderStatus> = new Array<EOrderStatus>();
        orderStatus.push(EOrderStatus.CREATED);
        orderStatus.push(EOrderStatus.APPROVED);
        orderStatus.push(EOrderStatus.PICKING);
        orderStatus.push(EOrderStatus.PACKAGING);
        orderStatus.push(EOrderStatus.SHIPPED);
        orderStatus.push(EOrderStatus.DELIVERED);
        waitPayFilter.set("paymentStatus", paymentStatus);
        waitPayFilter.set("orderStatus", orderStatus);
        return waitPayFilter;
    }

    getFilterColumns(): Map<string, any> {
        let filterColumns: Map<string, any> = new Map<string, any>();
        if (this.props.match.params.orderType) {
            let orderType = this.props.match.params.orderType as EMenuitem;
            if (orderType === EMenuitem.WaitApproveOrderList) {
                filterColumns = this.getWaitApproveOrderFilter();
            } else if (orderType === EMenuitem.WaitPayOrderList) {
                filterColumns = this.getWaitPayOrderFilter();
            } else if (orderType === EMenuitem.ShippedOrderList) {
                filterColumns = this.getShippedOrderFilter();
            } else if (orderType === EMenuitem.WaitShippingOrderList) {
                filterColumns = this.getWaitShippingOrderFilter();
            } else if (orderType === EMenuitem.ExceptionOrderList) {
                filterColumns = this.getExceptionOrderFilter();
            } else if (orderType === EMenuitem.UserOrderList) {
                filterColumns = this.getUserOrderFilter();
            } else if (orderType === EMenuitem.MarketingOrderList) {
                filterColumns = this.getMarketingOrderFilter();
            } else if (orderType === EMenuitem.OrderList) {

            }
        }
        return filterColumns;
    }

    getOrderTitle(): string {
        let orderTitle = "订单列表";
        if (this.props.match.params.orderType) {
            let orderType = this.props.match.params.orderType as EMenuitem;
            if (orderType === EMenuitem.WaitApproveOrderList) {
                orderTitle = "待审核订单列表";
            } else if (orderType === EMenuitem.WaitPayOrderList) {
                orderTitle = "待支付订单列表";
            } else if (orderType === EMenuitem.ShippedOrderList) {
                orderTitle = "待收货订单列表";
            } else if (orderType === EMenuitem.WaitShippingOrderList) {
                orderTitle = "待发货订单列表";
            } else if (orderType === EMenuitem.ExceptionOrderList) {
                orderTitle = "异常订单列表";
            } else if (orderType === EMenuitem.UserOrderList) {
                orderTitle = "用户订单列表";
            } else if (orderType === EMenuitem.MarketingOrderList) {
                orderTitle = "活动营销订单列表";
            } else if (orderType === EMenuitem.OrderList) {
                orderTitle = "全部订单列表";
            }
        }
        return orderTitle;
    }

    getOrderPageType(): EOrderPageType| undefined {
        let orderPageType: EOrderPageType| undefined = undefined;
        if (this.props.match.params.orderType) {
            let orderType = this.props.match.params.orderType as EMenuitem;
            if (orderType === EMenuitem.WaitApproveOrderList) {
                orderPageType = EOrderPageType.WAIT_APPROVE_ORDER;
            } else if (orderType === EMenuitem.WaitPayOrderList) {
                orderPageType = EOrderPageType.WAIT_PAY_ORDER;
            } else if (orderType === EMenuitem.ShippedOrderList) {
                orderPageType = EOrderPageType.SHIPPED_ORDER;
            } else if (orderType === EMenuitem.WaitShippingOrderList) {
                orderPageType = EOrderPageType.WAIT_SHIPPING_ORDER;
            } else if (orderType === EMenuitem.ExceptionOrderList) {
                orderPageType = EOrderPageType.EXCEPTION_ORDER;
            } else if (orderType === EMenuitem.UserOrderList) {
                orderPageType = EOrderPageType.USER_ORDER;
            } else if (orderType === EMenuitem.MarketingOrderList) {
                orderPageType = EOrderPageType.MARKETING_ORDER;
            } else if (orderType === EMenuitem.OrderList) {
                orderPageType = EOrderPageType.TOTAL_ORDER;
            }
        }
        return orderPageType;
    }

    render() {
        const orderPageType = this.getOrderPageType();
        const orderTitle = this.getOrderTitle();
        const filterColumns = this.getFilterColumns();
        return <FssOrderListPage key={this.props.location.pathname}
                                 history={this.props.history}
                                 location={this.props.location}
                                 match={this.props.match}
                                 orderPageType={orderPageType}
                                 title={orderTitle}
                                 filterColumns={filterColumns}
                                 isProductDetailPage={false}/>;
    }
}
