import * as React from "react";
import {ChangeEvent} from "react";
import FtGrid from "../../ui/FtGrid";
import FtRow from "../../ui/FtRow";
import JtTextField from "../../ui/JtTextField";
import FtPanel from "../../ui/FtPanel";
import FtUtil from "../../util/FtUtil";
import FtColorInput from "../../ui/FtColorInput";
import {i18n} from "../../util/I18n";
import FssComponent from "../../model/FssComponent";
import {FssAbstractCompData} from "../../page/component/data/FssAbstractCompData";

interface FssCompHeaderEditorProp {
    component: FssComponent;
    onChangeComponent?: (component: FssComponent) => void;
}

export class FssCompHeaderEditorState {
}

export default class FssCompHeaderEditor extends React.Component<FssCompHeaderEditorProp, FssCompHeaderEditorState> {

    constructor(props: any) {
        super(props);
        this.state = {};
        this.onChangeComponent = this.onChangeComponent.bind(this);
    }

    componentDidMount(): void {
    }

    render() {
        const component = this.props.component;
        const abstractCompData = component.data as FssAbstractCompData;
        return <div key={this.props.component.id}>
            <FtPanel panelHeader={i18n("组件") + component.componentName} marginVertical={8}>
                <FtGrid>
                    <FtRow cellWidthM={"3,2,3"}>
                        <JtTextField id={"componentName"} label={i18n("组件名称")} value={component.componentName}
                                     style={{width: "100%"}} onChange={this.onChangeComponent}/>
                        <JtTextField id={"componentType"} label={i18n("类型")} value={component.componentType}
                                     disabled={true}
                                     style={{width: "100%"}}/>
                        <JtTextField id={"createDatetimeText"} label={i18n("创建时间")} value={component.createDatetimeText}
                                     disabled={true} style={{width: "100%"}}/>
                    </FtRow>
                </FtGrid>
            </FtPanel>
            <FtPanel panelHeader={i18n("外观")} marginVertical={8}>
                <FtGrid>
                    <FtRow cellVerticalSpace={8}>
                        <FtColorInput label={i18n("背景颜色")} backgroundColor={abstractCompData.backgroundColor}
                                      onChangeColor={(color?: string) => {
                                          FtUtil.setProperty(this.props.component.data, "backgroundColor", color);
                                          if (this.props.onChangeComponent) {
                                              this.props.onChangeComponent(this.props.component);
                                          }
                                      }}/>
                        <FtColorInput label={i18n("前景颜色")} backgroundColor={abstractCompData.color}
                                      onChangeColor={(color?: string) => {
                                          FtUtil.setProperty(this.props.component.data, "color", color);
                                          if (this.props.onChangeComponent) {
                                              this.props.onChangeComponent(this.props.component);
                                          }
                                      }}/>
                    </FtRow>
                    <FtRow cellWidthM={"1,1"}>
                        <FtPanel title={i18n("间距")}>
                            <FtRow cellWidthM={"1,1,1,1"}>
                                <JtTextField id="data.marginTop" label={i18n("上")} value={abstractCompData.marginTop}
                                             variant="outlined" onChange={this.onChangeComponent}/>
                                <JtTextField id="data.marginBottom" label={i18n("下")}
                                             value={abstractCompData.marginBottom}
                                             variant="outlined" onChange={this.onChangeComponent}/>
                                <JtTextField id="data.marginLeft" label={i18n("左")} value={abstractCompData.marginLeft}
                                             variant="outlined" onChange={this.onChangeComponent}/>
                                <JtTextField id="data.marginRight" label={i18n("右")}
                                             value={abstractCompData.marginRight}
                                             variant="outlined" onChange={this.onChangeComponent}/>
                            </FtRow>
                        </FtPanel>
                        <FtPanel title={i18n("圆角")}>
                            <FtRow cellWidthM={"1,1,1,1"}>
                                <JtTextField id="data.borderTopLeftRadius" label={i18n("左上")}
                                             value={abstractCompData.borderTopLeftRadius}
                                             variant="outlined" onChange={this.onChangeComponent}/>
                                <JtTextField id="data.borderTopRightRadius" label={i18n("右上")}
                                             value={abstractCompData.borderTopRightRadius}
                                             variant="outlined" onChange={this.onChangeComponent}/>
                                <JtTextField id="data.borderBottomLeftRadius" label={i18n("左下")}
                                             value={abstractCompData.borderBottomLeftRadius}
                                             variant="outlined" onChange={this.onChangeComponent}/>
                                <JtTextField id="data.borderBottomRightRadius" label={i18n("右下")}
                                             value={abstractCompData.borderBottomRightRadius}
                                             variant="outlined" onChange={this.onChangeComponent}/>
                            </FtRow>
                        </FtPanel>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </div>;
    }

    private onChangeComponent(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        // if (event.target.id == "componentName") {
        //     this.props.component.componentName = event.target.value;
        // } else if (event.target.id.startsWith("data.")) {
        //     const abstractCompData = this.props.component.data as FssAbstractCompData;
        //     let abstractDataKey = event.target.id.substring("data.".length);
        //     // 动态添加属性
        //     lodash.assign(abstractCompData, {[abstractDataKey]: event.target.value});
        // } else {
        //     return;
        // }

        if (event.target.id.startsWith("data.")) {
            //如果是数字参数，则都需要转
            if (event.target.id.startsWith("data.margin") || event.target.id.startsWith("data.border")) {
                if (event.target.value.trim().length > 0)
                    FtUtil.setProperty(this.props.component.data, event.target.id.substring("data.".length), +event.target.value.trim());
                else
                    FtUtil.setProperty(this.props.component.data, event.target.id.substring("data.".length), "");
            } else
                FtUtil.setProperty(this.props.component.data, event.target.id.substring("data.".length), event.target.value.trim());
        } else
            FtUtil.setProperty(this.props.component, event.target.id, event.target.value.trim());

        if (this.props.onChangeComponent)
            this.props.onChangeComponent(this.props.component);
    }
}

