import * as React from "react";
import {CSSProperties, ReactNode} from "react";
import {InputAdornment, InputLabelProps, TextField} from "@material-ui/core";
import {BaseTextFieldProps} from "@material-ui/core/TextField";
import {OutlinedInputProps} from "@material-ui/core/OutlinedInput";
import {FilledInputProps} from "@material-ui/core/FilledInput";
import {InputProps as StandardInputProps} from "@material-ui/core/Input/Input";
import FtUtil from "../util/FtUtil";
import AppClientInfo from "../data/AppClientInfo";

interface FtTextFieldStringProps extends BaseTextFieldProps {
    helperText?: string;
    originalValue?: unknown;
    startAdornment?: ReactNode;
    endAdornment?: ReactNode;
    variant?: "standard" | "filled" | "outlined";
    onChangeValue?: (value?: string | undefined) => void;
    onKeyDownEnter?:()=>void;
    onBlur?: StandardInputProps['onBlur'] | FilledInputProps["onBlur"] | OutlinedInputProps["onBlur"];
    onFocus?: StandardInputProps['onFocus'] | FilledInputProps["onFocus"] | OutlinedInputProps["onFocus"];
    value?: string;
    inputStyle?: CSSProperties;
    InputProps?: any;
}

class FtTextFieldStringState {
    value: string="";
}

export default class FtTextFieldString extends React.Component<FtTextFieldStringProps, FtTextFieldStringState> {
    constructor(props: any) {
        super(props);
        this.state = {value: ""};
        this.onChange = this.onChange.bind(this);
        this.onChangeCapture = this.onChangeCapture.bind(this);
        this.onKeyDown=this.onKeyDown.bind(this);
    }

    static getDerivedStateFromProps(props: FtTextFieldStringProps, state: FtTextFieldStringState) {
        if (props.value != state.value) {
            return {value: props.value};
        }
        return state;
    }

    render() {
        const baseTextFieldProps: BaseTextFieldProps = this.props;
        let variant: any = "standard";
        if (this.props.variant)
            variant = this.props.variant;
        else
            variant=AppClientInfo.clientInfo.theme?.FtTextFieldString?.variant;
        let InputProps: any = {};
        if (this.props.startAdornment)
            InputProps.startAdornment = <InputAdornment position="start">{this.props.startAdornment}</InputAdornment>;
        if (this.props.endAdornment)
            InputProps.endAdornment = <InputAdornment position="end">{this.props.endAdornment}</InputAdornment>;
        if (this.props.InputProps) {
            InputProps = FtUtil.mergeObject(InputProps, this.props.InputProps);
        }
        let error: boolean | undefined = this.props.error;
        let helperText: string | undefined = this.props.helperText ? this.props.helperText : "";
        let inputLabelProps:InputLabelProps={ shrink: true };
        if(this.props.InputLabelProps)
            inputLabelProps=FtUtil.mergeObject(inputLabelProps,this.props.InputLabelProps);
        let style= AppClientInfo.clientInfo.theme?.FtTextFieldString?.style;
        if(this.props.style)
            style=FtUtil.mergeObject(style,this.props.style);
        let size:any=AppClientInfo.clientInfo.theme?.FtTextFieldString?.size;
        if(this.props.size)
            size=this.props.size;
        if (this.props.originalValue && this.props.value != this.props.originalValue)
            return <TextField {...baseTextFieldProps} value={this.state.value} error={error} helperText={helperText}
                              variant={"filled"} inputProps={{style: this.props.inputStyle}} InputLabelProps={inputLabelProps}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onKeyDown={this.onKeyDown} style={style} size={size}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
        else
            return <TextField {...baseTextFieldProps} value={this.state.value} error={error} helperText={helperText}
                              variant={variant} inputProps={{style: this.props.inputStyle}} InputLabelProps={inputLabelProps}
                              InputProps={InputProps} onChange={this.onChange} onChangeCapture={this.onChangeCapture}
                              onKeyDown={this.onKeyDown} style={style} size={size}
                              onBlur={this.props.onBlur} onFocus={this.props.onFocus}>
            </TextField>;
    }

    private onChange(event: any) {
        const value: string = event.target.value as string;
        if (this.props.onChangeValue)
            this.props.onChangeValue(value);

    }

    private onChangeCapture(event: any) {
        return true;
    }
    private onKeyDown(event:React.KeyboardEvent<HTMLDivElement>){
        if(event.keyCode==13||event.key=="Enter"){
            if(this.props.onKeyDownEnter)
                this.props.onKeyDownEnter();
        }
    }
}
