import * as React from "react";
import {ReactNode} from "react";
import FtPanel from "../../ui/FtPanel";
import FtRow from "../../ui/FtRow";
import FssCompHeaderEditor from "./FssCompHeaderEditor";
import JtTextField from "../../ui/JtTextField";
import FtGrid from "../../ui/FtGrid";
import lodash from "lodash";
import FtComboboxDynamic from "../../ui/FtComboboxDynamic";
import FtSnackbar from "../../ui/FtSnackbar";
import {Add} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {FormLabel} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import {i18n} from "../../util/I18n";
import FssComponent, {EComponentType} from "../../model/FssComponent";
import FssCategory from "../../model/FssCategory";
import FssCategoryGridCompData from "../../page/component/data/FssCategoryGridCompData";
import FssCategoryService from "../../service/FssCategoryService";
import FtPagedList from "../../model/FtPagedList";
import FssStoreCategory from "../../model/FssStoreCategory";
import FssStoreCategoryService from "../../service/FssStoreCategoryService";

interface FssCategoryGridCompEditorProp {
    type: EComponentType.STORE_CATEGORY_NAVIGATION | EComponentType.CATEGORY_NAVIGATION;
    component: FssComponent;
    onChangeComponent?: (component: FssComponent) => void;
    key: string;
    onChangeDraggableFlag?: (draggableFlag: boolean) => void;
}

export class FssCategoryGridCompEditorState {
    category: FssCategory | FssStoreCategory = new FssCategory();
    categoryList: Array<FssCategory | FssStoreCategory> = [];
    categoryOptionList: Array<FssCategory | FssStoreCategory> = [];
    dragElement: FssCategory | FssStoreCategory = new FssCategory();
    lock: boolean = true;
    openMessageBoxDialog: boolean = true;
    deleteCategory: FssCategory | FssStoreCategory = new FssCategory();
    loadingCategoryList: boolean = false;
}


export default class FssCategoryGridCompEditor extends React.Component<FssCategoryGridCompEditorProp, FssCategoryGridCompEditorState> {

    constructor(props: any) {
        super(props);

        let category: FssCategory | FssStoreCategory = new FssCategory();
        let dragElement: FssCategory | FssStoreCategory = new FssCategory();
        let deleteCategory: FssCategory | FssStoreCategory = new FssCategory();
        if (this.props.type == EComponentType.STORE_CATEGORY_NAVIGATION) {
            category = new FssStoreCategory();
            dragElement = new FssStoreCategory();
            deleteCategory = new FssStoreCategory();
        }
        this.state = {
            category: category,
            categoryList: [],
            categoryOptionList: [],
            dragElement: dragElement,
            lock: true,
            deleteCategory: deleteCategory,
            openMessageBoxDialog: false,
            loadingCategoryList: false
        };

        this.onChangeComponent = this.onChangeComponent.bind(this);
        this.onChangeSelectOptions = this.onChangeSelectOptions.bind(this);
        this.onChangeCategoryInput = this.onChangeCategoryInput.bind(this);
        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.getMessageBoxDialog = this.getMessageBoxDialog.bind(this);
        this.onChangeDraggableFlag = this.onChangeDraggableFlag.bind(this);
    }

    componentDidMount(): void {
        const component = this.props.component;
        const categoryGridCompData = component.data as FssCategoryGridCompData;
        if (categoryGridCompData.categoryIdArray && categoryGridCompData.categoryIdArray.length > 0) {
            if (this.props.type == EComponentType.CATEGORY_NAVIGATION) {
                FssCategoryService.getCategoryListByIds(categoryGridCompData.categoryIdArray, (categoryList: Array<FssCategory>) => {
                    this.setState({categoryOptionList: categoryList});
                });
            } else if (this.props.type == EComponentType.STORE_CATEGORY_NAVIGATION) {
                FssStoreCategoryService.getCategoryListByIds(categoryGridCompData.categoryIdArray, (categoryList: Array<FssStoreCategory>) => {
                    this.setState({categoryOptionList: categoryList});
                });
            }
        }
        document.addEventListener('dragover', this.preventDefault);
        document.addEventListener('drop', this.preventDefault);
    }

    componentWillUnmount() {
        document.removeEventListener('dragover', this.preventDefault);
        document.removeEventListener('drop', this.preventDefault);
    }

    preventDefault = (e: Event) => {
        e.preventDefault();
    }

    onDragStart = (category: FssCategory | FssStoreCategory) => {
        this.setState({dragElement: category});
        this.onChangeDraggableFlag(false);
    }

    onDragEnter = (category: FssCategory | FssStoreCategory) => {
        let categoryId = category.id;
        if (categoryId !== this.state.dragElement.id) {
            const oldDragIndex = lodash.findIndex(this.state.categoryOptionList, (item: { id: string; }) => item.id === this.state.dragElement.id);
            const oldEnterIndex = lodash.findIndex(this.state.categoryOptionList, (item: { id: string; }) => item.id === category.id);
            if (oldDragIndex > oldEnterIndex) {
                const newDataArray = this.state.categoryOptionList.filter(item => item.id !== this.state.dragElement.id);
                const insertIndex = lodash.findIndex(newDataArray, (item: { id: string; }) => item.id === category.id);
                newDataArray.splice(insertIndex, 0, this.state.dragElement);
                this.onChangeSelectOptions(newDataArray);
            } else {
                const newDataArray = this.state.categoryOptionList.filter(item => item.id !== this.state.dragElement.id);
                const insertIndex = lodash.findIndex(newDataArray, (item: { id: string; }) => item.id === category.id) + 1;
                newDataArray.splice(insertIndex, 0, this.state.dragElement);
                this.onChangeSelectOptions(newDataArray);
            }
        }
    }
    onDragLeave = (category: FssCategory | FssStoreCategory) => {
        if (category.id !== this.state.dragElement.id) {
            if (this.state.lock && category.id === (this.state.categoryOptionList[this.state.categoryOptionList.length - 1]).id) {
                const newDataArray = this.state.categoryOptionList.filter(item => item.id !== this.state.dragElement.id);
                newDataArray.push(this.state.dragElement);
                this.setState({lock: false,}, () => {
                    this.onChangeSelectOptions(newDataArray);
                });
            } else {
                this.setState({lock: true,});
            }
        }
    }

    onChangeDraggableFlag(flag: boolean) {
        if (this.props.onChangeDraggableFlag) {
            this.props.onChangeDraggableFlag(flag);
        }
    }

    onChangeCategoryInput(keyword: string) {
        if (this.props.type == EComponentType.CATEGORY_NAVIGATION) {
            FssCategoryService.getStoreCategoryList(keyword, 0, 5, 'NORMAL', (result: FtPagedList<FssCategory>) => {
                this.setState({categoryList: result.content});
            });
        } else if (this.props.type == EComponentType.STORE_CATEGORY_NAVIGATION) {
            FssStoreCategoryService.getStoreCategoryList(keyword, 5, (categoryList: Array<FssStoreCategory>) => {
                this.setState({categoryList: categoryList});
            });
        }
    }

    onChangeCategory(item: any) {
        this.setState({category: item});
        this.forceUpdate();
    }

    onChangeSelectOptions(categoryOptionList: Array<FssCategory | FssStoreCategory>) {
        this.setState({categoryOptionList: categoryOptionList});
        let categoryIdArray = lodash.map(categoryOptionList, 'id');
        const categoryGridCompData = this.props.component.data as FssCategoryGridCompData;
        categoryGridCompData.categoryIdArray = categoryIdArray;
        if (this.props.onChangeComponent)
            this.props.onChangeComponent(this.props.component);
    }

    getMessageBoxDialog(): ReactNode {
        let deleteCategory = this.state.deleteCategory;
        let categoryOptionList = this.state.categoryOptionList;

        return <Dialog open={this.state.openMessageBoxDialog} onClose={(e) => {
            this.setState({openMessageBoxDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("确认")}</DialogTitle>
            <DialogContent>
                {i18n("确定要删除『{0}』吗?", deleteCategory.categoryName)}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                }}>{i18n("取消")}</Button>
                <Button variant="outlined" color="primary" onClick={(e) => {
                    lodash.remove(categoryOptionList, (item: { id: string; }) => item.id === deleteCategory.id);
                    this.setState({openMessageBoxDialog: false});
                    this.onChangeSelectOptions(categoryOptionList);
                }}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }

    render() {
        const component = this.props.component;
        const categoryGridCompData = component.data as FssCategoryGridCompData;

        return <div key={this.props.key}>
            <FssCompHeaderEditor component={this.props.component} onChangeComponent={this.onChangeComponent}/>
            <FtRow cellWidthM={"1,1"} cellHorizontalAlign={"left"}>
                <FtPanel panelHeader={i18n("手机版")}>
                    <JtTextField id="mobileColumnAmount" type={"integer"} label={i18n("每行显示数量")}
                                 value={categoryGridCompData.mobileColumnAmount}
                                 onChange={(event) => {
                                     categoryGridCompData.mobileColumnAmount = +event.target.value;
                                     this.onChangeComponent();
                                 }} style={{width: "50%", margin: 8}}>
                    </JtTextField>
                </FtPanel>
                <FtPanel panelHeader={i18n("电脑版")}>
                    <JtTextField id="pcColumnAmount" type={"integer"} label={i18n("每行显示数量")}
                                 value={categoryGridCompData.pcColumnAmount}
                                 onChange={(event) => {
                                     categoryGridCompData.pcColumnAmount = +event.target.value;
                                     this.onChangeComponent();
                                 }} style={{width: "50%", margin: 8}}>
                    </JtTextField>
                </FtPanel>
            </FtRow>
            <FtPanel marginVertical={8}>
                <FtRow cellWidthM={"3,1,2"} style={{alignItems: "flex-end"}}>
                    <FtComboboxDynamic optionItems={this.state.categoryList}
                                       labelPropertyName={"categoryName"}
                                       valuePropertyName={"categoryId"}
                                       onSelectItem={this.onChangeCategory}
                                       onChangeInput={this.onChangeCategoryInput}
                                       loading={this.state.loadingCategoryList}
                                       style={{minWidth: 200}}/>
                    <Button onClick={() => {
                        let categoryOptionList = this.state.categoryOptionList;
                        if (this.state.category.id.length > 0) {
                            const checkExist = lodash.findIndex(categoryOptionList, (item: { id: string; }) => item.id === this.state.category.id);
                            if (checkExist > -1)
                                FtSnackbar.showText(i18n("此分类已经添加过了"));
                            else {
                                categoryOptionList.push(this.state.category);
                                this.onChangeSelectOptions(categoryOptionList);
                            }
                        }
                    }} color={"primary"} variant="outlined"><Add/>{i18n("添加")}</Button>
                    <div style={{textAlign: "left"}}>
                        <Button> {i18n("文字大小")} </Button>
                        <JtTextField select value={categoryGridCompData.fontSize}
                                     onChange={(event: any) => {
                                         categoryGridCompData.fontSize = event.target.value;
                                         this.onChangeComponent();
                                     }}>
                            <MenuItem value={"60%"}>60%</MenuItem>
                            <MenuItem value={"80%"}>80%</MenuItem>
                            <MenuItem value={"100%"}>100%</MenuItem>
                            <MenuItem value={"120%"}>120%</MenuItem>
                            <MenuItem value={"140%"}>140%</MenuItem>
                        </JtTextField>
                    </div>
                </FtRow>
                {this.state.categoryOptionList.length > 0 &&
                <FormLabel component="legend" style={{width: "100%"}}>已选分类</FormLabel>}
                <FtRow cellWidthM={"1,1,1,1,1"} style={{alignItems: "end"}}>
                    {this.state.categoryOptionList.map((category: FssCategory | FssStoreCategory) => {
                        return <div key={category.id}
                                    draggable={true}
                                    onDragStart={() => {
                                        this.onDragStart(category)
                                    }}
                                    onDragEnd={(e) => {
                                        this.onChangeDraggableFlag(true);
                                        e.preventDefault();
                                    }}
                                    onDragEnter={() => {
                                        this.onDragEnter(category)
                                    }}
                                    onDragLeave={() => {
                                        this.onDragLeave(category)
                                    }}>
                            <FtGrid style={{textAlign: "center", position: "relative"}}>
                                <IconButton style={{padding: 0, position: "absolute", top: 5, right: 5}}
                                            aria-label="Delete" onClick={() => {
                                    this.setState({openMessageBoxDialog: true, deleteCategory: category});
                                    this.forceUpdate();
                                }}><DeleteIcon/>
                                </IconButton>
                                <img alt="complex" src={category.coverImageUrl}
                                     style={{maxWidth: "100%", pointerEvents: "none"}}/>
                                <div style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontSize: categoryGridCompData.fontSize,
                                    display: "-webkit-box",
                                    "WebkitLineClamp": 1,
                                    "WebkitBoxOrient": "vertical",
                                }}>
                                    {category.categoryName}
                                </div>
                            </FtGrid>
                        </div>;
                    })}
                </FtRow>
            </FtPanel>
            {this.getMessageBoxDialog()}
        </div>
    }

    onChangeComponent() {
        if (this.props.onChangeComponent)
            this.props.onChangeComponent(this.props.component);
    }
}
