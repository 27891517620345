import * as React from "react";
import {CSSProperties, HTMLAttributes, ReactNode} from "react";
import AppClientInfo from "../data/AppClientInfo";
import FtUtil from "../util/FtUtil";

export interface FtRowProp extends HTMLAttributes<HTMLDivElement> {
    justifyContent?: "space-between" | "flex-start" | "flex-end" | "center"
    cellHorizontalAlign?: "justify" | "left" | "center" | "right";
    cellVerticalAlign?: "top" | "center" | "bottom";
    cellWidthM?: string;
    cellWidthS?: string;
    cellWidthL?: string;
    cellHorizontalSpace?: 0 | 2 | 4 | 8 | 12 | 16 | 32;
    cellVerticalSpace?: 0 | 2 | 4 | 8 | 12 | 16 | 32;
    maxRowWidth?: "s" | "m" | "l"
    minRowWidth?: "s" | "m" | "l"
}

export default class FtRow extends React.Component<FtRowProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        let styles: CSSProperties = {
            display: "flex",
            justifyContent: this.props.justifyContent ? this.props.justifyContent : "flex-start",
            alignItems: "center",
            flexWrap: "wrap"
        };

        if (this.props.maxRowWidth && this.props.maxRowWidth == "s")
            styles.maxWidth = 600;
        else if (this.props.maxRowWidth && this.props.maxRowWidth == "m")
            styles.maxWidth = 900;
        else if (this.props.maxRowWidth && this.props.maxRowWidth == "l")
            styles.maxWidth = 1200;
        if (this.props.minRowWidth && this.props.minRowWidth == "s")
            styles.minWidth = 600;
        else if (this.props.minRowWidth && this.props.minRowWidth == "m")
            styles.minWidth = 900;
        else if (this.props.minRowWidth && this.props.minRowWidth == "l")
            styles.minWidth = 1200;
        //处理垂直对齐
        if (this.props.cellVerticalAlign)
            styles.alignItems = this.props.cellVerticalAlign;
        // 处理宽度
        let cellWidthArray: Array<string> = [];
        let cellWidth: string | undefined = this.props.cellWidthL;
        if (this.props.cellWidthM)
            cellWidth = this.props.cellWidthM;
        if (this.props.cellWidthS)
            cellWidth = this.props.cellWidthS;
        let defaultGutter:number=4;
        if (AppClientInfo.clientInfo && AppClientInfo.clientInfo.viewMode) {
            if (AppClientInfo.clientInfo.viewMode == "mobile") {
                defaultGutter=4;
                cellWidth = this.props.cellWidthS ? this.props.cellWidthS : (this.props.cellWidthM ? this.props.cellWidthM : (this.props.cellWidthL ? this.props.cellWidthL : ""));
            }else if (AppClientInfo.clientInfo.viewMode == "pad") {
                defaultGutter=4;
                cellWidth = this.props.cellWidthM ? this.props.cellWidthM : (this.props.cellWidthS ? this.props.cellWidthS : (this.props.cellWidthL ? this.props.cellWidthL : ""));
            }else if (AppClientInfo.clientInfo.viewMode == "pc") {
                defaultGutter=8;
                cellWidth = this.props.cellWidthL ? this.props.cellWidthL : (this.props.cellWidthM ? this.props.cellWidthM : (this.props.cellWidthS ? this.props.cellWidthS : ""));
            }
        }
        if (cellWidth)
            cellWidthArray = FtUtil.stringToArray(cellWidth);
        const children: Array<ReactNode> = React.Children.toArray(this.props.children);
        const elements: Array<ReactNode> = [];
        let totalColumns = 0;
        //求总列数
        cellWidthArray.forEach((value: string, index: number) => {
            totalColumns += +value;
        });
        children.forEach((child: ReactNode, index: number) => {
            const childStyle: CSSProperties = {boxSizing: "border-box"};

            //处理空隙
            childStyle.paddingLeft = this.props.cellHorizontalSpace == null ? defaultGutter / 2 : this.props.cellHorizontalSpace / 2;
            childStyle.paddingRight = this.props.cellHorizontalSpace == null ? defaultGutter / 2 : this.props.cellHorizontalSpace / 2;
            childStyle.paddingTop = this.props.cellVerticalSpace == null ? defaultGutter / 2 : this.props.cellVerticalSpace / 2;
            childStyle.paddingBottom = this.props.cellVerticalSpace == null ? defaultGutter / 2 : this.props.cellVerticalSpace / 2;
            //如果设置了列宽分布
            if (cellWidthArray != null && cellWidthArray.length > 0) {
                const offset: number = index % cellWidthArray.length;
                //处理水平对齐
                if (this.props.cellHorizontalAlign == "justify") {
                    if (offset == 0)
                        childStyle.textAlign = "left";
                    else if (offset == cellWidthArray.length - 1)
                        childStyle.textAlign = "right";
                } else
                    childStyle.textAlign = this.props.cellHorizontalAlign;
                //处理宽度
                childStyle.width = +cellWidthArray[offset] * 100 / totalColumns + "%";
                //因为如果去除了左边padding或者右边padding，就会导致第一个和最后一个单元格宽度更大，所以要重新计算第一个和最后一个单元格的宽度
                if (offset == 0) {
                    childStyle.width = "calc(" + childStyle.width + " - " + (childStyle.paddingLeft) + "px)";
                    // childStyle.width =childStyle.width;
                        childStyle.paddingLeft = 0;
                }
                if (offset == cellWidthArray.length - 1) {
                    childStyle.width = "calc(" + childStyle.width + " - " + (childStyle.paddingRight) + "px)";
                    // childStyle.width =childStyle.width;
                    childStyle.paddingRight = 0;
                }
            }
            if (index == 0) {
                childStyle.paddingLeft = 0;
            }
            if (index == children.length-1) {
                childStyle.paddingRight = 0;
            }

            elements.push(<div style={childStyle} key={index}>{child}</div>);
        });

        if (this.props.style)
            styles = FtUtil.mergeObject(styles, this.props.style);
        return <div style={styles} onClick={this.props.onClick}  id={this.props.id} className={this.props.className}>
            {elements}
        </div>;
    }
}
