import * as React from "react";
import FtToast from "../ui/FtToast";
import {Button, Divider, Typography} from "@material-ui/core";
import {ChevronLeft, Print} from "@material-ui/icons";
import {RouteComponentProps} from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {i18n} from "../util/I18n";
import FtRow from "../ui/FtRow";
import {grey} from "@material-ui/core/colors";
import FssShippedSumOrderPrint from "./FssShippedSumOrderPrint";
import FtToolbar from "../ui/FtToolbar";
import FtGrid from "../ui/FtGrid";
import FtPanel from "../ui/FtPanel";
import FtUtil from "../util/FtUtil";
import FssOrderItem from "../model/FssOrderItem";
import FssMarketingEventShipSumOrder from "../model/FssMarketingEventShipSumOrder";
import FssMarketingEventService from "../service/FssMarketingEventService";

export interface FssShippedSumOrderPageProp extends RouteComponentProps<{ id: string }> {
}

class FssShippedSumOrderPageState extends FtComponentState {
    order: FssMarketingEventShipSumOrder = new FssMarketingEventShipSumOrder();
}


export default class FssShippedSumOrderPage extends FtComponent<FssShippedSumOrderPageProp, FssShippedSumOrderPageState> {
    print!: FssShippedSumOrderPrint | null;

    constructor(props: any) {
        super(props);

        this.state = {order: new FssMarketingEventShipSumOrder()};
        this.onCancel = this.onCancel.bind(this);
        this.onPrintOrderList58 = this.onPrintOrderList58.bind(this);
    }

    componentDidMount(): void {
        this.loadData();
    }

    loadData(): void {
        if (this.props.match.params.id && +this.props.match.params.id.length > 1) {
            FtToast.showToastLoading("loading");
            FssMarketingEventService.getShippedSumOrder(this.props.match.params.id,
                (result: FssMarketingEventShipSumOrder) => {
                    FtToast.closeToast();
                    this.setState({order: result});
                });
        }
    }

    onCancel() {
        this.props.history.goBack();
    }

    render() {
        let total: number = 0;
        if (this.state.order != null && this.state.order.orderItemList != null && this.state.order.orderItemList.length > 0) {
            this.state.order.orderItemList.forEach((item => {
                total += item.price*item.quantity;
            }))
        }
        return <div style={{position: "relative"}}>
            <FtToolbar leftNode={<Button onClick={this.onCancel}><ChevronLeft/>{i18n("返回")}</Button>}
                       centerNode={<Typography variant="h6">{i18n("出货总单")}</Typography>}/>
            <div><FtPanel>
                <FtGrid>
                    <FtRow style={{marginLeft: 20}} cellWidthS={"1,1"} cellVerticalSpace={8}
                           cellHorizontalAlign={"right"}>
                        <div></div>
                        <Button variant="contained" onClick={this.onPrintOrderList58}><Print/>打印</Button>
                    </FtRow>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={8}
                           cellHorizontalAlign={"left"}>
                        <Typography style={{wordBreak:"break-all"}}>{"发货时间：" + this.state.order.scheduleDatetime}</Typography>
                        <Typography style={{wordBreak:"break-all"}}>{"团购标题：" + this.state.order.marketingEventName}</Typography>
                        <FtRow cellWidthS={"1,1"} cellVerticalSpace={8}>
                        <Typography style={{wordBreak:"break-all"}}>{"发货总量：" + this.state.order.totalQuantity}</Typography>
                        <Typography style={{wordBreak:"break-all"}}>{"总金额：" +  FtUtil.toCurrencyText(total)}</Typography>
                        </FtRow>

                    </FtRow>
                    <FtRow cellWidthS={"1,1,1"} style={{backgroundColor: grey[400]}}>
                        <Typography>{"数量"}</Typography>
                        <Typography>{"产品名称"}</Typography>
                        <Typography>{"单价"}</Typography>
                    </FtRow>

                    {this.state.order.orderItemList.map((item: FssOrderItem, index) => {
                        return <div><FtRow cellWidthS={"1,1,1"}
                                      style={{backgroundColor:"white"}}>
                                    <Typography>{item.quantity}</Typography>
                                    <Typography style={{wordBreak:"break-all"}} dangerouslySetInnerHTML={{__html:item.productName}}></Typography>
                                    <Typography>{FtUtil.toCurrencyText(item.price)}</Typography>
                                </FtRow>
                                <Divider/>
                        </div>
                    })}
                    <FssShippedSumOrderPrint ref={(element) => this.print = element} order={this.state.order}/>
                </FtGrid>
            </FtPanel>
                <Divider></Divider>
            </div>
        </div>;
    }

    onPrintOrderList58() {
        if (this.print) {
            window.setTimeout(() => {
                if (this.print)
                    this.print.print();
            }, 500);
        }
    }

}
