import * as React from "react";
import {ReactNode} from "react";
import FtPanel from "../../ui/FtPanel";
import FssCompHeaderEditor from "./FssCompHeaderEditor";
import {Card, CardMedia, Typography} from "@material-ui/core";
import lodash from "lodash";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import {ZoomInOutlined} from "@material-ui/icons";
import FtMediaViewerDialog from "../../ui/FtMediaViewerDialog";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";
import {i18n} from "../../util/I18n";
import FssComponent from "../../model/FssComponent";
import FssUserMediaSelectDialog from "../../userMedia/FssUserMediaSelectDialog";
import FssLogoBannerCompData from "../../page/component/data/FssLogoBannerCompData";
import FssUserMedia from "../../model/FssUserMedia";


interface FssLogoBannerCompEditorProp {
    component: FssComponent;
    onChangeComponent?:(component:FssComponent)=>void;
}

export class FssLogoBannerCompEditorState {
    openMessageBoxDialog: boolean = false;
    removeImageId: string = "";
    removeImageType: string = "";
}


export default class FssLogoBannerCompEditor extends React.Component<FssLogoBannerCompEditorProp, FssLogoBannerCompEditorState> {
    jtUserMediaSelectDialog!: FssUserMediaSelectDialog;
    constructor(props: any) {
        super(props);
        this.state = {openMessageBoxDialog: false, removeImageId: "", removeImageType: ""};
        this.onChangeComponent=this.onChangeComponent.bind(this);
        this.onClickImage=this.onClickImage.bind(this);
        this.onClickRemove=this.onClickRemove.bind(this);
        this.onClickZoomOut=this.onClickZoomOut.bind(this);
    }

    componentDidMount(): void {

    }

    render() {
        const component=this.props.component;
        const logoBannerCompData = component.data as FssLogoBannerCompData;
        return <div key={component.id}>
            <FssCompHeaderEditor component={this.props.component} onChangeComponent={this.onChangeComponent}>
            </FssCompHeaderEditor>
            <FtPanel marginVertical={8} style={{padding: 8}} panelHeader={""}>
                <Typography variant="subtitle2">LOGO{i18n("图片")}</Typography>
                <Card style={{backgroundColor: "#EEEEEE", position: "relative", width: 150}}>
                    {logoBannerCompData.logoImageUrl&&logoBannerCompData.logoImageUrl.length>0&&
                    <IconButton style={{position: "absolute", left: 3, top: 3, backgroundColor: "#EEEEEE", color: "red"}}
                                onClick={(event)=>{
                                    if(logoBannerCompData.logoImageUrl)
                                        this.onClickZoomOut(event, logoBannerCompData.logoImageUrl);
                                }}>
                        <ZoomInOutlined/>
                    </IconButton>}
                    {logoBannerCompData.logoImageUrl&&logoBannerCompData.logoImageUrl.length>0&&
                    <IconButton style={{position: "absolute", right: 3, top: 3, backgroundColor: "#EEEEEE", color: "red"}}
                        onClick={()=>{this.setState({openMessageBoxDialog: true, removeImageId: "logoImageUrl", removeImageType: i18n("LOGO图片")})}}>
                        <DeleteIcon/>
                    </IconButton>}
                    <CardMedia style={{height: 150}} image={logoBannerCompData.logoImageUrl} onClick={()=>{this.onClickImage("logoImageUrl")}}/>
                </Card>
                <Typography variant="subtitle2">{i18n("背景图片")}</Typography>
                <Card style={{backgroundColor: "#EEEEEE", position: "relative"}}>
                    {logoBannerCompData.backgroundImageUrl&&logoBannerCompData.backgroundImageUrl.length>0&&
                    <IconButton style={{position: "absolute", left: 10, top: 10, backgroundColor: "#EEEEEE", color: "red"}}
                                onClick={(event)=>{
                                    if(logoBannerCompData.backgroundImageUrl)
                                        this.onClickZoomOut(event, logoBannerCompData.backgroundImageUrl);
                                }}>
                        <ZoomInOutlined/>
                    </IconButton>}
                    {logoBannerCompData.backgroundImageUrl&&logoBannerCompData.backgroundImageUrl.length>0&&
                    <IconButton style={{position: "absolute", right: 10, top: 10, backgroundColor: "#EEEEEE", color: "red"}}
                        onClick={()=>{this.setState({openMessageBoxDialog: true, removeImageId: "backgroundImageUrl", removeImageType: i18n("背景图片")})}}>
                        <DeleteIcon/>
                    </IconButton>}
                    <CardMedia style={{height: 200}} image={logoBannerCompData.backgroundImageUrl} onClick={()=>{this.onClickImage("backgroundImageUrl")}}/>
                </Card>
            </FtPanel>
            <FssUserMediaSelectDialog ref={(element: any) => {
                this.jtUserMediaSelectDialog = element
            }} />
            {this.getMessageBoxDialog()}
        </div>
    }

    onChangeComponent(){
        if(this.props.onChangeComponent)
            this.props.onChangeComponent(this.props.component);
    }

    onClickImage(id?: string) {
        this.jtUserMediaSelectDialog.show("single", (userMediaList: Array<FssUserMedia> | null) => {
            if (userMediaList != null && userMediaList.length > 0 && id) {
                const logoBannerCompData = this.props.component.data as FssLogoBannerCompData;
                lodash.assign(logoBannerCompData, {[id]: userMediaList[0].mediaUrl});
                this.onChangeComponent();
            }
        });
    }

    onClickRemove(){
        const logoBannerCompData = this.props.component.data as FssLogoBannerCompData;
        lodash.assign(logoBannerCompData, {[this.state.removeImageId]: ""});
        this.onChangeComponent();
    }

    onClickZoomOut(event: any, imageUrl: string) {
        event.stopPropagation();
        if (imageUrl && imageUrl.length > 0) {
            FtMediaViewerDialog.FtMediaViewerDialog.show(imageUrl);
        }
    }

    getMessageBoxDialog(): ReactNode {
        return <Dialog open={this.state.openMessageBoxDialog} onClose={(e) => {
            this.setState({openMessageBoxDialog: false});
        }} PaperProps={{style: {minWidth: 400}}}>
            <DialogTitle>{i18n("确认")}</DialogTitle>
            <DialogContent>
                {i18n("确定要删除「{0}」吗?",this.state.removeImageType)}
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                }}>{i18n("取消")}</Button>
                <Button variant="outlined" color="primary" onClick={(e) => {
                    this.setState({openMessageBoxDialog: false});
                    this.onClickRemove();
                }}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>
    }
}
