import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {TextField, Typography} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";

import FtDivFlex from "../ui/FtDivFlex";

import {i18n} from "../util/I18n";
import {RouteComponentProps} from "react-router-dom";

import JtTextField from "../ui/JtTextField";
import FtPage from "../ui/FtPage";
import FssUserMediaSelectDialog from "../userMedia/FssUserMediaSelectDialog";
import FssUserMedia from "../model/FssUserMedia";
import FssUserMediaComp from "../userMedia/FssUserMediaComp";
import FssSellerService from "../service/FssSellerService";
import FssSeller from "../model/FssSeller";
import FssClientData from "../data/FssClientData";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";

interface FssSellerProfilePageProp extends RouteComponentProps {
}

export class FssSellerProfilePageState {
    seller: FssSeller = new FssSeller();
}


export default class FssSellerPage extends React.Component<FssSellerProfilePageProp, FssSellerProfilePageState> {

    fssUserMediaSelectDialog!: FssUserMediaSelectDialog;

    constructor(props: any) {
        super(props);
        this.state = {seller: new FssSeller()};
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onChangeImage = this.onChangeImage.bind(this);
        this.onRemoveImage = this.onRemoveImage.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidMount(): void {
        FtToast.showToastLoading();
        FssSellerService.getSellerByIdAdmin((seller: FssSeller) => {
            FtToast.closeToast();
            let newValue: FssSeller = new FssSeller();
            newValue = FtUtil.mergeObject(newValue, seller);
            this.setState({seller: newValue});
        });
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.seller, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssSellerService.saveMySeller(this.state.seller,
            (data: FssSeller) => {
                FtToast.closeToast();
                this.setState({seller: FtUtil.mergeObject(this.state.seller, data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功"));
                this.forceUpdate();
            });
    }

    onChangeImage(imageUrl?: string, id?: string) {
        if (id != null && id == "logo") {
            this.fssUserMediaSelectDialog.show("single", (userMediaList: Array<FssUserMedia> | null) => {
                if (userMediaList != null && userMediaList.length > 0) {
                    this.state.seller.logoUrl = userMediaList[0].mediaUrl;
                    this.forceUpdate();
                }
            });
        } else if (id != null && id == "coverImageUrl") {
            this.fssUserMediaSelectDialog.show("single", (userMediaList: Array<FssUserMedia> | null) => {
                if (userMediaList != null && userMediaList.length > 0) {
                    this.state.seller.coverImageUrl = userMediaList[0].mediaUrl;
                    this.forceUpdate();
                }
            });
        }
    }

    onRemoveImage(id?: string) {
        if (id != null && id == "logo") {
            this.state.seller.logoUrl = "";
        }
        if (id != null && id == "coverImageUrl") {
            this.state.seller.coverImageUrl = "";
        }
        this.forceUpdate();
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    render() {
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Typography variant="h6">{i18n("公开资料")}</Typography>}>
            <Card>
                <CardContent>
                    <FtRow title="">
                        <FtDivFlex style={{width: "100%"}} justifyContent={"space-around"}>
                            <FssUserMediaComp id={"logo"} label={i18n("商户LOGO")}
                                              mediaUrl={this.state.seller.logoUrl}
                                              onClickRemove={this.onRemoveImage}
                                              mediaType={"image"} style={{width: 100, height: 100}}
                                              onClickImage={this.onChangeImage}/>
                            <FssUserMediaComp id={"coverImageUrl"} label={i18n("店铺背景图")}
                                              mediaUrl={this.state.seller.coverImageUrl}
                                              onClickRemove={this.onRemoveImage}
                                              mediaType={"image"} style={{width: 300}}
                                              onClickImage={this.onChangeImage}/>
                        </FtDivFlex>
                    </FtRow>
                    <FtRow title="">
                        <JtTextField id="shortName" label={i18n("简称")}
                                     value={this.state.seller.shortName}
                                     onChange={this.onChange} style={{width: 300}}
                                     endAdornment={this.getTranslationIcon("shortName", this.state.seller.shortName)}/>
                        <JtTextField id="fullName" label={i18n("全称")}
                                     value={this.state.seller.fullName}
                                     onChange={this.onChange} style={{width: 300}}
                                     endAdornment={this.getTranslationIcon("fullName", this.state.seller.fullName)}/>
                        {/*<TextField id="sellerType" label={i18n("商户类型")}*/}
                        {/*           value={this.state.seller.sellerType ? this.state.seller.sellerType : ""}*/}
                        {/*           style={{width: 100}} disabled={true}/>*/}
                        <TextField id="shortName" label={i18n("状态")}
                                   value={this.state.seller.sellerStatusText}
                                   style={{width: 100}} disabled={true}/>
                        <div style={{width: "100%"}}/>
                        <TextField id="slogan" label={i18n("宣传语")}
                                   value={this.state.seller.slogan}
                                   onChange={this.onChange} style={{width: "100%"}}/>
                        <TextField id="createDatetime" label={i18n("注册日期")}
                                   value={FssClientData.formatDatetime(this.state.seller.createDatetime)}
                                   style={{width: 300}} disabled={true}/>
                        <TextField id="storeName" label={i18n("所属分站")}
                                   value={this.state.seller.fssStore.storeName}
                                   onChange={this.onChange} style={{width: "100%"}} disabled={true}/>
                        <TextField id="storeAddress" label={i18n("商家地址")}
                                   value={this.state.seller.storeAddress}
                                   onChange={this.onChange} style={{width: "100%"}}/>
                    </FtRow>
                    <FtRow title={""}>
                        <JtTextField id="shortDescription" label={i18n("简述")} rows={7} multiline={true}
                                     value={this.state.seller.shortDescription}
                                     onChange={this.onChange} style={{width: "40%"}}
                                     endAdornment={this.getTranslationIcon("shortDescription", this.state.seller.shortDescription, 7)}/>
                        <JtTextField id="storeOpeningHours" label={i18n("营业时间")} rows={7} multiline={true}
                                     value={this.state.seller.storeOpeningHours}
                                     onChange={this.onChange} style={{width: "40%"}}
                                     endAdornment={this.getTranslationIcon("storeOpeningHours", this.state.seller.storeOpeningHours, 7)}/>
                    </FtRow>

                </CardContent>
                <CardActions style={{justifyContent: "center"}}>
                    <Button variant="contained" color="primary" onClick={this.onClickSave}>{i18n("保存")}</Button>
                </CardActions>
            </Card>
            <FssUserMediaSelectDialog ref={(element: any) => {
                this.fssUserMediaSelectDialog = element
            }}/>
        </FtPage>;
    }

    getTranslationIcon(itemKey: string, defaultText?: string, rows?: number): ReactNode {
        if (this.state.seller && this.state.seller.id.length > 0) {
            let appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite && appWebsite.supportLanguages !== null && appWebsite.supportLanguages.length > 0) {
                return <FssTranslationDialogComp objectId={this.state.seller.id}
                                                 groupKey={"seller"} itemKey={itemKey}
                                                 rows={rows}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>
            } else {
                return null;
            }
        } else {
            return null;
        }
        return null;
    }
}
