import * as React from "react";
import {ChangeEvent, HTMLAttributes, ReactNode} from "react";
import FtGrid from "../ui/FtGrid";
import FtPanel from "../ui/FtPanel";
import {
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Typography
} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import FtCombobox from "../ui/FtCombobox";
import FssProduct, {EBusinessType, EControlType, EProductStatus} from "../model/FssProduct";
import JtTextField from "../ui/JtTextField";
import FssUserMediaSelectDialog from "../userMedia/FssUserMediaSelectDialog";
import grey from "@material-ui/core/colors/grey";
import FssCategory from "../model/FssCategory";
import FssUserMedia from "../model/FssUserMedia";
import FtUtil from "../util/FtUtil";
import FssUserMediaComp from "../userMedia/FssUserMediaComp";
import {ListAlt} from "@material-ui/icons";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import ChipInput from 'material-ui-chip-input'
import _ from "lodash";
import {i18n} from "../util/I18n";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import FssShippingFeeTemplate from "../model/FssShippingFeeTemplate";
import FssProductSupplierComboBox from "../productSupplier/FssProductSupplierComboBox";
import FssProductSupplier, {EType} from "../model/FssProductSupplier";
import FssProductService from "../service/FssProductService";
import FtPagedList from "../model/FtPagedList";
import FssProductTaxPanel from "./FssProductTaxPanel";
import FssTaxName from "../model/FssTaxName";
import FssShippingFeeTemplateService from "../service/FssShippingFeeTemplateService";
import FssClientData from "../data/FssClientData";
import FssTranslationDialogComp from "./FssTranslationDialogComp";
import FssCategoryService from "../service/FssCategoryService";
import FtSnackbar from "../ui/FtSnackbar";
import FssStore from "../model/FssStore";
import FssStoreService from "../service/FssStoreService";
import FssStoreCategory from "../model/FssStoreCategory";
import FssStoreCategoryService from "../service/FssStoreCategoryService";

interface FssProductBaseInfoTabPanelProp extends HTMLAttributes<HTMLDivElement> {
    fssProduct: FssProduct;
    hideTagsPanel?: boolean;
    onChangeProduct?: (product: FssProduct) => void;
}

export class FssProductBaseInfoTabPanelState {
    categoryList?: Array<FssCategory> = [];
    storeCategoryList?: Array<FssStoreCategory> = [];
    shippingFeeTemplateList?: Array<FssShippingFeeTemplate> = new Array<FssShippingFeeTemplate>();
    stockAmountType: string = "";
    stockAmount: number = 0;
    openUpdateStockAmountDialog: boolean = false;
    messageText: string = "";
    checkedTemplateId: Array<number> = [];
    store: FssStore = new FssStore();
}

export default class FssProductBaseInfoTabPanel extends React.Component<FssProductBaseInfoTabPanelProp, FssProductBaseInfoTabPanelState> {
    jtUserMediaSelectDialog!: FssUserMediaSelectDialog;

    constructor(props: any) {
        super(props);
        this.state = {
            openUpdateStockAmountDialog: false,
            stockAmountType: "",
            messageText: "",
            stockAmount: 0,
            checkedTemplateId: [],
            store: new FssStore(),
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeCategory = this.onChangeCategory.bind(this);
        this.onChangeStoreCategory = this.onChangeStoreCategory.bind(this);
        this.onClickImage = this.onClickImage.bind(this);
        this.onRemoveImage = this.onRemoveImage.bind(this);
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onChangeChecked = this.onChangeChecked.bind(this);
        this.handleAddChip = this.handleAddChip.bind(this);
        this.handleDeleteChip = this.handleDeleteChip.bind(this);
        this.onUpdateStockAmount = this.onUpdateStockAmount.bind(this);
        this.onChangeProductSupplier = this.onChangeProductSupplier.bind(this);
        this.onChangeProductPickingGroup = this.onChangeProductPickingGroup.bind(this);
        this.shippingTemplate = this.shippingTemplate.bind(this);
        this.onChangeProductStorageLocation = this.onChangeProductStorageLocation.bind(this);
    }

    componentDidMount(): void {
        FssCategoryService.getStoreCategoryList("", 0, 999, '', (result: FtPagedList<FssCategory>) => {
            this.setState({categoryList: result.content});
        });
        FssStoreCategoryService.getStoreCategoryList(undefined, undefined, (result: Array<FssStoreCategory>) => {
            this.setState({storeCategoryList: result});
        });
        FssShippingFeeTemplateService.getNormalShippingFeeTemplateList((result: Array<FssShippingFeeTemplate>) => {
            this.setState({shippingFeeTemplateList: result});
        });
        let adminSession = FssClientData.getData().webSession.adminSession;
        if (adminSession != null && adminSession.storeId != null) {
            FssStoreService.getStoreById(adminSession.storeId, (store: FssStore) => {
                this.setState({store: store});
            });
        }
    }

    render() {
        let isEdit: boolean = false;
        if (this.props.fssProduct.id != null && this.props.fssProduct.id.length != 0)
            isEdit = true;
        let isMainProduct: boolean = true;
        if (this.props.fssProduct.parentProductId != null && this.props.fssProduct.parentProductId.length > 0)
            isMainProduct = false;

        return <div style={{backgroundColor: grey[100], display: "flex",}}>
            <FtRow cellHorizontalAlign={"left"} cellVerticalAlign={"top"} cellWidthS={"1"} cellWidthM={"1,2"}
                   style={{maxWidth: 1120}}>
                <div style={{}}>
                    <FtPanel marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1,1"} cellHorizontalAlign={"left"}>
                                <FormControl style={{minWidth: "100%"}}
                                             disabled={this.props.fssProduct.productStatusControlType == EControlType.AUTO}>
                                    <InputLabel> {i18n("状态")} </InputLabel>
                                    <Select id="productStatus" value={this.props.fssProduct.productStatus}
                                            onChange={(event, child) => {
                                                let value = event.target.value as EProductStatus;
                                                if (value == EProductStatus.ENABLE) {
                                                    if (this.props.fssProduct.salesPriceUsd == null || this.props.fssProduct.salesPriceUsd <= 0) {
                                                        FtSnackbar.showText(i18n("请设置销售价(USD)"), 60000, "error")
                                                        return;
                                                    }
                                                }
                                                this.props.fssProduct.productStatus = event.target.value as EProductStatus;
                                                this.forceUpdate();
                                            }}>
                                        <MenuItem value="-1"/>
                                        <MenuItem value={EProductStatus.ENABLE}>{i18n("上架销售")}</MenuItem>
                                        <MenuItem value={EProductStatus.DISABLE}>{i18n("已下架")}</MenuItem>
                                        <MenuItem value={EProductStatus.STOP_SELL}>{i18n("上架售罄")}</MenuItem>
                                        <MenuItem value={EProductStatus.ARCHIVE}>{i18n("存档")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </FtRow>
                            <FtRow cellWidthS={"1"}>
                                <FormControl style={{minWidth: "100%"}}>
                                    <FormHelperText>{i18n("商品状态控制类型")}</FormHelperText>
                                    <RadioGroup row value={this.props.fssProduct.productStatusControlType}
                                                onChange={(event, value) => {
                                                    this.props.fssProduct.productStatusControlType = value as EControlType;
                                                    this.forceUpdate();
                                                }}>
                                        <FormControlLabel value={EControlType.MANUAL} control={<Radio/>}
                                                          label={i18n("手动上架")}/>
                                        <FormControlLabel value={EControlType.AUTO} control={<Radio/>}
                                                          label={i18n("自动上架")}/>
                                    </RadioGroup>
                                    {this.props.fssProduct.productStatusControlType == EControlType.AUTO &&
                                    <FormHelperText
                                        style={{color: "red"}}>{i18n("当库存数量发生变化后,如果商品库存数量大于0件时会自动上架销售")}</FormHelperText>}
                                </FormControl>
                            </FtRow>
                            {isMainProduct && <FtRow cellWidthS={"1,1"} cellHorizontalAlign={"left"}>
                                <FormControl style={{minWidth: "100%"}}>
                                    <InputLabel> {i18n("业务类型")} </InputLabel>
                                    <Select id="businessType" value={this.props.fssProduct.businessType}
                                            disabled={this.props.fssProduct.businessType == EBusinessType.NORMAL && this.props.fssProduct.childProductList && this.props.fssProduct.childProductList.length > 0}
                                            onChange={(event, child) => {
                                                this.props.fssProduct.businessType = event.target.value as EBusinessType;
                                                this.forceUpdate();
                                            }}>
                                        <MenuItem value={EBusinessType.NORMAL} key={"NORMAL"}>{i18n("普通商品")}</MenuItem>
                                        <MenuItem value={EBusinessType.EXCHANGE}
                                                  key={"EXCHANGE"}>{i18n("换购商品")}</MenuItem>
                                        <MenuItem value={EBusinessType.MARKETING_EVENT}
                                                  key={"MARKETING_EVENT"}>{i18n("营销活动专属商品")}</MenuItem>
                                    </Select>
                                </FormControl>

                            </FtRow>}
                            <FtRow cellWidthS={"1"}>
                                {isMainProduct && this.state.categoryList && this.state.categoryList.length > 0 &&
                                <FtCombobox label={i18n("本店分类")} optionItems={this.state.categoryList}
                                            labelPropertyName={"categoryName"}
                                            valuePropertyName={"id"}
                                            value={this.props.fssProduct.categoryId}
                                            onSelectItem={this.onChangeCategory} style={{width: "100%"}}/>}
                                {isMainProduct && this.state.storeCategoryList && this.state.storeCategoryList.length > 0 &&
                                <FtCombobox label={i18n("网站分类")} optionItems={this.state.storeCategoryList}
                                            labelPropertyName={"categoryName"}
                                            valuePropertyName={"id"}
                                            value={this.props.fssProduct.storeCategoryId}
                                            onSelectItem={this.onChangeStoreCategory} style={{width: "100%"}}/>}
                            </FtRow>
                            <FtRow cellWidthS={"1,1"}>
                                <FtTextFieldNumber id="sortIndex" value={this.props.fssProduct.sortIndex}
                                                   style={{width: "100%"}} label={i18n("排序系数")}
                                                   helperText={i18n("数字越大排列越前")}
                                                   type="integer" inputStyle={{textAlign: "center"}}
                                                   onChangeValue={value => this.onChangeValue("sortIndex", value)}/>
                                <FormControlLabel
                                    control={<Checkbox id="toTop" color="secondary"
                                                       checked={this.props.fssProduct.toTop}
                                                       onChange={this.onChangeChecked}/>}
                                    label={i18n("是否置顶")}/>
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                    <FtPanel panelHeader={i18n("销售")} marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1,1"}>
                                <FtTextFieldNumber id="salesPriceUsd" value={this.props.fssProduct.salesPriceUsd}
                                                   style={{width: "100%"}} label={i18n("销售价(USD)")}
                                                   type="currency" inputStyle={{textAlign: "right"}}
                                                   onChangeValue={value => this.onChangeValue("salesPriceUsd", value)}/>
                                <FtTextFieldNumber id="regularPriceUsd" value={this.props.fssProduct.regularPriceUsd}
                                                   style={{width: "100%"}}
                                                   label={i18n("原价(USD)")}
                                                   type="currency" inputStyle={{textAlign: "right"}}
                                                   onChangeValue={value => this.onChangeValue("regularPriceUsd", value)}/>
                                <JtTextField id="sellUnit" label={i18n("销售单位")}
                                             value={this.props.fssProduct.sellUnit}
                                             placeholder={i18n("如：件、瓶、箱")}
                                             onChange={this.onChange} style={{width: "100%"}}/>
                                <JtTextField id="sellUnitAmount" label={i18n("销售单位数量")}
                                             value={this.props.fssProduct.sellUnitAmount}
                                             onChange={this.onChange} style={{width: "100%"}}/>
                                <JtTextField id="userAmountLimit" label={i18n("用户购买数量限制")}
                                             value={this.props.fssProduct.userAmountLimit ? this.props.fssProduct.userAmountLimit : 0}
                                             onChange={this.onChange} style={{width: "100%"}}/>

                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                    <FssProductTaxPanel selectedItems={this.props.fssProduct.taxNameList}
                                        onSelectChanged={(taxNameList: Array<FssTaxName>) => {
                                            this.props.fssProduct.taxNameList = taxNameList;
                                            if (this.props.onChangeProduct)
                                                this.props.onChangeProduct(this.props.fssProduct);
                                        }}/>
                    <FtPanel panelHeader={i18n("库存")} marginHorizontal={16}
                             marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1,1"}>
                                <FtTextFieldNumber id="stockAmount" value={this.props.fssProduct.stockAmount}
                                                   style={{width: "100%"}} label={i18n("当前库存数量")}
                                                   type="integer" inputStyle={{textAlign: "center"}} disabled={isEdit}
                                                   onChangeValue={value => this.onChangeValue("stockAmount", value)}
                                                   endAdornment={isEdit &&
                                                   <Tooltip title={i18n("修改库存数量")}>
                                                       <IconButton color={"secondary"} onClick={() => {
                                                           this.setState({
                                                               openUpdateStockAmountDialog: true,
                                                               messageText: "",
                                                               stockAmountType: "",
                                                               stockAmount: 0
                                                           });
                                                       }}><ListAlt/>
                                                       </IconButton>
                                                   </Tooltip>}
                                />
                                <FtTextFieldNumber id="salesAmount" value={this.props.fssProduct.salesAmount}
                                                   style={{width: "100%"}} label={i18n("已销售数量")}
                                                   type="integer" inputStyle={{textAlign: "center"}}
                                                   onChangeValue={value => this.onChangeValue("salesAmount", value)}/>
                            </FtRow>
                            <FtRow cellWidthS={"1"}>
                                <FssProductSupplierComboBox label={i18n("拣货分组")}
                                                            type={EType.PICKING_GROUP}
                                                            onChangeProductSupplier={this.onChangeProductPickingGroup}
                                                            selectedSupplierName={this.props.fssProduct.pickingGroup}/>
                                <FssProductSupplierComboBox label={i18n("库存位置")}
                                                            type={EType.STORAGE_LOCATION}
                                                            onChangeProductSupplier={this.onChangeProductStorageLocation}
                                                            selectedSupplierName={this.props.fssProduct.storageLocation}/>
                                <FormControl style={{width: "100%"}}>
                                    <InputLabel> 库存控制方式 </InputLabel>
                                    <Select id="inventoryControlType" value={this.props.fssProduct.inventoryControlType}
                                            onChange={(event, child) => {
                                                this.onChangeValue("inventoryControlType", event.target.value);
                                            }}>
                                        <MenuItem value={"OVER_SELL_STOP"} key={"OVER_SELL_STOP"}>超卖售罄</MenuItem>
                                        <MenuItem value={"ALLOW_OVER_SELL"} key={"ALLOW_OVER_SELL"}>允许超卖</MenuItem>
                                        <MenuItem value={"OVER_SELL_DISABLE"} key={"OVER_SELL_DISABLE"}>超卖下架</MenuItem>
                                    </Select>
                                </FormControl>
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                    <FtPanel panelHeader={i18n("配送")} marginHorizontal={16}
                             marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1,1"}>
                                <JtTextField id="weight" label={i18n("重量（克）")}
                                             type={"integer"}
                                             errorHelperText={"请输入整数克"}
                                             value={this.props.fssProduct.weight}
                                             onChange={this.onChange} style={{width: "100%"}}/>
                                <JtTextField id="size" label={i18n("体积（立方米）")}
                                             value={this.props.fssProduct.size}
                                             onChange={this.onChange} style={{width: "100%"}}/>
                            </FtRow>
                            {this.state.shippingFeeTemplateList && this.state.shippingFeeTemplateList.length > 0 && this.shippingTemplate()}
                        </FtGrid>
                    </FtPanel>
                    <FtPanel panelHeader={i18n("其他信息")} marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1"}>
                                <JtTextField id="viewsCount" label={i18n("浏览次数")}
                                             value={this.props.fssProduct.viewsCount ? this.props.fssProduct.viewsCount : 0}
                                             disabled={true}
                                             onChange={this.onChange} style={{width: "100%"}}/>
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                </div>
                <div style={{}}>
                    <FtPanel panelHeader={i18n("商品信息")} marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1"}>
                                <JtTextField id="title" label={i18n("标题")}
                                             value={this.props.fssProduct.title} required={true}
                                             onChange={this.onChange} style={{width: "100%"}}
                                             endAdornment={this.getTranslationIcon("title", this.props.fssProduct.title)}/>
                                <JtTextField id="shortDescription" label={i18n("简述（在商品列表中显示，不超过50字）")}
                                             value={this.props.fssProduct.shortDescription}
                                             onChange={this.onChange} style={{width: "100%"}}
                                             multiline={true} rows={2}
                                             endAdornment={this.getTranslationIcon("shortDescription", this.props.fssProduct.shortDescription, 2)}/>
                            </FtRow>
                            <FtRow cellWidthS={"1"} cellWidthM={"2,1"}>
                                <JtTextField id="name" label={i18n("商品名称")} value={this.props.fssProduct.name}
                                             onChange={this.onChange} style={{width: "100%"}}
                                             endAdornment={this.getTranslationIcon("name", this.props.fssProduct.name)}/>
                                <JtTextField id="brand" label={i18n("品牌")} value={this.props.fssProduct.brand}
                                             onChange={this.onChange} style={{width: "100%"}}
                                             endAdornment={this.getTranslationIcon("brand", this.props.fssProduct.brand)}/>
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                    <FtPanel panelHeader={isMainProduct ? i18n("商品图片") : i18n("商品图片（这是个子商品，如不设置则默认主商品图片）")}
                             marginHorizontal={16}
                             marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1,1"} cellWidthM={"1,1,1,1,1"}>
                                {this.getJtUserMediaComp(0, "0", i18n("主图"), this.props.fssProduct.productImageList[0])}
                                {this.getJtUserMediaComp(1, "1", i18n("图片1"), this.props.fssProduct.productImageList[1])}
                                {this.getJtUserMediaComp(2, "2", i18n("图片2"), this.props.fssProduct.productImageList[2])}
                                {this.getJtUserMediaComp(3, "3", i18n("图片3"), this.props.fssProduct.productImageList[3])}
                                {this.getJtUserMediaComp(4, "4", i18n("图片4"), this.props.fssProduct.productImageList[4])}
                                {this.getJtUserMediaComp(5, "5", i18n("图片5"), this.props.fssProduct.productImageList[5])}
                                {this.getJtUserMediaComp(6, "6", i18n("图片6"), this.props.fssProduct.productImageList[6])}
                                {this.getJtUserMediaComp(7, "7", i18n("图片7"), this.props.fssProduct.productImageList[7])}
                                {this.getJtUserMediaComp(8, "8", i18n("图片8"), this.props.fssProduct.productImageList[8])}
                                {this.getJtUserMediaComp(9, "9", i18n("图片9"), this.props.fssProduct.productImageList[9])}
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                    <FtPanel panelHeader={isMainProduct ? i18n("规格") : i18n("规格（这是个子商品，不能修改规格名称，只能填写规格值）")}
                             marginHorizontal={16} marginVertical={16}>
                        {this.getSpecificationComp(isMainProduct)}
                    </FtPanel>
                    <FtPanel panelHeader={i18n("其他")} marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,1,1"}>
                                <JtTextField id="productCode" label={i18n("商品编码")}
                                             value={this.props.fssProduct.productCode} disabled={true}
                                             onChange={this.onChange} style={{width: "100%"}}/>
                                <JtTextField id="barcode" label={i18n("商品编码")}
                                             value={this.props.fssProduct.barcode}
                                             onChange={this.onChange} style={{width: "100%"}}/>
                                {isEdit &&
                                <JtTextField id="createDatetime" label={i18n("创建时间")}
                                             value={this.props.fssProduct.createDatetimeText}
                                             style={{width: "100%"}} disabled={true}/>}
                            </FtRow>
                            <FtRow cellWidthS={"1"}>
                                <JtTextField id="productDetail.comment" label={i18n("评论")}
                                             value={this.props.fssProduct.productDetail.comment}
                                             onChange={this.onChange} style={{width: "100%"}} multiline={true} rows={3}
                                             rowsMax={5}/>
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                    {(this.props.hideTagsPanel === undefined || !this.props.hideTagsPanel) &&
                    <FtPanel panelHeader={i18n("标签")} marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1"}>
                                <ChipInput label={""}
                                           variant={"standard"}
                                           placeholder={i18n("请输入标签")}
                                           alwaysShowPlaceholder={true}
                                           value={this.props.fssProduct.tagsList}
                                           onAdd={(chip) => this.handleAddChip(chip)}
                                           onDelete={(chip, index) => this.handleDeleteChip(chip)}
                                           style={{width: "100%"}}/>
                            </FtRow>
                        </FtGrid>
                        <FtGrid>
                            <FtRow cellHorizontalSpace={16}>
                                {this.props.fssProduct.defaultTagsList.map((data: string, index: number) => {
                                    let targetIndex = -1;
                                    if (this.props.fssProduct.tagsList) {
                                        targetIndex = _.findIndex(this.props.fssProduct.tagsList, (tag: string) => {
                                            return data == tag;
                                        });
                                    }
                                    return <Chip color={targetIndex > -1 ? "secondary" : "default"}
                                                 label={data}
                                                 onClick={() => {
                                                     if (targetIndex > -1) {
                                                         this.handleDeleteChip(data);
                                                     } else {
                                                         this.handleAddChip(data);
                                                     }
                                                 }}/>;
                                })}
                            </FtRow>
                        </FtGrid>
                    </FtPanel>}
                    <FtPanel panelHeader={i18n("采购")} marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,2"} style={{alignItems: "baseline"}}>
                                <FtTextFieldNumber id="purchasePriceUsd" value={this.props.fssProduct.purchasePriceUsd}
                                                   style={{width: "100%"}} label={i18n("原价(USD)")}
                                                   type="currency" inputStyle={{textAlign: "left"}}
                                                   onChangeValue={value => this.onChangeValue("purchasePriceUsd", value)}/>
                                <FssProductSupplierComboBox label={i18n("供货商")}
                                                            type={EType.SUPPLIER}
                                                            onChangeProductSupplier={this.onChangeProductSupplier}
                                                            selectedSupplierName={this.props.fssProduct.supplierName}/>
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                </div>
                <FssUserMediaSelectDialog ref={(element: any) => {
                    this.jtUserMediaSelectDialog = element
                }}/>
            </FtRow>
            {this.onUpdateStockAmount()}
        </div>;
    }

    getSpecificationComp(isMainProduct: boolean) {
        if (this.props.fssProduct.specificationList.length == 1) {
            let spec = this.props.fssProduct.specificationList[0];
            return <FtGrid>
                <FtRow cellWidthS={"1,2"}>
                    <JtTextField id={"specificationName"} label={i18n("规格名称")} disabled={!isMainProduct}
                                 value={spec.name}
                                 placeholder={i18n("如: 尺码、颜色")}
                                 onChange={event => {
                                     this.props.fssProduct.specificationList[0].name = event.target.value
                                     this.forceUpdate();
                                 }}
                                 style={{width: "100%"}}
                                 endAdornment={this.getTranslationIconSpec(spec.id, "name", spec.name)}/>
                    <JtTextField id={"specification"} label={i18n("规格值")}
                                 value={spec.value}
                                 placeholder={i18n("如: XL、红色")}
                                 onChange={event => {
                                     this.props.fssProduct.specificationList[0].value = event.target.value
                                     this.forceUpdate();
                                 }}
                                 style={{width: "100%"}}
                                 endAdornment={this.getTranslationIconSpec(spec.id, "value", spec.value)}/>
                </FtRow>
            </FtGrid>
        } else {
            return <FtGrid>
                {this.props.fssProduct.specificationList.map((spec, index) => {
                    return <FtRow cellWidthS={"1,2"}>
                        <JtTextField id={"specificationName" + index} label={i18n("规格名称" + (index + 1))}
                                     disabled={!isMainProduct}
                                     value={spec.name}
                                     placeholder={i18n("如: 尺码、颜色")}
                                     onChange={event => {
                                         this.props.fssProduct.specificationList[index].name = event.target.value
                                         this.forceUpdate();
                                     }}
                                     style={{width: "100%"}}
                                     endAdornment={this.getTranslationIconSpec(spec.id, "name", spec.name)}/>
                        <JtTextField id={"specification" + index} label={i18n("规格值" + (index + 1))}
                                     value={spec.value}
                                     placeholder={i18n("如: XL、红色")}
                                     onChange={event => {
                                         this.props.fssProduct.specificationList[index].value = event.target.value
                                         this.forceUpdate();
                                     }}
                                     style={{width: "100%"}}
                                     endAdornment={this.getTranslationIconSpec(spec.id, "value", spec.value)}/>
                    </FtRow>
                })}
            </FtGrid>
        }
    }

    shippingTemplate() {
        let templateIds: string[] = [];
        let templateNames: string[] = [];
        this.state.shippingFeeTemplateList?.forEach(value => {
            templateIds.push(value.id);
            templateNames.push(value.templateNameText);
        });

        return <FtRow cellWidthS={"1"}>
            <FormControl style={{minWidth: "100%"}}>
                <FormLabel> {i18n("运费模版")} </FormLabel>
                <FtCheckboxGroup row={false}
                                 values={templateIds}
                                 labels={templateNames}
                                 checkedValues={this.props.fssProduct.shippingFeeTemplateIdList}
                                 onCheck={(checkedValue) => {
                                     this.props.fssProduct.shippingFeeTemplateIdList = checkedValue;
                                     this.forceUpdate();
                                 }}>
                </FtCheckboxGroup>
            </FormControl>
        </FtRow>
    }

    onChangeProductStorageLocation(item?: FssProductSupplier) {
        let supplierName = item ? item.supplierName : "";
        this.onChangeValue("storageLocation", supplierName);
    }

    onChangeProductSupplier(item?: FssProductSupplier) {
        let supplierName = item ? item.supplierName : "";
        this.onChangeValue("supplierName", supplierName);
    }

    onChangeProductPickingGroup(item?: FssProductSupplier) {
        let pickingGroup = item ? item.supplierName : "";
        this.onChangeValue("pickingGroup", pickingGroup);
    }

    handleAddChip(chip: string) {
        let index = this.props.fssProduct.tagsList.indexOf(chip);
        if (index == -1) {
            this.props.fssProduct.tagsList.push(chip);
            if (this.props.onChangeProduct)
                this.props.onChangeProduct(this.props.fssProduct);
        }
    }

    handleDeleteChip(chip: string) {
        let index = this.props.fssProduct.tagsList.indexOf(chip);
        if (index > -1) {
            this.props.fssProduct.tagsList.splice(index, 1)
            if (this.props.onChangeProduct)
                this.props.onChangeProduct(this.props.fssProduct);
        }

    }

    onChangeChecked(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.type == "checkbox") {
            FtUtil.setProperty(this.props.fssProduct, event.target.id, event.target.checked);
        }
        this.forceUpdate();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        if (event.target.id == "productDetail.comment") {
            this.props.fssProduct.productDetail.comment = event.target.value;
            this.forceUpdate();
            if (this.props.onChangeProduct) {
                this.props.onChangeProduct(this.props.fssProduct);
            }
        } else {
            this.onChangeValue(event.target.id, event.target.value);
        }

    }

    onChangeValue(id: string, value?: unknown) {
        FtUtil.setProperty(this.props.fssProduct, id, value);
        if (this.props.onChangeProduct)
            this.props.onChangeProduct(this.props.fssProduct);
    }


    onChangeCategory(item: any) {
        if (item != null) {
            this.props.fssProduct.categoryId = (item as FssCategory).id;
            this.props.fssProduct.category = item as FssCategory;
        } else {
            this.props.fssProduct.categoryId = "";
            this.props.fssProduct.category = undefined;
        }
        if (this.props.onChangeProduct) {
            this.props.onChangeProduct(this.props.fssProduct);
        }
    }

    onChangeStoreCategory(item: any) {
        if (item != null) {
            this.props.fssProduct.storeCategoryId = (item as FssStoreCategory).id;
        } else {
            this.props.fssProduct.storeCategoryId = "";
        }
        if (this.props.onChangeProduct) {
            this.props.onChangeProduct(this.props.fssProduct);
        }
    }

    onClickImage(imageUrl?: string, id?: string, index?: number) {
        if (id != null) {
            this.jtUserMediaSelectDialog.show("single", (userMediaList: Array<FssUserMedia> | null) => {
                if (userMediaList != null && userMediaList.length > 0) {
                    this.props.fssProduct.productImageList[+id] = userMediaList[0].mediaUrl;
                    this.forceUpdate();
                    if (this.props.onChangeProduct)
                        this.props.onChangeProduct(this.props.fssProduct);
                }
            });
        }
    }

    onRemoveImage(id?: string, index?: number) {
        if (id != null) {
            this.props.fssProduct.productImageList[+id] = "";
            this.forceUpdate();
        }
        if (this.props.onChangeProduct)
            this.props.onChangeProduct(this.props.fssProduct);
    }

    /**
     * @param index 当前图片位置，用来做位移的
     * @param id 图片字段，
     * @param label  主图，图片1...
     * @param imageUrl  图片地址
     */
    getJtUserMediaComp(index: number, id: string, label: string, imageUrl: string): ReactNode {
        return <FssUserMediaComp id={id} label={label} onClickRemove={this.onRemoveImage}
                                 mediaUrl={imageUrl}
                                 mediaType={"image"} style={{
            width: "100%",
            height: 0,
            paddingBottom: "100%",
            minHeight: "unset",
            minWidth: "unset"
        }}
                                 onClickImage={this.onClickImage} className={"userMediaComp"}>
        </FssUserMediaComp>;
    }

    onUpdateStockAmount() {
        return <Dialog open={this.state.openUpdateStockAmountDialog} onClose={(e) => {
            this.setState({openUpdateStockAmountDialog: false});
        }}>
            <DialogTitle>修改库存数量</DialogTitle>
            <DialogContent>
                <div>
                    <Typography variant="subtitle1">
                        当前库存数量【{this.props.fssProduct.stockAmount}】
                    </Typography>
                    <FormControl>
                        <RadioGroup row onChange={(event, value) => {
                            this.setState({messageText: "", stockAmountType: value});
                        }}>
                            <FormControlLabel value={"IN"} control={<Radio color="primary"/>} label="入库"/>
                            <FormControlLabel value={"OUT"} control={<Radio color="primary"/>} label="出库"/>
                        </RadioGroup>
                    </FormControl>
                    <div style={{width: "100%"}}/>
                    <FtTextFieldNumber label={"数量"} type={"float"} size={"medium"} onChangeValue={(value) => {
                        if (value) {
                            this.setState({messageText: "", stockAmount: value});
                        }
                    }}/>
                    <Typography variant="body1" color={"primary"} style={{height: 30}}>
                        {this.state.messageText}
                    </Typography>
                </div>
            </DialogContent>
            <DialogActions style={{justifyContent: "space-between"}}>
                <Button variant="outlined" onClick={() => {
                    this.setState({openUpdateStockAmountDialog: false});
                }}>取消</Button>
                <Button variant="outlined" color="primary" onClick={() => {
                    if (this.state.stockAmountType == "" || this.state.stockAmountType.length == 0) {
                        this.setState({messageText: "请选择入库或者出库"});
                        return;
                    } else if (this.state.stockAmount == 0) {
                        this.setState({messageText: "请选择数量"});
                        return;
                    } else {
                        FssProductService.updateProductStockAmount(this.props.fssProduct.id,
                            this.state.stockAmountType, this.state.stockAmount, (product: FssProduct) => {
                                this.setState({openUpdateStockAmountDialog: false, messageText: ""});
                                if (this.props.onChangeProduct) {
                                    let detailProduct = this.props.fssProduct;
                                    detailProduct.stockAmount = product.stockAmount;
                                    detailProduct.productStatus = product.productStatus;
                                    this.props.onChangeProduct(detailProduct);
                                }
                            });
                    }
                }}>确定</Button>
            </DialogActions>
        </Dialog>
    }

    getTranslationIcon(itemKey: string, defaultText: string, rows?: number): ReactNode {
        if (this.props.fssProduct.id.length > 0) {
            let appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite && appWebsite.supportLanguages !== null && appWebsite.supportLanguages.length > 0) {
                return <FssTranslationDialogComp objectId={this.props.fssProduct.id}
                                                 groupKey={"product"} itemKey={itemKey}
                                                 rows={rows}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    getTranslationIconSpec(objectId: string, itemKey: string, defaultText: string, rows?: number): ReactNode {
        if (this.props.fssProduct.id.length > 0) {
            let appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite && appWebsite.supportLanguages !== null && appWebsite.supportLanguages.length > 0) {
                return <FssTranslationDialogComp objectId={objectId}
                                                 groupKey={"productSpecification"} itemKey={itemKey}
                                                 rows={rows}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
