import FssOrderItem from "./FssOrderItem";
import FssUser from "./FssUser";
import FssStore from "./FssStore";
import FssSeller from "./FssSeller";
import FssDeliveryProvider from "./FssDeliveryProvider";
import FssMarketingEvent from "./FssMarketingEvent";

export default class FssOrder {
    id: string = "";
    orderCode: string = "";
    storeId: string = "";
    countryInfoId: string = "";
    sellerId: string = "";
    createDatetime?: Date;
    quantity: number = 0;
    subtotalProduct: number = 0;
    shippingFee: number = 0;
    discount: number = 0;
    total: number = 0;
    tax: number = 0;
    tips: number = 0;
    paymentType?: EPaymentType;
    paymentTypeText: string = "";
    shippingType: EShippingType = EShippingType.DELIVERY_TO_HOME;
    shippingTypeText: string = "";
    paymentStatus: EPaymentStatus = EPaymentStatus.WAIT;
    paymentStatusText: string = "";
    sellerPaymentStatusText: string = "";
    userId: string = "";
    shipToName: string = "";
    shipToMobile: string = "";
    shipToAddress: string = "";
    shipToAddressLat: number = 0;
    shipToAddressLng: number = 0;
    shipToCity: string = "";
    shipToPostcode: string = "";
    shipToUnitNo: string = "";
    userComment: string = "";
    serviceComment: string = "";
    paymentProfileId: string = "";
    scheduleDatetime: string = "";
    scheduleDatetimeText: string = "";
    scheduleDate: string = "";
    scheduleTime: string = "";
    tipsPercent: number = 0;
    clientType: string = "";
    referral: string = "";
    shipToCountry: string = "";
    shipToProvince: string = "";
    shipToComment: string = "";
    shipFromName: string = "";
    shipFromPhone: string = "";
    shipFromAddress: string = "";
    shipToIdNumber: string = "";
    shipToIdType: string = "";
    deliveryProviderId: string = "";
    trackingNumber: string = "";
    packageFee: number = 0;
    paymentFee: number = 0;
    totalPaid: number = 0;
    status: EOrderStatus = EOrderStatus.CREATED;
    statusText: string = "";
    sellerStatusText: string = "";
    shippingFeeDescription: string = "";
    externalSystemId: string = "";
    otherFee: number = 0;
    totalWeight: number = 0;
    totalSize: number = 0;
    scheduleDatetimeDesc: string = "";
    shipToBuzzCode: string = "";
    orderChannel: string = "";
    pointUsed: number = 0;
    pointDeducted: number = 0;
    balanceUsed: number = 0;
    parentOrderId: string = "";
    modifiedDatetime: string = "";
    modifiedDatetimeText: string = "";
    shippingFeeRuleId: string = "";
    regionId: string = "";
    marketingEventId: string = "";
    trackingNumberBarcode: string = "";
    orderCodeBarcode: string = "";
    items: Array<FssOrderItem> = [];
    taxNameList: Array<string> = [];
    taxMoneyList: Array<number> = [];
    childOrderList?: Array<FssOrder>;
    parentOrder?: FssOrder;
    recalculateFlag?: boolean;
    itemsMap: { [key: string]: FssOrderItem; } = {};

    userCancelable: boolean = true;
    user: FssUser = new FssUser();
    store: FssStore = new FssStore();
    seller: FssSeller = new FssSeller();
    deliveryProvider: FssDeliveryProvider = new FssDeliveryProvider();
    timeSpan: string = "";
    unpaidMoney: number = 0;
    courierName?: string;
    deliverySequence?: number;
    marketingEvent: FssMarketingEvent = new FssMarketingEvent();
}

export enum EOrderStatus {
    CREATED = "CREATED",
    APPROVED = "APPROVED",
    PICKING = "PICKING",
    PACKAGING = "PACKAGING",
    SHIPPED = "SHIPPED",
    DELIVERED = "DELIVERED",
    CANCELED = "CANCELED",
    RETURNED = "RETURNED",
    DELETED = "DELETED",
    CLOSED = "CLOSED",
}

export enum EPaymentStatus {
    PARTIAL_PAID = "PARTIAL_PAID",
    PAID = "PAID",
    OVER_PAID = "OVER_PAID",
    RECONCILIATION = "RECONCILIATION",
    REFUND = "REFUND",
    WAIT = "WAIT",
}

export enum EShippingType {
    DELIVERY_TO_HOME = "DELIVERY_TO_HOME",
    SAFE_DROP = "SAFE_DROP",
    DELIVERY_WITH_SIGNATURE = "DELIVERY_WITH_SIGNATURE",
    PICK_UP = "PICK_UP",
    DELIVERY_WITH_ID_AGE_19 = "DELIVERY_WITH_ID_AGE_19",
}

export enum EPaymentType {
    PAYNICORN = "PAYNICORN",
    ONLINE_POINT = "ONLINE_POINT",
    PAY_AT_STORE = "PAY_AT_STORE",
    ONLINE_ACCOUNT_BALANCE = "ONLINE_ACCOUNT_BALANCE",
    OFFLINE_COD = "OFFLINE_COD",
    ONLINE_CUSTOMER_SERVICE = "ONLINE_CUSTOMER_SERVICE",
    OFFLINE_TRANSFER = "OFFLINE_TRANSFER",
}
