import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssUserMedia from "../model/FssUserMedia";

export default class FssUserMediaService {

    static deleteUserMedia(userMediaId: number | string, successFunction: (userMedia: Array<FssUserMedia>) => void) {
        FssHttpService.postFormData("/api/userMedia/deleteUserMedia", {userMediaId: userMediaId}, successFunction, FssHttpService.handleError);
    }

    static saveUserMedia(userMedia: FssUserMedia, successFunction: (userMedia: Array<FssUserMedia>) => void) {
        FssHttpService.postJson("/api/userMedia/saveUserMedia", userMedia, successFunction, FssHttpService.handleError);
    }

    static getUserMediaList(searchKeyword: string, pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssUserMedia>) => void) {
        FssHttpService.get("/api/userMedia/getUserMediaList", {
            searchKeyword: searchKeyword,
            page: pageNo,
            size: pageSize
        }, successFunction, FssHttpService.handleError);
    }

    static uploadMedia(file: any, fileName: string | undefined, successFunction: (data: string) => void, progressFunction: (percent: number) => void, failFunction: (error: any) => void): void {
        FssHttpService.upload("/api/userMedia/uploadMedia", file, fileName, (data: any) => {
            if (data && data.code != null && data.code === "OK") {
                successFunction(data.content);
            }
        }, progressFunction, failFunction);
    }
}
