import FtPagedList from "../model/FtPagedList";
import FssSellerEmployee from "../model/FssSellerEmployee";
import FssHttpService from "./FssHttpService";

export default class FssSellerEmployeeService {
    static getPagedList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                        pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssSellerEmployee>) => void) {
        FssHttpService.search("/api/sellerEmployee/getPagedList", searchKeyword,
            filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction);
    }
    static inviteFssSellerEmployee(email: string, successFunction: (data: FssSellerEmployee) => void) {
        FssHttpService.postFormData("/api/sellerEmployee/inviteFssSellerEmployee", { email: email }, successFunction, FssHttpService.handleError);
    }
    static inviteUserByEmail(verificationCode: string, successFunction: (fssSellerEmployee: FssSellerEmployee) => void) {
        let params = {verificationCode: verificationCode};
        FssHttpService.postFormData("/api/sellerEmployee/inviteUserByEmail", params, successFunction, FssHttpService.handleError);
    }
    static checkInviteUserVerification(verificationCode: string, successFunction: (fssSellerEmployee: FssSellerEmployee) => void) {
        let params = {verificationCode: verificationCode};
        FssHttpService.postFormData("/api/sellerEmployee/checkInviteUserVerification", params, successFunction, FssHttpService.handleError);
    }
    static getFssSellerEmployeeById(id: string, successFunction: (data: FssSellerEmployee) => void) {
        FssHttpService.get("/api/sellerEmployee/getFssSellerEmployeeById", { id: id }, successFunction, FssHttpService.handleError);
    }
    static saveFssSellerEmployee(fssSellerEmployee: FssSellerEmployee, successFunction: (data: FssSellerEmployee) => void) {
        FssHttpService.postJson("/api/sellerEmployee/saveFssSellerEmployee", fssSellerEmployee, successFunction, FssHttpService.handleError);
    }
    static getBySellerIdAndUserId(sellerId: string, successFunction: (data: FssSellerEmployee) => void) {
        FssHttpService.get("/api/sellerEmployee/getBySellerIdAndUserId", { sellerId: sellerId }, successFunction, FssHttpService.handleError);
    }
}
