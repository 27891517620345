import {ETwoStatus} from "./FtAbstractModel";

export default class FssStoreCategory {
    id: string = "";
    storeId: string = "";
    parentCategoryId: string = "";
    categoryName: string = "";
    shortDescription: string = "";
    coverImageUrl: string = "";
    sortIndex: number = 0;
    categoryStatus: ETwoStatus = ETwoStatus.NORMAL;
    createDatetimeText: string = "";
    modifiedDatetimeText: string = "";
    subCategoryList: Array<FssStoreCategory> = [];

    constructor(storeId?: string, parentCategoryId?: string) {
        if (storeId) {
            this.storeId = storeId;
        }
        if (parentCategoryId) {
            this.parentCategoryId = parentCategoryId;
        }
    }
}
