import * as React from "react";
import FtToast from "../ui/FtToast";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import {Typography} from "@material-ui/core";
import FtRow from "../ui/FtRow";
import FssMarketingEventShipSumOrder from "../model/FssMarketingEventShipSumOrder";
import FssOrderItem from "../model/FssOrderItem";

interface FssShippedSumOrderPrintProp {
    order: FssMarketingEventShipSumOrder;
}

class FssShippedSumOrderPrintState {
    iframList: Array<HTMLIFrameElement | null> = new Array<HTMLIFrameElement>();
}

export default class FssShippedSumOrderPrint extends React.Component<FssShippedSumOrderPrintProp, FssShippedSumOrderPrintState> {
    constructor(props: any) {
        super(props);
        this.state = {iframList: new Array<HTMLIFrameElement>()}
    }

    print() {
        FtToast.showToastLoading(i18n("Printing, please wait "));
        this.state.iframList.forEach((iframe: HTMLIFrameElement | null, index: number) => {
            if (iframe && iframe.contentWindow) {
                if (iframe.contentWindow.document.body)
                    iframe.contentWindow.document.body.innerHTML = "";
                if (iframe.children && iframe.children.length > 0) {
                    const item = iframe.children.item(0);
                    if (item != null) {
                        iframe.contentWindow.document.write(item.innerHTML);
                        window.setTimeout(() => {
                            if (iframe && iframe.contentWindow)
                                iframe.contentWindow.print();
                        }, 1000);
                    }
                }

            }
        });
    }

    render() {
        let total: number = 0;
        if (this.props.order != null && this.props.order.orderItemList != null && this.props.order.orderItemList.length > 0) {
            this.props.order.orderItemList.forEach((item => {
                total += item.price * item.quantity;
            }))
        }
        return <div style={{height: 0}}>

            <iframe key={1} style={{width: 0, height: 0, margin: 0, padding: 0, border: 0}} ref={(element) => {
                this.state.iframList[1] = element
            }}>
                <html>
                <head>
                </head>
                <body style={{fontSize: 10, margin: 0, padding: 8}}>
                <div>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={8}
                           cellHorizontalAlign={"left"}>
                        <Typography>{"发货时间：" + this.props.order.scheduleDatetime}</Typography>
                        <Typography>{"团购标题：" + this.props.order.marketingEventName}</Typography>
                        <FtRow cellWidthS={"1,1"} cellVerticalSpace={8}>
                            <Typography>{"发货总量：" + this.props.order.totalQuantity}</Typography>
                            <Typography>{"总金额：" + FtUtil.toCurrencyText(total)}</Typography>
                        </FtRow>
                    </FtRow>
                    <table style={{width: "100%", fontSize: 10, border: "1px solid black", borderCollapse: "collapse"}}>
                        <thead>
                        <tr>
                            <th align="left" style={{width: "10%"}}>{i18n("数量")}</th>
                            <th align="left" style={{width: "80%"}}>{i18n("产品名称")}</th>
                            <th align="left" style={{width: "10%"}}>{i18n("单价")}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.order.orderItemList != null && this.props.order.orderItemList.map((orderItem: FssOrderItem, itemIndex: number) => {
                            if (orderItem.productName)
                                return <tr key={"item" + itemIndex}>
                                    <td align="left" style={{borderTop: "1px solid black"}}>{orderItem.quantity}</td>
                                    <td align="left" style={{borderTop: "1px solid black"}}
                                        dangerouslySetInnerHTML={{__html: orderItem.productName}}></td>
                                    <td align="left"
                                        style={{borderTop: "1px solid black"}}>{FtUtil.toCurrencyText(orderItem.price)}</td>
                                </tr>;
                            else
                                return null;
                        })}
                        </tbody>
                    </table>
                    <table style={{pageBreakAfter: "always"}}>
                    </table>
                </div>
                </body>
                </html>
            </iframe>
        </div>;
    }
}
