
export enum EOAuthAction {
    REDIRECT = "REDIRECT",
    SCAN_QRCODE = "SCAN_QRCODE",
    NONE = "NONE",
    INVOKE_APP_SDK = "INVOKE_APP_SDK"
}

export default class FtOAuthData{
    redirectUrl?:string;
    qrcodeImageBase64?:string;
    action:EOAuthAction=EOAuthAction.NONE;
}
