import * as React from "react";
import {ChangeEvent} from "react";
import {Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {RouteComponentProps} from "react-router-dom";
import JtTextField from "../ui/JtTextField";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtPanel from "../ui/FtPanel";
import {i18n} from "../util/I18n";
import {Save} from "@material-ui/icons";
import {ReactNode} from "react";
import FssRegionService from "../service/FssRegionService";
import ChipInput from "material-ui-chip-input";
import lodash from "lodash";
import FssRegion, {EStatus, EType} from "../model/FssRegion";
import FtPage from "../ui/FtPage";
import FssClientData from "../data/FssClientData";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import FssCountryInfoService from "../service/FssCountryInfoService";
import FssCountryInfo from "../model/FssCountryInfo";

interface FssRegionDetailPageProp extends RouteComponentProps<{ regionId: string }> {
}

export class FssRegionDetailPageState {
    region: FssRegion = new FssRegion();
    provinceAll: boolean = false;
    cityAll: boolean = false;
    countryList: Array<FssCountryInfo> = [];
}


export default class FssRegionDetailPage extends React.Component<FssRegionDetailPageProp, FssRegionDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {region: new FssRegion(), provinceAll: false, cityAll: false, countryList: []};
        this.onChange = this.onChange.bind(this);
        this.onChangeProvince = this.onChangeProvince.bind(this);
        this.onChangeCity = this.onChangeCity.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.getCountryList = this.getCountryList.bind(this);
    }

    componentDidMount(): void {
        this.load();
        this.getCountryList();

    }

    getCountryList() {
        FssCountryInfoService.getAvailableList((data: Array<FssCountryInfo>) => {
            this.setState({countryList: data});
        });
    }

    load() {
        if (this.props.match.params.regionId != null && this.props.match.params.regionId != "0") {
            FssRegionService.getById(this.props.match.params.regionId, (result: FssRegion) => {
                const region: FssRegion = FtUtil.mergeObject(new FssRegion(), result);
                let provinceAll = false;
                let privince = lodash.find(region.provinceList, value => {
                    return value == "ALL";
                })
                if (privince) {
                    provinceAll = true;
                }
                let cityAll = false;
                let city = lodash.find(region.cityList, value => {
                    return value == "ALL";
                })
                if (city) {
                    cityAll = true;
                }
                this.setState({
                    region: region,
                    provinceAll: provinceAll,
                    cityAll: cityAll
                });
            });
        }
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {

        FtUtil.setProperty(this.state.region, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeProvince(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.type == "checkbox") {
            this.state.region.provinceList = new Array<string>();
            if (event.target.checked) {
                this.state.region.provinceList.push("ALL");

            }
            this.setState({provinceAll: event.target.checked});
            this.forceUpdate();
        }
    }

    onChangeCity(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.type == "checkbox") {
            this.state.region.cityList = new Array<string>();
            if (event.target.checked) {
                this.state.region.cityList.push("ALL");

            }
            this.setState({cityAll: event.target.checked});
            this.forceUpdate();
        }
    }

    onClickSave() {
        if (this.state.region.regionCode == "") {
            FtSnackbar.showText(i18n("请填写『编码』"));
            return;
        }
        if (this.state.region.regionName == "") {
            FtSnackbar.showText(i18n("请填写『名称』"));
            return;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        FssRegionService.save(this.state.region,
            (data: FssRegion) => {
                FtToast.closeToast();
                this.setState({region: FtUtil.mergeObject(this.state.region, data)});
                FtSnackbar.showText(i18n("保存成功！"));
                this.onCancelClick();
            });
    }

    render() {
        return <div>
            <FtPage onCancelClick={this.onCancelClick}
                    toolbarLeftNode={<Button variant="contained"
                                             color="primary"
                                             startIcon={<Save/>}
                                             onClick={this.onClickSave}>{i18n("保存")}</Button>}>
                <FtPanel panelHeader={"基本信息"}>
                    <FtGrid>
                        <div style={{width: '100%'}}/>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1,1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <JtTextField id="regionName" label={i18n("地区名称")}
                                         value={this.state.region.regionName}
                                         style={{width: '100%'}} onChange={this.onChange}
                                         endAdornment={this.getTranslationIcon("regionName", this.state.region.regionName)}/>

                            <JtTextField id="regionCode" label={i18n("地区编码")} helperText={"英文,数字或符号"}
                                         value={this.state.region.regionCode}
                                         style={{width: '100%'}} onChange={this.onChange} fullWidth/>

                            <FormControl style={{width: '100%'}}>
                                <InputLabel>{i18n("类型")}</InputLabel>
                                <Select
                                    id="type" value={this.state.region.type}
                                    onChange={(event, child) => {
                                        this.state.region.type = event.target.value as EType;
                                        this.forceUpdate();
                                    }}>
                                    <MenuItem value={EType.REGION}>{i18n("行政区范围")}</MenuItem>
                                    <MenuItem value={EType.POSTAL}>{i18n("邮编范围")}</MenuItem>
                                    <MenuItem value={EType.BOUNDARY}>{i18n("经纬度范围")}</MenuItem>
                                    <MenuItem value={EType.DISTANCE}>{i18n("距离范围")}</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel> {i18n("状态")} </InputLabel>
                                <Select id="status"
                                        value={this.state.region.status}
                                        onChange={(event, child) => {
                                            this.state.region.status = event.target.value as EStatus;
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={EStatus.NORMAL}>{i18n("正常")}</MenuItem>
                                    <MenuItem value={EStatus.STOP}>{i18n("停用")}</MenuItem>
                                </Select>
                            </FormControl>
                        </FtRow>
                        {this.state.region.type == EType.REGION &&
                        <div>
                            <FtRow cellWidthS={"1"}>
                                <TextField select
                                           value={this.state.region.countyList.length > 0 ? this.state.region.countyList[0] : ""}
                                           id={"shipToCountry"}
                                           label={i18n("国家")}
                                           onChange={(event: any) => {
                                               this.state.region.countyList = [];
                                               this.state.region.countyList.push(event.target.value);
                                               this.forceUpdate();
                                           }} style={{width: 200, textAlign: "left"}}>
                                    {this.state.countryList != null && this.state.countryList.map((country: FssCountryInfo) => {
                                        return <MenuItem value={country.countryName} key={country.countryName}>{country.countryName}</MenuItem>
                                    })}
                                </TextField>
                            </FtRow>
                            <FtRow justifyContent={"flex-start"}>
                                <Checkbox id="" color="primary" style={{marginLeft: -10}}
                                          checked={this.state.provinceAll}
                                          onChange={this.onChangeProvince}/>
                                <ChipInput label={i18n("省/州")}
                                           variant={"standard"}
                                           disabled={this.state.provinceAll}
                                           placeholder={i18n("请输入标签")}
                                           alwaysShowPlaceholder={false}
                                           value={this.state.region.provinceList}
                                           onAdd={(chip) => {
                                               let index = this.state.region.provinceList.indexOf(chip);
                                               if (index == -1) {
                                                   if (lodash.toLower(chip) == "all") {
                                                       chip = "ALL";
                                                       this.state.region.provinceList = new Array<string>();
                                                       this.setState({provinceAll: true});
                                                   }
                                                   this.state.region.provinceList.push(chip);
                                                   this.forceUpdate();
                                               }
                                           }}
                                           onDelete={(chip) => {
                                               let index = this.state.region.provinceList.indexOf(chip);
                                               if (index > -1) {
                                                   this.state.region.provinceList.splice(index, 1)
                                                   this.forceUpdate();
                                               }
                                           }}
                                           style={{width: "100%"}}/>

                            </FtRow>
                            <FtRow justifyContent={"flex-start"}>
                                <Checkbox id="" color="primary" style={{marginLeft: -10}}
                                          checked={this.state.cityAll}
                                          onChange={this.onChangeCity}/>
                                <ChipInput label={i18n("城市/镇")}
                                           variant={"standard"}
                                           disabled={this.state.cityAll}
                                           placeholder={i18n("请输入标签")}
                                           alwaysShowPlaceholder={false}
                                           value={this.state.region.cityList}
                                           onAdd={(chip) => {
                                               let index = this.state.region.cityList.indexOf(chip);
                                               if (index == -1) {
                                                   if (lodash.toLower(chip) == "all") {
                                                       chip = "ALL";
                                                       this.state.region.cityList = new Array<string>();
                                                       this.setState({cityAll: true});
                                                   }
                                                   this.state.region.cityList.push(chip);
                                                   this.forceUpdate();
                                               }
                                           }}
                                           onDelete={(chip) => {
                                               let index = this.state.region.cityList.indexOf(chip);
                                               if (index > -1) {
                                                   this.state.region.cityList.splice(index, 1)
                                                   this.forceUpdate();
                                               }
                                           }}
                                           style={{width: "100%"}}/>
                            </FtRow>
                        </div>
                        }
                        {this.state.region.type == EType.POSTAL &&
                        <div>
                            <FtRow cellWidthS={"1"}>
                                <ChipInput label={i18n("邮编")}
                                           variant={"standard"}
                                           placeholder={i18n("请输入标签")}
                                           helperText={"*表示通配符，例如：V6P***表示所有V6P开头的6位邮编，945**表示所有945开头的5位邮编"}
                                           alwaysShowPlaceholder={false}
                                           value={this.state.region.postalCodeList}
                                           onAdd={(chip) => {
                                               let index = this.state.region.postalCodeList.indexOf(chip);
                                               if (index == -1) {
                                                   this.state.region.postalCodeList.push(chip);
                                                   this.forceUpdate();
                                               }
                                           }}
                                           onDelete={(chip) => {
                                               let index = this.state.region.postalCodeList.indexOf(chip);
                                               if (index > -1) {
                                                   this.state.region.postalCodeList.splice(index, 1)
                                                   this.forceUpdate();
                                               }
                                           }}
                                           style={{width: "100%"}}/>
                            </FtRow>
                            <br/>
                        </div>
                        }
                        {this.state.region.type == EType.BOUNDARY &&
                        <div>
                            <FtRow cellWidthS={"1"}>
                                <ChipInput label={i18n("经纬度范围")}
                                           variant={"standard"}
                                           placeholder={i18n("请输入标签")}
                                           alwaysShowPlaceholder={false}
                                           value={this.state.region.boundaryList}
                                           onAdd={(chip) => {
                                               let index = this.state.region.boundaryList.indexOf(chip);
                                               if (index == -1) {
                                                   this.state.region.boundaryList.push(chip);
                                                   this.forceUpdate();
                                               }
                                           }}
                                           onDelete={(chip) => {
                                               let index = this.state.region.boundaryList.indexOf(chip);
                                               if (index > -1) {
                                                   this.state.region.boundaryList.splice(index, 1)
                                                   this.forceUpdate();
                                               }
                                           }}
                                           style={{width: "100%"}}/>
                            </FtRow>
                            <br/>
                        </div>
                        }
                        {this.state.region.type == EType.DISTANCE &&
                        <div>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,4"} cellHorizontalSpace={32} maxRowWidth={"l"}
                                   cellVerticalSpace={16}>
                                <JtTextField id="distance" label={i18n("距离")} helperText={""}
                                             value={this.state.region.distance}
                                             style={{width: '100%'}} onChange={this.onChange} fullWidth
                                             endAdornment={"m"}/>
                                <JtTextField id="centerAddress" label={i18n("中心点地址")} helperText={""}
                                             value={this.state.region.centerAddress}
                                             style={{width: '100%'}} onChange={this.onChange} fullWidth/>
                            </FtRow>
                        </div>
                        }
                    </FtGrid>
                </FtPanel>
            </FtPage>
        </div>;

    }

    getTranslationIcon(itemKey: string, defaultText?: string, rows?: number): ReactNode {
        if (this.state.region && this.state.region.id != "0") {
            const appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite.supportLanguages != null && appWebsite.supportLanguages.length > 0) {
                return <FssTranslationDialogComp objectId={this.state.region.id}
                                                 groupKey={"fssRegion"} itemKey={itemKey}
                                                 rows={rows}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
