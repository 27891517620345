import * as React from "react";
import { ChangeEvent, ReactNode } from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import { Button, TextField } from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import FtDataTable, { FtDataTableAdapter, FtDataTableColumnProps } from "../ui/FtDataTable";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { Link } from "react-router-dom";
import FtComponent, { FtComponentState } from "../ui/FtComponent";
import FtState from "../ui/FtState";
import FtSnackbar from "../ui/FtSnackbar";
import FtMessageDialog from "../ui/FtMessageDialog";
import {i18n} from "../util/I18n";
import FssRegion, {EStatus, EType} from "../model/FssRegion";
import FtTextFieldString from "../ui/FtTextFieldString";
import FssRegionService from "../service/FssRegionService";
import FtRow from "../ui/FtRow";

export interface FssRegionListPageProp {
}

class FssRegionListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class JtTableAdapter extends FtDataTableAdapter<FssRegion> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssRegionService.getPageRegionList(this.keyword, this.filterColumns, null, null, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssRegion>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}
export default class FssRegionListPage extends FtComponent<FssRegionListPageProp, FssRegionListPageState> {


    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = new Array();
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("regionName", i18n("模板名称"));
        column2.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const fssRegion: FssRegion = rowData as FssRegion;
            return <Link to={"/fssRegionDetailPage/" + fssRegion.id}>{fssRegion.regionName}</Link>
        };
        columnProps.push(column2);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("regionCode", i18n("编码"));
        columnProps.push(column1);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("typeText", i18n("类型"));
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("statusText", i18n("状态"));
        columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("county", i18n("国家"));
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("province", i18n("省份"));
        columnProps.push(column7);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("city", i18n("收件人市"));
        columnProps.push(column8);
        const column9: FtDataTableColumnProps = new FtDataTableColumnProps("postalCode", i18n("邮编"));
        columnProps.push(column9);



        let tableAdapter: JtTableAdapter;
        const cacheState: FssRegionListPageState = FtState.pop("FssRegionListPageState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });

        this.state = { tableAdapter: tableAdapter, columnProps: columnProps, stateKey: "FssRegionListPageState" };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this)

    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("服务地区")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                toolbarWithSelect={this.getToolbarWithSelect()}
                toolbarNoSelect={this.getToolbarNoSelect()}
                filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }


    private onClickDelete() {
        const rows = this.state.tableAdapter.selectedRow;
        if (rows != null && rows.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除选定的{0}条记录吗？",rows.length+""), () => {
                const ids: Array<string> = [];
                rows.forEach((value: FssRegion, index: number) => {
                    ids.push(value.id);
                });
                FssRegionService.delete(ids, (data: FssRegion) => {
                    FtSnackbar.showText(i18n("删除成功！"));
                    this.state.tableAdapter.loadData();
                });
            });
        }
    }
    getFilterPanel(): ReactNode {
        return <FtRow>
            <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
                this.state.tableAdapter.keyword = value ? value : "";
                this.forceUpdate();
            }} value={this.state.tableAdapter.keyword} style={{width: 200}}/>


            <FormControl style={{ minWidth: 150 }}>
                <InputLabel> {i18n("状态")} </InputLabel>
                <Select id="status" value={this.state.tableAdapter.getFilterColumnValue("status")}
                    onChange={(event: any) => {
                        this.state.tableAdapter.filterColumns.set("status", event.target.value);
                        this.forceUpdate();
                    }}>
                    <MenuItem value={EStatus.NORMAL}>{i18n("正常")}</MenuItem>
                    <MenuItem value={EStatus.STOP}>{i18n("停用")}</MenuItem>
                </Select>
            </FormControl>

            <FormControl style={{ minWidth: 150 }}>
                <InputLabel>{i18n("配送类型")}</InputLabel>
                <Select
                    id="deliveryType" value={this.state.tableAdapter.getFilterColumnValue("type")}
                    onChange={(event: any) => {
                        this.state.tableAdapter.filterColumns.set("type", event.target.value);
                        this.forceUpdate();
                    }}>
                    <MenuItem value={EType.REGION}>{i18n("行政区范围")}</MenuItem>
                    <MenuItem value={EType.POSTAL}>{i18n("邮编范围")}</MenuItem>
                    <MenuItem value={EType.BOUNDARY}>{i18n("经纬度范围")}</MenuItem>
                    <MenuItem value={EType.DISTANCE}>{i18n("距离范围")}</MenuItem>

                </Select>
            </FormControl>
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/fssRegionDetailPage/0"}>
                <Button>
                    <Add />{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }
    getToolbarWithSelect(): ReactNode {
        return <div>
            <Button onClick={this.onClickDelete}>
                {i18n("删除")}
            </Button>
        </div>;
    }
}
