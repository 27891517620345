import * as React from "react";
import {ReactNode} from "react";
import {Button} from "@material-ui/core";
import {Add} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import {Link} from "react-router-dom";
import FtToast from "../ui/FtToast";
import FssAdvert from "../model/FssAdvert";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtPagedList from "../model/FtPagedList";
import {i18n} from "../util/I18n";
import FtRow from "../ui/FtRow";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtSnackbar from "../ui/FtSnackbar";
import FssAdvertService from "../service/FssAdvertService";

export interface JtShowSpotListPageProp {
}

class JtShowSpotListPageState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = new Array();
}

class JtTableAdapter extends FtDataTableAdapter<FssAdvert> {

    loadData(): void {
        FtToast.showToastLoading("loading");
        FssAdvertService.getShowSpotList(this.keyword, this.filterColumns, this.sortColumn ? this.sortColumn.propertyName : null,
            this.sortDirection, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssAdvert>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssShowSpotListPage extends React.Component<JtShowSpotListPageProp, JtShowSpotListPageState> {
    constructor(props: any) {
        super(props);
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.getToolbarNoSelect = this.getToolbarNoSelect.bind(this);
        this.getToolbarWithSelect = this.getToolbarWithSelect.bind(this);
        this.onClickDelete = this.onClickDelete.bind(this);

        const tableAdapter = new JtTableAdapter();
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("position", i18n("位置"));
        column1.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const advert: FssAdvert = rowData as FssAdvert;
            return <Link to={"/showSpot/detail/" + advert.id}>{advert.position}</Link>
        };
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("advertStatus", i18n("状态"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("product.title", i18n("商品"));
        column3.renderCell = (rowData: object, rowIndex: number, columnValue: any) => {
            const advert: FssAdvert = rowData as FssAdvert;
            if (advert != null && advert.product != null) {
                return <Link to={"/productDetail/" + advert.productId} target={"_blank"}>{advert.product.title}</Link>;
            } else if (advert != null && advert.imageUrl != null && advert.imageUrl.length > 0){
                return <img src={advert.imageUrl} style={{height: 60}} />;
            } else {
                return null;
            }
        };
        columnProps.push(column3);
        this.state = {tableAdapter: tableAdapter, columnProps: columnProps};
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("广告位列表")}
                         columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         toolbarWithSelect={this.getToolbarWithSelect()}
                         toolbarNoSelect={this.getToolbarNoSelect()}
                         filterPanel={this.getFilterPanel()}>
            </FtDataTable>
        </div>;
    }

    getFilterPanel(): ReactNode {
        return <FtRow style={{padding: "0px 16px"}} cellHorizontalSpace={16}>
            <FormControl style={{minWidth: 200}}>
                <InputLabel> {i18n("位置")} </InputLabel>
                <Select id="position" value={this.state.tableAdapter.filterColumns.get("position") ? this.state.tableAdapter.filterColumns.get("position") : ""}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("position", event.target.value);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={""} />
                    <MenuItem value={"HOME_LOGO"}>{i18n("首页顶部LOGO")}</MenuItem>
                    <MenuItem value={"HOME_TOP_BANNER"}>{i18n("首页顶部横幅广告")}</MenuItem>
                    <MenuItem value={"HOME_BANNER"}>{i18n("首页轮播广告")}</MenuItem>
                    <MenuItem value={"HOME_SECTION"}>{i18n("首页横幅广告")}</MenuItem>
                    <MenuItem value={"HOME_RECOMMEND_PRODUCT"}>{i18n("首页推荐商品")}</MenuItem>
                    <MenuItem value={"LOGIN_BANNER"}>{i18n("登陆页横幅广告")}</MenuItem>
                    <MenuItem value={"ORDER_PRINT_HEADER_HTML"}>{i18n("订单打印头部HTML片段")}</MenuItem>
                    <MenuItem value={"ORDER_PRINT_FOOTER_HTML"}>{i18n("订单打印底部HTML片段")}</MenuItem>
                    <MenuItem value={"HOME_IMAGE_DIALOG"}>{i18n("首页图片弹窗广告")}</MenuItem>
                </Select>
            </FormControl>
            <FormControl style={{minWidth: 150}}>
                <InputLabel> {i18n("状态")} </InputLabel>
                <Select id="advertStatus" value={this.state.tableAdapter.filterColumns.get("advertStatus") ? this.state.tableAdapter.filterColumns.get("advertStatus"): ""}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("advertStatus", event.target.value);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={"L"} key={""} />
                    <MenuItem value={"NORMAL"} key={"NORMAL"}>{i18n("启用")}</MenuItem>
                    <MenuItem value={"STOP"} key={"STOP"}>{i18n("禁用")}</MenuItem>
                </Select>
            </FormControl>
        </FtRow>;
    }

    getToolbarNoSelect(): ReactNode {
        return <div>
            <Link to={"/showSpot/detail/0"}>
                <Button>
                    <Add/>{i18n("新增")}
                </Button>
            </Link>
        </div>;
    }

    getToolbarWithSelect(): ReactNode {
        return <div><Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={this.onClickDelete}>
                <DeleteIcon/>
            </IconButton>
        </Tooltip>
        </div>;
    }

    onClickDelete() {
        if (this.state.tableAdapter.selectedRow.length > 0) {
            FtMessageDialog.dialog.openDialog(i18n("确定要删除{0}个记录吗？",this.state.tableAdapter.selectedRow.length+""), () => {
                    FtMessageDialog.dialog.closeDialog();
                    const idList: Array<string> = [];
                    this.state.tableAdapter.selectedRow.forEach((rowData: any, index: number) => {
                        idList.push((rowData as FssAdvert).id);
                    });
                    FssAdvertService.deleteShowSpot(idList, (data: String) => {
                        FtSnackbar.showText("删除成功");
                        this.state.tableAdapter.selectedRow = new Array<any>();
                        this.state.tableAdapter.loadData();
                    });
                },
                () => {
                    FtMessageDialog.dialog.closeDialog();
                }
            )
        }
    }
}
