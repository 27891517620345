import * as React from "react";
import {ChangeEvent} from "react";
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import {RouteComponentProps} from "react-router-dom";
import _ from "lodash";
import {Save} from "@material-ui/icons";
import {ReactNode} from "react";
import FssTaxName, {ETaxHowToCompute, ETaxNameStatus} from "../model/FssTaxName";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import FtToast from "../ui/FtToast";
import FtPage from "../ui/FtPage";
import FtSnackbar from "../ui/FtSnackbar";
import FtRow from "../ui/FtRow";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import JtTextField from "../ui/JtTextField";
import FtRegion from "../model/FtRegion";
import FssTaxRate from "../model/FssTaxRate";
import FtTextFieldPercent from "../ui/FtTextFieldPercent";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import FssClientData from "../data/FssClientData";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import FssTaxNameService from "../service/FssTaxNameService";
import FssCityService from "../service/FssCityService";
import lodash from "lodash";

interface JtTaxSettingDetailPageProp extends RouteComponentProps<{ taxNameId: string }> {
}

export class JtTaxSettingDetailPageState {
    fssTaxName: FssTaxName = new FssTaxName();
    //fssRegion: FtRegion = new FtRegion();
    countryList: Array<FtRegion> = new Array<FtRegion>();
}


export default class JtTaxSettingDetailPageDetailPage extends React.Component<JtTaxSettingDetailPageProp, JtTaxSettingDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {fssTaxName: new FssTaxName(),  countryList: [],};
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
    }

    componentDidMount(): void {
        this.load();
        this.state.fssTaxName.country = "CANADA";
    }

    load() {
        if (this.props.match.params.taxNameId != null && this.props.match.params.taxNameId != "0") {
            FssTaxNameService.getTaxNameById(this.props.match.params.taxNameId, (result: FssTaxName) => {
                const fssTaxName: FssTaxName = FtUtil.mergeObject(new FssTaxName(), result);
                this.setState({fssTaxName: fssTaxName});
            });
        }
        // FssCityService.getCanadaRegion((result: FtRegion) => {
        //     FtToast.closeToast();
        //     this.state.countryList.push(result);
        //     this.forceUpdate();
        // });
        // FssCityService.getAmericaRegion((result: FtRegion) => {
        //     FtToast.closeToast();
        //     this.state.countryList.push(result);
        //     this.forceUpdate();
        // });
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.fssTaxName, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeTaxRate(index: number, value?: number) {
        if(this.state.fssTaxName.taxRateList){
            if (value) {
                this.state.fssTaxName.taxRateList[index].taxRate = value;
            } else {
                this.state.fssTaxName.taxRateList[index].taxRate = 0;
            }
        }
        this.forceUpdate();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssTaxNameService.saveTaxName(this.state.fssTaxName,
            (data: FssTaxName) => {
                this.setState({fssTaxName: FtUtil.mergeObject(this.state.fssTaxName, data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功"));
                this.onCancelClick();
            });

    }

    render() {
        const provinceList: Array<FtRegion> = new Array<FtRegion>();
        if(this.state.fssTaxName.country!=null){
            const country = lodash.find(this.state.countryList, {'code': this.state.fssTaxName.country});
            if(country&&country.subItems&&country.subItems.length>0) {
                country.subItems.map((region: FtRegion) => {
                    provinceList.push(region);
                })
            }
        }
        return <FtPage onCancelClick={this.onCancelClick}
                                  toolbarLeftNode={<Button variant="contained"
                                                           color="primary"
                                                           startIcon={<Save />}
                                                           onClick={this.onClickSave}>{i18n("保存")}</Button>}>
            <FtRow cellHorizontalAlign={"left"} cellVerticalAlign={"top"} cellWidthS={"1"} cellWidthM={"1,2"}
                   style={{maxWidth: 1120}}>
                <div style={{}}>
                    <FtPanel panelHeader={i18n("基本信息")} marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <div style={{width: '100%'}}></div>
                            <FtRow cellWidthS={"1"} cellWidthM={"1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                                   cellVerticalSpace={16}>
                                <TextField disabled id="taxNameId" label="ID"
                                             value={this.state.fssTaxName.id}
                                             onChange={this.onChange} fullWidth/>
                                <JtTextField id="taxName" label={i18n("税名称")} value={this.state.fssTaxName.taxName}
                                             onChange={this.onChange} fullWidth
                                             endAdornment={this.getTranslationIcon("taxName", this.state.fssTaxName.taxName)}/>
                                <JtTextField id="taxShortName" label={i18n("缩写")} value={this.state.fssTaxName.taxShortName}
                                             onChange={this.onChange} fullWidth/>
                                <FtRow cellWidthS={"1"} cellWidthM={"1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                                       cellVerticalSpace={16}>
                                    <FormControl style={{width: '100%'}}>
                                        <InputLabel> {i18n("状态")} </InputLabel>
                                        <Select id="taxNameStatus" value={this.state.fssTaxName.taxNameStatus}
                                                onChange={(event, child) => {
                                                    this.state.fssTaxName.taxNameStatus = event.target.value as ETaxNameStatus;
                                                    this.forceUpdate();
                                                }}>
                                            <MenuItem value={ETaxNameStatus.NORMAL}>{i18n("正常")}</MenuItem>
                                            <MenuItem value={ETaxNameStatus.STOP}>{i18n("停用")}</MenuItem>
                                            {/* <MenuItem value={EDeliveryProviderStatus.DELETED}>删除</MenuItem> */}
                                        </Select>
                                    </FormControl>
                                </FtRow>
                                <FtRow cellWidthS={"1"} cellWidthM={"1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                                       cellVerticalSpace={16}>
                                    <FormControl style={{width: '100%'}}>
                                        <InputLabel> {i18n("计算方法")} </InputLabel>
                                        <Select id="howToCompute" value={this.state.fssTaxName.howToCompute}
                                                onChange={(event, child) => {
                                                    this.state.fssTaxName.howToCompute = event.target.value as ETaxHowToCompute;
                                                    this.forceUpdate();
                                                }}>
                                            <MenuItem value={ETaxHowToCompute.PERCENTAGE}>{i18n("按金额百分比")}</MenuItem>
                                            <MenuItem value={ETaxHowToCompute.FIXED_PER_ITEM}>{i18n("按数量")}</MenuItem>
                                        </Select>
                                    </FormControl>
                                </FtRow>
                                <TextField select value={this.state.fssTaxName.country} id={"shipToCountry"}
                                           label={i18n("国家")}
                                           onChange={(event: any) => {
                                               this.state.fssTaxName.country=event.target.value;
                                               this.forceUpdate();
                                           }} style={{width: 200, textAlign: "left"}}>
                                    <MenuItem value={"US"} key={"US"}>{i18n("美国")}</MenuItem>
                                    <MenuItem value={"CANADA"} key={"CANADA"}>{i18n("加拿大")}</MenuItem>
                                </TextField>
                                <JtTextField id="description" label={i18n("描述")} value={this.state.fssTaxName.description}
                                             onChange={this.onChange} fullWidth
                                             endAdornment={this.getTranslationIcon("description", this.state.fssTaxName.description)}/>
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                </div>
                <div style={{}}>
                    <FtPanel panelHeader={i18n("税率")} marginHorizontal={16} marginVertical={16}>
                        <FtGrid>
                            <FtRow cellWidthS={"1,1,1"}>
                                {
                                    provinceList.map((item: FtRegion, index: number) => {
                                        let targetIndex = 0;
                                        if(this.state.fssTaxName.taxRateList){
                                            targetIndex = _.findIndex(this.state.fssTaxName.taxRateList, (taxRate: FssTaxRate) => {
                                                return taxRate.province == item.code;
                                            });
                                            if(targetIndex<0){
                                                let initialTaxRate = new FssTaxRate();
                                                initialTaxRate.taxRate = 0.0;
                                                initialTaxRate.province = item.code;
                                                initialTaxRate.country = this.state.fssTaxName.country;
                                                targetIndex = this.state.fssTaxName.taxRateList.push(initialTaxRate) - 1;
                                            }

                                        }else{
                                            this.state.fssTaxName.taxRateList = new Array<FssTaxRate>();
                                            let initialTaxRate = new FssTaxRate();
                                            initialTaxRate.taxRate = 0.0;
                                            initialTaxRate.province = item.code;
                                            initialTaxRate.country = this.state.fssTaxName.country;
                                            targetIndex = this.state.fssTaxName.taxRateList.push(initialTaxRate) - 1;

                                        }
                                        if (this.state.fssTaxName.howToCompute != null && this.state.fssTaxName.howToCompute == ETaxHowToCompute.PERCENTAGE)
                                            return <FtTextFieldPercent id="specificationName" label={item.name}
                                                                       value={this.state.fssTaxName.taxRateList[targetIndex].taxRate}
                                                                       placeholder={i18n("税率")+":"} type={"float"}
                                                                       onChangeValue={value => {
                                                                           this.onChangeTaxRate(targetIndex, value)
                                                                       }}
                                                                       style={{width: "100%"}}/>;
                                        else
                                            return <FtTextFieldNumber id="specificationName" label={item.name}
                                                                      value={this.state.fssTaxName.taxRateList[targetIndex].taxRate}
                                                                      placeholder={i18n("税值")+":"} type={"float"}
                                                                      onChangeValue={value => {
                                                                          this.onChangeTaxRate(targetIndex, value)
                                                                      }}
                                                                      style={{width: "100%"}}/>;
                                    })
                                }
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                </div>
            </FtRow>
        </FtPage>;
    }

    getTranslationIcon (itemKey: string, defaultText?: string): ReactNode {
        if (this.state.fssTaxName && this.state.fssTaxName.id.length > 0) {
            let website = FssClientData.getData().appWebsite;
            if (website &&  website.supportLanguages.length>0) {
                return <FssTranslationDialogComp objectId={this.state.fssTaxName.id+""}
                                                groupKey={"fssTaxName"} itemKey={itemKey}
                                                dialogTitle={"多语言编辑"} defaultText={defaultText}/>;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
