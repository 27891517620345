
import FssSellerEmployeeRole from "../model/FssSellerEmployeeRole";
import FssHttpService from "./FssHttpService";


export default class FssSellerEmployeeRoleService {
    /**
     * 查询角色列表
     * @param productCode
     * @param successCallback
     */
    static getList(successCallback: (productRoleList: Array<FssSellerEmployeeRole>) => void) {
        FssHttpService.get("/api/sellerEmployeeRole/getList", null, successCallback, FssHttpService.handleError);
    }


}
