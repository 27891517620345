import * as React from "react";
import {ChangeEvent} from "react";
import {Card, CardContent, Checkbox, FormControlLabel, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtToast from "../../ui/FtToast";
import FtSnackbar from "../../ui/FtSnackbar";
import {i18n} from "../../util/I18n";
import FtPage from "../../ui/FtPage";
import FtPanel from "../../ui/FtPanel";
import FtRow from "../../ui/FtRow";
import JtTextField from "../../ui/JtTextField";
import FtToolbar from "../../ui/FtToolbar";
import GrubmarketService from "./GrubmarketService";
import DealVo from "./DealVo";
import {grey} from "@material-ui/core/colors";

class JtLoginWithRegisterSettingPageState {
    token:string="1b9970a9-96ae-4a5c-975d-292d91dcef50";
    dealList:Array<DealVo>=[];
    checkAll:boolean=false;
}


export default class SynDataPage extends React.Component<any, JtLoginWithRegisterSettingPageState> {

    constructor(props: any) {
        super(props);
        this.state = {token:"1b9970a9-96ae-4a5c-975d-292d91dcef50",dealList:[],checkAll:false};
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickView = this.onClickView.bind(this);
    }

    componentDidMount() {

    }


    onCancelClick() {
        this.props.history.goBack();
    }

    onClickSave() {
        this.forceUpdate(() => {
            let dealIds:Array<string>=[];
            this.state.dealList.forEach(dealVo => {
                if(dealVo.checked==true){
                    dealIds.push(dealVo.id);
                }
            })
            if(dealIds.length<=0){
                FtSnackbar.showText(i18n("选择活动"));
                return;
            }
            FtToast.showToastLoading(i18n("后台正在同步,不要重复提交"));
            GrubmarketService.importData(this.state.token,dealIds,
                (data: string) => {
                    FtToast.closeToast();
                    FtSnackbar.showText(i18n(data));
                });
        });
    }
    onClickView() {
        this.forceUpdate(() => {
            FtToast.showToastLoading("loading",12000);
            GrubmarketService.viewData(this.state.token,
                (data: Array<DealVo>) => {
                    FtToast.closeToast();
                    this.setState({dealList:data});
                    this.forceUpdate();
                });
        });
    }

    render() {
        const paperStyle: any = {padding: 15, marginBottom: 15};
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarCenterNode={<Typography variant="h6">{i18n("同步旧数据")}</Typography>}>
            <FtToolbar leftNode={<div>
                <Button color="default" onClick={this.onCancelClick}>{i18n("返回")}</Button>
                {this.state.dealList && this.state.dealList.length==0 && <Button variant="contained"
                        color="primary"
                        onClick={this.onClickView}>{i18n("查看数据")}</Button>}
                {this.state.dealList && this.state.dealList.length>0 &&<Button variant="contained"
                        color="primary"
                        onClick={this.onClickSave}>{i18n("同步数据")}</Button>}
            </div>}>
            </FtToolbar>
            <FtPanel elevation={3} style={paperStyle}
                     panelHeader={""}>
                <FtRow cellWidthS={"1"}>
                    <JtTextField id="token" label={i18n("token")}
                                 value={this.state.token}
                                 style={{width: "100%"}} onChange={(event)=>{
                                     this.setState({token:event.target.value})
                                 }
                    }/>
                </FtRow>
                <Card>
                    <CardContent>
                        {this.state.dealList&&this.state.dealList.length>0 &&
                        <div>
                            <FtRow cellVerticalSpace={16}>
                                <Typography variant={"h6"} >{i18n("活动列表")}</Typography>
                            </FtRow>
                            <FtRow cellWidthS={"5,1"} cellWidthM={"5,1"} style={{backgroundColor: grey[400]}}>
                                <FormControlLabel
                                    control={<Checkbox id="checked" color="primary"
                                                       checked={this.state.checkAll}
                                                       onChange={(event: ChangeEvent<HTMLInputElement>)=>{
                                                           this.setState({checkAll:event.target.checked});
                                                           this.state.dealList.forEach(deal=>{
                                                               deal.checked=event.target.checked;
                                                           });
                                                           this.forceUpdate();
                                                       }}
                                    />}
                                    label={"活动名称"} />
                                <Typography>{i18n("商品数量")}</Typography>
                            </FtRow>
                            {this.state.dealList.map((deal:DealVo, index) => {
                                return <FtRow cellWidthS={"5,1"}  cellWidthM={"5,1"} cellWidthL={"5,1"} style={{backgroundColor: index % 2 == 0 ? "white" : grey[100]}}>
                                    <FormControlLabel
                                        control={<Checkbox id="checked" color="primary"
                                                           checked={deal.checked}
                                                           onChange={(event: ChangeEvent<HTMLInputElement>)=>{
                                                               deal.checked=event.target.checked;
                                                               this.forceUpdate();
                                                           }}
                                        />}
                                        label={deal.title} />
                                    <Typography>{deal.count}</Typography>
                                </FtRow>
                            })}
                        </div>
                        }
                    </CardContent>
                </Card>
            </FtPanel>
        </FtPage>;
    }
}
