import * as React from "react";
import {CSSProperties} from "react";
import FtTabs from "../ui/FtTabs";
import FtTabPanel from "../ui/FtTabPanel";
import {i18n} from "../util/I18n";
import {grey} from "@material-ui/core/colors";
import FssClientData from "../data/FssClientData";

export interface FssAccountTypeCompProp {
    mobileLabel: string;
    emailLabel: string;
    onChangeTabs: (tabValue: EAccountType)=>void;
}

class FssAccountTypeCompState {
    tabValue?: EAccountType;
    accountType?: EAccountType;
}

export enum EAccountType {
    ALL = "ALL",
    MOBILE = "MOBILE",
    EMAIL = "EMAIL",
}

export default class FssAccountTypeComp extends React.Component<FssAccountTypeCompProp, FssAccountTypeCompState> {
    constructor(props: any) {
        super(props);
        let accountType = this.getAccountType();
        let tabValue = EAccountType.MOBILE;
        if (accountType === EAccountType.EMAIL) {
            tabValue = EAccountType.EMAIL;
        }
        this.state = {tabValue: tabValue, accountType: accountType};
        this.props.onChangeTabs(tabValue);
    }

    render() {
        if (this.state.accountType == EAccountType.ALL) {
            return <FtTabs value={this.state.tabValue} style={{margin: "0px 16px"}}
                           indicatorColor="primary" textColor="primary"
                           onChange={(event: React.ChangeEvent<{}>, newValue: EAccountType) => {
                               this.setState({tabValue: newValue});
                               this.props.onChangeTabs(newValue);
                           }}>
                <FtTabPanel label={i18n(this.props.mobileLabel)} style={this.getTabsStyles(this.state.tabValue == EAccountType.MOBILE)} value={EAccountType.MOBILE} />
                <FtTabPanel label={i18n(this.props.emailLabel)} style={this.getTabsStyles(this.state.tabValue == EAccountType.EMAIL)} value={EAccountType.EMAIL} />
            </FtTabs>;
        } else {
            return null;
        }
    }

    getAccountType() {
        let accountType: EAccountType = EAccountType.MOBILE;
        let appWebsite = FssClientData.getData().appWebsite;
        if (appWebsite) {
            if (appWebsite.mobileFlag && appWebsite.emailFlag) {
                accountType = EAccountType.ALL;
            } else if (appWebsite.mobileFlag) {
                accountType = EAccountType.MOBILE;
            } else if (appWebsite.emailFlag) {
                accountType = EAccountType.EMAIL;
            }
        }
        return accountType;
    }

    getTabsStyles(isSelected: boolean): CSSProperties {
        if (isSelected) {
            const selectedStyles: CSSProperties = {
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                backgroundColor: "white",
                width: "50%"
            };
            return selectedStyles;
        } else {
            const noSelectedStyles: CSSProperties = {
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                backgroundColor: grey[300],
                width: "50%"
            };
            return noSelectedStyles;
        }
    }
}
