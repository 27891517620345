import {FssAbstractCompData} from "./FssAbstractCompData";
import FssProductSearchCriteria from "../../../model/FssProductSearchCriteria";

export enum EProductListDataSource {
    SEARCH = "SEARCH",
    SPECIFIED = "SPECIFIED",
    ONLYLABEL = "ONLYLABEL"
}

export enum EProductSortType {
    SPECIFIED = "SPECIFIED",
    RANDOM = "RANDOM",
    HEAT = "HEAT"
}

export default class FssProductListCompData extends FssAbstractCompData {
    productIdArray?: Array<string> = [];
    productListDataSource? = EProductListDataSource.SEARCH;
    searchCriteria?: FssProductSearchCriteria;
    mobileLineNum: number = 0;
    pcLineNum: number = 0;
    sortType: string = EProductSortType.SPECIFIED;
    href: string = "";
    showProductNum: number = 0;
    backgroundColor?: string;
    tagsList: Array<string> = [];
    title?: string;
    showMore?: boolean = false;
    moreLink?: string;
}
