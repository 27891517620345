import * as React from "react";
import {CSSProperties} from "react";
import FssAbstractCompProp from "./FssAbstractCompProp";
import FtComponent, {FtComponentState} from "../../ui/FtComponent";
import {Grid} from "@material-ui/core";
import FssLogoBannerCompData from "./data/FssLogoBannerCompData";

interface FssLogoBannerCompProp extends FssAbstractCompProp {
    editMode?: boolean;
}


export class FssLogoBannerCompState extends FtComponentState {
}


export default class FssLogoBannerComp extends FtComponent<FssLogoBannerCompProp, FssLogoBannerCompState> {

    constructor(props: any) {
        super(props);
        this.state = {stateKey: "FssLogoBannerComp" + this.props.location.pathname};
    }

    componentDidMount(): void {
    }

    render() {
        const compData = this.props.component.data as FssLogoBannerCompData;
        let headerStyle: CSSProperties = {
            width: "100%", height: 150, float: "right", top: 0, zIndex: -2
        }
        var logoElement = <div></div>
        if (compData != null) {
            if (compData.backgroundImageUrl != null && compData.backgroundImageUrl.length > 0) {
                headerStyle.backgroundImage = "url('" + compData.backgroundImageUrl + "')"
                headerStyle.backgroundSize = "cover";
                headerStyle.filter = "blur(3px)";
                headerStyle.WebkitFilter = "blur(3px)";
            }
            logoElement = <div style={{width: "100%", height: 150, position: "relative"}}>
                <Grid container justify={"center"} style={{position: "absolute", bottom: 20}}>
                    <img src={compData.logoImageUrl} style={{height: 63}}/>
                </Grid>
            </div>
        }
        return <div>
            <div style={headerStyle}>&nbsp; </div>
            {logoElement}
        </div>
    }
}
