

export default class FssProductSupplier {
    id: string = "";
    supplierName: string = "";
    createDatetimeText: string = "";
    type: EType=EType.SUPPLIER;

}
export enum EType{
    SUPPLIER = "SUPPLIER",
    PICKING_GROUP = "PICKING_GROUP",
    STORAGE_LOCATION = "STORAGE_LOCATION",
}
