import * as React from "react";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FssProduct from "../model/FssProduct";
import {RouteComponentProps} from "react-router-dom";
import FssProductDetailComp from "./FssProductDetailComp";
import FtToolbar from "../ui/FtToolbar";
import {ChevronLeft} from "@material-ui/icons";
import FtMessageDialog from "../ui/FtMessageDialog";
import {i18n} from "../util/I18n";
import FssProductService from "../service/FssProductService";
import FssProductSpecification from "../model/FssProductSpecification";

interface JtProductDetailPageProp extends RouteComponentProps<{ productId: string }> {
}

export class JtProductDetailPageState {
    fssProduct: FssProduct = new FssProduct();
}


export default class FssProductDetailPage extends React.Component<JtProductDetailPageProp, JtProductDetailPageState> {

    constructor(props: any) {
        super(props);
        let fssProduct = new FssProduct()
        fssProduct.specificationList.push(new FssProductSpecification());
        fssProduct.specificationList.push(new FssProductSpecification());
        fssProduct.specificationList.push(new FssProductSpecification());
        this.state = {fssProduct:fssProduct };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.load = this.load.bind(this);
        this.onClickCopy = this.onClickCopy.bind(this);
        this.onRefreshClick = this.onRefreshClick.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        if (this.props.match.params.productId != null && this.props.match.params.productId !="0") {
            FssProductService.getProduct(this.props.match.params.productId, (result: FssProduct) => {
                this.setState({fssProduct: FtUtil.mergeObject(new FssProduct(), result)});
            });
        }
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }
    onRefreshClick(): void {
        this.load();
        FtSnackbar.showText(i18n("刷新成功！"));
    }

    onChange(product: FssProduct) {
        this.setState({fssProduct: product});
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssProductService.saveProduct(this.state.fssProduct,
            (data: FssProduct) => {
                this.setState({fssProduct: FtUtil.mergeObject(new FssProduct(), data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
                this.props.history.replace("/productDetail/" + data.id);
            });

    }

    onClickCopy() {
            FtMessageDialog.dialog.openDialog(i18n("确定要复制当前的商品吗？"), () => {
                FtToast.showToastLoading(i18n("正在复制"));
                FssProductService.copyProduct(this.state.fssProduct.id,
                    (data: FssProduct) => {
                        this.setState({fssProduct: FtUtil.mergeObject(new FssProduct(), data)});
                        FtToast.closeToast();
                        FtSnackbar.showText(i18n("复制成功！"));
                        this.props.history.replace("/productDetail/" + data.id);
                    });
            });
    }

    render() {
        let isEdit: boolean = false;
        if (this.state.fssProduct.id != null && this.state.fssProduct.id.length != 0)
            isEdit = true;
        let isMainProduct: boolean = true;
        if (this.state.fssProduct.parentProductId != null && this.state.fssProduct.parentProductId.length > 0)
            isMainProduct = false;
        return <div>
            <FtToolbar leftNode={<div>
                           <Button onClick={this.onCancelClick}><ChevronLeft/>{i18n("返回")}</Button>
                           <Button onClick={this.onRefreshClick}>{i18n("刷新")}</Button>
                           {isMainProduct && isEdit && <Button onClick={this.onClickCopy}>{i18n("复制商品")}</Button>}
                           <Button color="primary" onClick={this.onClickSave}>{i18n("保存")}</Button>
                       </div>}
                       rightNode={<div>

                       </div>}>
            </FtToolbar>
            <div style={{overflow: "scroll", height: document.documentElement.clientHeight - 96}}>
                <FssProductDetailComp history={this.props.history}
                                      location={this.props.location}
                                      match={this.props.match} fssProduct={this.state.fssProduct} onChange={this.onChange} />
            </div>
        </div>;
    }
}
