import * as React from "react";
import {ChangeEvent} from "react";
import {FormLabel, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {RouteComponentProps} from "react-router-dom";
import {Add, ChevronLeft, Save} from "@material-ui/icons";
import FssAdvert, {EPosition} from "../model/FssAdvert";
import FssProduct, {EProductStatus} from "../model/FssProduct";
import FssUserMediaSelectDialog from "../userMedia/FssUserMediaSelectDialog";
import JtMceEditor from "../common/JtMceEditor";
import FssAdvertService from "../service/FssAdvertService";
import FtUtil from "../util/FtUtil";
import {i18n} from "../util/I18n";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import FssUserMedia from "../model/FssUserMedia";
import FssProductService from "../service/FssProductService";
import FtToolbar from "../ui/FtToolbar";
import FtRow from "../ui/FtRow";
import FtComboboxDynamic from "../ui/FtComboboxDynamic";
import FssUserMediaComp from "../userMedia/FssUserMediaComp";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import MoveProductItem from "./MoveProductItem";

interface JtShowSpotDetailPageProp extends RouteComponentProps<{ id: string }> {
}

export class JtShowSpotDetailPageState {
    advert: FssAdvert = new FssAdvert();
    productList: Array<FssProduct> = [];
    productSelectOptions: Array<FssProduct> = [];
}


export default class FssShowSpotDetailPage extends React.Component<JtShowSpotDetailPageProp, JtShowSpotDetailPageState> {
    userMediaSelectDialog!: FssUserMediaSelectDialog;
    mceEditor!: JtMceEditor;

    constructor(props: any) {
        super(props);
        const advert = new FssAdvert();
        advert.advertStatus = "NORMAL";
        this.state = {
            advert: advert,
            productList: [],
            productSelectOptions: []
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickImage = this.onClickImage.bind(this);
        this.onChangeProductInput = this.onChangeProductInput.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
    }

    componentDidMount(): void {
        this.load();
    }

    load() {
        if (this.props.match.params.id != null && this.props.match.params.id != "0") {
            FssAdvertService.getShowSpotById(this.props.match.params.id, (result: FssAdvert) => {
                const advert: FssAdvert = FtUtil.mergeObject(new FssAdvert(), result);
                let productSelectOptions = new Array<FssProduct>();
                if (advert.advertList) {
                    advert.advertList.forEach((childAdvert: FssAdvert) => {
                        if (childAdvert.product) {
                            productSelectOptions.push(childAdvert.product);
                        }
                    })
                }
                this.setState({advert: advert, productSelectOptions: productSelectOptions});
            });
        }
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.advert, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        const message: string = this.state.advert.validate();
        if (message == null || message == "") {
            FtToast.showToastLoading(i18n("正在保存"));
            if (EPosition.HOME_SECTION == this.state.advert.position && this.state.productSelectOptions.length > 0) {
                let text = "";
                this.state.productSelectOptions.forEach((product: FssProduct) => {
                    text += "," + product.id;
                });
                this.state.advert.text = text.substring(1);
                this.state.advert.productId = "";
            }
            FssAdvertService.saveShowSpot(this.state.advert, (data: FssAdvert) => {
                this.setState({advert: FtUtil.mergeObject(this.state.advert, data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功"));
                this.onCancelClick();
            });
        } else {
            FtSnackbar.showText(message);
        }
    }

    onClickImage(imageUrl?: string, id?: string) {
        this.userMediaSelectDialog.show("single", (userMediaList: Array<FssUserMedia> | null) => {
            if (userMediaList != null && userMediaList.length > 0) {
                this.state.advert.imageUrl = userMediaList[0].mediaUrl;
                this.forceUpdate();
            }
        });
    }

    onChangeProduct(item: any) {
        if (item != null) {
            this.state.advert.product = item;
            this.state.advert.productId = (item as FssProduct).id;
        } else {
            this.state.advert.product = new FssProduct();
            this.state.advert.productId = "";
        }
    }

    onChangeProductInput(keyword: string) {
        FssProductService.getAvailProductList(keyword, 5, [EProductStatus.ENABLE], (productList: Array<FssProduct>) => {
            this.setState({productList: productList});
        });
    }

    render() {
        return <div style={{display: "flex", flexDirection: "column"}}>
            <FtToolbar leftNode={<div>
                <Button color="default" onClick={this.onCancelClick}><ChevronLeft/>{i18n("返回")}</Button>
                <Button color="primary" onClick={this.onClickSave}><Save/>{i18n("保存")}</Button>
            </div>}>
            </FtToolbar>
            <FtRow>
                <FormControl style={{minWidth: 150}}>
                    <InputLabel> </InputLabel>
                    <Select id="position" value={this.state.advert.position}
                            onChange={(event, child) => {
                                this.state.advert.position = event.target.value as EPosition;
                                this.forceUpdate();
                            }}>
                        <MenuItem value={"HOME_LOGO"}>{i18n("首页顶部LOGO")}</MenuItem>
                        <MenuItem value={"HOME_TOP_BANNER"}>{i18n("首页顶部横幅广告")}</MenuItem>
                        <MenuItem value={"HOME_BANNER"}>{i18n("首页轮播广告")}</MenuItem>
                        <MenuItem value={"HOME_SECTION"}>{i18n("首页横幅广告")}</MenuItem>
                        <MenuItem value={"HOME_RECOMMEND_PRODUCT"}>{i18n("首页推荐商品")}</MenuItem>
                        <MenuItem value={"LOGIN_BANNER"}>{i18n("登陆页横幅广告")}</MenuItem>
                        <MenuItem value={"ORDER_PRINT_HEADER_HTML"}>{i18n("订单打印头部HTML片段")}</MenuItem>
                        <MenuItem value={"ORDER_PRINT_FOOTER_HTML"}>{i18n("订单打印底部HTML片段")}</MenuItem>
                        <MenuItem value={"HOME_IMAGE_DIALOG"}>{i18n("首页图片弹窗广告")}</MenuItem>
                    </Select>
                </FormControl>
                <TextField id="sortIndex" label={i18n("排序系数")} value={this.state.advert.sortIndex}
                           onChange={this.onChange} style={{width: 150}}/>
            </FtRow>
            <FtRow>
                {this.state.advert.position == "HOME_RECOMMEND_PRODUCT" &&
                <FtComboboxDynamic label={i18n("商品")} optionItems={this.state.productList}
                                   labelPropertyName={"title"}
                                   valuePropertyName={"productId"}
                                   onSelectItem={this.onChangeProduct}
                                   onChangeInput={this.onChangeProductInput} loading={false}
                                   style={{minWidth: 400}}/>}
                {(this.state.advert.position == "HOME_BANNER"
                    || this.state.advert.position == "HOME_LOGO"
                    || this.state.advert.position == "HOME_TOP_BANNER"
                    || this.state.advert.position == "LOGIN_BANNER"
                    || this.state.advert.position == "HOME_IMAGE_DIALOG")
                && <FssUserMediaComp label={i18n("广告图片")} mediaType={"image"} style={{width: 300, height: 100}}
                                     mediaUrl={this.state.advert.imageUrl}
                                     onClickRemove={() => {
                                         this.state.advert.imageUrl = "";
                                         this.forceUpdate();
                                     }}
                                     onClickImage={this.onClickImage}/>}
            </FtRow>
            <FtRow>
                {(this.state.advert.position == "HOME_BANNER"
                    || this.state.advert.position == "HOME_TOP_BANNER"
                    || this.state.advert.position == "LOGIN_BANNER"
                    || this.state.advert.position == "HOME_IMAGE_DIALOG")
                && <TextField id="href" label={i18n("目标链接 URL")} value={this.state.advert.href}
                              style={{width: 400}} onChange={this.onChange}/>}
            </FtRow>
            <FtRow>
                {this.state.advert.position == "HOME_SECTION" &&
                <FssUserMediaComp label={i18n("广告图片")} mediaType={"image"} style={{width: 300, height: 100}}
                                  mediaUrl={this.state.advert.imageUrl}
                                  onClickRemove={() => {
                                      this.state.advert.imageUrl = "";
                                      this.forceUpdate();
                                  }}
                                  onClickImage={this.onClickImage}/>}
            </FtRow>
            <FtRow>
                {this.state.advert.position == "HOME_SECTION" && <FormControl style={{minWidth: 150}}>
                    <InputLabel>{i18n("手机商品布局")}</InputLabel>
                    <Select id="linesNumber" value={this.state.advert.linesNumber}
                            onChange={(event, child) => {
                                this.state.advert.linesNumber = event.target.value as number;
                                this.forceUpdate();
                            }}>
                        <MenuItem value={1}>{i18n("每行【一件】商品")}</MenuItem>
                        <MenuItem value={2}>{i18n("每行【两件】商品")}</MenuItem>
                        <MenuItem value={3}>{i18n("每行【三件】商品")}</MenuItem>
                    </Select>
                </FormControl>}
            </FtRow>
            <FtRow>
                {this.state.advert.position == "HOME_SECTION" &&
                <TextField id="href" label={i18n("目标链接 URL")} value={this.state.advert.href}
                           style={{width: 400}} onChange={this.onChange}/>}
            </FtRow>
            <FtRow>
                {this.state.advert.position == "HOME_SECTION" &&
                <FtComboboxDynamic label={i18n("商品")} optionItems={this.state.productList}
                                   labelPropertyName={"title"}
                                   valuePropertyName={"productId"}
                                   onSelectItem={this.onChangeProduct} loading={false}
                                   onChangeInput={this.onChangeProductInput}
                                   style={{minWidth: 400}}/>}
                {this.state.advert.position == "HOME_SECTION" && <Button onClick={() => {
                    let productSelectOptions = this.state.productSelectOptions;
                    if (this.state.advert.product.title != '') {
                        let addFlag = true;
                        productSelectOptions.forEach((product: FssProduct) => {
                            if (product.id == this.state.advert.product.id) {
                                addFlag = false;
                                return;
                            }
                        });
                        if (addFlag) {
                            productSelectOptions.push(this.state.advert.product);
                            this.setState({productSelectOptions: productSelectOptions});
                        } else {
                            FtSnackbar.showText(i18n("此商品已经添加过了"));
                        }
                    }
                }} color={"primary"} variant="outlined"><Add/>{i18n("添加")}</Button>}
            </FtRow>
            <FtRow>
                {this.state.advert.position == "HOME_SECTION" && this.state.productSelectOptions.length > 0 &&
                <div>
                    <FormLabel component="legend">{i18n("已选商品")}</FormLabel>
                    <MoveProductItem productOptions={this.state.productSelectOptions}
                                     onMoveOptions={(productSelectOptions) => {
                                         this.setState({productSelectOptions: productSelectOptions});
                                         this.forceUpdate();
                                     }}/>
                </div>}
            </FtRow>
            <FtRow>
                {this.state.advert.position == "ORDER_PRINT_HEADER_HTML" &&
                <FtPanel panelHeader={i18n("订单头部HTML")} marginVertical={8}>
                    <FtGrid>
                        <FtRow cellWidthM={"1"}>
                            <JtMceEditor ref={(element: any) => {
                                this.mceEditor = element;
                            }}
                                         onChangeContent={(content: string) => {
                                             this.state.advert.text = content;
                                             this.forceUpdate();
                                         }}
                                         initialValue={this.state.advert.text}
                                         height={(document.documentElement.clientHeight - 320) + "px"} width={"100%"}/>
                        </FtRow>
                    </FtGrid>
                </FtPanel>}
                {this.state.advert.position == "ORDER_PRINT_FOOTER_HTML" &&
                <FtPanel panelHeader={i18n("订单底部HTML")} marginVertical={8}>
                    <FtGrid>
                        <FtRow cellWidthM={"1"}>
                            <JtMceEditor ref={(element: any) => {
                                this.mceEditor = element;
                            }}
                                         onChangeContent={(content: string) => {
                                             this.state.advert.text = content;
                                             this.forceUpdate();
                                         }}
                                         initialValue={this.state.advert.text}
                                         height={(document.documentElement.clientHeight - 320) + "px"} width={"100%"}/>
                        </FtRow>
                    </FtGrid>
                </FtPanel>}
            </FtRow>
            <FssUserMediaSelectDialog ref={(element: any) => {
                this.userMediaSelectDialog = element
            }}/>
        </div>;
    }
}
