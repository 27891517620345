import * as React from "react";
import {ReactNode} from "react";
import {IconButton, List, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import FtAppBar from "../ui/FtAppBar";
import {ChevronLeft} from "@material-ui/icons";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtPanel from "../ui/FtPanel";
import {i18n} from "../util/I18n";
import {grey} from "@material-ui/core/colors";
import AppClientInfo from "../data/AppClientInfo";
import FssAppWebsiteService from "../service/FssAppWebsiteService";
import FssClientData from "../data/FssClientData";
import FssSellerService from "../service/FssSellerService";
import FssSellerEmployee from "../model/FssSellerEmployee";

interface FssSelectSellerDialogProp {
}

export class FssSelectSellerDialogState {
    open: boolean = false;
    sellerEmployeeList: Array<FssSellerEmployee> = [];
}


export default class FssSelectSellerDialog extends React.Component<FssSelectSellerDialogProp, FssSelectSellerDialogState> {
    static dialog: FssSelectSellerDialog;

    constructor(props: any) {
        super(props);
        this.state = {
            open: false,
            sellerEmployeeList: []
        };
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onSelectSeller=this.onSelectSeller.bind(this);
    }

    componentDidMount(): void {
        FssSellerService.getMySellerEmployeeList(data => {
            this.setState({sellerEmployeeList: data});
        });
    }

    show(): void {
        this.setState({open: true});
    }

    onCancelClick(): void {
        this.setState({open: false});
    }

    getAppBar(): ReactNode {
        const fullScreen: boolean = AppClientInfo.clientInfo.viewMode != null && AppClientInfo.clientInfo.viewMode == "mobile";
        if (fullScreen) {
            let adminSession = FssClientData.getData().webSession.adminSession;
            let leftNode: undefined| ReactNode = undefined;
            if (adminSession && adminSession.sellerId !== null) {
                leftNode = <IconButton onClick={this.onCancelClick}><ChevronLeft/> </IconButton>
            }
            return <FtAppBar title={i18n("请选择")} position={"fixedTop"} leftNode={leftNode} />
        } else {
            return null;
        }
    }

    render() {
        const fullScreen: boolean = AppClientInfo.clientInfo.viewMode != null && AppClientInfo.clientInfo.viewMode == "mobile";
        return <Dialog open={this.state.open} fullScreen={fullScreen}
                       PaperProps={{className: "paddingTopSafeAreaInset", style: {backgroundColor: grey[200], minWidth: 450}}}
                       onClose={()=>{
                           let adminSession = FssClientData.getData().webSession.adminSession;
                           if (adminSession && adminSession.sellerId !== null) {
                               this.onCancelClick();
                           }
                       }}>
            {this.getAppBar()}
            {fullScreen && <div style={{paddingTop: 64}}/>}
            {!fullScreen && <div style={{paddingTop: 16}}/>}
            <FtPanel style={{marginLeft: 16, marginTop: 0, marginBottom: 16, marginRight: 16}}>
                <FtGrid style={{marginLeft: 16, marginTop: 0, marginBottom: 16, marginRight: 16}}>
                    <FtRow cellWidthS={"1"} cellVerticalSpace={32} cellHorizontalAlign={"center"}>
                        <List component="nav">
                            {this.state.sellerEmployeeList && this.state.sellerEmployeeList.map((sellerEmployee: FssSellerEmployee, index: number) => {
                                if (sellerEmployee.seller)
                                    return <ListItem key={sellerEmployee.id} button onClick={
                                        ()=>this.onSelectSeller(sellerEmployee.seller?.storeId,sellerEmployee.seller?.id)}>
                                        {sellerEmployee.seller.logoUrl &&
                                        <ListItemIcon><img src={sellerEmployee.seller.logoUrl}
                                                           style={{width: 48}}/></ListItemIcon>}
                                        <ListItemText primary={sellerEmployee.seller.fssStore.storeName + " - " + sellerEmployee.seller.shortName}/>
                                        {/*<ListItemText primary={sellerEmployee.seller.shortName}*/}
                                        {/*              secondary={sellerEmployee.seller.shortDescription}/>*/}
                                    </ListItem>;
                            })}
                            {/*<ListItem key={"newSeller"} button onClick={() => {*/}
                            {/*    FssRegisterSellerDialog.dialog.show();*/}
                            {/*}}>*/}
                            {/*    <ListItemIcon><AddOutlined color={"primary"}/></ListItemIcon>*/}
                            {/*    <ListItemText primary={"注册新卖家"}/>*/}
                            {/*</ListItem>*/}
                        </List>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </Dialog>;
    }

    private onSelectSeller(storeId?:string,sellerId?:string) {
        FssAppWebsiteService.updateAdminSession(storeId, sellerId, data=>{
            if (data!=null) {
                FssClientData.updateWebSession(data);
            }
            window.location.reload();
        });
    }
}
