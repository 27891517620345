import * as React from "react";
import FtToast from "../ui/FtToast";
import {i18n} from "../util/I18n";
import {Typography} from "@material-ui/core";
import FssMarketingEventSingleOrder from "../model/FssMarketingEventSingleOrder";
import FssSingleOrder from "../model/FssSingleOrder";
import {EDeliveryType} from "../model/FssDeliveryProvider";

interface FssSingleOrderPrintProp {
    order?: FssMarketingEventSingleOrder;
    orderList?: Array<FssMarketingEventSingleOrder>;
}

class FssSingleOrderPrintState {
    iframList: Array<HTMLIFrameElement | null> = new Array<HTMLIFrameElement>();
}

export default class FssSingleOrderPrint extends React.Component<FssSingleOrderPrintProp, FssSingleOrderPrintState> {
    constructor(props: any) {
        super(props);
        this.state = { iframList: new Array<HTMLIFrameElement>() }
    }

    print() {
        FtToast.showToastLoading(i18n("Printing, please wait "));
        this.state.iframList.forEach((iframe: HTMLIFrameElement | null, index: number) => {
            if (iframe && iframe.contentWindow) {
                if (iframe.contentWindow.document.body)
                    iframe.contentWindow.document.body.innerHTML = "";
                if (iframe.children && iframe.children.length > 0) {
                    const item = iframe.children.item(0);
                    if (item != null) {
                        iframe.contentWindow.document.write(item.innerHTML);
                        window.setTimeout(() => {
                            if (iframe && iframe.contentWindow)
                                iframe.contentWindow.print();
                        }, 1000);
                    }
                }

            }
        });
    }

    render() {
        let orderList = this.props.orderList;
        if (orderList == null && this.props.order != null) {
            orderList = [this.props.order];
        }
        return <div style={{height: 0}}>
            {orderList != null && orderList.map((order: FssMarketingEventSingleOrder, index: number) => (
                <iframe key={index} style={{ width: 0, height: 0, margin: 0, padding: 0, border: 0 }} ref={(element) => {
                    this.state.iframList[index] = element
                }}>
                    <html>
                    <head>
                    </head>
                    <body style={{ fontSize: 10, margin: 0, padding: 8 }}>
                    <div>

                        <div>
                            <Typography>{"发货时间：" + order.scheduleDatetime}</Typography>
                            <Typography>{"团购标题：" + order.marketingEventName}</Typography>
                            <Typography>{(order.deliveryType==EDeliveryType.SELF_PICK_UP?"取货点：":"配送商：") + order.deliveryProviderName}</Typography>
                        </div>
                        <table style={{ width: "100%", fontSize: 10, borderTop: "1px solid black" ,borderCollapse: "collapse"}}>
                            <thead>
                            <tr style={{ backgroundColor: "#909090",
                                borderBottomColor:"#909090",
                                borderBottomStyle:"solid",
                                borderBottomWidth:2}}>
                                <th align="left">{"订单号"}</th>
                                <th align="left">{"联系人"}</th>
                                <th align="left">{"支付"}</th>
                                <th align="left">{"品项"}</th>
                                <th align="left">{"备注"}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {order.orderItemList.map((item: FssSingleOrder, index) => {
                                return <tr  style={{
                                    marginLeft: 20,
                                    backgroundColor: index % 2 == 0 ? "white" : "#a0a0a0",
                                    borderBottomColor:"#a0a0a0",
                                    borderBottomStyle:"solid",
                                    borderBottomWidth:1
                                }}>
                                    <td align="left" valign={"top"}>{item.orderCode}</td>
                                    <td align="left" valign={"top"}>{item.contactMobile}</td>
                                    <td align="left" valign={"top"}>{item.paymentStatusText}<br/>{item.paymentTypeText}</td>
                                    <td align="left" valign={"top"} dangerouslySetInnerHTML={{__html:item.productName}}></td>
                                    <td align="left" valign={"top"}>{item.userComment}</td>
                                </tr>
                            })}
                            </tbody>
                        </table>
                        <table style={{ pageBreakAfter: "always" }}>
                        </table>
                    </div>
                    </body>
                    </html>
                </iframe>
            ))}
        </div>;
    }
}
