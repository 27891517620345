import * as React from "react";
import {ReactNode} from "react";
import FtToast from "../ui/FtToast";
import {Button, Typography} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import FssMarketingEvent, {EStatus} from "../model/FssMarketingEvent";
import {RouteComponentProps} from "react-router-dom";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import {i18n} from "../util/I18n";
import FtTextFieldString from "../ui/FtTextFieldString";
import FtRow from "../ui/FtRow";
import FssMarketingEventService from "../service/FssMarketingEventService";
import FtPagedList from "../model/FtPagedList";
import FssClientData from "../data/FssClientData";
import FtToolbar from "../ui/FtToolbar";
import AppClientInfo from "../data/AppClientInfo";
import IconButton from "@material-ui/core/IconButton";
import FtUtil from "../util/FtUtil";
import FtMessageDialog from "../ui/FtMessageDialog";
import FssProductService from "../service/FssProductService";

export interface FssApplyForPublisherListPageProp extends RouteComponentProps<{id: string}> {
}

class FssApplyForPublisherListPageState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
}

class JtTableAdapter extends FtDataTableAdapter<FssMarketingEvent> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssMarketingEventService.getApplyForPublisherPagedList(this.keyword, this.filterColumns, this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssMarketingEvent>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
    resetSearch() {
        this.keyword = "";
        this.selectedRow = [];
    }
}

export default class FssApplyForPublisherListPage extends FtComponent<FssApplyForPublisherListPageProp, FssApplyForPublisherListPageState> {
    constructor(props: any) {
        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("publisher.nickName", i18n("团长昵称"));
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("publisher.email", i18n("联系人邮箱"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("publisher.mobile", i18n("联系人手机"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("createDatetime", i18n("申请时间"));
        column4.renderCell = (rowData: object) => {
            const model: FssMarketingEvent = rowData as FssMarketingEvent;
            return FssClientData.formatDatetime(model.createDatetime);
        };
        columnProps.push(column4);
        const column5: FtDataTableColumnProps = new FtDataTableColumnProps("status", i18n("审核状态"));
        column5.renderCell = (rowData: object, index: number) => {
            const model: FssMarketingEvent = rowData as FssMarketingEvent;
            if (model.status === EStatus.PENDING) {
                return <Button variant={"outlined"} color={"primary"}
                               onClick={()=>{
                                   this.onSave(model, index, EStatus.NORMAL, this.onCopyProducts)
                               }}>审核通过</Button>;
            } else if (model.status === EStatus.NORMAL) {
                return <Button variant={"outlined"} color={"secondary"}
                               onClick={()=>{
                                   this.onSave(model, index, EStatus.PENDING)
                               }}>取消审核</Button>;
            }
            return null;
        };
        columnProps.push(column5);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("publisher.marketingEvent", i18n("查看团长活动"));
        column6.renderCell = (rowData: object, index: number) => {
            const model: FssMarketingEvent = rowData as FssMarketingEvent;
            if (model.status === EStatus.NORMAL) {
                return <a href={"/website/index.html#/s@" + model.sellerId + "/jielong/" + model.id} target={"_blank"}>
                    <Button variant={"outlined"} color={"primary"}>预览</Button>
                </a>
            }
            return null;
        };
        columnProps.push(column6);

        let tableAdapter: JtTableAdapter = new JtTableAdapter();
        tableAdapter.filterColumns.set("id", this.props.match.params.id);
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter,
            columnProps: columnProps,
            stateKey: "FssApplyForPublisherListPage",
        };
        this.renderRowForMobile=this.renderRowForMobile.bind(this);
        this.onCancel=this.onCancel.bind(this);
        this.onSave=this.onSave.bind(this);
        this.onCopyProducts=this.onCopyProducts.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    onCancel() {
        this.props.history.goBack();
    }

    render() {
        let backElement = <FtToolbar leftNode={<Button onClick={this.onCancel}><ChevronLeft/>{i18n("返回")}</Button>}/>;
        if (AppClientInfo.clientInfo.viewMode == "mobile") {
            backElement = <IconButton style={{position: "absolute", left: 0, top: 0, padding: 8}} onClick={this.onCancel}>
                <ChevronLeft style={{color: "black"}}/>
            </IconButton>;
        }
        return <div style={{position: "relative"}}>
            {backElement}
            <FtDataTable title={i18n("开团申请列表")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         filterPanel={this.getFilterPanel()} renderRowForMobile={this.renderRowForMobile} />
        </div>;
    }

    renderRowForMobile(rowData: any, index: number) {
        const marketingEvent:FssMarketingEvent = rowData as FssMarketingEvent;
        let button: ReactNode| null = null;
        if (marketingEvent.status === EStatus.PENDING) {
            button = <Button variant={"outlined"} color={"primary"}
                             onClick={()=>{
                                 this.onSave(marketingEvent, index, EStatus.NORMAL, this.onCopyProducts);
                             }}>审核通过</Button>;
        } else if (marketingEvent.status === EStatus.NORMAL) {
            button = <Button variant={"outlined"} color={"secondary"}
                             onClick={()=>{
                                 this.onSave(marketingEvent, index, EStatus.PENDING)
                             }}>取消审核</Button>;
        }
        return <FtRow cellWidthS={"7,3"} cellVerticalAlign={"top"}  style={{overflowWrap:"anywhere"}}>
            <FtRow cellWidthS={"1"}>
                <Typography variant="subtitle2">{i18n("团长昵称") + "：" + marketingEvent.publisher.nickName}</Typography>
                <Typography variant="subtitle2">{i18n("联系人邮箱") + "：" + marketingEvent.publisher.email}</Typography>
                <Typography variant="subtitle2">{i18n("联系人手机") + "：" + marketingEvent.publisher.mobile}</Typography>
                <Typography variant="subtitle2">{i18n("申请时间") + "：" + FssClientData.formatDatetime(marketingEvent.createDatetime)}</Typography>
            </FtRow>
            <FtRow cellWidthS={"1"}>
                {button}
                {marketingEvent.status === EStatus.NORMAL &&
                <a href={"/website/index.html#/s@" + marketingEvent.sellerId + "/jielong/" + marketingEvent.id} target={"_blank"}>
                    <Button variant={"outlined"} color={"primary"}>预览</Button>
                </a>}
            </FtRow>
        </FtRow>
    }

    getFilterPanel(): ReactNode {
        return <FtTextFieldString label={i18n("关键字")} onChangeValue={(value) => {
            this.state.tableAdapter.keyword = value ? value : "";
            this.forceUpdate();
        }} value={this.state.tableAdapter.keyword} style={{marginLeft: 8}}/>;
    }

    onSave(marketingEvent: FssMarketingEvent, index: number, status: EStatus, copyProduct?: (marketingEvent: FssMarketingEvent) => void) {
        let msg = "确定【审核通过】吗？";
        if (status === EStatus.PENDING) {
            msg = "确定【取消审核通过】吗？";
        }
        FtMessageDialog.dialog.openDialog(i18n(msg),()=>{
            let publisher = marketingEvent.publisher;
            marketingEvent.status = status;
            FtToast.showToastLoading("loading");
            FssMarketingEventService.save(marketingEvent, (data: FssMarketingEvent)=>{
                FtToast.closeToast();
                let event = FtUtil.mergeObject(new FssMarketingEvent(), data);
                event.publisher = publisher;
                this.state.tableAdapter.pagedList.content[index] = event;
                this.forceUpdate();
                // 复制商品
                if (copyProduct) {
                    copyProduct(marketingEvent);
                }
            })
        });
    }

    onCopyProducts(marketingEvent: FssMarketingEvent) {
        FtToast.showToastLoading("loading");
        FssProductService.marketingEventCopyProductByTag(marketingEvent.id,(msg: string)=>{
            FtToast.closeToast();
        });
    }
}
