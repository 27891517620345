import * as React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import JtUserMedia from "../model/FssUserMedia";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from "@material-ui/core/DialogContent";
import {i18n} from "../util/I18n";
import FssUserMediaListPage from "./FssUserMediaListPage";
import FtRow from "../ui/FtRow";
import {Typography} from "@material-ui/core";

interface JtUserMediaSelectDialogProp {
}

export class JtUserMediaSelectDialogState {
    open: boolean = false;
    onClose?: (data: Array<JtUserMedia> | null) => void;
    selectedList: Array<JtUserMedia> = new Array<JtUserMedia>();
    selectMode: "single" | "multiple" = "single";
}


export default class FssUserMediaSelectDialog extends React.Component<JtUserMediaSelectDialogProp, JtUserMediaSelectDialogState> {
    jtUserMediaListPage!: FssUserMediaListPage;

    constructor(props: any) {
        super(props);
        this.state = {open: false, selectedList: new Array<JtUserMedia>(), selectMode: "single"};
        this.show = this.show.bind(this);
        this.onClickOK = this.onClickOK.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
        this.onClickItem = this.onClickItem.bind(this);
        this.onRemoveItem = this.onRemoveItem.bind(this);
    }

    componentDidMount(): void {

    }

    show(selectMode?: "single" | "multiple", onClose?: (data: Array<JtUserMedia> | null) => void): void {
        this.setState({
            open: true,
            selectMode: selectMode ? selectMode : "single",
            onClose: onClose,
            selectedList: new Array<JtUserMedia>()
        });
    }

    onClickOK() {
        this.setState({open: false});
        if (this.state.onClose)
            this.state.onClose(this.state.selectedList);
    }

    onClickCancel() {
        this.setState({open: false});
        if (this.state.onClose)
            this.state.onClose(null);
    }

    onClickItem(clickItem: JtUserMedia) {
        let found: boolean = false;
        this.state.selectedList.forEach((userMedia: JtUserMedia, index: number) => {
            if (userMedia.id == clickItem.id) {
                this.state.selectedList.splice(index, 1);
                found = true;
            }
        });
        if (!found) {
            this.state.selectedList.push(clickItem);
            //如果是单选模式，直接关闭对话框
            if (this.state.selectMode == "single")
                this.onClickOK();
        }
        this.forceUpdate();
    }

    onRemoveItem(id: string) {
        this.state.selectedList.forEach((userMedia: JtUserMedia, index: number) => {
            if (userMedia.id == id) {
                this.state.selectedList.splice(index, 1);
            }
        });
    }

    render() {
        return <Dialog open={this.state.open} onClose={this.onClickCancel} scroll={"paper"} maxWidth={"md"}>
            <FtRow justifyContent={"space-between"} style={{width: "100%"}}>
                <Typography style={{paddingLeft: 16}}>{i18n("请选择")}</Typography>
                <IconButton onClick={this.onClickCancel}>
                    <CloseIcon/>
                </IconButton>
            </FtRow>
            <DialogContent dividers={true} style={{padding: 0}}>
                <FssUserMediaListPage
                    ref={(element: any) => {
                        this.jtUserMediaListPage = element
                    }}
                    onRemoveItem={this.onRemoveItem}
                    onClickItem={this.onClickItem}
                    selectedList={this.state.selectedList}/>
            </DialogContent>
            <DialogActions>
                <Button variant={"contained"} onClick={this.onClickCancel}>{i18n("取消")}</Button>
                <Button variant={"contained"} color={"primary"} onClick={this.onClickOK}>{i18n("确定")}</Button>
            </DialogActions>
        </Dialog>;
    }

}
