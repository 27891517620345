import * as React from "react";
import {
    Button, Checkbox,
    FormControl, FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from "@material-ui/core";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { RouteComponentProps } from "react-router-dom";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FssSellerEmployee, {EStatus} from "../model/FssSellerEmployee";
import FtToast from "../ui/FtToast";
import FtPage from "../ui/FtPage";
import FtToolbar from "../ui/FtToolbar";
import {i18n} from "../util/I18n";
import FtUtil from "../util/FtUtil";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import JtTextField from "../ui/JtTextField";
import FssSellerEmployeeService from "../service/FssSellerEmployeeService";
import FssSellerEmployeeRole, {ERoleCode} from "../model/FssSellerEmployeeRole";
import FssSellerEmployeeRoleService from "../service/FssSellerEmployeeRoleService";
import FtSnackbar from "../ui/FtSnackbar";

export interface FasCustomerMemberDetailPageProp extends RouteComponentProps<{ employeeId: string }> {
}

export class FasCustomerMemberDetailPageState extends FtComponentState {
    fssSellerEmployee: FssSellerEmployee = new FssSellerEmployee();
    productRoleList: Array<FssSellerEmployeeRole> = [];
    appUserRoleList: Array<string> = [];
}

export default class FssSellerEmployeeDetailPage extends FtComponent<FasCustomerMemberDetailPageProp, FasCustomerMemberDetailPageState> {
    constructor(props: any) {
        super(props);
        this.state = { fssSellerEmployee: new FssSellerEmployee(),productRoleList: [], appUserRoleList: []};
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onClickStop = this.onClickStop.bind(this);
        this.onClickNormal = this.onClickNormal.bind(this);
        this.onClickBack = this.onClickBack.bind(this);
    }

    componentDidMount(): void {
        if (this.props.match.params.employeeId) {
            FtToast.showToastLoading();
            FssSellerEmployeeService.getFssSellerEmployeeById(this.props.match.params.employeeId, (data: FssSellerEmployee) => {
                FtToast.closeToast();
                this.setState({ fssSellerEmployee: data,appUserRoleList:data.roles?.split(",") });
            });
        }
        FssSellerEmployeeRoleService.getList( (productRoleList: Array<FssSellerEmployeeRole>) => {
            if (productRoleList && productRoleList.length > 0) {
                this.setState({productRoleList: productRoleList});
            }
        })
    }


    onChange(id: string, value: string) {
        FtUtil.setProperty(this.state.fssSellerEmployee, id, value);
        this.forceUpdate();
    }

    render() {
        return <FtPage>
            <FtToolbar position={"sticky"}
                leftNode={<div>
                    <IconButton onClick={this.onClickBack}><ChevronLeft /></IconButton>
                    <Button onClick={this.onClickSave}>{i18n("保存")}</Button>
                    {this.state.fssSellerEmployee.status=="NORMAL" && <Button onClick={this.onClickStop}>{i18n("停用")}</Button>}
                    {this.state.fssSellerEmployee.status=="STOP" && <Button onClick={this.onClickNormal}>{i18n("启用")}</Button>}
                </div>}
                centerNode={<Typography variant={"h6"}>{i18n("成员详情")}</Typography>}>

            </FtToolbar>
            <FtPanel elevation={2} marginVertical={16} marginHorizontal={16}>
                <FtGrid>
                    <FtRow cellWidthS={"1"} cellWidthM={"1,1,1"} cellWidthL={"1,1,1,1"} cellHorizontalSpace={12} cellVerticalSpace={16} maxRowWidth={"l"}>

                        <JtTextField id="mobile" value={this.state.fssSellerEmployee.user?.mobile?this.state.fssSellerEmployee.user?.mobile:""} disabled={true} label={i18n("收件电话")} type={"phone"} required={true} style={{ width: "100%" }}></JtTextField>
                        <JtTextField id="email" value={this.state.fssSellerEmployee.user?.email?this.state.fssSellerEmployee.user?.email:""} disabled={true} label="Email" type={"email"} required={true} style={{ width: "100%" }}></JtTextField>

                        <FormControl style={{ width: "100%", textAlign: "left" }} disabled={true} >
                            <InputLabel id="demo-simple-select-label">{i18n("状态")}</InputLabel>
                            <Select id="status" labelId="demo-simple-select-label" style={{ width: "100%" }} onChange={(event, child) => {
                                    this.state.fssSellerEmployee.status = event.target.value as EStatus ;
                                    this.forceUpdate();
                            }} value={this.state.fssSellerEmployee.status}>
                                <MenuItem value={EStatus.NORMAL}>{i18n("启用")}</MenuItem>
                                <MenuItem value={EStatus.DISABLED}>{i18n("未激活")}</MenuItem>
                                <MenuItem value={EStatus.STOP}>{i18n("停用")}</MenuItem>
                            </Select>
                        </FormControl>
                    </FtRow>
                    <FormControl style={{marginTop: 10}}>
                        <FormLabel>{i18n("角色")}</FormLabel>
                        <FormGroup>
                            {this.state.productRoleList.map((role: FssSellerEmployeeRole, index: number) => {
                                let findIndex: number = this.state.appUserRoleList.indexOf(role.roleCode as string);
                                let disabled = false;
                                if (ERoleCode.ADMIN != role.roleCode && this.state.appUserRoleList.indexOf(ERoleCode.ADMIN) >= 0) {
                                    disabled = true;
                                }
                                return <FormControlLabel key={index} disabled={disabled}
                                                         control={<Checkbox checked={findIndex >= 0}
                                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                                                this.onChangeCheckBox(role, checked)
                                                                            }} value={role.roleCode}/>}
                                                         label={role.roleName + "," + role.description}/>
                            })}
                        </FormGroup>
                    </FormControl>
                </FtGrid>
                <FtGrid paddingVertical={32}>
                    <FtRow cellHorizontalSpace={16} justifyContent={"center"}>
                        <Button variant={"outlined"} onClick={this.onClickBack}>{i18n("返回")}</Button>
                        <Button variant={"contained"} color={"primary"} onClick={this.onClickSave}>{i18n("保存")}</Button>
                    </FtRow>
                </FtGrid>
            </FtPanel>
        </FtPage>;
    }

    private onClickSave() {
        let appUserRoleList = this.state.appUserRoleList;
        if (appUserRoleList.indexOf(ERoleCode.ADMIN) >= 0) {
            appUserRoleList = [ERoleCode.ADMIN];
        }
        let roles = appUserRoleList.join(",");
        if(roles===""){
            FtSnackbar.showText(i18n("角色不能为空"));
            return;
        }
        this.state.fssSellerEmployee.roles=roles;
        FtToast.showToastLoading(i18n("正在保存"));
        FssSellerEmployeeService.saveFssSellerEmployee(this.state.fssSellerEmployee, (data: FssSellerEmployee) => {
            FtToast.closeToast();
            this.setState({ fssSellerEmployee: data });
            FtSnackbar.showText(i18n("保存成功！"));
        });
    }
    private onClickStop() {
        FtToast.showToastLoading(i18n("正在保存"));
        this.state.fssSellerEmployee.status=EStatus.STOP;
        FssSellerEmployeeService.saveFssSellerEmployee(this.state.fssSellerEmployee, (data: FssSellerEmployee) => {
            FtToast.closeToast();
            this.setState({ fssSellerEmployee: data });
            FtSnackbar.showText(i18n("操作成功！"));
        });
    }
    private onClickNormal() {
        FtToast.showToastLoading(i18n("正在保存"));
        this.state.fssSellerEmployee.status=EStatus.NORMAL;
        FssSellerEmployeeService.saveFssSellerEmployee(this.state.fssSellerEmployee, (data: FssSellerEmployee) => {
            FtToast.closeToast();
            this.setState({ fssSellerEmployee: data });
            FtSnackbar.showText(i18n("操作成功！"));
        });
    }
    private onClickBack() {
        this.props.history.goBack();
    }
    onChangeCheckBox(productRole: FssSellerEmployeeRole, checked: boolean) {
        let checkedValue: Array<string> = this.state.appUserRoleList;
        let findIndex: number = this.state.appUserRoleList.indexOf(productRole.roleCode as string);
        if (checked && findIndex < 0) {
            checkedValue.push(productRole.roleCode as string);
        } else if (!checked && findIndex >= 0) {
            checkedValue.splice(findIndex, 1);
        }
        this.setState({appUserRoleList: checkedValue});
    }
}
