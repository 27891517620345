import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FssUserCoupon from "../model/FssUserCoupon";

export default class FssUserCouponService {
    /**
     *
     * @param searchKeyword
     * @param filterColumns
     * @param sortPropertyName
     * @param sortDirection
     * @param pageNo
     * @param pageSize
     * @param successFunction
     * @param failFunction
     */
    static getJtUserCouponList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                               pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssUserCoupon>) => void,
                               failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/userCoupon/getJtUserCouponList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }
    /**
     *
     * @param couponId
     * @param userCodes
     * @param successFunction
     * @param failFunction
     */
    static saveJtUserCoupon(couponId: string, userCodes: string, successFunction: (mobileList: Array<string>) => void, failFunction?: (error: any) => void): void {
        let params = {couponId: couponId, userCodes: userCodes};
        FssHttpService.postFormData("/api/userCoupon/saveJtUserCoupon", params, successFunction, FssHttpService.handleError);
    }

}
