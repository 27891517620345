export default class I18n {
    static textMap:Map<string,string>=new Map<string, string>();
    public static setTextMap(values:Array<any>){
        if(values!=null){
            this.textMap=new Map<string, string>(values);
        }
    }
}

export const i18n=(text:string|undefined,...arg:string[])=>{
    if(text!=null) {
        let result = text;
        if (I18n.textMap != null&&I18n.textMap.size>0) {
            const value: string | undefined = I18n.textMap.get(text);
            if (value != null && value.length > 0) {
                result = value;
            } else
                console.log("Not found: textMap.size=" + I18n.textMap.size + " , " + text);
        }
        // else
            // console.log("Not found, textMap is null: " + text);
        if (arg != null && arg.length > 0) {
            arg.forEach((str: string, index: number) => {
                result = result.replace("{" + index + "}", str);
            });
        }
        return result;
    }else
        return "";

}
