import FssHttpService from "./FssHttpService";
import FssOrderTrackData from "../model/FssOrderTrackData";
import FtPagedList from "../model/FtPagedList";
import FssOrder, {EOrderStatus, EPaymentStatus, EPaymentType} from "../model/FssOrder";
import {FssOrderSetting} from "../model/FssOrderSetting";
import FssPointTransaction from "../model/FssPointTransaction";
import FssUserBalanceTransaction from "../model/FssUserBalanceTransaction";
import FssPaymentTransaction from "../model/FssPaymentTransaction";
import FssOrderDiscount from "../model/FssOrderDiscount";
import FssOrderItemTax from "../model/FssOrderItemTax";
import {FssOrderStatistics} from "../model/FssOrderStatistics";
import FssShoppingCart from "../model/FssShoppingCart";
import FssCheckoutData from "../model/FssCheckoutData";
import FssOrderItem from "../model/FssOrderItem";
import {FssPublisherOrderStatistics} from "../model/FssPublisherOrderStatistics";
import AppClientInfo from "../data/AppClientInfo";
import FssClientData from "../data/FssClientData";

export default class FssOrderService {
    /**
     * 同步运单数据
     * @param orderIds
     * @param successFunction
     */
    static syncWithDeliveryProvider(orderIds: Array<string>, successFunction: (msg: string) => void) {
        FssHttpService.postFormData("/api/order/syncWithDeliveryProvider", {orderIds: orderIds}, successFunction, FssHttpService.handleError);
    }

    static getStoreOrderList(searchKeyword: string, filterColumns: Map<string, any>,
                             sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                             pageNo: number, pageSize: number,
                             successFunction: (result: FtPagedList<FssOrder>) => void,
                             failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/order/getStoreOrderList", searchKeyword, filterColumns,
            sortPropertyName, sortDirection, pageNo, pageSize, successFunction,
            failFunction ? failFunction : FssHttpService.handleError);
    }

    static exportOrderExcel(searchKeyword: string, filterColumns: Map<string, any>,
                            sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                            pageNo: number, pageSize: number,
                            successFunction: (response: Response) => void,
                            failFunction?: (error: any) => void): void {
        let params = "searchKeyword=" + searchKeyword;
        filterColumns.forEach((value: any, key: string) => {
            params += "&" + key + "=" + value;
        });
        let sort = sortPropertyName == null ? "" : sortPropertyName;
        let direction = sortDirection == null ? "asc" : sortDirection;
        params += "&sort=" + sort + "," + direction;
        params += "&page=" + pageNo;
        params += "&size=" + pageSize;
        fetch(AppClientInfo.clientInfo.apiServerUrl + "/api/excel/exportOrderExcel?" + params, {
            method: "get",
            headers: {ftshop: FssClientData.getData().webSession.dataString + ""}
        }).then(successFunction);
    }

    static getOrder(id: string, successFunction: (order: FssOrder) => void) {
        FssHttpService.get("/api/order/getOrderById", {orderId: id}, successFunction, FssHttpService.handleError);
    }

    static getListByParentId(id: string, successFunction: (orderList: Array<FssOrder>) => void) {
        FssHttpService.get("/api/order/getListByParentId", {parentOrderId: id}, successFunction, FssHttpService.handleError);
    }

    static saveOrder(order: FssOrder, successFunction: (order: FssOrder) => void): void {
        FssHttpService.postJson("/api/order/saveOrder", order, successFunction, FssHttpService.handleError);
    }

    /**
     * 开始拣货（备货）
     * @param orderIds
     * @param successFunction
     */
    static pickingOrders(orderIds: Array<string>, successFunction: (msg: string) => void) {
        FssHttpService.postFormData("/api/order/pickingOrders", {orderIds: orderIds}, successFunction, FssHttpService.handleError);
    }

    /**
     * 打包完成
     * @param orderIds
     * @param successFunction
     */
    static packOrders(orderIds: Array<string>, successFunction: (msg: string) => void) {
        FssHttpService.postFormData("/api/order/packOrders", {orderIds: orderIds}, successFunction, FssHttpService.handleError);
    }

    /**
     * 完成订单
     * @param orderIds
     * @param successFunction
     */
    static finishOrders(orderIds: Array<string>, successFunction: (msg: string) => void) {
        FssHttpService.postFormData("/api/order/finishOrders", {orderIds: orderIds}, successFunction, FssHttpService.handleError);
    }

    /**
     * 恢复订单
     * @param orderIds
     * @param successFunction
     */
    static restoreOrders(orderIds: Array<string>, successFunction: (msg: string) => void, failFunction?: (error: any) => void) {
        FssHttpService.postFormData("/api/order/restoreOrders", {orderIds: orderIds}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static sendToDeliveryProvider(orderId: string, deliveryProviderId: string, trackingNumber: string,
                                  smsNotificationFlag: boolean, smsNotificationText: string,
                                  successFunction: (order: FssOrder) => void): void {
        let params = {
            orderId: orderId,
            deliveryProviderId: deliveryProviderId,
            trackingNumber: trackingNumber,
            smsNotificationFlag: smsNotificationFlag,
            smsNotificationText: smsNotificationText
        };
        FssHttpService.postFormData("/api/order/sendToDeliveryProvider", params, successFunction, FssHttpService.handleError);
    }

    static cancelShipping(orderId: string, successFunction: (order: FssOrder) => void): void {
        FssHttpService.postFormData("/api/order/cancelShipping", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static refundOrder(refundTo: "balance" | "source", orderId: string, refundOption: "all" | "overPaid" | "partial" = "partial", refundMoney: number = 0, reason: string = "",
                       successFunction: (order: FssOrder) => void, failCallback?: (apiResponse: any) => void): void {
        FssHttpService.postFormData("/api/order/refundOrder", {
            refundTo: refundTo,
            orderId: orderId,
            refundOption: refundOption,
            refundMoney: refundMoney,
            reason: reason
        }, successFunction, failCallback ? failCallback : FssHttpService.handleError);
    }

    static updateDeliveryProvider(orderId: string, shippingFeeRuleId: string, regionId: string, deliveryProviderId: string, trackingNumber: string, successFunction: (order: FssOrder) => void) {
        let params = {
            orderId: orderId,
            shippingFeeRuleId: shippingFeeRuleId,
            regionId: regionId,
            deliveryProviderId: deliveryProviderId,
            trackingNumber: trackingNumber
        };
        FssHttpService.postFormData("/api/order/updateDeliveryProvider", params, successFunction, FssHttpService.handleError);
    }

    static resetShippedOrders(orderIds: Array<string>, successFunction: (msg: string) => void) {
        FssHttpService.postFormData("/api/order/resetShippedOrders", {orderIds: orderIds}, successFunction, FssHttpService.handleError);
    }

    static confirmOrderPayment(orderId: string, paymentType: EPaymentType, successFunction: (order: FssOrder) => void): void {
        FssHttpService.postFormData("/api/order/confirmOrderPayment", {
            orderId: orderId,
            paymentType: paymentType
        }, successFunction, FssHttpService.handleError);
    }

    static approveOrder(orderId: string, successFunction: (order: FssOrder) => void): void {
        FssHttpService.postFormData("/api/order/approveOrder", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static creditPaymentPartial(orderId: string, successFunction: (order: FssOrder) => void): void {
        FssHttpService.postFormData("/api/order/creditPaymentPartial", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static cancelOrder(orderId: string, successFunction: (order: FssOrder) => void): void {
        FssHttpService.postFormData("/api/order/cancelOrder", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static saveOrUpdateOrderSetting(orderSetting: FssOrderSetting, successFunction: (orderSetting: FssOrderSetting) => void) {
        FssHttpService.postJson("/api/setting/saveOrUpdateOrderSetting", orderSetting, successFunction, FssHttpService.handleError);
    }

    static getPointTransactionListByOrderId(orderId: string, successFunction: (couponList: Array<FssPointTransaction>) => void): void {
        FssHttpService.get("/api/order/getPointTransactionListByOrderId", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static getBalanceTransactionListByOrderId(orderId: string, successFunction: (couponList: Array<FssUserBalanceTransaction>) => void): void {
        FssHttpService.get("/api/order/getBalanceTransactionListByOrderId", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static getPaymentTransactionListByOrderId(orderId: string, successFunction: (couponList: Array<FssPaymentTransaction>) => void): void {
        FssHttpService.get("/api/order/getPaymentTransactionListByOrderId", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static getOrderDiscountByOrderId(orderId: string, successFunction: (couponList: Array<FssOrderDiscount>) => void): void {
        FssHttpService.get("/api/order/getOrderDiscountByOrderId", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static getTaxListByOrderItemId(orderItemId: string, successFunction: (couponList: Array<FssOrderItemTax>) => void): void {
        FssHttpService.get("/api/order/getTaxListByOrderItemId", {orderItemId: orderItemId}, successFunction, FssHttpService.handleError);
    }

    static getCheckoutData(shoppingCart: FssShoppingCart, successFunction: (checkoutData: FssCheckoutData) => void, failFunction ?: (error: any) => void) {
        FssHttpService.postJson("/api/order/getCheckoutData", shoppingCart, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static submitOrder(shoppingCart: FssShoppingCart, successFunction: (orderList: Array<FssOrder>) => void, failFunction ?: (error: any) => void) {
        FssHttpService.postJson("/api/order/submitOrder", shoppingCart, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static getOrderListByIds(orderIds: string, successFunction: (orderList: Array<FssOrder>) => void) {
        FssHttpService.get("/api/order/getOrderListByIds", {orderIds: orderIds}, successFunction, FssHttpService.handleError);
    }

    // 获取订单数量统计
    static getOrderStatistics(successFunction: (data: FssOrderStatistics) => void): void {
        FssHttpService.get("/api/order/getOrderStatistics", {}, successFunction, FssHttpService.handleError);
    }

    // 获取订单数量统计
    static getOrderStatisticsBySeller(successFunction: (data: FssOrderStatistics) => void): void {
        FssHttpService.get("/api/order/getOrderStatisticsBySeller", {}, successFunction, FssHttpService.handleError);
    }

    // 获取团长订单数量统计
    static getMyPublisherOrderStatistics(successFunction: (data: FssPublisherOrderStatistics) => void): void {
        FssHttpService.get("/api/order/getMyPublisherOrderStatistics", {}, successFunction, FssHttpService.handleError);
    }

    // 获取我的订单
    static getMyOrderList(page: number, size: number, orderStatus: Array<EOrderStatus>, paymentStatus: Array<EPaymentStatus>,
                          successFunction: (orderList: FtPagedList<FssOrder>) => void): void {
        let params = {page: page, size: size, orderStatus: orderStatus, paymentStatus: paymentStatus};
        FssHttpService.get("/api/order/getMyOrderList", params, successFunction, FssHttpService.handleError);
    }

    // 获取我的团长订单
    static getMyPublisherOrderList(page: number, size: number, type: string, userId: string,
                                   successFunction: (orderList: FtPagedList<FssOrder>) => void): void {
        let params = {page: page, size: size, type: type, userId: userId};
        FssHttpService.get("/api/order/getMyPublisherOrderList", params, successFunction, FssHttpService.handleError);
    }

    static getOrderItemPagedList(filterColumns: Map<string, any>, pageNo: number, pageSize: number, successFunction: (pagedList: FtPagedList<FssOrderItem>) => void,
                                 failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/order/getOrderItemPagedList", "", filterColumns, null, null, pageNo, pageSize, successFunction, FssHttpService.handleError);
    }

    static getOrderItemById(orderItemId: string, successFunction: (orderItem: FssOrderItem) => void, failFunction?: (error: any) => void): void {

        FssHttpService.get("/api/order/getOrderItemById", {orderItemId: orderItemId}, successFunction, FssHttpService.handleError);
    }

    static getOrderSetting(successFunction: (orderSetting: FssOrderSetting) => void) {
        FssHttpService.get("/api/setting/getOrderSetting", "", successFunction, FssHttpService.handleError);
    }

    static getWebsiteOrderSetting(storeId: string, sellerId: string, successFunction: (orderSetting: FssOrderSetting) => void) {
        FssHttpService.get("/api/setting/getWebsiteOrderSetting", {
            storeId: storeId,
            sellerId: sellerId
        }, successFunction, FssHttpService.handleError);
    }

    // 获取用户相同地址已审核订单
    static getSameAddressApprovedOrder(storeId: string, sellerId: string, shipToAddress: string, shipToMobile: string, shippingFeeRuleId: string, marketingEventIds: Array<string>, successFunction: (order: FssOrder) => void) {
        let params = {
            storeId: storeId,
            sellerId: sellerId,
            shipToAddress: shipToAddress,
            shipToMobile: shipToMobile,
            shippingFeeRuleId: shippingFeeRuleId,
            marketingEventIds: marketingEventIds
        };
        FssHttpService.get("/api/order/getSameAddressApprovedOrder", params, successFunction, FssHttpService.handleError);
    }

    // 获取用户相同自取点已审核订单
    static getSamePickupApprovedOrder(storeId: string, sellerId: string, deliveryProviderId: string, marketingEventIds: Array<string>, successFunction: (order: FssOrder) => void) {
        let params = {
            storeId: storeId,
            sellerId: sellerId,
            deliveryProviderId: deliveryProviderId,
            marketingEventIds: marketingEventIds
        };
        FssHttpService.get("/api/order/getSamePickupApprovedOrder", params, successFunction, FssHttpService.handleError);
    }

    static cancelOrderByUser(orderId: string, successFunction: (order: FssOrder) => void): void {
        FssHttpService.postFormData("/api/order/cancelOrderByUser", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static getOrderTrackData(orderId: string, successFunction: (orderTrackData: FssOrderTrackData) => void) {
        FssHttpService.get("/api/order/getOrderTrackData", {orderId: orderId}, successFunction, FssHttpService.handleError);
    }

    static exportExpressOrder(orderIds: string, deliveryProviderId: string, successFunction: (response: Response) => void) {
        let params = "orderIds=" + orderIds + "&deliveryProviderId=" + deliveryProviderId;
        fetch(AppClientInfo.clientInfo.apiServerUrl + "/api/excel/exportExpressOrder?" + params, {
            method: "get",
            headers: {ftshop: FssClientData.getData().webSession.dataString + ""}
        }).then(successFunction);
    }
}
