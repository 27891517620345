import FssOrderItem from "./FssOrderItem";

export default class FssMarketingEventPickUpSumOrder {
    scheduleDatetime: string = "";
    marketingEventId: string = "";
    marketingEventName: string = "";
    deliveryProviderId: string = "";
    pickUpName: string = "";
    pickUpAddress: string = "";
    contactName: string = "";
    contactPhone: string = "";
    total: number = 0;
    totalPaid: number = 0;
    totalOrderQuantity: number = 0;
    orderItemList: Array<FssOrderItem> = [];
}
