export enum EPeriodTypes {
    DAILY="DAILY",
    WEEKLY="WEEKLY",
    MONTHLY="MONTHLY"
}
export enum EStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    STOP_ORDER = 'STOP_ORDER'
}
export default class FssShippingSchedule {
    id: string="";
    country:string="";
    province:string="";
    city:string="";
    periodValues:string="";
    periodType:EPeriodTypes=EPeriodTypes.DAILY;
    startTime:string="";
    endTime:string="";
    stopOrderDayTime:string="";
    deliveryProviderId:string="0";
    regionId:number=0;
    status:EStatus=EStatus.ENABLED;
    statusText="";
    availableDaysCount:number=0;
    regionName:string="";
}
