import * as React from "react";
import _ from "lodash";
import FssOrder from "../model/FssOrder";
import AppClientInfo from "../data/AppClientInfo";
import FtUtil from "../util/FtUtil";
import FssClientData from "../data/FssClientData";
import FtToast from "../ui/FtToast";

interface JtOrderWaybillPrintProp {
    order?: FssOrder;
    orderList?: Array<FssOrder>;

}

class JtOrderWaybillPrintState {
    orderIds: Array<string> = [];
}

export default class FssOrderWaybillPrint extends React.Component<JtOrderWaybillPrintProp, JtOrderWaybillPrintState> {

    constructor(props: any) {
        super(props);
        this.state = {orderIds:new Array()};
    }

    print() {
        let orderList = this.props.orderList;
        if (orderList == null && this.props.order != null) {
            orderList = [this.props.order];
        }
        //把订单按照订单号排序，把子单和主单排在一起
        orderList=_.sortBy(orderList,(order:FssOrder)=>{
            if(order.parentOrderId!=null&&order.parentOrderId.length>0)
                return order.parentOrderId;
            else
                return order.id;
        });
        const orderIds:Array<string>=[];
        orderList.forEach((order:FssOrder,index:number)=>{
            orderIds.push(order.id);
        });
        this.setState({orderIds:orderIds},()=>{
            const form:any=window.document.getElementById("printOrderWaybillForm");
            if(form)
                form.submit();
        });
    }

    render() {


        return <div>
            <form id={"printOrderWaybillForm"} target={"_blank"} method={"POST"}
                  action={AppClientInfo.clientInfo.apiServerUrl + "/api/print/batchPrintOrderList?"+FssClientData.getData().webSession.dataString}>
                <input type={"hidden"} name={"orderIds"} value={FtUtil.arrayToString(this.state.orderIds)}></input>
                <input type={"hidden"} name={"printType"} value={"waybill"}></input>
            </form>
        </div>;
    }
}
