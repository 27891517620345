
export default class FssSellerEmployeeRole {
    id: string = "";
    roleCode?: ERoleCode;
    roleName: string = "";
    description: string = "";
}
export enum ERoleCode {
    ADMIN = "ADMIN",
    PRODUCT_MANAGER = "PRODUCT_MANAGER",
    ORDER_MANAGER = "ORDER_MANAGER",
    USER_MANAGER = "USER_MANAGER",
    MARKETING_MANAGER = "MARKETING_MANAGER",
    UI_DESIGNER = "UI_DESIGNER",
    FINANCIAL_MANAGER = "FINANCIAL_MANAGER",
    ORDER_VIEWER = "ORDER_VIEWER",
}
