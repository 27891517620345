const regexpUSPhone = new RegExp("^\\+1{1}[0-9]{10}$");
const regexpCNPhone = new RegExp("^\\+86{1}[0-9]{11}$");

export default class FtPhoneCodeUtil {


    static canadaPhoneAreaCodes: Array<number> = [204, 226, 236, 249, 250, 289, 306, 343, 365, 367, 403, 416, 418, 431,
        437, 438, 450, 506, 514, 519, 548, 579, 581, 587, 604, 613, 639, 647, 705, 709, 778, 780, 782, 807, 819, 825, 867, 873, 902, 905];


    static getCountryISOCode(phoneNumber: string): string | undefined {
        if (phoneNumber != null && phoneNumber.startsWith("+1")) {
            if (phoneNumber.length >= 5) {
                const areaCode: string = phoneNumber.substring(2, 5);
                if (this.canadaPhoneAreaCodes.indexOf(+areaCode) >= 0)
                    return "ca";
                else
                    return "us";
            } else
                return "ca";
        } else if (phoneNumber != null && phoneNumber.startsWith("+86"))
            return "cn";
        else
            return undefined;
    }

    static isPhoneNumber(value:string){
        return regexpCNPhone.test(value)||regexpUSPhone.test(value);
    }
}
