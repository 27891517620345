import * as React from "react";
import FtPanel from "../../ui/FtPanel";
import FssCompHeaderEditor from "./FssCompHeaderEditor";
import FssComponent from "../../model/FssComponent";
import FssSearchBoxCompData from "../../page/component/data/FssSearchBoxCompData";
import {Checkbox, FormControlLabel} from "@material-ui/core";

interface FssSearchBoxCompEditorProp {
    component: FssComponent;
    onChangeComponent?: (component: FssComponent) => void;
}

export class FssSearchBoxCompEditorState {
}


export default class FssSearchBoxCompEditor extends React.Component<FssSearchBoxCompEditorProp, FssSearchBoxCompEditorState> {

    constructor(props: any) {
        super(props);
        this.state = {};

        this.onChangeComponent = this.onChangeComponent.bind(this);
    }

    componentDidMount(): void {

    }

    render() {
        const component = this.props.component;
        const searchBoxCompContent = component.data as FssSearchBoxCompData;
        return <div>
            <FssCompHeaderEditor component={this.props.component} onChangeComponent={this.onChangeComponent}/>
            <FtPanel style={{padding: 16}}>
                <FormControlLabel label="显示返回按钮"
                                  control={<Checkbox checked={searchBoxCompContent.showBackIcon} color="primary"
                                                     onChange={(event, checked) => {
                                                         searchBoxCompContent.showBackIcon = checked;
                                                         this.onChangeComponent();
                                                     }}/>}/>
            </FtPanel>
        </div>

    }

    onChangeComponent() {
        if (this.props.onChangeComponent) {
            this.props.onChangeComponent(this.props.component);
        }
    }
}
