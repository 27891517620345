export default class FtState {
    static data: { [key: string]: any; } = {};

    public static put(key?: string, value?: any) {
        if (key && value)
            this.data[key] = value;
    }

    public static remove(key:string){
        this.data[key] = undefined;
    }

    public static pop(key?: string): any {
        if (key) {
            const value = this.data[key];
            FtState.data[key] = null;
            return value;
        } else
            return null;
    }

    public static get(key?: string): any {
        if (key) {
            const value = FtState.data[key];
            return value;
        } else
            return null;
    }
}
