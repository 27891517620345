import * as React from "react";
import {Avatar, Badge, createStyles, Theme, Typography, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import AppClientInfo from "../data/AppClientInfo";
import FtRow from "../ui/FtRow";
import {ArrowDropDown, ExitToApp} from "@material-ui/icons";
import FtDivFlex from "../ui/FtDivFlex";
import {grey} from "@material-ui/core/colors";
import {i18n} from "../util/I18n";
import FtToast from "../ui/FtToast";
import FtAdminMenu from "../model/FtAdminMenu";
import FtAdminFrame from "../ui/FtAdminFrame";
import FssCategoryListNewPage from "../category/FssCategoryListNewPage";
import {Route, RouteComponentProps} from "react-router-dom";
import FssLoginDialog from "../user/FssLoginDialog";
import FssClientData from "../data/FssClientData";
import FssProductListPage from "../product/FssProductListPage";
import FssOrderTabsPage from "../order/FssOrderTabsPage";
import FssOrderFindPage from "../order/FssOrderFindPage";
import FssProductDetailPage from "../product/FssProductDetailPage";
import FssSettingMainPage from "../setting/FssSettingMainPage";
import FssOrderDetailPage from "../order/FssOrderDetailPage";
import FssSellerEmployeeListPage from "../sellerEmployee/FssSellerEmployeeListPage";
import FssOrderSettingPage from "../setting/FssOrderSettingPage";
import FssMarketingMainPage from "../marketing/FssMarketingMainPage";
import FssCouponDetailPage from "../coupon/FssCouponDetailPage";
import FssCouponListPage from "../coupon/FssCouponListPage";
import FssUserCouponDetailPage from "../userCoupon/FssUserCouponDetailPage";
import FssUserCouponListPage from "../userCoupon/FssUserCouponListPage";
import FssCouponSettingTabsPage from "../marketing/FssCouponSettingTabsPage";
import JtShippingSettingTabsPage from "../setting/JtShippingSettingTabsPage";
import FssDeliveryProviderDetailPage from "../deliveryProvider/FssDeliveryProviderDetailPage";
import FssRegionDetailPage from "../region/FssRegionDetailPage";
import JtShippingFeeTempListPage from "../shippingfee/JtShippingFeeTempList";
import JtShippingFeeTempDetail from '../shippingfee/JtShippingFeeTempDetailPage';
import JtShippingFeeRuleDetailPage from "../shippingfee/JtShippingFeeRuleDetailPage";
import JtShippingScheduleDetailPage from "../shippingSchedule/JtShippingScheduleDetail";
import FssUserMediaListPage from "../userMedia/FssUserMediaListPage";
import FtImportProductPage from "../import/FtImportProductPage";
import FssPageListPage from "../page/FssPageListPage";
import FssPageDetailPage from "../page/FssPageDetailPage";
import FssReportMainPage from "../report/FssReportMainPage";
import FssPaymentTransactionPage from "../report/FssPaymentTransactionPage";
import FssSellerPage from "../setting/FssSellerPage";
import FssProductSaleListPage from "../report/FssProductSaleListPage";
import FssLanguageSettingPage from "../setting/FssLanguageSettingPage";
import JtTaxSettingListPage from "../tax/JtTaxSettingListPage";
import JtTaxSettingDetailPage from "../tax/JtTaxSettingDetailPage";
import JtApplicationSettingsPage from "../setting/JtApplicationSettingsPage";
import FssSellerEmployeeDetailPage from "../sellerEmployee/FssSellerEmployeeDetailPage";
import FssAdminMenuService from "../service/FssAdminMenuService";
import FssMyUserDetailPage from "../user/FssMyUserDetailPage";
import FssSelectSellerDialog from "./FssSelectSellerDialog";
import FtMessageDialog from "../ui/FtMessageDialog";
import FssSeller, {ESellerType} from "../model/FssSeller";
import FssSellerService from "../service/FssSellerService";
import FssUser from "../model/FssUser";
import FssUserService from "../service/FssUserService";
import FssShowSpotListPage from "../showSpot/FssShowSpotListPage";
import FssShowSpotDetailPage from "../showSpot/FssShowSpotDetailPage";
import FssMarketingEventDetailPage from "../marketing/FssMarketingEventDetailPage";
import FssMarketingEventListPage from "../marketing/FssMarketingEventListPage";
import FssProductReviewListPage from "../productReview/FssProductReviewListPage";
import FssProductReviewDetailPage from "../productReview/FssProductReviewDetailPage";
import FssOrderService from "../service/FssOrderService";
import {FssOrderStatistics} from "../model/FssOrderStatistics";
import FssApplyForPublisherListPage from "../marketing/FssApplyForPublisherListPage";
import SynDataPage from "../setting/synData/SynDataPage";
import {FssAdminSession} from "../model/FssWebSession";
import FssNotificationSettingPage from "../setting/FssNotificationSettingPage";
import FssProductExchangeRuleListPage from "../productExchangeRule/FssProductExchangeRuleListPage";
import FssProductExchangeRuleDetailPage from "../productExchangeRule/FssProductExchangeRuleDetailPage";
import FssShippedSumOrderPage from "../marketing/FssShippedSumOrderPage";
import FssSingleOrderPage from "../marketing/FssSingleOrderPage";
import FssPickUpSumOrderPage from "../marketing/FssPickUpSumOrderPage";
import FssChatPage from "../chat/FssChatPage";
import FssMessageService from "../service/FssMessageService";
import {chatWebsocket} from "../chat/FssChatWebsocket";
import FssChatClientData from "../chat/FssChatClientData";
import FssConversation from "../model/FssConversation";
import FssMessage from "../model/FssMessage";

export const drawerWidth = 160;
export const topBarHeight = 40;

interface FssSellerAdminMainPageProp extends RouteComponentProps {

}

class FssSellerAdminMainPageState {
    openDrawer: boolean = true;
    mobileViewMode: boolean = false;
    menuList: Array<FtAdminMenu> = [];
    unreadMessageCount: number = 0;
    openMessageBoxDialog: boolean = false;
    seller?: FssSeller;
    user?: FssUser;
}

export default class FssSellerAdminMainPage extends React.Component<FssSellerAdminMainPageProp, FssSellerAdminMainPageState> {
    chatPage!: FssChatPage;

    constructor(props: any) {
        super(props);
        let openDrawer: boolean = true;
        let mobileViewMode: boolean = false;
        //根据屏幕大小默认是否显示左侧栏
        if (AppClientInfo.clientInfo.viewMode && (AppClientInfo.clientInfo.viewMode === "mobile" || AppClientInfo.clientInfo.viewMode === "pad")) {
            openDrawer = false;
            mobileViewMode = true;
        }
        this.state = {
            openDrawer: openDrawer,
            mobileViewMode: mobileViewMode,
            menuList: [],
            unreadMessageCount: 0,
            openMessageBoxDialog: false
        };
        this.onClickLeftDrawerMenu = this.onClickLeftDrawerMenu.bind(this);
        this.onClickUserAvatar = this.onClickUserAvatar.bind(this);
        this.loadOrderStatistics = this.loadOrderStatistics.bind(this);
        this.loadUnreadMessageCount = this.loadUnreadMessageCount.bind(this);
        this.onChangeUnreadMessageCount = this.onChangeUnreadMessageCount.bind(this);
        this.loadConversationListForSeller = this.loadConversationListForSeller.bind(this);
        this.onSubscribeMassage = this.onSubscribeMassage.bind(this);
        this.onSubscribeRegisterCustomerUser = this.onSubscribeRegisterCustomerUser.bind(this);
    }

    componentDidMount(): void {
        const webSession = FssClientData.getData().webSession;
        if (FssClientData.isLogin()) {
            let sellerId = this.getSelectedSellerId(webSession.adminSession);
            if (sellerId.length > 0) {
                FtToast.showToastLoading();
                FssSellerService.getSellerById(sellerId, data => {
                    FtToast.closeToast();
                    if (data && data.sellerType === ESellerType.PUBLISHER) {
                        FssSelectSellerDialog.dialog.show();
                    }
                        // else if (data && data.sellerType === ESellerType.LITE) {
                        //     window.location.href = "/sellerAdminLite/index.html";
                    // }
                    else {
                        this.setState({seller: data});
                        FtToast.showToastLoading();
                        FssAdminMenuService.getMyMenuList(response => {
                                FtToast.closeToast();
                                this.setState({menuList: response}, () => {
                                    this.loadOrderStatistics();
                                    this.loadUnreadMessageCount();
                                });
                            }
                        );
                        if (webSession.userId) {
                            FssUserService.getMyUserDetail(data => this.setState({user: data}));
                        }
                    }
                });
            } else {
                FssSelectSellerDialog.dialog.show();
            }
        } else {
            FssClientData.clearWebSession();
            FssLoginDialog.loginDialog.show(() => {
                window.location.reload();
            });
        }
    }

    onClickLogout() {
        FtMessageDialog.dialog.openDialog(i18n("确定要退出吗？"), () => {
            FssClientData.clearWebSession();
            let number = window.location.href.indexOf("#");
            if (number > 0) {
                window.location.href = window.location.href.substr(0, number + 1);
            }
            window.location.reload();
        });
    }

    getSelectedSellerId(adminSession?: FssAdminSession) {
        if (adminSession && adminSession.sellerId && adminSession.sellerId.trim().length > 0) {
            return adminSession.sellerId;
        } else {
            return "";
        }
    }

    render() {
        const webSession = FssClientData.getData().webSession;
        let sellerId = this.getSelectedSellerId(webSession.adminSession);
        if (FssClientData.isLogin() && webSession.isSellerAdmin && sellerId.length > 0) {
            return <FtAdminFrame topBar={this.getTopBar()}
                                 leftDrawerMenu={this.state.menuList}
                                 leftDrawerOpenDefault={!this.state.mobileViewMode}
                                 onOpenLeftDrawer={(isOpen: boolean) => {
                                     this.setState({openDrawer: isOpen})
                                 }} leftDrawerWidth={drawerWidth}
                                 onClickLeftDrawerMenu={this.onClickLeftDrawerMenu}>
                <main style={{
                    paddingLeft: (this.state.openDrawer ? drawerWidth : 0),
                    paddingTop: topBarHeight,
                    backgroundColor: grey[200]
                }}>
                    <div style={{
                        // padding: 16,
                        // height: document.documentElement.clientHeight - 32 - topBarHeight,
                        padding: 0,
                        height: document.documentElement.clientHeight - topBarHeight,
                        overflow: "auto"
                    }}>
                        <Route path="/category/list" component={FssCategoryListNewPage} exact={true}/>
                        <Route path="/product/list" component={FssProductListPage} exact={true}/>
                        <Route path="/import/importProduct" component={FtImportProductPage} exact={false}/>
                        <Route path="/productDetail/:productId" component={FssProductDetailPage} exact={false}/>
                        <Route path="/order/list/:orderType/:objectId" component={FssOrderTabsPage} exact={true}/>
                        <Route path="/findOrderPage" component={FssOrderFindPage} exact={true}/>
                        <Route path="/settings" component={FssSettingMainPage} exact={false}/>
                        <Route path="/orderSetting" component={FssOrderSettingPage} exact={false}/>
                        <Route path="/notificationSetting" component={FssNotificationSettingPage} exact={false}/>
                        <Route path="/sellerProfile" component={FssSellerPage} exact={true}/>
                        <Route path="/orderDetail/:orderId" component={FssOrderDetailPage} exact={false}/>
                        <Route path="/sellerEmployeeList" component={FssSellerEmployeeListPage} exact={false}/>
                        <Route path="/marketSettings" component={FssMarketingMainPage} exact={false}/>
                        <Route path="/marketingEventList" component={FssMarketingEventListPage} exact={false}/>
                        <Route path="/marketingEventDetail/:id?" component={FssMarketingEventDetailPage} exact={false}/>
                        <Route path="/couponSetting" component={FssCouponSettingTabsPage} exact={false}/>
                        <Route path="/coupon/FssCouponListPage" component={FssCouponListPage} exact={false}/>
                        <Route path="/coupon/FssCouponDetailPage/:couponId" component={FssCouponDetailPage}
                               exact={false}/>
                        <Route path="/userCoupon/FssUserCouponListPage" component={FssUserCouponListPage}
                               exact={false}/>
                        <Route path="/userCoupon/FssUserCouponDetailPage/:couponId"
                               component={FssUserCouponDetailPage} exact={false}/>
                        <Route path="/shippingSetting" component={JtShippingSettingTabsPage} exact={false}/>
                        <Route path="/distributionSettings/:deliveryProviderId"
                               component={FssDeliveryProviderDetailPage}
                               exact={false}/>
                        <Route path="/fssRegionDetailPage/:regionId" component={FssRegionDetailPage} exact={false}/>
                        <Route path="/store/shippingFeeTemplateList" component={JtShippingFeeTempListPage}
                               exact={false}/>
                        <Route path="/store/shippingFeeTemplateDetail/:shippingFeeTemplateId/:deliveryProviderId"
                               component={JtShippingFeeTempDetail} exact={false}/>
                        <Route path="/shippingFeeRuleDetail/:id/:shippingFeeTemplateId"
                               component={JtShippingFeeRuleDetailPage} exact={false}/>
                        <Route path="/shippingScheduleDetail/:shippingScheduleId/:deliveryProviderId"
                               component={JtShippingScheduleDetailPage}
                               exact={false}/>
                        <Route path="/media/list" component={FssUserMediaListPage} exact={false}/>
                        <Route path="/page/list" component={FssPageListPage} exact={false}/>
                        <Route path="/pageDetail/:pageId" component={FssPageDetailPage} exact={false}/>
                        <Route path="/report" component={FssReportMainPage} exact={false}/>
                        <Route path="/paymentReport" component={FssPaymentTransactionPage} exact={false}/>
                        <Route path="/productSaleList" component={FssProductSaleListPage} exact={false}/>
                        <Route path="/languageSetting" component={FssLanguageSettingPage} exact={false}/>
                        <Route path="/taxSetting" component={JtTaxSettingListPage} exact={false}/>
                        <Route path="/taxSettings/:taxNameId" component={JtTaxSettingDetailPage} exact={false}/>
                        <Route path="/applicationSetting" component={JtApplicationSettingsPage} exact={false}/>
                        <Route path="/employee/detail/:employeeId" component={FssSellerEmployeeDetailPage}
                               exact={true}/>
                        <Route path="/myUserDetail" component={FssMyUserDetailPage} exact={false}/>
                        <Route path="/showSpot/list" component={FssShowSpotListPage} exact={false}/>
                        <Route path="/showSpot/detail/:id" component={FssShowSpotDetailPage} exact={false}/>
                        <Route path="/fssProductReviewListPage" component={FssProductReviewListPage} exact={false}/>
                        <Route path="/fssProductReviewDetailPage/:id" component={FssProductReviewDetailPage}
                               exact={false}/>
                        <Route path="/applyForPublisherList/:id" component={FssApplyForPublisherListPage}
                               exact={false}/>
                        <Route path="/synData" component={SynDataPage} exact={false}/>
                        <Route path="/fssExchangeRuleList" component={FssProductExchangeRuleListPage} exact={false}/>
                        <Route path="/fssExchangeRuleDetail/:id" component={FssProductExchangeRuleDetailPage}
                               exact={false}/>
                        <Route path="/shippedSumOrder/:id" component={FssShippedSumOrderPage} exact={false}/>
                        <Route path="/pickUpSumOrder/:id" component={FssPickUpSumOrderPage} exact={false}/>
                        <Route path="/singleOrder/:id" component={FssSingleOrderPage} exact={false}/>
                        {this.showChatPage() &&
                        <Route path="/chat"
                               render={() =>
                                   <FssChatPage onChangeUnreadMessageCount={this.onChangeUnreadMessageCount}
                                                ref={element => {
                                                    if (element) {
                                                        this.chatPage = element;
                                                    }
                                                }}/>
                               }/>}
                    </div>
                </main>
            </FtAdminFrame>;
        } else {
            return <div/>;
        }
    }

    getTopBar() {
        return <FtDivFlex justifyContent={"space-between"}
                          style={{paddingLeft: 8, flexGrow: 100, height: topBarHeight}}>
            {!this.state.mobileViewMode &&
            <FtRow cellVerticalSpace={0} cellHorizontalSpace={0}>
                <Button style={{color: "white"}}> Yolebuy Shop Admin </Button>
            </FtRow>}
            <FtRow cellVerticalSpace={0} cellHorizontalSpace={0}>
                <Button style={{color: "white"}} endIcon={<ArrowDropDown/>} onClick={() => {
                    FssSelectSellerDialog.dialog.show();
                }}>
                    {this.state.seller?.fssStore.storeName + " - " + this.state.seller?.shortName}
                </Button>
            </FtRow>
            <FtRow cellVerticalSpace={0} cellHorizontalSpace={0}>
                {!this.state.mobileViewMode &&
                <Typography variant={"subtitle2"} style={{marginRight: 8}}>{i18n("欢迎")}</Typography>}
                <Avatar style={{width: 32, height: 32}} alt="头像"
                        onClick={this.onClickUserAvatar}
                        src={this.state.user?.avatarUrl}/>
                <Button size={"small"} color={"inherit"}
                        onClick={this.onClickLogout}><ExitToApp/></Button>
            </FtRow>
        </FtDivFlex>;
    }

    onClickUserAvatar() {
        this.props.history.push("/myUserDetail");
    }

    private onClickLeftDrawerMenu(menuId: string) {
        if (menuId === "SellerEmployeeList") {
            this.props.history.push("/sellerEmployeeList");
        }
        switch (menuId as EMenuitem) {
            case EMenuitem.Dashboard:
                // this.props.history.push("/dashboard");
                this.props.history.push("/");
                return;
            case EMenuitem.CategoryList:
                this.props.history.push("/category/list");
                return;
            case EMenuitem.ProductList:
                this.props.history.push("/product/list");
                return;
            case EMenuitem.WaitApproveOrderList:
                this.props.history.push("/order/list/" + EMenuitem.WaitApproveOrderList + "/0");
                return;
            case EMenuitem.WaitPayOrderList:
                this.props.history.push("/order/list/" + EMenuitem.WaitPayOrderList + "/0");
                return;
            case EMenuitem.ShippedOrderList:
                this.props.history.push("/order/list/" + EMenuitem.ShippedOrderList + "/0");
                return;
            case EMenuitem.WaitShippingOrderList:
                this.props.history.push("/order/list/" + EMenuitem.WaitShippingOrderList + "/0");
                return;
            case EMenuitem.OrderList:
                this.props.history.push("/order/list/" + EMenuitem.OrderList + "/0");
                return;
            case EMenuitem.FindOrder:
                this.props.history.push("/findOrderPage");
                return;
            case EMenuitem.MarketSettings:
                this.props.history.push("/marketSettings");
                return;
            case EMenuitem.Settings:
                this.props.history.push("/settings");
                return;
            case EMenuitem.UserMedia:
                this.props.history.push("/media/list");
                return;
            case EMenuitem.Page:
                this.props.history.push("/page/list");
                return;
            case EMenuitem.Report:
                this.props.history.push("/report");
                return;
            case EMenuitem.ShowSpot:
                this.props.history.push("/showSpot/list");
                return;
            case EMenuitem.Chat:
                this.props.history.push("/chat");
                return;
        }
    }

    loadUnreadMessageCount() {
        if (this.state.menuList && this.state.menuList.length > 0) {
            this.state.menuList.forEach((menu: FtAdminMenu) => {
                if (menu.menuCode != null && menu.menuCode == "Chat") {
                    FssMessageService.getUnreadMessageCountForSeller((data: number) => {
                        this.onChangeUnreadMessageCount(data);
                    });
                    // 初始化websocket
                    chatWebsocket.activate(() => {
                        // 首页先获取所有的 conversation
                        this.loadConversationListForSeller();
                        this.onSubscribeRegisterCustomerUser();
                    });
                    return;
                }
            })
        }
    }

    onSubscribeRegisterCustomerUser() {
        // 监听系统信息
        const subId = "REGISTER_NEW_USER";
        let adminSession = FssClientData.getData().webSession.adminSession;
        if (adminSession != null && adminSession.sellerId != null) {
            chatWebsocket.subscribeSystem(subId, adminSession.sellerId, () => {
                window.setTimeout(() => {
                    FssChatClientData.removeAllConversationSubscription();
                    this.loadConversationListForSeller();
                }, 2000);
            }, subId);
        }
    }

    loadConversationListForSeller() {
        FssChatClientData.loadConversationList(() => {
            this.forceUpdate(this.onSubscribeMassage);
        });
    }

    onSubscribeMassage() {
        // 订阅聊天消息
        let conversationList = FssChatClientData.getData().conversationList;
        if (conversationList != null && conversationList.length > 0) {
            conversationList.forEach((conversation: FssConversation, index: number) => {
                if (conversation != null && conversation.id != null) {
                    let conversationId = conversation.id;
                    FssMessageService.getConversationById(conversationId, (newConversation: FssConversation) => {
                        //将messages反序
                        if (newConversation != null && newConversation.messages != null) {
                            newConversation.messages = newConversation.messages.reverse();
                        }
                        chatWebsocket.subscribe(conversationId, (receivedMessage: FssMessage) => {
                            if (newConversation.messages === null || newConversation.messages === undefined) {
                                newConversation.messages = [];
                            }
                            newConversation.lastMessageId = receivedMessage.id;
                            newConversation.lastMessageTime = receivedMessage.messageTime;
                            newConversation.messages.push(receivedMessage);
                            this.forceUpdate();
                            let pathname = this.props.location.pathname;
                            const currentConversation = FssChatClientData.getData().currentConversation;
                            if (pathname == "/chat" && currentConversation != null && currentConversation.id == newConversation.id) {
                                this.chatPage.chatConversationComp.onMessagesChanged(newConversation.messages);
                            } else {
                                if (newConversation.unreadMessageCount) {
                                    newConversation.unreadMessageCount++;
                                } else {
                                    newConversation.unreadMessageCount = 1;
                                }
                                this.onChangeUnreadMessageCount(1);
                            }
                        }, conversationId);
                        if (conversationList != null) {
                            conversationList[index] = newConversation;
                            this.forceUpdate();
                        }
                    });
                }
            });
        }
    }

    onChangeUnreadMessageCount(data: number) {
        this.setState({unreadMessageCount: this.state.unreadMessageCount + data}, () => {
            this.state.menuList.forEach((adminMenu: FtAdminMenu) => {
                if (adminMenu.menuCode == "Chat") {
                    let badge = <StyledBadge badgeContent={this.state.unreadMessageCount}/>
                    adminMenu.itemSecondaryAction = this.state.unreadMessageCount > 0 ? badge : undefined;
                }
                adminMenu.primaryText = i18n(adminMenu.primaryText);
            })
            this.forceUpdate();
        })
    }

    loadOrderStatistics() {
        if (this.state.menuList && this.state.menuList.length > 0) {
            this.state.menuList.forEach((menu: FtAdminMenu) => {
                if (menu.menuCode && menu.menuCode === "order") {
                    FssOrderService.getOrderStatisticsBySeller((data: FssOrderStatistics) => {
                        if (menu.subMenuList != null && menu.subMenuList.length > 0) {
                            menu.subMenuList.forEach((childMenu: FtAdminMenu) => {
                                let childMenuitem = childMenu.menuCode as EMenuitem;
                                if (childMenuitem === EMenuitem.WaitShippingOrderList) {
                                    childMenu.primaryText = i18n(childMenu.primaryText) + "(" + data.waitShippingOrderCount + ")";
                                } else if (childMenuitem === EMenuitem.WaitApproveOrderList) {
                                    childMenu.primaryText = i18n(childMenu.primaryText) + "(" + data.waitApproveOrderCount + ")";
                                } else if (childMenuitem === EMenuitem.WaitPayOrderList) {
                                    childMenu.primaryText = i18n(childMenu.primaryText) + "(" + data.waitPayOrderCount + ")";
                                } else if (childMenuitem === EMenuitem.ShippedOrderList) {
                                    childMenu.primaryText = i18n(childMenu.primaryText) + "(" + data.shippedOrderCount + ")";
                                } else if (childMenuitem === EMenuitem.OrderList) {
                                    childMenu.primaryText = i18n(childMenu.primaryText) + "(" + data.allOrderCount + ")";
                                }
                            });
                        }
                        this.forceUpdate();
                    });
                }
            });
        }
    }

    showChatPage() {
        let conversationList = FssChatClientData.getData().conversationList;
        return conversationList != null && conversationList.length > 0;
    }
}

const StyledBadge = withStyles((theme: Theme) =>
    createStyles({
        badge: {
            right: 4,
            border: `1px solid`,
            padding: '0 4px',
        },
    }),
)(Badge);

export enum EMenuitem {
    Dashboard = "Dashboard",
    FtOrderBoard = "FtOrderBoard",
    OrderAdd = "OrderAdd",
    FindOrder = "FindOrder",
    WaitShippingOrderList = "WaitShippingOrderList",
    WaitPayOrderList = "WaitPayOrderList",
    WaitApproveOrderList = "WaitApproveOrderList",
    ShippedOrderList = "ShippedOrderList",
    ExceptionOrderList = "ExceptionOrderList",
    UserOrderList = "UserOrderList",
    OrderList = "OrderList",
    MarketingOrderList = "MarketingOrderList",
    ProductList = "ProductList",
    CategoryList = "CategoryList",
    StoreProfile = "StoreProfile",
    UserMedia = "UserMedia",
    PageContent = "PageContent",
    Page = "Page",
    ShowSpot = "ShowSpot",
    AppSetting = "AppSetting",
    PaymentTransactionList = "PaymentTransactionList",
    UserProfile = "UserProfile",
    UserAddress = "UserAddress",
    JtPaymentTransactionTabsPage = "JtPaymentTransactionTabsPage",
    ShippingScheduleList = "ShippingScheduleList",
    ShippingFeeTemplateList = "ShippingFeeTemplateList",
    JtLightSpeedPage = "JtLightSpeedPage",
    DistributionSetting = "DistributionSetting",
    JtCoupon = "JtCoupon",
    JtUserCoupon = "JtUserCoupon",
    JtScoreRuleListIn = "JtScoreRuleListIn",
    JtScoreRuleListOut = "JtScoreRuleListOut",
    JtUserBalanceList = "JtUserBalanceList",
    TaxSetting = "TaxSetting",
    JtExchangeRuleList = "JtExchangeRuleList",
    JtBatchSendSMS = "JtBatchSendSMS",
    JtLuckDraw = "JtLuckDraw",
    Settings = "Settings",
    MarketSettings = "MarketSettings",
    Report = "report",
    Chat = "Chat",
}
