import * as React from "react";
import {ChangeEvent} from "react";
import Button from "@material-ui/core/Button";
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField} from '@material-ui/core';
import FtPhoneCodeUtil from "../util/FtPhoneCodeUtil";
import FtToast from "../ui/FtToast";
import FssUserService from "../service/FssUserService";
import FtGrid from "../ui/FtGrid";
import FtSnackbar from "../ui/FtSnackbar";
import FtRow from "../ui/FtRow";
import FtTextFieldPhone from "../ui/FtTextFieldPhone";
import FtDivFlex from "../ui/FtDivFlex";


export interface FssUpdateMobileDialogProp {
    open: boolean;
    handleClose: (open: boolean) => void;
    updateMobile: (mobile: string) => void;
}

export class FssUpdateMobileDialogState {
    mobile: string = "";
    verifyCodeMessage?: string;
    second: number = 0;
    intervalId?: number;
    verifyCode: string = "";
    verifyCodeId: string = "";
}


export default class FssUpdateMobileDialog extends React.Component<FssUpdateMobileDialogProp, FssUpdateMobileDialogState> {

    constructor(props: FssUpdateMobileDialogProp) {
        super(props);
        this.state = {
            verifyCodeMessage: "获取验证码",
            mobile: "",
            second: 0,
            verifyCode: "",
            verifyCodeId: "",
        };
        this.onChange = this.onChange.bind(this);
        this.onSendVerifyCode = this.onSendVerifyCode.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.updateMobile = this.updateMobile.bind(this);
    }
    /**
     * 输入框修改事件
     */
    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) {
        if (event.target.id == "verifyCode") {
            this.setState({ verifyCode: event.target.value });
        } else if (event.target.id == "mobile") {
            this.setState({ mobile: event.target.value });
        }

        this.forceUpdate();
    }
    /**
     * 关闭当前对话框，并把值清空
     */
    handleClose(): void {
        this.setState({
            mobile: "",
            verifyCode: "",
            second: 0,
            verifyCodeMessage: "获取验证码",
        });
        this.props.handleClose(false);
    }
    /**
     * 发送验证码
     */
    onSendVerifyCode() {
        if (this.state.second > 0) {
            return;
        }
        if (this.state.mobile && FtPhoneCodeUtil.isPhoneNumber(this.state.mobile)) {
            FtToast.showToastLoading("正在发送短信验证码", 10000);
            // FssUserService.sendVerificationCode(this.state.mobile, (data: string) => {
            //     FtToast.closeToast();
            //     this.setState({ verifyCodeId: data, second: 60 });
            //     const intervalId = window.setInterval(() => {
            //         if (this.state.second > 0) {
            //             this.setState({ second: this.state.second - 1, verifyCodeMessage: this.state.second + "秒后可重发" });
            //         } else {
            //             window.clearInterval(this.state.intervalId);
            //             this.setState({ verifyCodeMessage: "获取验证码" });
            //         }
            //     }, 1000);
            //     this.setState({ intervalId: intervalId });
            // })
        } else {
            FtSnackbar.FtSnackbar.show("请输入有效的手机号");
        }
    }

    /**
     * 保存修改的值
     */
    updateMobile(): void {
        if (!this.state.mobile) {
            FtSnackbar.showText("请输入手机号", 3000);
            return;
        } else if (!this.state.verifyCode) {
            FtSnackbar.showText("请输入验证码", 3000);
            return;
        }
        FssUserService.updateMyUserMobile(this.state.mobile, this.state.verifyCode, this.state.verifyCodeId, () => {
            this.props.updateMobile(this.state.mobile);
            this.setState({
                mobile: "",
                verifyCode: "",
                second: 0,
                verifyCodeMessage: "获取验证码",
            });
            FtSnackbar.showText("修改手机号成功！");
            this.props.handleClose(false);
        });
    }
    render() {
        return <Dialog open={this.props.open} onClose={this.handleClose} maxWidth="md" aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">更改手机号</DialogTitle>
            <Divider />
            <DialogContent>
                <FtGrid>
                    <FtRow cellWidthS={"1"} maxRowWidth={"s"} cellHorizontalSpace={8}>
                        <FtTextFieldPhone id="mobile" label="手机号" value={this.state.mobile} required={true} style={{ width: "100%" }}
                                          onChangeValue={(value)=>{
                                              this.setState({ mobile: value ? value : "" });
                                          }}/>
                        <FtRow cellWidthS={"9,3"} maxRowWidth={"s"} justifyContent={"center"} style={{alignItems: "flex-end"}}>
                            <TextField id="verifyCode" type="text" label={"短信验证码"} style={{ width: "100%" }} value={this.state.verifyCode} onChange={this.onChange} required={true} />
                            <Button variant={"outlined"} size={"small"} onClick={this.onSendVerifyCode} style={{ width: "100%" }}>{this.state.verifyCodeMessage}</Button>
                        </FtRow>
                    </FtRow>
                </FtGrid>
            </DialogContent>
            <DialogActions style={{ margin: "0 auto", border: 0 }}>
                <FtDivFlex justifyContent={"center"} style={{ marginTop: 16 }}>
                    <Button color={"primary"} variant={"contained"} style={{ marginRight: 16 }} onClick={this.handleClose}>
                        取消
                    </Button>
                    <Button color={"primary"} variant={"contained"} onClick={this.updateMobile}>
                        确认
                    </Button>
                </FtDivFlex>
            </DialogActions>
        </Dialog>
    }
}
