import {FtAbstractModel} from "../model/FtAbstractModel";
import FssUser from "./FssUser";
import FssStore from "./FssStore";
export enum EStatus {
	NORMAL="NORMAL", DISABLED="DISABLED"
}
export default class FssPublisher extends FtAbstractModel{

	publisherCode:string="";
	status:EStatus=EStatus.NORMAL;
	nickName:string="";
	user:FssUser=new FssUser();
	statusText:string="";
	email:string="";
	mobile:string="";
	sellerId:string="";
	storeId:string="";
	fssStore:FssStore = new FssStore();

}
