import FssComponent from "./FssComponent";

export enum EStatus {
    NORMAL = "NORMAL", STOP = "STOP"
}

export default class FssPageComponent {
    createDatetimeText: string = "";
    status: EStatus = EStatus.NORMAL;
    storeId: string = "";
    sellerId: string = "";
    component?: FssComponent;
    id: string = "";
    pageId: string = "";
    reference: boolean = false;
    sortIndex: number = 0;

    constructor(pageId?:string,component?: FssComponent) {
        if(pageId) {
            this.pageId=pageId;
        }
        this.component = component;
    }
}
