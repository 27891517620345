import FtApiResponse from "../model/FtApiResponse";
import FtPagedList from "../model/FtPagedList";
import FssHttpService from "./FssHttpService";
import FtTranslation from "../model/FtTranslation";

export default class FssTranslationService extends FssHttpService {

    static saveTranslation(translation: FtTranslation, successFunction: (result: FtApiResponse) => void) {
        this.postJson("/api/translation/saveTranslation", translation, successFunction);
    }

    static getTranslationContent(objectId: string, groupKey: string, itemKey: string, lang: string, successFunction: (data: Array<FtTranslation>) => void) {
        let params = {objectId: objectId, groupKey: groupKey, itemKey: itemKey, lang: lang};
        this.get("/api/translation/getTranslationContent", params, successFunction);
    }

    static getTranslationPaged(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                               pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FtTranslation>) => void): void {
        const uri = "/api/translation/getTranslationPaged";
        this.search(uri, searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize, successFunction, this.handleError);
    }

    static deleteTranslation(translationArray: Array<FtTranslation>, successFunction: (result: string) => void) {
        this.postJson("/api/translation/deleteTranslation", translationArray, successFunction);
    }
}
