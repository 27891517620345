import * as React from "react";
import FtComboboxDynamic from "../ui/FtComboboxDynamic";
import FssHttpService from "../service//FssHttpService";
import FssMarketingEvent, {EType} from "../model/FssMarketingEvent";
import FssMarketingEventService from "../service/FssMarketingEventService";
import {ETwoStatus} from "../model/FtAbstractModel";

interface FssMarketingEventComboBoxProp {
    label?: string;
    marketingEvent?: FssMarketingEvent;
    onChange?: (marketingEvent?: FssMarketingEvent) => void;
}

class FssMarketingEventComboBoxState {
    marketingEventList: Array<FssMarketingEvent> = [];
    marketingEvent?: FssMarketingEvent;
    loading:boolean=false;
    filterColumns: Map<string, any> = new Map<string, any>()
}

export default class FssMarketingEventComboBox extends React.Component<FssMarketingEventComboBoxProp, FssMarketingEventComboBoxState> {
    constructor(props: any) {
        super(props);
        let filterColumns = new Map<string, any>();
        filterColumns.set("status", [ETwoStatus.NORMAL]);
        filterColumns.set("types", [EType.JIE_LONG]);
        this.state = {marketingEventList: [], loading: false, filterColumns: filterColumns};
        this.onChangeInput = this.onChangeInput.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(): void {

    }

    render() {
        let marketingEventList = this.state.marketingEventList;
        if((marketingEventList == null || marketingEventList.length == 0) && this.props.marketingEvent != null) {
            marketingEventList = [this.props.marketingEvent];
        }
        return <FtComboboxDynamic label={this.props.label} optionItems={marketingEventList}
                                  labelPropertyName={"name"}
                                  valuePropertyName={"id"}
                                  selectedItem={this.props.marketingEvent}
                                  onSelectItem={this.onChange}
                                  onChangeInput={this.onChangeInput}
                                  loading={this.state.loading} style={{width:"100%"}} />;
    }

    onChangeInput(keyword: string) {
        if(!this.state.loading) {
            this.setState({loading: true}, () => {
                FssMarketingEventService.getAvailMarketingEventList(keyword,
                    (result: Array<FssMarketingEvent>)=>{
                        this.setState({marketingEventList: result, loading: false});
                    },(error:any)=>{
                        FssHttpService.handleError(error);
                        this.setState({loading:false});
                    })
            });
        }
    }

    onChange(item: any) {
        this.setState({marketingEvent: item});
        if(this.props.onChange) {
            this.props.onChange(item);
        }
    }
}
