
export class FssAbstractCompData{
    marginLeft?:string|number;
    marginRight?:string|number;
    marginTop?:string|number;
    marginBottom?:string|number;
    borderTopLeftRadius?:string|number;
    borderTopRightRadius?:string|number;
    borderBottomLeftRadius?:string|number;
    borderBottomRightRadius?:string|number;
    backgroundColor?:string;
    color?:string;
}
