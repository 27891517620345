import * as React from "react";
import {Checkbox, FormControlLabel, FormGroup} from "@material-ui/core";

export interface FtCheckboxGroupProp {
    labels: Array<string>;
    values: Array<any>;
    checkedValues?: Array<any>;
    showCheckAll?: boolean;
    row?: boolean;
    onCheck?: (checkedValues: Array<any>) => void;
}

export class FtCheckboxGroupState {
}

export default class FtCheckboxGroup extends React.Component<FtCheckboxGroupProp, FtCheckboxGroupState> {
    constructor(props: any) {
        super(props);
        const checkedStatus: Array<boolean> = [];
        this.props.labels.forEach((label: string, index: number) => {
            checkedStatus.push(false);
        });
        this.state = {checkedStatus: checkedStatus};
    }

    onChange(index: number, checked: boolean) {
        if (this.props.onCheck) {
            let checkedValue: Array<any> =[];
            let findIndex:number=-1;
            if(this.props.checkedValues){
                checkedValue= [...this.props.checkedValues];
                findIndex = this.props.checkedValues.indexOf(this.props.values[index]);
            }
            if (checked && findIndex < 0) {
                checkedValue.push(this.props.values[index]);
                this.props.onCheck(checkedValue);
            } else if (!checked && findIndex >= 0) {
                checkedValue.splice(findIndex, 1);
                this.props.onCheck(checkedValue);
            }

        }
        this.forceUpdate();
    }

    render() {
        return <FormGroup row={this.props.row}>
            {this.props.labels.map((label: string, index: number) => {
                let findIndex:number=-1;
                if(this.props.checkedValues){
                    findIndex = this.props.checkedValues.indexOf(this.props.values[index]);
                }
                return <FormControlLabel key={index}
                                         control={<Checkbox checked={findIndex>=0}
                                                            onChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                                                this.onChange(index, checked)
                                                            }} value={this.props.values[index]}/>}
                                         label={label}/>
            })}
        </FormGroup>;
    }
}
