import * as React from "react";
import FssCompHeaderEditor from "./FssCompHeaderEditor";
import FtPanel from "../../ui/FtPanel";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FtRow from "../../ui/FtRow";
import FssImageListSelected from "./FssImageListSelected";
import {i18n} from "../../util/I18n";
import FssComponent from "../../model/FssComponent";
import FssImageListCompData, {EImageListLayout} from "../../page/component/data/FssImageListCompData";
import JtTextField from "../../ui/JtTextField";
import {Checkbox, FormControlLabel, Switch, Typography} from "@material-ui/core";
import lodash from "lodash";
import Button from "@material-ui/core/Button";
import {AddBox} from "@material-ui/icons";
import FtToolbar from "../../ui/FtToolbar";

interface FssImageListCompEditorProp {
    component: FssComponent;
    onChangeComponent?: (component: FssComponent) => void;
}

export class FssImageListCompEditorState {
}

export default class FssImageListCompEditor extends React.Component<FssImageListCompEditorProp, FssImageListCompEditorState> {
    constructor(props: any) {
        super(props);
        this.onChangeComponent = this.onChangeComponent.bind(this);
    }

    componentDidMount(): void {

    }

    render() {
        const component = this.props.component;
        const imageListCompData = component.data as FssImageListCompData;
        if (!imageListCompData.layout) {
            imageListCompData.layout = EImageListLayout.ROW;
        }
        return <div key={component.id}>
            <FssCompHeaderEditor component={this.props.component} onChangeComponent={this.onChangeComponent}/>
            <FtPanel panelHeader={<Typography variant={"h6"}>{i18n("通用参数")}</Typography>} style={{padding: 16}}>
                <FtRow cellWidthM={"1"} cellHorizontalAlign={"left"}>
                    <FormControl style={{width: 300}}>
                        <InputLabel>{i18n("图片展示方式")}</InputLabel>
                        <Select id="mobileLineNum" value={imageListCompData.layout}
                                onChange={(event) => {
                                    imageListCompData.layout = event.target.value as EImageListLayout;
                                    this.onChangeComponent();
                                }}>
                            <MenuItem value={EImageListLayout.CAROUSEL}>{EImageListLayout.CAROUSEL}</MenuItem>
                            <MenuItem value={EImageListLayout.ROW}>{EImageListLayout.ROW}</MenuItem>
                            {/*<MenuItem value={EImageListLayout.COLUMN}>{EImageListLayout.COLUMN}</MenuItem>*/}
                        </Select>
                    </FormControl>
                    {imageListCompData.layout == EImageListLayout.ROW &&
                    <JtTextField id="title" label={i18n("标题")} value={imageListCompData.title}
                                 onChange={(event) => {
                                     imageListCompData.title = event.target.value;
                                     this.onChangeComponent();
                                 }} style={{width: 300}}/>}
                    {imageListCompData.layout == EImageListLayout.ROW &&
                    <FormControlLabel label="显示更多"
                                      control={<Checkbox checked={imageListCompData.showMore} color="primary"
                                                         onChange={(event, checked) => {
                                                             imageListCompData.showMore = checked;
                                                             this.onChangeComponent();
                                                         }}/>}/>}
                    {imageListCompData.layout == EImageListLayout.ROW &&
                    <JtTextField id="title" label={i18n("更多链接URL")} value={imageListCompData.moreLink}
                                 disabled={!imageListCompData.showMore}
                                 onChange={(event) => {
                                     imageListCompData.moreLink = event.target.value;
                                     this.onChangeComponent();
                                 }} style={{width: 500}}/>}
                    {imageListCompData.layout == EImageListLayout.ROW &&
                    <FtRow cellWidthM={"1,1,1,1"} cellVerticalSpace={0} cellHorizontalSpace={0}>
                        <FormControl style={{width: "95%"}}>
                            <InputLabel>{i18n("左右间距")}</InputLabel>
                            <Select id="cellHorizontalSpace" value={imageListCompData.cellHorizontalSpace ? imageListCompData.cellHorizontalSpace : 0}
                                    onChange={(event) => {
                                        imageListCompData.cellHorizontalSpace = event.target.value as number;
                                        this.onChangeComponent();
                                    }}>
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                                <MenuItem value={16}>16</MenuItem>
                                <MenuItem value={32}>32</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl style={{width: "95%"}}>
                            <InputLabel>{i18n("上下间距")}</InputLabel>
                            <Select id="cellVerticalSpace" value={imageListCompData.cellVerticalSpace ? imageListCompData.cellVerticalSpace : 0}
                                    onChange={(event) => {
                                        imageListCompData.cellVerticalSpace = event.target.value as number;
                                        this.onChangeComponent();
                                    }}>
                                <MenuItem value={0}>0</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={4}>4</MenuItem>
                                <MenuItem value={8}>8</MenuItem>
                                <MenuItem value={12}>12</MenuItem>
                                <MenuItem value={16}>16</MenuItem>
                                <MenuItem value={32}>32</MenuItem>
                            </Select>
                        </FormControl>
                    </FtRow>}
                </FtRow>
            </FtPanel>
            <FtRow cellWidthM={"1,1"} cellHorizontalAlign={"left"} cellVerticalAlign={"top"} cellHorizontalSpace={4} justifyContent={"space-between"}>
                <FtPanel panelHeader={i18n("手机版")}>
                    {imageListCompData.layout && imageListCompData.layout !== EImageListLayout.CAROUSEL &&
                    <FormControl style={{width: 220, padding: 8}}>
                        <InputLabel>{i18n("每行显示数量")}</InputLabel>
                        <Select id="mobileLineNum"
                                value={imageListCompData.mobileLineNum ? imageListCompData.mobileLineNum : 1}
                                onChange={(event) => {
                                    imageListCompData.mobileLineNum = event.target.value as number;
                                    this.onChangeComponent();
                                }}>
                            <MenuItem value={1}>{i18n("每行【一张】图片")}</MenuItem>
                            <MenuItem value={2}>{i18n("每行【两张】图片")}</MenuItem>
                            <MenuItem value={3}>{i18n("每行【三张】图片")}</MenuItem>
                        </Select>
                    </FormControl>}
                    <hr style={{width: "98%"}}></hr>
                    <FssImageListSelected key={component.id + "_mobile"} imageListCompData={imageListCompData}
                                          onChangeComponent={this.onChangeComponent} showType="mobile"/>
                </FtPanel>
                <FtPanel>
                    <FtToolbar style={{minHeight: 36, paddingRight: 12}}
                               leftNode={<Button>{i18n("电脑版")}</Button>}
                               rightNode={<FormControlLabel
                                   control={<Switch checked={imageListCompData.likeMobile}
                                                    color="primary" style={{fontSize: 12}}/>
                                   } label={<Typography variant="caption"
                                                        gutterBottom>{i18n("与手机版一致")}</Typography>}
                                   labelPlacement="start"
                               />} />
                    {imageListCompData.layout && imageListCompData.layout !== EImageListLayout.CAROUSEL &&
                    <FormControl style={{width: 220, padding: 8}} disabled={true}>
                        <InputLabel>{i18n("每行显示数量")}</InputLabel>
                        <Select id="mobileLineNum"
                                value={imageListCompData.mobileLineNum ? imageListCompData.mobileLineNum : 1}
                                onChange={(event) => {
                                    imageListCompData.mobileLineNum = event.target.value as number;
                                    this.onChangeComponent();
                                }}>
                            <MenuItem value={1}>{i18n("每行【一张】图片")}</MenuItem>
                            <MenuItem value={2}>{i18n("每行【两张】图片")}</MenuItem>
                            <MenuItem value={3}>{i18n("每行【三张】图片")}</MenuItem>
                        </Select>
                    </FormControl>}
                    <hr style={{width: "98%"}}></hr>
                    <FssImageListSelected key={component.id + "_pc"} imageListCompData={imageListCompData}
                                          onChangeComponent={this.onChangeComponent} showType="pc"/>
                </FtPanel>
            </FtRow>
        </div>
    }

    onChangeComponent() {
        if (this.props.onChangeComponent) {
            this.props.onChangeComponent(this.props.component);
        }
    }
}
