import FtPagedList from "../model/FtPagedList";
import {ProductSaleInfo} from "../model/ProductSaleInfo";
import FssHttpService from "./FssHttpService";

export default class FssProductReportService {
    static getJtProductSaleList(searchKeyword: string,dateType:string, startDate: string, endDate: string,supplierName:string,categoryList:Array<string>,orderStatus:string,
                                tagsList:Array<string>,includeScheduleTimeIsNull:boolean, pageNo: number = 0, pageSize: number = 20, successFunction: (result: FtPagedList<ProductSaleInfo>) => void) {
        FssHttpService.postFormData("/api/report/getJtProductSaleList", {
            searchKeyword: searchKeyword,
            dateType:dateType,
            startDate: startDate,
            endDate: endDate,
            supplierName:supplierName,
            categoryList:categoryList,
            orderStatus:orderStatus,
            tagsList:tagsList,
            includeScheduleTimeIsNull:includeScheduleTimeIsNull,
            page: pageNo,
            size: pageSize
        }, successFunction, FssHttpService.handleError);
    }
    static getJtProductSaleListForAdmin(storeId:string,sellerId:string,searchKeyword: string,dateType:string, startDate: string, endDate: string,supplierName:string,categoryList:Array<string>,orderStatus:string,
                                tagsList:Array<string>,includeScheduleTimeIsNull:boolean, pageNo: number = 0, pageSize: number = 20, successFunction: (result: FtPagedList<ProductSaleInfo>) => void) {
        FssHttpService.postFormData("/api/report/getJtProductSaleListForAdmin", {
            searchKeyword: searchKeyword,
            dateType:dateType,
            startDate: startDate,
            endDate: endDate,
            supplierName:supplierName,
            categoryList:categoryList,
            orderStatus:orderStatus,
            tagsList:tagsList,
            includeScheduleTimeIsNull:includeScheduleTimeIsNull,
            page: pageNo,
            size: pageSize,
            storeId:storeId,
            sellerId:sellerId
        }, successFunction, FssHttpService.handleError);
    }
}
