import FtRegion from "../model/FtRegion";
import FssHttpService from "./FssHttpService";

export default class FssCityService {
    static getRegionByStoreId(countryInfoId: string, successFunction: (result: FtRegion) => void) {
        FssHttpService.get("/api/city/getRegionByStoreId", {countryInfoId: countryInfoId}, successFunction, FssHttpService.handleError);
    }

    static getRegionList(successFunction: (result: Array<FtRegion>) => void) {
        FssHttpService.get("/api/city/getRegionList", {}, successFunction, FssHttpService.handleError);
    }
}
