import FtComponent, {FtComponentState} from "../ui/FtComponent";
import React, {ReactNode} from "react";
import Button from "@material-ui/core/Button";
import {ChevronLeft, Close, CropOriginal, Edit} from "@material-ui/icons";
import {i18n} from "../util/I18n";
import {RouteComponentProps} from "react-router-dom";
import FtPanel from "../ui/FtPanel";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtTextFieldString from "../ui/FtTextFieldString";
import {
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    IconButton,
    InputAdornment,
    MenuItem,
    Tab,
    Tabs,
    TextField,
    Typography
} from "@material-ui/core";
import FssMarketingEvent, {EStatus, EType} from "../model/FssMarketingEvent";
import FtToast from "../ui/FtToast";
import FssMarketingEventService from "../service/FssMarketingEventService";
import FtStringUtil from "../util/FtStringUtil";
import FtSnackbar from "../ui/FtSnackbar";
import FtTextFieldDate from "../ui/FtTextFieldDate";
import FssProductService from "../service/FssProductService";
import FssClientData from "../data/FssClientData";
import JtMceEditor from "../common/JtMceEditor";
import {grey} from "@material-ui/core/colors";
import FtMediaViewerDialog from "../ui/FtMediaViewerDialog";
import JtUserMedia from "../model/FssUserMedia";
import FssUserMediaSelectDialog from "../userMedia/FssUserMediaSelectDialog";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import FssDeliveryProvider, {EDeliveryType} from "../model/FssDeliveryProvider";
import FssDeliveryProviderService from "../service/FssDeliveryProviderService";
import FssMarketingEventDeliveryProvider from "../model/FssMarketingEventDeliveryProvider";
import _ from "lodash";
import FtMessageDialog from "../ui/FtMessageDialog";
import {EMenuitem} from "../mainframe/FssSellerAdminMainPage";
import FtAppBar from "../ui/FtAppBar";
import FssProduct from "../model/FssProduct";
import FtUtil from "../util/FtUtil";
import FssMarketingEventDetailRightMenuComp from "./FssMarketingEventDetailRightMenuComp";
import FssProductComboBox from "../product/FssProductComboBox";
import FssProductLiteDialog from "../product/FssProductLiteDialog";
import FssPickUpProviderDialog from "../setting/FssPickUpProviderDialog";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import FtToolbar from "../ui/FtToolbar";
import TranslateIcon from "@material-ui/icons/Translate";
import {Language} from "../data/AppClientInfo";
import FssTranslationService from "../service/FssTranslationService";
import FtTranslation from "../model/FtTranslation";

export interface FssMarketingEventDetailPageProp extends RouteComponentProps<{ id: string }> {

}

export class FssMarketingEventDetailPageState extends FtComponentState {
    model: FssMarketingEvent = new FssMarketingEvent();
    producatTagList: Array<string> = new Array<string>();
    filterColumns: Map<string, any> = new Map<string, any>();
    publicToPublisher: boolean = false;
    openHistoryDialog: boolean = false;
    marketingEventDeliveryProviderList: Array<FssMarketingEventDeliveryProvider> = [];
    selfPickUpList: Array<FssDeliveryProvider> = [];
    deliveryToDoorList: Array<FssDeliveryProvider> = [];
    tabIndex: number = 0;
    bodyHeight: number = 0;
    productList: Array<FssProduct> = [];
    product?: FssProduct;
    lang: string = "";
    productDescription: string = "";
}

export default class FssMarketingEventDetailPage extends FtComponent<FssMarketingEventDetailPageProp, FssMarketingEventDetailPageState> {
    mceEditor!: JtMceEditor;
    userMediaSelectDialog!: FssUserMediaSelectDialog;
    productLiteDialog!: FssProductLiteDialog;
    pickUpProviderDialog!: FssPickUpProviderDialog;

    constructor(props: any) {
        super(props);
        let filterColumns = new Map<string, any>();
        if (this.props.match.params.id != null && this.props.match.params.id.length > 0) {
            filterColumns.set("marketingEventId", this.props.match.params.id.trim());
        }
        let appWebsite = FssClientData.getData().appWebsite;
        let lang = "";
        if (appWebsite && appWebsite.language) {
            lang = appWebsite.language
        }
        this.state = {
            bodyHeight: window.innerHeight,
            model: new FssMarketingEvent(),
            producatTagList: [],
            productList: [],
            filterColumns: filterColumns,
            publicToPublisher: false,
            marketingEventDeliveryProviderList: [],
            selfPickUpList: [],
            deliveryToDoorList: [],
            tabIndex: 0,
            openHistoryDialog: false,
            lang: lang,
            productDescription: ""
        };
        this.onClickBack = this.onClickBack.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.loadProductTagList = this.loadProductTagList.bind(this);
        this.loadSelectedProductList = this.loadSelectedProductList.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.getCoverImageComp = this.getCoverImageComp.bind(this);
        this.onFinishUpload = this.onFinishUpload.bind(this);
        this.onShowMediaDialog = this.onShowMediaDialog.bind(this);
        this.onClickSearchOrder = this.onClickSearchOrder.bind(this);
        this.onClickPublisherList = this.onClickPublisherList.bind(this);
        this.loadPublisherShipingInfo = this.loadPublisherShipingInfo.bind(this);
        this.loadMarketingListEventDeliveryProvider = this.loadMarketingListEventDeliveryProvider.bind(this);
        this.onChangeDeliveryProviderList = this.onChangeDeliveryProviderList.bind(this);
        this.onCopyMarketingEvent = this.onCopyMarketingEvent.bind(this);
        this.onChangeTab = this.onChangeTab.bind(this);
        this.getBasicTab = this.getBasicTab.bind(this)
        this.getProductTab = this.getProductTab.bind(this)
        this.getDescriptionTab = this.getDescriptionTab.bind(this);
        this.getDeliveryTab = this.getDeliveryTab.bind(this);
        this.onDeleteProduct = this.onDeleteProduct.bind(this);
        this.onOpenHistoryDialog = this.onOpenHistoryDialog.bind(this);
        this.onChangeProduct = this.onChangeProduct.bind(this);
        this.onAddHistoryProduct = this.onAddHistoryProduct.bind(this);
        this.onClickLangBtn = this.onClickLangBtn.bind(this);
        this.onChangeTranslation = this.onChangeTranslation.bind(this);
        this.saveTranslation = this.saveTranslation.bind(this);
    }

    componentDidMount() {
        const appWebsite = FssClientData.getData().appWebsite;
        if (appWebsite.supportLanguages != null && appWebsite.supportLanguages.length > 0) {
            appWebsite.supportLanguages.some((language: string) => {
                if (language !== appWebsite.language) {
                    this.onClickLangBtn(language);
                    return true;
                }
            });
        }
        if (this.props.match.params.id != null && this.props.match.params.id.length > 0) {
            FtToast.showToastLoading();
            FssMarketingEventService.getById(this.props.match.params.id, data => {
                FtToast.closeToast();
                this.setState({model: data, publicToPublisher: data.publicToPublisher}, () => {
                    this.loadSelectedProductList();
                    this.loadMarketingListEventDeliveryProvider();
                });
            })
        } else {
            //新增，则默认一些值
            this.state.model.status = EStatus.NORMAL;
            this.state.model.type = EType.JIE_LONG;
            this.forceUpdate();
        }
        this.loadProductTagList();
        this.loadPublisherShipingInfo();
    }

    loadProductTagList() {
        FssProductService.getStoreTagsList(result => this.setState({producatTagList: result}));
    }

    loadSelectedProductList() {
        FtToast.showToastLoading();
        if (!FtStringUtil.isEmpty(this.state.model.productTag)) {
            FssProductService.getOnlineListByTag(this.state.model.id, this.state.model.productTag, "", "", data => {
                if (data != null && data.content != null)
                    this.setState({productList: data.content})
            });
        }
        FtToast.closeToast();
    }

    loadPublisherShipingInfo() {
        let storeId = FssClientData.getData().webSession.adminSession?.storeId;
        let sellerId = FssClientData.getData().webSession.adminSession?.sellerId;
        if (storeId && sellerId) {
            FtToast.showToastLoading();
            FssDeliveryProviderService.getDeliveryProviderList(storeId, sellerId, (data: Array<FssDeliveryProvider>) => {
                FtToast.closeToast();
                if (data !== null && data.length > 0) {
                    let selfPickUpList: Array<FssDeliveryProvider> = [];
                    let deliveryToDoorList: Array<FssDeliveryProvider> = [];
                    data.forEach((deliveryProvider: FssDeliveryProvider) => {
                        if (deliveryProvider.deliveryType === EDeliveryType.DELIVERY_TO_DOOR) {
                            deliveryToDoorList.push(deliveryProvider);
                        }
                        if (deliveryProvider.deliveryType === EDeliveryType.SELF_PICK_UP) {
                            selfPickUpList.push(deliveryProvider);
                        }
                    })
                    this.setState({selfPickUpList: selfPickUpList, deliveryToDoorList: deliveryToDoorList});
                }
            })
        }
    }

    loadMarketingListEventDeliveryProvider() {
        FtToast.showToastLoading();
        FssMarketingEventService.getByMarketingListEventId(this.state.model.id, (data: Array<FssMarketingEventDeliveryProvider>) => {
            FtToast.closeToast();
            if (data !== null) {
                this.setState({marketingEventDeliveryProviderList: data});
            }
        });
    }

    getLeftNode(): ReactNode {
        return <FtRow cellHorizontalSpace={12}>
            <Button onClick={this.onClickBack}><ChevronLeft/>{i18n("返回")}</Button>
            <Button onClick={this.onClickSave} color={"primary"}>{i18n("保存")}</Button>
        </FtRow>
    }

    getRightNode(): ReactNode {
        if (!FtStringUtil.isEmpty(this.state.model.id)) {
            return <FtRow cellHorizontalSpace={12}>
                {!FtStringUtil.isEmpty(this.state.model.id)
                && <a href={"/website/index.html#/s@" + this.state.model.sellerId + "/jielong/" + this.state.model.id}
                      target={"_blank"}>
                    <Button>预览</Button>
                </a>}
                <FssMarketingEventDetailRightMenuComp onCopyMarketingEvent={this.onCopyMarketingEvent}
                                                      onOrderList={this.onClickSearchOrder}
                                                      onPublisherList={this.onClickPublisherList}/>
            </FtRow>
        }
        return null;
    }

    onChangeTab(event: React.ChangeEvent<{}>, value: any) {
        this.setState({tabIndex: +value});
    }

    render() {
        return <div>
            <FtAppBar position={"static"} style={{width: "100%", backgroundColor: "white"}}>
                <FtRow justifyContent={"space-between"} style={{width: "100%"}} cellHorizontalSpace={0}
                       cellVerticalSpace={0}>
                    {this.getLeftNode()}
                    {this.getRightNode()}
                </FtRow>
            </FtAppBar>
            <Tabs value={this.state.tabIndex} onChange={this.onChangeTab} textColor="primary" indicatorColor={"primary"}
                  style={{width: "100%", backgroundColor: grey[200]}}>
                <Tab label={i18n("基本信息")}/>
                <Tab label={i18n("商品")}/>
                <Tab label={i18n("描述")}/>
                <Tab label={i18n("提货方式")}/>
            </Tabs>
            {this.state.tabIndex == 0 && this.getBasicTab()}
            {this.state.tabIndex == 1 && this.getProductTab()}
            {this.state.tabIndex == 2 && this.getDescriptionTab()}
            {this.state.tabIndex == 3 && this.getDeliveryTab()}
            <FssPickUpProviderDialog ref={(element: any) => {
                this.pickUpProviderDialog = element;
            }}/>
            <FssUserMediaSelectDialog ref={(element: any) => {
                this.userMediaSelectDialog = element
            }}/>
            <FssProductLiteDialog {...this.props} ref={(element: any) => {
                this.productLiteDialog = element
            }}/>
            {this.getHistoryProductDialog()}
        </div>
    }

    private getBasicTab() {
        return <FtPanel elevation={0} style={{overflow: "scroll", height: this.state.bodyHeight - (40 + 48 + 48 + 32)}}>
            <FtGrid>
                <FtRow cellWidthS={"1"} cellWidthM={"1,1"} justifyContent={"space-between"}>
                    <FtRow cellWidthS={"1"} justifyContent={"space-between"}>
                        <FtTextFieldString label={i18n("标题")} value={this.state.model.title} multiline={true} rows={3}
                                           style={{width: "100%"}}
                                           onChangeValue={value => {
                                               this.state.model.title = value + "";
                                               this.state.model.name = value + "";
                                               this.forceUpdate();
                                           }}
                                           endAdornment={this.getTranslationIcon("title", this.state.model.title)}
                        />
                        <FtRow cellWidthS={"1,1"}>
                            <TextField select label={i18n("类型")}
                                       value={this.state.model.type || ""}
                                       onChange={(event) => {
                                           this.state.model.type = event.target.value as EType;
                                           this.forceUpdate();
                                       }} style={{width: "100%"}}>
                                <MenuItem key={EType.JIE_LONG} value={EType.JIE_LONG}>
                                    {i18n("接龙")}
                                </MenuItem>
                            </TextField>
                            <TextField select label={i18n("状态")}
                                       value={this.state.model.status || ""}
                                       onChange={(event) => {
                                           this.state.model.status = event.target.value as EStatus;
                                           this.forceUpdate();
                                       }} style={{width: "100%"}}>
                                <MenuItem key={EStatus.NORMAL} value={EStatus.NORMAL}>
                                    {i18n(EStatus.NORMAL)}
                                </MenuItem>
                                <MenuItem key={EStatus.DISABLED} value={EStatus.DISABLED}>
                                    {i18n(EStatus.DISABLED)}
                                </MenuItem>
                            </TextField>
                        </FtRow>
                        {this.getCoverImageComp()}
                        <FormControlLabel
                            control={<Checkbox checked={this.state.model.timeLimited} onChange={(event, checked) => {
                                if (checked) {
                                    this.state.model.startDatetime = new Date().toISOString();
                                } else {
                                    this.state.model.startDatetime = "";
                                    this.state.model.endDatetime = "";
                                }
                                this.state.model.status = EStatus.NORMAL;
                                this.state.model.type = EType.JIE_LONG;
                                this.state.model.timeLimited = checked;
                                this.forceUpdate();
                            }}/>} label={i18n("限定有效期")}/>
                        {this.state.model.timeLimited &&
                        <FtRow cellWidthS={"1,1"}>
                            <TextField label={i18n("开始时间")}
                                       type="datetime-local"
                                       disabled={!this.state.model.timeLimited}
                                       value={this.toDatetimeLocal(this.state.model.startDatetime)}
                                       InputLabelProps={{shrink: true,}}
                                       style={{width: "100%"}}
                                       onChange={event => {
                                           let value = event.target.value;
                                           if (value != null && !FtStringUtil.isEmpty(value)) {
                                               this.state.model.startDatetime = new Date(value).toISOString();
                                               ;
                                           } else {
                                               this.state.model.startDatetime = "";
                                           }
                                           this.forceUpdate();
                                       }}
                            />
                            <TextField label={i18n("结束时间")}
                                       type="datetime-local"
                                       disabled={!this.state.model.timeLimited}
                                       value={this.toDatetimeLocal(this.state.model.endDatetime)}
                                       InputLabelProps={{shrink: true,}}
                                       style={{width: "100%"}}
                                       onChange={event => {
                                           let value = event.target.value;
                                           if (value != null && !FtStringUtil.isEmpty(value)) {
                                               this.state.model.endDatetime = new Date(value).toISOString();
                                           } else {
                                               this.state.model.endDatetime = "";
                                           }
                                           this.forceUpdate();
                                       }}
                            />
                        </FtRow>}
                        <FtRow cellWidthS={"1,1"}>
                            <FormControlLabel control={
                                <Checkbox checked={this.state.model.checkoutSeparate}
                                          onChange={(event, checked) => {
                                              this.state.model.checkoutSeparate = checked;
                                              this.state.model.shippingDate = "";
                                              this.forceUpdate();
                                          }}/>} label={i18n("是否需要单独结算")}/>
                            {this.state.model.checkoutSeparate &&
                            <FtTextFieldDate label={i18n("发货日期")}
                                             disabled={!this.state.model.checkoutSeparate}
                                             value={this.state.model.shippingDate}
                                             InputLabelProps={{shrink: true,}}
                                             style={{width: "100%"}} onChangeValue={value => {
                                if (value != null && !FtStringUtil.isEmpty(value)) {
                                    this.state.model.shippingDate = new Date(value).toISOString();
                                } else {
                                    this.state.model.shippingDate = "";
                                }
                                this.forceUpdate();
                            }}/>}

                        </FtRow>
                        <FtRow cellWidthS={"1,1"}>
                            <FormControlLabel control={
                                <Checkbox checked={this.state.model.publishToStore}
                                          onChange={(event, checked) => {
                                              this.state.model.publishToStore = checked;
                                              this.forceUpdate();
                                          }}/>} label={i18n("是否发布到网站")}/>
                        </FtRow>
                        <FtRow cellWidthS={"1,1"}>
                            <FormControlLabel control={
                                <Checkbox checked={this.state.model.publicToPublisher}
                                          onChange={(event, checked) => {
                                              this.state.model.publicToPublisher = checked;
                                              if (!checked) {
                                                  this.state.model.instructionsForPublisher = "";
                                                  this.state.model.publisherCommissionRate = 0;
                                              }
                                              this.forceUpdate();
                                          }}/>} label={i18n("是否允许团长开团")}/>
                            {this.state.model.publicToPublisher &&
                            <FtTextFieldNumber label={i18n("佣金比例")} style={{width: "50%"}}
                                               value={this.state.model.publisherCommissionRate}
                                               onChangeValue={value => {
                                                   if (value) {
                                                       this.state.model.publisherCommissionRate = value;
                                                       this.forceUpdate();
                                                   }
                                               }}
                                               endAdornment={<InputAdornment position="end">%</InputAdornment>}/>}
                        </FtRow>
                        {this.state.model.publicToPublisher &&
                        <FtTextFieldString label={i18n("开团须知")} value={this.state.model.instructionsForPublisher}
                                           multiline={true} rows={3}
                                           style={{width: "100%"}} variant="outlined"
                                           onChangeValue={value => {
                                               if (value) {
                                                   this.state.model.instructionsForPublisher = value;
                                               } else {
                                                   this.state.model.instructionsForPublisher = "";
                                               }
                                               this.forceUpdate();
                                           }}/>}
                    </FtRow>
                </FtRow>
            </FtGrid>
        </FtPanel>
    }

    getTranslationIcon(itemKey: string, defaultText: string, rows?: number): ReactNode {
        if (this.state.model.title.length > 0) {
            let appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite && appWebsite.supportLanguages !== null && appWebsite.supportLanguages.length > 0) {
                return <FssTranslationDialogComp objectId={this.state.model.id}
                                                 groupKey={"fssMarketingEvent"} itemKey={itemKey}
                                                 rows={rows}
                                                 dialogTitle={"多语言编辑"} defaultText={defaultText}/>
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    private toDatetimeLocal(datetime: string) {
        if (datetime != null && datetime.trim().length > 0) {
            let date = new Date(datetime);
            let year = date.getFullYear()
            let month = date.getMonth() + 1;
            let day = date.getDate();
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let dateString = year + "-" + (month > 9 ? month : ("0" + month)) + "-" + (day > 9 ? day : ("0" + day));
            let timeString = (hours > 9 ? hours : ("0" + hours)) + ":" + (minutes > 9 ? minutes : ("0" + minutes));
            return dateString + "T" + timeString;
        }
        return undefined;
    }

    private getProductTab() {
        if (FtStringUtil.isEmpty(this.state.model.id)) {
            return <FtPanel elevation={0}>
                <FtGrid>
                    <Typography variant={"subtitle1"}>{i18n("请先保存，再选择商品")}</Typography>
                </FtGrid>
            </FtPanel>
        } else {
            return <FtPanel elevation={0}>
                <FtAppBar position={"static"} style={{width: "100%", backgroundColor: "white"}}>
                    <FtRow justifyContent={"space-between"} style={{width: "100%", paddingLeft: 16, paddingRight: 16}}>
                        <Typography>商品列表</Typography>
                        <FtRow cellHorizontalSpace={16}>
                            <Button variant={"outlined"} size={"small"} onClick={() => {
                                let product: FssProduct = new FssProduct();
                                product.tagsList.push(this.state.model.productTag);
                                this.productLiteDialog.show(product, (product: FssProduct) => {
                                    FtSnackbar.showText(i18n("添加成功"));
                                    this.state.productList.unshift(product);
                                    this.forceUpdate();
                                });
                            }}>添加商品</Button>
                            <Button variant={"contained"} size={"small"}
                                    onClick={this.onOpenHistoryDialog}>历史商品</Button>
                        </FtRow>
                    </FtRow>
                </FtAppBar>
                <Divider/>
                <FtGrid
                    style={{overflow: "scroll", height: this.state.bodyHeight - (40 + 48 + 48 + 48 + 32), padding: 2}}>
                    {this.state.productList && this.state.productList.length > 0 &&
                    this.state.productList.map((product: FssProduct, index: number) => {
                        return <div>
                            {this.getProductItemComp(product, index, false)}
                            <Divider style={{marginLeft: 74, marginBottom: 4}}/>
                        </div>;
                    })}
                </FtGrid>
            </FtPanel>
        }
    }

    getProductItemComp(product: FssProduct, index: number, isDailog: boolean) {
        return <div style={{width: "100%", alignItems: "flex-end", display: "flex",}}>
            <div style={{
                width: 70, height: 70,
                backgroundImage: "url('" + product.coverImageUrl + "')",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                border: "1px solid rgba(0, 0, 0, 0.12)",
                borderRadius: 6, marginRight: 4
            }}/>
            <div style={{width: "calc(100% - 74px)", height: 70}}>
                <FtRow cellHorizontalSpace={0} cellVerticalSpace={0} justifyContent={"space-between"}
                       style={{width: "100%", alignItems: "flex-start"}}
                       cellWidthS={"4,1"} cellWidthM={"7,1"} cellWidthL={"10,1"}>
                    <div>
                        <FtRow cellVerticalSpace={0} cellHorizontalSpace={8} cellWidthS={"1"}>
                            <Typography style={{
                                width: "100%",
                                boxSizing: "border-box",
                                fontSize: 14,
                                lineHeight: "16px",
                                height: 32,
                                overflow: "hidden",
                                "WebkitLineClamp": 2,
                                "WebkitBoxOrient": "vertical",
                                display: "-webkit-box",
                            }}>{product.title}</Typography>
                            <FtRow cellVerticalSpace={0} cellHorizontalSpace={8}
                                   style={{lineHeight: "18px", height: 18}}>
                                <Typography variant={"h6"} style={{
                                    fontSize: "100%",
                                    fontWeight: 500,
                                    flexGrow: 2,
                                    lineHeight: 1,
                                    color: "#ff2800"
                                }}>
                                    {FtUtil.toCurrencyText(product.salesPriceUsd)}
                                </Typography>
                                <Typography variant={"caption"}>{i18n("库存: {0}", product.stockAmount + "")}</Typography>
                                <Typography
                                    variant={"caption"}>{i18n("已售: {0}", (product.salesAmount == null ? "0" : product.salesAmount + ""))}</Typography>
                            </FtRow>
                        </FtRow>
                        <FtRow cellVerticalSpace={0} cellHorizontalSpace={8} style={{lineHeight: "16px"}}>
                            <Typography variant={"caption"}>{i18n("排序系数: {0}", product.sortIndex + "")}</Typography>
                            <Typography variant={"caption"}>{product.productStatusText}</Typography>
                        </FtRow>
                    </div>
                    {!isDailog &&
                    <FtRow cellHorizontalSpace={0} cellVerticalSpace={0} cellWidthS={"1"} style={{textAlign: "center"}}>
                        <IconButton size={"small"} style={{padding: 5}} onClick={() => {
                            this.productLiteDialog.show(product, (product: FssProduct) => {
                                FtSnackbar.showText(i18n("修改成功"));
                                this.state.productList[index] = product;
                                this.forceUpdate();
                            });
                        }}><Edit/></IconButton>
                        <IconButton size={"small"} style={{padding: 5}} onClick={() => {
                            this.onDeleteProduct(product, index)
                        }}><Close/></IconButton>
                    </FtRow>}
                </FtRow>
            </div>
        </div>
    }

    onDeleteProduct(product: FssProduct, index: number) {
        FtMessageDialog.dialog.openDialog(i18n("确定要删除吗?"), () => {
            FtToast.showToastLoading();
            FssProductService.setBatchProductTags(product.id, [this.state.model.id], "delete", (resp: string) => {
                FtToast.closeToast();
                FtSnackbar.showText(i18n("删除成功"));
                this.state.productList.splice(index, 1);
                this.forceUpdate();
            });
        });
    }

    // private getDescriptionTab() {
    //     return <FtPanel elevation={0} style={{overflow: "scroll", height: this.state.bodyHeight - (40 + 48 + 48 + 32)}}>
    //         <FtGrid>
    //             <JtMceEditor ref={(element: any) => {
    //                 this.mceEditor = element;
    //             }}
    //                          onChangeContent={this.onChangeDescription}
    //                          initialValue={this.state.model.description}
    //                          height={"400px"} width={"100%"}/>
    //         </FtGrid>
    //     </FtPanel>;
    // }
    private getDescriptionTab(): ReactNode {
        let defauleLangNode: ReactNode | undefined = undefined;
        const langArr: Array<ReactNode> = [];
        const appWebsite = FssClientData.getData().appWebsite;
        if (appWebsite.language) {
            defauleLangNode = <Button variant={"outlined"}>
                {this.getLanguageText(appWebsite.language) + "(系统默认)"}
            </Button>;
            if (appWebsite.supportLanguages && appWebsite.supportLanguages.length > 0) {
                appWebsite.supportLanguages.map((language: string) => {
                    if (language !== appWebsite.language) {
                        langArr.push(<Button variant={"outlined"} onClick={() => {
                            this.onClickLangBtn(language)
                        }}
                                             color={this.state.lang === language ? "primary" : "default"}>
                            {this.getLanguageText(language)}
                        </Button>);
                    }
                });
            }
        }
        return <FtRow cellWidthS={"1,1"}>
            <FtPanel>
                <FtToolbar
                    leftNode={langArr.length > 0 &&
                    <FtRow>
                        <TranslateIcon/>
                        {defauleLangNode}
                    </FtRow>}/>
                <JtMceEditor ref={(element: any) => {
                    this.mceEditor = element;
                }}
                             onChangeContent={this.onChangeDescription}
                             initialValue={this.state.model.description}
                             height={"500px"} width={"100%"}/>
            </FtPanel>
            {this.state.model.id.length > 0 && defauleLangNode !== undefined && langArr.length > 0 &&
            <FtPanel>
                <FtToolbar rightNode={<Button variant={"contained"} color={"primary"}
                                              onClick={this.saveTranslation}>保存</Button>}
                           leftNode={langArr.length > 0 &&
                           <FtRow>
                               <TranslateIcon/>
                               {langArr.map((node: ReactNode) => {
                                   return node;
                               })}
                           </FtRow>}/>
                <JtMceEditor ref={(element: any) => {
                    this.mceEditor = element;
                }}
                             onChangeContent={this.onChangeTranslation}
                             initialValue={this.state.productDescription}
                             height={"500px"} width={"100%"}/>
            </FtPanel>}
        </FtRow>;
    }

    getLanguageText(language: string) {
        let text = "";
        switch (language as Language) {
            case Language.en_CA:
                text = "English";
                break;
            case Language.zh_CN:
                text = "简体中文";
                break;
            case Language.zh_TW:
                text = "繁体中文";
                break;
        }
        return text;
    }

    onClickLangBtn(lang: string) {
        this.setState({lang: lang}, () => {
            const appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite && this.state.lang === appWebsite.language) {
                this.setState({productDescription: this.state.model.description});
            } else {
                FssTranslationService.getTranslationContent(this.state.model.id, "fssMarketingEvent", "description", this.state.lang, (data: Array<FtTranslation>) => {
                    FtToast.closeToast();
                    if (data && data.length > 0) {
                        const translation: FtTranslation = data[0];
                        this.setState({productDescription: translation.content});
                    } else {
                        this.setState({productDescription: ""});
                    }
                });
            }
        });
    }

    onChangeTranslation(content: string) {
        this.setState({productDescription: content});
    }

    saveTranslation() {
        FtToast.showToastLoading(i18n("正在保存"));
        let content = this.state.productDescription;
        const translation: FtTranslation = FtTranslation.newInstance(this.state.model.id, "fssMarketingEvent", "description", this.state.lang, content);
        FssTranslationService.saveTranslation(translation, () => {
            FtToast.closeToast();
            FtSnackbar.showText(i18n("保存成功！"));
        });
    }

    private getDeliveryTab() {
        /**
         * 加一个 新增提货点按钮，点击打开对话框新增
         * 每个提货点占一行，后面有个编辑的图标，点击可以打开对话框修改内容
         */
        return <FtPanel elevation={0}
                        style={{overflow: "scroll", height: this.state.bodyHeight - (40 + 48 + 48 + 32)}}>
            <FtGrid>
                {this.getDeliveryComp()}
            </FtGrid>
        </FtPanel>
    }

    getDeliveryComp(): ReactNode {
        if (this.state.deliveryToDoorList.length > 0 || this.state.selfPickUpList.length > 0) {
            return <div>
                {this.state.deliveryToDoorList.length > 0 && <Typography color={"secondary"}>请选择配送商</Typography>}
                {this.state.deliveryToDoorList.length > 0 &&
                <FtRow cellWidthS={"1"}>
                    {this.state.deliveryToDoorList.map((deliveryProvider: FssDeliveryProvider) => {
                        return <FormControlLabel onChange={() => {
                            this.onChangeDeliveryProviderList(deliveryProvider.id)
                        }}
                                                 control={<Checkbox
                                                     checked={this.findByDeliveryProviderId(deliveryProvider.id)}/>}
                                                 label={deliveryProvider.deliveryProviderName}/>
                    })}
                </FtRow>}
                {this.state.selfPickUpList.length > 0 &&
                <FtRow cellWidthS={"1,1"}>
                    <Typography color={"secondary"}>请选择自取点</Typography>
                    <Button variant={"outlined"} size={"small"} onClick={() => {
                        this.pickUpProviderDialog.show(new FssDeliveryProvider(), () => {
                            this.loadPublisherShipingInfo();
                        });
                    }}>新增自取点</Button>
                </FtRow>}
                {this.state.selfPickUpList.length > 0 &&
                this.state.selfPickUpList.map((deliveryProvider: FssDeliveryProvider) => {
                    return <div>
                        <FtRow>
                            <FormControlLabel onChange={() => {
                                this.onChangeDeliveryProviderList(deliveryProvider.id)
                            }}
                                              control={<Checkbox
                                                  checked={this.findByDeliveryProviderId(deliveryProvider.id)}/>}
                                              label={deliveryProvider.deliveryProviderName}/>
                            <IconButton size={"small"} onClick={() => {
                                this.pickUpProviderDialog.show(deliveryProvider, () => {
                                    this.loadPublisherShipingInfo();
                                });
                            }}><Edit/></IconButton>
                        </FtRow>
                        <FtRow justifyContent={"space-between"} cellWidthS={"1,1"}>
                            <FtTextFieldString label={i18n("联系人")} disabled={true} value={deliveryProvider.contactName}
                                               style={{width: "100%"}}/>
                            <FtTextFieldString label={i18n("联系电话")} disabled={true}
                                               value={deliveryProvider.contactPhone} style={{width: "100%"}}/>
                        </FtRow>
                        <FtRow cellWidthS={"1"}>
                            <FtTextFieldString label={i18n("自取地址")} disabled={true}
                                               value={deliveryProvider.pickUpAddress} style={{width: "100%"}}/>
                        </FtRow>
                    </div>
                })}
            </div>;
        } else {
            return <Typography>暂无配送点和自取点</Typography>;
        }
    }

    findByDeliveryProviderId(deliveryProviderId: string) {
        let find = _.find(this.state.marketingEventDeliveryProviderList, {'deliveryProviderId': deliveryProviderId});
        if (find === undefined) {
            return false;
        } else {
            return true;
        }
    }

    onChangeDeliveryProviderList(deliveryProviderId: string) {
        let findIndex = _.findIndex(this.state.marketingEventDeliveryProviderList, {'deliveryProviderId': deliveryProviderId});
        if (findIndex === -1) {
            let delivertProvider = new FssMarketingEventDeliveryProvider();
            delivertProvider.deliveryProviderId = deliveryProviderId;
            delivertProvider.marketingEventId = this.state.model.id;
            this.state.marketingEventDeliveryProviderList.push(delivertProvider);
        } else {
            this.state.marketingEventDeliveryProviderList.splice(findIndex, 1);
        }
        this.forceUpdate();
    }

    onChangeDescription(content: string) {
        this.state.model.description = content;
        this.forceUpdate();
    }

    onClickSearchOrder(): void {
        this.props.history.push("/order/list/" + EMenuitem.MarketingOrderList + "/" + this.state.model.id);
    }

    onClickPublisherList(): void {
        this.props.history.push("/applyForPublisherList/" + this.state.model.id);
    }

    onClickBack(): void {
        this.props.history.goBack();
    }

    onCopyMarketingEvent(): void {
        FtMessageDialog.dialog.openDialog(i18n("确定复制当前活动吗？"), () => {
            FtToast.showToastLoading(i18n("正在复制"));
            FssMarketingEventService.copyMarketingEvent([this.state.model.id], (data: Array<FssMarketingEvent>) => {
                FtToast.closeToast();
                if (data != null && data.length > 0) {
                    this.setState({model: data[0]});
                    FtSnackbar.showText(i18n("成功复制 " + data.length + " 条记录"));
                    this.props.history.replace("/marketingEventDetail/" + data[0].id);
                }
            });
        });
    }

    onClickSave(): void {
        if (FtStringUtil.isEmpty(this.state.model.title)) {
            FtSnackbar.showText("请填写活动标题");
            return;
        }
        if (this.state.model.checkoutSeparate && this.state.model.shippingDate == "") {
            FtSnackbar.showText("请选择发货日期");
            return;
        }
        FtToast.showToastLoading();
        FssMarketingEventService.save(this.state.model, data => {
            FtToast.closeToast();
            this.setState({model: data, publicToPublisher: data.publicToPublisher}, () => {
                this.loadProductTagList();
                this.loadSelectedProductList();
                if (data.deliveryProviderStatus) {
                    let deliveryProviders: Array<string> = [];
                    this.state.marketingEventDeliveryProviderList.forEach((marketingEventDeliveryProvider: FssMarketingEventDeliveryProvider) => {
                        deliveryProviders.push(marketingEventDeliveryProvider.deliveryProviderId);
                    });
                    FssMarketingEventService.saveMarketingEventDeliveryProvider(data.id, deliveryProviders,
                        (deliveryProviders: Array<FssMarketingEventDeliveryProvider>) => {
                            FtToast.closeToast();
                        });
                }
            });
            this.props.history.replace("/marketingEventDetail/" + data.id);
        })
    }

    getCoverImageComp(): ReactNode {
        if (this.state.model.coverImageUrl !== null && this.state.model.coverImageUrl.length > 0) {
            return <div style={{position: "relative", display: "inline-flex"}}>
                <img src={this.state.model.coverImageUrl} style={{maxWidth: "calc(100%)"}}
                     onClick={(event) => {
                         event.stopPropagation();
                         this.onClickZoomOut(this.state.model.coverImageUrl);
                     }}/>
                <IconButton size={"small"}
                            style={{
                                backgroundColor: "rgba(0, 0, 0, 0.75)", top: 0, right: 0,
                                position: "absolute", borderRadius: 0, borderBottomLeftRadius: "75%",
                                justifyContent: "flex-end"
                            }}
                            onClick={() => {
                                this.state.model.coverImageUrl = "";
                                this.forceUpdate();
                            }}>
                    <Close style={{color: "white"}}/>
                </IconButton>
            </div>;
        } else {
            let buttonStyle = {
                cursor: "pointer",
                width: 100, height: 100,
                padding: 0,
                backgroundColor: grey[100],
                border: "1px dashed grey", borderRadius: 3,
                display: "flex", alignItems: "center",
            };
            return <Button style={buttonStyle} onClick={this.onShowMediaDialog}>
                <div style={{color: "grey", margin: "auto", textAlign: "center"}}>
                    <CropOriginal viewBox={"3 3 18 18"} style={{width: 35, height: 35}}/>
                    <Typography>添加封面图</Typography>
                </div>
            </Button>;
        }
    }

    onShowMediaDialog() {
        this.userMediaSelectDialog.show("single", (userMediaList: Array<JtUserMedia> | null) => {
            if (userMediaList != null && userMediaList.length > 0) {
                this.state.model.coverImageUrl = userMediaList[0].mediaUrl;
                this.forceUpdate();
            }
        });
    }

    onFinishUpload(fileUrl: string, fileName: string) {
        if (fileUrl.length > 0) {
            this.state.model.coverImageUrl = fileUrl;
        } else {
            this.state.model.coverImageUrl = "";
        }
        this.forceUpdate();
    }

    onClickZoomOut(mediaUrl: string) {
        if (mediaUrl.length > 0) {
            FtMediaViewerDialog.FtMediaViewerDialog.show(mediaUrl, "image");
        }
    }

    onOpenHistoryDialog() {
        this.setState({openHistoryDialog: !this.state.openHistoryDialog})
    }

    getHistoryProductDialog() {
        return <Dialog open={this.state.openHistoryDialog} PaperProps={{style: {height: 300, width: 400}}}>
            <DialogTitle>{i18n("历史商品")}</DialogTitle>
            <DialogContent dividers style={{paddingLeft: 8, paddingRight: 8}}>
                <FssProductComboBox label={i18n("商品名称")}
                                    onChangeProduct={this.onChangeProduct}
                                    selectedProduct={this.state.product}/>
                <div style={{paddingTop: 16}}>
                    {this.state.product != null &&
                    this.getProductItemComp(this.state.product, -1, true)}
                </div>
            </DialogContent>
            <DialogActions style={{border: "none"}}>
                <Button variant="outlined" onClick={this.onOpenHistoryDialog}>取消</Button>
                <Button variant="outlined" onClick={this.onAddHistoryProduct} disabled={this.state.product == null}
                        color={"primary"}>添加</Button>
            </DialogActions>
        </Dialog>
    }

    onAddHistoryProduct() {
        if (this.state.product != null) {
            let productId = this.state.product.id;
            let indexOf = _.findIndex(this.state.productList, {'id': productId});
            if (indexOf > -1) {
                FtSnackbar.showText(i18n("商品已经存在活动列表"), 60000, "error");
            } else {
                FtToast.showToastLoading();
                FssProductService.setBatchProductTags(this.state.product.id, [this.state.model.id], "add", (resp: string) => {
                    FtToast.closeToast();
                    FtSnackbar.showText(i18n("添加成功"));
                    if (this.state.product) {
                        this.state.product.tagsList.push(this.state.model.id);
                        this.state.productList.unshift(this.state.product);
                    }
                    this.setState({openHistoryDialog: false});
                });
            }
        }
    }

    onChangeProduct(item: any) {
        this.setState({product: item});
    }
}
