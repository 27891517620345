import FssHttpService from "./FssHttpService";
import {FssWebSession} from "../model/FssWebSession";
import FssUserProfile from "../model/FssUserProfile";
import FtPagedList from "../model/FtPagedList";
import FssUser from "../model/FssUser";
import FssUserBalanceTransaction from "../model/FssUserBalanceTransaction";
import FssPointTransaction from "../model/FssPointTransaction";
import FtApiResponse from "../model/FtApiResponse";
import FssPointRule from "../model/FssPointRule";
import FssUserAddress from "../model/FssUserAddress";
import FssClientData from "../data/FssClientData";
import FtOAuthData from "../model/FtOAuthData";
import {EAccountType} from "../model/FssUserAccount";
import FssOAuthResult from "../model/FssOAuthResult";
import FasUser from "../model/FasUser";

export default class FssUserService {

    static register(mobile: string, email: string, password: string, verifyCode: string, verifyCodeId: string,
                    inviteCode: string, referral: string, successFunction: (data: FssWebSession) => void) {
        FssHttpService.postFormData("/api/user/register", {
            mobile: mobile,
            email: email,
            password: password,
            verifyCode: verifyCode,
            verifyCodeId: verifyCodeId,
            inviteCode: inviteCode,
            referral: referral
        }, successFunction);
    }


    public static login(loginName: string, password: string, successFunction: (webSession: FssWebSession) => void) {
        FssHttpService.postFormData("/api/user/loginByAdmin", {
            loginName: loginName,
            password: password
        }, successFunction);
    }

    public static loginBySso(accessToken: string, userDomainName: string, successFunction: (webSession: FssWebSession) => void) {
        let params = {accessToken: accessToken, userDomainName: userDomainName};
        FssHttpService.postFormData("/api/user/loginBySso", params, successFunction);
    }

    /**
     * 获取第三方OAuth2的数据
     * @param accountType
     * @param action
     * @param redirectUri
     * @param successFunction
     */
    static getOAuthData(accountType: EAccountType, action: "LOGIN" | "CONNECT", redirectUri: string, successFunction: (data: FtOAuthData) => void) {
        let params = {accountType: accountType, action: action, redirectUri: redirectUri};
        FssHttpService.get("/api/userAccount/getOAuthData", params, successFunction);
    }

    /**
     * 处理 OAuth2跳回来时的参数
     * @param url
     * @param successFunction
     */
    static processOAuthRedirect(url: string, successFunction: (data: FssOAuthResult) => void) {
        FssHttpService.postFormData("/api/userAccount/processOAuthRedirect", {currentUrl: url}, successFunction);
    }

    /**
     * 发Email验证码
     * @param email 接收验证码的手机号
     * @param content 短信内容
     * @param successFunction 发送成功的回调函数，参数为 verificationId，在验证的时候需要使用这个id
     */
    static sendEmailVerificationCode(email: string, type: number, successFunction: (verificationId: string) => void) {
        FssHttpService.postFormData("/api/email/sendEmailVerificationCode", {
            email: email,
            type: type
        }, successFunction);
    }

    static resetPassword(mobile: string, email: string, password: string, verifyCode: string, verifyCodeId: string, successFunction: (data: FssWebSession) => any) {
        let params = {
            mobile: mobile,
            email: email,
            password: password,
            verifyCode: verifyCode,
            verifyCodeId: verifyCodeId
        };
        FssHttpService.postFormData("/api/user/resetPassword", params, (apiResponse: any) => {
            if (apiResponse && (apiResponse.errorCode || apiResponse.message)) {
                FssHttpService.handleError(apiResponse);
            } else {
                successFunction(apiResponse);
            }
        }, FssHttpService.handleError);
    }

    static updateUserDefaultLanguage(defaultLanguage: string,
                                     successFunction: (userProfile: FssUserProfile) => void) {
        FssHttpService.postFormData("/api/user/updateUserDefaultLanguage",
            {defaultLanguage: defaultLanguage},
            successFunction);
    }

    static getMyUserProfile(successFunction: (jtUserProfile: FssUserProfile) => void) {
        FssHttpService.get("/api/user/getUserProfile", null, successFunction, FssHttpService.handleError);
    }

    static getMyInvite(successFunction: (jtUserProfile: FssUserProfile) => void) {
        FssHttpService.get("/api/user/getMyInvite", null, successFunction, FssHttpService.handleError);
    }

    static checkAccountStatus(mobile: string, email: string, userId: string, successFunction: (apiResponse: FtApiResponse) => void, failFunction?: (data: FtApiResponse) => void): void {
        FssHttpService.postFormData("/api/user/checkAccountBindStatus", {
            mobile: mobile,
            email: email,
            userId: userId
        }, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static bindMobileOrEmail(mobile: string, email: string, userId: string, verifyCode: string, verifyCodeId: string, successFunction: (user: FssUser) => void): void {
        let params = {mobile: mobile, email: email, userId: userId, verifyCode: verifyCode, verifyCodeId: verifyCodeId};
        FssHttpService.postFormData("/api/user/bindMobileWithEmail", params, successFunction, FssHttpService.handleError);
    }

    static bindMobile(loginName: string, password: string, verifyCode: string, verifyCodeId: string, email: string,
                      successFunction: (data: FtApiResponse) => void,
                      failFunction: (data: FtApiResponse) => void) {
        let params = {
            mobile: loginName,
            password: password,
            verifyCode: verifyCode,
            email: email,
            verifyCodeId: verifyCodeId
        };
        FssHttpService.postFormData("/api/user/bindMobile", params, successFunction, failFunction);
    }

    static getUser(successFunction: (user: FssUser) => void, failFunction?: (response: FtApiResponse) => void) {
        FssHttpService.get("/api/user/getUser", {}, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static logout(successFunction: () => void) {
        FssClientData.clearWebSession();
        successFunction();
    }

    static getUserList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                       pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssUserProfile>) => void) {
        FssHttpService.search("/api/user/getUserProfileWithUserList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction);
    }

    static exportUserCsv(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                         pageNo: number, pageSize: number, successFunction: (fileUrl: string) => void): void {
        FssHttpService.search("/api/user/exportUserCsv", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction);
    }

    static getUserById(id: string, successFunction: (data: FssUserProfile) => void): void {
        FssHttpService.get("/api/user/getUserById", {id: id}, successFunction);
    }

    static saveUserWithUserProfile(data: FssUserProfile, successFunction: (data: FssUserProfile) => void): void {
        FssHttpService.postJson("/api/user/saveJtUserProfileWithUser", data, successFunction);
    }

    static resetUserPassword(userId: string, password: string, successFunction: (data: FssUser) => void): void {
        let params = {uid: userId, password: password};
        FssHttpService.postFormData("/api/user/resetJtUserPassword", params, successFunction);
    }

    static getBalanceListByUserId(userId: string, successFunction: (balanceList: Array<FssUserBalanceTransaction>) => void) {
        FssHttpService.postFormData("/api/user/getBalanceListByUserId", {id: userId}, successFunction);
    }

    static getPointListByUserId(userId: string, successFunction: (balanceList: Array<FssPointTransaction>) => void) {
        FssHttpService.postFormData("/api/user/getScoreListByUserId", {id: userId}, successFunction);
    }

    static getBalanceListByOrderId(orderId: string, successFunction: (balanceList: Array<FssUserBalanceTransaction>) => void) {
        FssHttpService.postFormData("/api/user/getBalanceListByOrderId", {orderId: orderId}, successFunction);
    }

    static getPointListByOrderId(orderId: string, successFunction: (balanceList: Array<FssPointTransaction>) => void) {
        FssHttpService.postFormData("/api/user/getScoreListByOrderId", {orderId: orderId}, successFunction);
    }

    static updateUserStatus(userId: string, successFunction: (jtUser: FssUser) => void) {
        FssHttpService.postFormData("/api/user/updateUserStatus", {userId: userId}, successFunction);
    }

    static updateUserBalance(userId: string, amountType: string, amount: number, comment: string, successFunction: (jtUserProfile: FssUserProfile) => void): void {
        let params = {uid: userId, amountType: amountType, amount: amount, comment: comment};
        FssHttpService.postFormData("/api/user/updateUserBalance", params, successFunction);
    }

    static updateUserPoint(userId: string, amountType: string, amount: number, comment: string, successFunction: (jtUserProfile: FssUserProfile) => void): void {
        let params = {uid: userId, amountType: amountType, amount: amount, comment: comment};
        FssHttpService.postFormData("/api/user/updateUserPoint", params, successFunction);
    }

    static getPointRuleList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                            pageNo: number, pageSize: number, successFunction: (data: FtPagedList<FssPointRule>) => void,
                            failFunction?: (error: any) => void): void {
        FssHttpService.search("/api/user/getPointRuleList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction, FssHttpService.handleError);
    }

    static savePointRule(FssPointRule: FssPointRule, successFunction: (data: FssPointRule) => void,
                         failFunction?: (error: any) => void): void {
        FssHttpService.postJson("/api/user/savePointRule", FssPointRule, successFunction, FssHttpService.handleError);
    }

    static getPointRuleByRuleCode(ruleCode: string, successFunction: (data: FssPointRule) => void,
                                  failFunction?: (error: any) => void): void {
        FssHttpService.get("/api/user/save/getPointRuleByRuleCode", {ruleCode: ruleCode}, successFunction, FssHttpService.handleError);
    }

    static getUserAddressList(searchKeyword: string, filterColumns: Map<string, any>, sortPropertyName: string | null, sortDirection: "asc" | "desc" | null,
                              pageNo: number, pageSize: number, successFunction: (result: FtPagedList<FssUserAddress>) => void) {
        FssHttpService.search("/api/user/getUserAddressList", searchKeyword, filterColumns, sortPropertyName, sortDirection, pageNo, pageSize,
            successFunction);
    }

    static saveUserAddress(userAddress: FssUserAddress,
                           successFunction: (userAddress: FssUserAddress) => void,
                           failFunction ?: (error: any) => void): void {
        FssHttpService.postJson("/api/user/saveUserAddress", userAddress, successFunction);
    }

    static getUserAddressById(userAddressId: string, successFunction: (userAddressList: FssUserAddress) => void) {
        FssHttpService.get("/api/user/getUserAddressById", {userAddressId: userAddressId}, successFunction);
    }

    static getMyUserDetail(successFunction: (data: FssUser) => void) {
        FssHttpService.get("/api/user/getMyUserDetail", null, successFunction, FssHttpService.handleError);
    }

    static saveMyUserDetail(fssUser: FssUser, successFunction: (data: FssUser) => void) {
        FssHttpService.postJson("/api/user/saveMyUserDetail", fssUser, successFunction, FssHttpService.handleError);
    }

    static updateMyUserMobile(mobile: string, verifyCode: string, verifyCodeId: string, successFunction: (data: FssUser) => void) {
        FssHttpService.postFormData("/api/user/updateMyUserMobile", {
            mobile: mobile,
            verifyCode: verifyCode,
            verifyCodeId: verifyCodeId
        }, successFunction, FssHttpService.handleError);
    }

    static userEmailVerification(email: string, successFunction: (apiResponse: any) => void): void {
        let params = {email: email};
        FssHttpService.postFormData("/api/user/userEmailVerification", params, successFunction, FssHttpService.handleError);
    }

    static checkEmailVerification(verificationCode: string, successFunction: (apiResponse: string) => void, failFunction?: (data: FtApiResponse) => void) {
        let params = {verificationCode: verificationCode};
        FssHttpService.postFormData("/api/user/checkEmailVerification", params, successFunction, failFunction ? failFunction : FssHttpService.handleError);
    }

    static checkImportUserFile(fileUrl: string, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/user/checkImportUserFile", {fileUrl: fileUrl}, successFunction, FssHttpService.handleError);
    }

    static importUserFile(fileUrl: string, resetPassword: boolean, successFunction: (data: string) => void) {
        FssHttpService.postFormData("/api/user/importUserFile", {
            fileUrl: fileUrl,
            resetPassword: resetPassword
        }, successFunction, FssHttpService.handleError);
    }

    // fas user
    static getFasUserDetail(successFunction: (data: FasUser) => void) {
        FssHttpService.get("/api/user/getFasUserDetail", null, successFunction, FssHttpService.handleError);
    }

    static saveFasUserDetail(fssUser: FasUser, successFunction: (data: FasUser) => void) {
        FssHttpService.postJson("/api/user/saveFasUserDetail", fssUser, successFunction, FssHttpService.handleError);
    }

    static updateFasUserMobile(mobile: string, verifyCode: string, verifyCodeId: string, successFunction: (data: FssUser) => void) {
        FssHttpService.postFormData("/api/user/updateFasUserMobile", {
            mobile: mobile,
            verifyCode: verifyCode,
            verifyCodeId: verifyCodeId
        }, successFunction, FssHttpService.handleError);
    }

    static fasUserEmailVerification(email: string, successFunction: (data: FasUser) => void): void {
        let params = {email: email};
        FssHttpService.postFormData("/api/user/fasUserEmailVerification", params, successFunction, FssHttpService.handleError);
    }

    static resetFasUserPassword(mobile: string, email: string, password: string, verifyCode: string, verifyCodeId: string, successFunction: (data: string) => any) {
        let params = {
            mobile: mobile,
            email: email,
            password: password,
            verifyCode: verifyCode,
            verifyCodeId: verifyCodeId
        };
        FssHttpService.postFormData("/api/user/resetFasUserPassword", params, (apiResponse: any) => {
            if (apiResponse && (apiResponse.errorCode || apiResponse.message)) {
                FssHttpService.handleError(apiResponse);
            } else {
                successFunction(apiResponse);
            }
        }, FssHttpService.handleError);
    }
}
