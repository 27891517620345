import {FtAbstractModel} from "../model/FtAbstractModel";
import FssUser from "./FssUser";
import FssSeller from "./FssSeller";

export enum EStatus{
    NORMAL="NORMAL",DISABLED="DISABLED",STOP="STOP"
}

export default class FssSellerEmployee extends FtAbstractModel{
    user: FssUser = new FssUser();
    roles: string="";
    status: EStatus = EStatus.NORMAL;
    createDatetime: string = "";
    seller: FssSeller = new FssSeller();
}
