import FssBrand from "./FssBrand";
import JtCategory from "./FssCategory";
import JtProduct from "./FssProduct";


export enum ECouponType {
    PERCENT = "PERCENT", MONEY = "MONEY"
}

export enum EActionType {
    TOTALMONEY = "TOTALMONEY", PRODUCTMONEY = "PRODUCTMONEY", SHIPMONEY = "SHIPMONEY"
}

export enum EHowToUse {
    CHOOSE = "CHOOSE", SYSTEM = "SYSTEM", COUPONCODE = "COUPONCODE"
}

export enum EExpireStandard {
    EQUALS = "EQUALS", DELAY = "DELAY"
}

export enum EHowToReceive {
    USER_ACTIVE = "USER_ACTIVE",
    REGISTER_AFTER = "REGISTER_AFTER",
    LANDING_PAGE = "LANDING_PAGE",
    ORDER_PAYMENT = "ORDER_PAYMENT",
    STORE_ADMIN = "STORE_ADMIN"

}

export enum EproductInclude {
    INCLUDE="INCLUDE",EXCLUDE="EXCLUDE"
}
export enum EStatus {
    NORMAL="NORMAL", STOP="STOP"
}
export enum EOrderChannel {
    UNKNOWN = "UNKNOWN",
    WECHAT_OFFICIAL_ACCOUNT = "WECHAT_OFFICIAL_ACCOUNT",
    WECHAT_MINI_PROGRAM = "WECHAT_MINI_PROGRAM",
    APP_ANDROID = "APP_ANDROID",
    APP_IOS = "APP_IOS",
    MOBILE_WEB = "MOBILE_WEB",
    PC_WEB = "PC_WEB",
    THIRD_API = "THIRD_API",
    STORE_ADMIN = "STORE_ADMIN",
    ALIPAY_H5 = "ALIPAY_H5"
}
export default class FssCoupon {
    id?: string = "";
    storeId?: string = "";
    sellerId:string="";
    couponName?: string = "";
    description?: string = "";
    couponCode?: string = "";
    userAmountLimit: number = 1;
    discount?: number = 0;
    couponType?: string = "";
    startDatetime: string = "";
    startDatetimeText: string = "";
    createDatetime: string = "";
    createDatetimeText?: string = "";
    status?: string = EStatus.NORMAL;
    actionType?: string = "";
    howToUse?: string = "";
    endDatetime: string = "";
    endDatetimeText: string = "";
    expireStandard?: string = EExpireStandard.EQUALS;
    expireDays?: number = 0;
    totalAmountLimit: number = 0;
    newUserOnly?: boolean = false;
    userLevel?: number = 0;
    shippingType?: string = "";
    paymentType?: string = "";
    orderChannel?: string = "";
    orderTotalMoney?: number = 0;
    orderReferral?: string = "";
    flyerImageUrl?: string = "";
    howToReceive?: string = EHowToReceive.LANDING_PAGE;
    country: string = "";
    province: string = "";
    city: string = "";
    receiveAmount: number = 0;
    usedAmount: number = 0;
    productInclude:string = EproductInclude.INCLUDE;

    brandList: Array<FssBrand> = [];
    categoryList: Array<JtCategory> = [];
    productList: Array<JtProduct> = [];

    brand: FssBrand = new FssBrand();
    category: JtCategory = new JtCategory();
    product: JtProduct = new JtProduct();

    startDatetimeString: string = "";
    endDatetimeString: string = "";

    couponTags:Array<string>=[];
    tagsList:Array<string> = new Array<string>();
    defaultTagsList:Array<string> = new Array<string>();
    productMoney: number = 0;

}
