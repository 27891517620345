import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import {FormControl, FormLabel, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {RouteComponentProps} from "react-router-dom";
import JtTextField from "../ui/JtTextField";
import FssDeliveryProvider, {EDeliveryProviderStatus, EDeliveryType} from "../model/FssDeliveryProvider";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtPanel from "../ui/FtPanel";
import {i18n} from "../util/I18n";
import {Save} from "@material-ui/icons";
import FtTranslationDialogComp from "../product/FssTranslationDialogComp";
import JtShippingFeeTempListPage from "../shippingfee/JtShippingFeeTempList";
import FtRegion from "../model/FtRegion";
import FtTextFieldNumber from "../ui/FtTextFieldNumber";
import JtShippingScheduleListPage from "../shippingSchedule/JtShippingScheduleListPage";
import lodash from "lodash";
import FssDeliveryProviderService from "../service/FssDeliveryProviderService";
import FtPage from "../ui/FtPage";
import FssClientData from "../data/FssClientData";
import {EPeriodTypes, EStatus} from "../model/FssShippingSchedule";
import Typography from "@material-ui/core/Typography";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";
import FssCityService from "../service/FssCityService";

interface FssDeliveryProviderDetailPageProp extends RouteComponentProps<{ deliveryProviderId: string }> {
}

export class FssDeliveryProviderDetailPageState {
    deliveryProvider: FssDeliveryProvider = new FssDeliveryProvider();
    countryList: Array<FtRegion> = new Array<FtRegion>();
    selectCountry?: string = "";
    provinceList: Array<FtRegion> = new Array<FtRegion>();
    selectProvince?: string = "";
    cityList: Array<FtRegion> = new Array<FtRegion>();
    selectCity?: string = "";

    stopOrderDay: string = "";
    stopOrderTime: string = "";

}


export default class FssDeliveryProviderDetailPage extends React.Component<FssDeliveryProviderDetailPageProp, FssDeliveryProviderDetailPageState> {

    constructor(props: any) {
        super(props);
        this.state = {
            deliveryProvider: new FssDeliveryProvider(),
            countryList: [],
            selectCountry: "",
            provinceList: [],
            selectProvince: "",
            cityList: [],
            selectCity: "",
            stopOrderDay: "",
            stopOrderTime: "",
        };
        this.onChange = this.onChange.bind(this);
        this.onChangeFssShippingSchedule = this.onChangeFssShippingSchedule.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChangeStopOrderDay = this.onChangeStopOrderDay.bind(this);
        this.onChangeStopOrderTime = this.onChangeStopOrderTime.bind(this);
        this.getShippingScheduleCheckbox = this.getShippingScheduleCheckbox.bind(this);
        this.loadCountry = this.loadCountry.bind(this);
    }

    componentDidMount(): void {
        this.load();
        this.loadCountry();
    }

    load() {
        if (this.props.match.params.deliveryProviderId != null && this.props.match.params.deliveryProviderId != "0") {
            FssDeliveryProviderService.getDeliveryProviderById(this.props.match.params.deliveryProviderId, (result: FssDeliveryProvider) => {
                const deliveryProvider: FssDeliveryProvider = FtUtil.mergeObject(new FssDeliveryProvider(), result);
                this.setState({deliveryProvider: deliveryProvider});
                if (deliveryProvider != null && deliveryProvider.deliveryType == EDeliveryType.SELF_PICK_UP) {
                    let stopOrderDayTime = this.state.deliveryProvider.fssShippingSchedule.stopOrderDayTime;
                    if (stopOrderDayTime.substring(0, 1) == "-") {
                        stopOrderDayTime = stopOrderDayTime.substring(1);
                    }
                    let index = stopOrderDayTime.indexOf(" ");
                    this.setState({stopOrderDay: stopOrderDayTime.substring(0, index)});
                    this.setState({stopOrderTime: stopOrderDayTime.substring(index + 1)});
                }
            });
        }
    }

    loadCountry() {
        FssCityService.getRegionList((countryList: Array<FtRegion>) => {
            this.setState({countryList: countryList});
        });
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.deliveryProvider, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeFssShippingSchedule(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {

        FtUtil.setProperty(this.state.deliveryProvider.fssShippingSchedule, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onClickSave() {
        if (this.state.deliveryProvider.deliveryProviderCode == "") {
            FtSnackbar.showText(i18n("请填写『编码』"));
            return;
        }
        if (this.state.deliveryProvider.deliveryProviderName == "") {
            FtSnackbar.showText(i18n("请填写『名称』"));
            return;
        }
        FtToast.showToastLoading(i18n("正在保存"));
        FssDeliveryProviderService.saveDeliveryProvider(this.state.deliveryProvider,
            (data: FssDeliveryProvider) => {
                FtToast.closeToast();
                this.setState({deliveryProvider: FtUtil.mergeObject(this.state.deliveryProvider, data)});
                FtSnackbar.showText(i18n("保存成功！"));
                this.onCancelClick();
            });
    }

    render() {
        const provinceList: Array<FtRegion> = new Array<FtRegion>();
        if (this.state.deliveryProvider.pickUpCountry != null) {
            const country = lodash.find(this.state.countryList, {'code': this.state.deliveryProvider.pickUpCountry});
            if (country && country.subItems && country.subItems.length > 0) {
                country.subItems.map((region: FtRegion) => {
                    provinceList.push(region);
                })
            }
        }
        return <div>
            <FtPage onCancelClick={this.onCancelClick}
                    toolbarLeftNode={<Button variant="contained"
                                             color="primary"
                                             startIcon={<Save/>}
                                             onClick={this.onClickSave}>{i18n("保存")}</Button>}>
                <FtPanel panelHeader={"配送服务商基本信息"}>
                    <FtGrid>
                        <div style={{width: '100%'}}/>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <JtTextField id="deliveryProviderCode" label={i18n("编码")} helperText={""}
                                         value={this.state.deliveryProvider.deliveryProviderCode}
                                         style={{width: '100%'}} onChange={this.onChange} fullWidth/>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel> {i18n("状态")} </InputLabel>
                                <Select id="deliveryProviderStatus"
                                        value={this.state.deliveryProvider.deliveryProviderStatus}
                                        onChange={(event, child) => {
                                            this.state.deliveryProvider.deliveryProviderStatus = event.target.value as EDeliveryProviderStatus;
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={EDeliveryProviderStatus.NORMAL}>{i18n("正常")}</MenuItem>
                                    <MenuItem value={EDeliveryProviderStatus.STOP}>{i18n("停用")}</MenuItem>
                                    {/* <MenuItem value={EDeliveryProviderStatus.DELETED}>删除</MenuItem> */}
                                </Select>
                            </FormControl>

                            <FormControl style={{width: '100%'}}>
                                <InputLabel>{i18n("配送类型")}</InputLabel>
                                <Select disabled={this.state.deliveryProvider.id.length > 0}
                                        id="deliveryType" value={this.state.deliveryProvider.deliveryType}
                                        onChange={(event, child) => {
                                            this.state.deliveryProvider.deliveryType = event.target.value as EDeliveryType;
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={EDeliveryType.DELIVERY_TO_DOOR}>{i18n("送货")}</MenuItem>
                                    <MenuItem value={EDeliveryType.SELF_PICK_UP}>{i18n("自取")}</MenuItem>
                                </Select>
                            </FormControl>
                        </FtRow>
                        <FtRow cellWidthS={"1"}>
                            <JtTextField id="deliveryProviderName" label={i18n("模板名称")}
                                         value={this.state.deliveryProvider.deliveryProviderName}
                                         style={{width: '100%'}} onChange={this.onChange}
                                         endAdornment={this.getTranslationIcon("deliveryProviderName", this.state.deliveryProvider.deliveryProviderName)}/>
                            <JtTextField id="shortDescription" label={i18n("简述")}
                                         value={this.state.deliveryProvider.shortDescription}
                                         style={{width: '100%'}}
                                         onChange={this.onChange}
                                         endAdornment={this.getTranslationIcon("shortDescription", this.state.deliveryProvider.shortDescription)}/>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1"} cellHorizontalSpace={8} maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <TextField id="comment" multiline label={i18n("评论")}
                                       value={this.state.deliveryProvider.comment}
                                       onChange={this.onChange} style={{width: '100%'}}/>
                        </FtRow>

                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <JtTextField id="contactName" label={i18n("联系人")}
                                         value={this.state.deliveryProvider.contactName}
                                         onChange={this.onChange} style={{width: '100%'}}/>

                            <JtTextField type='phone' id="contactPhone" label={i18n("联系电话")}
                                         value={this.state.deliveryProvider.contactPhone} onChange={this.onChange}
                                         style={{width: '100%'}}/>
                            <JtTextField id="contactEmail" label={i18n("联系email")}
                                         value={this.state.deliveryProvider.contactEmail} onChange={this.onChange}
                                         style={{width: '100%'}}/>
                            <JtTextField id="createDatetime" label={i18n("创建时间")}
                                         value={this.state.deliveryProvider.createDatetimeText}
                                         style={{width: "100%"}} disabled={true}/>
                        </FtRow>
                    </FtGrid>
                </FtPanel>
                <br/>
                {this.state.deliveryProvider.deliveryType == EDeliveryType.SELF_PICK_UP &&
                <div>
                    <FtPanel panelHeader={"自取点"}>
                        <FtGrid>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                                   cellVerticalSpace={16}>
                                <FormControl style={{width: "100%"}}>
                                    <InputLabel id="demo-simple-select-label"> {i18n("国家")} </InputLabel>
                                    <Select id="pickUpCountry" labelId="demo-simple-select-label"
                                            value={this.state.deliveryProvider.pickUpCountry}
                                            onChange={(event, child) => {
                                                this.state.deliveryProvider.pickUpCountry = event.target.value as string;
                                                let provinceList: Array<FtRegion> = new Array<FtRegion>();
                                                const country = lodash.find(this.state.countryList, {'code': event.target.value as string});
                                                if (country && country.subItems && country.subItems.length > 0) {
                                                    country.subItems.map((region: FtRegion) => {
                                                        provinceList.push(region);
                                                    })
                                                }
                                                this.setState({
                                                    selectCountry: event.target.value as string,
                                                    provinceList: provinceList
                                                });
                                                this.forceUpdate();

                                            }}>
                                        {this.state.countryList.map(country => {
                                            return <MenuItem value={country.code}>{i18n(country.name)}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <FormControl style={{width: "100%"}}>
                                    <InputLabel id="demo-simple-select-label"> {i18n("省份")} </InputLabel>
                                    <Select id="pickUpCountry" labelId="demo-simple-select-label"
                                            value={this.state.deliveryProvider.pickUpProvince}
                                            onChange={(event, child) => {
                                                this.state.deliveryProvider.pickUpProvince = event.target.value as string;
                                                let cityList: Array<FtRegion> = new Array<FtRegion>();
                                                const province = lodash.find(this.state.provinceList, {'code': event.target.value as string});
                                                if (province && province.subItems && province.subItems.length > 0) {
                                                    province.subItems.map((region: FtRegion) => {
                                                        cityList.push(region);
                                                    })
                                                }
                                                this.setState({
                                                    selectProvince: event.target.value as string,
                                                    cityList: cityList
                                                });
                                                this.forceUpdate();
                                            }}>
                                        {provinceList.map(province => {
                                            return <MenuItem value={province.code}>{i18n(province.name)}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>
                                <JtTextField id="pickUpCity" label={i18n("收件人市")}
                                             value={this.state.deliveryProvider.pickUpCity}
                                             onChange={this.onChange} style={{width: "100%"}}/>
                                <JtTextField id="pickUpAddress" label={i18n("详细地址")} required={true}
                                             value={this.state.deliveryProvider.pickUpAddress} onChange={this.onChange}
                                             style={{width: '100%'}}/>
                            </FtRow>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,1,1,1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                                   cellVerticalSpace={16}>

                                <FtTextFieldNumber id="minMoney" label={i18n("最小订单金额")}
                                                   value={this.state.deliveryProvider.minMoney}
                                                   type="float"
                                                   onChangeValue={value => this.state.deliveryProvider.minMoney = value ? value : 0}
                                                   style={{width: "100%"}}
                                                   inputStyle={{
                                                       textAlign: "right"
                                                   }}/>
                                <FtTextFieldNumber id="userHandleFee" label={i18n("手续费")}
                                                   value={this.state.deliveryProvider.userHandleFee}
                                                   onChangeValue={(value) => {
                                                       this.state.deliveryProvider.userHandleFee = value ? value : 0;
                                                   }} style={{width: "100%"}} type={"currency"}/>
                                <FormControl style={{width: '100%'}}>
                                    <InputLabel> {i18n("支持到店付款")} </InputLabel>
                                    <Select id="payAtStore"
                                            value={this.state.deliveryProvider.payAtStore}
                                            onChange={(event, child) => {
                                                this.state.deliveryProvider.payAtStore = event.target.value as boolean;
                                                this.forceUpdate();
                                            }}>
                                        <MenuItem value={"false"}>{i18n("否")}</MenuItem>
                                        <MenuItem value={"true"}>{i18n("是")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </FtRow>
                        </FtGrid>
                    </FtPanel>
                    <br/>
                    <FtPanel panelHeader={i18n("自取时间基本信息")}>
                        <FtGrid>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,1,1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                                   cellVerticalSpace={16}>
                                <FormControl style={{width: '100%'}}>
                                    <InputLabel> {i18n("状态")} </InputLabel>
                                    <Select id="status"
                                            value={this.state.deliveryProvider.fssShippingSchedule.status}
                                            onChange={(event, child) => {
                                                this.state.deliveryProvider.fssShippingSchedule.status = event.target.value as EStatus;
                                                this.forceUpdate();
                                            }}>
                                        <MenuItem value={EStatus.ENABLED}>{i18n("启用")}</MenuItem>
                                        <MenuItem value={EStatus.DISABLED}>{i18n("停用")}</MenuItem>
                                        <MenuItem value={EStatus.STOP_ORDER}>{i18n("禁止下单")}</MenuItem>
                                    </Select>
                                </FormControl>
                            </FtRow>
                            <FtRow cellWidthS={"1"} cellWidthM={"1,2"} cellHorizontalSpace={32} maxRowWidth={"l"}
                                   cellVerticalSpace={16}>
                                <FormControl style={{width: '100%'}}>
                                    <InputLabel> {i18n("自取周期")} </InputLabel>
                                    <Select id="periodType"
                                            value={this.state.deliveryProvider.fssShippingSchedule.periodType}
                                            onChange={(event, child) => {
                                                this.state.deliveryProvider.fssShippingSchedule.periodType = event.target.value as EPeriodTypes;
                                                this.state.deliveryProvider.fssShippingSchedule.periodValues = "";
                                                this.forceUpdate();
                                            }}>
                                        <MenuItem value={EPeriodTypes.DAILY}>{i18n("每日自取")}</MenuItem>
                                        <MenuItem value={EPeriodTypes.WEEKLY}>{i18n("每周自取")}</MenuItem>
                                        <MenuItem value={EPeriodTypes.MONTHLY}>{i18n("每月自取")}</MenuItem>
                                    </Select>
                                </FormControl>

                                <FtRow>
                                    <Typography variant="caption">{i18n("用户下单时可以选择")}</Typography>
                                    <TextField id="availableDaysCount"
                                               value={this.state.deliveryProvider.fssShippingSchedule.availableDaysCount}
                                               onChange={this.onChangeFssShippingSchedule} style={{width: 20}}/>
                                    <Typography variant="caption">{i18n("个自取日期")}</Typography>
                                </FtRow>

                            </FtRow>
                            {this.getShippingScheduleCheckbox()}

                            <div style={{width: "100%"}}/>
                            <FormControl style={{minWidth: 400}}>
                                <FormLabel> {i18n("自取服务时段")} </FormLabel>
                                <FtRow>
                                    <TextField id="startTime" type="time" onChange={this.onChangeFssShippingSchedule}
                                               value={this.state.deliveryProvider.fssShippingSchedule.startTime}
                                               required={true} InputLabelProps={{shrink: true,}}
                                               inputProps={{step: "300"}}/>-
                                    <TextField id="endTime" type="time" onChange={this.onChangeFssShippingSchedule}
                                               value={this.state.deliveryProvider.fssShippingSchedule.endTime}
                                               required={true} InputLabelProps={{shrink: true}}
                                               inputProps={{step: "300"}}/>
                                </FtRow>
                            </FormControl>
                            <div style={{width: "100%"}}/>
                            <FormControl style={{minWidth: 400}}>
                                <FormLabel> {i18n("截单时间")} </FormLabel>
                                <FtRow>
                                    <Typography variant="caption">{i18n("提前")}</Typography>
                                    <JtTextField style={{marginTop: 20, width: 100}} id="stopOrderTime"
                                                 value={this.state.stopOrderDay}
                                                 onChange={this.onChangeStopOrderDay} helperText={"当天截单请填0"}/>
                                    <Typography variant="caption">{i18n("天的")}</Typography>
                                    <TextField id="stopOrderTime" type="time" value={this.state.stopOrderTime}
                                               onChange={this.onChangeStopOrderTime}
                                               required={true} InputLabelProps={{shrink: true}}
                                               inputProps={{step: "300"}}/>
                                    <Typography variant="caption">{i18n("截单")}</Typography>
                                </FtRow>
                            </FormControl>
                        </FtGrid>
                    </FtPanel>
                </div>
                }
                {this.state.deliveryProvider.deliveryType == EDeliveryType.DELIVERY_TO_DOOR && +this.state.deliveryProvider.id.length > 0 &&
                <div>
                    <FtPanel>
                        <JtShippingFeeTempListPage
                            deliveryProviderId={this.state.deliveryProvider.id + ""}/>
                    </FtPanel>
                    <br/>
                    <FtPanel>
                        <JtShippingScheduleListPage
                            deliveryProviderId={this.state.deliveryProvider.id + ""}/>
                    </FtPanel>
                </div>
                }
            </FtPage>
        </div>;

    }

    onChangeStopOrderDay(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        let stopOrderTime = this.state.deliveryProvider.fssShippingSchedule.stopOrderDayTime;
        if (stopOrderTime != null && stopOrderTime.length == 0) {
            stopOrderTime = "0 00:00";
        }
        let index = stopOrderTime.indexOf(" ");
        let newStopOrderTime = event.target.value == "0" ?
            event.target.value + stopOrderTime.substring(index) : "-" + event.target.value + stopOrderTime.substring(index);
        FtUtil.setProperty(this.state.deliveryProvider.fssShippingSchedule, "stopOrderDayTime", newStopOrderTime);
        this.setState({stopOrderDay: event.target.value});
    }

    onChangeStopOrderTime(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        let stopOrderTime = this.state.deliveryProvider.fssShippingSchedule.stopOrderDayTime;
        if (stopOrderTime != null && stopOrderTime.length == 0) {
            stopOrderTime = "0 00:00";
        }
        let index = stopOrderTime.indexOf(" ");
        let newStopOrderTime = stopOrderTime.substring(0, index + 1) + event.target.value;
        FtUtil.setProperty(this.state.deliveryProvider.fssShippingSchedule, "stopOrderDayTime", newStopOrderTime);
        this.setState({stopOrderTime: event.target.value});
    }

    getShippingScheduleCheckbox() {
        if (this.state.deliveryProvider.fssShippingSchedule.periodType != null) {
            if (this.state.deliveryProvider.fssShippingSchedule.periodType == EPeriodTypes.WEEKLY) {
                return <FormControl style={{minWidth: 400}}>
                    <FormLabel> {i18n("配送日")} </FormLabel>
                    <FtCheckboxGroup row={true}
                                     values={["1", "2", "3", "4", "5", "6", "7"]}
                                     labels={[i18n("星期日"), i18n("星期一"), i18n("星期二"), i18n("星期三"), i18n("星期四"), i18n("星期五"), i18n("星期六")]}
                                     checkedValues={this.state.deliveryProvider.fssShippingSchedule.periodValues.split(",")}
                                     onCheck={(checkedValues) => {
                                         let checkedValuesStr = "";
                                         for (let periodValue of checkedValues) {
                                             if (periodValue) {
                                                 checkedValuesStr += "," + periodValue;
                                             }
                                         }
                                         FtUtil.setProperty(this.state.deliveryProvider.fssShippingSchedule, "periodValues",
                                             checkedValuesStr.substring(1));
                                         this.forceUpdate();
                                     }}>
                    </FtCheckboxGroup>
                </FormControl>;
            } else if (this.state.deliveryProvider.fssShippingSchedule.periodType == EPeriodTypes.MONTHLY) {
                return <FormControl style={{minWidth: 400}}>
                    <FormLabel> {i18n("配送日")} </FormLabel>
                    <FtCheckboxGroup row={true}
                                     values={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15",
                                         "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]}
                                     labels={["1日", "2日", "3日", "4日", "5日", "6日", "7日", "8日", "9日", "10日", "11日", "12日", "13日", "14日", "15日",
                                         "16日", "17日", "18日", "19日", "20日", "21日", "22日", "23日", "24日", "25日", "26日", "27日", "28日", "29日", "30日", "31日"]}
                                     checkedValues={this.state.deliveryProvider.fssShippingSchedule.periodValues.split(",")}
                                     onCheck={(checkedValues) => {
                                         let checkedValuesStr = "";
                                         for (let periodValue of checkedValues) {
                                             if (periodValue) {
                                                 checkedValuesStr += "," + periodValue;
                                             }
                                         }
                                         FtUtil.setProperty(this.state.deliveryProvider.fssShippingSchedule, "periodValues",
                                             checkedValuesStr.substring(1));
                                         this.forceUpdate();
                                     }}>
                    </FtCheckboxGroup>
                </FormControl>;
            }
        } else {
            return null;
        }
    }

    getTranslationIcon(itemKey: string, defaultText?: string, rows?: number): ReactNode {
        if (this.state.deliveryProvider && this.state.deliveryProvider.id.length > 0) {
            const appWebsite = FssClientData.getData().appWebsite;
            if (appWebsite.supportLanguages != null && appWebsite.supportLanguages.length > 0) {
                return <FtTranslationDialogComp objectId={this.state.deliveryProvider.id}
                                                groupKey={"fssDeliveryProvider"} itemKey={itemKey}
                                                rows={rows}
                                                dialogTitle={"多语言编辑"} defaultText={defaultText}/>;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}
