import * as React from "react";
import {ChangeEvent, ReactNode} from "react";
import FtPagedList from "../model/FtPagedList";
import FtToast from "../ui/FtToast";
import FtDataTable, {FtDataTableAdapter, FtDataTableColumnProps} from "../ui/FtDataTable";
import {TextField} from "@material-ui/core";
import FtComponent, {FtComponentState} from "../ui/FtComponent";
import FtState from "../ui/FtState";
import {CSVLink} from "react-csv";
import CloudDownloadOutlined from "@material-ui/icons/CloudDownloadOutlined";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {i18n} from "../util/I18n";
import FssPaymentTransactionService from "../service/FssPaymentTransactionService";
import FssSellerStatement, {EType} from "../model/FssSellerStatement";
import FtRow from "../ui/FtRow";

export interface FssSellerStatementListProp {
}

class FssSellerStatementListState extends FtComponentState {
    tableAdapter: JtTableAdapter = new JtTableAdapter();
    columnProps: Array<FtDataTableColumnProps> = [];
    csvData: Array<FssSellerStatement> = new Array<FssSellerStatement>();
}

class JtTableAdapter extends FtDataTableAdapter<FssSellerStatement> {
    loadData(): void {
        FtToast.showToastLoading("loading");
        FssPaymentTransactionService.getFssSellerStatementList(this.keyword,
            this.getFilterColumnValue("startDate"), this.getFilterColumnValue("endDate"),
            this.getFilterColumnValue("orderCode"),this.getFilterColumnValue("type"),
            this.pagedList.number, this.pagedList.size,
            (result: FtPagedList<FssSellerStatement>) => {
                FtToast.closeToast();
                this.pagedList = result;
                this.fireUpdateData();
            });
    }
}

export default class FssSellerStatementListPage extends FtComponent<FssSellerStatementListProp, FssSellerStatementListState> {
    csvLink!:any;
    constructor(props: any) {

        super(props);
        const columnProps: Array<FtDataTableColumnProps> = [];
        const column0: FtDataTableColumnProps = new FtDataTableColumnProps("payDatetimeText", i18n("支付时间"));
        columnProps.push(column0);
        const column1: FtDataTableColumnProps = new FtDataTableColumnProps("money", i18n("付款金额"),"right");
        columnProps.push(column1);
        const column2: FtDataTableColumnProps = new FtDataTableColumnProps("typeText", i18n("订单类型"));
        columnProps.push(column2);
        const column3: FtDataTableColumnProps = new FtDataTableColumnProps("paymentAccountName", i18n("支付帐号名称"));
        columnProps.push(column3);
        const column4: FtDataTableColumnProps = new FtDataTableColumnProps("gatewayOrderId", i18n("订单网关ID"));
        columnProps.push(column4);
        const column6: FtDataTableColumnProps = new FtDataTableColumnProps("orderCode", i18n("订单编号"),"right");
        columnProps.push(column6);
        const column7: FtDataTableColumnProps = new FtDataTableColumnProps("orderDatetimeText", i18n("订单创建时间"));
        columnProps.push(column7);
        const column8: FtDataTableColumnProps = new FtDataTableColumnProps("orderStatusText", i18n("Order Status "));
        columnProps.push(column8);
        const column9: FtDataTableColumnProps = new FtDataTableColumnProps("paymentType", i18n("支付类型"));
        columnProps.push(column9);
        const column10: FtDataTableColumnProps = new FtDataTableColumnProps("paymentStatus", i18n("支付状态"));
        columnProps.push(column10);
        const column11: FtDataTableColumnProps = new FtDataTableColumnProps("totalPayment", i18n("总支付金额"));
        columnProps.push(column11);
        const column12: FtDataTableColumnProps = new FtDataTableColumnProps("userHandleFee", i18n("手续费"),"right");
        columnProps.push(column12);
        const column13: FtDataTableColumnProps = new FtDataTableColumnProps("tips", i18n("小费"),"right");
        columnProps.push(column13);
        let tableAdapter: JtTableAdapter;
        const cacheState: FssSellerStatementListState = FtState.pop("FssSellerStatementListState");
        if (cacheState != null) {
            tableAdapter = cacheState.tableAdapter;
        } else {
            tableAdapter = new JtTableAdapter();
        }
        tableAdapter.onUpdateData = ((pagedList: FtPagedList<any>) => {
            this.forceUpdate();
        });
        this.state = {
            tableAdapter: tableAdapter, columnProps: columnProps,
            stateKey: "FssSellerStatementListState", csvData: new Array<FssSellerStatement>()
        };
        this.getFilterPanel = this.getFilterPanel.bind(this);
        this.downloadCsvFile = this.downloadCsvFile.bind(this);
    }

    componentDidMount(): void {
        this.state.tableAdapter.loadData();
    }

    render() {
        return <div>
            <FtDataTable title={i18n("交易明细")} columnProps={this.state.columnProps} adapter={this.state.tableAdapter}
                         filterPanel={this.getFilterPanel()}
                         toolbarNoSelect={this.downloadCsvFile()}>
            </FtDataTable>
        </div>;
    }

    getFilterPanel(): ReactNode {

        return <FtRow>
            <TextField label={i18n("订单号")} onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.state.tableAdapter.filterColumns.set("orderCode", event.target.value);
                this.forceUpdate();
            }} value={this.state.tableAdapter.getFilterColumnValue("orderCode")} style={{ width: 200 }} />
            <TextField id="startDate"
                       label={i18n("开始时间")}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("startDate")}
                       InputLabelProps={{
                           shrink: true,
                       }}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("startDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            <TextField id="endDate"
                       label={i18n("结束时间")}
                       type="date"
                       style={{width: 150}}
                       value={this.state.tableAdapter.getFilterColumnValue("endDate")}
                       InputLabelProps={{
                           shrink: true,
                       }}
                       onChange={event => {
                           this.state.tableAdapter.filterColumns.set("endDate", event.target.value);
                           this.forceUpdate();
                       }}
            />
            <FormControl style={{ minWidth: 150 }}>
                <InputLabel> {i18n("订单类型")} </InputLabel>
                <Select id="type" value={this.state.tableAdapter.getFilterColumnValue("type")}
                        onChange={(event: any) => {
                            this.state.tableAdapter.filterColumns.set("type", event.target.value as EType);
                            this.forceUpdate();
                        }}>
                    <MenuItem value={EType.ALL} key={EType.ALL}>{i18n("全部商品")}</MenuItem>
                    <MenuItem value={EType.PAY_ORDER} key={EType.PAY_ORDER}>{i18n("订单付款")}</MenuItem>
                    <MenuItem value={EType.REFUND_ORDER} key={EType.REFUND_ORDER}>{i18n("订单退款")}</MenuItem>
                    <MenuItem value={EType.PAY_BALANCE_RECHARGE} key={EType.PAY_BALANCE_RECHARGE}>{i18n("充值付款")}</MenuItem>
                    <MenuItem value={EType.REFUND_BALANCE_RECHARGE} key={EType.REFUND_BALANCE_RECHARGE}>{i18n("充值退款")}</MenuItem>
                </Select>
            </FormControl>
        </FtRow>;
    }

    downloadCsvFile(): ReactNode {
        let headers = [
            {label: i18n("支付时间"), key: "payDatetimeText"},
            {label: i18n("金额"), key: "money"},
            {label: i18n("订单类型"), key: "typeText"},
            {label: i18n("支付帐号名称"), key: "paymentAccountName"},
            {label: i18n("订单网关ID"), key: "gatewayOrderId"},
            {label: i18n("订单ID"), key: "orderId"},
            {label: i18n("订单编号"), key: "orderCode"},
            {label: i18n("订单创建时间"), key: "orderDatetimeText"},
            {label: i18n("Order Status "), key: "orderStatusText"},
            {label: i18n("支付类型"), key: "paymentType"},
            {label: i18n("支付状态"), key: "paymentStatus"},
            {label: i18n("总支付金额"), key: "totalPayment"},
            {label: i18n("手续费"), key: "userHandleFee"},
            {label: i18n("小费"), key: "tips"}

        ];

        return <div>
            <IconButton onClick={() => {
                FtToast.showToastLoading("loading");
                FssPaymentTransactionService.getFssSellerStatementList(this.state.tableAdapter.keyword,
                    this.state.tableAdapter.getFilterColumnValue("startDate"), this.state.tableAdapter.getFilterColumnValue("endDate"),
                    this.state.tableAdapter.getFilterColumnValue("orderCode"), this.state.tableAdapter.getFilterColumnValue("type"),
                    0, 999999,
                    (result: FtPagedList<FssSellerStatement>) => {
                        FtToast.closeToast();
                        this.setState({csvData: result.content},()=>{
                            if(this.csvLink&&this.csvLink.link)
                                this.csvLink.link.click();
                        });
                    });
            }}><CloudDownloadOutlined/></IconButton>
            <CSVLink data={this.state.csvData} headers={headers} filename={i18n("交易明细.csv")}
                     ref={(element:any)=>{this.csvLink=element}}>
            </CSVLink>
        </div>
    }
}
