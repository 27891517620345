import * as React from "react";
import {ChangeEvent} from "react";
import {FormControl, FormLabel, InputLabel, MenuItem, Select, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FtUtil from "../util/FtUtil";
import FtToast from "../ui/FtToast";
import FtSnackbar from "../ui/FtSnackbar";
import {RouteComponentProps} from "react-router-dom";
import JtTextField from "../ui/JtTextField";
import FtGrid from "../ui/FtGrid";
import FtRow from "../ui/FtRow";
import FtPanel from "../ui/FtPanel";
import FssShippingSchedule, {EPeriodTypes, EStatus} from "../model/FssShippingSchedule";
import {i18n} from "../util/I18n";
import {Save} from "@material-ui/icons";
import FssRegionService from "../service/FssRegionService";
import Typography from "@material-ui/core/Typography";
import FssRegion from "../model/FssRegion";
import FtPagedList from "../model/FtPagedList";
import FssShippingScheduleService from "../service/FssShippingScheduleService";
import FtPage from "../ui/FtPage";
import FtCheckboxGroup from "../ui/FtCheckboxGroup";

interface JtShippingScheduleDetailPageProp extends RouteComponentProps<{ shippingScheduleId: string,deliveryProviderId:string }> {
}

export class JtShippingScheduleDetailPageState {
    jtShippingScheduleSetting: FssShippingSchedule = new FssShippingSchedule();
    stopOrderDay: string = "";
    stopOrderTime: string = "";
    fssReginList: Array<FssRegion> = [];
}


export default class JtShippingScheduleDetailPage extends React.Component<JtShippingScheduleDetailPageProp, JtShippingScheduleDetailPageState> {

    constructor(props: any) {
        super(props);
        let fssShippingSchedule:FssShippingSchedule = new FssShippingSchedule();
        fssShippingSchedule.deliveryProviderId=this.props.match.params.deliveryProviderId;
        this.state = {
            stopOrderDay: "",
            stopOrderTime: "",
            jtShippingScheduleSetting: fssShippingSchedule,
            fssReginList: new Array<FssRegion>()
        };
        this.onChange = this.onChange.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onChangeStopOrderDay = this.onChangeStopOrderDay.bind(this);
        this.onChangeStopOrderTime = this.onChangeStopOrderTime.bind(this);
        this.getShippingScheduleCheckbox = this.getShippingScheduleCheckbox.bind(this);
    }

    componentDidMount(): void {
        this.load();
        this.loadFssRigion();
    }
    loadFssRigion(){
        let filterColumns = new Map<string, any>();
        filterColumns.set("status", "NORMAL");
        FssRegionService.getPageRegionList("",filterColumns, null, null, 0, 999,
            (result: FtPagedList<FssRegion>) => {
                FtToast.closeToast();
                this.setState({fssReginList:result.content});
                this.forceUpdate();
            });
    }

    load() {
        if (this.props.match.params.shippingScheduleId != null && this.props.match.params.shippingScheduleId!="0") {
            FssShippingScheduleService.getShippingScheduleById(this.props.match.params.shippingScheduleId, (result: FssShippingSchedule) => {
                const jtShippingScheduleSetting: FssShippingSchedule = FtUtil.mergeObject(new FssShippingSchedule(), result);
                this.setState({jtShippingScheduleSetting: jtShippingScheduleSetting});
                let stopOrderDayTime = this.state.jtShippingScheduleSetting.stopOrderDayTime;
                if(stopOrderDayTime.substring(0,1)=="-"){
                    stopOrderDayTime = stopOrderDayTime.substring(1);
                }
                let index = stopOrderDayTime.indexOf(" ");
                this.setState({stopOrderDay : stopOrderDayTime.substring(0,index)});
                this.setState({stopOrderTime : stopOrderDayTime.substring(index+1)});
            });
        }
    }

    onCancelClick(): void {
        this.props.history.goBack();
    }

    onChange(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        FtUtil.setProperty(this.state.jtShippingScheduleSetting, event.target.id, event.target.value);
        this.forceUpdate();
    }

    onChangeStopOrderDay(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        let stopOrderTime = this.state.jtShippingScheduleSetting.stopOrderDayTime;
        if(stopOrderTime.length == 0){
            stopOrderTime = "0 00:00";
        }
        let index = stopOrderTime.indexOf(" ");
        let newStopOrderTime = event.target.value=="0"?
            event.target.value + stopOrderTime.substring(index) : "-" + event.target.value + stopOrderTime.substring(index);
        FtUtil.setProperty(this.state.jtShippingScheduleSetting, "stopOrderDayTime", newStopOrderTime);
        this.setState({stopOrderDay : event.target.value});
    }

    onChangeStopOrderTime(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | HTMLSelectElement>) {
        let stopOrderTime = this.state.jtShippingScheduleSetting.stopOrderDayTime;
        if(stopOrderTime.length == 0){
            stopOrderTime = "0 00:00";
        }
        let index = stopOrderTime.indexOf(" ");
        let newStopOrderTime = stopOrderTime.substring(0, index+1) + event.target.value ;
        FtUtil.setProperty(this.state.jtShippingScheduleSetting, "stopOrderDayTime", newStopOrderTime);
        this.setState({stopOrderTime : event.target.value});
    }

    onClickSave() {
        if(this.state.jtShippingScheduleSetting.periodType!=null&&this.state.jtShippingScheduleSetting.periodType==EPeriodTypes.DAILY){
            this.state.jtShippingScheduleSetting.periodValues="";
        }else if(this.state.jtShippingScheduleSetting.periodType!=null&&this.state.jtShippingScheduleSetting.periodType==EPeriodTypes.WEEKLY){
            if(this.state.jtShippingScheduleSetting.periodValues==null||this.state.jtShippingScheduleSetting.periodValues==""){
                FtSnackbar.showText(i18n("每周配送时必须至少勾选一天"));
                return;
            }
        }
        if (this.state.jtShippingScheduleSetting.regionId == 0) {
            FtSnackbar.showText(i18n("请选择地区"));
            return;
        }
        // if (this.state.stopOrderTime == "") {
        //     FtSnackbar.showText(i18n("请填写截单时间"));
        //     return;
        // }
        FtToast.showToastLoading(i18n("正在保存"));

        FssShippingScheduleService.saveShippingSchedule(this.state.jtShippingScheduleSetting,
            (data: FssShippingSchedule) => {
                this.setState({jtShippingScheduleSetting: FtUtil.mergeObject(this.state.jtShippingScheduleSetting, data)});
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
                this.onCancelClick();
            });

    }

    render() {
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="contained"
                                                           color="primary"
                                                           startIcon={<Save />}
                                                           onClick={this.onClickSave}>{i18n("保存")}</Button>}>
                <FtPanel panelHeader={i18n("配送时间基本信息")}>
                    <FtGrid>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,1,1"} cellHorizontalSpace={32} maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel> {i18n("地区")} </InputLabel>
                                <Select id="regionId" value={this.state.jtShippingScheduleSetting.regionId}
                                        onChange={(event, child) => {
                                            this.state.jtShippingScheduleSetting.regionId = event.target.value as number;
                                            this.forceUpdate();
                                        }}>
                                    {this.state.fssReginList.map(value => {
                                        return <MenuItem value={value.id}>{value.regionName}</MenuItem>
                                    })
                                    }

                                </Select>
                            </FormControl>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel> {i18n("状态")} </InputLabel>
                                <Select id="status"
                                        value={this.state.jtShippingScheduleSetting.status}
                                        onChange={(event, child) => {
                                            this.state.jtShippingScheduleSetting.status = event.target.value as EStatus ;
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={EStatus.ENABLED}>{i18n("启用")}</MenuItem>
                                    <MenuItem value={EStatus.DISABLED}>{i18n("停用")}</MenuItem>
                                    <MenuItem value={EStatus.STOP_ORDER}>{i18n("禁止下单")}</MenuItem>
                                </Select>
                            </FormControl>
                        </FtRow>
                        <FtRow cellWidthS={"1"} cellWidthM={"1,2"} cellHorizontalSpace={32} maxRowWidth={"l"}
                               cellVerticalSpace={16}>
                            <FormControl style={{width: '100%'}}>
                                <InputLabel> {i18n("配送周期")} </InputLabel>
                                <Select id="periodType" value={this.state.jtShippingScheduleSetting.periodType}
                                        onChange={(event, child) => {
                                            this.state.jtShippingScheduleSetting.periodType = event.target.value as EPeriodTypes;
                                            this.state.jtShippingScheduleSetting.periodValues = "";
                                            this.forceUpdate();
                                        }}>
                                    <MenuItem value={EPeriodTypes.DAILY}>{i18n("每日配送")}</MenuItem>
                                    <MenuItem value={EPeriodTypes.WEEKLY}>{i18n("每周配送")}</MenuItem>
                                    <MenuItem value={EPeriodTypes.MONTHLY}>{i18n("每月配送")}</MenuItem>
                                </Select>
                            </FormControl>

                            <FtRow>
                                <Typography variant="caption">{i18n("用户下单时可以选择")}</Typography>
                                <TextField id="availableDaysCount"  value={this.state.jtShippingScheduleSetting.availableDaysCount}
                                           onChange={this.onChange} style={{width:20}}/>
                                <Typography variant="caption">{i18n("个配送日期")}</Typography>
                            </FtRow>

                        </FtRow>
                        {this.getShippingScheduleCheckbox()}

                        <div style={{width:"100%"}}/>
                        <FormControl style={{minWidth: 400}}>
                            <FormLabel> {i18n("配送服务时段")} </FormLabel>
                            <FtRow>
                                <TextField id="startTime" type="time" onChange={this.onChange}
                                       value={this.state.jtShippingScheduleSetting.startTime}
                                       required={true} InputLabelProps={{shrink: true,}}
                                       inputProps={{step: "300"}}/>-
                                <TextField id="endTime" type="time" onChange={this.onChange}
                                           value={this.state.jtShippingScheduleSetting.endTime}
                                           required={true} InputLabelProps={{shrink: true}}
                                           inputProps={{step: "300"}}/>
                            </FtRow>
                        </FormControl>
                        <div style={{width:"100%"}}/>
                        <FormControl style={{minWidth: 400}}>
                            <FormLabel> {i18n("截单时间")} </FormLabel>
                            <FtRow>
                                <Typography variant="caption">{i18n("提前")}</Typography>
                                <JtTextField style={{marginTop:20,width:100}} id="stopOrderTime"  value={this.state.stopOrderDay}
                                             onChange={this.onChangeStopOrderDay}  helperText={"当天截单请填0"}/>
                                <Typography variant="caption">{i18n("天的")}</Typography>
                                <TextField id="stopOrderTime" type="time"  value={this.state.stopOrderTime}
                                           onChange={this.onChangeStopOrderTime}
                                           required={true} InputLabelProps={{shrink: true}}
                                           inputProps={{step: "300"}}/>
                                <Typography variant="caption">{i18n("截单")}</Typography>
                            </FtRow>
                        </FormControl>
                    </FtGrid>
                </FtPanel>
        </FtPage>;
    }

    getShippingScheduleCheckbox () {
        if (this.state.jtShippingScheduleSetting.periodType!=null) {
            if (this.state.jtShippingScheduleSetting.periodType==EPeriodTypes.WEEKLY) {
                return <FormControl style={{minWidth: 400}}>
                    <FormLabel> {i18n("配送日")} </FormLabel>
                    <FtCheckboxGroup row={true}
                                     values={["1", "2", "3", "4", "5", "6", "7"]}
                                     labels={[ i18n("星期日"), i18n("星期一"), i18n("星期二"), i18n("星期三"), i18n("星期四"), i18n("星期五"), i18n("星期六")]}
                                     checkedValues={this.state.jtShippingScheduleSetting.periodValues.split(",")}
                                     onCheck={(checkedValues) => {
                                         let checkedValuesStr = "";
                                         for(let periodValue of checkedValues){
                                             if(periodValue){
                                                 checkedValuesStr+="," + periodValue;
                                             }
                                         }
                                         FtUtil.setProperty(this.state.jtShippingScheduleSetting, "periodValues",
                                             checkedValuesStr.substring(1));
                                         this.forceUpdate();
                                     }}>
                    </FtCheckboxGroup>
                </FormControl>;
            } else if (this.state.jtShippingScheduleSetting.periodType==EPeriodTypes.MONTHLY){
                return <FormControl style={{minWidth: 400}}>
                    <FormLabel> {i18n("配送日")} </FormLabel>
                    <FtCheckboxGroup row={true}
                                     values={["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15",
                                         "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]}
                                     labels={["1日", "2日", "3日", "4日", "5日", "6日", "7日", "8日", "9日", "10日", "11日", "12日", "13日", "14日", "15日",
                                         "16日", "17日", "18日", "19日", "20日", "21日", "22日", "23日", "24日", "25日", "26日", "27日", "28日", "29日", "30日", "31日"]}
                                     checkedValues={this.state.jtShippingScheduleSetting.periodValues.split(",")}
                                     onCheck={(checkedValues) => {
                                         let checkedValuesStr = "";
                                         for(let periodValue of checkedValues){
                                             if(periodValue){
                                                 checkedValuesStr+="," + periodValue;
                                             }
                                         }
                                         FtUtil.setProperty(this.state.jtShippingScheduleSetting, "periodValues",
                                             checkedValuesStr.substring(1));
                                         this.forceUpdate();
                                     }}>
                    </FtCheckboxGroup>
                </FormControl>;
            }
        } else {
            return null;
        }
    }
}
