import * as React from "react";
import {ReactNode} from "react";
import FtToast from "../ui/FtToast";
import {i18n} from "../util/I18n";
import {Button, Checkbox, MenuItem, TextField, Typography} from "@material-ui/core";
import FtPage from "../ui/FtPage";
import FssNotificationSetting, {EAttributeKey, ENotificationType} from "../model/FssNotificationSetting";
import FssNotificationSettingService from "../service/FssNotificationSettingService";
import FtPagedList from "../model/FtPagedList";
import FssTranslationDialogComp from "../product/FssTranslationDialogComp";
import FssClientData from "../data/FssClientData";
import {Save} from "@material-ui/icons";
import FtPanel from "../ui/FtPanel";
import FssAppWebsiteService from "../service/FssAppWebsiteService";
import FssAppWebsite, {ENotificationRule} from "../model/FssAppWebsite";
import JtTextField from "../ui/JtTextField";
import _ from "lodash";
import FtMessageDialog from "../ui/FtMessageDialog";
import FtRow from "../ui/FtRow";
import Tooltip from "@material-ui/core/Tooltip";
import FtSnackbar from "../ui/FtSnackbar";

class FssNotificationSettingPageState {
    appWebsite: FssAppWebsite = new FssAppWebsite();
    smsNotificationSettingList: Array<FssNotificationSetting> = [];
    emailNotificationSettingList: Array<FssNotificationSetting> = [];
}

export default class FssNotificationSettingPage extends React.Component<any, FssNotificationSettingPageState> {

    constructor(props: any) {
        super(props);

        this.state = {
            appWebsite: new FssAppWebsite(),
            smsNotificationSettingList: [],
            emailNotificationSettingList: [],
        };
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onClickSave = this.onClickSave.bind(this);
        this.loadAppWebsite = this.loadAppWebsite.bind(this);
        this.loadNotification = this.loadNotification.bind(this);
        this.getContentPanel = this.getContentPanel.bind(this);
        this.getPanel = this.getPanel.bind(this);
        this.getNotificationSetting = this.getNotificationSetting.bind(this);
        this.onChangeCheckBox = this.onChangeCheckBox.bind(this);
        this.onChageValue = this.onChageValue.bind(this);
        this.getValueComp = this.getValueComp.bind(this);
    }

    componentDidMount() {
        this.loadAppWebsite();
        this.loadNotification(ENotificationType.SMS);
        this.loadNotification(ENotificationType.EMAIL);
    }

    loadNotification(notificationType: ENotificationType) {
        FtToast.showToastLoading();
        let filterColumns = new Map<string, any>();
        filterColumns.set("notificationType", notificationType);
        FssNotificationSettingService.getNotificationSettingList(filterColumns, (result: FtPagedList<FssNotificationSetting>) => {
            FtToast.closeToast();
            if (result && result.content && result.content.length > 0) {
                if (notificationType === ENotificationType.SMS) {
                    this.setState({smsNotificationSettingList: result.content});
                }
                if (notificationType === ENotificationType.EMAIL) {
                    this.setState({emailNotificationSettingList: result.content});
                }
            }
        });
    }

    loadAppWebsite() {
        FtToast.showToastLoading();
        FssAppWebsiteService.getAppWebsite((website: FssAppWebsite) => {
            FtToast.closeToast();
            this.setState({appWebsite: website});
        });
    }

    onCancelClick() {
        this.props.history.goBack();
    }

    onClickSave() {
        FtToast.showToastLoading(i18n("正在保存"));
        FssAppWebsiteService.saveAppWebsite(this.state.appWebsite, (appWebsite: FssAppWebsite) => {
            this.setState({appWebsite: appWebsite});
            let notificationSettingList: Array<FssNotificationSetting> = [];
            if (this.state.smsNotificationSettingList && this.state.smsNotificationSettingList.length > 0) {
                this.state.smsNotificationSettingList.forEach((notificationSetting: FssNotificationSetting)=>{
                    notificationSettingList.push(notificationSetting);
                });
            }
            if (this.state.emailNotificationSettingList && this.state.emailNotificationSettingList.length > 0) {
                this.state.emailNotificationSettingList.forEach((notificationSetting: FssNotificationSetting)=>{
                    notificationSettingList.push(notificationSetting);
                });
            }
            if (notificationSettingList.length > 0) {
                FssNotificationSettingService.saveNotificationSetting(notificationSettingList, (notificationSettingList: Array<FssNotificationSetting>) => {
                    FtToast.closeToast();
                    this.loadNotification(ENotificationType.SMS);
                    this.loadNotification(ENotificationType.EMAIL);
                    FtSnackbar.showText(i18n("保存成功！"));
                });
            } else {
                FtToast.closeToast();
                FtSnackbar.showText(i18n("保存成功！"));
            }
        });
    }

    render() {
        return <FtPage onCancelClick={this.onCancelClick}
                       toolbarLeftNode={<Button variant="contained"
                                                color="primary"
                                                startIcon={<Save />}
                                                onClick={this.onClickSave}>{i18n("保存")}</Button>}
                       toolbarCenterNode={<Typography variant="h6">{i18n("用户通知设置")}</Typography>}>
            <FtPanel elevation={3} style={{padding: 15, marginBottom: 15}}>
                <TextField select id="timeZone" label={i18n("发送规则")} style={{width: 400}}
                           value={this.state.appWebsite.notificationRule}
                           onChange={(event) => {
                               this.state.appWebsite.notificationRule = event.target.value as ENotificationRule;
                               this.forceUpdate();
                           }}>
                    <MenuItem value={ENotificationRule.STOP}>不发送</MenuItem>
                    <MenuItem value={ENotificationRule.MOBILE_AND_EMAIL}>同时发送短信和Email</MenuItem>
                    <MenuItem value={ENotificationRule.MOBILE_FIRST}>短信优先</MenuItem>
                    <MenuItem value={ENotificationRule.EMAIL_FIRST}>Email优先</MenuItem>
                </TextField>
            </FtPanel>
            {this.getContentPanel()}
        </FtPage>;
    }

    getContentPanel(): ReactNode {
        if (!this.state.appWebsite.notificationRule || this.state.appWebsite.notificationRule === ENotificationRule.STOP) {
            return null;
        }
        let panelList: Array<ENotificationType> = [ENotificationType.SMS, ENotificationType.EMAIL];
        if (this.state.appWebsite.notificationRule === ENotificationRule.EMAIL_FIRST) {
            panelList = [ENotificationType.EMAIL, ENotificationType.SMS];
        }
        return <div>
            {panelList.map((notificationType: ENotificationType)=>{
                return this.getPanel(notificationType);
            })}
        </div>;
    }

    getNotificationSetting(notificationType: ENotificationType, attributeKey: EAttributeKey) {
        let notificationSettingList = notificationType === ENotificationType.SMS ? this.state.smsNotificationSettingList: this.state.emailNotificationSettingList
        let notificationSetting = _.find(notificationSettingList, {'attributeKey': attributeKey});
        if (notificationSetting === undefined) {
            notificationSetting = new FssNotificationSetting();
            notificationSetting.notificationType = notificationType;
            notificationSetting.attributeKey = attributeKey;
            notificationSetting.checked = false;
            notificationSettingList.push(notificationSetting);
            if (notificationType === ENotificationType.SMS) {
                this.setState({smsNotificationSettingList: notificationSettingList});
            } else if (notificationType === ENotificationType.EMAIL) {
                this.setState({emailNotificationSettingList: notificationSettingList});
            }
        } else if (notificationSetting.checked === undefined || !notificationSetting) {
            notificationSetting.checked = notificationSetting.attributeValue !== null && notificationSetting.attributeValue.trim().length > 0
        }
        return notificationSetting;
    }

    onChangeCheckBox(checked: boolean, notificationSetting: FssNotificationSetting) {
        if (checked) {
            notificationSetting.checked = true;
            this.onChageValue(this.getDefaultValue(notificationSetting.attributeKey), notificationSetting);
        } else {
            FtMessageDialog.dialog.openDialog(i18n("取消选中后此发送内容将被清空，确定要取消选中吗？"), () => {
                notificationSetting.checked = false;
                this.onChageValue("", notificationSetting);
            }, () => {
                FtMessageDialog.dialog.closeDialog();
            }, i18n("确认"), i18n("取消"));
        }
    }

    onChageValue(value: string, notificationSetting: FssNotificationSetting) {
        notificationSetting.attributeValue = value;
        this.forceUpdate();
    }

    getValueComp(notificationSetting: FssNotificationSetting) {
        return <FtRow style={{width: "100%", marginBottom: 8}}>
            <Checkbox color="primary" checked={notificationSetting.checked}
                      onChange={(event, checked)=>{
                          this.onChangeCheckBox(checked, notificationSetting);
                      }}/>
            <JtTextField label={i18n(this.getAttributeKeyText(notificationSetting.attributeKey))}
                         style={{width: 800}} multiline rows={notificationSetting.notificationType === ENotificationType.EMAIL? 2: undefined}
                         value={notificationSetting.attributeValue !== null ? notificationSetting.attributeValue: ""}
                         onChange={(event)=>{
                             this.onChageValue(event.target.value?event.target.value: "", notificationSetting);
                         }}
                         helperText={"提示：" + this.getHelpText(notificationSetting.attributeKey)}
                         disabled={!notificationSetting.checked}
                         endAdornment={this.getTranslationIcon(notificationSetting.id, "attributeValue", notificationSetting.attributeValue)}/>
        </FtRow>;
    }

    getPanel(notificationType: ENotificationType): ReactNode {
        let title = notificationType === ENotificationType.SMS ? "短信通知设置": "Email通知设置";
        return <FtPanel elevation={3} style={{padding: 15, marginBottom: 15}}
                        panelHeader={<Typography variant={"h6"} style={{width: 800, textAlign: "center"}}>{i18n(title)}</Typography>}>
            <Typography variant="subtitle1" color={"secondary"}>{i18n("发送给用户的通知")}</Typography>
            {this.getValueComp(this.getNotificationSetting(notificationType, EAttributeKey.CREATED))}
            {this.getValueComp(this.getNotificationSetting(notificationType, EAttributeKey.PAY_DONE))}
            {this.getValueComp(this.getNotificationSetting(notificationType, EAttributeKey.REFUND))}
            {this.getValueComp(this.getNotificationSetting(notificationType, EAttributeKey.OVERDUE))}
            {this.getValueComp(this.getNotificationSetting(notificationType, EAttributeKey.SELF_PICK_UP))}
            {this.getValueComp(this.getNotificationSetting(notificationType, EAttributeKey.DELIVERY_TO_DOOR))}
            {/*先去掉，因为订单那个地方已经加了固定参数，这里设置了也不会用到*/}
            {/*{this.getValueComp(this.getNotificationSetting(notificationType, EAttributeKey.PROMPT_PAYMENT))}*/}
            <Typography variant="subtitle1" color={"secondary"}>{i18n("发送给商家管理员的通知")}</Typography>
            {this.getValueComp(this.getNotificationSetting(notificationType, EAttributeKey.TO_BE_SHIPPED))}
        </FtPanel>
    }

    getTranslationIcon(objectId: string, itemKey: string, defaultText?: string): ReactNode {
        if (objectId !== null && objectId.trim().length > 0) {
            let appLanguageSetting = FssClientData.getData().appWebsite.supportLanguages;
            if (appLanguageSetting && appLanguageSetting.length > 0) {
                return <Tooltip title="设置多语言">
                    <FssTranslationDialogComp objectId={objectId} rows={3}
                                              groupKey={"notification"} itemKey={itemKey}
                                              dialogTitle={"多语言编辑"} defaultText={defaultText}/>
                </Tooltip>;
            }
        }
        return null;
    }

    getAttributeKeyText(attributeKey: EAttributeKey): string {
        if (attributeKey === EAttributeKey.CREATED) {
            return "提交新订单通知";
        } else if (attributeKey === EAttributeKey.PAY_DONE) {
            return "支付成功通知";
        } else if (attributeKey === EAttributeKey.REFUND) {
            return "订单退款通知";
        } else if (attributeKey === EAttributeKey.OVERDUE) {
            return "支付即将超时通知";
        } else if (attributeKey === EAttributeKey.SELF_PICK_UP) {
            return "自取发货通知";
        } else if (attributeKey === EAttributeKey.DELIVERY_TO_DOOR) {
            return "配送发货通知";
        } else if (attributeKey === EAttributeKey.PROMPT_PAYMENT) {
            return "提醒付款通知";
        } else if (attributeKey === EAttributeKey.TO_BE_SHIPPED) {
            return "新的待发货（已审核）订单";
        }
        return attributeKey;
    }

    getHelpText(attributeKey: EAttributeKey): string {
        if (attributeKey === EAttributeKey.CREATED) {
            return "内容中可包含\"{0}\"和\"{1}\"，系统会把\"{0}\"转换为当前订单号，把\"{1}\"转换为当前订单的查看地址";
        } else if (attributeKey === EAttributeKey.PAY_DONE) {
            return "内容中可包含\"{0}\"，系统会把\"{0}\"转换为当前订单号";
        } else if (attributeKey === EAttributeKey.REFUND) {
            return "内容中可包含\"{0}\"和\"{1}\"，系统会把\"{0}\"转换为当前订单号，把\"{1}\"转换为退款余额";
        } else if (attributeKey === EAttributeKey.OVERDUE) {
            return "内容中可包含\"{0}\"，系统会把\"{0}\"转换为当前订单号";
        } else if (attributeKey === EAttributeKey.SELF_PICK_UP) {
            return "内容中可包含\"{0}\"，系统会把\"{0}\"转换为当前订单号";
        } else if (attributeKey === EAttributeKey.DELIVERY_TO_DOOR) {
            return "内容中可包含\"{0}\"和\"{1}\"，系统会把\"{0}\"转换为当前订单号，把\"{1}\"转换为配送员姓名";
        } else if (attributeKey === EAttributeKey.PROMPT_PAYMENT) {
            return "内容中可包含\"{0}\"和\"{1}\"，系统会把\"{0}\"转换为当前订单号，把\"{1}\"转换为当前未支付余额";
        } else if (attributeKey === EAttributeKey.TO_BE_SHIPPED) {
            return "内容中可包含\"{0}\"和\"{1}\"，系统会把\"{0}\"转换为当前订单号，把\"{1}\"转换为订单总余额";
        }
        return "";
    }

    getDefaultValue(attributeKey: EAttributeKey): string {
        if (attributeKey === EAttributeKey.CREATED) {
            return "您的订单#{0}提交成功，请尽快支付，未支付订单不会处理，如已支付请忽略";
        } else if (attributeKey === EAttributeKey.PAY_DONE) {
            return "您的订单#{0}已支付成功，谢谢！";
        } else if (attributeKey === EAttributeKey.REFUND) {
            return "订单#{0}退款{1}元已原路退回，预计3个工作日内到账";
        } else if (attributeKey === EAttributeKey.OVERDUE) {
            return "订单#{0}还未支付，即将自动取消，请尽快支付，如已支付请忽略";
        } else if (attributeKey === EAttributeKey.SELF_PICK_UP) {
            return "您的订单#{0}已备好，请及时自取";
        } else if (attributeKey === EAttributeKey.DELIVERY_TO_DOOR) {
            return "您的订单#{0}已发货，将由{1}为您配送，请注意查收";
        } else if (attributeKey === EAttributeKey.TO_BE_SHIPPED) {
            return "有新的待发货订单：{0}，金额：{1}";
        } else if (attributeKey === EAttributeKey.PROMPT_PAYMENT) {
            return "您的订单#{0}还有${1}未支付，请尽快支付";
        }
        return "";
    }
}
