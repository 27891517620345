import * as React from "react";
import {CSSProperties} from "react";
import {CardHeader} from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import FtPanel from "../ui/FtPanel";
import {grey} from "@material-ui/core/colors";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {i18n} from "../util/I18n";

class FssReportMainPageState {

}

export default class FssReportMainPage extends React.Component<any, FssReportMainPageState> {

    constructor(props: any) {
        super(props);

    }

    render() {
        let iconSize = 40;
        let cardStyle: CSSProperties = {cursor: "pointer", width: 220, marginRight: 8, marginBottom: 8}
        return <FtPanel elevation={0} marginVertical={12} marginHorizontal={8} style={{backgroundColor: grey[200]}}>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <Card style={cardStyle} onClick={() => this.props.history.push("/paymentReport")}>
                    <CardHeader avatar={<AssessmentIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("交易汇总")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/productSaleList")}>
                    <CardHeader avatar={<AssessmentIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("商品销售报表")}
                                subheader={i18n("")}
                    />
                </Card>
            </div>
        </FtPanel>
    }
}
