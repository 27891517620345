import * as React from "react";
import {Editor} from "@tinymce/tinymce-react/lib/es2015/main/ts";
import FssUserMediaSelectDialog from "../userMedia/FssUserMediaSelectDialog";
import JtUserMedia from "../model/FssUserMedia";
import {i18n} from "../util/I18n";
import { Editor as TinyMCEEditor} from 'tinymce';

declare let tinymce: any;

interface JtMceEditorProp {
    initialValue?: string
    onChangeContent?: (content: string) => void;
    height?:string;
    width?:string;
}

class JtMceEditorState {
    content: string = "";
}

export default class JtMceEditor extends React.Component<JtMceEditorProp, any> {
    jtUserMediaSelectDialog!: FssUserMediaSelectDialog;
    mceEditor!: any;

    constructor(props: any) {
        super(props);
        this.state = {content: this.props.initialValue};
        this.initPlugin = this.initPlugin.bind(this);
        this.onChangeContent = this.onChangeContent.bind(this);
        this.onClickInsertImage = this.onClickInsertImage.bind(this);
        this.onInitMceEditor = this.onInitMceEditor.bind(this);
    }

    componentWillMount(): void {
        // tinymce.PluginManager.add('JtMceImagePlugin', ((editor: any, url: any) => {
        //     this.initPlugin(editor, url, this.onClickInsertImage)
        // }));
    }


    onInitMceEditor(event: any, editor: any) {
        editor.ui.registry.addButton('JtMceInsertImage', {
            text: i18n('插入图片'),
            onAction: this.onClickInsertImage
        });
        this.mceEditor = editor;
    }

    initPlugin(editor: any, url: any, clickFunction: () => void) {
        // Add a button that opens a window
        editor.ui.registry.addButton('JtMceInsertImage', {
            text: i18n('插入图片'),
            onAction: clickFunction
        });
    }

    onChangeContent(content: string, editor: any) {
        if (this.props.onChangeContent)
            this.props.onChangeContent(content);
    }

    onClickInsertImage() {
        this.jtUserMediaSelectDialog.show("multiple",(userMediaList: Array<JtUserMedia> | null) => {
            if (userMediaList != null && userMediaList.length > 0) {
                console.log(this.mceEditor);
                userMediaList.forEach((userMedia: JtUserMedia) => {
                    if (userMedia != null && userMedia.mediaUrl != null && userMedia.mediaUrl.length > 0)
                        this.mceEditor.insertContent("<img src='" + userMedia.mediaUrl + "' width='100%'></img>");
                })

            }
        });
    }

    render() {
        return <div>
            <Editor
                // initialValue={this.props.initialValue}
                init={{
                    mobile: {
                        theme: 'silver'
                    },
                    convert_urls: false,
                    menubar: '',
                    height: this.props.height?this.props.height:"600px",
                    width: this.props.width?this.props.width:"600px",
                    contextmenu: '',
                    plugins: 'link code JtMceImagePlugin',
                    toolbar: 'undo redo | fontsizeselect | forecolor backcolor | bold italic | alignleft aligncenter alignright | JtMceInsertImage | link | code',
                    fontsize_formats: '10px 12px 14px 16px 20px 24px 36px',
                    color_map: [
                        "000000", "Black",
                        "993300", "Burnt orange",
                        "333300", "Dark olive",
                        "003300", "Dark green",
                        "003366", "Dark azure",
                        "000080", "Navy Blue",
                        "333399", "Indigo",
                        "333333", "Very dark gray",
                        "800000", "Maroon",
                        "FF6600", "Orange",
                        "808000", "Olive",
                        "008000", "Green",
                        "008080", "Teal",
                        "0000FF", "Blue",
                        "666699", "Grayish blue",
                        "808080", "Gray",
                        "FF0000", "Red",
                        "FF9900", "Amber",
                        "99CC00", "Yellow green",
                        "339966", "Sea green",
                        "33CCCC", "Turquoise",
                        "3366FF", "Royal blue",
                        "800080", "Purple",
                        "999999", "Medium gray",
                        "FF00FF", "Magenta",
                        "FFCC00", "Gold",
                        "FFFF00", "Yellow",
                        "00FF00", "Lime",
                        "00FFFF", "Aqua",
                        "00CCFF", "Sky blue",
                        "993366", "Red violet",
                        "FFFFFF", "White",
                        "FF99CC", "Pink",
                        "FFCC99", "Peach",
                        "FFFF99", "Light yellow",
                        "CCFFCC", "Pale green",
                        "CCFFFF", "Pale cyan",
                        "99CCFF", "Light sky blue",
                        "CC99FF", "Plum"
                    ]
                }}
                onEditorChange={this.onChangeContent}
                onInit={this.onInitMceEditor}
                value={this.props.initialValue}
            />
            <FssUserMediaSelectDialog ref={(element: any) => {
                this.jtUserMediaSelectDialog = element
            }} />

        </div>;
    }
}
