import FssHttpService from "./FssHttpService";
import FssTaxName from "../model/FssTaxName";
import FtPagedList from "../model/FtPagedList";

export default class FssTaxNameService  {

    static getTaxNameValidList(country: string, successFunction: (data:Array<FssTaxName>) => void): void {
        FssHttpService.get("/api/tax/getTaxNameValidList", {country: country},
            successFunction, FssHttpService.handleError);
    }
    /**
     * 获取税费列表
     */
    static getPagedList(pageNo: number, pageSize: number,
                        successFunction: (data: FtPagedList<FssTaxName>) => void): void {
        FssHttpService.get("/api/tax/getTaxNamePagedList", {
            page: pageNo,
            size: pageSize
        }, successFunction, FssHttpService.handleError);
    }

    /**
     * 根据id获取税费详情
     */
    static getTaxNameById(taxNameId: string, successFunction: (data: FssTaxName) => void): void {
        FssHttpService.get("/api/tax/getTaxNameById", { taxNameId: taxNameId }, successFunction, FssHttpService.handleError);
    }

    /**
     * 修改 / 添加 税费
     */
    static saveTaxName(taxName: FssTaxName, successFunction: (data: FssTaxName) => void): void {
        FssHttpService.postJson("/api/tax/saveTaxName",  taxName, successFunction, FssHttpService.handleError);
    }

    /**
     * 删除税费
     */
    static permanentDelete(taxNameIds:Array<string>, successFunction: (data: FssTaxName) => void) {
        FssHttpService.postFormData("/api/tax/deleteBatchTaxName", {taxNameIds:taxNameIds}, successFunction, FssHttpService.handleError);
    }

}
