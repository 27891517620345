import {FtAbstractModel} from "../model/FtAbstractModel";


export default class FssPointTransaction extends FtAbstractModel{
    transactionId: string = "";
    userId: string = "";
    pointBefore: number = 0;
    pointAfter: number = 0;
    pointAmount: number = 0;
    type: EScoreType = EScoreType.IN;
    thirdId: string = "";
    ruleId: string = "";
    createDatetime: string = "";
    createDatetimeText: string = "";
    comment: string = "";
    sellerId: string = "";
    storeId: string = "";
}
export enum EScoreType {
    IN = "IN",
    OUT = "OUT",
}
