import FtAdminMenu from "../model/FtAdminMenu";
import FtApiResponse from "../model/FtApiResponse";
import FssHttpService from "./FssHttpService";

export default class FssAdminMenuService extends FssHttpService {

    static getMyMenuList(successFunction: (response: Array<FtAdminMenu>) => void, failFunction?: (error: FtApiResponse) => void) {
        FssHttpService.get("/api/adminMenu/getMyMenuList", null, successFunction, failFunction);
    }
}
