import FssProduct from "./FssProduct";
import {FtAbstractModel} from "../model/FtAbstractModel";

export default class FssAdvert extends FtAbstractModel{
	id: string = "";
	storeId: string = "";
	productId: string = "";
	categoryId: string = "";
	sortIndex:number=0;
	position:string="";					
	text:string="";					
	href:string="";					
	action:string="";					
	imageUrl:string="";					
	city:string="";					
	advertStatus:string="";
	createDatetimeText:string="";
	startDatetimeText:string="";
	endDatetimeText:string="";
	product:FssProduct=new FssProduct();
	advertList: Array<FssAdvert> =[];
	linesNumber:number=2;
	validate():string{
		return "";
	}
}

export enum EPosition{
	HOME_BANNER="HOME_BANNER",
	HOME_RECOMMEND_PRODUCT="HOME_RECOMMEND_PRODUCT",
	HOME_SECTION="HOME_SECTION",
	HOME_LOGO="HOME_LOGO",
	HOME_TOP_BANNER="HOME_TOP_BANNER",
	LOGIN_BANNER="LOGIN_BANNER",
	ORDER_PRINT_HEADER_HTML="ORDER_PRINT_HEADER_HTML",
	ORDER_PRINT_FOOTER_HTML="ORDER_PRINT_FOOTER_HTML",
	ORDER_PRINT_HTML="ORDER_PRINT_HTML",
	HOME_IMAGE_DIALOG="HOME_IMAGE_DIALOG"
}
export enum EAdvertStatus{
	NORMAL="NORMAL",
	STOP="STOP"
}
