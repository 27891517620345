import {FtAbstractModel} from "../model/FtAbstractModel";


export default class FssProductReview extends FtAbstractModel{
    productId: string = "";
    userId: string = "";
    userNickName: string = "";
    userAvatorUrl: string = "";
    productName: string = "";
    productImageUrl: string = "";
    orderItemId: string = "";
    anonymousStatus: boolean = false;
    content: string = "";
    reviewImage1: string = "";
    reviewImage2: string = "";
    reviewImage3: string = "";
    starCount: number = 0;
    reviewStatus?: EReviewStatus;
    createDatetimeText: string = "";
    createDateText: string = "";
    reviewStatusText: string = "";
    storeReply: string = "";
    storeId: string = "";
    sellerId: string = "";
    createDatetime?:Date;
}

export enum EReviewStatus {
    TO_BE_APPROVED = "TO_BE_APPROVED",
    APPROVE = "APPROVE",
    USER_DELETED = "USER_DELETED",
    ADMIN_DELETED = "ADMIN_DELETED",
}
