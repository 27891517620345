import * as React from "react";
import FssProductBaseInfoTabPanel from "./FssProductBaseInfoTabPanel";
import FssProduct from "../model/FssProduct";

interface JtProductChildDetailCompProp {
    fssProduct: FssProduct;
    onChange?: (product: FssProduct) => void;
}

export class JtProductChildDetailCompState {

}


export default class FssProductChildDetailComp extends React.Component<JtProductChildDetailCompProp, JtProductChildDetailCompState> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
    }


    render() {
        return <FssProductBaseInfoTabPanel fssProduct={this.props.fssProduct}
                                           onChangeProduct={this.props.onChange} />;
    }
}
