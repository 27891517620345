import {Client, StompSubscription} from "@stomp/stompjs";
import FssMessage from "../model/FssMessage";
import FssHttpService from "../service/FssHttpService";

declare const WEBSOCKET_ENDPOINT: string;

class FssChatWebsocket {
    // websocketClient!:Client;
    websocketClient = new Client({
        brokerURL: WEBSOCKET_ENDPOINT,
        connectHeaders: {
            login: 'user',
            passcode: 'password',
        },
        debug: function (str: string) {
        }
    });
    subscriptionList: Array<StompSubscription> = new Array<StompSubscription>();

    constructor() {

        this.publish = this.publish.bind(this);
        this.activate = this.activate.bind(this);
        this.deactivate = this.deactivate.bind(this);
    }

    public activate(onConnect: () => void) {
        this.deactivate();
        //建立长连接
        this.websocketClient.onConnect = onConnect;
        this.websocketClient.onStompError = function (frame: any) {
            console.log('Broker reported error: ' + frame.headers['message']);
            console.log('Additional details: ' + frame.body);
        };
        this.websocketClient.activate();
    }

    public isActive(): boolean {
        return this.websocketClient.active;
    }

    public deactivate() {
        this.subscriptionList.forEach(value => {
            if (value)
                value.unsubscribe();
        });
        this.subscriptionList = new Array<StompSubscription>();
        if (this.websocketClient.active)
            this.websocketClient.deactivate();
    }

    public subscribe(conversationId: string, callback: (message: FssMessage) => void, mySubId?: string) {
        var headers = {};
        if (mySubId) {
            headers = {id: mySubId}
        }
        var subscription = this.websocketClient.subscribe("/topic/messages/" + conversationId, (outputFrame: any) => {
            const receivedMessage: FssMessage = JSON.parse(outputFrame.body);
            callback(receivedMessage);
        }, headers);
        this.subscriptionList.push(subscription);
    }

    public subscribeSystem(systemType: string, sellerId: string, callback: () => void, mySubId?: string) {
        var headers = {};
        if (mySubId) {
            headers = {id: mySubId}
        }
        var subscription = this.websocketClient.subscribe("/topic/systemMessages/" + systemType + "/" + sellerId, (outputFrame: any) => {
            callback();
        }, headers);
        this.subscriptionList.push(subscription);
    }

    public publish(message: FssMessage) {
        this.websocketClient.publish({
            destination: "/app/chat/" + message.conversationId,
            body: JSON.stringify(message),
            headers: FssHttpService.getRequestHeaders(),
        });
    }
}

export let chatWebsocket: FssChatWebsocket = new FssChatWebsocket();
