import * as React from "react";
import {CSSProperties} from "react";
import {CardHeader} from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import FtPanel from "../ui/FtPanel";
import {grey} from "@material-ui/core/colors";
import {i18n} from "../util/I18n";
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CommentIcon from '@material-ui/icons/Comment';
import SyncAltIcon from '@material-ui/icons/SyncAlt';

class JtMarketingMainPageState {
}

export default class FssMarketingMainPage extends React.Component<any, JtMarketingMainPageState> {

    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {

    }

    render() {
        let iconSize = 40;
        let cardStyle: CSSProperties = {cursor: "pointer", width: 220, marginRight: 8, marginBottom: 8}
        return <FtPanel elevation={0} marginVertical={12} marginHorizontal={8} style={{backgroundColor: grey[200]}}>
            <div style={{display: "flex", flexWrap: "wrap"}}>
                <Card style={cardStyle} onClick={() => this.props.history.push("/marketingEventList")}>
                    <CardHeader avatar={<CardGiftcardIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("营销活动")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/fssProductReviewListPage")}>
                    <CardHeader avatar={<CommentIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("商品评价")}
                                subheader={i18n("")}
                    />
                </Card>
                <Card style={cardStyle} onClick={() => this.props.history.push("/fssExchangeRuleList")}>
                    <CardHeader avatar={<SyncAltIcon style={{width: iconSize, height: iconSize}}/>}
                                title={i18n("换购规则")}
                                subheader={i18n("")}
                    />
                </Card>
            </div>
        </FtPanel>
    }
}
{/*<Card style={{cursor: "pointer"}}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/couponSetting");}}*/
}
{/*                avatar={<ConfirmationNumberIcon style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title={i18n("优惠券")}*/
}
{/*                subheader={i18n("")}*/
}
{/*    />*/
}
{/*</Card>*/
}
{/*<Card style={{cursor: "pointer"}}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/userBalance/JtUserBalanceRuleListPage");}}*/
}
{/*                avatar={<MonetizationOnIcon style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title={i18n("余额充值规则")}*/
}
{/*                subheader={i18n("余额充值规则描述")}*/
}
{/*    />*/
}
{/*</Card>*/
}
{/*<Card style={{cursor: "pointer"}}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/exchangeRule/jtExchangeRuleList");}}*/
}
{/*                avatar={<SyncAltIcon style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title={i18n("换购规则")}*/
}
{/*                subheader={i18n("换购规则描述")}*/
}
{/*    />*/
}
{/*</Card>*/
}
{/*<Card style={{cursor: "pointer"}}>*/
}
{/*    <CardHeader onClick={()=>{this.props.history.push("/jtLuckDrawPage");}}*/
}
{/*                avatar={<CardGiftcardIcon style={{width: iconSize, height: iconSize}}/>}*/
}
{/*                title={i18n("抽奖活动")}*/
}
{/*                subheader={i18n("抽奖活动描述")}*/
}
{/*    />*/
}
{/*</Card>*/
}
